import React from "react";
import { RadioButton } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import {
  OptionsGroup,
  Title,
  Option,
  OptionContent,
  Text,
  Radio,
} from "./CommonComponents";

function FilterOptionsGroup({
  titleTranslationString,
  disabled = false,
  selectedOptionKey,
  onSelectedOptionChange,
  options,
  testIdPrefix,
}) {
  const { t } = useTranslation();
  return (
    <OptionsGroup disabled={disabled}>
      <Title>{t(titleTranslationString)}</Title>
      {options?.map((option) => (
        <Option
          disabled={disabled}
          data-testid={`${testIdPrefix}_${option.key}`}
          key={option.key}
          onClick={() => {
            onSelectedOptionChange(option);
          }}
        >
          <OptionContent>
            <Text>{t(option.name)}</Text>
            <Radio>
              <RadioButton
                readOnly
                checked={selectedOptionKey === option.key}
              />
            </Radio>
          </OptionContent>
        </Option>
      ))}
    </OptionsGroup>
  );
}

export default FilterOptionsGroup;
