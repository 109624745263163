import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { sortingTypes } from "@coworker/types/lib/taskSortingTypes";
import { FilterItem } from "./FilterItem";

const Title = styled.div`
  margin: 10px 29px;
  font-weight: bold;
  font-size: 18px;
`;

export default function SortFilter({ filterSetter, selectedFilter }) {
  return (
    <React.Fragment>
      <Title>
        <Trans>sortByTitleString</Trans>
      </Title>
      <FilterItem
        content={<Trans>newestFirstString</Trans>}
        filterType={sortingTypes.BY_NEWEST_FIRST}
        filterSetter={filterSetter}
        checked={selectedFilter?.includes(sortingTypes.BY_NEWEST_FIRST)}
      />
      <FilterItem
        content={<Trans>oldestFirstString</Trans>}
        filterType={sortingTypes.BY_OLDEST_FIRST}
        filterSetter={filterSetter}
        checked={selectedFilter?.includes(sortingTypes.BY_OLDEST_FIRST)}
      />
      <FilterItem
        content={<Trans>byGridcodeString</Trans>}
        filterType={sortingTypes.BY_GRID_CODE}
        filterSetter={filterSetter}
        checked={selectedFilter?.includes(sortingTypes.BY_GRID_CODE)}
      />
    </React.Fragment>
  );
}
