import React from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { ReactComponent as DeliveryIcon } from "../assets/svg/DeliveryTruck.svg";
import { StockIndicator } from "../features/stock";
import useFormatter from "../hooks/useFormatter";
import { useLatestSalesDate } from "../hooks/useLatestSalesDate";
import { useExpectedDelivery } from "../hooks/useExpectedDelivery";
import { useAvailableElevatedStock } from "../hooks/useAvailableElevatedStock";

const Span = styled.span`
  display: inline-block;
  font-weight: bold;
  span {
    color: var(--bti-red);
  }
`;
const SpanMargin = styled(Span)`
  margin-left: 8px;
`;
const ContainerPill = styled.div`
  display: flex;
  align-items: center;
`;
const Pill = styled.div`
  border: ${(props) => (props.noBorder ? "none" : "1px solid grey")};
  border-radius: ${(props) => (props.noBorder ? "0px" : "25px")};
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-right: 10px;
  height: 40px;
  padding: 0px 16px;
`;

export function StockInfoPills({ type, short_id, noBorder }) {
  const { t } = useTranslation();
  const full_id = `${type || ""}${short_id || ""}`;
  const { latestSalesDate } = useLatestSalesDate(full_id);

  const { available, elevated, loading, color } =
    useAvailableElevatedStock(full_id);

  const { formatNumber, formatDate } = useFormatter();
  const { nextDelivery, formattedDelivery } = useExpectedDelivery(short_id);

  function returnFormattedStockData(quantity) {
    if (!quantity && quantity !== 0) return "";
    return `${formatNumber(quantity)}`;
  }

  const formattedElevated = returnFormattedStockData(elevated); // This is "" initially, then becomes "0" or some other string of a number.

  return (
    <ContainerPill data-testid="stockInfoPills">
      {!loading && (
        <Pill noBorder={noBorder}>
          <StockIndicator color={color} marginRight={8} size={8} />
          <Span>
            {returnFormattedStockData(available)}
            {!!formattedElevated && <> ({formattedElevated})</>}
          </Span>
        </Pill>
      )}
      {nextDelivery && (
        <Pill noBorder={noBorder}>
          <DeliveryIcon />
          <SpanMargin>
            {formattedDelivery}
            {", "}
            {returnFormattedStockData(nextDelivery.quantity)}
            {t("pcsString")}
          </SpanMargin>
        </Pill>
      )}
      {latestSalesDate && (
        <Pill noBorder={noBorder}>
          <Span>
            <span>EDS: {formatDate(latestSalesDate)}</span>
          </Span>
        </Pill>
      )}
    </ContainerPill>
  );
}
