import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { Button } from "@coworker/components";
import tracker from "../../helpers/tracker";
import { isRDTDevice } from "@coworker/reusable";

import overview from "../../assets/images/dashcom-overview.png";
import allDevices from "../../assets/images/dashcom-all-devices.png";
import inspiration from "../../assets/images/dashcom-inspiration.png";

const Container = styled.div`
  margin-top: 70px;
  height: 100vh;
`;
const Banner = styled.div`
  background: var(--grey100);
  display: flex;
`;
const StartToday = styled.div`
  font-size: 18px;
  width: 100%;
  font-weight: bold;
  padding-left: 24px;
  margin: auto;
`;
const OverviewImage = styled.img`
  margin: 12px;
`;
const Followup = styled.div`
  font-size: 16px;
`;
const BlockText = styled.div`
  padding: 32px 24px;
`;
const BlockTextWithUrl = styled.div`
  padding: 32px 24px 0px 24px;
`;
const AllDevicesImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
`;
const Inspiration = styled.div`
  font-size: 16px;
  background: var(--grey50);
`;
const InspirationImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;
const Access = styled.div`
  font-size: 16px;
`;
const AccessHeadLine = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 38px 24px 0px 24px;
`;
const ClickableUrl = styled.div`
  text-decoration: underline;
  font-weight: bold;
  color: black;
  margin: 0px 24px;
`;
const Url = styled.a`
  font-weight: bold;
  color: black;
  margin: 0px 24px;
`;
const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 38px 24px;
`;
interface PopupProps {
  onClose: () => void;
}

export function InsightsStoreOverview({ onClose }: PopupProps) {
  const { t } = useTranslation();
  const isRDT = isRDTDevice();

  return (
    <Container>
      <Banner>
        <StartToday>
          <Trans>dashComModalStartToday</Trans>
        </StartToday>
        <OverviewImage src={overview} />
      </Banner>
      <Followup>
        <BlockText>
          <Trans>dashComModalFollowUp</Trans>
        </BlockText>
        <AllDevicesImage src={allDevices} />
      </Followup>
      <Inspiration>
        <BlockText>
          <Trans>dashComModalVisualize</Trans>
        </BlockText>
        <InspirationImage src={inspiration} />
        <BlockText>
          <Trans>dashComModalBenefits</Trans>
        </BlockText>
      </Inspiration>
      <Access>
        <AccessHeadLine>
          <Trans>dashComModalAccessHeadline</Trans>
        </AccessHeadLine>
        <BlockTextWithUrl>
          <Trans>dashComModalRequestAccess</Trans>
        </BlockTextWithUrl>
        <ClickableUrl
          onClick={() => {
            tracker.insightStoreOverview.trackLinkClicked("RequestAccess");
            if (!isRDT) {
              window.open("https://go.ikea.com/Dashboard-access", "_blank");
            }
          }}
        >
          https://go.ikea.com/Dashboard-access
        </ClickableUrl>
        <BlockTextWithUrl>
          <Trans>dashComModalVisitDashboard</Trans>
        </BlockTextWithUrl>
        <ClickableUrl
          onClick={() => {
            tracker.insightStoreOverview.trackLinkClicked("Dashboard");
            if (!isRDT) {
              window.open("https://fixadashboard.ingka.com", "_blank");
            }
          }}
        >
          https://fixadashboard.ingka.com
        </ClickableUrl>
        <BlockTextWithUrl>
          <Trans>dashComModalFeedback</Trans>
        </BlockTextWithUrl>
        <Url href="mailto:fixa.support@ingka.ikea.com">
          fixa.support@ingka.ikea.com
        </Url>
      </Access>
      <ButtonArea>
        <Button
          fullWidth
          noMargin
          primary
          dark
          text={t("okIUnderstandString")}
          onClick={() => {
            tracker.insightStoreOverview.trackGotItClicked();
            onClose();
          }}
        />
      </ButtonArea>
    </Container>
  );
}
