import React from "react";
import styled from "styled-components";
import { isFinishedState } from "../constants/taskState";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import {
  getLocationGridCode,
  getPickupGridCode,
  getAmounts,
  getAmountByProductUnit,
} from "../helpers/taskHelper";
import { useTranslation } from "@coworker/locales";
import useFormatter from "../hooks/useFormatter";

const Location = styled.span`
  ${(props) => props.bold && `font-weight: 700`}
`;

const TaskLocation = styled.p`
  font-size: 14px;
  line-height: 1.5;
  color: var(--grey700);
`;

function VisualizeCorrection({ now, expected, changed }) {
  return changed && now !== expected ? (
    <>
      <s>{expected}</s> {now}
    </>
  ) : (
    now
  );
}

/**
 * Returns location information for task based on type and state.
 * @param {Object} task task object
 * @param {Array<string>} addonFilterOption filter list for addon location.
 */
export function TaskCardLocation({ task, addonFilterOption = [] }) {
  const { t } = useTranslation();
  const { productUnit } = useFormatter();
  const withUnit = ([actual, amount, type]) => {
    const value = actual ?? getAmountByProductUnit(amount, type, task);
    return `${value} ${productUnit(type, value)}`;
  };
  const to = ` ${t("itemsToString")} `;
  const from = ` ${t("itemsFromString")} `;

  if (task.task_type === taskTypeOptions.ADDON) {
    const { requested, picked, refilled } = getAmounts(task);
    if (
      (task.picked || isFinishedState(task.state)) &&
      (!addonFilterOption || addonFilterOption.length < 1)
    ) {
      return (
        <TaskLocation>
          <VisualizeCorrection
            now={withUnit(refilled || picked || requested)}
            expected={withUnit(picked || requested)}
            changed={requested !== picked || refilled}
          />
          {to}
          <Location>{getLocationGridCode(task)}</Location>
        </TaskLocation>
      );
    } else if (picked) {
      return (
        <TaskLocation>
          <VisualizeCorrection
            now={withUnit(picked)}
            expected={withUnit(requested)}
            changed={picked !== requested}
          />
          {to}
          <Location
            data-testid="refilllocation"
            bold={addonFilterOption.includes("refillFilter")}
          >
            {getLocationGridCode(task)}
          </Location>
        </TaskLocation>
      );
    } else {
      return (
        <TaskLocation>
          {withUnit(requested)}
          {from}
          <Location
            data-testid="pickinglocation"
            bold={addonFilterOption.includes("pickingFilter")}
          >
            {getPickupGridCode(task)}
          </Location>
          {to}
          <Location
            data-testid="refilllocation"
            bold={addonFilterOption.includes("refillFilter")}
          >
            {getLocationGridCode(task)}
          </Location>
        </TaskLocation>
      );
    }
  } else if (task.task_type === taskTypeOptions.PRODUCT_ISSUE) {
    return <TaskLocation>{getLocationGridCode(task)}</TaskLocation>;
  } else if (task.task_type === taskTypeOptions.TESTBUY_FOLLOW_UP) {
    return (
      <TaskLocation>
        {`${t("atString")} ${getLocationGridCode(task)}`}
      </TaskLocation>
    );
  } else if (
    task.task_type === taskTypeOptions.ROOM_SETTINGS &&
    task.is_new_article
  ) {
    return (
      <TaskLocation>
        {withUnit([task.pieces, undefined, "pieces"])}
      </TaskLocation>
    );
  } else {
    return <TaskLocation />;
  }
}
