import { Trans } from "@coworker/locales";
import React from "react";
import styled from "styled-components";
import { Container, OverflowBackground } from "./CommonComponents";
import { SearchInput } from "./TestBuyAreas/SearchInput";
import { StoreList } from "../Insights/StoreList";

const RoundedContainer = styled(Container)`
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin: 24px;
`;

const SearchContainer = styled.div`
  margin: 24px;
`;

interface PopupProps {
  onClose: () => void;
  onSubmit: (store: object) => void;
  initialStore?: string;
}

export function StoreListPopup({
  onClose,
  onSubmit,
  initialStore,
}: PopupProps) {
  const [searchValue, setSearchValue] = React.useState<string>();
  const [selectedStore, setSelectedStore] = React.useState(initialStore);

  const onSelectedStore = React.useCallback(
    (store) => {
      setSelectedStore(store.id);
      onSubmit(store);
    },
    [onSubmit]
  );

  React.useEffect(() => {
    setSearchValue(searchValue);
  }, [searchValue]);

  return (
    <>
      <OverflowBackground data-testid="background" onClick={onClose} />
      <RoundedContainer data-testid="store-selector-popup">
        <Title>
          <Trans>selectStoreString</Trans>
        </Title>
        <SearchContainer data-testid="searchContainer">
          <SearchInput value={searchValue || ""} setValue={setSearchValue} />
        </SearchContainer>
        <StoreList
          query={searchValue}
          selected={selectedStore}
          onSelect={onSelectedStore}
        />
      </RoundedContainer>
    </>
  );
}
