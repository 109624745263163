import React from "react";

function formReducer(state, action) {
  switch (action.type) {
    case "editField":
      return {
        ...state,
        [action.field]: action.value,
      };
    case "resetForm":
      return action.init;
    case "editFields":
      return {
        ...state,
        ...action.values,
      };
    default:
      return state;
  }
}

function useForm(initial = {}) {
  const [state, dispatch] = React.useReducer(formReducer, initial);

  const editField = React.useCallback(
    (field, value) => dispatch({ type: "editField", field, value }),
    [dispatch]
  );
  const editFields = React.useCallback(
    (values) => dispatch({ type: "editFields", values }),
    [dispatch]
  );
  const resetForm = React.useCallback(
    (init) => dispatch({ type: "resetForm", init }),
    [dispatch]
  );

  return {
    form: state,
    editField,
    editFields,
    resetForm,
  };
}

export default useForm;
