import React from "react";
import styled, { css } from "styled-components";
import { PositiveNumberKeypad } from "../PositiveNumberKeypad";
import { Trans } from "@coworker/locales";
import MultiSelectCheckbox from "./../MultiSelectCheckbox";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 50px;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: var(--grey900);
  margin: 55px 29px;
  margin-bottom: 0px;
`;

const AmountWrapper = styled.input<{ $supplierNumberNotKnownChecked: boolean }>`
  font-size: 16px;
  line-height: 40px;
  width: 100%;
  padding: 0px 0px 0px 10px;
  ${({ $supplierNumberNotKnownChecked }) =>
    $supplierNumberNotKnownChecked
      ? css`
          border: 1px solid var(--grey200);
        `
      : css`
          border: 1px solid var(--grey900);
        `}
`;

const InputWrapper = styled.div`
  margin: 10px 40px 10px 29px;
`;

const CapInfo = styled.div`
  font-size: 12px;
  color: var(--grey600);
`;

const CapWarning = styled.div`
  font-size: 12px;
  color: var(--success);
`;

const UnknownCheckBoxWrapper = styled.div`
  display: flex;
  margin: 22px 0px;
  width: 100%;
`;

const CheckBoxTextWrapper = styled.div`
  vertical-align: center;
  font-size: 14px;
  width: 100%;
`;

const SUPPLIER_NUMBER_LENGTH = 5;

interface SupplierNumberValue {
  supplier_number: string;
  supplier_number_unknown: boolean;
}
interface SupplierNumberProps {
  value: SupplierNumberValue;
  bigTitle: string;
  instruction: React.ReactNode;
  onSubmit: (value: SupplierNumberValue) => void;
}

const SupplierNumber = ({
  value,
  bigTitle,
  instruction,
  onSubmit,
}: SupplierNumberProps) => {
  const [supplierNumber, setSupplierNumber] = React.useState<string>(
    value.supplier_number || ""
  );
  const containerRef = React.useRef(null);
  const [supplierNumberNotKnownChecked, setSupplierNumberNotKnownChecked] =
    React.useState<boolean>(value.supplier_number_unknown || false);

  const CapText = () =>
    supplierNumber.length < SUPPLIER_NUMBER_LENGTH ? (
      <CapInfo>
        <Trans values={{ length: SUPPLIER_NUMBER_LENGTH }}>maxNDigits</Trans>
      </CapInfo>
    ) : (
      <CapWarning>
        <Trans values={{ length: SUPPLIER_NUMBER_LENGTH }}>
          numberCanOnlyBeNDigits
        </Trans>
      </CapWarning>
    );

  const UnknownCheckbox = () => (
    <UnknownCheckBoxWrapper>
      <CheckBoxTextWrapper>
        <Trans>iDontHaveASuppliedNumber</Trans>
      </CheckBoxTextWrapper>
      <MultiSelectCheckbox
        checked={supplierNumberNotKnownChecked}
        onClick={() => {
          const newSupplierNumberNotKnown = !supplierNumberNotKnownChecked;
          setSupplierNumberNotKnownChecked(newSupplierNumberNotKnown);
          if (newSupplierNumberNotKnown) {
            setSupplierNumber("");
          }
        }}
        value={""}
      />
    </UnknownCheckBoxWrapper>
  );

  const KeyPadConfirmDisabled = () => {
    const hasInvalidValidValue =
      !supplierNumber || SUPPLIER_NUMBER_LENGTH !== supplierNumber.length;
    if (supplierNumberNotKnownChecked) {
      return false;
    }
    return hasInvalidValidValue;
  };

  return (
    <Container ref={containerRef}>
      <Title>{bigTitle}</Title>
      <InputWrapper>
        <AmountWrapper
          value={supplierNumber}
          readOnly
          $supplierNumberNotKnownChecked={supplierNumberNotKnownChecked}
        />
        <CapText />
        <UnknownCheckbox />
      </InputWrapper>
      <PositiveNumberKeypad
        initialValue={supplierNumber}
        disabled={KeyPadConfirmDisabled()}
        buttonsDisabled={supplierNumberNotKnownChecked}
        onUpdate={(updatedValue: string) => {
          if (!supplierNumberNotKnownChecked) {
            setSupplierNumber(updatedValue.slice(0, 11));
          }
        }}
        onComplete={() => {
          onSubmit({
            supplier_number: supplierNumber,
            supplier_number_unknown: supplierNumberNotKnownChecked,
          });
        }}
        maxLength={SUPPLIER_NUMBER_LENGTH}
        completeTestid="supplierNumberButton"
      />
      {instruction}
    </Container>
  );
};

export default SupplierNumber;
