import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { useInputPopup } from "../InputPopup";
import styled from "styled-components";
import { ReactComponent as ArrowForward } from "../../assets/svg/icon-arrow-small-forward-cropped.svg";
import tracker from "../../helpers/tracker";

const Container = styled.div`
  font-size: 14px;
  color: var(--white);
  background: var(--blue-medium);
  display: flex;
`;
const Inner = styled.div`
  padding: 24px;
  width: 100%;
`;
const Title = styled.div`
  font-weight: bold;
`;
const Arrow = styled.div`
  padding: 32px 12px;
`;
const WhiteArrowForward = styled(ArrowForward)`
  vertical-align: middle;
  color: var(--white);
`;

export const InsightsStoreOverviewBox = () => {
  const { t } = useTranslation();
  const { getInput } = useInputPopup();

  const getInsightsStoreOverview: () => void = () => {
    tracker.insightStoreOverview.trackBlueBannerClicked();
    getInput("insightsStoreOverview", { title: t("dashComModalAbout") });
  };

  React.useEffect(() => {
    tracker.insightStoreOverview.trackBlueBannerSeen();
  }, []);

  return (
    <Container onClick={getInsightsStoreOverview}>
      <Inner>
        <Title>
          <Trans>dashComLinkHeadline</Trans>
        </Title>
        <Trans>dashComLinkClarification</Trans>
      </Inner>
      <Arrow>
        <WhiteArrowForward />
      </Arrow>
    </Container>
  );
};
