export function isEqual(a: any, b: any): boolean {
  if (isObject(a) && isObject(b)) {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
    if (keysA.length !== keysB.length) {
      return false;
    }
    for (let i = 0; i < keysA.length; i++) {
      const key = keysA[i]!;
      if (!isEqual(a[key], b[key])) {
        return false;
      }
    }
    return true;
  }
  if (isArray(a) && isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (!isEqual(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }
  return a === b;
}

function isObject(x: any): boolean {
  return typeof x === "object" && !Array.isArray(x) && x !== null;
}

function isArray(x: any): boolean {
  return Array.isArray(x);
}

export function groupByProp(objects: { [key: string]: any }[], prop: string) {
  return groupByFunc(objects, (object) => object[prop]);
}

export function groupByFunc(
  objects: { [key: string]: any }[],
  reducer: (object: any) => any
) {
  const map: { [key: string]: any } = {};
  objects.forEach((object) => {
    const key = reducer(object);
    map[key] = map[key] || [];
    map[key].push(object);
  });
  return map;
}

export function setIn(
  object: { [key: string]: any },
  props: string[],
  value: any
) {
  return setInFunc(object, props, value, overwriter);
}

export function setInFunc(
  object: { [key: string]: any },
  props: string[],
  value: any,
  mutator: (object: { [key: string]: any }, key: string, value: any) => void
) {
  let current = object;
  for (let i = 0; i < props.length - 1; i++) {
    const prop = props[i]!;
    if (!isObject(current[prop])) {
      current[prop] = {};
    }
    current = current[prop];
  }
  mutator(current, props[props.length - 1]!, value);
}

function overwriter(object: { [key: string]: any }, key: string, value: any) {
  object[key] = value;
}

export function indexOverwriter(
  object: { [key: string]: any },
  key: string,
  value: any[]
) {
  const newArray = [...value];
  const oldArray = object[key];
  if (isArray(oldArray)) {
    for (let i = newArray.length; i < oldArray.length; i++) {
      newArray.push(oldArray[i]);
    }
    object[key] = newArray;
  } else {
    object[key] = value;
  }
}

export function merge(
  o1: { [key: string]: any } | null | undefined,
  o2: { [key: string]: any } | null | undefined
): object {
  const setters = [...createSetters([], [], o1), ...createSetters([], [], o2)];

  const result: { [key: string]: any } = {};

  setters.forEach(({ props, value }) => {
    setInFunc(
      result,
      props,
      value,
      isArray(value) ? indexOverwriter : overwriter
    );
  });

  return result;
}

function createSetters(
  props: string[],
  setters: { props: string[]; value: any }[],
  obj: { [key: string]: any } | null | undefined
) {
  if (isObject(obj)) {
    Object.keys(obj!).forEach((key) => {
      const newProps = [...props, key];
      const value = obj![key];
      if (value !== undefined && !isObject(value)) {
        setters.push({ props: newProps, value: value });
      } else {
        createSetters(newProps, setters, value);
      }
    });
  }
  return setters;
}
