import allPermissions from "@coworker/functions/src/enums/roles.json";
import store from "../config/store";

export function canPerformAction(action: string) {
  const state = store.getState();

  const roleKey = state.firebase.profile?.role_id;
  const permissions = allPermissions[roleKey as keyof typeof allPermissions];
  return !!permissions?.[action as keyof typeof permissions];
}

export const permissionActions = {
  UPDATE_TEAMS_TASK: "UPDATE_TEAMS_TASK",
  CLOSE_TEAMS_TASK: "CLOSE_TEAMS_TASK",
  RELEASE_TEAMS_TASK: "RELEASE_TEAMS_TASK",
  CREATE_ADD_ON_TASK: "CREATE_ADD_ON_TASK",
  CREATE_CUSTOM_TASK: "CREATE_CUSTOM_TASK",
  CREATE_PRODUCT_ISSUE_TASK: "CREATE_PRODUCT_ISSUE_TASK",
};
