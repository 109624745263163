import React from "react";
import {
  callInternalApi,
  getCachedInternalApi,
  getLatestCacheValue,
} from "./API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";

export function storeAmountSettings(shortId, storeId, amounts) {
  return callInternalApi("amount_settings/set", {
    shortId,
    amounts,
    store_id: storeId,
  });
}

const empty = {};

export function useProductAmountSettings(shortId) {
  const storeId = useStoreId();
  const method = `amount_settings?shortId=${shortId}`;
  const [data, setData] = React.useState(getLatestCacheValue(method));
  React.useEffect(
    () =>
      getCachedInternalApi(method, setData, 5, false, "", {
        store_id: storeId,
      }),
    [method, storeId]
  );
  return data?.data?.data || empty;
}
