import React from "react";
import { Trans } from "@coworker/locales";
import AvatarImage from "../../AvatarImage";
import {
  DetailSection,
  DetailSectionRight,
  DetailSectionLeft,
  SectionText,
  SectionTitle,
} from "./_common";
import { useStoreUsers } from "../../../hooks/useStoreContacts";

function Creator({ task, title }) {
  const users = useStoreUsers();
  const creator_name = React.useMemo(() => {
    const user = users.find(
      (user) =>
        user.fixa_uid === task.creator_id || user.uid === task.creator_id
    );
    return user ? (
      `${user.given_name} ${user.surname}`
    ) : (
      <Trans>unknownUserString</Trans>
    );
  }, [users, task.creator_id]);
  return (
    <DetailSection>
      <DetailSectionLeft>
        <SectionTitle>{title || <Trans>createdByString</Trans>}</SectionTitle>
        <SectionText data-testid="taskCreator">{creator_name}</SectionText>
      </DetailSectionLeft>
      <DetailSectionRight>
        <AvatarImage uid={task.creator_id} />
      </DetailSectionRight>
    </DetailSection>
  );
}

export default Creator;
