import React from "react";
import styled from "styled-components";
import { FixaEmailLink } from "../PrivacyStatementComponents";

const Container = styled.section``;
const TitleText = styled.section`
  font-weight: bold;
  padding-bottom: 0px;
`;
const DescriptionText = styled.section``;

function DividedView({ title, text }) {
  if (title === "" && text === "") return null;

  return (
    <Container>
      <TitleText>{title}</TitleText>
      <DescriptionText>{text}</DescriptionText>
    </Container>
  );
}

function FixaContact() {
  return (
    <p>
      Fixa team:&nbsp;
      <FixaEmailLink />
    </p>
  );
}

export function renderPolicy(policy) {
  const {
    title,
    aboutFixaText,
    personalDataCollectedTitle,
    personalDataCollectedText,
    purposeOfDataCollectionTitle,
    purposeOfDataCollectionText,
    legalGroundsTitle,
    legalGroundsText,
    dataWillBeStoredTitle,
    dataWillBeStoredText,
    dataControllerForPersonalDataTitle,
    dataControllerForPersonalDataText,
    rightToPersonalInformationText,
    multiLangeInformation,
  } = policy;

  return (
    <>
      {multiLangeInformation && <p>{multiLangeInformation}</p>}
      <h1>{title}</h1>
      {aboutFixaText}
      <DividedView
        title={personalDataCollectedTitle}
        text={personalDataCollectedText}
      />
      <DividedView
        title={purposeOfDataCollectionTitle}
        text={purposeOfDataCollectionText}
      />
      <DividedView title={legalGroundsTitle} text={legalGroundsText} />
      <DividedView title={dataWillBeStoredTitle} text={dataWillBeStoredText} />
      <DividedView
        title={dataControllerForPersonalDataTitle}
        text={dataControllerForPersonalDataText}
      />
      {rightToPersonalInformationText}
      <FixaContact />
    </>
  );
}
