import { Task } from "@coworker/types/lib/tasks/base";
import React from "react";
import styled from "styled-components";
import TaskPriorityWidget from "../../TaskForm/widgets/PriorityWidget";

interface PriorityProps {
  task: Pick<Task, "priority_flag">;
}
const CardContainer = styled.div`
  padding-bottom: 20px;
`;
function Priority({ task }: PriorityProps) {
  return (
    <CardContainer>
      <TaskPriorityWidget
        isPriority={task.priority_flag}
        togglePriority={() => {}}
      />
    </CardContainer>
  );
}

export default Priority;
