import { TasksFilterType } from "../types/tasksFilterType";
import { sortingTypes } from "@coworker/types/lib/taskSortingTypes";
import { taskStateFilterTypes } from "@coworker/types/lib/taskStateFilterTypes";
import { taskStatusFilterTypes } from "@coworker/types/lib/taskStatusFilterTypes";
import { closedReasonFilterTypes } from "@coworker/types/lib/closedReasonFilterTypes";
import { TaskTypesFilters } from "../enums/taskTypesFilters";
import { GroupByFilters } from "../enums/groupByFilters";

export const TASKS_FILTERS_DEFAULT: TasksFilterType = {
  taskType: [TaskTypesFilters.ALL],
  sortBy: sortingTypes.BY_NEWEST_FIRST,
  groupBy: GroupByFilters.NO_GROUPING,
  activityFilters: {
    closedReason: closedReasonFilterTypes.ALL_CLOSED_REASONS,
    completedTaskStatusType: taskStatusFilterTypes.ALL_COMPLETED_TASKS,
    selectedTeam: "",
  },
  locationFilter: {
    locationGroup: [],
    locationGroupName: [],
  },
  related: {
    taskState: taskStateFilterTypes.ALL_STATES,
  },
};
