import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: var(--white);
  padding-top: 20px;
  min-height: 49px;
  max-height: 49px;
  height: 49px;
  border-bottom: 1px solid var(--grey100);
  @media (min-width: 1440px) {
    font-size: 18px;
    padding-left: 70px;
    padding-bottom: 20px;
    padding-top: 80px;
  }
`;

const Title = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;
  @media (min-width: 740px) {
    margin-left: 32px;
  }
  @media (min-width: 1440px) {
    font-size: 18px;
  }
`;

const CreateHeader = (props) => (
  <Container>
    <Title>{props.title}</Title>
  </Container>
);

export default CreateHeader;
