import React from "react";
import styled, { css } from "styled-components";
import { useFormatRecurring } from "../hooks/useFormatRecurring";
import { Indicator, Icon } from "@coworker/components";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import { Trans } from "@coworker/locales";
import useFormatScheduled from "../hooks/useFormatScheduled";
import { hasChangedQuantity } from "../helpers/taskHelper";

const IndicatorContainer = styled.div`
  display: flex;
  & > div {
    margin-top: 19px;
  }
`;

const RepeatableContainer = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  color: var(--grey700);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  & svg {
    width: 10px;
    height: 10px;
    fill: var(--grey900);
    margin-right: 7px;
  }
`;

const WrappedIndicator = styled(Indicator)`
  ${({ blue }) =>
    blue &&
    css`
      color: var(--light-blue);
    `}
  ${({ green }) =>
    green &&
    css`
      color: var(--success);
    `}
    ${({ bluenew }) =>
    bluenew &&
    css`
      color: var(--new);
    `}
    ${({ red }) =>
    red &&
    css`
      color: var(--error);
    `}
  span {
    margin-left: 7px;
  }
`;

function OldRecurrentTask({ task }) {
  const { recurrenceStrings } = useFormatRecurring();
  const { timeAgo, recursOn } = recurrenceStrings(task);

  return (
    <RepeatableContainer>
      <Icon family="actions" name="recurring" size="15" />
      <div>
        {timeAgo}
        {" • "}
        {recursOn}
      </div>
    </RepeatableContainer>
  );
}

function PlannedTask({ task }) {
  const { startDate, previewHint } = useFormatScheduled(task.planned);
  return (
    <RepeatableContainer>
      {task.planned.recurring && (
        <Icon family="actions" name="recurring" size="15" />
      )}
      <div>
        {!task.planned.recurring && startDate}
        {task.planned.recurring && previewHint}
      </div>
    </RepeatableContainer>
  );
}

function RecurrenceComponent({ task }) {
  if (task.planned) {
    return <PlannedTask task={task} />;
  }
  return <OldRecurrentTask task={task} />;
}

export function Indicators({ task, hasNewNote }) {
  const isPlannedTask = task.task_type === taskTypeOptions.PLANNED;
  const isCompletedTask =
    task.state === taskStateOptions.COMPLETED &&
    task.task_type !== taskTypeOptions.PRODUCT_QUALITY;

  const isCompletedWithChanges = isCompletedTask && hasChangedQuantity(task);

  const isOngoingTask =
    [taskStateOptions.UNASSIGNED, taskStateOptions.ASSIGNED].includes(
      task.state
    ) && task.task_type === taskTypeOptions.PRODUCT_QUALITY;
  const isAcceptedTask =
    task.state === taskStateOptions.COMPLETED &&
    task.task_type === taskTypeOptions.PRODUCT_QUALITY;
  const isRejectedTask =
    task.state === taskStateOptions.CLOSED &&
    task.task_type === taskTypeOptions.PRODUCT_QUALITY;

  return (
    <IndicatorContainer>
      {isPlannedTask && <RecurrenceComponent task={task} />}
      {isCompletedTask && (
        <WrappedIndicator green>
          <Icon family="icons" name="check" color="success" size="24" />
          <span>
            {isCompletedWithChanges ? (
              <Trans>completedWithChangesTagString</Trans>
            ) : (
              <Trans>completedTagString</Trans>
            )}
          </span>
        </WrappedIndicator>
      )}
      {isOngoingTask && (
        <WrappedIndicator bluenew>
          <Icon family="icons" name="state_ongoing" color="success" size="13" />
          <span>
            <Trans>ongoingString</Trans>
          </span>
        </WrappedIndicator>
      )}
      {isAcceptedTask && (
        <WrappedIndicator green>
          <Icon
            family="icons"
            name="state_accepted"
            color="success"
            size="12"
          />
          <span>
            <Trans>acceptedString</Trans>
          </span>
        </WrappedIndicator>
      )}
      {isRejectedTask && (
        <WrappedIndicator red>
          <Icon
            family="icons"
            name="state_declined"
            color="success"
            size="12"
          />
          <span>
            <Trans>declinedString</Trans>
          </span>
        </WrappedIndicator>
      )}
      {hasNewNote && (
        <WrappedIndicator blue>
          <Icon family="icons" name="comment" color="light-blue" size="18" />
          {hasNewNote}
          <span>
            <Trans>new2String</Trans>
          </span>
        </WrappedIndicator>
      )}
    </IndicatorContainer>
  );
}
