import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Arrows_Chevron_Medium_Right } from "../assets/svg/arrow-small-forward.svg";
import { ReactComponent as Actions_Delete } from "../assets/svg/actions-delete-default.svg";
import { ReactComponent as Actions_Torch_On } from "../assets/svg/actions-torch-on.svg";
import { ReactComponent as Actions_Torch_Off } from "../assets/svg/actions-torch-off.svg";
import { ReactComponent as Actions_Refresh } from "../assets/svg/refresh.svg";
import { ReactComponent as Actions_Close_Small } from "../assets/svg/close-small.svg";
import { ReactComponent as Actions_Close_Large } from "../assets/svg/close-large.svg";
import { ReactComponent as Slash } from "../assets/svg/slash.svg";
import { ReactComponent as Repeatable } from "../assets/svg/recurring.svg";
import { ReactComponent as Comment } from "../assets/svg/comment.svg";
import { ReactComponent as Check } from "../assets/svg/check.svg";
import { ReactComponent as LinkOut } from "../assets/svg/link-out.svg";
import { ReactComponent as StateOngoing } from "../assets/svg/state_ongoing.svg";
import { ReactComponent as StateAccepted } from "../assets/svg/state_accepted.svg";
import { ReactComponent as StateRejected } from "../assets/svg/state_declined.svg";
import { ReactComponent as Wrench } from "../assets/svg/wrench.svg";
import { ReactComponent as Pencil } from "../assets/svg/pencil.svg";
import { ReactComponent as MagnifyingGlass } from "../assets/svg/magnifying_glass.svg";
import { ReactComponent as Calendar } from "../assets/svg/calendar.svg";
import { ReactComponent as Truck } from "../assets/svg/truck.svg";
import { ReactComponent as Download } from "../assets/svg/arrow_down_to_base.svg";
import { ReactComponent as RDTScanner } from "../assets/svg/rdt_scanner.svg";
import { ReactComponent as Globe } from "../assets/svg/globe.svg";
import { ReactComponent as Camera } from "../assets/svg/camera.svg";
import { ReactComponent as RectangleHorizontal } from "../assets/svg/rectangle-horizontal.svg";
import { ReactComponent as RectangleHorizontalDouble } from "../assets/svg/rectangle-horizontal-double.svg";
import { ReactComponent as ExclamationSign } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { ReactComponent as Description } from "../assets/svg/comment.svg";
import { ReactComponent as Merge } from "../assets/svg/icon-arrow-merge.svg";
import { ReactComponent as Location } from "../assets/svg/location.svg";
import { ReactComponent as Sofa } from "../assets/svg/sofa.svg";
import { ReactComponent as Flag } from "../assets/svg/flag.svg";

const Wrapper = styled.div`
  width: ${({ size }) => `${size}px`};
  ${(props) =>
    props.className === "removeHeight"
      ? ""
      : css`
          height: ${props.size}px;
        `}
  color: var(${({ color }) => `--${color}`});
  display: inline-block;
  & > svg {
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    max-width: 100%;
    max-height: 100%;
  }
`;

export const library = {
  arrows: {
    "chevron-right": Arrows_Chevron_Medium_Right,
    slash: Slash,
  },
  actions: {
    delete: Actions_Delete,
    "torch-on": Actions_Torch_On,
    "torch-off": Actions_Torch_Off,
    refresh: Actions_Refresh,
    "close-small": Actions_Close_Small,
    "close-large": Actions_Close_Large,
    recurring: Repeatable,
  },
  icons: {
    comment: Comment,
    check: Check,
    linkOut: LinkOut,
    state_accepted: StateAccepted,
    state_declined: StateRejected,
    state_ongoing: StateOngoing,
    wrench: Wrench,
    pencil: Pencil,
    magnifying_glass: MagnifyingGlass,
    truck: Truck,
    calendar: Calendar,
    download: Download,
    rdt_scanner: RDTScanner,
    globe: Globe,
    camera: Camera,
    rectangle_horizontal: RectangleHorizontal,
    rectangle_horizontal_double: RectangleHorizontalDouble,
    warning_triangle: ExclamationSign,
    description: Description,
    merge: Merge,
    location: Location,
    sofa: Sofa,
    flag: Flag,
  },
};

/**
 * @param {{family:string,name:string,mode?:string,color?:string,size?:number,className?:string}} param0
 */
export default function Icon({
  family,
  name,
  mode = "default",
  color = "black",
  size = 16,
  className,
}) {
  const Icon = library[family]?.[name] || (() => "(icon)");
  return (
    <Wrapper color={color} className={className} size={size}>
      <Icon width={size} height={size} />
    </Wrapper>
  );
}
