import { useQuery } from "@tanstack/react-query";
import { getRoomsAndArticleCount } from "../services/rooms.service";

export const useRoomsAndArticleCount = (
  productArticleId: string | undefined,
  storeId: string | undefined
) => {
  return useQuery({
    queryKey: ["roomsAndArticleCount", productArticleId, storeId],
    queryFn: () =>
      getRoomsAndArticleCount(productArticleId || "", storeId || ""),
    enabled: !!productArticleId && !!storeId,
  });
};
