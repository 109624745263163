import {
  HEADER,
  LOCATION,
  DESCRIPTION,
  ASSIGNMENT,
  ACTIVITY,
  SPACER,
} from "./_common";

import { PickUpTask, CompleteTask } from "../actions/index";
import { SCHEDULE, REPEATABLE_CHILD } from "../widgets";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fields: [
    HEADER,
    DESCRIPTION(),
    LOCATION(),
    ASSIGNMENT(),
    { type: SCHEDULE },
    { type: REPEATABLE_CHILD },
    ACTIVITY,
    SPACER(90), // Workaround for RDT: Same height as bottom action bar
  ],
  actions: [PickUpTask, CompleteTask],
};
