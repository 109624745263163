import { isRDTDevice } from "@coworker/reusable";

// Format number according to http://www.ietf.org/rfc/rfc3966.txt
const formatTelLink = (number_string) => `tel:${number_string}`;

export function useTelephoneLinker() {
  return (string) =>
    isRDTDevice() || !string
      ? undefined
      : () => window.open(formatTelLink(string));
}
