import dayjs from "dayjs";
import type { TestBuy } from "@coworker/types";

export const isDateWithinLastWeek = (date?: number) =>
  dayjs(date).startOf("day").isAfter(dayjs().startOf("day").subtract(7, "day"));

export function calculatePassRatio(
  totalCompletedTestBuys: TestBuy[],
  totalArticlesCount: number
) {
  let failedArticlesCount = 0;

  for (const testBuy of totalCompletedTestBuys) {
    if (testBuy && testBuy?.articles) {
      const { articles } = testBuy;
      for (const article of articles) {
        if (article.criteria.some((criteria) => criteria.state !== "OK")) {
          failedArticlesCount++;
        }
      }
    }
  }

  const testbuyFailedRatio =
    totalCompletedTestBuys.length > 0
      ? failedArticlesCount / totalArticlesCount
      : 1;

  return (1 - testbuyFailedRatio) * 100;
}
