import React from "react";

/**
 * Makes it possible to toggle a value between a falsy value and any non-falsy value.
 * @param value Used for initialization. If non-falsy, it's used verbatim. If falsy, we initialize with null instead.
 */
const useNullToggler = (initialValue) => {
  const [checked, setChecked] = React.useState(initialValue || null);

  const toggleWithValue = (value) => {
    if (checked === value) {
      setChecked(null);
    } else {
      setChecked(value);
    }
  };

  return { checked, onClick: toggleWithValue };
};

export default useNullToggler;
