// eslint-disable-next-line no-restricted-imports
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const { lazyTranslations } = require("../translations");
const en_GB = require("../en-GB/translations.json");

class WebpackyBackend {
  read(language, namespace, callback) {
    const loader = lazyTranslations[language];
    if (!loader) return callback(null, true); // language missing
    loader()
      .then((res) => callback(null, res.default.translations))
      .catch((err) => callback(err, false));
  }
}
WebpackyBackend.type = "backend";

const resources = {
  "en-GB": en_GB,
  blabla: {
    translations: new Proxy({}, { get: () => "blabla" }), // Use "blabla" language for testing only.
  },
};

i18n
  .use(initReactI18next)
  .use(WebpackyBackend)
  .init({
    resources,
    lng: "en-GB",
    fallbackLng: "en-GB",
    load: "currentOnly", // avoids loading "en" right after loading "en-GB"
    compatibilityJSON: "v3",

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    partialBundledLanguages: true,
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true, // together with the below, only the listed HTML elements are allowed.
      transKeepBasicHtmlNodesFor: ["br", "b"], // https://react.i18next.com/latest/trans-component#using-with-react-components
    },

    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });

export default i18n;
