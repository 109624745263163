import { getTokenAsync } from "../../../hooks/API/getAuthToken";
import { makeHeaders } from "../tools";
import { Articles } from "../types/article";
import {
  AlertResponse,
  PartialRoom,
  RoomResponse,
  RoomsAndArticleCountResponse,
  RoomsResponse,
} from "../types/room";
import { ROOM_SETTINGS_SERVICE_URL } from "./service.helper";

const BASE_URL = ROOM_SETTINGS_SERVICE_URL + "/rooms";

export const getRoomsByStoreId = async (storeId: string) => {
  try {
    const token = await getTokenAsync(true);
    if (!token || typeof token !== "string") {
      return [];
    }
    const response = await fetch(
      `${BASE_URL}/stores/${encodeURIComponent(storeId)}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as RoomsResponse;
    return data.rooms || {};
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return [];
};

export const getArticlesByRoomId = async (
  roomId: string,
  active?: "ACTIVE" | "INACTIVE" | undefined
) => {
  try {
    const token = await getTokenAsync(true);
    if (!token || typeof token !== "string") {
      return [];
    }
    const response = await fetch(`${BASE_URL}/${roomId}/articles`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (response.status === 404) return [];

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const articles = (await response.json()) as Articles;
    if (!active) return articles;
    return articles.filter((a) => a.active === active);
  } catch (error) {
    console.error("Fetch error:", error);
    return [];
  }
};

export const getArticlesByProductArticleId = async (
  roomId: string,
  productArticleId: string
) => {
  try {
    const token = await getTokenAsync(true);
    if (!token || typeof token !== "string") {
      return [];
    }
    const response = await fetch(
      `${BASE_URL}/${roomId}/articles/product_article_id/${productArticleId}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );

    if (response.status === 404) return [];

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const articles = (await response.json()) as Articles;
    return articles;
  } catch (error) {
    console.error("Fetch error:", error);
    return [];
  }
};

export const getRoomById = async (roomId: string) => {
  try {
    const token = await getTokenAsync(true);
    if (!token || typeof token !== "string") {
      return null;
    }
    const response = await fetch(`${BASE_URL}/${roomId}`, {
      method: "GET",
      headers: makeHeaders(token),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const room = (await response.json()) as RoomResponse;
    return room.room;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const getRoomsAndArticleCount = async (
  productArticleId: string,
  storeId: string
) => {
  try {
    const token = await getTokenAsync(true);
    if (!token || typeof token !== "string") {
      return null;
    }
    const response = await fetch(
      `${BASE_URL}/${encodeURIComponent(storeId)}/articles/${productArticleId}`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = (await response.json()) as RoomsAndArticleCountResponse[];
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return null;
};

export const updateRoom = async (roomId: string, roomData: PartialRoom) => {
  try {
    const token = await getTokenAsync(true);
    if (!token || typeof token !== "string") {
      return null;
    }
    const response = await fetch(`${BASE_URL}/${roomId}`, {
      method: "PUT",
      headers: makeHeaders(token),
      body: JSON.stringify(roomData),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as string;
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }

  return null;
};

export const deleteRoom = async (roomId: string) => {
  try {
    const token = await getTokenAsync(true);
    if (!token || typeof token !== "string") {
      return null;
    }
    const response = await fetch(`${BASE_URL}/${roomId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const data = (await response.json()) as string;
    return data;
  } catch (error) {
    console.error("Delete error:", error);
  }

  return null;
};

export const getAlerts = async (storeId: string) => {
  try {
    const token = await getTokenAsync(true);
    if (!token || typeof token !== "string") {
      return;
    }
    const response = await fetch(
      `${BASE_URL}/${encodeURIComponent(storeId)}/articles/alerts`,
      {
        method: "GET",
        headers: makeHeaders(token),
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }
    const data = (await response.json()) as AlertResponse[];
    data.forEach((alert) => {
      alert.eds = 0;
    }); // TODO: Unhide EDS by removing this section
    return data;
  } catch (error) {
    console.error("Fetch error:", error);
  }
  return [] as AlertResponse[];
};
