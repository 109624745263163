import React from "react";
import {
  callInternalApi,
  getCachedInternalApi,
  getLatestCacheValue,
} from "./API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";

const empty = [];

export function useUnreads() {
  const storeId = useStoreId();
  const [data, setData] = React.useState(getLatestCacheValue("my/unreads"));
  React.useEffect(
    () =>
      getCachedInternalApi(
        "my/unreads",
        setData,
        120_000,
        false,
        "CORE_SERVICE",
        {
          store_id: storeId,
        }
      ),
    [storeId]
  );
  return data?.data?.list || empty;
}

export function useHasNewNotesCheck() {
  const notes = useUnreads();
  return React.useCallback(
    (task) => notes.some((note) => note.task_id === task.id),
    [notes]
  );
}

export function useClearTaskUnreads(taskId) {
  const storeId = useStoreId();
  React.useEffect(
    () =>
      callInternalApi(`my/unreads/clear?id=${taskId}`, { store_id: storeId }),
    [taskId, storeId]
  );
}
