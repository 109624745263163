import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { useInputPopup } from "../InputPopup";
import { ReactComponent as Question } from "../../assets/svg/help.svg";
import styled from "styled-components";
import tracker from "../../helpers/tracker";

const Container = styled.div`
  font-size: 14px;
  background: var(--white);
  max-width: 100vw;
  padding: 17px 21px;
`;
const Inner = styled.div`
  padding: 20px;
  background: var(--grey100);
  border-radius: 5px;
`;
const Top = styled.div`
  font-weight: bold;
  display: flex;
`;
const Title = styled.div`
  margin-left: 22px;
`;
const Bottom = styled.div`
  margin-left: 42px;
`;

const HomeAndAwayBox = ({ status, location, hfb, level }) => {
  const { t } = useTranslation();

  const displayHFB = (hfb) => {
    if (!hfb) return t("allHFBsString");
    if (hfb === "OT") return t("otherHFBsString");

    return `HFB ${hfb}`;
  };
  const hfbToDisplay = displayHFB(hfb);
  const home = status === "HOME";
  const away = status === "AWAY";
  const { getInput } = useInputPopup();
  const both = !home && !away;
  const getHomeAndAwayHelp = () => {
    tracker.insights.trackHomeAwayInfo(level);
    getInput("homeAndAway", { title: t("whatIsHomeString") });
  };

  // if we do not have HFB specified, we do not need to display this box as Away/Home concept does not apply for All departments
  if (!hfb && level > 1) {
    return null;
  }

  const title = () => {
    if (both) {
      return <Trans>whatIsHomeString</Trans>;
    } else if (!!home) {
      return (
        <>
          {location ? (
            <Trans>locationsString</Trans>
          ) : (
            <Trans>articlesString</Trans>
          )}{" "}
          <Trans>homeBoxTitleString</Trans>
        </>
      );
    } else if (!!away) {
      return (
        <>
          {location ? (
            <Trans>locationsString</Trans>
          ) : (
            <Trans>articlesString</Trans>
          )}{" "}
          <Trans>awayBoxTitleString</Trans>
        </>
      );
    }
  };

  const content = (hfb) => {
    if (both) {
      return <Trans>introducingString</Trans>;
    } else if (!!home) {
      return (
        <Trans i18nKey="homeBoxContentString" hfb={hfb}>
          All add-ons sold in {{ hfb }}
        </Trans>
      );
    } else if (!!away) {
      return (
        <Trans i18nKey="awayBoxContentString" hfb={hfb}>
          All add-ons from {{ hfb }}, sold outside of {{ hfb }}.
        </Trans>
      );
    }
  };

  return (
    <Container onClick={getHomeAndAwayHelp} data-testid="home-away-container">
      <Inner>
        <Top>
          <Question />
          <Title data-testid="home-away-box-title">{title()}</Title>
        </Top>
        <Bottom>
          <div data-testid="home-away-box-content">{content(hfbToDisplay)}</div>
          <u>
            {both ? (
              <Trans>readMoreString</Trans>
            ) : home ? (
              <div>
                <Trans>learnMoreString</Trans>
              </div>
            ) : (
              <Trans>learnMoreString</Trans>
            )}
          </u>
        </Bottom>
      </Inner>
    </Container>
  );
};

export default HomeAndAwayBox;
