import { Contact } from "@coworker/types";

export const filterContactFromSearch = (
  contact: Contact,
  searchString?: string | null | undefined
) => {
  if (searchString) {
    const lowerCaseSearchString = searchString.toLocaleLowerCase();
    return (
      contact.name.toLocaleLowerCase().includes(lowerCaseSearchString) ||
      contact.phone_number.toLocaleLowerCase().includes(lowerCaseSearchString)
    );
  }
  return true;
};

export const virtualStoreRegex = /[$#]/;
