import { useQuery } from "@tanstack/react-query";
import { getArticlesByProductArticleId } from "../services/rooms.service";

export const useArticlesByProductArticleId = (
  roomId: string | undefined,
  productArticleId: string | undefined
) => {
  return useQuery({
    queryKey: [
      "articlesByRoomAndProductArticleId",
      { roomId, productArticleId },
    ],
    queryFn: () =>
      getArticlesByProductArticleId(roomId || "", productArticleId || ""),
    enabled: !!roomId && !!productArticleId,
  });
};
