import React from "react";
import { MainContentScrollDirectionContext } from "../../context/MainContentScrollDirectionContextProvider";
import { useScrollDirection } from "@coworker/app/src/hooks/useScrollDirection";
import { useLocation } from "react-router-dom";
import styles from "./main-content.module.css";

interface MainContentProps {
  children: React.ReactNode;
}

const MainContent = ({ children }: MainContentProps) => {
  const { setScrollDirection } = React.useContext(
    MainContentScrollDirectionContext
  );

  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollTarget] = useScrollDirection();
  const location = useLocation();

  React.useEffect(() => {
    if (!scrollContainerRef.current) return;

    setScrollTarget(scrollContainerRef.current);
  }, [scrollContainerRef, setScrollTarget]);

  React.useEffect(() => {
    setScrollDirection(scrollDirection);
  }, [setScrollDirection, scrollDirection]);

  const isHomePage = location.pathname === "/" || location.pathname === "/home";

  return (
    <div
      className={`${styles["main-content"]} ${
        isHomePage && styles["grey-background"]
      }`}
      ref={scrollContainerRef}
      id="main-content"
    >
      {children}
    </div>
  );
};

export default MainContent;
