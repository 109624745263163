import React from "react";
import { GreyListWrapper, PaddedContainer } from "../styles/styles";
import { Text } from "./Text";

interface GreyStripProps {
  text: string;
}

export function GreyStrip({ text }: GreyStripProps) {
  return (
    <GreyListWrapper>
      <PaddedContainer $py={"8px"} $px={"24px"}>
        <Text text={text} bold />
      </PaddedContainer>
    </GreyListWrapper>
  );
}
