import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";

import {
  PQDescribeTheProblemOptions,
  PQDescribeTheProblemTag,
} from "@coworker/types/lib/tasks/constants";
import { DescribeTheProblemProps } from "../../types/describeTheProblem";
import {
  DescribeTheProblemList,
  OptionListItem,
  TagTranslationKey,
} from "../../constants/DescribeTheProblemList";
import { getTypeListEntry } from "../../helpers/describeTheProblem";

import { PleaseFillIn } from "../Reusable/PleaseFillIn";
// Components
import { ReactComponent as PencilIcon } from "../../assets/svg/pencil.svg";

// Skapa components
import Button from "@ingka/button";
import TextArea from "@ingka/text-area";
import Pill from "@ingka/pill";

// Skapa styles
import "@ingka/svg-icon/dist/style.css";
import "@ingka/choice/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/pill/dist/style.css";
import ListView, { ListViewItem } from "@ingka/list-view";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 50px;
  overflow-y: auto;
`;
const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: var(--grey900);
  margin: 24px;
  margin-bottom: 0px;
`;
const ScrollContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
`;
const StyledListView = styled(ListView)`
  display: flex;
  flex-direction: column;
`;
const StyledListViewItem = styled(ListViewItem)`
  display: flex;
  span {
    text-decoration: none !important;
  }
`;
const MetaText = styled.div`
  font-size: 14px;
  color: var(--grey718);
  margin: 0px 24px;
`;
const TypeChangeArea = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 32px;
  margin: 0px 24px;
`;
const TypeText = styled.div`
  font-size: 14px;
  font-color: var(--grey718);
  text-align: left;
  text-decoration: underline;
  margin: 4px 8px 0px 0px;
`;
const StyledPencilIcon = styled(PencilIcon)`
  width: 14px;
  height: 14px;
  margin-top: 8px;
`;
const TextAreaContainer = styled.div`
  margin: 12px 24px;
`;
const StyledTextArea = styled(TextArea)`
  margin: 10px 0;
`;
const PleaseFillInArea = styled.div`
  margin: 0px 24px 12px 24px;
`;
const FormWarningArea = styled.div`
  margin-top: 16px;
`;
const PillStack = styled.div`
  margin: 16px;
`;
const StyledPill = styled(Pill)`
  margin: 4px;
`;
const StyledButton = styled(Button)`
  margin: 0px 24px 8px 24px;
`;

export const DescribeTheProblem = ({
  value,
  testParams,
  onSubmit,
}: DescribeTheProblemProps) => {
  const { t } = useTranslation();

  // States
  const [selectedType, setSelectedType] =
    React.useState<PQDescribeTheProblemOptions>(
      value.pq_describe_the_problem_type
    );

  const [pageNumber, setPageNumber] = React.useState<number>(
    testParams?.pageNumber || 1
  );
  const [buttonPressed, setButtonPressed] = React.useState<boolean>(
    testParams?.buttonPressed || false
  );
  const [description, setDescription] = React.useState<string>(
    value.description
  );
  const [tags, setTags] = React.useState<PQDescribeTheProblemTag[]>(
    value.pq_describe_the_problem_tags ?? new Array<PQDescribeTheProblemTag>()
  );

  // Methods
  function toggleTags(tag: PQDescribeTheProblemTag) {
    const filteredTags = tags.filter(
      (tagItem: PQDescribeTheProblemTag) => tagItem !== tag
    );
    if (filteredTags.length === tags.length) {
      filteredTags.push(tag);
    }
    setTags(filteredTags);
  }

  function pillIsSelected(tag: PQDescribeTheProblemTag): boolean {
    const matchingTag = tags.find(
      (tagItem: PQDescribeTheProblemTag) => tagItem === tag
    );
    return !!matchingTag;
  }

  function turnToPage(number: number) {
    if (number !== pageNumber) {
      setPageNumber(number);
      setButtonPressed(false);
    }
  }

  function choiceClicked(item: OptionListItem) {
    if (item.type !== selectedType) {
      setTags(new Array<PQDescribeTheProblemTag>());
    }
    setSelectedType(item.type);
  }

  function firstButtonPressed() {
    setButtonPressed(true);
    if (selectedType) {
      turnToPage(2);
    }
  }

  function secondButtonPressed() {
    setButtonPressed(true);
    if (description) {
      value.pq_describe_the_problem_type = selectedType;
      value.description = description;
      value.pq_describe_the_problem_tags = tags;
      onSubmit(value);
    }
  }

  const typeTranslationKey =
    selectedType && getTypeListEntry(selectedType)?.translationKey;

  // Components
  const firstPageComponent = (
    <Container>
      <ScrollContainer>
        <Title>
          <Trans>pqdSelectTypeOfProblem</Trans>
        </Title>
        <StyledListView id="listviewid" size="small">
          {DescribeTheProblemList.map((item: OptionListItem) => (
            <StyledListViewItem
              id={item.type}
              key={item.type}
              inset
              onChange={() => choiceClicked(item)}
              control="radiobutton"
              controlProps={{
                value: selectedType === item.type ? "ON" : "OFF",
                checked: selectedType === item.type ? true : false,
              }}
              title={t(item.translationKey)}
              data-testid={item.type}
            />
          ))}
        </StyledListView>
      </ScrollContainer>
      <FormWarningArea>
        <PleaseFillIn
          show={!selectedType && buttonPressed}
          alternateWarning="pleaseMakeASelectionString"
          centerText={true}
          testId="pleaseMakeASelectionWarning"
        />
      </FormWarningArea>
      <StyledButton
        text={t("continue2String")}
        type="primary"
        onClick={() => firstButtonPressed()}
        data-testid={"continueFirstPage"}
      />
    </Container>
  );

  const secondPageComponent = (
    <Container>
      <ScrollContainer>
        <Title>
          <Trans>describeProblemString</Trans>
        </Title>
        <TypeChangeArea onClick={() => turnToPage(1)}>
          <TypeText>
            <Trans>{typeTranslationKey}</Trans>
          </TypeText>
          <StyledPencilIcon />
        </TypeChangeArea>
        <TextAreaContainer>
          <StyledTextArea
            label={t("description4String")}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            data-testid={"descriptionTextArea"}
          />
        </TextAreaContainer>
        <PleaseFillInArea>
          <PleaseFillIn
            show={!description && buttonPressed}
            alternateWarning={`${t("pleaseFillInString")} ${t(
              "description4String"
            )}`}
            testId="fillInDescriptionWarning"
          />
        </PleaseFillInArea>
        <MetaText>
          <Trans>optionalColonString</Trans>
        </MetaText>
        <MetaText>
          <Trans>pqdSelectTagsThatMatchTheProblem</Trans>
        </MetaText>
        <PillStack>
          {selectedType &&
            DescribeTheProblemList.find(
              (item: OptionListItem) => item.type === selectedType
            )?.tags.map((tag: TagTranslationKey) => (
              <StyledPill
                key={tag.tag}
                label={t(tag.translationKey)}
                onClick={() => toggleTags(tag.tag)}
                selected={pillIsSelected(tag.tag)}
                size="small"
                data-testid={tag.tag}
              />
            ))}
        </PillStack>
      </ScrollContainer>
      <FormWarningArea>
        <PleaseFillIn
          show={!description && buttonPressed}
          alternateWarning="fillInMandatoryFieldString"
          centerText={true}
          testId="fillMandatoryFieldsWarning"
        />
      </FormWarningArea>
      <StyledButton
        text={t("continue2String")}
        type="primary"
        onClick={() => secondButtonPressed()}
        data-testid={"continueSecondPage"}
      />
    </Container>
  );

  return pageNumber === 1 ? firstPageComponent : secondPageComponent;
};
