import React from "react";
import ListView, { ListViewItem, ListViewProps } from "@ingka/list-view";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/aspect-ratio-box/dist/style.css";
import "@ingka/image/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/list-view/dist/style.css";
import "@ingka/focus/dist/style.css";
import { Trans } from "@coworker/locales";
import { ShoppingToolType } from "../constants";
import styled, { AnyStyledComponent, css } from "styled-components";
interface ShoppingToolsListViewProps {
  listViewProps: ListViewProps;
  itemList: ShoppingToolType[];
  setChosenTool?: React.Dispatch<React.SetStateAction<string>>;
  setChecked?: React.Dispatch<React.SetStateAction<boolean>>;
  checked?: boolean;
  chosenTool?: string;
  borderColor: string;
}

const StyledListViewItem = styled(ListViewItem as AnyStyledComponent)<{
  borderColor: string;
}>`
  padding: 0 1rem;
  border-bottom: none;
  ${(props) =>
    css`
      border-left: 8px solid ${props.borderColor};
    `}
  box-shadow: 0 1px 0 0 #DFDFDF inset;
`;

export const ShoppingToolsListView = ({
  listViewProps,
  itemList,
  setChosenTool,
  setChecked,
  checked,
  chosenTool,
  borderColor,
}: ShoppingToolsListViewProps) => {
  return (
    <ListView id={listViewProps.id} size="small">
      {itemList.map((item) => {
        return (
          <StyledListViewItem
            key={item.label}
            borderColor={borderColor}
            control={item.control}
            leadingIcon={item.icon}
            title={<Trans>{item.label}</Trans>}
            controlProps={{
              checked: item.name === chosenTool || checked ? true : false,
              value: item.name,
            }}
            onChange={() => {
              setChosenTool && setChosenTool(item.name);
              setChecked && setChecked(!checked);
            }}
          />
        );
      })}
    </ListView>
  );
};
