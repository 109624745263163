import React from "react";
import { useTranslation } from "@coworker/locales";
import { Pill } from "@coworker/components";
import styled from "styled-components";
import { groupByTypes, groupByTypesTranslations } from "../constants/groupBy";
import { Trans } from "@coworker/locales";
import {
  showGroupByFilters,
  showGroupGridCodeFilter,
  showGroupPackageFilter,
  showGroupTaskFilter,
} from "../helpers/taskGrouping";

const Title = styled.div`
  margin: 10px 29px;
  font-weight: bold;
  font-size: 18px;
`;

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 12px 0px 12px 25px;
`;

export const TaskGroupBy = ({
  activeSelected,
  setActiveSelected,
  selectedFilter,
}) => {
  const { t } = useTranslation();

  if (!showGroupByFilters(selectedFilter)) return null;

  const isActive = (selectedType) => {
    return activeSelected === selectedType;
  };

  const onSelect = (selectedType) => {
    isActive(selectedType)
      ? setActiveSelected(groupByTypes.NO_GROUPING)
      : setActiveSelected(selectedType);
  };

  return (
    <>
      <Title>
        <Trans>groupByTitleString</Trans>
      </Title>
      <PillContainer>
        {showGroupTaskFilter(selectedFilter) && (
          <Pill
            text={t(groupByTypesTranslations.BY_TASK_TYPE)}
            active={isActive(groupByTypes.BY_TASK_TYPE)}
            onClick={() => onSelect(groupByTypes.BY_TASK_TYPE)}
          />
        )}

        {showGroupGridCodeFilter(selectedFilter) && (
          <Pill
            text={t(groupByTypesTranslations.BY_GRID_CODE)}
            active={isActive(groupByTypes.BY_GRID_CODE)}
            onClick={() => onSelect(groupByTypes.BY_GRID_CODE)}
          />
        )}

        {showGroupPackageFilter(selectedFilter) && (
          <Pill
            text={t(groupByTypesTranslations.BY_PACKAGE_TYPE)}
            active={isActive(groupByTypes.BY_PACKAGE_TYPE)}
            onClick={() => onSelect(groupByTypes.BY_PACKAGE_TYPE)}
          />
        )}
      </PillContainer>
    </>
  );
};
