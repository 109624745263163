import React from "react";
import styled, { css } from "styled-components";

const Price = styled.span<{
  isBreathTakingItem: boolean;
  isSecondary: boolean | undefined;
}>`
  position: relative;
  height: 24px;
  font-size: ${({ isSecondary }) => (isSecondary ? "12px" : "18px")};
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: var(--grey900);
  & > span {
    height: 14px;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    vertical-align: top;
    margin-left: 1px;
  }
  & > span:nth-child(2) {
    vertical-align: baseline;
  }
  ${({ isBreathTakingItem }) =>
    isBreathTakingItem &&
    css`
      background: var(--bti-yellow);
      padding: 6px;
      &:before {
        width: 100%;
        height: 100%;
        content: "";
        background: var(--bti-red);
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: -1;
      }
    `}
`;
const NoPrice = styled.div`
  color: var(--grey700);
  font-weight: bold;
`;

const OriginalPrice = styled.span<{ isSecondary: boolean | undefined }>`
  font-size: ${({ isSecondary }) => (isSecondary ? "10px" : "14px")};
  line-height: 1.5;
  color: var(--grey700);
  text-decoration: line-through;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function splitPrice(price?: number): [string, string] {
  if (price == null || isNaN(price) || price < 0) return ["", ""];
  const whole = Math.trunc(price);
  if (price - whole < 0.01) return [whole.toFixed(0), ""];
  return [whole.toFixed(0), price.toFixed(2).slice(-2)];
}

interface PriceBadgeProps {
  price: number | undefined;
  currency: string;
  originalPrice: number | undefined;
  isBreathTakingItem: boolean;
  secondary?: boolean;
  priceUnit: string | undefined;
}

export function PriceBadge({
  isBreathTakingItem,
  secondary,
  price,
  currency,
  priceUnit,
  originalPrice,
}: PriceBadgeProps) {
  const [whole, decimals] = splitPrice(price);
  return (
    <PriceContainer>
      {currency ? (
        <>
          <Price
            isBreathTakingItem={!!isBreathTakingItem}
            isSecondary={secondary}
          >
            {whole}
            <span>
              {decimals}
              {currency}
            </span>
            <span>{priceUnit}</span>
          </Price>
          {originalPrice != null && !isNaN(originalPrice) && (
            <OriginalPrice isSecondary={secondary}>
              {originalPrice} {currency}
            </OriginalPrice>
          )}
        </>
      ) : (
        <NoPrice>—</NoPrice>
      )}
    </PriceContainer>
  );
}
