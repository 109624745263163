import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { RoomsAndArticleCountResponse } from "../types/room";

export function getSortedRoomsArticleCount(
  currentRoomName: string,
  roomsArticleCount: RoomsAndArticleCountResponse[],
  roomId?: string | undefined
): RoomsAndArticleCountResponse[] {
  return roomsArticleCount.sort((a, b) => {
    if (roomId) {
      if (a.room_id === roomId) {
        return -3;
      } else if (b.room_id === roomId) {
        return 3;
      }
    } else if (a.name === currentRoomName) {
      return -2;
    } else if (b.name === currentRoomName) {
      return 2;
    } else if (a.name > b.name) {
      return 1;
    }
    return -1;
  });
}

export function reduceRoomsArticleCount(
  roomsArticleCount: RoomsAndArticleCountResponse[]
): RoomsAndArticleCountResponse[] {
  return roomsArticleCount.reduce((acc, cur) => {
    const existingRoom = acc.find((room) => room.room_id === cur.room_id);
    if (existingRoom) {
      existingRoom.nbr_articles += cur.nbr_articles;
    } else {
      acc.push(cur);
    }
    return acc;
  }, [] as RoomsAndArticleCountResponse[]);
}

export function getPrimeTasks(
  roomsArticleCount: RoomsAndArticleCountResponse[],
  tasks: RoomSettings[]
): RoomSettings[] {
  const primeTasks: RoomSettings[] = [];
  roomsArticleCount.forEach(({ article_id }) => {
    const matchedTask = tasks.find((task) => task.article_id === article_id);
    if (matchedTask) {
      primeTasks.push(matchedTask);
    }
  });

  return primeTasks;
}
