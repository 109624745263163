import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { RoundCard } from "../Rounds/RoundCard";
import IngkaButton from "@ingka/button";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useParams } from "react-router-dom";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { Tools } from "./Tools";
import { DIVISION_HASH, HEADER_COLOR_SCHEMA } from "../constants";
import { scrollToTop } from "../helper";
import { useCreateShoppingTool } from "../Hooks/useCreateShoppingTool";
import { useFetchDivisionsCountByRound } from "../Hooks/useFetchDivisionsCountByRound";
import SSRIcon from "@ingka/ssr-icon";
import SettingsIcon from "@ingka/ssr-icon/paths/gear";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const MarkethallOTW = () => {
  const [cameWithThisTool, setCameWithThisTool] = React.useState("");
  const [leftWithThisTool, setLeftWithThisTool] = React.useState("");
  let [currentValue, setCurrentValue] = React.useState<number>(0);
  const { t } = useTranslation();

  const { push } = useWorkspacesAction();
  const { id } = useParams();
  const createShoppingToolMutation = useCreateShoppingTool();
  const storeId = useStoreId();

  const { data: startValue, isFetching } = useFetchDivisionsCountByRound(
    DIVISION_HASH.MARKETHALL?.division ?? "",
    id ?? ""
  );

  React.useEffect(() => {
    setCurrentValue(startValue);
  }, [startValue]);

  React.useEffect(() => {
    if (cameWithThisTool && !leftWithThisTool) {
      setLeftWithThisTool(cameWithThisTool);
    }
  }, [cameWithThisTool, leftWithThisTool]);

  const handleSubmit = async () => {
    await createShoppingToolMutation.mutateAsync({
      storeId: storeId,
      division: DIVISION_HASH.MARKETHALL?.division ?? "",
      roundName: id ?? "",
      initialShoppingTools: { [cameWithThisTool]: 1 },
      lastShoppingTools: { [leftWithThisTool]: 1 },
    });
    setCameWithThisTool("");
    setLeftWithThisTool("");
    currentValue++;
    setCurrentValue(currentValue);
    scrollToTop();
  };

  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <SSRIcon paths={SettingsIcon} />,
      onClick: () => push("/shoppingtools/settings"),
      position: "right",
    },
  ];

  return (
    <FullScreenPopup
      appBarConfig={{
        title: (
          <>
            <Trans>markethallOTWString</Trans> - <Trans>{`${id}String`}</Trans>
          </>
        ),
        actions: appBarActions,
        onBack: () => push("/shoppingtools/progress"),
      }}
      noPadding
    >
      <Tools
        id={"came"}
        title={"customerCameToolString"}
        division={DIVISION_HASH.SHOWROOM?.division ?? ""}
        setChosenTool={setCameWithThisTool}
        chosenTool={cameWithThisTool}
        colorSchema={HEADER_COLOR_SCHEMA.GREY}
      />
      <Tools
        id={"left"}
        title={"customerLeftToolString"}
        division={DIVISION_HASH.MARKETHALL?.division ?? ""}
        setChosenTool={setLeftWithThisTool}
        chosenTool={leftWithThisTool}
        colorSchema={HEADER_COLOR_SCHEMA.BLUE}
      />
      <RoundCard
        title={DIVISION_HASH.MARKETHALL?.transKey ?? ""}
        currentValue={currentValue}
        showNav={false}
        background="secondary"
        whiteBarBackground
        loading={isFetching && !startValue}
      />
      <ButtonWrapper>
        <IngkaButton
          type="primary"
          style={{ marginTop: "15px", width: "80%" }}
          text={<Trans>submitNewString</Trans>}
          disabled={!cameWithThisTool.length || !leftWithThisTool.length}
          onClick={handleSubmit}
        />
        <IngkaButton
          aria-label={t("goToCashlineAriaString")}
          disabled={currentValue < 50}
          type="secondary"
          style={{ marginTop: "15px", width: "80%" }}
          text={<Trans>changeToCashlineString</Trans>}
          onClick={() => push(`/shoppingtools/progress/cashline/${id}`)}
        />
      </ButtonWrapper>
    </FullScreenPopup>
  );
};
