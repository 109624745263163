import { TaskState } from "@coworker/apprestructured/src/tasks/enums/taskState";
import {
  Question,
  QuestionTaskType,
  VotesByArea,
} from "../BackendApiRepository/QuestionResponse";

export function getActiveTask(question: Question, area: string) {
  const activeTask = question?.tasks?.find(
    (task: QuestionTaskType) =>
      task.state !== TaskState.COMPLETED &&
      task.state !== TaskState.CLOSED &&
      task.area === area
  );
  return activeTask
    ? { hasOngoingTask: true, taskId: activeTask.task_id }
    : { hasOngoingTask: false, taskId: null };
}
export function createTaskHistory(question: Question, area: string) {
  const completedTasks = question?.tasks?.filter(function (
    task: QuestionTaskType
  ) {
    return task.state === TaskState.COMPLETED && task.area === area;
  });
  return completedTasks;
}

export function calculateNumberOfVotes(question: Question, area: string) {
  if (area === "whole=whole") {
    return question?.upvotes_by_area?.reduce(
      (total: number, item: VotesByArea) => total + item.votes_unresolved,
      0
    );
  } else {
    const foundArea = question?.upvotes_by_area?.find(
      (item: { area: string }) => item.area === area
    );
    return foundArea ? foundArea?.votes_unresolved : 0;
  }
}

export function onUpvote(
  questionId: string,
  area: string,
  storeId: string,
  createVote: Function,
  setQuestion: Function,
  question: Question
) {
  handleVote(questionId, area, storeId, createVote, setQuestion, question, 1);
}

export function onDownvote(
  questionId: string,
  area: string,
  storeId: string,
  createVote: Function,
  setQuestion: Function,
  question: Question
) {
  handleVote(questionId, area, storeId, createVote, setQuestion, question, -1);
}

function handleVote(
  questionId: string,
  area: string,
  storeId: string,
  createVote: Function,
  setQuestion: Function,
  question: Question,
  voteType: 1 | -1
) {
  createAreaIfMissing(question, area);
  const updatedUpvotesByArea = question.upvotes_by_area.map(
    (upvoteArea: VotesByArea) => {
      if (upvoteArea.area === area) {
        return {
          ...upvoteArea,
          votes_unresolved: upvoteArea.votes_unresolved + voteType,
        };
      } else return upvoteArea;
    }
  );
  setQuestion({
    ...question,
    upvotes_by_area: updatedUpvotesByArea,
  });
  createVote(questionId, voteType, area, storeId);
}

function createAreaIfMissing(question: Question, area: string) {
  const foundArea = question.upvotes_by_area.find(
    (item: { area: string }) => item.area === area
  );
  if (!foundArea) {
    const newArea: VotesByArea = {
      area: area,
      votes_unresolved: 0,
      votes_resolved: 0,
      votes: 0,
    };
    question.upvotes_by_area.push(newArea);
  }
}

export function sortUpvotesByArea(upVotesByArea: VotesByArea[]) {
  const sorted = upVotesByArea.sort(
    (a: VotesByArea, b: VotesByArea) => b.votes_unresolved - a.votes_unresolved
  );
  return sorted.filter((area: VotesByArea) => area.votes_unresolved > 0);
}

export function removeQuestionMarks(newQuestionText: string) {
  return newQuestionText.replace(/\?/g, "");
}

const capitalizeFirst = (text: string) =>
  text?.charAt ? text.charAt(0).toLocaleUpperCase() + text.slice(1) : "";

export function getTranslatedQuestionText(text = "") {
  return `${capitalizeFirst(removeQuestionMarks(text).trim())}?`;
}

//TODO: Fix test
export function canVote(endDate: number): boolean {
  const today = new Date();

  // Create a Date object from the endDate timestamp
  const endDateObj = new Date(endDate);

  // Check if the year, month, and day match
  return (
    today.getFullYear() === endDateObj.getFullYear() &&
    today.getMonth() === endDateObj.getMonth() &&
    today.getDate() === endDateObj.getDate()
  );
}
