import {
  HEADER as header,
  PRODUCT as product,
  ISSUE as issue,
  LOCATION as location,
  DESCRIPTION as description,
  PRODUCT_WITH_PILLS as product_with_pills,
  ASSIGNMENT as assignment,
  ACTIVITY as activity,
  SECTION as section,
  CREATOR as creator,
  CREATED as created,
  RADIOBUTTONSWITHTEXT as radioButtonsWithText,
  MFAQ_QUESTION as mfaq_question,
  MFAQ_RESOLUTION as mfaq_resolution,
  MFAQ_AREA as mfaq_area,
  IMAGES as images,
  CHECKLIST as checklist,
  TASKLISTVALIDATION as taskListValidation,
  PRIORITY as priority,
  DIVIDER as divider,
  SPACER as spacer,
  DESCRIBE_THE_PROBLEM as describeTheProblem,
  ROOM_SETTINGS_TODO as roomSettingsTodo,
  PROOF_OF_COMPLETION as proofOfCompletion,
  PREVIOUS_PRODUCT as previousProduct,
} from "../widgets";

export const HEADER = { type: header };
export const IMAGES = { type: images };
export const PRODUCT = { type: product };
export const ISSUE = { type: issue };
export const ACTIVITY = { type: activity };
export const CREATOR = { type: creator };
export const CREATED = { type: created };
export const MFAQ_QUESTION = { type: mfaq_question };
export const MFAQ_RESOLUTION = { type: mfaq_resolution };
export const MFAQ_AREA = { type: mfaq_area };
export const CHECKLIST = { type: checklist };
export const TASKLISTVALIDATION = { type: taskListValidation };
export const PRIORITY = { type: priority };

/**
 * @param {"pickup" | "refill" | undefined} locationType
 */
export const LOCATION = (locationType) => ({
  type: location,
  conf: { addon: locationType },
});

export const LOCATIONWITHICON = {
  type: location,
  conf: { shouldShowIcon: true },
};

export const DESCRIPTION = (
  field,
  titleTranslationKey,
  icon,
  iconSize,
  fieldOverrideTranslationKey
) => ({
  type: description,
  conf: {
    field,
    titleTranslationKey,
    icon,
    iconSize,
    fieldOverrideTranslationKey,
  },
});

export const PRODUCT_WITH_PILLS = {
  type: product_with_pills,
  values: {
    in: ({ product_article_id, product_article_type }) => ({
      productId: product_article_id,
      productType: product_article_type,
    }),
  },
};

export const DIVIDER = { type: divider };

export const SPACER = (height) => ({
  type: spacer,
  conf: { height },
});

export const SECTION = (titleTranslationKey) => ({
  type: section,
  conf: { titleTranslationKey },
});

export const RADIOBUTTONSWITHTEXT = (
  options,
  field,
  fieldText,
  titleTranslationKey,
  icon,
  iconSize
) => ({
  type: radioButtonsWithText,
  conf: { options, field, fieldText, titleTranslationKey, icon, iconSize },
});

export const ASSIGNMENT = (icon) => ({
  type: assignment,
  conf: { icon },
});

export const DESCRIBE_THE_PROBLEM = {
  type: describeTheProblem,
  values: {
    in: ({
      pq_describe_the_problem_type,
      description,
      pq_describe_the_problem_tags,
    }) => ({
      pq_describe_the_problem_type,
      description,
      pq_describe_the_problem_tags,
    }),
  },
};

export const ROOM_SETTINGS_TODO = {
  type: roomSettingsTodo,
  values: {
    in: ({
      id,
      is_new_article,
      is_ptag,
      is_new_article_handled,
      is_ptag_handled,
      state,
      pieces,
      reason,
    }) => ({
      id,
      is_new_article,
      is_ptag,
      is_new_article_handled,
      is_ptag_handled,
      state,
      pieces,
      reason,
    }),
  },
};

export const PROOF_OF_COMPLETION = {
  type: proofOfCompletion,
  values: {
    in: ({ proof_of_completion, state, id, isDone, setIsDone }) => ({
      proof_of_completion,
      state,
      id,
      isDone,
      setIsDone,
    }),
  },
};

export const PREVIOUS_PRODUCT = {
  type: previousProduct,
  values: {
    in: ({ new_product_article_id, state, reason }) => ({
      new_product_article_id,
      state,
      reason,
    }),
  },
};
