import React, { useContext } from "react";
import hamburgerMenuIcon from "@ingka/ssr-icon/paths/menu";
import { ModalContext } from "../../../../context/ModalContextProvider";
import { ModalType } from "../../../../context/modalType";
import styles from "./hamburger-menu.module.css";
import Button from "@ingka/button";
import { useTranslation } from "react-i18next";

const HamburgerMenu = () => {
  const { toggleModal } = useContext(ModalContext);
  const { t } = useTranslation();
  return (
    <div className={styles["hamburger-menu"]}>
      <Button
        aria-label={t("menuString")}
        type="tertiary"
        onClick={() => toggleModal({ type: ModalType.MainNavigation })}
        inverseTheme={true}
        iconOnly={true}
        ssrIcon={hamburgerMenuIcon}
      />
    </div>
  );
};

export default HamburgerMenu;
