import React from "react";
import { CARD_MODE } from "./constants";
import { CardMode } from "./types";

interface ImageProps {
  image: string;
  cardMode: CardMode;
}

export const Image = ({ image, cardMode }: ImageProps) => {
  if (cardMode !== CARD_MODE.COMPLEX || !image) return null;

  return <img alt="productImage" height={44} src={image} />;
};
