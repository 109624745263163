import React from "react";
import { LargeSkeletonBox } from "../Molecules/skeletons/LargeSkeletonBox";
import { MediumSkeletonListItem } from "../Molecules/skeletons/MediumSkeletonListItem";
import Skeleton from "@ingka/skeleton";

export const RoomViewSkeleton = () => {
  return (
    <>
      <LargeSkeletonBox />

      {[...Array(3)].map((_, index) => (
        <MediumSkeletonListItem key={index} />
      ))}
      <Skeleton height={"48px"} width={"90%"} />
    </>
  );
};
