import { BrowserCacheLocation, Configuration } from "@azure/msal-browser";
import { HejMsalConfig } from "@ingka-group-digital/cwep-hej-core";
export const msalHejConfiguration: HejMsalConfig = {
  auth: {
    clientId:
      process.env["REACT_APP_SSO_CLIENT_ID"] ??
      "Missing REACT_APP_SSO_CLIENT_ID",
    authority: `https://login.microsoftonline.com/${
      process.env["REACT_APP_SSO_TENANT"] ?? "Missing REACT_APP_SSO_TENANT"
    }`,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
};
export const tokenScopes = ["https://coworkerapp.ikea.com/fixa_api"];

export const msalConfiguration: Configuration = {
  auth: {
    clientId:
      process.env["REACT_APP_SSO_CLIENT_ID"] ??
      "Missing REACT_APP_SSO_CLIENT_ID",
    authority: `https://login.microsoftonline.com/${
      process.env["REACT_APP_SSO_TENANT"] ?? "Missing REACT_APP_SSO_TENANT"
    }`,
    redirectUri: encodeURI(`${window.location.origin}/login`),
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
};
