import React from "react";
import { Container, TextWrapper } from "../../Progress/TodaysProgress";
import { SmallText } from "../../Overview/NewReview";
import Skeleton from "@ingka/skeleton";

export const TodaysProgressSkeleton = () => {
  return (
    <Container>
      <TextWrapper>
        <SmallText style={{ marginBottom: "10px" }}>
          <Skeleton width="100px" height="17px" />
        </SmallText>
        <Skeleton width="100%" height="75px" />
      </TextWrapper>
    </Container>
  );
};
