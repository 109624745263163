import {
  PRODUCT,
  HEADER,
  ASSIGNMENT,
  ACTIVITY,
  DESCRIPTION,
  ROOM_SETTINGS_TODO,
  PROOF_OF_COMPLETION,
  PREVIOUS_PRODUCT,
} from "./_common";
import { PickUpTask, CompleteTask } from "../actions/index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fields: [
    HEADER,
    PRODUCT,
    DESCRIPTION("reason", "typeOfTaskString", "", 0, "dailyMaintenanceString"),
    ROOM_SETTINGS_TODO,
    PREVIOUS_PRODUCT,
    PROOF_OF_COMPLETION,
    DESCRIPTION("room_name", "roomSettingNameString", ""),
    DESCRIPTION("description", "description4String", ""),
    ASSIGNMENT(),
    ACTIVITY,
  ],
  actions: [PickUpTask, CompleteTask],
};
