import React from "react";
import BlueLink from "../PrivacyStatementComponents";

export const policy = {
  title: <p>Datenschutzinformation zu „Fixa“ Informationspflichten </p>,
  aboutFixaText: (
    <p>
      Informationspflichten nach Art. 13 Datenschutz-Grundverordnung (DSGVO)
      Hiermit informieren wir dich über die Verarbeitung deiner personen-
      bezogenen Daten („Daten“) sowie über deine datenschutzrechtlichen
      Ansprüche und Rechte. Hier kannst du nachlesen, wie die IKEA Möbelvertrieb
      GmbH bzw., die IKEA Austria GmbH, nachfolgend „IKEA“ personenbezogene
      Daten verarbeitet, die du in dieser Anwendung (genannt „Fixa“) verarbeitet
      werden und informieren dich über deine Rechte.
    </p>
  ),
  personalDataCollectedTitle: (
    <p>
      1.Wer ist für die Datenverarbeitung verantwortlich und an wen kannst du
      dich wenden?
    </p>
  ),
  personalDataCollectedText: (
    <div>
      Verantwortlicher für die Datenverarbeitung ist die jeweilige Unit in der
      du beschäftigt bist:
      <br />
      <b>IKEA Möbelvertrieb OHG</b>
      <br />
      Rechtsform: Offene Handelsgesellschaft
      <br />
      Einrichtungshaus <b>Graz</b> - Weblinger Gürtel 31, 8054 Graz
      <br />
      Einrichtungshaus <b>Haid</b> - Ikeaplatz 1, 4053 Haid
      <br />
      Einrichtungshaus <b>Innsbruck</b> - Amraser-See-str. 56c, 6020 Innsbruck
      <br />
      Einrichtungshaus <b>Klagenfurt</b> - IKEA Platz 1, 9020 Klagenfurt
      <br />
      Einrichtungshaus <b>Salzburg</b> - Europastraße 5, 5020 Salzburg
      <br />
      Einrichtungshaus <b>Vösendorf</b> - Shopping City Süd, Südring, 2334
      Vösendorf
      <br />
      Einrichtungshaus <b>Wien</b> Nord - Sverigestraße 1a, 1220 Wien
      <br />
      <br />
      <br />
      Firmenbuchnummer: FN 316 s<br />
      Firmenbuchgericht: Landesgericht Wr. N<br />
      Umsatzsteueridentifikationsnummer: ATU19420008
      <br />
      <br />
      <b>IKEA Austria GmbH</b>
      <br />
      Rechtsform: Gesellschaft mit beschränkter Haftung
      <br />
      <br />
      SCS Südring, 2334 Vösendorf
      <br />
      Firmenbuchnummer: 235629p
      <br />
      Firmenbuchgericht: Landesgericht Wr. Neustadt
      <br />
      Umsatzsteueridentifikationsnummer: ATU57230302
      <br />
      Solltest du zu der Verarbeitung deiner Daten Fragen oder Anliegen haben,
      kannst du dich an deine jeweilige lokale People &amp; Culture ("P&amp;C")
      Abteilung in deiner Einheit wenden.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>
      2. Zu welchen Zwecken werden die Daten verarbeitet und für welche Dauer?
    </div>
  ),
  purposeOfDataCollectionText: (
    <div>
      Fixa ist eine mobile Smartphone Anwendung ("App"), mit dem Zweck die
      Abläufe im Einrichtungshaus zu vereinfachen und Aufgaben dir oder deinen
      Mitarbeiterinnen und Mitarbeitern zuteilen zu können, z.B.: zur Pflege und
      Wartung von Produkten, zur Inventur und zum Auffüllen von Add-ons. <br />
      Um Fixa verwenden zu können verarbeitet die App deinen Vor- und Nachnamen,
      deine geschäftliche E-Mail-Adresse, deine Berufsbezeichnung, deine Rolle
      und deine bevorzugte Sprache aus dem IKEA Mitarbeiterverzeichnis (CDS).
      Diese Daten werden benötigt, um Aufgaben erstellen und an die jeweiligen
      Benutzer verteilen zu können. Um die Abläufe effizient gestalten zu
      können, ist es möglich über verschiedene Abläufe von der App
      benachrichtigt werden, z.B.: wenn dir eine Aufgabe zugeteilt wurde, du an
      die Aufgabe erinnert werden sollst oder die Aufgabe an eine andere Person
      oder ein anderes Team übergeben wurde. Du wirst auch informiert welche
      Aufgaben mit dem Auftrag verbunden sind und kannst den Auftrag übernehmen,
      bearbeiten, mit/ohne Änderungen abschließen. Rechtsgrundlage für die
      hiermit verbundene Datenverarbeitung ist Art. 6 Abs. 1 ) DSGVO, d.h. deine
      Daten werden zur Erfüllung deiner Pflichten aus deinem Dienstvertrag
      verarbeitet. Deine erledigten Aufgaben werden in der Anwendung für 30 Tage
      gespeichert und danach anonymisiert. Nach Beendigung deines
      Dienstverhältnisses werden deine Daten in Fixa 1 Monat nach der Löschung
      deines Profils in CDS entfernt. Wechselst du die Position innerhalb des
      Unternehmens und dein Fixa Zugang wird nicht mehr benötigt, werden deine
      Daten nach 3 Monaten Inaktivität in Fixa gelöscht.
    </div>
  ),
  legalGroundsTitle: <div>3. Wer erhält deine Daten?</div>,
  legalGroundsText: (
    <div>
      Von uns eingesetzte Dienstleister (als Auftragsverarbeiter), wie etwa (IT-
      Unternehmen) sofern diese deine Daten zur Erfüllung ihrer jeweiligen
      Aufgaben benötigen. Diese Dienstleister sind vertraglich dazu
      verpflichtet, deine Daten vertraulich zu behandeln und nur soweit für die
      Leistungserbringung notwendig zu verarbeiten.
      <br />
      Innerhalb der IKEA Möbelvertrieb OHG bzw. IKEA Austria GmbH und der
      Unternehmensgruppe IKEA erhalten diejenigen Mitarbeiter und Stellen deine
      Daten, die diese für die Erfüllung der jeweiligen vertraglichen und
      gesetzlichen Pflichten, sowie unserer berechtigten Interessen benötigen.
      <br />
      Sofern wir dazu gesetzlich verpflichtet sind, geben wir deine Daten auch
      an öffentliche Stellen und Behörden weiter.
      <br />
    </div>
  ),
  dataWillBeStoredTitle: (
    <div>
      4. Deine Rechte im Zusammenhang mit der Verarbeitung personenbezogener
      Daten
    </div>
  ),
  dataWillBeStoredText: (
    <div>
      Du kannst, (i) von uns Auskunft verlangen, ob und welche personenbezogenen
      Daten wir über dich gespeichert haben und Kopien dieser Daten erhalten,
      (ii) die Berichtigung, Ergänzung, oder das Löschen deiner
      personenbezogenen Daten verlangen, die falsch sind oder nicht
      rechtskonform verarbeitet werden, (iii) von uns verlangen, die
      Verarbeitung deiner personenbezogenen Daten einzuschränken, (iv) unter
      bestimmten Umständen der Verarbeitung deiner personenbezogenen Daten
      widersprechen oder die für das Verarbeiten zuvor gegebene Einwilligung
      widerrufen, (v) Daten, die du uns gegeben hast in einem übertragbaren
      Format verlangen und (vi) bei der österreichischen Datenschutzbehörde (
      <BlueLink href="https://www.dsb.gv.at">www.dsb.gv.at</BlueLink>)
      Beschwerde erheben. Zur Geltendmachung aller vorgenannten Rechte wende
      dich bitte an die oben angeführten P&amp;C Abteilungen in deiner IKEA
      Einheit.
    </div>
  ),
  dataControllerForPersonalDataTitle: "",
  dataControllerForPersonalDataText: "",
  rightToPersonalInformationText: "",
};
