import React from "react";
import { Trans } from "@coworker/locales";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import { useFormatRecurring } from "../../../hooks/useFormatRecurring";

function Repeatable({ task }) {
  const { formatRecurring } = useFormatRecurring();
  if (!task.repeatable) return null;
  const { name } = formatRecurring(task.repeatable.frequency, task.repeatable);
  return (
    <DetailSection>
      <DetailSectionLeft>
        <SectionTitle>
          <Trans>repeatsString</Trans>
        </SectionTitle>
        <SectionText data-testid="taskRepeatable">{name}</SectionText>
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default Repeatable;
