import { InternalProxyResponse, TestBuy } from "@coworker/types";
import { TaskState } from "@coworker/types/lib/tasks/constants";
import dayjs from "dayjs";
import useCallInternal, {
  TESTBUY_GET_BY_ID,
  TESTBUY_GETALL,
  TESTBUY_GET_TASKS_DATA,
  callInternal,
} from "../../../../hooks/API/useCallInternal";
import { useStoreId } from "../../../../core/auth/useLoggedInUser";
import React from "react";

interface HookResponse<T> {
  loading: boolean;
  error?: string;
  elapsed: number;
  dataIsFromAPI: boolean;
  isBlocked: boolean;
  data: T;
}

/**
 * Get a testbuy by id
 * @param id Test buy document id
 * @returns Basic test buy data
 */
export function useTestbuy(id: string | undefined): {
  loading: boolean;
  testbuy: TestBuy | null;
  refetch: () => void;
} {
  const [testbuy, setTestbuy] = React.useState<TestBuy | null>(null);
  const [loading, setLoading] = React.useState<boolean>(true);
  const mounted = React.useRef(true);

  React.useEffect(() => {
    async function fetchTestBuy() {
      setLoading(false);

      const res: InternalProxyResponse<TestBuy | null> = await callInternal(
        TESTBUY_GET_BY_ID,
        { id }
      );
      res && mounted.current && setTestbuy(res.data);
    }

    if (id && loading) {
      fetchTestBuy();
    }
  }, [id, loading]);

  React.useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  return {
    loading,
    testbuy,
    refetch: () => setLoading(true),
  };
}

export function useTestBuys(
  area: string | undefined,
  sinceTs: number | undefined,
  untilTs: number | undefined,
  divisionId: string | undefined,
  fixaUid: string
): { loading: boolean; testbuys?: TestBuy[] } {
  const storeId = useStoreId();
  // @ts-ignore
  const apiRes: HookResponse<TestBuy[]> = useCallInternal(
    TESTBUY_GETALL,
    {
      area,
      storeId,
      since: dayjs(sinceTs).format("YYYY-MM-DD"),
      until: dayjs(untilTs).format("YYYY-MM-DD"),
      divisionId,
      fixaUid,
    },
    { blockRequest: !sinceTs || !untilTs }
  ); // TODO TSify useCallInternal

  return { loading: apiRes.loading, testbuys: apiRes.data };
}

export function useTestbuyTasks(id: string) {
  // @ts-ignore
  const apiRes: HookResponse<TaskData[]> = useCallInternal(
    TESTBUY_GET_TASKS_DATA,
    { id },
    { blockRequest: !id }
  ); // TODO TSify useCallInternal
  return apiRes.data;
}

interface TaskData {
  articleId: string;
  taskId: string;
  state: TaskState;
  finishedAt?: number;
}
