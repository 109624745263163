const roles_enum = require("@coworker/functions/src/enums/roleType.json");

module.exports.displayRoleTranslationString = function (role) {
  switch (role) {
    case roles_enum.COUNTRY_SUPERUSER:
      return "countrySuperuserString";
    case roles_enum.SUPERUSER:
      return "globalSuperuserString";
    case roles_enum.STOREOWNER:
      return "storeSuperuserString";
    case roles_enum.COWORKER:
    default:
      return "coworkerString";
  }
};
