import { AlertResponse } from "../types/room";
import { TaskLinks } from "../types/taskLink";

export const getOngoingTaskCount = (tasks: TaskLinks, roomId: string) => {
  if (tasks) {
    return tasks.filter((task) => task.roomId === roomId).length;
  }

  return 0;
};

export const getEDSAlertCount = (alerts: AlertResponse[], roomId: string) => {
  if (Array.isArray(alerts)) {
    const edsAlertForRoom = alerts.find(
      (alert, _index) => alert.roomId === roomId
    );

    if (edsAlertForRoom) {
      return edsAlertForRoom.eds;
    }
  }
  return 0;
};

export const getSalesStopAlertCount = (
  alerts: AlertResponse[],
  roomId: string
) => {
  if (Array.isArray(alerts)) {
    const salesStopAlertForRoom = alerts.find(
      (alert) => alert.roomId === roomId
    );

    if (salesStopAlertForRoom) {
      return salesStopAlertForRoom.salesStop;
    }
  }
  return 0;
};

export const getRoomMetaInfo = (
  ongoingTaskCount: number,
  edsAlertCount: number,
  salesStopAlertCount: number,
  t: (key: string) => string
) => {
  let result = "";
  if (ongoingTaskCount > 0)
    result += `${ongoingTaskCount} ${t("ongoingString").toLowerCase()}`;

  if (edsAlertCount > 0) {
    if (result) result += ", ";
    result += `${edsAlertCount} EDS`;
  }

  if (salesStopAlertCount > 0) {
    if (result) result += ", ";
    result += `${salesStopAlertCount} ${t("salesStopString")}`;
  }
  return result;
};
