import React from "react";
import styled from "styled-components";

interface ListImageProps {
  src: string;
  alt: string;
}

const Image = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

export function ListImage({ src, alt }: ListImageProps) {
  if (!src) {
    src =
      "https://www.ikea.com/ext/ingkadam/m/73a4aae2f1f90a1b/original/PH193360.jpg?f=sg";
  }

  return <Image src={src} alt={alt} />;
}
