import React from "react";
import { useTranslation, Trans } from "@coworker/locales";
import {
  eventTypes,
  eventObjectTypes,
  isTeamEvent,
} from "@coworker/functions/src/enums/eventTypes";
import useFormatter from "../../hooks/useFormatter";
import trackerHelper from "../../helpers/tracker";
import NotificationCard from "./NotificationCard";

function Notification({
  event,
  uid,
  teamId,
  users,
  teams,
  showIndicator,
  onClickHandler,
}) {
  const { t } = useTranslation();
  const { toTimeAgo, formatFirstNameAndInitial } = useFormatter();
  const getShortName = React.useCallback(
    (uid) => formatFirstNameAndInitial(users[uid], uid),
    [formatFirstNameAndInitial, users]
  );

  function registerGoogleAnalyticsEvent() {
    trackerHelper.trackNotificationScreenItemClick(event.type);
  }

  const { events = [] } = event;
  const lastEvent = events[events.length - 1];
  const [title, subtitle] = React.useMemo(() => {
    if (!lastEvent) return ["", ""];

    const toYou = lastEvent.data.assigned_to === uid;
    const creator = getShortName(lastEvent.data.created_by);
    const assignee = isTeamEvent(lastEvent.type)
      ? getTeam(lastEvent.data.assigned_to)
      : getShortName(lastEvent.data.assigned_to);

    function getTeam(team_id) {
      if (!team_id || !teams) return "";
      if (team_id === teamId) return t("teamYourString");
      return teams[team_id]?.name || t("teamUnknownString");
    }

    return [
      getTitle(lastEvent.type, t),
      getSubtitle(lastEvent.type, { assignee, creator }, toYou),
    ];
  }, [lastEvent, t, teamId, teams, uid, getShortName]);

  return (
    <NotificationCard
      onClickHandler={() => {
        registerGoogleAnalyticsEvent();
        onClickHandler();
      }}
      link={getLink(event)}
      showIndicator={showIndicator}
      title={title}
      time={toTimeAgo(event.last_event_at)}
      subtitle={subtitle}
    />
  );
}

function getTitle(eventType, t) {
  switch (eventType) {
    case eventTypes.TASK_ASSIGNED:
      return t("taskAssignedString");
    case eventTypes.TASK_ASSIGNED_TO_TEAM:
      return t("taskAssignedString");
    case eventTypes.TASK_REASSIGNED:
      return t("taskReassignedString");
    case eventTypes.TASK_REASSIGNED_TO_TEAM:
      return t("taskReassignedString");
    case eventTypes.TASK_COMPLETED_WITH_CHANGES:
      return t("taskCompletedWithChangesString");
    case eventTypes.TASK_EDITED:
      return t("taskEditedString");
    case eventTypes.TASK_CLOSED:
      return t("taskWasClosedString");
    case eventTypes.TASK_PICKED_UP:
      return t("taskPickedUpString");
    case eventTypes.TASK_IS_ONE_DAY_OLD:
      return t("taskIsOneDayOldString");
    case eventTypes.TASK_IS_FOUR_HOURS_OLD:
      return t("taskIsFourHoursOldString");
    case eventTypes.TEAM_TASK_IS_ONE_DAY_OLD:
      return t("taskIsOneDayOldString");
    case eventTypes.TEAM_TASK_IS_FOUR_HOURS_OLD:
      return t("taskIsFourHoursOldString");
    case eventTypes.TASK_COMMENTED:
      return t("taskCommentedString");
    case eventTypes.TESTBUY_REASSIGNED:
      return t("testbuyReassignedString");
    default:
      return "";
  }
}

function getSubtitle(type, values, toYou) {
  switch (type) {
    case eventTypes.TASK_ASSIGNED:
      if (toYou) {
        return <Trans values={values} i18nKey="hasAssignedToYouString" />;
      }
      return <Trans values={values} i18nKey="hasAssignedString" />;
    case eventTypes.TASK_ASSIGNED_TO_TEAM:
      return <Trans values={values} i18nKey="hasAssignedString" />;
    case eventTypes.TASK_REASSIGNED:
      if (toYou) {
        return <Trans values={values} i18nKey="hasReassignedToYouString" />;
      }
      return <Trans values={values} i18nKey="hasReassignedString" />;
    case eventTypes.TASK_REASSIGNED_TO_TEAM:
      return <Trans values={values} i18nKey="hasReassignedString" />;
    case eventTypes.TASK_COMPLETED_WITH_CHANGES:
      return <Trans values={values} i18nKey="hasCompletedWithChangesString" />;
    case eventTypes.TASK_EDITED:
      return <Trans values={values} i18nKey="hasEditedString" />;
    case eventTypes.TASK_CLOSED:
      return <Trans values={values} i18nKey="hasClosedString" />;
    case eventTypes.TASK_PICKED_UP:
      return <Trans values={values} i18nKey="hasPickedUpString" />;
    case eventTypes.TASK_IS_ONE_DAY_OLD:
      return <Trans values={values} i18nKey="hasBecomeOneDayOldString" />;
    case eventTypes.TASK_IS_FOUR_HOURS_OLD:
      return <Trans values={values} i18nKey="hasBecomeFourHoursOldString" />;
    case eventTypes.TEAM_TASK_IS_ONE_DAY_OLD:
      return <Trans values={values} i18nKey="hasBecomeOneDayOldTeamString" />;
    case eventTypes.TEAM_TASK_IS_FOUR_HOURS_OLD:
      return (
        <Trans values={values} i18nKey="hasBecomeFourHoursOldTeamString" />
      );
    case eventTypes.TASK_COMMENTED:
      return <Trans values={values} i18nKey="hasCommentedString" />;
    case eventTypes.TESTBUY_REASSIGNED:
      if (toYou) {
        return (
          <Trans values={values} i18nKey="hasReassignedTestbuyToYouString" />
        );
      }
      return <Trans values={values} i18nKey="hasReassignedString" />;
    default:
      return "";
  }
}

function getLink(event) {
  switch (event.type) {
    case eventObjectTypes.TASK:
      return `/task/${event.instance_id}`;
    case eventObjectTypes.TESTBUY:
      return `/testbuy/${event.instance_id}`;
    default:
      return "/";
  }
}

export default Notification;
