interface IssueTypeDescriptionType {
  transKey: string;
  type: string;
}

export const issueTypeDescriptions: IssueTypeDescriptionType[] = [
  {
    transKey: "issueNameInventoryString",
    type: "INVENTORY",
  },
  {
    transKey: "issueNameDamagedString",
    type: "DAMAGED_OR_BROKEN",
  },
  {
    transKey: "issueNameAssemblyString",
    type: "POOR_ASSEMBLY",
  },
  {
    transKey: "issueNameDirtyString",
    type: "DIRTY",
  },
  {
    transKey: "issueNameMissingPartsString",
    type: "MISSING_PARTS",
  },
  {
    transKey: "issueNameNoPriceTagString",
    type: "NO_PRICE_TAG",
  },
  {
    transKey: "issueNameRackingString",
    type: "RACKING",
  },
  {
    transKey: "issueNameOutOfStockString",
    type: "OUT_OF_STOCK",
  },
  {
    transKey: "issueNameMissingDisplayModelString",
    type: "MISSING_DISPLAY_MODEL",
  },
  {
    transKey: "issueNameWrongSalesLocationString",
    type: "WRONG_SALES_LOCATION",
  },
  {
    transKey: "issueTitleMissingPartsString",
    type: "OTHER",
  },
];
