import React from "react";

const makeLink =
  (url) =>
  ({ children }) =>
    (
      <span>
        {" "}
        <a href={url}>{children}</a>{" "}
      </span>
    );

function inDutch(loggedIn) {
  const PrivacyLink = makeLink(
    "https://inside.ingka.com/careerlearning/icw/nl/Documents/NL%20Privacy%20Policy%20NL.pdf"
  );

  return (
    <>
      <h1>Privacyverklaring voor Fixa</h1>
      <div>
        Fixa is een app voor winkelactiviteiten. Hiermee kunnen gebruikers
        efficiënt de winkel onderhouden door taken aan te maken die samenhangen
        met product- en winkelonderhoud, voorraad en add-on aanvullingen.
      </div>
      <div>
        Wanneer je Fixa gebruikt, verzamelt het systeem informatie over je uit
        de Co-Worker Database (CDS). Het gaat bijvoorbeeld om je zakelijke
        e-mailadres, primaire taal en andere ondersteunde talen voor je land.
        Het systeem bewaart ook het IKEA telefoonnummer dat je handmatig invoert
        in Fixa. We verzamelen deze persoonsgegevens om de Fixa app te kunnen
        aanbieden. Met deze app kunnen kleine taken worden aangemaakt, aan
        winkelmedewerkers worden toegewezen en door hen worden voltooid. Deze
        taken bevatten persoonsgegevens en omvatten ook meldingen voor
        verschillende taakacties, zoals het toewijzen en opnieuw toewijzen van
        taken aan een bepaalde medewerker of een bepaald team, plus
        taakgebeurtenissen (ophalen, bewerken, sluiten en voltooien met
        wijzigingen). Ook kunnen er taakherinneringen worden gestuurd naar
        mensen die taken hebben aangemaakt en die meldingen hebben ingeschakeld.
      </div>
      <div>
        Taken worden samen met de bijbehorende persoonsgegevens opgeslagen en na
        voltooiing nog 60 dagen bewaard. Je werkgever is verantwoordelijk voor
        de verwerking van persoonsgegevens in Fixa.
      </div>
      {loggedIn ? (
        <div>
          <i>
            Wil je meer weten over hoe je persoonsgegevens worden verwerkt en
            welke rechten je hebt op dat gebied? Gebruik
            <PrivacyLink>deze link</PrivacyLink> om de volledige
            privacyverklaring voor IKEA B.V. te lezen.
          </i>
        </div>
      ) : (
        <div>
          <i>
            Log in bij Fixa als je meer wilt weten over hoe je persoonsgegevens
            worden verwerkt en welke rechten je hebt op dat gebied.
          </i>
        </div>
      )}
    </>
  );
}

function inEnglish(loggedIn) {
  const PrivacyLink = makeLink(
    "https://inside.ingka.com/careerlearning/icw/nl/Documents/NL%20Privacy%20Policy%20EN.pdf"
  );

  return (
    <>
      <h1>Fixa privacy statement</h1>
      <div>
        Fixa is a store operations app giving users the possibility to
        efficiently maintain the store by creating tasks linked to product and
        store maintenance, inventory and the add-on refill.
      </div>
      <div>
        When you use Fixa, the system will collect information about you from
        the Co-Worker Database (CDS) such as your work email, primary language
        and other supported languages for your country. The system also stores
        the IKEA phone number that you insert manually in Fixa. The purpose of
        the personal data collection is to administer the Fixa application that
        enables creation of small tasks that can be assigned to store co-workers
        and completed. These tasks will contain personal data and will also
        allow notifications for different task actions like task assignment and
        reassignment to a particular co-worker or a team, task events (pick-up,
        edit, close and complete with changes) as well as send task reminders to
        task creators who have their notifications turned on.
      </div>
      <div>
        Tasks are stored with the personal data in them until they are completed
        and for a period of 60 days thereafter. It is your employer that is
        responsible for the processing of personal data in Fixa.
      </div>
      {loggedIn ? (
        <div>
          <i>
            If you want to know more about how your personal data is processed
            and your rights in relation thereto, please use
            <PrivacyLink>this link</PrivacyLink> to read the full privacy notice
            for IKEA BV.
          </i>
        </div>
      ) : (
        <div>
          <i>
            If you want to know more about how your personal data is processed
            and your rights in relation thereto, please login to Fixa.
          </i>
        </div>
      )}
    </>
  );
}

export const policy = ({ loggedIn }) => (
  <>
    {inDutch(loggedIn)}
    <br />
    <br />
    <br />
    {inEnglish(loggedIn)}
  </>
);
