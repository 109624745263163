import { firebase, firestore } from "@coworker/common/config/firebase";
import trackerHelper, { resetUserId } from "../helpers/tracker";
import {
  getSubscription,
  createSubscription,
  removeSubscription,
} from "../helpers/pushSubscription";
import { reportMessageToSentry } from "../hooks/useConfigureSentry";
import { callInternalApi } from "../hooks/API/useCallInternal";

export function updateUser(uid, user) {
  if (!uid || !user) {
    try {
      throw new Error("updateUser: empty id?");
    } catch (error) {
      console.log(error);
    }
    reportMessageToSentry("updateUser empty id!");
    return;
  }
  const formattedUser = {
    ...user,
    fixaUid: uid,
  };

  if (user.team_id) formattedUser["teamId"] = user.team_id;
  if (typeof user.phone !== "undefined")
    formattedUser["phoneNumber"] = user.phone;

  callInternalApi(
    `update/user?fixa_uid=${uid}`,
    formattedUser,
    false,
    false,
    "CORE_SERVICE"
  )
    .then((result) => {
      return result;
    })
    .catch((e) => console.log("error while updating user", e));
}

export async function logoutUser(trackUserEvent = true) {
  console.log("logoutUser");
  const { uid } = firebase.auth().currentUser || {};
  if (!uid) {
    return;
  }

  if (trackUserEvent) {
    trackerHelper.trackLogout();
  }

  try {
    const removedSubscription = await removeSubscription();
    if (removedSubscription) {
      await unsubscribeUserOnServer(uid, removedSubscription);
    }
    await firebase.auth().signOut();
    resetUserId();
    await firestore.terminate();
    await firestore.clearPersistence();
  } catch (error) {
    console.error("Error when logging out", error);
  }
}

const UPDATE_FAIL_WARNING =
  "Can't update subscriptions stored in user's profile. User is maybe INACTIVE or DELETED.";

export function subscribeUserOnServer(uid, createdSubscription) {
  const serialized = createdSubscription.toJSON();
  try {
    updateUser(
      uid,
      {
        subscription: firebase.firestore.FieldValue.arrayUnion(serialized),
      },
      false
    );
  } catch (error) {
    console.log(UPDATE_FAIL_WARNING);
  }
}

export function unsubscribeUserOnServer(uid, removedSubscription) {
  const serialized = removedSubscription.toJSON();
  try {
    return updateUser(
      uid,
      {
        subscription: firebase.firestore.FieldValue.arrayRemove(serialized),
      },
      false
    );
  } catch (error) {
    console.log(UPDATE_FAIL_WARNING);
  }
}

export function subscribeToUid(uid) {
  if (!uid || !("Notification" in window)) return;
  getSubscription()
    .catch(() => {
      console.error("Error setting up notification subscription.");
    })
    .then((existingSubscription) => {
      if (!existingSubscription) {
        createSubscription()
          .then((newSubscription) => {
            subscribeUserOnServer(uid, newSubscription);
          })
          .catch((error) => console.log("Didn't create subscription", error));
      } else {
        subscribeUserOnServer(uid, existingSubscription);
      }
    });
}
