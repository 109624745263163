import React from "react";
import { Trans } from "@coworker/locales";
import AddonList from "./AddonList";
import useInsightsAddonLists from "../../hooks/useInsightsAddonLists";

const AddonLocations = ({ itemLinker, params }) => {
  const { data, loading, total } = useInsightsAddonLists(true, params);
  const link = params?.product_id
    ? `/insights/product/${params.product_id}/locations`
    : "/insights/locations";

  return (
    <AddonList
      data={!loading && data}
      title={<Trans>insightsAddonSalesLocationListTitle</Trans>}
      testId="addonLocations"
      link={link}
      total={total}
      itemTransformFn={itemLinker}
      loading={loading}
      showOnlyLocation
      noPadding={true}
    />
  );
};

export default AddonLocations;
