import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Prohlášení o zásadách ochrany osobních údajů</div>,
  aboutFixaText: (
    <div>
      Fixa je nástroj pro správu úkolů a “merchandasing tool” pro in-store
      pracovníky/zaměstnance. Účelem aplikace Fixa je nahrazení aktivit
      založených na použití manuálních, papírových nebo excelových “aktivit” a
      usměrnění řízení úkolů v obchodním domě (doplňování artiklů, úklid,
      deviace, kontrolní nákupy, často kladené otázky atp.). Fixa také umožňuje
      analýzu skrze náhled z předchozích úloh.
      <br />
      <br />
      Správce údajů (jak je upřesněno níže) o Vás bude při používání aplikace
      Fixa sbírat osobní údaje.
    </div>
  ),
  personalDataCollectedTitle: <div>Jedná se o tyto osobní údaje:</div>,
  personalDataCollectedText: (
    <div>
      Když používáte Fixu, systém o Vás sbírá údaje z databáze zaměstnanců
      (CDS), jako například Vaše jméno a příjmení, email, Vaši pozici, telefonní
      číslo (pokud bylo poskytnuto, nemělo by se jednat o soukromé telefonní
      číslo), fotografii z CDS, upřednostňovaný jazyk v aplikaci, ID obchodního
      domu, verzi přístroje a operačního systému, IP adresu z Google Cloud
      Platform a Sentry (Sentry je software sledující chyby v aplikaci).
      <br />
      <br />
      Profil pracovníka tvoří Fixa-admin (webová aplikace) manuálně skrz
      managera/Super usera. Osobní údaje jsou přeneseny z CDS, takže profily
      mohou být vytvořeny pouze pro pracovníky s aktivním CDS profilem.
      <br />
    </div>
  ),
  purposeOfDataCollectionTitle: <div>Důvody, proč sbíráme Vaše údaje:</div>,
  purposeOfDataCollectionText: (
    <div>
      Osobní údaje v aplikaci Fixa jsou sbírány za účelem správné funkčnosti
      aplikace tak, aby umožňovala snadnou interní komunikaci mezi pracovníky a
      vykonávala správu úloh mezi pracovníky obchodního domu. Propojení úlohy s
      pracovníkem nám umožňuje podporovat upozornění na aktualizace úkolů pro
      přiřazené uživatele a tvůrce.
    </div>
  ),
  legalGroundsTitle: <div>Právním základem zpracování údajů je:</div>,
  legalGroundsText: <div>Oprávněný zájem správce.</div>,

  dataWillBeStoredTitle: <div>Doba zpracování údajů:</div>,
  dataWillBeStoredText: (
    <div>
      Ukončené úkony s odkazy na uživatele ukládáme po dobu 70 dnů, poté jsou
      anonymizovány. Osobní údaje pracovníka jsou uchovávány v aplikaci Fixa po
      dobu trvání pracovní smlouvy.
      <br />
      <br />
      Ukončení pracovní smlouvy se společností Ingka Group: Veškeré osobní údaje
      jsou automaticky odstraněny do jednoho měsíce po odstranění CDS profilu.
      <br />
      <br />
      Změna pozice v Ingka Group: Veškeré osobní údaje budou automaticky smazány
      do třech měsíců (od nečinnosti), pokud zaměstnanec nadále
      nepotřebuje/nevyžaduje použití Fixy.
      <br />
      <br />
      Osobní údaje také mohou být kdykoli odstraněny na žádost místního
      Information Security and Data Privacy (ISDP) Leadera, viz kontakt níže.
      <br />
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>Správcem údajů pro toto zpracování osobních údajů je:</div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA Česká republika, s.r.o.,
      <br />
      Skandinávská 131/1, 155 00 Praha 5
      <br />
      <br />
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      V souvislosti se svými osobními údaji máte určitá práva. Prosím navštivte:{" "}
      <a href="https://iweof.sharepoint.com/:b:/t/o365g_privacyczhusk_retczso/EcNr7Qv5wDxLsfNitg-z0mYB6jNb-KVDCs8uQUtAJPP8UQ?e=GTFBS3">
        https://iweof.sharepoint.com/:b:/t/o365g_privacyczhusk_retczso/EcNr7Qv5wDxLsfNitg-z0mYB6jNb-KVDCs8uQUtAJPP8UQ?e=GTFBS3
      </a>
      <br />
      <br />
      Kontaktní informace ISDP Leader:{" "}
      <EmailLink email="data.privacy.cz@ikea.com" />
    </div>
  ),
};
