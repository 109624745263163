import React, { useEffect } from "react";

import { AppBar, AppBarProps } from "../AppBar/AppBar";
import {
  useScrollDirection,
  SCROLL_UP,
} from "@coworker/app/src/hooks/useScrollDirection";

import styles from "./full-screen-popup.module.css";
import { ActionBar } from "../ActionBar/ActionBar";

interface FullScreenPopupProps {
  children: React.ReactNode;
  appBarConfig?: AppBarProps;
  noPadding?: boolean;
  actionBarContent?: React.ReactNode;
  isActionBarHiddenOnScroll?: boolean;
  noAppBar?: boolean;
}

const FullScreenPopup = ({
  children,
  appBarConfig,
  noPadding,
  actionBarContent,
  isActionBarHiddenOnScroll = true,
  noAppBar,
}: FullScreenPopupProps) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollDirection, setScrollTarget] = useScrollDirection();

  useEffect(() => {
    if (!scrollContainerRef.current) return;

    setScrollTarget(scrollContainerRef.current);
  }, [scrollContainerRef, setScrollTarget]);

  return (
    <div
      className={`${styles["full-screen-popup"]} ${
        noPadding ? styles["no-padding"] : ""
      }`}
      ref={scrollContainerRef}
    >
      {!noAppBar && appBarConfig && <AppBar {...appBarConfig} />}
      <div className={styles["content"]}>{children}</div>
      {actionBarContent && (
        <ActionBar
          hidden={isActionBarHiddenOnScroll && scrollDirection === SCROLL_UP}
        >
          {actionBarContent}
        </ActionBar>
      )}
    </div>
  );
};

export default FullScreenPopup;
