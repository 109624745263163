import { useQuery } from "@tanstack/react-query";
import { callInternalApi } from "../API/useCallInternal";
import { isChineseEnvironment } from "@coworker/reusable";
import { useStoreId } from "../../core/auth/useLoggedInUser";

export const useFetchNotifications = () => {
  const isChina = isChineseEnvironment();
  const storeId = useStoreId();

  return useQuery({
    queryKey: ["notifications"],
    queryFn: async () => await fetchNotifications(storeId),
    staleTime: 300000,
    enabled: !isChina,
  });
};

async function fetchNotifications(storeId: string) {
  const response = await callInternalApi("my/events", { store_id: storeId });
  const responseData = response.data.list.sort(
    (eventA: any, eventB: any) => eventB.last_event_at - eventA.last_event_at // NOSONAR
  );
  return responseData;
}
