import React from "react";
import { useTranslation } from "@coworker/locales";
import userPreferences from "@coworker/functions/src/enums/profilePreferences.json";
import useFlag, { FLAGS } from "../hooks/useFlag";
import { useMyStore } from "../hooks/useMyStore";
import { useUserPreference } from "../hooks/useProfilePreferencesQuery";

export function resolveCountryId(userCountryId, mystoreConfig = {}) {
  const { country_id = "" } = mystoreConfig;
  const countryCodefromMyStore = (country_id.length === 2 && country_id) || "";
  const storeLocaleConfig = mystoreConfig?.locale || {};
  return (
    countryCodefromMyStore ||
    storeLocaleConfig?.primary_locale?.slice(-2) ||
    userCountryId
  );
}

export function useSetInitialLanguage() {
  const myStoreConfig = useMyStore()?.configuration || {};
  const storeLocaleConfig = myStoreConfig?.locale || {};
  const primaryLocale = storeLocaleConfig?.primary_locale;

  const [userLanguage, , isLoading] = useUserPreference(
    userPreferences.LANGUAGE,
    primaryLocale
  );

  const { changeLanguage, language: activeLang = "" } = useTranslation();
  const blablaLanguage = useFlag(FLAGS.BLABLA_LANGUAGE);

  React.useEffect(() => {
    if (blablaLanguage) {
      changeLanguage("blabla");
    } else if (!isLoading && userLanguage !== activeLang) {
      changeLanguage(userLanguage);
    }
  }, [changeLanguage, activeLang, blablaLanguage, userLanguage, isLoading]);
}
