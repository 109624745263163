// Helper to wrap multi-parameter filter functions.
export const wrap = (f, ...rest) => f && ((task) => f(task, ...rest));

/**
 * returns a number being the sum of calculated values
 */
export const sumOver = (map, calculator) => {
  if (!map) return 0;
  let sum = 0;
  Object.values(map).forEach((item) => {
    sum += calculator(item) || 0;
  });
  return sum;
};
