import React from "react";
import styled from "styled-components";
import { SubHeader, SubHeaderProps } from "./SubHeader";

const CardWrapper = styled.div<{ background: string }>`
  background: ${({ background }) => background};
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SubHeaderCard = ({
  title,
  colorSchema,
  fontSize,
}: SubHeaderProps) => {
  return (
    <CardWrapper background={colorSchema.background}>
      <SubHeader title={title} fontSize={fontSize} colorSchema={colorSchema} />
    </CardWrapper>
  );
};
