import { useQuery } from "@tanstack/react-query";
import { getTaskLinksByRoom } from "../services/tasks.service";

export const useTaskLinksByRoom = (roomId: string | undefined) => {
  return useQuery({
    queryKey: ["taskLinksByRoom", roomId],
    queryFn: () => getTaskLinksByRoom(roomId || ""),
    enabled: !!roomId,
  });
};
