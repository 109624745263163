import React from "react"; // importing FunctionComponent
import styled from "styled-components";
import { Criterion, CriteriaState } from "@coworker/types";
import { sortCriteriaDisplayOrder } from "../../ArticleDetailPage/CriterionComponent/CriteriaHelper";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding-left: 5rem;
`;

const ProgressBarTaskIndicator = styled.div`
  height: 0.5rem;
  align-items: center;
  height: 5px;
  width: calc(
    100% /
      ${(props: { numberOfCriteria: number; criteriaState: CriteriaState }) =>
        props.numberOfCriteria}
  );
  &:first-child {
    border-radius: 5px 0 0 5px;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  background-color: ${(props: {
    numberOfCriteria: number;
    criteriaState: CriteriaState;
  }) =>
    props.criteriaState === "NOT_ANSWERED"
      ? "var(--grey100)"
      : props.criteriaState === "OK"
      ? "var(--success)"
      : "var(--error)"};
`;

const ProgressBarCounter = styled.div`
  padding-left: 1rem;
  width: 3rem;
  color: var(--grey600);
  font-size: 12px;
`;

const formatAnsweredCriteria = (criteria: Criterion[]): string => {
  const answeredCriteria = criteria?.filter(
    (criterion) => criterion.state !== "NOT_ANSWERED"
  ).length;
  return `${answeredCriteria} / ${criteria?.length}`;
};

export const TestBuyArticleProgressBar = ({
  criteriaArray,
}: {
  criteriaArray: Criterion[];
}) => {
  const sortedCriteria = sortCriteriaDisplayOrder(criteriaArray);
  return (
    <Container>
      <ProgressBarContainer>
        {criteriaArray?.map((criterion) => {
          return (
            <ProgressBarTaskIndicator
              key={criterion.key}
              criteriaState={criterion.state}
              numberOfCriteria={sortedCriteria.length}
            />
          );
        })}
      </ProgressBarContainer>
      <ProgressBarCounter>
        {formatAnsweredCriteria(sortedCriteria)}
      </ProgressBarCounter>
    </Container>
  );
};
