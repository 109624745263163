import React from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";
import { useLocation } from "react-router-dom";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/tabs/dist/style.css";
import "@ingka/focus/dist/style.css";

export function SkapaTabs({ tabs, active, panels }) {
  return (
    <Tabs
      style={{ paddingRight: "40px", paddingLeft: "30px" }}
      tabs={tabs}
      tabPanels={panels}
      activeTab={active}
    />
  );
}
export function SkapaTabPanel({ tabKey, children }) {
  return (
    <TabPanel style={{ paddingTop: 3 }} key={tabKey} tabPanelId={tabKey}>
      {children}
    </TabPanel>
  );
}

export function SkapaTab({
  tabKey,
  title,
  testId = "testId1",
  onTabChanged,
  setChecked,
}) {
  const pathname = useLocation().pathname;
  return (
    <div
      style={{
        paddingRight: "15px",
      }}
    >
      <Tab
        text={title}
        onClick={() => {
          onTabChanged({
            area: pathname === "/testbuy/new" ? "" : "whole=whole",
            div: tabKey,
          });
          pathname !== "/testbuy/new" &&
            setChecked({ type: tabKey, item: "whole=whole" });
        }}
        tabPanelId={tabKey}
        data-testid={testId}
      />
    </div>
  );
}
