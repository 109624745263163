import React from "react";
import useCallSIK, { SIK_SEARCH, SIK_SEARCH_MORE } from "./API/useCallSIK";
import { parseSearchProduct, parseProduct } from "../services/products.service";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { useLanguage } from "../core/hooks/useLanguage";
import {
  useTasksServiceMFSSearch,
  useCallMFSSearchProducts,
} from "./API/MFS/useCallMFSSearchProducts";
import { INTERVAL } from "../constants/clientTime";
import { useFetchedBasicProduct } from "./useBasicProductsMap";
import useFlag, { FLAGS } from "../hooks/useFlag";

const sortProductByTitle = (a, b) => a.title.localeCompare(b.title);

const defaultState = {
  loading: false,
  hideSPR: false,
  data: [],
  page: 1,
  total: 0,
  totalSIK: 0,
  totalMFS: 0,
  dupesSIK: 0,
  dupesMFS: 0,
  hiddenSIK: 0,
  hiddenMFS: 0,
  token: "",
};

function guesstimateTotal(state, extra) {
  return Math.max(extra, state.total, state.totalMFS, state.totalSIK);
}

const paginationReducer = (state, action) => {
  const alreadyListed = (query) => state.data.find(({ key }) => key === query);
  // skip SPR articles if necessary
  const shouldHide = (row) => row?.type === "SPR" && state?.hideSPR;

  switch (action.type) {
    case "numericSR": {
      const { basicProduct } = action;
      const key = basicProduct.type + basicProduct.id;
      // Do nothing if item is already listed from other search sources.
      if (shouldHide(basicProduct) || alreadyListed(key)) return state;

      const [kindAndColor, measurements] = basicProduct.descriptives || [];
      const row = {
        key,
        title: basicProduct.name,
        kindAndColor,
        measurements,
        image: basicProduct.smallImage,
        fromSR: true,
      };

      return { ...state, loading: false, data: [...state.data, row] };
    }

    case "moreMFS": {
      const data = [...state.data];
      let { dupesMFS, hiddenMFS } = state;
      for (const row of action.data || []) {
        if (shouldHide(row)) hiddenMFS++;
        else {
          const listed = alreadyListed(row.key);
          if (!listed) data.push({ ...row, fromMFS: true });
          else if (!listed.fromMFS) dupesMFS++;
        }
      }
      const totalMFS = action.totalMFS || state.totalMFS;
      const total = guesstimateTotal(state, totalMFS);
      return {
        ...state,
        loading: false,
        data,
        dupesMFS,
        hiddenMFS,
        totalMFS,
        total,
      };
    }
    case "moreSIK": {
      const data = [...state.data];
      let { dupesSIK, hiddenSIK } = state;
      for (const row of action.data || []) {
        if (shouldHide(row)) hiddenSIK++;
        else {
          const listed = alreadyListed(row.key);
          if (!listed) data.push({ ...row, fromSIK: true });
          else if (!listed.fromSIK) dupesSIK++;
        }
      }
      const totalSIK = action.totalSIK || state.totalSIK;
      const total = guesstimateTotal(state, totalSIK);
      return {
        ...state,
        loading: false,
        data,
        dupesSIK,
        hiddenSIK,
        totalSIK,
        total,
        token: action.token,
      };
    }
    case "reset": {
      return {
        ...defaultState,
        hideSPR: action.hideSPR,
        loading: action.loading,
      };
    }
    case "loadnewpage": {
      return {
        ...state,
        hideSPR: action.hideSPR,
        page: state.page + 1,
      };
    }
    default:
      return state;
  }
};
// helper function for dispatching events
const moreMFS = (data, totalMFS) => ({ type: "moreMFS", data, totalMFS });
const numericSR = (basicProduct) => ({ type: "numericSR", basicProduct });
const moreSIK = (data, totalSIK, token) => ({
  type: "moreSIK",
  data,
  totalSIK,
  token,
});
const reset = (nonEmptyQuery, hideSPR) => ({
  type: "reset",
  hideSPR,
  loading: nonEmptyQuery,
});
const loadNewPage = (hideSPR) => ({ type: "loadnewpage", hideSPR });

const emptyList = [];
/**
 * @returns {Array<Object>} A list of products to be parsed by `parseSearchProduct.fromSIK`
 */
function collectItemsSIK(data, page) {
  const base = page > 1 ? data?.more : data?.searchResultPage?.products.main;
  const items = [];
  // Since the /more with token was implemented, you might get results that aren't type product, at least if you somehow miss to include the `types=` parameter in a /more -request.
  for (const item of base?.items || []) {
    if (item?.product) items.push(item?.product);
  }
  return items.length ? items : emptyList;
}

/**
 * @returns {String} A token for requesting the next page of search results.
 */
function returnSIKmoreToken(SIKResponse) {
  const data = SIKResponse?.data || {};
  const base = data?.searchResultPage?.products?.main || data?.more;
  return base?.moreToken;
}

const RESULT_LIMIT_PER_PAGE = 10;
const productNumberRE = /^\s*(?:s|ART|SPR)\s*(\d\d\d)\.?(\d\d\d)\.?(\d\d)\s*/;

/**
 * Searches SIK only if query includes a space (" ")
 *
 *  - If a user searches a partial product name/id the SIK endpoint is used
 *  - If a user searches a partial product name/id and selects an autocomplete
 * option that does not exactly match the search input use SIK endpoint
 *  - If a user searches a full product name (eg.: FIXA or BILLY or 50205626)
 * and select an autocomplete option that matches the input it searches MFS
 * endpoint
 *  - If no results from autocomplete use MFS search
 */
export function useMixedSearchProducts(articleId = "", options) {
  const [state, dispatch] = React.useReducer(paginationReducer, defaultState);
  const store_id = useStoreId();
  const language = useLanguage();

  const { blockRequest, includeNumberSearch } = options;
  const match = articleId.match(productNumberRE);
  const query = match ? match.slice(1).join("") : articleId;
  const [short_id] = parseProduct(query);
  const queryLength = query.length * (language === "zh_CN" ? 3 : 1);

  const hideSPR = options?.hideSPR;

  const callTasksService = window.callTasksService;

  const blockMFS =
    useFlag(FLAGS.DISABLE_MFS_SEARCH) || query.includes(" ") || blockRequest;

  const MSFResponseFromTasksService = useTasksServiceMFSSearch(
    query,
    !blockMFS && callTasksService
  );

  const MFSResponseFromFirestore = useCallMFSSearchProducts(
    { query, page: state.page, store_id },
    {
      ...options,
      blockRequest: blockMFS || queryLength < 3 || callTasksService,
    }
  );

  // TODO: Once Tasks Service is live in all regions remove call to old backend.
  const MFSResponse = callTasksService
    ? MSFResponseFromTasksService
    : MFSResponseFromFirestore;

  const { token } = state;
  const blockSIK = queryLength < 2 || blockRequest;
  const SIKResponse = useCallSIK(
    state.page === 1 ? SIK_SEARCH : SIK_SEARCH_MORE,
    {
      q: query,
      range: "ALL",
      ...(state.page === 1
        ? { size: RESULT_LIMIT_PER_PAGE }
        : { size: RESULT_LIMIT_PER_PAGE, token }),
      types: "PRODUCT",
    },
    { ...options, blockRequest: blockSIK || (state.page > 1 && !state.token) }
  );

  const { basicProduct } = useFetchedBasicProduct(
    includeNumberSearch && short_id
  );

  const loading =
    (SIKResponse.loading && !SIKResponse.isBlocked) ||
    (!blockMFS && MFSResponse.loading && !MFSResponse.isBlocked);

  React.useEffect(() => {
    dispatch(reset(query !== "", hideSPR));
  }, [query, hideSPR]);

  React.useEffect(() => {
    const firstTotal = SIKResponse.data?.searchResultPage?.products?.badge; // Needed once per search
    const token = returnSIKmoreToken(SIKResponse);
    const items = collectItemsSIK(SIKResponse.data, state.page);
    const products = parseSearchProduct.fromSIK(store_id, language, items);
    if (products?.length) dispatch(moreSIK(products, firstTotal, token));
    // Ignored: SIKResponse object to avoid looping
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SIKResponse.data, language, store_id, state.page]);

  React.useEffect(() => {
    if (!blockMFS) {
      const total = MFSResponse?.data?.total;
      const items = MFSResponse?.data?.items;
      const products = parseSearchProduct.fromMFS(store_id, language, items);
      if (products?.length)
        dispatch(moreMFS(products.sort(sortProductByTitle), total));
    }
    // Ignored: MFSResponse object to avoid looping
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MFSResponse.data, language, store_id, state.page]);

  React.useEffect(() => {
    if (basicProduct?.type) dispatch(numericSR(basicProduct));
  }, [basicProduct]);

  return {
    ...state,
    loading,
    RESULT_LIMIT_PER_PAGE,
    nextPage: () => dispatch(loadNewPage(hideSPR)),
  };
}

function toBasicProduct(fromSIK) {
  const { key, type, title, kindAndColor, measurements, image } = fromSIK;
  const descriptives = [kindAndColor, measurements];
  const [id] = parseProduct(key);
  return { key, id, type, name: title, descriptives, smallImage: image };
}

/**
 * @param {string} shortId Search query
 * @param {number} cacheTimeout milliseconds
 * @returns {[product:object, loading:boolean]}
 */
export function useBasicProductFromSIK(
  shortId,
  cacheTimeout = 8 * INTERVAL.HOUR
) {
  const store_id = useStoreId();
  const language = useLanguage();
  const blockRequest = !shortId || shortId?.length < 7;
  const { data, loading } = useCallSIK(
    SIK_SEARCH,
    { q: shortId, size: 1, types: "PRODUCT", range: "ALL" },
    { blockRequest, cacheTimeout }
  );
  return React.useMemo(() => {
    if (blockRequest) return;
    const items = collectItemsSIK(data, 1);
    const parsedItems = parseSearchProduct.fromSIK(store_id, language, items);
    const found = parsedItems.find((parsedItem) =>
      [parsedItem?.id, parsedItem?.key].join(",").includes(shortId)
    );
    return !loading && found && toBasicProduct(found);
  }, [shortId, data, language, store_id, blockRequest, loading]);
}
