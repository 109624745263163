import React from "react";
import Accordion, { AccordionItem } from "@ingka/accordion";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/accordion/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/collapsible/dist/style.css";

function SkapaAccordionItem({
  key,
  id,
  title,
  onHeadingClicked,
  children,
  testId = () => {},
}) {
  return (
    <AccordionItem
      key={key}
      id={id}
      title={title}
      onHeadingClicked={onHeadingClicked}
      children={children}
      data-testid={testId}
    />
  );
}

function SkapaAccordion({ items }) {
  return <Accordion padded={true} children={items} />;
}

export { SkapaAccordion, SkapaAccordionItem };
