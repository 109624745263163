import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import styled from "styled-components";

export const PaddedContainer = styled.div`
  display: flex;
  padding: 50px 0px;
  justify-content: center;
`;

interface RoomsViewNoSearchResultProps {
  query: string;
}

export function RoomsViewNoSearchResult({
  query,
}: RoomsViewNoSearchResultProps) {
  const { t } = useTranslation();

  return (
    <PaddedContainer>
      <Text text={`${t("noSearchResultsString", { query })}`} bold />
    </PaddedContainer>
  );
}
