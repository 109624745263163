/**
 * @param {BrowsingModeDetector} BrowsingModeDetector
 * @returns {MozillaBrowser}
 * @constructor
 */
var MozillaBrowser = function (BrowsingModeDetector) {
  this.BrowsingModeDetector = BrowsingModeDetector;

  this.detectBrowsingMode = function (_executeUserCallback) {
    var self = this;

    var callbackWhenIndexedDBSuccess = function () {
      self.BrowsingModeDetector.setBrowsingInNormalMode();
      _executeUserCallback();
      return;
    };

    var callbackWhenIndexedDBError = function () {
      self.BrowsingModeDetector.setBrowsingInIncognitoMode();
      _executeUserCallback();
      return;
    };

    let indexedDB =
      window.indexedDB ||
      window.mozIndexedDB ||
      window.webkitIndexedDB ||
      window.msIndexedDB;
    if (indexedDB) {
      var req = indexedDB.open("test");
      req.onerror = function (event) {
        callbackWhenIndexedDBError();
      };
      req.onsuccess = function (event) {
        callbackWhenIndexedDBSuccess();
      };
    }
  };

  return this;
};

/**
 * @param {BrowsingModeDetector} BrowsingModeDetector
 * @returns {SafariBrowser}
 * @constructor
 */
var SafariBrowser = function (BrowsingModeDetector) {
  this.BrowsingModeDetector = BrowsingModeDetector;

  this.detectBrowsingMode = function (_executeUserCallback) {
    // From https://github.com/jLynx/PrivateWindowCheck
    if (window.safariIncognito) {
      this.BrowsingModeDetector.setBrowsingInIncognitoMode();
      _executeUserCallback();
      return;
    }
    // iOS 11
    // From gist discussion: https://gist.github.com/cou929/7973956#gistcomment-2272103 and
    // https://github.com/jLynx/PrivateWindowCheck
    try {
      window.openDatabase(null, null, null, null);
      window.localStorage.setItem("test", 1); // NOTE: We want this to throw and catch on iOS11.
    } catch (e) {
      this.BrowsingModeDetector.setBrowsingInIncognitoMode();
      _executeUserCallback();
      return;
    }

    // Older Safari
    try {
      if (!localStorage.length) {
        localStorage.i = 1;
        localStorage.removeItem("i"); // NOTE: We want this to throw and catch on older Safari.
      }
      this.BrowsingModeDetector.setBrowsingInNormalMode();
    } catch (e) {
      // From: https://gist.github.com/jherax/a81c8c132d09cc354a0e2cb911841ff1
      // Safari only enables cookie in private mode
      // if cookie is disabled then all client side storage is disabled
      // if all client side storage is disabled, then there is no point
      // in using private mode
      if (navigator.cookieEnabled) {
        this.BrowsingModeDetector.setBrowsingInIncognitoMode();
      } else {
        this.BrowsingModeDetector.setBrowsingInNormalMode();
      }
    }

    _executeUserCallback();
    return;
  };

  return this;
};

/**
 * @param {BrowsingModeDetector} BrowsingModeDetector
 * @returns {IE10EdgeBrowser}
 * @constructor
 */
var IE10EdgeBrowser = function (BrowsingModeDetector) {
  this.BrowsingModeDetector = BrowsingModeDetector;

  this.detectBrowsingMode = function (_executeUserCallback) {
    if (window.indexedDB) {
      this.BrowsingModeDetector.setBrowsingInNormalMode();
    } else {
      this.BrowsingModeDetector.setBrowsingInIncognitoMode();
    }
    _executeUserCallback();
  };

  return this;
};

/**
 * @param {BrowsingModeDetector} BrowsingModeDetector
 * @returns {OtherBrowser}
 * @constructor
 */
var OtherBrowser = function (BrowsingModeDetector) {
  this.BrowsingModeDetector = BrowsingModeDetector;

  this.detectBrowsingMode = function (_executeUserCallback) {
    this.BrowsingModeDetector.setBrowsingInNormalMode();
    _executeUserCallback();
  };

  return this;
};

/**
 * @param {BrowsingModeDetector} BrowsingModeDetector
 * @returns {WebkitBrowser}
 * @constructor
 */
var WebkitBrowser = function (BrowsingModeDetector) {
  this.BrowsingModeDetector = BrowsingModeDetector;

  this.detectBrowsingMode = function (_executeUserCallback) {
    var self = this;
    var callbackWhenWebkitTemporaryStorageQuotaNotLimited = function () {
      self.BrowsingModeDetector.setBrowsingInNormalMode();
      _executeUserCallback();
      return;
    };

    var callbackWhenWebkitTemporaryStorageQuotaLimited = function () {
      self.BrowsingModeDetector.setBrowsingInIncognitoMode();
      _executeUserCallback();
      return;
    };

    var checkWebkitTemporaryStorageQuota = function () {
      if (
        navigator.webkitTemporaryStorage &&
        navigator.webkitTemporaryStorage.queryUsageAndQuota
      ) {
        navigator.webkitTemporaryStorage.queryUsageAndQuota(
          function (usedBytes, grantedBytes) {
            // There’s a hard limit of 110MB while this is not the case for non-incognito window
            if (grantedBytes < 11e7) {
              callbackWhenWebkitTemporaryStorageQuotaLimited();
            } else {
              callbackWhenWebkitTemporaryStorageQuotaNotLimited();
            }
          },
          function (e) {
            callbackWhenWebkitTemporaryStorageQuotaNotLimited();
          }
        );
      } else {
        callbackWhenWebkitTemporaryStorageQuotaNotLimited();
      }
    };

    var callbackWhenWebkitStorageQuotaNotLimited = function () {
      checkWebkitTemporaryStorageQuota();
      return;
    };

    var callbackWhenWebkitStorageQuotaLimited = function () {
      self.BrowsingModeDetector.setBrowsingInIncognitoMode();
      _executeUserCallback();
      return;
    };

    var checkStorageQuota = function () {
      if (navigator.storage && navigator.storage.estimate) {
        navigator.storage.estimate().then(function (estimate) {
          // There’s a hard limit of 120MB while this is not the case for non-incognito window
          if (estimate.quota < 12e7) {
            callbackWhenWebkitStorageQuotaLimited();
          } else {
            callbackWhenWebkitStorageQuotaNotLimited();
          }
        });
      } else {
        callbackWhenWebkitStorageQuotaNotLimited();
      }
    };

    var callbackWhenWebkitRequestFileSystemIsON = function () {
      checkStorageQuota();
    };

    var callbackWhenWebkitRequestFileSystemIsOFF = function () {
      self.BrowsingModeDetector.setBrowsingInIncognitoMode();
      _executeUserCallback();
    };

    window.webkitRequestFileSystem(
      window.TEMPORARY,
      1,
      callbackWhenWebkitRequestFileSystemIsON,
      callbackWhenWebkitRequestFileSystemIsOFF
    );
  };

  return this;
};

/**
 * @returns {BrowserFactory}
 * @constructor
 */
var BrowserFactory = function () {
  var _browser;

  /**
   * @param {BrowsingModeDetector} BrowsingModeDetector
   * @returns {*}
   * @private
   */
  var _resolve = function (BrowsingModeDetector) {
    if (
      /constructor/i.test(window.HTMLElement) ||
      (navigator.vendor && navigator.vendor.indexOf("Apple") > -1)
    ) {
      return new SafariBrowser(BrowsingModeDetector);
    } else if ("MozAppearance" in document.documentElement.style) {
      return new MozillaBrowser(BrowsingModeDetector);
    } else if (window.webkitRequestFileSystem) {
      return new WebkitBrowser(BrowsingModeDetector);
    } else if (window.PointerEvent || window.MSPointerEvent) {
      return new IE10EdgeBrowser(BrowsingModeDetector);
    } else {
      return new OtherBrowser(BrowsingModeDetector);
    }
  };

  this.browser = function (BrowsingModeDetector) {
    if (typeof _browser === "object") {
      return _browser;
    }

    return (_browser = _resolve(BrowsingModeDetector));
  };

  return this;
};

/**
 * BrowsingModeDetector
 *
 * @returns {BrowsingModeDetector}
 * @constructor
 * @author Maykonn Welington Candido <maykonn@outlook.com>
 */
function BrowsingModeDetector() {
  var _browsingInIncognitoMode;
  var _callbackDefault; // if given, will be executed even _callbackForNormalMode and _callbackForIncognitoOrPrivateMode are given

  this.BROWSING_NORMAL_MODE = "NORMAL_MODE";
  this.BROWSING_INCOGNITO_PRIVATE_MODE = "INCOGNITO_PRIVATE_MODE";

  this.setBrowsingInIncognitoMode = function () {
    _browsingInIncognitoMode = true;
  };

  this.setBrowsingInNormalMode = function () {
    _browsingInIncognitoMode = false;
  };

  var _executeUserCallback = function () {
    _callbackDefault(_browsingInIncognitoMode);
  };

  /**
   * @param defaultCallback optional
   */
  this.do = function (defaultCallback) {
    _callbackDefault = defaultCallback;
    new BrowserFactory().browser(this).detectBrowsingMode(_executeUserCallback);
  };

  return this;
}

export async function isIncognito() {
  var bmd = new BrowsingModeDetector();
  return new Promise((resolve) =>
    bmd.do((isIncognito) => resolve(isIncognito))
  );
}
