import React from "react";
import styled, { css } from "styled-components";
import { ColorSchema } from "../constants";
export interface SubHeaderProps {
  title: JSX.Element;
  colorSchema: ColorSchema;
  fontSize: number;
}

const StyledHeader = styled.div<{ fontSize: number; color: string }>`
  ${(props) =>
    css`
      font-size: ${props.fontSize}px;
    `}
  ${(props) =>
    css`
      color: ${props.color};
    `}
  margin-left: 16px;
  font-weight: 700;
`;

export const SubHeader = ({ title, fontSize, colorSchema }: SubHeaderProps) => {
  return (
    <StyledHeader fontSize={fontSize} color={colorSchema.color}>
      {title}
    </StyledHeader>
  );
};
