import React from "react";
import styles from "./task-card.module.css";
import { useTranslation } from "@coworker/locales";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { defaultTimezone } from "@coworker/app/src/constants/clientTime";

dayjs.extend(utc);
dayjs.extend(timezone);

export const CreatedAtDate = ({ createdAt }: { createdAt: number }) => {
  const { t } = useTranslation();

  const utcDate = dayjs.utc(createdAt).tz(defaultTimezone);
  const currentTime = dayjs();

  const timeDifferenceMinutes = currentTime.diff(utcDate, "minutes");
  const timeDifferenceHours = currentTime.diff(utcDate, "hours");
  const timeDifferenceDays = currentTime.diff(utcDate, "days");

  const getTimeString = (
    difference: number,
    singularKey: string,
    pluralKey: string
  ) =>
    `${difference} ${difference === 1 ? t(singularKey) : t(pluralKey)} ${t(
      "agoString"
    )}`;

  const getCreatedAtTime = () => {
    if (timeDifferenceMinutes === 0) return t("justNowString");
    if (timeDifferenceMinutes < 60)
      return getTimeString(
        timeDifferenceMinutes,
        "minuteString",
        "minutesString"
      );
    if (timeDifferenceHours < 24)
      return getTimeString(timeDifferenceHours, "hourString", "hoursString");
    if (timeDifferenceDays <= 5)
      return getTimeString(timeDifferenceDays, "dayString", "daysString");

    return utcDate.format("YYYY-MM-DD HH:mm");
  };

  return <div className={styles["created-at"]}>{getCreatedAtTime()}</div>;
};
