import {
  useTeamId,
  useUID,
  useFixaUID,
} from "@coworker/app/src/core/auth/useLoggedInUser";

import { TasksFilterType } from "../types/tasksFilterType";
import { TaskTabsTypes } from "../../shared/constants/taskTabsTypes";
import { isRefillOrPickAndRefill } from "@coworker/app/src/helpers/taskFilters";
import { wrap } from "@coworker/app/src/helpers/mapHelpers";
import {
  getFilterFunction,
  userFilters,
  userLocationFilters,
  teamFilter,
  taskStatusFilter,
  taskStateFilter,
  closedReasonFilter,
} from "@coworker/app/src/helpers/taskFilters";

export const useSelectedTaskFilters = (
  taskTabType: TaskTabsTypes,
  selectedTaskFilters: TasksFilterType | undefined,
  productIdFilter?: string
) => {
  const uid = useUID();
  const team_id = useTeamId();
  const fixa_uid = useFixaUID();

  if (selectedTaskFilters) {
    const selectedTaskTypeFilter = selectedTaskFilters?.taskType;

    const selectedRelatedTaskStateFilter =
      selectedTaskFilters?.related?.taskState;

    const selectedCompletedTaskStatusFilter =
      selectedTaskFilters?.activityFilters?.completedTaskStatusType;

    const selectedClosedReasonFilter =
      selectedTaskFilters?.activityFilters?.closedReason;

    const selectedLocationNameFilter =
      selectedTaskFilters?.locationFilter?.locationGroupName;

    const selectedTeamFilter =
      selectedTaskFilters?.activityFilters.selectedTeam;

    const selectedLocationGroupFilter =
      selectedTaskFilters?.locationFilter?.locationGroup;

    const selectedAddonLocationTypeFilter =
      selectedTaskFilters?.addonLocationType;

    const isOpenOrMy =
      taskTabType === TaskTabsTypes.OPEN || taskTabType === TaskTabsTypes.MY;

    const completedTaskStatusFiltering =
      taskTabType.endsWith("completed") &&
      isRefillOrPickAndRefill(selectedTaskTypeFilter);

    const isRelated = !isOpenOrMy;

    const closedReasonFiltering = taskTabType.endsWith("closed");

    // The Activity tab there shold not be an filtering. The user has no visual clue that there is a filter in action. So, do not filter.
    if (
      window.location.href.includes("active=activity") ||
      window.location.href.includes("activity/created_by_me")
    ) {
      return [
        wrap(
          getFilterFunction(taskTabType),
          { uid, team_id, fixa_uid },
          productIdFilter
        ),
      ];
    }

    return [
      wrap(
        getFilterFunction(taskTabType),
        { uid, team_id, fixa_uid },
        productIdFilter
      ),
      wrap(userFilters, selectedTaskTypeFilter),
      wrap(
        userLocationFilters,
        selectedLocationNameFilter,
        selectedLocationGroupFilter,
        selectedAddonLocationTypeFilter,
        selectedTaskTypeFilter
      ),
      wrap(
        completedTaskStatusFiltering && taskStatusFilter,
        selectedCompletedTaskStatusFilter
      ),
      wrap(!isOpenOrMy && teamFilter, selectedTeamFilter),
      wrap(isRelated && taskStateFilter, selectedRelatedTaskStateFilter),
      wrap(
        closedReasonFiltering && closedReasonFilter,
        selectedClosedReasonFilter
      ),
    ];
  }
  return [];
};
