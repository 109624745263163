import React from "react";
import styled from "styled-components";
import { ShoppingToolsQuantityStepper } from "../Components/ShoppingToolsQuantityStepper";
import SSRIcon from "@ingka/ssr-icon";
import InlineMessage from "@ingka/inline-message";
import { Trans, useTranslation } from "@coworker/locales";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import {
  ShoppingToolType,
  TOTAL_MAX_VALUE,
  MAX_SHOWROOM_TOOLS,
} from "../constants";
import { useCreateShoppingTool } from "../Hooks/useCreateShoppingTool";
import { useDeleteShoppingTool } from "../Hooks/useDeleteShoppingTool";

interface ShowroomQuantityCardProps {
  type: ShoppingToolType;
  round: string;
  division: string;
  startValue: number;
  currentTotalValue: number;
  disableList: ShoppingToolType[];
  setCurrentTotalValue: React.Dispatch<React.SetStateAction<number>>;
}

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-left: 8px solid var(--grey200);
  height: 60px;
  padding-left: 15px;
  &:first-child {
    padding-top: 10px;
  }
  &:last-child {
    padding-bottom: 10px;
  }
`;

export const SubTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

export const InlineMessageWrapper = styled.div`
  padding-left: 15px;
  padding-bottom: 15px;
  border-left: 8px solid var(--grey200);
`;

export const ShowroomQuantityCard = ({
  type,
  round,
  division,
  startValue,
  currentTotalValue,
  setCurrentTotalValue,
  disableList,
}: ShowroomQuantityCardProps) => {
  let [currentToolValue, setCurrentToolValue] = React.useState<number>(0);
  const createShoppingToolMutation = useCreateShoppingTool();
  const deleteShoppingToolMutation = useDeleteShoppingTool();
  const storeId = useStoreId();
  const isDisabled = disableList.some((disable) => disable.name === type.name);
  React.useEffect(() => {
    setCurrentToolValue(startValue);
  }, [setCurrentToolValue, startValue]);

  const { t } = useTranslation();

  function onIncreaseToolValue() {
    currentToolValue++;
    setCurrentToolValue(currentToolValue);
    currentTotalValue++;
    setCurrentTotalValue(currentTotalValue);
  }

  function onDecreaseToolValue() {
    currentToolValue--;
    setCurrentToolValue(currentToolValue);
    currentTotalValue--;
    setCurrentTotalValue(currentTotalValue);
  }

  const isMaxAmountReached = currentToolValue === MAX_SHOWROOM_TOOLS;

  return (
    <>
      <CardWrapper key={type.label}>
        <ShoppingToolsQuantityStepper
          isDisabled={isDisabled}
          value={currentToolValue}
          small
          minVal={0}
          maxVal={TOTAL_MAX_VALUE}
          ariaDescribedById="unique-dom-id"
          ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
          onIncrease={() => {
            if (!isMaxAmountReached) {
              createShoppingToolMutation.mutateAsync({
                roundName: round,
                division: division,
                storeId: storeId,
                initialShoppingTools: { [type.name ?? ""]: 1 },
              });
              onIncreaseToolValue();
            }
          }}
          onDecrease={() => {
            deleteShoppingToolMutation.mutateAsync({
              roundName: round,
              division: division,
              storeId: storeId,
              initialShoppingTools: { [type.name ?? ""]: 1 },
            });
            onDecreaseToolValue();
          }}
        />
        <SSRIcon paths={type.icon!} style={{ margin: "0px 10px" }} />
        <SubTitle style={{ margin: !type.icon ? "0px 10px" : 0 }}>
          <Trans>{type.label}</Trans>
        </SubTitle>
      </CardWrapper>
      {isMaxAmountReached && (
        <InlineMessageWrapper>
          <InlineMessage
            variant="cautionary"
            subtle
            body={t("maxToolAmountReachedString")}
          />
        </InlineMessageWrapper>
      )}
    </>
  );
};
