import React from "react";
import { Trans } from "@coworker/locales";
import { Container, OverflowBackground } from "./CommonComponents";
import { Input } from "@coworker/components";
import { TitleText, StyledButton, InputWrapper } from "./MFAQresolution";

const MFAQeditQuestion = (props) => {
  const [value, setValue] = React.useState(props?.questionText);

  return (
    <>
      <OverflowBackground onClick={props.onClose} />
      <Container>
        <TitleText>
          <Trans>mfaqEditQuestionString</Trans>
        </TitleText>
        <InputWrapper>
          <Input
            testId="mfaqEditQuestionInput"
            name="editQuestionInput"
            autoFocus
            value={value}
            onChange={(e) => setValue(e)}
          ></Input>
        </InputWrapper>
        <StyledButton
          text={<Trans>saveString</Trans>}
          onClick={(event) => {
            event.preventDefault();
            props.onSubmit(value);
          }}
        ></StyledButton>
      </Container>
    </>
  );
};

export default MFAQeditQuestion;
