import React from "react";
import { clean } from "../helpers/string.helpers";

export function Highlighter({ text, regexp }) {
  if (!regexp) return <>{text}</>;
  if (!text?.trim) return "";
  const baseText = text.trim();

  let from = 0;
  const takeChars = (count) => baseText.slice(from, (from += count));

  const fragments = [];
  const matchableText = clean(text);
  matchableText.replace(regexp, (match, offset) => {
    if (from < offset) fragments.push(<>{takeChars(offset - from)}</>);
    fragments.push(<b key={offset + text}>{takeChars(match.length)}</b>);
  });
  fragments.push(<>{takeChars(baseText.length)}</>);

  return fragments;
}
