import { useStoreUsers } from "../hooks/useStoreContacts.ts";
import statusTypes from "@coworker/functions/src/enums/statusTypes";
import { useStoreTeams } from "../core/hooks/useStoreTeams";

export function useActiveUsers() {
  return useStoreUsers().filter((user) => user.status === statusTypes.ACTIVE);
}

export function useNameFormatter() {
  const users = useActiveUsers();
  const teams = useStoreTeams();

  const teamNameFor = (gid) => {
    const team = teams.find((team) => team.id === gid);
    return team?.name || "?";
  };
  // TODO: This is showing the _current_ team name, but the user may have changed teams in-between, so we would relly want to have a stored team_id instead, so we can stop using this function.
  const uidToTeamName = (uid) => {
    const user = users.find(
      (user) => user.fixa_uid === uid || user.uid === uid
    );
    const team = teams.find((team) => team.id === user.team_id);
    return team?.name || "?";
  };

  return { teamNameFor, uidToTeamName };
}
