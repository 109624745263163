import React from "react";
import BlueLink, { IndividualRightsLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Fixaプライバシーステートメント</div>,
  aboutFixaText: (
    <div>
      Fixaは、イケア店舗のコワーカー向けのタスク管理およびマーチャンダイジングツールです。Fixaアプリケーションの目的は、手作業、紙またはExcelベースの作業から切り替えて、イケア店舗でのタスク管理を合理化することです（商品の補充、かたづけ、問題への対応、テスト購入、もっともよくある質問など）。
      <br />
      Fixaでは、完了したタスクから得た洞察を通じての分析も可能です。
      <br />
      <br />
      Fixaを使用すると、Ingkaグループの会社のデータ管理者（下記のように定義されます）があなたに関する特定の個人情報を収集します。
    </div>
  ),
  personalDataCollectedTitle: <div>収集される個人情報：</div>,
  personalDataCollectedText: (
    <div>
      Fixaを使用すると、システムにより、コワーカーデータベース（CDS）からあなたに関する次のような情報が収集されます：名、ミドルネーム、姓、Eメール、職位、役割、電話番号（手動で入力した場合
      -
      個人の電話番号は入力しないでください）、CDSからの写真、アプリでの優先言語、ストアID、カントリーID、デバイスのバージョンおよびオペレーティングシステム、Google
      Cloud
      PlatformおよびSentryで処理されたIPアドレス（Sentryは、アプリケーションエラートラッキングソフトウェアです）。
      <br />
      <br />
      コワーカーのプロフィールは、Fixa-admin（管理ウェブアプリ）を通じてマネジャー/スーパーユーザーによって手動で作成されます。
      <br />
      個人情報はCDSから取得されるため、プロフィールは、アクティブなCDSプロフィールを持つコワーカーに対してのみ作成されます。
    </div>
  ),
  purposeOfDataCollectionTitle: <div>データ収集の目的：</div>,
  purposeOfDataCollectionText: (
    <div>
      Fixaでの個人情報の収集の目的は、アプリケーションがコワーカー間の容易な社内コミュニケーションを可能にする方法で適切に機能し、イケア店舗のコワーカー間のタスクを管理するためです。
      <br />
      <br />
      タスクをユーザーに関連付けると、アサインされたユーザーおよびクリエイターのタスクに関するアップデート情報を通知できるようになります。
    </div>
  ),
  legalGroundsTitle: <div>データ処理の法的根拠： </div>,
  legalGroundsText: (
    <div>
      データ処理の法的根拠は、同意に基づくもの。データ対象者が自分の個人データの処理に同意していること。
    </div>
  ),
  dataWillBeStoredTitle: <div>データの保存期間： </div>,
  dataWillBeStoredText: (
    <div>
      イケアは、完了済みタスクをユーザーへの参照付きで110日間保存します。その後、それらのタスクは匿名化されます。
      <br />
      コワーカーの個人情報は、雇用契約の期間中、Fixaに保存されます。
      <br />
      <br />
      Ingkaグループとの雇用契約の終了：
      <br />
      CDSプロフィールが削除されて1カ月以内に、すべての個人情報が自動的に削除されます。
      <br />
      <br />
      Ingkaグループ内の役割の変更：
      <br />
      コワーカーがFixaの使用を必要としなくなった場合、アクティビティを行わなくなって3カ月以内にすべての個人情報が自動的に削除されます。
      <br />
      <br />
      個人情報は、地域の情報セキュリティ・データプライバシーリーダーまたは店舗マネジャーへリクエストすることで、任意の時点で削除することもできます。
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>この個人情報処理のデータ管理者：</div>
  ),
  dataControllerForPersonalDataText: <div>イケア・ジャパン </div>,
  rightToPersonalInformationText: (
    <div>
      あなたには、ご自身の個人情報に関して一定の権利があります。そうした権利について詳しくは、
      <IndividualRightsLink />
      を参照してください。
      <br />
      個人の権利リクエスト:
      <br />
      <BlueLink href="https://iweof.sharepoint.com/:w:/r/teams/o365g_all_retjpso/_layouts/15/Doc.aspx?sourcedoc=%7BE831B0F4-8A2B-4188-B133-E388902BB023%7D&file=Data%20Privacy%20and%20individual%20rights%20JP.docx&action=default&mobileredirect=true">
        https://iweof.sharepoint.com&#8203;/:w:&#8203;/r&#8203;/teams&#8203;/o365g_all_retjpso&#8203;/_layouts&#8203;/15&#8203;/Doc.aspx?&#8203;sourcedoc=&#8203;%7BE831B0F4-8A2B-4188-B133-E388902BB023%7D&#8203;&file=Data%20&#8203;Privacy%20&#8203;and%20&#8203;individual%20&#8203;rights%20JP.docx&#8203;&action=default&#8203;&mobileredirect=true
      </BlueLink>
      <br />
      連絡先情報：P&Cマネージャー
    </div>
  ),
};
