import React, { useContext } from "react";

import { useTranslation } from "@coworker/locales";

import { ModalContext } from "../../context/ModalContextProvider";
import { ModalType } from "../../context/modalType";
import ListView, { ListViewItem } from "@ingka/list-view";
import Modal, { Sheets, ModalHeader } from "@ingka/modal";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import { useInputPopup } from "@coworker/app/src/components/InputPopup";
import { useProductQualityTeamId } from "@coworker/app/src/hooks/useProductQualityTeamId";
import { TaskTypesRoutes } from "../../../home/constants/taskTypesRoutes";

import {
  NavigationItemsConfig,
  NavigationItem,
} from "./constants/navigationItemsConfig";

import styles from "./main-navigation.module.css";
import { FixaWindow } from "@coworker/reusable";

const MainNavigation = () => {
  const { push, setNavigationState } = useWorkspacesAction();
  const { t } = useTranslation();
  const { modalState, toggleModal } = useContext(ModalContext);
  const qualityTeamId = useProductQualityTeamId();

  const { getInput } = useInputPopup();

  const handleNavigationLinkClick = async (type: string) => {
    if (type !== TaskTypesRoutes.CUSTOM) {
      setNavigationState({
        search: true,
        type,
      });
      const product = await getInput("scansearch", { start: new Date() });
      if (product) {
        push(`/task/new/${type}`, { product, qualityTeamId });
      }
    } else {
      push(`/task/new/${type}`, { qualityTeamId });
    }
  };

  const handleToggleMainNavigation = () => {
    toggleModal({ type: ModalType.MainNavigation });
  };

  const hideForGermanyUsers = (window as FixaWindow).hideForGermanyUsers;

  const navigationItems = hideForGermanyUsers
    ? NavigationItemsConfig.filter(
        (item) =>
          !["test-buy", "room-setting", "product-quality-report"].includes(
            item.id
          )
      )
    : NavigationItemsConfig;

  // Load saved order indexes from localStorage
  const savedOrderIndexes = localStorage.getItem("navigationOrderIndexes");
  if (savedOrderIndexes) {
    const parsedOrderIndexes = JSON.parse(savedOrderIndexes);
    navigationItems.forEach((item) => {
      const savedOrderIndex = parsedOrderIndexes[item.id];
      if (savedOrderIndex !== undefined) {
        item.orderIndex = savedOrderIndex;
      }
    });
  }

  // Increment order index for the clicked element and save it to localStorage
  const updateNavigationLinkOrder = (id: string) => {
    const item = navigationItems.find((item) => item.id === id);
    if (item) {
      item.orderIndex++;
      localStorage.setItem(
        "navigationOrderIndexes",
        JSON.stringify(
          navigationItems.reduce((acc: { [key: string]: number }, item) => {
            acc[item.id] = item.orderIndex;
            return acc;
          }, {})
        )
      );
    }
  };

  const handleItemClick = async (item: NavigationItem) => {
    handleToggleMainNavigation();
    if (item.href) {
      push(item.href);
    }

    if (item.taskType) {
      handleNavigationLinkClick(item.taskType);
    }

    updateNavigationLinkOrder(item.id);
  };

  return (
    <Modal
      visible={modalState.isMainNavigationOpen}
      keepContentMounted={true}
      handleCloseBtn={handleToggleMainNavigation}
    >
      <Sheets
        header={null}
        footer={null}
        preserveAlignment={false}
        size="small"
        alignment="left"
      >
        <ModalHeader
          title={t("Menu")}
          floating={false}
          closeBtnClick={handleToggleMainNavigation}
        />
        <ListView id="main-navigation" size="small">
          {navigationItems
            .sort((a, b) => b.orderIndex - a.orderIndex)
            .map((item) => (
              <div
                className={styles["list-view-item-wrapper"]}
                key={item.id}
                onClick={() => handleItemClick(item)}
              >
                <ListViewItem
                  key={item.id}
                  emphasised
                  id={item.id}
                  leadingIcon={item.icon}
                  title={t(item.labelTranslationKey)}
                  className={styles["list-view-item"] || ""}
                  size="small"
                />
              </div>
            ))}
        </ListView>
      </Sheets>
    </Modal>
  );
};

export default MainNavigation;
