import { Criterion } from "@coworker/types";
import { CriterionKey } from "@coworker/types/lib/testbuy";

export function sortCriteriaDisplayOrder(criteria: Criterion[]): Criterion[] {
  return criteria.sort(
    (first, second) =>
      sortingIndexValue(first.key) - sortingIndexValue(second.key)
  );
}

export function sortCriteriaKeyDisplayOrder(
  criteriaKey: CriterionKey[]
): CriterionKey[] {
  return criteriaKey.sort(
    (first, second) => sortingIndexValue(first) - sortingIndexValue(second)
  );
}

export function sortingIndexValue(criterionKey: CriterionKey) {
  const order = [
    "IsItDisplayed",
    "IsItCleanAndPerfect",
    "DoesItHaveTag",
    "AreBuyInstructionClear",
    "IsItPlacedAndGroupInRangeArea",
    "IsItAvailableInIndicatedPosition",
  ];
  return order.indexOf(criterionKey);
}
