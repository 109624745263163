import { useMemo } from "react";
import { Room } from "../components/RoomSettings/types/room";

export const useMatchingRooms = (
  rooms: Room[] | undefined,
  query: string | undefined
) => {
  const matchingRooms = useMemo(() => {
    if (rooms && rooms.length && query) {
      return rooms.filter(
        (room) =>
          room.name.toLowerCase().includes(query.toLowerCase()) ||
          room.main_article_name_1
            .toLowerCase()
            .includes(query.toLowerCase()) ||
          room.main_article_name_2.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      return undefined;
    }
  }, [rooms, query]);

  return matchingRooms;
};
