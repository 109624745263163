import React from "react";
import { create } from "zustand";
export const RECONNECT_TIMEOUT = 4000;

interface OnlineState {
  isOnline: boolean;
  isReconnecting: boolean;
}

const useOnlineStore = create<OnlineState>(() => ({
  isOnline: navigator.onLine,
  isReconnecting: false,
}));

/**
 * Hook exposing the current state of the device network connection.
 */
export function useOnline() {
  const reconnectTimer = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    const handleOnline = () => {
      useOnlineStore.setState({ isReconnecting: true });
      reconnectTimer.current = setTimeout(
        () => useOnlineStore.setState({ isOnline: true }),
        RECONNECT_TIMEOUT
      );
    };
    const handleOffline = () => {
      clearTimeout(reconnectTimer.current);
      useOnlineStore.setState({ isReconnecting: false });
      useOnlineStore.setState({ isOnline: false });
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  return useOnlineStore();
}
