import React from "react";
import styled from "styled-components";
import { useGivenName } from "../../core/auth/useLoggedInUser";
import { Trans } from "@coworker/locales";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-large.svg";
import { useParentPopup } from "../InputPopup";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { ReactComponent as DefaultAvatar } from "../../assets/svg/Team.svg";
import { ReactComponent as Tag } from "../../assets/svg/tag.svg";
import { usePinLock } from "../../hooks/usePinLock";
import { LoaderIcon } from "@coworker/reusable/Loader";
import trackerHelper from "../../helpers/tracker";

const Container = styled.div`
  background: var(--blue);
  box-sizing: border-box;
  padding: 4px 20px 4px 20px;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  color: var(--white);
  user-select: none;
`;

const VerticalAvatarAndName = styled.div`
  display: flex;
  height: 24px;
  line-height: 24px;
  margin-left: -20px;
`;

const UserIcon = styled.div`
  svg {
    margin-top: -8px;
    width: 40px;
    height: 40px;
  }
`;

const Instructions = styled.div`
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  max-width: 220px;
  min-height: 60px;
  padding-top: 8px;
  margin: 0 auto;
`;

const PinInput = styled.input`
  font-size: 44px;
  height: 40px;
  background: none;
  color: white;
  width: 186px;
  letter-spacing: 28px;
  padding-left: 18px;
  -webkit-text-security: disc;
  -moz-text-security: disc;
`;

const PinInputContainer = styled.div`
  width: 186px;
  margin: 0 auto;
`;

const PinBottomLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
`;

const Line = styled.div`
  width: 34px;
  height: 1px;
  background-color: white;
`;

const Subtitle = styled.div`
  line-height: 20px;
  min-height: 40px;
  font-size: 11px;
  margin-top: 8px;
  ${({ error }) => error && `color: var(--yellow)`}
`;

const TopRowButton = styled.div`
  line-height: 24px;
  font-weight: bold;
`;

const Close = styled.div`
  margin: auto 0;
`;

const TopRow = styled.div`
  font-size: 11px;
  width: 100%;
  display: flex;
  height: 24px;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default function PinCodeForm({
  error,
  onPinEntered,
  showLogout,
  title,
  instructions,
  showCloseButton,
  onClose = () => {},
  loading,
}) {
  const { getInput } = useParentPopup();
  const { push } = useWorkspacesAction();
  const { updateResetRequest } = usePinLock();
  const name = useGivenName();
  const [value, setValue] = React.useState("");

  const resetPinCodeAndLogout = async () => {
    const confirm = await getInput("confirmation", {
      question: <Trans>resetPinCodeString</Trans>,
      description: <Trans>youWillBeAskedForNewPinString</Trans>,
      positiveText: <Trans>resetPinCodeString</Trans>,
      negativeText: <Trans>cancelString</Trans>,
    });
    if (confirm) {
      trackerHelper.pinLock.logResetRequested();
      await updateResetRequest(true);
      logout();
    }
  };

  function logout() {
    push("/logout");
    onClose();
  }

  React.useEffect(() => {
    window.PIN_CODE_POPUP = true;

    // Ensure no <input> is focused so we surely close the Android soft keyboard.
    const activeElement = document.activeElement;
    if (activeElement?.blur) activeElement.blur();

    return () => {
      window.PIN_CODE_POPUP = undefined;
    };
  }, []);

  if (loading) {
    return (
      <Container>
        <LoaderContainer>
          <LoaderIcon size="100" />
        </LoaderContainer>
      </Container>
    );
  }

  return (
    <Container>
      <TopRow>
        <VerticalAvatarAndName>
          <UserIcon>
            <DefaultAvatar />
          </UserIcon>
          <span>{name}</span>
        </VerticalAvatarAndName>
        {!showCloseButton && showLogout && (
          <TopRowButton onClick={logout}>
            <Trans>logoutString</Trans>
          </TopRowButton>
        )}
        {!showCloseButton && showLogout && (
          <TopRowButton onClick={resetPinCodeAndLogout}>
            <Trans>resetPinCodeString</Trans>
          </TopRowButton>
        )}
        {showCloseButton && (
          <Close onClick={onClose}>
            <CloseIcon />
          </Close>
        )}
      </TopRow>
      <Instructions>
        <Trans>{title}</Trans>
      </Instructions>
      <PinInputContainer>
        <PinInput type="text" value={value} readOnly />
        <PinBottomLine>
          <Line />
          <Line />
          <Line />
          <Line />
        </PinBottomLine>
        <Subtitle error={error}>
          <Trans>{error || instructions}</Trans>
        </Subtitle>
      </PinInputContainer>
      <PinKeys
        onPinEntered={onPinEntered}
        setStars={(k) => setValue("".padStart(k, "0"))}
      />
    </Container>
  );
}

const ButtonSized = styled.div`
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  margin: 8px;
`;

const RoundButton = styled(ButtonSized)`
  border-radius: 50%;
  border: 1px solid white;
`;

function PinKeys({ onPinEntered, setStars }) {
  const [value, setValue] = React.useState("");
  const enter = (key) => () => {
    const entered =
      key !== "-" ? `${value}${key}` : value.slice(0, value.length - 1);
    setStars(entered.length);
    setValue(entered);
    if (entered.length === 4) {
      setValue("");
      onPinEntered(entered);
      setStars(0);
    }
  };
  const makeKey = (key) => {
    return key?.type ? (
      key
    ) : (
      <RoundButton key={key} onClick={enter(key)}>
        {key}
      </RoundButton>
    );
  };
  return (
    <div>
      {[
        1,
        2,
        3,
        <br key="br" />,
        4,
        5,
        6,
        <br key="br2" />,
        7,
        8,
        9,
        <br key="br3" />,
        <ButtonSized key="spacer">&nbsp;</ButtonSized>,
        0,
        <ButtonSized key="backspace" onClick={enter("-")}>
          <Tag />
        </ButtonSized>,
      ].map(makeKey)}
    </div>
  );
}
