import { useMutation, useQueryClient } from "@tanstack/react-query";
import { callInternalApi } from "../API/useCallInternal";
import { useStoreId } from "../../core/auth/useLoggedInUser";

export function useReadNotification() {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  return useMutation({
    mutationFn: (noteId: string) => markEventRead(noteId, storeId),
    onSuccess: () => {
      queryClient.invalidateQueries(["notifications"]);
    },
  });
}

async function markEventRead(noteId: string, storeId: string) {
  await callInternalApi(
    `my/events/markEventRead?noteId=${encodeURIComponent(noteId)}`,
    { store_id: storeId }
  );
}
