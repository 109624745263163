import React from "react";
import { useInputPopup } from "../../InputPopup";
import ProductInfoRow from "../../ProductInfoRow";
import { Container } from "./_common";
import { Trans } from "@coworker/locales";
import { StockDetails } from "../../StockDetails";

export default function TaskFormProduct({
  value: { productId, productType },
  onChange,
  conf: { withAvailability = false },
  disabled,
}) {
  const { getInput } = useInputPopup();

  const pickADifferentProduct = async () => {
    const confirmation = await getInput("confirmation", {
      question: <Trans>questionString</Trans>,
      description: <Trans>descriptionString</Trans>,
      positiveText: <Trans>positiveString</Trans>,
      negativeText: <Trans>negativeString</Trans>,
    });
    if (confirmation) {
      const productParams = await getInput("scansearch", {
        start: new Date(),
        withHistory: false,
      });
      if (productParams) {
        onChange({
          productId: productParams.product,
          productType: productParams.type || "",
        });
      }
    }
  };

  const full_id = `${productType || ""}${productId || ""}`;
  const unitCode = "PIECES";
  return productId ? (
    <Container disabled={disabled} data-testid="product" tabIndex={0}>
      <ProductInfoRow
        art={productId}
        onClick={pickADifferentProduct}
        imageLoader
      />
      {!!withAvailability && (
        <StockDetails full_id={full_id} unitCode={unitCode} />
      )}
    </Container>
  ) : null;
}
