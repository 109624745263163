import React from "react";
import dayjs from "dayjs";
import styled, { css } from "styled-components";
import { Trans } from "@coworker/locales";
import { getSalesWeekFromDate } from "../Insights/helpers";
import { GraphContainer, inverseLerp } from "@coworker/components";
import useFormatter from "../../hooks/useFormatter";
import { fromEntries } from "../../helpers/polyfills";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import preferences from "@coworker/functions/src/enums/profilePreferences";
import { groupByProp } from "../../helpers/utils";

const StyledGraphContainer = styled(GraphContainer)`
  & > div {
    margin-bottom: 15px;
  }
`;

function WeeksLabel({ week }) {
  return (
    <>
      <Trans>weekAbbreviationString</Trans>
      {week}
    </>
  );
}

function DateLabel({ date }) {
  const { formatDateISO8601 } = useFormatter();
  return formatDateISO8601(date);
}

const LeftText = styled.div`
  min-width: 40px;
  margin-left: 16px;
  padding-right: 8px;
  font-weight: bold;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
`;

const WeekGroupWrapper = styled.div`
  ${({ isCustomPeriod }) =>
    isCustomPeriod &&
    css`
      &:not(:first-child) {
        margin-top: 30px;
      }
    `}
`;

function WeekGroupLabel({ week, startDate, endDate }) {
  const { formatDateRange, formatWeekday } = useFormatter();
  const startWeekday = formatWeekday.short(dayjs(startDate).day());
  const endWeekday = formatWeekday.short(dayjs(endDate).day());
  return (
    <LeftText>
      <Trans>weekString</Trans> {week} - {formatDateRange(startDate, endDate)} -
      ({startWeekday} - {endWeekday})
    </LeftText>
  );
}

export default function AggregatedVotesGraph({
  data,
  startDate,
  endDate,
  interval,
  isCustomPeriod,
  offset,
  tasksOnQuestion,
  displayTasksOnGraph,
}) {
  // TODO: it looks like this could be simplified if we aggregate into graph groups form the start, to avoid building up intermediate objects.
  // Sort order of the graph groups would be safer to handle with a sortable .key that could be YYYY-${currentKey} and we avoid the year-split sorting issue.
  let barGroups = prepareGraphGroups(
    // This step sorts and takes care of Object.entries default sorting. Will likely fail if we cover more than a full year.
    makeGroupedWithDefaults(data, startDate, endDate, interval),
    interval,
    isCustomPeriod,
    offset
  );

  const [onlyQuestionsWithData] = useUserPreference(
    preferences.MFAQ_PREFIX + preferences.SHOW_ONLY_QUESTIONS_WITH_DATA
  );

  if (onlyQuestionsWithData) {
    const filteredGroups = barGroups.filter((group) =>
      group.some((groupObj) => groupObj.formattedPrimaryValue !== 0)
    );
    const filteredGroupsArray = filteredGroups.map((group) =>
      group.filter((groupObj) => groupObj.formattedPrimaryValue !== 0)
    );
    barGroups = filteredGroupsArray;
  }
  const { formatDateISO8601 } = useFormatter();
  if (!barGroups) return;
  return (
    <div>
      {barGroups.map((week) => {
        return (
          <WeekGroupWrapper key={week[0].key} isCustomPeriod={isCustomPeriod}>
            {isCustomPeriod && interval === "day" && (
              <WeekGroupLabel
                week={week[0].week}
                startDate={week[0].key}
                endDate={week[week.length - 1].key}
              />
            )}
            <StyledGraphContainer
              bars={week}
              tasksOnQuestion={tasksOnQuestion}
              formatDate={formatDateISO8601}
              displayTasksOnGraph={displayTasksOnGraph}
            />
          </WeekGroupWrapper>
        );
      })}
    </div>
  );
}

/**
 * Prepares an array of objects representing bars for the graph
 * @param {[string, {value:number, weekNumber: string}][]} groups
 * @param {Interval} interval
 * @param {boolean} isCustomPeriod
 * @param {number} offset
 */
function prepareGraphGroups(groups, interval, isCustomPeriod, offset) {
  const values = groups?.map(([, { value }]) => value);
  values[values.length - 1] += offset; // Adjusts today to get correct maxVotes
  const minVotes = Math.min(...values);
  const maxVotes = Math.max(...values);
  const bars =
    groups?.map(([label, { value, weekNumber }], idx) => {
      // Adjust today's actual value to show and calculate correct width
      const shownValue = idx === groups.length - 1 ? value + offset : value;
      const barWidth = calculateBarWidth(minVotes, maxVotes, shownValue);
      return {
        key: label,
        label:
          interval === "week" ? (
            <WeeksLabel week={label} />
          ) : (
            <DateLabel date={label} />
          ),
        formattedPrimaryValue: shownValue,
        week: weekNumber,
        primaryBarWidth: barWidth * 100, // [0, 1] * 100 to get %
      };
    }) ?? [];

  // Set the last bar as partial and blue
  if (bars.length > 0) {
    bars[bars.length - 1].isPartial = true;
    bars[bars.length - 1].isHighlighted = true;
  }

  if (isCustomPeriod && interval === "day") {
    return Object.values(groupByProp(bars, "week"));
  }
  return [bars];
}

/**
 * Calculates bar width based on other bars, returns number in interval [0, 1]
 * @param {number} minValue
 * @param {number} maxValue
 * @param {number} barValue
 */
function calculateBarWidth(minValue, maxValue, barValue) {
  if (barValue === 0) return 0;
  if (barValue === maxValue) return 1;
  if (minValue === maxValue) return 0;
  return inverseLerp(minValue, maxValue, barValue);
}

/**
 * @param {{weekNumber?: number, dayOfWeek?: number, value: number}[]} groups
 * @param {number} since
 * @param {number} until
 * @param {Interval} interval
 * @returns {[string, {value: number, weekNumber: string}][]}
 */
function makeGroupedWithDefaults(groups, since, until, interval) {
  const defaultGroups = makeDefaultGroups(interval, since, until);
  const populatedGroups = Object.entries({
    ...defaultGroups,
    ...fromEntries(
      groups?.map(({ weekNumber, bucketStartDate, value }) => [
        interval === "week" ? weekNumber : bucketStartDate,
        { value, weekNumber },
      ]) ?? []
    ),
  });

  // Because Object.entries sorts in alphabetical order of keys,
  // we can have the situation where our array looks like [w1, w2, w52, w53]
  // This finds the partition point, and puts the last year entries at the front
  if (interval === "week" && isLastYear(since)) {
    const whereNextKeyIsNotPlusOne = (group, idx, obj) => {
      return idx !== obj.length - 1 && group[0] != obj[idx + 1][0] - 1; // eslint-disable-line eqeqeq
      // disabled eqeqeq because those are numbers as strings, so letting type coercion do its thing
    };

    const index = populatedGroups.findIndex(whereNextKeyIsNotPlusOne) + 1;
    return [
      ...populatedGroups.slice(index),
      ...populatedGroups.slice(0, index),
    ];
  }
  return populatedGroups;
}

/**
 * @param {Interval} interval
 * @param {number} since
 * @param {number} until
 * @returns {{[key: string]: number}}
 */
function makeDefaultGroups(interval, since, until) {
  const defaultGroups = {};
  switch (interval) {
    case "week":
      const startWeek = getSalesWeekFromDate(since);
      const endWeek = getSalesWeekFromDate(until);
      // If interval contains new year break
      if (startWeek > endWeek) {
        const yearEndWeek = dayjs(since).endOf("year").isoWeek();

        fillWeeks(defaultGroups, startWeek, yearEndWeek);
        fillWeeks(defaultGroups, 1, endWeek);
      } else {
        fillWeeks(defaultGroups, startWeek, endWeek);
      }
      return defaultGroups;
    case "day":
      let startDay = dayjs(since).startOf("day");
      const endDay = dayjs(until).startOf("day");
      while (startDay.isBefore(endDay) || startDay.isSame(endDay)) {
        defaultGroups[startDay.format("YYYY-MM-DD")] = {
          value: 0,
          weekNumber: dayjs(startDay).isoWeek(),
        };
        startDay = startDay.add(1, "day");
      }
      return defaultGroups;
    default:
      return defaultGroups;
  }
}

/**
 * Fills object with week number keys between startWeek and endWeek. If weeks are from last year, negate the keys
 * @param {{[key: string]: number}} obj
 * @param {number} startWeek
 * @param {number} endWeek
 */
function fillWeeks(obj, startWeek, endWeek) {
  while (startWeek <= endWeek) {
    obj[startWeek] = { value: 0 };
    startWeek++;
  }
}

function isLastYear(ts) {
  return dayjs(ts).year() < dayjs().year();
}

/**
 * @typedef {"day" | "week"} Interval
 */
