import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { PreviewHeader } from "../core/components/Previews";
import { WidgetDivider, Spacer } from "@coworker/reusable/components/Widgets";
import { useFetchedBasicProduct } from "../hooks/useBasicProductsMap";
import { formatFullIdWithDots } from "@coworker/reusable";
import { titleKeys } from "./taskCardTitles";
import { Heading } from "./Layout/Heading";

const ProductHeaderTitle = styled.p`
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
`;

const ProductHeaderDescription = styled.p`
  color: var(--grey718);
  font-size: 14px;
`;

export function RelatedTasksScreenHeading({
  fullId,
  type,
}: {
  fullId: string;
  type: string;
}) {
  const productName = useFetchedBasicProduct(fullId)?.basicProduct?.name;

  return (
    <Heading
      alwaysSticky={true}
      title={<Trans>{(titleKeys as any)[type]}</Trans>}
      subTitle={
        !!fullId && (
          <>
            {formatFullIdWithDots(fullId)}
            {!!productName && (
              <>
                {" • "}
                {productName}
              </>
            )}
          </>
        )
      }
      // TS... :(
      children={null}
      showMenu={false}
      handleMenu=""
      handleBack=""
      isHidden={false}
      customRightAction=""
      rightComponent=""
      customLeftAction=""
      testId=""
      actionContainerFlex=""
      css=""
      onTapSearch=""
    />
  );
}

export function ProductHeader({ fullId }: { fullId: string }) {
  const { basicProduct } = useFetchedBasicProduct(fullId);
  const { smallImage, name, descriptives, descriptivesWithoutKindAndColor } =
    basicProduct;
  return (
    <div>
      <PreviewHeader image={smallImage}>
        <Spacer height={4} />
        <ProductHeaderTitle>{name}</ProductHeaderTitle>
        <ProductHeaderDescription>
          {descriptivesWithoutKindAndColor || descriptives[0]}
        </ProductHeaderDescription>
        <ProductHeaderDescription>
          {formatFullIdWithDots(fullId)}
        </ProductHeaderDescription>
        <Spacer height={4} />
      </PreviewHeader>
      <WidgetDivider />
    </div>
  );
}
