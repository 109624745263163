import React from "react";
import { Trans } from "@coworker/locales";
import { AddLocation } from "@coworker/components";
import { Widget } from "@coworker/components/src/components/Widget";
import { useInputPopup } from "../../InputPopup";
import {
  useAllSalesLocationsForArticleQuery,
  useNonSLMFoodLocation,
} from "../../../services/locations.service";
import {
  getProductCachedData,
  useProductType,
} from "../../../services/products.service";
import useFormatter from "../../../hooks/useFormatter";
import { getLocationPopup } from "../common/popup.helpers";

export default function TaskFormLocation({
  value: {
    code = "",
    custom = "",
    department = "",
    requestedAmount,
    articleId,
    articleType,
    panumber,
  },
  disabled,
  onChange,
  conf: {
    optional,
    locationString = <Trans>locationString</Trans>,
    addon,
    noArrow,
    amount,
    title,
    overridePadding,
    newAddonLocation,
  },
  additionalSubTitle = <></>,
}) {
  const { getInput } = useInputPopup();
  const [quantity, setQuantity] = React.useState(amount);
  const [ptag, setPtag] = React.useState();
  const [htag, setHtag] = React.useState();
  const lastArticleId = React.useRef(null);
  const product_article_type = useProductType(articleId, articleType);

  const { data: locationsData, isLoading: locationsLoading } =
    useAllSalesLocationsForArticleQuery(`${product_article_type}${articleId}`);

  const hasNoSLMLocations = locationsData?.length <= 0;
  const isSFM = panumber?.substring(0, 2) === "96";
  const isFoodServices = panumber?.substring(0, 2) === "60" ? true : false;
  const isCPG = panumber?.substring(0, 2) === "61" ? true : false;

  const { sfmLocationList, isLoading } = useNonSLMFoodLocation(
    isSFM,
    isFoodServices,
    isCPG,
    hasNoSLMLocations
  );

  const loading = (!!locationsLoading && !!locationsData) || isLoading;

  const articleLocations = hasNoSLMLocations ? sfmLocationList : locationsData;

  React.useEffect(() => {
    const found =
      !locationsLoading &&
      articleLocations?.find?.((location) =>
        code ? code === location?.location : location?.isPrimary
      );
    if (found) {
      hasNoSLMLocations ? setQuantity("") : setQuantity(found.quantity);
      setPtag(found.isPrimary);
      setHtag(found.isHomeBase || found.isHome);
    }
  }, [articleLocations, code, locationsLoading, hasNoSLMLocations]);

  const { unitCode } = getProductCachedData(articleId) || {};
  const { productUnit } = useFormatter();
  const displayUnit = !custom && productUnit(unitCode);

  // If article changes, location should be reset
  React.useEffect(() => {
    if (lastArticleId.current && lastArticleId.current !== articleId) {
      onChange({});
      setQuantity(null);
    }
    lastArticleId.current = articleId;
  }, [articleId, onChange]);
  return (
    <Widget
      tabIndex={0}
      onClick={() => {
        getLocationPopup({
          getInput,
          onChange,
          addon,
          code,
          custom,
          title,
          locationCode: code,
          locationCustom: custom,
          product_article_id: articleId,
          product_article_type,
          requestedAmount,
          isSFM,
          isFoodServices,
          isCPG,
        });
      }}
      disabled={disabled}
      data-testid={"addLocation" + (addon || "")}
    >
      <AddLocation
        loading={loading}
        code={custom || code || ""}
        department={department || ""}
        quantity={(!custom && quantity) || ""}
        displayUnit={displayUnit}
        deactivated={disabled}
        locationString={locationString}
        addon={addon}
        optional={optional}
        ptag={!custom && ptag}
        htag={!custom && htag}
        noArrow={noArrow}
        overridePadding={overridePadding}
        newAddonLocation={newAddonLocation}
        additionalSubTitle={!custom && !code ? additionalSubTitle : <></>}
      />
    </Widget>
  );
}
