import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRoomImage } from "../services/images.service";

export const useDeleteImages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      imageIds,
      roomId,
    }: {
      imageIds: string[];
      roomId: string;
    }) => {
      return Promise.all(
        imageIds.map((imageId) => deleteRoomImage(imageId, roomId))
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["imagesByRoom"] });
    },
  });
};
