import React from "react";
import {
  getSupplierNumber,
  getValidCode,
} from "../../helpers/barcodeValidator";
import { scannerLog } from "./log";
const noop = () => null;

/**
 * Registers a WebWorker and its response handler. Takes care of the lifetimes.
 * @param {React.MutableRefObject<boolean>} isScanningRef Ref passed around that says whether the worker is currently processing a scan
 * @param {React.SetStateAction<string>} setBarcode State setter that sets the barcode value
 * @param {React.SetStateAction<boolean>} setScanning State setter for the state that tracks whether the scanner is running or idle
 */
export default function useBrowserWorker(
  isScanningRef,
  setBarcode,
  setSupplierNumber,
  setScanning
) {
  /**
   * @type [Worker, (worker: Worker) => void]
   */
  const [barcodeWorker, setBarcodeWorker] = React.useState();
  React.useEffect(() => {
    // Setup and teardown worker once
    scannerLog(
      `Creating browser worker, window.isSecureContext=${window.isSecureContext}, window.devicePixelRatio=${window.devicePixelRatio}`
    );
    const worker = new Worker("/browserBarcodeWorker.js");
    setBarcodeWorker(worker);
    return () => {
      worker.terminate();
    };
  }, []);

  // Setup web worker response handler
  React.useEffect(() => {
    if (barcodeWorker) {
      barcodeWorker.onmessage = (e) => {
        isScanningRef.current = false;
        if (e.data.success) {
          const supplierNumber = getSupplierNumber(e.data.barcode);
          supplierNumber && setSupplierNumber(supplierNumber);
          const decoded = getValidCode(e.data.barcode);
          decoded && setBarcode(decoded); // This must happen after supplierNumber, or elst it won't be included on the onBarcode callback -- and right after that callback all data is cleared so supplierNumber is lost.
          setScanning(false);
          scannerLog(
            `Barcode: ${decoded || "-"}, Supplier number: ${
              supplierNumber || "-"
            }`
          );
        }
      };
    }

    return () => {
      if (barcodeWorker) barcodeWorker.onmessage = noop;
    };
  }, [
    barcodeWorker,
    isScanningRef,
    setBarcode,
    setScanning,
    setSupplierNumber,
  ]);

  return barcodeWorker;
}
