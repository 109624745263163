import React from "react";
import { ReactComponent as QuestionIcon } from "../../../assets/svg/question-icon.svg";
import { Trans } from "@coworker/locales";
import BasicWidget from "@coworker/components/src/components/BasicWidget";
import { useWorkspacesState } from "../../../hooks/useWorkspaces";

export function TaskFormMfaqQuestion({ question }: any) {
  const { navigationState } = useWorkspacesState();

  const questionText = question ?? navigationState.question?.question_text;

  return (
    <BasicWidget
      testId="task-creation-mfaq-question"
      icon={<QuestionIcon width={22} height={22} />}
      title={<Trans>questionStringMFAQ</Trans>}
      text={questionText}
      deactivated={!questionText}
    />
  );
}
