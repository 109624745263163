import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <p> Izjava o zasebnosti</p>,
  aboutFixaText: (
    <div>
      Fixa je orodje za vodenje opravil in urejanje razstavnega prostora,
      namenjeno zaposlenim v trgovini. Z aplikacijo FIXA želimo nadomestiti
      dejavnosti, ki se vodijo ročno, na papirju ali v razpredelnicah, ter
      optimirati upravljanje opravil v trgovini (dopolnjevanje zalog izdelkov,
      čiščenje, odstopanja, testni nakupi, pogosta vprašanja itd.).Fixa prek
      vpogledov iz dokončanih opravil omogoča tudi analitiko.
      <br />
      <br />
      Če uporabljaš orodje Fixa, bo upravljavec podatkov Skupine Ingka (kot je
      opredeljeno spodaj) o tebi zbiral določene osebne podatke.
    </div>
  ),

  personalDataCollectedTitle: <p>Zbirali se bodo naslednji osebni podatki:</p>,
  personalDataCollectedText: (
    <div>
      Ko uporabljaš orodje FIXA, sistem iz zbirke podatkov o zaposlenih (CDS)
      pridobi tvoje osebne podatke, na primer ime, drugo ime oz. imena, priimek,
      e-poštni naslov, naziv delovnega mesta, vlogo, telefonsko številko (če je
      ta ročno vnesena – osebnih telefonskih številk se ne vnaša), fotografijo
      iz zbirke CDS, želeni jezik v aplikaciji, ID trgovine, ID države,
      različiconaprave in operacijskega sistema, IP-naslov iz programov Google
      Cloud Platform in Sentry (Sentry je programska oprema za sledenje napak v
      aplikaciji).
      <br />
      <br />
      Profile zaposlenih oseb ročno ustvarijo vodje/superuporabniki prek
      skrbniškega vmesnika sistema Fixa (spletne aplikacije za skrbnike). Osebni
      podatki se pridobivajo iz zbirke CDS, zato je mogočeprofil ustvariti samo
      za zaposleno osebo z aktivnim profilom v zbirki CDS.
    </div>
  ),
  purposeOfDataCollectionTitle: <p>Namen zbiranja teh podatkov:</p>,
  purposeOfDataCollectionText: (
    <p>
      Osebni podatki se v orodju Fixa zbirajo, da aplikacija pravilno deluje in
      s tem omogoča enostavno interno komunikacijo ter upravljanje opravil med
      zaposlenimi v trgovini. Ker so opravila povezana z določenimiuporabniki,
      se lahko ob spremembah v povezavi z opravili pošiljajo obvestila
      dodeljenim uporabnikom ter uporabnikom, ki so opravilo ustvarili.
    </p>
  ),
  legalGroundsTitle: <p>Pravna podlaga za obdelavo:</p>,
  legalGroundsText: <p>Pravna podlaga za obdelavo je zakoniti interes.</p>,
  dataWillBeStoredTitle: <p>Obdobje hranjenja podatkov:</p>,
  dataWillBeStoredText: (
    <div>
      Dokončana opravila se s sklici na uporabnike hranijo 70 dni, po tem
      obdobju pa so anonimizirana. Osebni podatki zaposlenih oseb se v sistemu
      Fixa hranijo do izteka pogodbe o zaposlitvi.
      <br />
      <br />
      Prekinitev pogodbe o zaposlitvi s Skupino Ingka: Vsi osebni podatki se
      samodejno izbrišejo v 1 mesecu po odstranitvi profila iz zbirke CDS.
      <br />
      <br />
      Spremenjena vloga znotraj Skupine Ingka:Če zaposlena oseba pri svojem delu
      ne uporablja večorodja Fixa, se vsi osebni podatki samodejno izbrišejo v 3
      mesecih od zadnje aktivnosti.
      <br />
      <br />
      Na zahtevo, podano vodji za informacijsko varnost in zasebnost podatkov
      ali vodji trgovine, se lahko osebni podatki odstranijo kadarkoli.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <p>Upravljavec podatkov za obdelavo osebnih podatkov:</p>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA Slovenia
      <br />
      Svedska ulica 6,
      <br />
      1000 Ljubljana
      <br />
      <br />
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      V povezavi s svojimi osebnimi podatki imaš določene pravice. Če želiš
      izvedeti več o njih, obišči spletno stran.
      <br />
      <br />
      Kontaktni podatki: <EmailLink email="data.protection.sl@ingka.ikea.com" />
    </div>
  ),
};
