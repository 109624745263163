import React from "react";
import { useRoomById } from "../hooks/useRoomById";
import { useParams } from "react-router";
import { useTranslation } from "@coworker/locales";
import { RoomSettingsPlacement } from "../Molecules/RoomSettingsPlacement";
import { SupplierNumber } from "../Molecules/SupplierNumber";
import { DateStamp } from "../Molecules/DateStamp";
import { useQueryClient } from "@tanstack/react-query";
import { GreyStrip } from "../Atoms/GreyStrip";
import { CreateTasks } from "../Organisms/CreateTasks";
import { useTasksByArticle } from "../hooks/useTasksByArticle";
import { useRoomsAndArticleCount } from "../hooks/useRoomsAndArticleCount";
import {
  getPrimeTasks,
  getSortedRoomsArticleCount,
  reduceRoomsArticleCount,
} from "./ArticleView.helper";
import { ArticleViewSkeleton } from "../Skeletons/ArticleViewSkeleton";
import {
  isBackupForProduct,
  isMainArticle,
} from "../Molecules/RoomArticles.helper";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { ArticleStock } from "../Molecules/ArticleStock";
import ListView from "@ingka/list-view";
import { HFBLocation } from "../Molecules/HFBLocation";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useArticlesByProductArticleId } from "../hooks/useArticlesByProductArticleId";
import { ArticleInGroups } from "../Molecules/ArticleInGroups";
import { AggregatedArticleListItem } from "../Molecules/AggregatedArticleListItem";
import { AggregatedArticle } from "../hooks/useAggregatedArticles";
import { Group } from "../types/groups";

export function AggregatedArticleView() {
  const queryClient = useQueryClient();
  const { roomId, productArticleId } = useParams();
  const { t } = useTranslation();
  const { push, pop } = useWorkspacesAction();

  const {
    data: articles,
    refetch,
    isLoading: articlesLoading,
  } = useArticlesByProductArticleId(roomId, productArticleId);
  const { data: room, isLoading: roomLoading } = useRoomById(roomId);
  const { tasks } = useTasksByArticle(room?.store_id, productArticleId);
  const { data: roomsArticleCount, isLoading: racLoading } =
    useRoomsAndArticleCount(productArticleId, room?.store_id);

  const sortedRoomsArticleCount = reduceRoomsArticleCount(
    getSortedRoomsArticleCount(
      room?.name ?? "",
      roomsArticleCount || [],
      roomId
    )
  );

  const primeTasks = getPrimeTasks(roomsArticleCount || [], tasks);

  const article = articles && articles.length > 0 ? articles[0] : undefined;
  const header = `${article?.itemName ?? ""} (${room?.name ?? ""})`;

  const handleUpdateArticle = async () => {
    await queryClient.invalidateQueries(["articlesByRoomAndProductArticleId"]);
    await queryClient.invalidateQueries(["roomsAndArticleCount"]);
    await refetch();
  };

  if (articlesLoading || roomLoading || racLoading)
    return (
      <FullScreenPopup
        appBarConfig={{
          title: t("loadingString"),
          actions: [],
          onBack: () => {
            push("/roomsettings/" + room?.id);
          },
        }}
        noPadding
      >
        <ArticleViewSkeleton />
      </FullScreenPopup>
    );

  return (
    <>
      {article && room && tasks && (
        <FullScreenPopup
          appBarConfig={{
            title: header,
            actions: [],
            onBack: () => {
              pop();
            },
          }}
          noPadding
        >
          <AggregatedArticleListItem
            article={
              {
                ...article,
                isFromArticleView: true,
                aggregatedArticles: articles,
              } as AggregatedArticle
            }
            groups={
              (articles || [])
                .filter((a) => !!a.groupId)
                .map((a) => {
                  return { id: a.groupId, name: a.groupName } as Group;
                }) ?? []
            }
            isMainProduct={() =>
              isMainArticle(
                article.id,
                room.main_article_id_1,
                room.main_article_id_2
              )
            }
            isBackupForProduct={() =>
              isBackupForProduct(
                article.id,
                room.backup_article_id_1 ?? "",
                room.backup_article_id_2 ?? ""
              )
            }
            showActionRow={true}
            hideChevron={true}
          />
          <ListView id="article-view-list" size={"small"}>
            {(articles?.filter((a) => !!a.groupId).length ?? 0) > 0 && (
              <GreyStrip text={t("inGroupsString")} />
            )}

            <ArticleInGroups articles={articles ?? []} />

            <GreyStrip text={t("inMediaString")} />

            <RoomSettingsPlacement
              roomsArticleCount={sortedRoomsArticleCount || []}
              currentArticleId={article.id}
            />

            <GreyStrip text={t("aboutArticleString")} />

            <HFBLocation hfb={article.hfb} />

            <ArticleStock
              productArticleId={article.productArticleId}
              productArticleType={article.productArticleType}
            />

            <SupplierNumber
              article={article}
              value={article.supplierNumber ?? ""}
              onUpdateArticle={handleUpdateArticle}
            />

            <DateStamp
              article={article}
              value={article.dateStamp ?? ""}
              onUpdateArticle={handleUpdateArticle}
            />
          </ListView>

          <GreyStrip text={t("foundInAllRoomSettingsString")} />

          <CreateTasks
            roomsArticleCount={sortedRoomsArticleCount || []}
            tasks={primeTasks}
            currentArticleId={article.id}
          />
        </FullScreenPopup>
      )}
    </>
  );
}
