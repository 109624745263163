import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import { Icon } from "@coworker/components";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { getTranslationKeyFromReason } from "../../RoomSettings/tools";

export const CompactIconWrapper = styled.div`
  width: 22px;
  height: 24px;
  margin-right: 21px;
  margin-top: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    margin: 0px auto;
  }
`;

function Description({
  task,
  conf: {
    field = "description",
    titleTranslationKey = "description4String",
    icon,
    iconSize = 20,
    fieldOverrideTranslationKey,
  },
}) {
  return task[field] ? (
    <DetailSection>
      {icon && (
        <CompactIconWrapper>
          <Icon size={iconSize} family="icons" name={icon} color={"grey900"} />
        </CompactIconWrapper>
      )}
      <DetailSectionLeft>
        <SectionTitle>
          <Trans>{titleTranslationKey}</Trans>
        </SectionTitle>
        {task[field] && fieldOverrideTranslationKey ? (
          <SectionText data-testid="taskDescription">
            <Trans>
              {task.task_type === taskTypeOptions.ROOM_SETTINGS
                ? getTranslationKeyFromReason(task.reason)
                : fieldOverrideTranslationKey}
            </Trans>
          </SectionText>
        ) : (
          <SectionText data-testid="taskDescription">{task[field]}</SectionText>
        )}
      </DetailSectionLeft>
    </DetailSection>
  ) : null;
}

export default Description;
