export const ISSUE = "issue";
export const BOOLEAN_FLAG = "boolean_flag";
export const LOCATION = "location";
export const PRODUCT = "product";
export const ASSIGN = "assign";
export const PHOTOS = "photos";
export const DATE_STAMP = "dateStamp";
export const DESCRIPTION = "description";
export const CUSTOM_TITLE = "custom_title";
export const AMOUNT = "amount";
export const RADIOBUTTONS = "radiobuttons";
export const REPEATABLE = "repeatable";
export const SCHEDULE = "schedule";
export const OPTIONAL_LOCATION = "optional_location";
export const DIVIDER = "divider";
export const SIMPLE_PRODUCT = "simple_product";
export const SUBTITLE = "subtitle";
export const SUPPLIER_NUMBER = "supplierNumber";
export const TEXT_NOTE = "text_note";
export const TUTORIALS = "tutorials";
export const SECTION = "section";
export const PRODUCT_WITH_PILLS = "product_with_pills";
export const RELATED_TASKS = "related_tasks";
export const MFAQ_AREA = "mfaq_area";
export const MFAQ_QUESTION = "mfaq_question";
export const MFAQ_GRAPH_CARD = "mfaq_graph_card";
export const SAFETY_ALARM_INFO = "safety_alarm_info";
export const DESCRIBE_THE_PROBLEM = "describe_the_problem";
export const REFILL_TYPE = "refill_type";

export type WidgetType =
  | "issue"
  | "boolean_flag"
  | "location"
  | "product"
  | "assign"
  | "photos"
  | "dateStamp"
  | "description"
  | "custom_title"
  | "amount"
  | "radiobuttons"
  | "repeatable"
  | "schedule"
  | "optional_location"
  | "divider"
  | "simple_product"
  | "subtitle"
  | "supplierNumber"
  | "text_note"
  | "tutorials"
  | "section"
  | "product_with_pills"
  | "related_tasks"
  | "mfaq_area"
  | "mfaq_question"
  | "mfaq_graph_card"
  | "safety_alarm_info"
  | "describe_the_problem"
  | "refill_type";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [AMOUNT]: require("./amount").default,
  [ASSIGN]: require("./assign").default,
  [BOOLEAN_FLAG]: require("./boolean").default,
  [CUSTOM_TITLE]: require("./customTitle").default,
  [DATE_STAMP]: require("./dateStamp").default,
  [DESCRIPTION]: require("./description").default,
  [DIVIDER]: require("@coworker/reusable/components/Widgets").WidgetDivider,
  [ISSUE]: require("./issue").default,
  [LOCATION]: require("./location").default,
  [REFILL_TYPE]: require("./refillType").default,
  [MFAQ_AREA]: require("./mfaqArea").TaskFormMfaqArea,
  [MFAQ_QUESTION]: require("./mfaqQuestion").TaskFormMfaqQuestion,
  [MFAQ_GRAPH_CARD]: require("./mfaqGraphcard").default,
  [OPTIONAL_LOCATION]: require("./optionalLocation").default,
  [PHOTOS]: require("./photos").default,
  [PRODUCT_WITH_PILLS]: require("./ProductAndStockPillsWidget")
    .ProductAndStockPillsWidget,
  [RELATED_TASKS]: require("./RelatedTasksWidget").RelatedTasksWidget,
  [PRODUCT]: require("./product").default,
  [RADIOBUTTONS]: require("./radiobuttons").default,
  [SAFETY_ALARM_INFO]: require("./safety_alarm_info").default,
  [REPEATABLE]: require("./repeatable").default,
  [SCHEDULE]: require("./schedule").default,
  [SECTION]: require("./section").default,
  [SIMPLE_PRODUCT]: require("./simple_product").default,
  [SUBTITLE]: require("./subtitle").default,
  [SUPPLIER_NUMBER]: require("./supplierNumber").default,
  [TEXT_NOTE]: require("./textNote").default,
  [TUTORIALS]: require("./instructions").default,
  [DESCRIBE_THE_PROBLEM]: require("./describeTheProblem").default,
};
