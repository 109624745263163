// For now, all users get their Sunday at the end of the week.
// If this turns out it needs to be dynamic, try https://stackoverflow.com/a/57102881/296639
const weekEndsWithSunday = (module.exports.weekEndsWithSunday = true);

module.exports.SORTED_WEEKDAYS = weekEndsWithSunday
  ? [1, 2, 3, 4, 5, 6, 0]
  : [0, 1, 2, 3, 4, 5, 6];

// If weekEndsWithSunday we sort the 0=Sunday as if it was a 7
module.exports.weekdaySorter = weekEndsWithSunday
  ? (a, b) => (a || 7) - (b || 7)
  : (a, b) => a - b;
