import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <p> Izjava o privatnosti</p>,
  aboutFixaText: (
    <div>
      Fixa je alat za upravljanje zadacima i izlaganje robe za zaposlene u
      robnoj kući. Svrha FIXA aplikacije je da zameni ručne, papirne ili
      aktivnosti zasnovane na programu Excel i pojednostavi upravljanje zadacima
      u robnoj kući (dopunjavanje artikala, čišćenje, odstupanja, probne
      kupovine, najčešća pitanja itd.). Fixa takođe omogućava analitiku kroz
      uvide stečene iz završenih zadataka.
      <br />
      <br />
      Kada koristiš aplikaciju Fixa, kontrolor podataka kompanije Ingka Grupa
      (kao što je definisano u nastavku) prikupljaće određene lične podatke o
      tebi.
    </div>
  ),

  personalDataCollectedTitle: <p> Prikupljaju se sledeći lični podaci: </p>,
  personalDataCollectedText: (
    <div>
      Kada koristiš aplikaciju FIXA, sistem će prikupljati podatke o tebi iz
      baze podataka zaposlenih (CDS), kao što su tvoje ime, srednje ime,
      prezime, imejl, naziv posla, uloga, broj telefona (ako je ručno unet –
      lični broj telefona ne treba unositi), fotografija iz baze CDS, jezička
      podešavanja u aplikaciji, ID robne kuće, ID zemlje, verzija uređaja i
      operativnog sistema, IP adresa obrađena na platformama Google Cloud
      Platform i Sentry (Sentry je softver za praćenje grešaka u aplikaciji).
      <br />
      <br />
      Profil zaposlenog ručno kreira menadžer/superkorisnik preko aplikacije
      Fixa-admin (administratorska veb aplikacija). Lični podaci se preuzimaju
      iz baze CDS, tako da se profili mogu kreirati samo za zaposlene koji imaju
      aktivni CDS profil.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <p> Svrha prikupljanja tih podataka je sledeća:</p>
  ),
  purposeOfDataCollectionText: (
    <p>
      Svrha prikupljanja ličnih podataka u aplikaciji Fixa je da se obezbedi da
      aplikacija pravilno funkcioniše na način koji omogućava laku internu
      komunikaciju između zaposlenih i administriranje zadataka između
      zaposlenih u robnoj kući. Povezivanje zadataka s korisnikom omogućava nam
      da podržimo obaveštenja za ažuriranja zadataka za dodeljene korisnike i
      dizajnere.
    </p>
  ),
  legalGroundsTitle: <p>Pravna osnova za obradu je sledeća:</p>,
  legalGroundsText: <p>Pravna osnova za obradu je legitimni interes.</p>,
  dataWillBeStoredTitle: <p>Podaci će se skladištiti:</p>,
  dataWillBeStoredText: (
    <div>
      Završene zadatke s referencama na korisnike skladištimo 70 dana, nakon
      čega se oni anonimizuju. Lični podaci zaposlenog skladište se u aplikaciji
      Fixa tokom trajanja njegovog ugovora o radu.
      <br />
      <br />
      Raskid ugovora o radu s Ingka Grupom: Svi lični podaci se automatski brišu
      u roku od jednog meseca nakon uklanjanja CDS profila.
      <br />
      <br />
      Promena uloge unutar Ingka Grupe: Ako zaposleni više ne mora da koristi
      aplikaciju Fixa, svi lični podaci će biti automatski izbrisani u roku od
      tri meseca od neaktivnosti.
      <br />
      <br />
      Lični podaci takođe mogu biti uklonjeni u bilo kom trenutku slanjem
      zahteva lokalnom Lideru za bezbednost informacija i privatnost podataka
      ili Menadžeru robne kuće.
      <br />
      <br />
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <p>Kontrolor podataka za ovu obradu ličnih podataka je:</p>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA Serbia
      <br />
      Astrid Lindgren 11, 11231 Belgrade
      <br />
      <br />
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      Imaš određena prava u vezi sa svojim ličnim podacima. Poseti da saznaš
      više o tim pravima.
      <br />
      <br />
      Kontakt podaci: <EmailLink email="data.protection.rs@ingka.ikea.com" />
    </div>
  ),
};
