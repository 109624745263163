import Button from "@ingka/button";
import React from "react";
import { SizedText } from "../../styles/styles";
import { Articles } from "../../types/article";
import { useStoreId, useUserId } from "../../../../core/auth/useLoggedInUser";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import trashCan from "@ingka/ssr-icon/paths/trash-can"; // NOSONAR
import { setOngoingTasksToClosed } from "../../tools";
import { Trans, useTranslation } from "@coworker/locales";
import { ModalTypes } from "../../types/views";
import { Group } from "../../types/groups";
import { useDeleteGroupById } from "../../hooks/useDeleteGroupById";
import { useTasksByRoom } from "../../hooks/useTasksByRoom";
import { OngoingTaskLinksResponse } from "../../types/taskLink";
import { useToastNotification } from "@coworker/components";

interface DeleteGroupModalProps {
  modalVisibility: boolean;
  roomId: string;
  allArticles: Articles;
  currentGroup?: Group | undefined;
  onTriggerNewModal: (modalType: ModalTypes) => void;
}

export const DeleteGroupModal = ({
  modalVisibility,
  roomId,
  allArticles,
  currentGroup,
  onTriggerNewModal,
}: DeleteGroupModalProps) => {
  const { showToast } = useToastNotification();
  const { t } = useTranslation();
  const uid = useUserId();
  const storeId = useStoreId();
  const tasks = useTasksByRoom(roomId);
  const deleteGroupById = useDeleteGroupById();

  const handleDeleteRoom = async () => {
    if (currentGroup === undefined) return;
    const articlesIdsInGroup = allArticles
      .filter((article) => article.groupId === currentGroup.id)
      .map((article) => article.id);
    const tasksInGroup = tasks.tasks.filter((task) =>
      articlesIdsInGroup.includes(task.article_id)
    );

    await setOngoingTasksToClosed(
      roomId,
      t("taskClosedBecauseRemovedArticleString"),
      {
        count: tasksInGroup.length,
        tasks: tasksInGroup,
      } as OngoingTaskLinksResponse,
      uid,
      storeId
    );
    try {
      await deleteGroupById.mutateAsync({
        groupId: currentGroup.id,
        roomId: roomId,
      });
      onTriggerNewModal(ModalTypes.DEFAULT_VIEW);
      showToast(t("groupDeletedString"));
    } catch (error) {
      alert(t("searchErrorString"));
    }
  };

  return (
    <>
      <Modal
        visible={modalVisibility}
        handleCloseBtn={() => onTriggerNewModal(ModalTypes.DEFAULT_VIEW)}
      >
        <Prompt
          title={""}
          titleId={`delete-${currentGroup?.id || "1"}`}
          header={
            <ModalHeader
              title={t("deleteGroupString")}
              ariaCloseTxt="Close prompt."
            />
          }
          footer={
            <ModalFooter>
              <Button
                text={t("cancelString")}
                type="secondary"
                onClick={() => onTriggerNewModal(ModalTypes.DEFAULT_VIEW)}
              />
              <Button
                type="danger"
                text={t("deleteString")}
                ssrIcon={trashCan}
                onClick={handleDeleteRoom}
                style={{
                  backgroundColor: "var(--pink)",
                  color: "var(--white)",
                }}
              />
            </ModalFooter>
          }
        >
          <>
            <SizedText $fontSize={16} $bold={true}>
              <Trans values={{ item: currentGroup?.name || "" }}>
                sureToDeleteGroupString
              </Trans>
            </SizedText>
            <SizedText $fontSize={14} $bold={false}>
              <Trans>deleteGroupAndAllArticlesString</Trans> <br />
              <Trans>canNotBeUndoneString</Trans>
            </SizedText>
          </>
        </Prompt>
      </Modal>
    </>
  );
};
