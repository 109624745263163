import React from "react";
import { Trans } from "@coworker/locales";
import AddonList from "./AddonList";
import useInsightsAddonLists from "../../hooks/useInsightsAddonLists";

const AddonProducts = ({ itemLinker, params }) => {
  const { data, loading, total, errorLoadingProducts } = useInsightsAddonLists(
    false,
    params
  );
  const link = params?.location
    ? `/insights/location/${params.location}/products`
    : "/insights/products";

  return (
    <AddonList
      data={!loading && data}
      title={<Trans>insightsAddonArticleListTitle</Trans>}
      testId="addonProducts"
      link={link}
      total={total}
      itemTransformFn={itemLinker}
      loading={loading}
      errorLoadingProducts={errorLoadingProducts}
      noPadding={true}
    />
  );
};

export default AddonProducts;
