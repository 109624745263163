import React from "react";
import styled from "styled-components";
import { ReactComponent as OpenIcon } from "../../assets/svg/arrow-small-forward.svg";
import { Link } from "../Link";

const Button = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitleLink = styled(Link)`
  color: var(--black);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 22px;
  border-bottom: 1px solid var(--grey150);
`;

const Text = styled.p`
  height: 24px;
  line-height: 1.5;
`;

const TotalText = styled.p`
  height: 24px;
  line-height: 1.7;
`;

const WrappedOpenIcon = styled(OpenIcon)`
  margin-right: -8px;
`;

const ItemListLink = ({ title, link, total, testId }) => {
  return (
    <div data-testid={testId}>
      {title && (
        <StyledTitleLink to={link}>
          <Text>{title}</Text>
          {link && (
            <Button>
              <TotalText>{total}</TotalText>
              <WrappedOpenIcon />
            </Button>
          )}
        </StyledTitleLink>
      )}
    </div>
  );
};

export default ItemListLink;
