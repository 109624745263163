import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { sortingTypes } from "@coworker/types/lib/taskSortingTypes";
import { taskStatusFilterTypes } from "@coworker/types/lib/taskStatusFilterTypes";
import { taskFilters } from "../constants/filters";
import { closedReasonFilterItems } from "./InputPopup/Filters/ClosedReasonFilter";
import { taskStateFilterItems } from "./InputPopup/Filters/TaskStateFilter";

export const titleKeys = {
  created_by_me_open: "teamOpenString",
  created_by_me_in_progress: "inProgressString",
  created_by_me_planned: "plannedString",
  created_by_me_completed: "completd3String",
  created_by_me_closed: "closedFirstUppercaseString",
  all_ongoing: "inProgressInMyStoreString",
  all_closed: "allClosedInThisStore",
  all_completed: "allCompletedInStore",
  in_progress: "inProgressInMyTeamString",
  planned: "plannedString",
  completed: "completedByMyTeamString",
  closed: "closedByMyTeamString",
  related_product_tasks: "relatedTasksString",
};

export function TaskTypeFilterTitle({ filter }: { filter: string[] }) {
  if (filter?.includes(taskFilters.PICK_AND_REFILL)) {
    return <Trans>addonTitleString</Trans>;
  } else if (filter?.includes(taskFilters.REFILL)) {
    return <Trans>userTaskFilterRefillString</Trans>;
  } else if (filter?.includes(taskFilters.PRODUCT_ISSUE)) {
    return <Trans>productString</Trans>;
  } else if (filter?.includes(taskFilters.TESTBUY_FOLLOW_UP)) {
    return <Trans>userTaskFilterTestBuyFollowUpString</Trans>;
  } else if (filter?.includes(taskFilters.MFAQ_FOLLOW_UP)) {
    return <Trans>userTaskFilterMFAQFollowUpString</Trans>;
  } else if (filter?.includes(taskFilters.CUSTOM)) {
    return <Trans>userTaskFilterCustomString</Trans>;
  } else if (filter?.includes(taskFilters.RECURRING)) {
    return <Trans>userTaskFilterRecurringString</Trans>;
  } else if (filter?.includes(taskFilters.PRODUCT_QUALITY)) {
    return <Trans>productQualityReportString</Trans>;
  } else if (filter?.includes(taskFilters.ALL)) {
    return <Trans>userTaskFilterAllString</Trans>;
  } else if (filter?.includes(taskFilters.OTHER)) {
    return <Trans>otherString</Trans>;
  } else if (filter?.includes(taskFilters.ROOM_SETTINGS)) {
    return <Trans>roomSettingsString</Trans>;
  } else {
    return null;
  }
}

export function TaskSortFilterTitle({ filter }: { filter: string[] }) {
  if (filter.includes(sortingTypes.BY_NEWEST_FIRST)) {
    return <Trans>newestFirstString</Trans>;
  } else if (filter.includes(sortingTypes.BY_OLDEST_FIRST)) {
    return <Trans>oldestFirstString</Trans>;
  } else if (filter.includes(sortingTypes.BY_GRID_CODE)) {
    return <Trans>byGridcodeString</Trans>;
  } else {
    return null;
  }
}

export function TaskStatusFilterTitle({ filter }: { filter: string[] }) {
  if (filter.includes(taskStatusFilterTypes.ALL_COMPLETED_TASKS)) {
    return <Trans>allCompletedTasksString</Trans>;
  } else if (filter.includes(taskStatusFilterTypes.COMPLETED_WITH_CHANGES)) {
    return <Trans>completedWithChangesString</Trans>;
  } else if (filter.includes(taskStatusFilterTypes.COMPLETED_WITHOUT_CHANGES)) {
    return <Trans>completd3String</Trans>;
  } else {
    return null;
  }
}

export function ClosedReasonFilterTitle({ filter }: { filter: string[] }) {
  const translationString = closedReasonFilterItems.find((item) =>
    filter.includes(item.type)
  )?.translation;
  if (translationString) return <Trans>{translationString}</Trans>;
  return <></>;
}

export function TaskGroupFilterTitle({ groupBy }: { groupBy: string }) {
  const { t } = useTranslation();
  return `${t("groupByTitleString")} ${t(`${groupBy}String`).toLowerCase()}`;
}

export function TaskStateFilterTitle({ state }: { state: string }) {
  const { translation } =
    taskStateFilterItems.find((item) => item.type === state) || {};
  return translation ? <Trans>{translation}</Trans> : state;
}
