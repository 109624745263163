export type Article = {
  id: string;
  active: "ACTIVE" | "INACTIVE";
  itemName: string;
  itemType: string;
  itemColor: string;
  imageSmall: string;
  nbrArticles: number;
  newNbrArticles?: number;
  hfb: string;
  productArticleType: string;
  productArticleId: string;
  roomId: string;
  supplierNumber: string | undefined;
  groupId?: string | undefined;
  groupName?: string | undefined;
  dateStamp: string | undefined;
  isSkeleton?: boolean | undefined;
  isFromArticleView?: boolean | undefined;
};

export type ArticleWithRoomCount = Article & {
  inAmountOfRooms: number;
};

export type Articles = Article[];
export type PartialArticle = Partial<Article>;

export type ArticleSupplierNumberResponse = {
  supplier_number: string;
  supplier_number_unknown: boolean;
};

export type ArticleInfo = {
  id: string;
  name?: string;
  descriptives?: string[];
  image?: string;
  hfb?: string;
  smallImage?: string;
  type?: string;
};

export type ArticlesInfo = ArticleInfo[];

export type ArticleIdNbrArticles = {
  articleId: string;
  nbrArticles: number;
};

export type Id = {
  id: string;
};

export type EdsData = {
  classType: string;
  classUnitType: string;
  storeId: string;
  itemType: string;
  productArticleId: string;
  inRange: boolean;
  inStockRange: boolean;
  inCustomerOrderRange: boolean;
  validFromDateTime: string;
  validToDateTime?: string | undefined;
  createdAt?: string | undefined;
  updatedAt?: string | undefined;
};

export type SalesStopData = {
  classType: string;
  classUnitType: string;
  storeId: string;
  productArticleId: string;
  itemType: string;
  reason: string;
  scope: string;
  supplierNumber?: string;
  weekStampFormat?: boolean;
  productionDates?: string;
  weekStamp?: string;
  salesStopCreationDateTime: string;
  createdAt?: string;
  updatedAt?: string;
};

export function isArticleWithRoomCount(
  article: Article | ArticleWithRoomCount
): article is ArticleWithRoomCount {
  return (article as ArticleWithRoomCount).inAmountOfRooms !== undefined;
}
