const LivingSituationOptions = [
  "Living single",
  "Living together",
  "Living with children",
  "Multi-generational living",
];

export function getLivingSituationOptions() {
  return LivingSituationOptions.map((value) => ({
    value,
    name: value,
  }));
}
