import React from "react";

import "@ingka/product-identifier/dist/style.css";
import ProductIdentifier from "@ingka/product-identifier";
import { formatFullIdWithDots } from "@coworker/reusable";

interface ArticleIdentifierProps {
  articleId: string;
}

export function ArticleIdentifier({
  articleId: productId,
}: ArticleIdentifierProps) {
  return <ProductIdentifier value={formatFullIdWithDots(productId)} />;
}
