import {
  HEADER,
  SECTION,
  LOCATION,
  DESCRIPTION,
  ASSIGNMENT,
  ACTIVITY,
  MFAQ_QUESTION,
  MFAQ_AREA,
  IMAGES,
} from "./_common";

import { PickUpTask, CompleteTask } from "../actions/index";
import { MFAQ_RESOLUTION } from "../widgets";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fields: [
    HEADER,
    { type: MFAQ_RESOLUTION },
    SECTION("taskDetailsString"),
    MFAQ_QUESTION,
    DESCRIPTION("description", "description4String", "description"),
    LOCATION(),
    ASSIGNMENT("profileIcon"),
    MFAQ_AREA,
    IMAGES,
    SECTION("taskHistoryString"),
    ACTIVITY,
  ],
  actions: [PickUpTask, CompleteTask],
};
