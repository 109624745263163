import React from "react";
import posed from "react-pose";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

export const DARK_TOAST = "grey900";
export const LIGHT_TOAST = "grey300";
export const WARNING_TOAST = "warning";
export const SUCCESS_TOAST = "green";
type DARK = typeof DARK_TOAST;
type LIGHT = typeof LIGHT_TOAST;
type WARNING = typeof WARNING_TOAST;
type SUCCESS = typeof SUCCESS_TOAST;

const defaultVisibilityInSeconds = 3;
const undoDelayInSeconds = 1.5;

type ToastContextState = {
  msg: React.ReactNode;
  actionText?: React.ReactNode;
  actionHandler: (() => void) | undefined;
  start: number;
  duration?: number | undefined;
  color?: DARK | LIGHT | WARNING | SUCCESS | undefined;
  onDestroy?: () => void;
};
type ToastContextType = [
  ToastContextState | null,
  React.Dispatch<React.SetStateAction<ToastContextState | null>>
];

const ToastContext = React.createContext<ToastContextType>([null, () => {}]);
function useToastNotification() {
  const [state, setState] = React.useContext(ToastContext);
  const showToast = (
    msg: React.ReactNode,
    actionText?: React.ReactNode,
    actionHandler?: () => void,
    duration?: number,
    color?: DARK | LIGHT | WARNING | SUCCESS | undefined,
    onDestroy = () => {}
  ) =>
    setState({
      msg,
      actionText,
      actionHandler,
      start: Date.now(),
      duration,
      color,
      onDestroy,
    });
  const showToastWithUndo = (
    msg: React.ReactNode,
    undoHandler: () => void,
    duration?: number
  ) => {
    if (!undoHandler) return showToast(msg);
    setTimeout(
      () =>
        setState({
          msg,
          actionText: <Trans>undoString</Trans>,
          actionHandler: () => {
            undoHandler();
            setState(null); // When Undo is pressed, hide toast immediately, to prevent recording undo twice.
          },
          start: Date.now() + undoDelayInSeconds * 1000, // Offset +1.5s so the toast will be shown later.
          duration,
        }),
      250
    );
  };
  const { msg } = state || {};
  return { noToastShown: !msg, showToast, showToastWithUndo };
}

type ToastNotificationProviderProps = {
  children: React.ReactNode;
};

function ToastNotificationProvider({
  children,
}: ToastNotificationProviderProps) {
  const [state, setState] = React.useState<ToastContextState | null>(null);

  return (
    <ToastContext.Provider value={[state, setState]}>
      {children}
    </ToastContext.Provider>
  );
}

const Toast = styled.div`
  /* shared between Curtain and Toast */
  color: var(--white);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  cursor: pointer;

  /* Specific for toast */
  background-color: var(--${(props) => props.color});
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(17, 17, 17, 0.1);
  min-height: 42px;
  padding: 10px 0;
  bottom: 90px;
  left: 24px;
  right: 24px;
  z-index: var(--z-toast);
`;

const PosedToast = posed(Toast)({
  draggable: "y",
  dragBounds: { top: -100, bottom: 0 },
  // @ts-ignore - part of React Posed
  dragEnd: { transition: posed.inertia },
  hide: { y: "-100%" },
});
const Action = styled.div`
  font-weight: bold;
  margin: auto 0;
  padding: 8px 16px;
`;
const Text = styled.div`
  margin: auto 16px;
  padding: 8px 0;
`;
const VCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
function ToastNotification() {
  const [state, setState] = React.useContext(ToastContext);
  const { msg, actionText, actionHandler, onDestroy, start, duration, color } =
    state || {};
  const backgroundColor = color ? color : DARK_TOAST;

  React.useEffect(() => {
    if (!start) return;
    const now = Date.now();
    const durationInSeconds = duration ? duration : defaultVisibilityInSeconds;
    const end = start + durationInSeconds * 1000;
    let cleanup: NodeJS.Timeout;
    if (end > now) {
      cleanup = setTimeout(() => {
        if (onDestroy) onDestroy();
        setState(null);
      }, end - now);
    }

    return () => cleanup && clearTimeout(cleanup);
  }, [start, duration, color, setState, onDestroy]);
  const onClick = () => {
    setState(null);
    actionHandler && actionHandler();
  };
  const hideOnSwipe = { y: (y: number) => y < -20 && setState(null) };

  return msg ? (
    // @ts-ignore
    <PosedToast
      onValueChange={hideOnSwipe}
      data-testid="curtainNotification"
      color={backgroundColor}
    >
      <VCenter>
        <Text>{msg}</Text>
        {actionHandler && (
          <Action data-testid="notificationViewLink" onClick={onClick}>
            {actionText}
          </Action>
        )}
      </VCenter>
    </PosedToast>
  ) : null;
}

export { ToastNotification, ToastNotificationProvider, useToastNotification };
