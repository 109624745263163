import React from "react";
import AvatarImage from "../AvatarImage";

import styles from "./avatar.module.css";

export default function Avatar({ fixaUid, big, className = "" }) {
  return (
    <div
      className={`${styles["avatar"]} ${className}`}
      data-testid="profileAvatar"
    >
      <AvatarImage big={big} fixaUid={fixaUid} />
    </div>
  );
}
