import { useQuery } from "@tanstack/react-query";
import { searchArticlesInRooms } from "../services/articles.service";

export const useSearchArticlesInRoom = (
  storeId: string,
  searchQuery: string
) => {
  return useQuery({
    queryKey: ["searchArticlesInRooms", { storeId, searchQuery }],
    queryFn: () => searchArticlesInRooms(storeId, searchQuery),
  });
};
