import React, { useState } from "react";
import { OverflowCarousel } from "@ingka/carousel";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import { Room } from "../types/room";
import {
  CarouselImage,
  PaddedContainer,
  ZoomImageContainer,
  ZoomStaticButtonContainer,
} from "../styles/styles";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SkapaDualButton from "../../SkapaDualButton";
import { SkapaTheatre } from "./SkapaTheatre";
import plus from "@ingka/ssr-icon/paths/plus";
import minus from "@ingka/ssr-icon/paths/minus";
import { Image } from "../types/images";

interface RoomImagesCarouselProps {
  room: Room;
}

interface ZoomRoomImagesCarousel {
  signedUrlImages: Image[];
  selectedImageName: string;
}

const ZoomRoomImages = ({
  signedUrlImages,
  selectedImageName,
}: ZoomRoomImagesCarousel) => {
  const [zoomLevels, setZoomLevels] = useState<number[]>([]);
  const sortedImages = [...signedUrlImages].sort((a, b) => {
    if (a.fileName === selectedImageName) return -1;
    if (b.fileName === selectedImageName) return 1;
    return 0;
  });
  const handleZoomChange = (index: number, change: number) => {
    const currentZoom = zoomLevels[index] || 1;
    if (change < 0) {
      if (currentZoom <= 1) return;
    }
    const newZoom = currentZoom + change;

    const updatedZoomLevels = [...zoomLevels];
    updatedZoomLevels[index] = newZoom;
    setZoomLevels(updatedZoomLevels);
  };

  return (
    <PaddedContainer $py={"0px"} $px={"16px"}>
      <OverflowCarousel id="imagesByRoom" controlBtnType="primary">
        {sortedImages.map((image, index) => (
          <ZoomImageContainer key={`image-container-${index}`}>
            <TransformWrapper
              key={`image${index}`}
              doubleClick={{ disabled: true }}
              limitToBounds={false}
              centerZoomedOut={true}
            >
              <TransformComponent>
                <div style={{ transform: `scale(${zoomLevels[index] || 1})` }}>
                  <CarouselImage
                    key={`image${index}`}
                    src={image?.url ?? ""}
                    $rotation={image?.rotation || 0}
                  />
                </div>
              </TransformComponent>
            </TransformWrapper>
            <ZoomStaticButtonContainer>
              <SkapaDualButton
                orientation="vertical"
                ssrIconFirst={plus}
                ssrIconSecond={minus}
                onClickFirst={() => handleZoomChange(index, 0.3)}
                onClickSecond={() => handleZoomChange(index, -0.3)}
              ></SkapaDualButton>
            </ZoomStaticButtonContainer>
          </ZoomImageContainer>
        ))}
      </OverflowCarousel>
    </PaddedContainer>
  );
};

export const RoomImagesCarousel = ({ room }: RoomImagesCarouselProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [imageName, setImageName] = useState("");
  const signedUrlImages = useImagesBySignedUrl(room);

  if (signedUrlImages && signedUrlImages.length === 0) return <></>;

  const CarouselImages = () => (
    <PaddedContainer $py={"0px"} $px={"16px"}>
      <OverflowCarousel id="imagesByRoom">
        {signedUrlImages.map((image, index) => (
          <CarouselImage
            key={`image${index}`}
            src={image?.url ?? ""}
            $rotation={image?.rotation || 0}
            onClick={() => {
              setOpenModal(true);
              setImageName(image.fileName);
            }}
          />
        ))}
      </OverflowCarousel>
    </PaddedContainer>
  );

  return (
    <>
      <CarouselImages />

      {openModal && (
        <SkapaTheatre
          modalOpen={openModal}
          handleCloseBtn={() => setOpenModal(false)}
          children={
            <ZoomRoomImages
              signedUrlImages={signedUrlImages}
              selectedImageName={imageName}
            />
          }
        />
      )}
    </>
  );
};
