import React from "react";
import { useTranslation } from "@coworker/locales";
import trackerHelper from "../../helpers/tracker";
import { usePinLock, validPinFormat } from "../../hooks/usePinLock";
import { useCurtainNotification } from "../CurtainNotification";
import PinCodeForm from "./PinCodeForm";

export default function PinCodeReset({
  onClose,
  isManualReset,
  resetRequested,
  firstSet,
}) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { showCurtain } = useCurtainNotification();
  const [firstEntry, setFirstEntry] = React.useState("");
  const [passedInitialCheck, setPassedInitialCheck] = React.useState(false);
  const { hasPin, storePin, verifyPin } = usePinLock();

  const needsConfirmation = isManualReset && hasPin;

  async function processCode(code) {
    if (validPinFormat(code)) {
      setErrorMessage("");
      if (!passedInitialCheck && needsConfirmation) {
        if (verifyPin(code)) {
          setPassedInitialCheck(true);
          setErrorMessage("");
        } else {
          setErrorMessage("pinYouEnteredIsIncorrectString");
        }
      } else if (!firstEntry) {
        setFirstEntry(code);
      } else {
        if (firstEntry === code) {
          setLoading(true);
          if (!hasPin) {
            trackerHelper.pinLock.logSet();
          } else {
            trackerHelper.pinLock.logReset();
          }
          await storePin(code);
          showCurtain(t("yourPinCodeHasBeenSetUpString"));
          setLoading(false);
          onClose();
        } else {
          setFirstEntry("");
          setErrorMessage("pinCodesDidNotMatchString");
        }
      }
    } else {
      setErrorMessage("onlyNumericalCharactersAllowedForPinString");
      trackerHelper.pinLock.logNonDigitEntered();
    }
  }

  const isReset = hasPin && resetRequested;

  function getInstructionsText() {
    if (needsConfirmation && !passedInitialCheck) {
      return "pleaseEnterYourCurrentPINCodeString";
    }
    if (firstEntry) {
      return "confirmPinCodeString";
    }
    if (isManualReset || isReset || !hasPin) {
      return "createYourCodeString";
    }
    return "typeIn4TDigitCodeString";
  }

  const closeHandler = React.useCallback(() => {
    trackerHelper.pinLock.logCloseReset({ isManualReset, resetRequested });
    onClose();
    // Ignored: isManualReset, resetRequested
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClose]);

  return (
    <PinCodeForm
      error={errorMessage}
      onPinEntered={processCode}
      showLogout={!resetRequested && !firstSet}
      title={getInstructionsText()}
      showCloseButton={hasPin && !firstSet}
      onClose={closeHandler}
      instructions={isReset ? "resetOfPinCodeRequestedString" : ""}
      loading={loading}
    />
  );
}
