import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: (
    <div>
      英格卡中国员工隐私政策
      <br />
      <br />
      INGKA China Co-worker Privacy Policy
    </div>
  ),

  rightToPersonalInformationText: (
    <>
      <div>
        最近更新日期：【2022 年 08 月 03 日】
        <br />
        <br />
        Last Update Date: 3 rd Aug. 2022
        <br />
        <br />
        版本生效日期：【2022 年 09 月 02 日】
        <br />
        <br />
        Effective Date: 2 nd Sep. 2022
        <br />
        <br />
        <br />
        【英格卡中国】实体（以下简称“我们”、“公司”、或“英格卡中国”），通过本政策通知英格卡中
        国员工有关其个人信息的收集与使用的目的、范围与方法。仅为本政策目的，“员工”包括受英格卡中
        国人力资源管理约束的个人，包括直接雇佣、劳务派遣员工、为英格卡中国服务的退休人员和实习生，
        但不包括为英格卡中国提供服务的任何第三方组织的员工。
        <br />
        <br />
        This Privacy Policy applies to all INGKA China co-workers (hereinafter
        referred to as “we” or “us”, “company”, or “Ingka China”). The objective
        is to inform you of the purpose, scope and method of collecting and
        using your personal information. For the purpose of this Privacy Policy
        only, "co-worker" includes individuals who are subject to Ingka China's
        human resources management, including direct hired employees, seconded
        workers, retirees and interns serving Ingka China, yet excluding those
        who work for other thirdparty organization and provide services to Ingka
        China.
        <br />
        <br />
        <br />
        如果您有任何疑问、意见或建议，请通过以下联系方式与信息安全与隐私保护团队联系：
        <br />
        If you have any questions, comments or suggestions, please contact
        Information Security and Data Privacy Team with following contact
        information:
        <br />
        <br />
        电子邮件：
        <EmailLink email="isdp.general.cn@ingka.ikea.com" />
        <br />
        <br />
        E-mail: <EmailLink email="isdp.general.cn@ingka.ikea.com" />
        <br />
        <br />
        <br />
        英格卡中国深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对
        我们的信任，保护您的个人信息；同时，英格卡中国承诺，我们将按业界成熟的安全标准，采取相应的
        安全保护措施来保护您的个人信息。
        请仔细阅读并了解本《隐私政策》。如您选择加入英格卡中国，
        即意味着您完全理解本隐私政策的全部内容，并同意我们按照本隐私政策收集、使用、储存和分享您的
        相关信息。
        <br />
        <br />
        INGKA China knows the importance of personal information to you very
        well, so we will do our best to protect your personal information. We
        are committed to maintaining your trust and protecting your personal
        information. In the meantime, INGKA China promises that we will adopt
        appropriate security protection measures to protect your personal
        information based on mature security standards of the industry. Please
        read carefully and understand this Privacy Policy. Your choice of being
        employed by INGKA China means that you have fully understood the entire
        content of this Privacy Policy and agreed that we could collect, use,
        store and share your relevant information in accordance with this
        Privacy Policy.
        <br />
        <br />
        <br />
        注：本《隐私政策》版本更新日期为【2022 年 08 年 03 日】，将于【2022 年
        09 月 02 日】正式生 效，在【2022 年 08 月 03 日】至【2022 年 09 月 01
        日】期间，对于新入职员工，或是您认为更新
        的《隐私政策》对您更有利，我们同意双方适用新的《隐私政策》内容。
        <br />
        Please notice: The last update date of this privacy policy is 3 rd Aug.
        2022, and the effective date is 2 nd Sep. 2022. During the period
        between 3 rd Aug. 2022 and 1 st Sep. 2022, for all new co-workers, or
        co-workers who prefer to apply the new version of the Privacy Policy, we
        agree that the new version of the Privacy Policy shall apply.
        <br />
        <br />
      </div>
      <div>
        <h1>目录 Catalogue</h1>
        定义
        <br />
        Definition
        <br />
        <br />
        我们如何收集和使用您的个人信息？
        <br />
        How do we collect and use your personal information?
        <br />
        <br />
        您的个人信息是否会被委托处理、共享、转让或披露？
        <br />
        Will your personal information be entrusted, shared, transferred or
        disclosed?
        <br />
        <br />
        您的个人信息将被如何保护？
        <br />
        How will your personal information be protected?
        <br />
        <br />
        我们如何存储您的个人信息？
        <br />
        How will your personal information be stored?
        <br />
        <br />
        您的权利
        <br />
        Your rights
        <br />
        <br />
        我们如何处理未成年人的个人信息?
        <br />
        How do we handle personal information of the minors?
        <br />
        <br />
        您的个人信息如何在全球范围转移?
        <br />
        How is your personal information transferred globally?
        <br />
        <br />
        本政策如何更新？
        <br />
        How do we update this policy?
        <br />
        <br />
        任何疑问
        <br />
        Any doubt
        <br />
        <br />
      </div>
      <div>
        <h1>定义 Definition</h1>
        <span style={{ fontWeight: "bold" }}>英格卡集团：</span>
        指 INGKA Holding B.V.及其控制实体。
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>INGKA Group:</span> refers to INGKA
        Holding B.V. and its controlled entities.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>英格卡中国: </span> 指 INGKA Pro
        Holding
        B.V.在中华人民共和国（以下简称“中国”）境内（为本隐私政策之目的，中国的香港、澳门和台湾地区除外）投资的所有法人实体
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>INGKA China:</span> refers to all
        legal entities invested by INGKA Pro Holding B.V. in mainland (for the
        purpose of this Privacy Policy, Hong Kong, Macau and Taiwan are
        excluded) of the People’s Republic of China (hereinafter referred to as
        “China”).
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}> 个人信息 </span>:
        以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>Personal Information </span>: All
        kinds of information related to identified or identifiable natural
        persons recorded by electronic or other means, excluding the information
        processed anonymously.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>敏感个人信息</span>:
        一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
        <br />
        <span style={{ fontWeight: "bold" }}>
          Sensitive personal information:
        </span>
        The personal information that is likely to result in damage to the
        personal dignity of any natural person or damage to his or her personal
        or property safety once disclosed or illegally used, including such
        information as biometric identification, religious belief, specific
        identity, medical health, financial account and whereabouts and tracks,
        as well as the personal information of minors under the age of 14.
        <br />
        <br />
      </div>
      <div>
        <h1>
          我们如何收集和使用您的个人信息？How do we collect and use your
          personal information?
        </h1>
        我们收集、使用或披露您的个人信息来建立、管理、维护和终止英格卡中国与您的雇佣关系或您离职之后与英格卡中国的关系，包括但不限于如下目的及收集的个人信息种类，其中对于您的敏感个人信息已进行
        <span style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
          加粗与下划线
        </span>
        处理，以提请您注意：
        <br />
        We collect, use or disclose your personal information to establish,
        manage, maintain or terminate the employment relationship between you
        and Ingka China, or the relationship after your dismission, which
        includes but is not limited to the following purposes and personal
        information types. Please note, the sensitive personal information has
        been{" "}
        <span style={{ fontWeight: "bold", textDecorationLine: "underline" }}>
          bolded and underlined{" "}
        </span>
        to draw your attention:
        <br />
        <ol>
          <li>
            为了处理您的求职申请，并审查您作为一个英格卡中国未来员工的资格，我们会收集、使用您的【姓名、性别、民族、出生日期、户籍地、联系电话、联系地址、工作经历（包括过往或当前工作单位、工作起始时间、职位、雇主评价等）、教育经历（包括学历、就读院校、专业、毕业时间等）、技能与证书（包括学位证书、学历证书等）、离职证明、解除关系证明、退工单、就业失业登记证、证件照、
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              无犯罪记录证明、政治面貌、户口本首页及本人页、居住证、暂住证、婚姻状况、结婚证、子女出生证明、独生子女证、残疾人证】；
            </span>
            <br />
            In order to process your job application and review you
            qualification as a future employee of Ingka China, we will collect
            and use your [name, gender, ethnicity, date of birth, place of
            residence, contact number, contact address, work experience
            (including former or present employer, start time of work, position,
            employer’s evaluation, etc.), education experience (including
            academic qualification, college, major, graduate time, etc.), skills
            and certificates (including degree certificate, diploma, etc.),
            employment separation certificate, proof of termination, official
            leaving notice, employment or unemployment registration,
            identification photo,{" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              certificate of no criminal record, politics status, the first page
              and self-contained page of residence booklet, residence permit,
              temporary residential permit, marriage status, marriage
              certificate, child birth certificate, one-child certificate,
              disability certificate];
            </span>
          </li>
          <li>
            为了处理您的内部晋升申请与资格，我们会收集、使用您的【考勤记录、工作评价、工作年限、技能与证书、奖惩记录】；
            <br />
            In order to process internal promotion application and qualification
            verification, we will collect and use your [attendance records,
            performance appraisal, work seniority, skills and certificates,
            disciplinary records];
          </li>
          <li>
            为了实施公司内部条例、调查和政策，我们会收集、使用您的【考勤记录、工作记录、使用公司设备过程产生的数据、与供应商/客户的联络数据】；
            <br />
            In order to implement internal policy, procedures and investigation,
            we will collect and use your [attendance records, work records, data
            generated from company equipment, contact data with
            suppliers/customers];
          </li>
          <li>
            为了实施薪酬福利，包括员工福利及退休金计划，我们会收集您的
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              【社保公积金相关信息、银行卡账号】；
            </span>
            <br />
            In order to implement payroll and benefits, including employee
            benefits and retirement pay-out, we will collect and use your{" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              [relevant information about social insurance and public
              accumulation funds, bank account number];
            </span>
          </li>
          <li>
            为了更好地促进、实施和管理员工服务、健康、卫生和安全问题，我们会收集、使用您的【衣服尺码、{" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              体检报告、健康证、生育信息、医疗记录、传染病史】；
            </span>
            <br />
            In order to develop and improve employee service, health and
            security management, we will collect and use your{" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              [clothes size, medical examination report, health certificate,
              maternity information, medical records, contagious disease
              history];
            </span>
          </li>
          <li>
            为了在英格卡中国内部系统录入或注册您的信息以便未来需要（包括但不限于费用报销、国际搬迁），我们会收集您的{" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              【身份证号、护照号、银行卡账号、
            </span>{" "}
            相关旅行住宿情况】；
            <br />
            In order to register for INGKA China internal systems with your
            personal information for future purposes (including but not limited
            to expense claim and international moving), we will collect and use
            your
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              [ID number, passport number, bank account number,
            </span>{" "}
            related travel and accommodation information];
          </li>
          <li>
            为了实现英格卡中国的合法商业目的，其中包括日常开展业务、出售或转让全部或部分的业务及进行诉讼、调查或争议解决，包括为与供应商、合作伙伴或消费者进行联系、公司组织活动的分享或公司作为雇主身份进行品牌宣传，我们会收集、使用您的【姓名、手机号码及其他电话号码，邮箱，背景资质信息，含有员工个人影像的照片、视频、文章，工作记录等在工作中产生的信息】；
            <br />
            In order to achieve Ingka China's legitimate business goals,
            including the daily business operation, the sale or transfer of all
            or part of business, litigation, investigation or dispute
            resolution, contacts with suppliers/business partners/customers,
            information sharing of company events, or the company brand
            promotion as an employer, we will collect and use your [name, mobile
            phone number and other phone numbers, email address, background
            information, and any information generated from work, e.g. photos,
            videos, articles and work records, etc.]
          </li>
          <li>
            为了使用、管理公司 IT 系统、维护 IT
            系统安全和网络安全（如检测漏洞、管理和保护访问服务器的密码、监控系统和网络上的活动，并将其与欺诈模式和违反政策的行为相匹配），我们会收集、使用您的【账号、密码、使用公司设备或系统过程产生的数据、网络日志】；
            <br />
            In order to utilize, manage and maintain IT system security and
            network security (e.g. detect bug, manage and protect password for
            server access, monitor activity on systems and networks, and match
            those with fraud patterns and policy violations), we will collect
            and use your [account, password, data and web logs generated by
            using company equipment or systems];
          </li>
          <li>
            为了管理和促进英格卡中国的业务活动，包括监管与合规报告、审计，以及本条款所述之目的的其他信息，我们会收集您的【工作中产生的记录、奖惩记录、使用公司设备或系统过程产生的数据】；
            <br />
            In order to manage and improve INGKA China’s business operation,
            including supervision and compliance report, and all other
            information for the purposes stated in this clause, we will collect
            your [work records, disciplinary records, data and web logs
            generated by using company equipment or systems];
          </li>
          <li>
            为了给您配备社会保险或商业保险，我们会收集您的【受益人信息，包括您的父母/配偶/子女信息
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              （含 14 周岁以下的未成年人相关信息）】；
            </span>
            <br />
            In order to provide you with social insurance or commercial
            insurance, we will collect your [beneficiary information, which may
            contain the information of your parents/spouse/children (including{" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              the personal information of minors under 14)];
            </span>
          </li>
          <li>
            为了记录您的考勤，我们会收集您的
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              【人脸、指纹或其他生物识别信息、考勤信息】；
            </span>
            <br />
            In order to record your attendance, we will collect your{" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              [face, fingerprint or other biometric information, attendance
              information]
            </span>
          </li>
          <li>
            为了进行休假、考勤管理，我们会收集您的【出勤及请假信息、您的请假事由及相关凭证（如您的
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              怀孕生育信息、医疗记录等）】。
            </span>
            In order to implement the leave and attendance management, we will
            collect your [attendance and leave information, reasons for your
            leave and related documents (such as your{" "}
            <span
              style={{ fontWeight: "bold", textDecorationLine: "underline" }}
            >
              maternity information, medical records etc.)]
            </span>
          </li>
          <li>
            为配合疫情防控，我们可能收集、使用您的【个人出行记录、健康码、行程码、核酸检测结果】等信息；
            <br />
            In order to respond to the requirements of epidemic prevention and
            control, we may collect and use your [personal travel records,
            health QR code, trip code, nucleic acid test report etc.];
          </li>
          <li>
            出于维护安全目的，我们会在线下店铺中安装 CCTV
            以监控店铺基本情况，在此过程中，可能会收集您【在监控区域的画面】；
            <br />
            In order to secure safety, we will install CCTV in the offline store
            to monitor the basic circumstances. In this context, we may collect
            your [picture in the monitoring area].
          </li>
        </ol>
        <br />
        为了建立雇佣关系的目的，英格卡中国要求您提供紧急联络人的姓名与联系方式。该资料的采集完全是为了保护您作为英格卡中国员工的利益。除了用以告知该紧急联络人与您相关的紧急和特殊的情况之外，英格卡中国不会使用或处理与紧急联络人有关的信息。您承诺您（而不是英格卡中国）负有从该紧急联络人取得使用其个人信息的同意的义务。
        <br />
        In order to develop employment relationship, INGKA China collects
        emergency contact information, including name and contact number. The
        collection is solely for the purpose of informing the contact of your
        emergency or special situations and INGKA China shall not use or process
        emergency contact information for other purposes. The responsibility to
        obtain consent to the usage of their personal information lies with you,
        not INGKA China.
        <br />
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>
          您充分知晓，我们收集、使用个人信息可能基于以下合法依据之一：
          <br />
          You shall be fully aware that we may collect and use your personal
          information based on one of the following legitimate foundations:
        </span>
        <br />
        <br />
        <ol>
          <li>
            取得个人的同意；
            <br />
            Where the consent of the individual concerned is obtained;
          </li>
          <li>
            为订立、履行个人作为一方当事人的合同所必需，或者按照依法制定的劳动规章制度和依法签订的集体合同实施人力资源管理所必需；
            <br />
            Where it is necessary for the conclusion or performance of a
            contract to which the individual concerned is a party, or for the
            implementation of human resources management in accordance with the
            labour rules and regulations formulated in accordance with the law
            and the collective contract concluded in accordance with the law
          </li>
          <li>
            为履行法定职责或者法定义务所必需；
            <br /> Where it is necessary for the performance of statutory duties
            or statutory obligations;
          </li>
          <li>
            为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需；
            <br />
            Where it is necessary for the response to a public health emergency
            or for the protection of the life, health and property safety of a
            natural person in an emergency;
          </li>
          <li>
            为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息；
            <br />
            Where such acts as news reporting and supervision by public opinions
            are carried out for the public interest, and the processing of
            personal information is within a reasonable scope;
          </li>
          <li>
            依照法律规定在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；
            <br />
            Where it is necessary to process the personal information disclosed
            by the individual concerned or other personal information that has
            been legally disclosed within a reasonable scope in accordance with
            relevant laws;
          </li>
          <li>
            法律法规规定的其他情形。
            <br />
            Other circumstances stipulated by laws and regulations.
          </li>
        </ol>
        <br />
      </div>
      <div>
        <h2>
          我们从第三方获得您个人信息的情形 <br />
          Obtaining your personal information from a third party
        </h2>
        <br />
        我们可能从第三方获取您授权共享的个人信息（简历信息，包括个人姓名、电话号码、工作经历等）。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
        <br />
        We may obtain the account information (CV information, including name,
        number and working experiences) shared under your authorization from a
        third party. We will first confirm the legality of the source of your
        personal information based on the agreement with a third party, and then
        use your personal information under the premise of complying with
        relevant laws and regulations.
        <br />
        <br />
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>
          使用您个人信息的规则 <br />
          Rules for using your personal information{" "}
        </span>
        如果当我们要将信息用于本政策未载明的其它用途时，会事先征求您的同意或基于其他合法依据。
        <br />
        If we intend to use your information for other purposes not covered by
        this policy, we will ask for your consent in advance or follow other
        legitimate foundations as mentioned above.
        <br />
        <br />
        当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意或基于其他合法依据。
        <br />
        When we intend to use your information collected for a specific purpose
        for other purposes, we will ask for your consent in advance or follow
        other legitimate foundations as mentioned above.
        <br />
        <br />
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>
          您的个人信息是否会被委托处理、共享、转让或披露？Will your personal
          information be entrusted, shared, transferred or disclosed? 委托处理
          Entrustment{" "}
        </span>
        为了向您提供服务，我们可能会委托第三方服务提供商帮助我们提供相关的运营和服务的支持。我们会将您的必要个人信息发送给第三方服务提供商，以提供相关技术服务，但其不能将其用于其他目的。这些服务商可能包括：
        <br />
        In order to provide you with relevant services, we may engage
        third-party service providers to assist us in providing relevant
        operation and service support. We will send your personal information
        where necessary to our service providers for them to provide relevant
        technical services. The service providers should not use your personal
        information for other purposes. The service providers may include the
        following types:
        <br />
        <br />
        <ol>
          <li>
            1. 数据分析服务提供商；
            <br />
            data analysis service provider;{" "}
          </li>
          <li>
            信息技术服务提供商；
            <br />
            information technology service provider;
          </li>
          <li>
            网站/系统托管服务提供商；
            <br />
            web/system hosting service provider;
          </li>
          <li>
            薪资及社会保险服务提供商;
            <br />
            payroll and social insurance service provider;
          </li>
          <li>
            招聘机构/猎头; <br />
            recruitment agency/headhunter;
          </li>
          <li>
            6. 其他适当的或法律允许的服务提供商。 Other service providers which
            may be applicable.
          </li>
        </ol>
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>共享 Share </span>
        <br />
        由于英格卡集团是一家跨国公司，基于集团运营和人力资源管理的需要，您的个人信息可能会在英格卡集团内被共享，以便我们为您提供更为优质的服务。
        <br />
        As INGKA Group is a multinational company, based on the demands of
        operations and human resource management, your personal information may
        be shared within INGKA Group, thus enabling us to provide you with
        better services.
        <br />
        我们承诺，所有的共享仅限您的必要个人信息，且受本隐私政策的约束；如果我们要改变个人信息的使用及处理目的，我们将再次征求您的授权同意或者基于其他合法依据。同时我们会严格遵守相关法律法规对数据跨境传输的各项要求，具体参见本隐私政策“您的个人信息如何在全球范围转移
        “章节。
        <br />
        We promise that all shared information is limited to your personal
        information, and the sharing will be subject to this Privacy Policy; if
        we intend to change the using and handling purpose of your personal
        information, we will ask for your consent anew or conform to other
        legitimate foundations. In the meanwhile, we will strictly comply with
        the requirements for cross-border data transfer as stated in the
        relevant laws and regulations. For details, please refer to “How is your
        personal information transferred globally“ in this Privacy Policy.
        <br />
        <br />
        我们不会与英格卡集团以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
        We will not share your personal information with any company,
        organization or individual other than INGKA Group, except:
        <br />
        <br />
        <ol>
          <li>
            在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。
            We may share your personal information with your explicit consent:
            After granting your explicit consent, we will share your personal
            information with other parties.
          </li>
          <li>
            我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
            We may share your personal information in accordance with the laws
            and regulations, or mandatory requirements from government
            authorities.
          </li>
          <li>
            与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的员工体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。
            We may share your personal information with the authorized partners:
            For the purpose stated in this policy, some of our services will be
            provided by the authorized partners. We may share your personal
            information with our partners, to provide better Co-worker
            experience. We will only share your personal information for
            legitimate, justified, necessary, specific, and explicit purposes,
            and only share the necessary information to provide the service. Our
            partners have no right to use the shared personal information for
            any other purpose.
          </li>
        </ol>
        目前，我们的授权合作伙伴包括以下主要类型：
        <br />
        At present, our authorized partners include the following main
        categories:
        <ol>
          <li>
            保险服务商；
            <br />
            Insurance service provider;
          </li>
          <li>
            薪资和福利管理人；
            <br /> Payroll and benefit service provider;
          </li>
          <li>
            安保承包商；
            <br />
            Security contractor;
          </li>
          <li>
            旅行社及其他差旅服务机构；
            <br />
            Travel agency and other travel service institution;
          </li>
          <li>
            体检服务机构；
            <br />
            Health examination institution;
          </li>
          <li>
            其他适当的或法律允许的服务提供商。
            <br />
            Other service providers which may be applicable.
          </li>
        </ol>
        <br />
        英格卡中国向第三方分享您的个人信息的具体情况请见附件一。
        <br />
        Please refer to Annex 1 for more details about how INGKA China shares
        your personal information.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}> 转让 Transfer </span>
        <br />
        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外:
        <br />
        We will not transfer your personal information to any company,
        organization or individual, except:
        <br />
        <br />
        获得您的明确同意后或者基于其他合法依据，我们会向其他方转让您的个人信息；
        <br />
        After obtaining your explicit consent or based on other legitimate
        foundations, we may transfer your personal information to other parties;
        <br />
        在涉及合并、分立、解散、被宣告破产等时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受此政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
        <br />
        Due to merger, division, dissolution or declaration of bankruptcy, etc.,
        if the transfer of personal information is involved, we will require the
        new company or organization holding your personal information to
        continue to comply with this policy; otherwise, we will ask the company
        or organization to seek for your permission.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>公开披露 Public disclosure </span>
        <br />
        我们仅会在以下情况下，公开披露您的个人信息： We will only publicly
        disclose your personal information in the following circumstances: 1.
        获得您单独同意后。 Your separate consent has been obtained. 2.
        基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。
        Disclosure based on legal requirements: As required by laws, legal
        procedures, lawsuits or mandatory requirements of government
        authorities, we may publicly disclose your personal information.
        <br />
        <br />
      </div>
      <div>
        <h1>
          您的个人信息将被如何保护？How will your personal information be
          protected?
        </h1>
        <br />
        我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权的访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。
        <br />
        We have taken security measures conforming to the industrial standards
        to protect the personal information you provide and to prevent the data
        from being accessed, disclosed publicly, used, modified, damaged or lost
        without authorization. We will take all reasonable and practicable
        measures to protect your personal information.
        <br />
        <br />
        我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与我们的合作关系。
        <br />
        We also require all personnel who may access to your personal
        information to perform the corresponding obligations of confidentiality.
        People who fail to perform these obligations may be investigated for
        legal responsibility or forced to terminate the cooperative
        relationship.
        <br />
        <br />
        互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
        <br />
        The Internet is not an absolutely secure environment, and additionally,
        it’s not certain if the E-mail, instant messaging, social software and
        other communication means with other users are fully encrypted.
        Therefore, we suggest that you use complex passwords and pay attention
        to your personal information security when using such tools.
        <br />
        <br />
        同时，我们将始终在管理、技术处理等过程中尽力确保信息安全，防止发生个人信息非法泄露，或在未经授权情况下被使用、披露、修改和删减。我们将致力于对您个人信息实施有效、得力的保护措施，使其免受各种可预见事故的危害。
        <br />
        At the same time, we will always try our best to guarantee the
        information security during the management, technical processing and
        other processes to prevent the personal information from being leaked
        illegally, or from being used, disclosed, modified or deleted without
        authorization. We will be committed to implementing effective and sound
        measures over your personal information, to protect it from the harm of
        various predictable accidents.
        <br />
        <br />
        在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
        <br />
        Where a personal information security incident occurs unfortunately, we
        will timely inform you as required by the laws and regulations: basic
        information and possible influences of the security incident, the
        treatment measures we have taken or will take, suggestions for you to
        prevent and reduce the risks by yourself, the remedial measures for
        your, etc. We will timely inform you of the related condition of the
        incident via mail, letter, telephone, pushed notification and other
        means. Where it is difficult to inform the subjects of the personal
        information one by one, we will publish an announcement in a reasonable
        and effective way.
        <br />
        <br />
        同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
        <br />
        Meanwhile, we will also actively report the treatment result of the
        personal information security incident as required by the authority.
        <br />
        <br />
      </div>
      <div>
        <h1>个人信息存储期限 Personal information storage period</h1>
        我们仅在本政策所述目的所必需的期间和法律法规要求的时限内保留您的个人信息，某些个人信息根据适用的法律或规定可能有最低保留期限，即使在此期限之前该信息收集的目的已达到。
        <br />
        We only retain your personal information within the period necessary for
        the purposes of this policy and required by the laws and regulations.
        Some personal information is required to be retained for minimum
        retention period under legislation, even if the purpose of using the
        personal information has been served already.
        <br />
        <br />
        除另有适用的法律或法规要求，英格卡中国同意在您的个人信息收集目的已达到时或在您的要求下及时销毁您的个人信息，或者停止除存储和采取必要的安全保护措施之外的处理。
        <br />
        Except if required by laws or legislations, when the purpose of
        processing the information has been served or under your requirement,
        INGKA China agrees to eliminate your personal information, or suspend
        processing except for the storage or necessary security measures.
        <br />
        <br />
      </div>
      <div>
        <span style={{ fontWeight: "bold" }}>您的权利 Your rights </span>
        <br />
        我们将采取适当的技术手段，保证您访问、更新、更正或删除您个人信息等权利，提供与您个人信息收集、使用、分享、存储等方面相关的多种选择。{" "}
        <br />
        We will take appropriate technical measures to guarantee your rights to
        access to, update, correct or delete your personal information, and
        provide various choices in terms of collection, use, sharing and storage
        of your personal information.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>
          访问权、复制权与更正权 Right of access, duplication and correction{" "}
        </span>
        您可以通过英格卡中国相关系统查看、复制您在英格卡中国系统中所留下的个人信息。{" "}
        <br />
        You can examine and duplicate your personal information in the relevant
        systems of INGKA China.
        <br />
        <br />
        您有权对错误或不完整的信息作出更正或更新，您可以登陆
        https://inside.ingka.com/或相关的员工系统对您的个人信息进行更正或更新，同时，您也可以联系英格卡中国员工与文化团队，其会根据本隐私政策所列明的方式和期限响应您的个人信息更正、更新请求。{" "}
        <br />
        You are entitled to correct or update the inaccurate or incomplete
        information on https://inside.ingka.com/ or other related co-worker
        system. You may also contact INGKA China People and Culture Team, who
        will respond to your request in accordance with the approaches and
        period stipulated in this Privacy Policy.
        <br />
        <br />
        但请注意，由于技术限制或安全性考虑，您的部分个人信息我们还无法为您提供访问、复制和更正的服务。{" "}
        <br />
        Please be noted that we might not be capable of providing the access,
        duplication and correction services for some of your personal
        information, due to technical infeasibility or security issue.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>删除权 Right of deletion </span>
        <br />
        如果（1）当我们违反法律法规或与您的约定处理您的个人信息；（2）当处理目的已实现、无法实现或者为实现处理目的不再必要的情形下；（3）当您个人信息的保存期限已届满；（4）当您撤回您的同意的情况下；或（5）在法律法规规定的其他情形下，您有权要求我们删除您的个人信息。{" "}
        <br />
        In case (1) we breach any law or regulation or the agreement with you to
        process your personal information; (2) the purpose of handling your
        personal information has been achieved, it is impossible to achieve such
        purpose, or it is no longer necessary to achieve such purpose; (3) the
        storage period of your personal information has expired; (4) you
        withdraw your consent; or (5) there are other circumstances stipulated
        by laws and regulations, you shall be entitled to request us to delete
        the information.
        <br />
        <br />
        如果您要求我们删除您的个人信息，
        而任何法律法规规定的保存期限尚未届满或者在技术上难以实现个人信息的删除，我们将停止除存储和采取必要的安全保护措施之外的处理。您可根据“任何疑问”部分所列的方式与我们联络，要求进行个人信息的删除。请注意您的该项请求不会影响我们基于除个人同意以外法律规定的其他依据继续存储、处理您的个人信息的权利。{" "}
        <br />
        If you request us to delete your personal information, whereas laws and
        regulations provide certain storage period, or such deletion is
        technically infeasible, we would suspend any processing on your personal
        information except for the storage or necessary security measures. You
        can contact us by the means listed in the section “Any doubt” and
        require the deletion of your personal information. Please note, in
        accordance with other legitimate foundations, we may still store or
        process your personal information regardless of your request.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>撤销权 Right of revocation </span>
        <br />
        在合理情况下，您可以联系英格卡中国信息安全与隐私保护团队，以改变您授权我们继续收集个人信息的范围或撤回您的授权。如果在此过程中，您需要任何帮助，可根据“任何疑问”部分所列的方式与我们联络。请注意您的该项请求不会影响我们基于除个人同意外法律规定的其他依据继续存储、处理您的个人信息的权利。{" "}
        <br />
        Under reasonable circumstances, you are entitled to adjust the
        authorization scope of personal information collected by us or revoke
        your authorization. INGKA China Information Security and Data Privacy
        team will respond to such request. If you need any assistance, please
        contact us by the means listed in the section “Any doubt”. Please note,
        in accordance with other legitimate foundations, we may still store or
        process your personal information regardless of your request.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>
          拒绝自动化决策权 Right of refusal to automated decision{" "}
        </span>
        <br />
        在我们基于您的同意处理您的个人信息且合理情况下，您有权要求我们对通过自动化决策方式做出对您个人权益有重大影响的决定的情况予以说明，以及有权拒绝我们仅通过自动化决策的方式做出对您个人权益有重大影响的决定。您可根据“任何疑问”部分所列的联系方式与我们联络。{" "}
        <br />
        Under reasonable circumstances where we process your personal
        information based on your consent, you are entitled to request us to
        elaborate on the circumstance where we make decision about your
        important individual interests through automated decision system, and
        you are entitled to decline the decision which we made solely through
        automated decision system and which has great impact on your individual
        interests. You may contact us by the means listed in the section “Any
        doubt”.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>
          要求我们解释您的个人信息处理规则 Request us to explain the processing
          rules for personal information{" "}
        </span>
        <br />
        您有权要求我们对处理您的个人信息的规则进行解释说明，您可以联系英格卡中国信息安全与隐私保护团队，或者根据“任何疑问”部分所列的方式与我们联络。{" "}
        <br />
        You are entitled to request us to explain the processing rules for
        personal information. You may contact INGKA China Information Security
        and Data Privacy team or contact us by the means listed in the section
        “Any doubt”.
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>
          响应您的上述请求 Response to your above requests{" "}
        </span>
        <br />
        为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份,
        然后再处理您的请求。我们将在核实您身份后的 15 个工作日内做出答复。{" "}
        <br />
        In order to guarantee security, you may be required to provide a written
        request or to prove your identity in other ways. We may ask you to
        verify your identify firstly before processing your request. We will
        respond in 15 business days after the verification.
        <br />
        <br />
        对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。请注意您行使上述请求不会影响我们在法律规定的其他依据上（除您的同意外）处理您的个人数据的权利。{" "}
        <br />
        In principle, we don’t charge fees over your reasonable requests. But
        for requests repeated for many times and beyond the reasonable limit, we
        will charge certain fees for cost as appropriate. And we may reject the
        requests that are repeated for no reason, require excessive technical
        means (For example, it needs to develop a new system or change the
        existing practice fundamentally), present risks to the legal interests
        of others or are very unrealistic. Please note, in accordance with other
        legitimate foundations (thus, except your consent), we may still process
        your personal information regardless of your request.
        <br />
        <br />
      </div>
      <div>
        <h1>
          我们如何处理未成年人的个人信息？How do we handle personal information
          of the minors?
        </h1>
        英格卡中国不会主动向未满 14
        周岁的未成年人寻求或收集个人信息。然而，我们仅会在为员工未成年人子女提供与员工福利相关服务时，才可能会根据目的明确、最少够用的原则，在家长或监护人（即员工）的明确同意下，直接从家长或监护人处收集未满
        14
        周岁未成年人的个人信息。家长、监护人在向我们提供未成年人信息前，请仔细阅读我们的隐私政策，以更好的呵护您的孩子。
        <br />
        INGKA China will not actively seek or collect personal information of
        minors under the age of 14. However, when we provide the services
        relating co-worker welfares specially designed for co-worker’s children,
        we may collect the personal information of minors under 14 from their
        parents or guardians (i.e. Co-worker), with explicit authorization from
        the parents or guardians, according to the principles of clear purpose
        and least but sufficient information. The parents or guardians should
        carefully read our Privacy Policy before providing us with the
        information of the minors, so as to take better care of the children.
        <br />
        如果我们发现自己在未事先获得可证实的父母同意的情况下收集了未成年人的个人信息，我们会设法尽快删除相关数据。
        <br />
        If we found the personal information of minors were collected without
        the prior verifiable permission from their parents, we will manage to
        delete the related data as soon as possible.
        <br />
        <br />
      </div>
      <div>
        <h1>
          您的个人信息如何在全球范围转移？How is your personal information
          transferred globally?
        </h1>
        原则上，我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内。由于英格卡集团是一家跨国公司，基于集团运营和管理的需要，我们通过遍布全球的资源和服务器提供产品或服务，这意味着您的个人信息可能会被转移到您被雇佣所在国家/地区的境外管辖区，或者受到来自这些管辖区的访问。具体情形请参见附件二。{" "}
        <br />
        In principle, the personal information collected and generated in the
        territory of the People’s Republic of China will be stored in this
        territory. Since INGKA Group is a multinational corporation, we provide
        products and services through the resources and servers spreading all
        over the world based on the needs for group operation and management.
        That means, your personal information may be transferred to an overseas
        territory beyond the country/region where you are employed, or may be
        accessed by people from such a territory. Please refer to the Appendix
        II for more details.
        <br />
        <br />
        此类管辖区可能设有不同的数据保护法，甚至未设立相关法律。在此类情况下，我们会确保您的个人信息得到在中华人民共和国境内足够同等的保护。我们将始终在管理、技术处理等过程确保信息安全，防止您的个人信息受到各种可预见事故的危害。{" "}
        <br />
        Such jurisdiction may have different data protection laws, or even have
        no related law available. Under such circumstances, we will ensure to
        protect your personal information to an extent equivalent to that in the
        People’s Republic of China. We will always guarantee information
        security during the management, technical processing and other
        processes, to prevent your personal information from being harmed by
        various predictable accidents.
        <br />
        <br />
      </div>
      <div>
        <h1>本政策如何更新 How do we update this policy？</h1>
        我们可能会不定时修改本政策，以便及时反映法律法规的变化以及我们的员工手册的调整。我们将按照法律、法规规定的流程及要求进行对于涉及您的切身利益或重大事项的修改，并公示或告知您相关修改。
        <br />
        We may modify this policy occasionally, so as to timely reflect the
        changes in laws and regulations and the adjustment of our Co-worker
        handbook. As for the modification that is significant or concerns your
        vital interests, we will comply with the procedures and requirements as
        stipulated in laws and regulations, and will make public announcement or
        notify you of such modification.
        <br />
        <br />
        我们将本政策的旧版本存档，供您查阅。 <br />
        We archived the old versions of this policy for you reference.
        <br />
        <br />
      </div>
      <div>
        <h1>任何疑问 any doubt</h1>
        如果您对本政策或我们所拥有的与您相关的个人信息有任何疑问，请随时联系我们。一般情况下，我们将在核实您身份后的
        15 个工作日内回复。 <br />
        If you have any doubt about this policy or your personal information in
        our possession, please feel free to contact us. Generally, we will reply
        in 15 business days after verifying your identification.
        <br />
        <br />
        电子邮件：
        <EmailLink email="isdp.general.cn@ingka.ikea.com" /> <br />
        Email: <EmailLink email="isdp.general.cn@ingka.ikea.com" />
        <br />
        <br />
        本隐私政策以中、英文两种文字书就，两种文本具有同等效力。如中英文文本有不一致之处，应以中文文本为准。
        <br />
        This Privacy Policy is written both in Chinese and English. Both
        versions shall be equally authentic. Where there is a discrepancy
        between the Chinese and English versions, the Chinese version shall
        prevail.
        <br />
      </div>

      <div>
        <span style={{ fontWeight: "bold" }}>
          附件一：涉及个人信息分享相关方列表 (不定时更新){" "}
        </span>

        <style>{"table{border:1px solid black;}"}</style>
        <style>{"tr{border:1px solid black;}"}</style>
        <style>{"td{border:1px solid black;}"}</style>

        <table>
          <tr>
            <td>第三方实体名称 </td>
            <td>联系方式 </td>
            <td>分享的个人信息种类 </td>
            <td>处理目的 </td>
          </tr>
          <tr>
            <td>上海圆迈贸易有限公司 </td>
            <td>中国上海市淞沪路 207 号明基广场 A 幢 7 楼 </td>
            <td>姓名，邮箱地址，电话号码 </td>
            <td>为员工注册京东员工福利平台的用户账号 </td>
          </tr>
          <tr>
            <td>平安养老保险股份有限公司上海分公司</td>
            <td>中国上海市静安区常熟路 8 号 7 楼 </td>
            <td>
              姓名，出生日期，城市，电话号码，身份证件号，性别，开户银行及账号，医保信息，宜家员工号{" "}
            </td>
            <td>用于为员工投保和理赔 </td>
          </tr>
          <tr>
            <td>平安养老保险股份有限公司上海分公司 </td>
            <td>中国上海市静安区常熟路 8 号 7 楼 </td>
            <td>
              姓名，性别，出生日期，城市，电话号码，身份证件号，开户银行及账号，医保信息，子女情况，个人收入或资产状况的相关数据，配偶姓名及证件号，父母姓名及证件号{" "}
            </td>
            <td>用于养老金缴费、养老金支付和养老金账户转移的工作 </td>
          </tr>
          <tr>
            <td>太平养老保险股份有限公司 </td>
            <td>
              中华人民共和国上海市浦东新区银城中路 488 号太平金融大厦 25-26 楼{" "}
            </td>
            <td>
              姓名，出生日期，电子邮件，电话号码，个人收入或资产状况的相关数据{" "}
            </td>
            <td>用于为员工投保和理赔 </td>
          </tr>
          <tr>
            <td>上海外服（集团）有限公司 </td>
            <td>中国（上海）自由贸易试验区张杨路 655 号 707 室</td>
            <td>姓名，出生日期，地址，城市，邮政编码，电子邮箱，电话号码 </td>
            <td>用于员工社保公积金的申报缴纳等工作 </td>
          </tr>
          <tr>
            <td>上海携程宏睿国际旅行社有限公司 </td>
            <td>上海市杨浦区铁岭路32 号 1643 室 </td>
            <td>
              姓名、地址、城市、电子邮箱、电话号码、唯一识别码（员工编号）、工作数据（现聘用单位、现任职务、曾任职务等）、个人识别码、支付卡数据、所属法人、所属业务部门、所属成本中心、员工卡号、授权人、账户激活/
              未激活、身份证/护照信息、航空公司/酒店和汽车租凭会员卡{" "}
            </td>
            <td>用于处理商务旅行相关事宜 </td>
          </tr>
        </table>
        <br />
        <br />
        <span style={{ fontWeight: "bold" }}>附件二：信息出境相关方列表 </span>
        <table>
          <tr>
            <td>中国境外实体名称 </td>
            <td>联系方式 </td>
            <td>传输的个人信息种类 </td>
            <td>处理目的 </td>
          </tr>
          <tr>
            <td>IKEA IT Aktiebolag </td>
            <td>P.O. Box 700, SE-343 81, Älmhult, Sweden</td>
            <td>姓名，邮箱地址，员工号，照片，工作数据等 </td>
            <td>用于员工使用公司内部系统、员工参加公司活动的信息共享等 </td>
          </tr>
        </table>
        <br />
        <br />
      </div>

      <div>
        <h1 style={{ textAlign: "center" }}>确认书 Confirmation letter</h1>
        <br />
        <br />
        本人已收到《英格卡中国员工隐私政策》。 I have received the INGKA China
        Co-worker Privacy Policy.
        <br />
        □ 本人确认并同意《英格卡中国员工隐私政策》中规定的内容。
        <br />
        <br />
        I confirm and agree to the content in the INGKA China Co-worker Privacy
        Policy.
        <br />
        <br />
        □本人确认并同意根据《英格卡中国员工隐私政策》向境外接收方传输我的个人信息
        <br />
        I confirm and agree to transfer my personal information to the overseas
        parties.
        <br />
        <br />
        □ 本人确认并同意根据《英格卡中国员工隐私政策》处理我的敏感个人信息。
        <br />
        <br />
        I confirm and agree to the processing of my sensitive personal
        information.
        <br />
        员工签字（Signature）：
        <br />
        <br />
        员工身份证号（ID card number）：
        <br />
        <br />
        签署日期（Date of signature）：
        <br />
        <br />
      </div>
    </>
  ),
};
