import React from "react";
import styled from "styled-components";
import { Container, OverflowBackground } from "./CommonComponents";
import { RadioButton } from "@coworker/components";
import {
  TYPES_OF_ARTICLES,
  TYPES_OF_ARTICLES_IN_CHINA,
} from "../TestBuy/constants";
import { Trans } from "@coworker/locales";
import { TypeOfArticle } from "@coworker/types";
import { isChineseEnvironment } from "@coworker/reusable";

const StyledOverflowBackground = styled(OverflowBackground)`
  z-index: 1;
`;

const RoundedContainer = styled(Container)`
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  padding-bottom: 24px;
`;

const ArticleType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  font-size: 16px;
`;

const ElementContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  width: 100%;
  padding: 0 48px 0 24px;
`;

const ArticleSelectionPopupHeader = styled.div`
  margin: 24px;
  font-size: 16px;
  font-weight: bold;
`;

type ArticleSelectionProps = {
  typeOfArticles: TypeOfArticle;
  onClose: () => void;
  onConfirm: (articleType: object) => void;
};

export function ArticleSelection({
  typeOfArticles,
  onClose,
  onConfirm,
}: ArticleSelectionProps) {
  const [typeOfArticleSelected, setTypeOfArticleSelected] =
    React.useState(typeOfArticles);

  const isChina = isChineseEnvironment();

  return (
    <>
      <StyledOverflowBackground onClick={onClose} />
      <RoundedContainer data-testid="number-of-articles-container">
        <ArticleSelectionPopupHeader>
          <Trans>typeOfArticlesString</Trans>
        </ArticleSelectionPopupHeader>
        {(isChina ? TYPES_OF_ARTICLES_IN_CHINA : TYPES_OF_ARTICLES).map(
          (typeOfArticle: TypeOfArticle, i: number) => (
            <ArticleType
              onClick={() => {
                setTypeOfArticleSelected(typeOfArticle);
                onConfirm(typeOfArticle);
                onClose();
              }}
              key={i}
            >
              <ElementContentWrapper>
                <Trans>{typeOfArticle.transKey}</Trans>
                <RadioButton
                  readOnly
                  checked={typeOfArticleSelected === typeOfArticle}
                />
              </ElementContentWrapper>
            </ArticleType>
          )
        )}
      </RoundedContainer>
    </>
  );
}
