import { TypeOfArticle, TypeOfDivision } from "@coworker/types";

// Array representing the options for amounts of articles to test buy
export const AMOUNTS: number[] = [5, 10, 15, 20];
export const DEFAULT_AMOUNT = AMOUNTS[1] ?? 10;
export const DEFAULT_DIVISION_AMOUNT = AMOUNTS[0];

// List of options for type of articles list to test buy
export const TYPES_OF_ARTICLES: TypeOfArticle[] = [
  { type: "ACESANDKING", transKey: "acesAndKingString" },
  { type: "RANDOM", transKey: "randomString" },
  { type: "CUSTOM", transKey: "customPeriodString" },
];
export const TYPES_OF_ARTICLES_IN_CHINA: TypeOfArticle[] = [
  { type: "ACESANDKING", transKey: "acesAndKingString" },
  { type: "CUSTOM", transKey: "customPeriodString" },
];
export const allDivisions: TypeOfDivision[] = [
  {
    divisionShortName: "SR",
    translationString: "divsionsShowRoomString",
  },
  {
    divisionShortName: "MH",
    translationString: "divisionsMarketHall",
  },
  {
    divisionShortName: "WH",
    translationString: "divisionsWareHouse",
  },
  {
    divisionShortName: "OT",
    translationString: "divisionOthers",
  },
  {
    divisionShortName: "SF",
    translationString: "divisionSelfServe",
  },
  {
    divisionShortName: "whole-store",
    translationString: "wholeStoreString",
  },
];
export const DIVISION_HASH = {
  SHOWROOM: allDivisions[0],
  MARKETHALL: allDivisions[1],
  WAREHOUSE: allDivisions[2],
  OTHERS: allDivisions[3],
  SELFSERVE: allDivisions[4],
  WHOLESTORE: allDivisions[5],
};

// TODO: Simplify
export const divisionTabData = [
  {
    key: "showroom",
    title: "Showroom",
    hash: DIVISION_HASH.SHOWROOM,
  },
  {
    key: "markethall",
    title: "Markethall",
    hash: DIVISION_HASH.MARKETHALL,
  },
  {
    key: "warehouse",
    title: "Warehouse",
    hash: DIVISION_HASH.WAREHOUSE,
  },
  {
    key: "others",
    title: "Others",
    hash: DIVISION_HASH.OTHERS,
  },
  {
    key: "selfserve",
    title: "Self serve",
    hash: DIVISION_HASH.SELFSERVE,
  },
  {
    key: "wholestore",
    title: "Whole store",
    hash: DIVISION_HASH.WHOLESTORE,
  },
];

export const ARTICLE_HASH = {
  ACESANDKING: TYPES_OF_ARTICLES[0],
  RANDOM: TYPES_OF_ARTICLES[1],
  CUSTOM: TYPES_OF_ARTICLES[2],
};

export const COMPLETED_STATUS: string = "COMPLETED";
