import React from "react";
import {
  CenterContainer,
  SizedText,
  PaddedContainer,
  SimpleContainer,
} from "../styles/styles";
import { Trans } from "@coworker/locales";
import Button from "@ingka/button";

interface MessageAndButtonProps {
  keyForMessageText: string;
  keyForButtonText: string;
  buttonAction: () => Promise<void>;
}

export function MessageAndButton({
  keyForMessageText,
  keyForButtonText,
  buttonAction,
}: MessageAndButtonProps) {
  return (
    <PaddedContainer $px={"0px"} $py={"48px"}>
      <CenterContainer $centerText={true}>
        <SimpleContainer $direction={"column"}>
          <SizedText $fontSize={20} $bold={true}>
            <Trans>{keyForMessageText}</Trans>
          </SizedText>
          <Button type="primary" onClick={async () => await buttonAction()}>
            <Trans>{keyForButtonText}</Trans>
          </Button>
        </SimpleContainer>
      </CenterContainer>
    </PaddedContainer>
  );
}
