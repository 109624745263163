import React from "react";
import { useOnline } from "../../core/hooks/useOnline";

type Options = {
  blockRequest: boolean;
  cacheTimeout?: number;
};

interface Response<T> {
  data?: T | undefined;
  loading: boolean;
  error?: string;
  elapsed: number;
  dataIsFromAPI: boolean;
  isBlocked: boolean;
}

const NODE_ENV: string = process.env.NODE_ENV;
const cache: Record<string, { data: unknown; updated: number }> = {};

function setInCache<T>(cacheKey: string, data: T) {
  cache[cacheKey] = {
    data,
    updated: Date.now(),
  };
}

/**
 * @param {string} cacheKey
 * @param {integer} cacheTimeout Max staleness of cached value (milliseconds)
 */
function fromCache<T>(cacheKey: string, cacheTimeout: number) {
  if (!cacheTimeout || "ci" === NODE_ENV) return false;
  const entry = cache[cacheKey];
  if (!entry || Date.now() - entry.updated > cacheTimeout) return false;
  return entry.data as T;
}

export function useAPIRequest<T>(
  requestPromise: () => Promise<T>,
  cacheKey: string,
  { cacheTimeout = 0, blockRequest }: Options = { blockRequest: false },
  processResponseFunction = (data: T) => data
) {
  const start = Date.now();
  const { isOnline } = useOnline();
  const [response, setResponse] = React.useState<Response<T>>({
    // TODO: loading should probably be initialized to !blockRequest to avoid flip-flopping between loading and not loading when load will be performed.
    loading: false, // Careful: search UX logic relies on this not flipping between false -> true -> false. If we want to have this only be true or false then we need to bring along an additional state variable to show if we've actually started searching. Even bringing along a isBlocked is not enough to cover the UX need.
    error: "",
    elapsed: 0,
    dataIsFromAPI: false,
    isBlocked: blockRequest,
  });

  React.useEffect(() => {
    let mounted = true;
    function respond(
      error: string,
      data: T | undefined,
      loading: boolean,
      dataIsFromAPI = false
    ) {
      if (!mounted) return;
      setResponse({
        data,
        loading,
        error,
        isBlocked: blockRequest,
        elapsed: Date.now() - start,
        dataIsFromAPI,
      });
    }

    if (!blockRequest) {
      const entry = fromCache<T>(cacheKey, cacheTimeout);
      if (entry) {
        respond("", entry, false);
      } else {
        respond("", undefined, true);
        if (isOnline) {
          requestPromise()
            .then((response) => {
              const processed = processResponseFunction(response);
              cacheTimeout && setInCache(cacheKey, processed); // Bugfix on this line
              respond("", processed, false, true);
            })
            .catch((err) => {
              respond(err.message || err, undefined, false);
            });
        }
      }
    }

    return () => {
      mounted = false;
    };
    // Ignored: requestPromise, processResponseFunction, start
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockRequest, cacheKey, cacheTimeout, isOnline]);
  return response;
}
