import React from "react";
import styled from "styled-components";
import { Button } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  position: fixed;
  z-index: var(--z-popup);
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;

const Body = styled.div`
  margin: 34px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
`;

const TextBlock = styled.div`
  color: var(--grey900);
  margin: 30% 0 auto 0;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: -0.8px;
`;

const Text = styled.div`
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.5;
`;

const CtaButton = styled(Button)`
  width: 100%;
  margin: 0;
`;

const Error = ({ title, text = [], button = {}, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Container>
      <Body>
        <TextBlock>
          <Title>{title}</Title>
          <Text>{text}</Text>
        </TextBlock>
        <CtaButton
          primary={true}
          dark={true}
          text={t("returnToHome")}
          onClick={() => {
            onClose();
            navigate("/");
          }}
          {...button}
        />
      </Body>
    </Container>
  );
};

export default Error;
