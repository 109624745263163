import React from "react";
import Text from "@ingka/text";
import styled from "styled-components";
import "@ingka/text/dist/style.css";
import Button from "@ingka/button";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import EllipsesIcon from "@ingka/ssr-icon/paths/ellipses-horizontal";
import { TaskTabsTypes } from "../../../shared/constants/taskTabsTypes";
import {
  myTasksPath,
  openTasksPath,
} from "../../../tasks/constants/taskRoutePaths";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";
import trackerHelper from "../../../../../coworker-app/src/helpers/tracker";
import { useLocation } from "react-router-dom";
import { useTranslation } from "@coworker/locales";

const TasksTodosTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  height: 24px;
`;

interface TasksTodosTitleProps {
  title: string;
  tasksCount: number;
  tabType: TaskTabsTypes;
}

export const TasksTodosTitle: React.FC<TasksTodosTitleProps> = ({
  title,
  tasksCount,
  tabType,
}) => {
  const { push } = useWorkspacesAction();
  const location = useLocation();
  const { t } = useTranslation();

  const handleViewAllClick = () => {
    const path = tabType === TaskTabsTypes.OPEN ? openTasksPath : myTasksPath;

    const section = tabType === TaskTabsTypes.OPEN ? "open_tasks" : "my_tasks";
    const isFromHome = location.pathname === "/";

    push(path);
    if (path === openTasksPath) {
      trackerHelper.trackOpenTasksPreview(section, isFromHome);
    } else if (path === myTasksPath) {
      trackerHelper.trackMyOpenTasksPreview(section, isFromHome);
    }
  };
  return (
    <TasksTodosTitleContainer>
      <Text tagName="h2" headingSize="s">
        {title} ({tasksCount})
      </Text>
      {tasksCount > 0 && (
        <Button
          text={t("viewAllString")}
          small={true}
          type="tertiary"
          onClick={() => handleViewAllClick()}
          iconPosition="trailing"
          ssrIcon={EllipsesIcon}
        ></Button>
      )}
    </TasksTodosTitleContainer>
  );
};
