import React from "react";
import InputField from "@ingka/input-field";
import FormField from "@ingka/form-field";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/hyperlink/dist/style.css";
import "@ingka/forms/dist/style.css";

import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import { isRDTDevice } from "@coworker/reusable";

export default function SkapaInputField({ formFieldProps, inputFieldProps }) {
  const isRDT = isRDTDevice();
  return (
    <FormField {...formFieldProps}>
      <InputField
        {...inputFieldProps}
        style={{ width: "90%", paddingLeft: isRDT && "0.5rem" }}
      />
    </FormField>
  );
}
