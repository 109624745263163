import Choice, { ChoiceItem } from "@ingka/choice";
import React from "react";
import styled from "styled-components";
import { Reasons } from "../../components/RoomSettings/constants";
import { useTranslation } from "@coworker/locales";
import { FlexContainer } from "../RoomSettings/styles/styles";

const RoomSettingsPopupContainer = styled.div`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin-top: 80px;
  overflow: hidden;
  overflow-y: auto;
`;
const RoomSettingsPopupTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  color: var(--grey900);
  padding-left: 24px;
  margin-bottom: 24px;
`;

interface TypeOfRoomSettingTaskProps {
  onSubmit: (value: string) => void;
}

export const TypeOfRoomSettingTask = ({
  onSubmit,
}: TypeOfRoomSettingTaskProps) => {
  const { t } = useTranslation();
  const getTitleOfTask = (reasonTitle: string) => {
    if (reasonTitle === "daily_maintenance") return t("dailyMaintenanceString");
    if (reasonTitle === "replace_product") return t("changeProductString");
    if (reasonTitle === "remove_product") return t("removeProductString");
    return "";
  };

  return (
    <RoomSettingsPopupContainer>
      <RoomSettingsPopupTitle>
        {t("selectTypeOfTaskString")}
      </RoomSettingsPopupTitle>
      <Choice preselectedIndex={1} subtle>
        <FlexContainer $direction="column">
          {Object.keys(Reasons)
            .filter((reason) => reason !== "SALES_STOP")
            .map((reason) => {
              const reasonValue = Reasons[reason as keyof typeof Reasons];
              return (
                <ChoiceItem
                  key={reason}
                  id={reasonValue}
                  title={getTitleOfTask(reasonValue)}
                  onClick={() => onSubmit(reasonValue)}
                  data-testid={reasonValue}
                />
              );
            })}
        </FlexContainer>
      </Choice>
    </RoomSettingsPopupContainer>
  );
};
