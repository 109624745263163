import React from "react";
import styled, { css } from "styled-components";
import { Link } from "./Link";
import { ReactComponent as ArrowForward } from "../assets/svg/icon-arrow-small-forward-cropped.svg";

const Container = styled.div`
  width: 100hw;
  height: 81px;
  ${(props) =>
    props.separatorBottomLine && "border-bottom: 1px solid var(--grey200);"}
  padding: 0 28px;
  background: white;
  color: var(--grey900);
  ${({ yellow }) =>
    yellow
      ? css`
          background-color: var(--yellow);
          color: var(--black);
        `
      : ""};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Horizontal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 30px 0;
  flex-direction: row;
`;

const Text = styled.div`
  line-height: 21px;
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const StyledLink = styled(Link)`
  width: 100%;
  height: 21px;
  font-size: 14px;
  font-weight: bold;
  line-height: 81px;
  text-decoration: none;
  letter-spacing: -0.26px;
  color: var(--grey500);
  ${({ yellow }) => (yellow ? "color: var(--grey900);" : "")}
  cursor: pointer;
`;
const Arrow = styled(ArrowForward)`
  margin: auto 0;
  color: var(--grey900);
`;

const ActivityLink = ({
  linkText,
  badge,
  to,
  onClick,
  separatorBottomLine,
  yellow,
  testId,
}) => {
  return (
    <StyledLink to={to} onClick={onClick}>
      <Container
        separatorBottomLine={separatorBottomLine}
        yellow={yellow}
        data-testid={testId}
      >
        <Horizontal>
          <Text>{linkText}</Text>
          <Spacer />
          {badge}
          <Arrow />
        </Horizontal>
      </Container>
    </StyledLink>
  );
};

export default ActivityLink;
