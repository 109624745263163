import React from "react";
import { TextStyle, fontSizeMap } from "../styles/styles";

interface TextProps {
  text: string;
  bold?: boolean;
  italic?: boolean;
  grey?: boolean;
  size?: keyof typeof fontSizeMap;
}

export const Text = ({ text, bold, italic, grey, size }: TextProps) => {
  const color = !!grey ? "grey718" : "black";
  return (
    <TextStyle
      $bold={!!bold}
      $italic={!!italic}
      $color={color}
      $size={size || "medium"}
    >
      {text}
    </TextStyle>
  );
};
