import React from "react";
import styled from "styled-components";

const GraphTitle = styled.div`
  color: var(--grey718);
  font-size: 12px;
  height: 16px;
  line-height: 16px;
  padding-top: 20px;
  margin: 0 auto;
`;
const Total = styled.div.attrs(({ $size }) => ({
  style: {
    fontSize: $size + "px",
    height: $size + "px",
    lineHeight: $size + "px",
  },
}))`
  text-align: center;
  font-weight: bold;
  color: var(--grey900);
  letter-spacing: -0.8px;
`;

const Avg = styled.div`
  margin: 0 auto;
  font-size: 12px;
  font-weight: bold;
  color: var(--grey718);
  text-align: center;
`;

const GroupHeaderWrapper = styled.div`
  margin: 0 auto 32px;
`;

function GraphHeader({ graphTitle, formattedTotalSum, totalLabelText }) {
  const len = String(formattedTotalSum).length;
  const totalSumFontSize = len > 6 ? (len > 8 ? 40 : 50) : 65;

  return (
    <GroupHeaderWrapper>
      <GraphTitle data-testid="insights_graph_title">{graphTitle}</GraphTitle>
      <Total $size={totalSumFontSize} data-testid="insights_graph_total">
        {formattedTotalSum}
      </Total>
      {totalLabelText && <Avg>{totalLabelText}</Avg>}
    </GroupHeaderWrapper>
  );
}

export default GraphHeader;
