export enum TeamStatus {
  "ACTIVE" = "ACTIVE",
  "INACTIVE" = "INACTIVE",
}

export type Team = {
  id: string;
  name: string;
  status: TeamStatus;
  is_product_quality: boolean;
};
