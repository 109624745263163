import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import useFormatter from "../../hooks/useFormatter";
import { Icon } from "@coworker/components";
import dayjs from "dayjs";
import { useInsightsEditStatus } from "../../features/insights/useInsightsEditStatus";
import { useUpdateTaskRefill } from "../../hooks/TaskActions/useUpdateTaskRefill";
import { LoaderIcon } from "@coworker/reusable/Loader";
import trackerHelper from "../../helpers/tracker";

const Container = styled.div`
  width: 100%;
`;
const Refills = styled.div`
  border: 1px solid var(--grey100);
  height: 91px;
  padding: 27px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 98%;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const RefillQuantity = styled(Column)`
  margin: 19px 0px;
  font-weight: bold;
  align-items: center;
  display: contents;
  margin-right: 10px;
`;

const RefillDateLocation = styled(Column)`
  font-size: 14px;
  font-weight: bold;
`;

const Location = styled(RefillDateLocation)`
  margin-left: 0px;
  font-weight: normal;
`;

const Pieces = styled.div`
  margin-right: 15px;
`;

const ButtonWrapper = styled.div`
  margin: 2px;
`;

const RefillSplitter = styled.div`
  padding: 18px 48px;
  background: var(--grey100);
`;

const Loader = styled(LoaderIcon)`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 45%;
  z-index: 1;
`;
export default function RefillsByLocation({ refills }) {
  if (!refills?.length) return null;

  return (
    <Container data-testid="refills-by-location">
      <RefillsContainer productRefills={refills} />
    </Container>
  );
}

function sortRefills(refills) {
  return [...refills].sort((a, b) => -a.dateTime.localeCompare(b.dateTime));
}

function RefillListItem({ refill, isEditable }) {
  const {
    refill_quantity,
    dateTime,
    location,
    taskId,
    productType,
    productId,
  } = refill;
  const { formatDateISO8601 } = useFormatter();
  const { setEditStatus } = useInsightsEditStatus();
  const { loading, call: updateTaskRefill } = useUpdateTaskRefill();

  return (
    <>
      {loading && <Loader />}
      <Refills>
        <Row>
          <RefillDateLocation>
            {formatDateISO8601(dateTime)}
            <Location>{location}</Location>
          </RefillDateLocation>
          <RefillQuantity>
            <Pieces data-testid={taskId}>
              {refill_quantity} <Trans>piecesString</Trans>
            </Pieces>
            <ButtonWrapper
              onClick={(event) => {
                trackerHelper.insights.corrections.startEditing({
                  taskId,
                  productId,
                });
                updateTaskRefill(
                  event,
                  taskId,
                  refill_quantity,
                  productType,
                  productId,
                  setEditStatus
                );
              }}
            >
              {isEditable && <Icon family="icons" name="pencil" size={18} />}
            </ButtonWrapper>
          </RefillQuantity>
        </Row>
      </Refills>
    </>
  );
}

function RefillsContainer({ productRefills }) {
  const { formatDateISO8601 } = useFormatter();
  const now = dayjs();

  function isTooOld(refill) {
    // Refills without taskId are not editable hence pushing to old refills.
    if (!refill.taskId) return true;

    const taskDate = refill.dateTime;
    const getInitialDate = dayjs(now).startOf("weeks").subtract(28, "days");
    return taskDate < formatDateISO8601(getInitialDate);
  }

  const oldRefills = [];
  const newRefills = [];
  const results = [];

  for (const refill of productRefills) {
    if (isTooOld(refill)) {
      oldRefills.push(refill);
    } else {
      newRefills.push(refill);
    }
  }

  results.push(
    sortRefills(newRefills).map((refill, index) => (
      <RefillListItem key={index} refill={refill} isEditable={true} />
    ))
  );

  if (oldRefills.length) {
    results.push(
      <RefillSplitter key="note">
        <Trans>refillsNotEditable4WeeksString</Trans>
      </RefillSplitter>
    );
    results.push(
      sortRefills(oldRefills).map((refill, index) => (
        <RefillListItem
          key={newRefills.length + index}
          refill={refill}
          isEditable={false}
        />
      ))
    );
  }

  return results;
}
