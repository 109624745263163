import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as CheckmarkIcon } from "../../../../assets/svg/checkmark.svg";
import { ReactComponent as WarningTriangle } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { Trans } from "@coworker/locales";
import { TaskState } from "@coworker/types/lib/tasks/constants";
import { isRDTDevice } from "@coworker/reusable";

const DetailsWrapper = styled.div<{ isRdt: boolean; isOk: boolean }>`
  margin-top: 5px;
  text-transform: uppercase;
  font-weight: bold;
  ${({ isRdt }) =>
    isRdt &&
    css`
      font-size: 10px;
    `}
  display: flex;
  justify-content: space-between;
  color: ${({ isOk }) => (isOk ? css`var(--green)` : css`var(--grey600)`)};
`;

const RightContainer = styled.div<{ finished?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ finished }) =>
    css`
      color: var(${finished ? "--grey600" : "--warning"});
    `};
`;

const SmallTriangle = styled(WarningTriangle)`
  height: 14px;
  fill: var(--warning);
`;

const SmallCheckmark = styled(CheckmarkIcon)`
  height: 14px;
`;

interface TaskDetailsProps {
  taskState: TaskState | undefined;
}

export function FollowupTaskDetails({ taskState }: TaskDetailsProps) {
  return (
    <DetailsWrapper isOk={!taskState} isRdt={isRDTDevice()}>
      {!taskState ? (
        <Trans>tbOkay</Trans>
      ) : (
        <>
          <Trans>tbNotOkay</Trans>
          {["COMPLETED", "CLOSED"].includes(taskState) ? (
            <RightContainer finished>
              <SmallCheckmark />
              <Trans>taskCompletedString</Trans>
            </RightContainer>
          ) : (
            <RightContainer>
              <SmallTriangle />
              <Trans>taskOngoingString</Trans>
            </RightContainer>
          )}
        </>
      )}
    </DetailsWrapper>
  );
}
