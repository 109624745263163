import React from "react";
import styled from "styled-components";
import { LocationRow } from "./LocationRow";
import { LocationGroupTitle } from "./LocationGroupTitle";
import { sumOver } from "../../../helpers/mapHelpers";
import trackerHelper from "../../../helpers/tracker";

const LocationsList = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

type LocationObject = {
  locationCode: string;
  locationSection: string;
  locationDepartment: string;
  locationQuantity: number;
  relatedOnThisLocation: number;
  locationCustom?: string;
};

interface LocationGroupProps {
  isCustomLocation: boolean;
  locationGroupTitle: string;
  locationsList: any;
  relatedAddons: any;
  selectedLocationCode: string;
  submitWithConfirmation: (locationObject: LocationObject) => void;
}

export function LocationGroup({
  isCustomLocation,
  locationGroupTitle,
  locationsList,
  relatedAddons,
  selectedLocationCode,
  submitWithConfirmation,
}: LocationGroupProps) {
  const getRelatedOnThisLocation = (code: string) => {
    return sumOver(
      relatedAddons[code],
      (task: any) => task?.requested_quantity
    );
  };
  return (
    <div>
      <LocationGroupTitle
        groupTitle={locationGroupTitle}
        showUnit={!isCustomLocation}
      />
      {!!locationsList.length && (
        <LocationsList>
          {locationsList.map((location: any) => {
            const relatedOnThisLocation = getRelatedOnThisLocation(
              location.code
            );
            return (
              <LocationRow
                key={location.code}
                selectedLocation={selectedLocationCode}
                onClick={() => {
                  submitWithConfirmation({
                    locationCode: location.code,
                    locationSection: location.section,
                    locationDepartment: location.department,
                    locationQuantity: location.quantity,
                    relatedOnThisLocation,
                  });
                  trackerHelper.trackCutomLocationItemClick(location.code);
                }}
                relatedOnThisLocation={relatedOnThisLocation}
                {...location}
                isCustomLocation={isCustomLocation}
              />
            );
          })}
        </LocationsList>
      )}
    </div>
  );
}
