const dropTypeKeys = {
  END_OF_SHIFT: "endOfShiftLabelString",
  ISSUE: "issueString",
  OTHER: "otherString",
};

const dropTypeOptions = {
  END_OF_SHIFT: "END_OF_SHIFT",
  ISSUE: "ISSUE",
  OTHER: "OTHER",
};

module.exports = {
  dropTypeKeys,
  dropTypeOptions,
};
