import React from "react";
import styled from "styled-components";
import Avatar from "../Avatar/Avatar";
import { Trans } from "@coworker/locales";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-large.svg";
import { displayRoleTranslationString } from "@coworker/common/helpers/user.helper";
import { useTelephoneLinker } from "../../hooks/useTelephoneLinker";
import { useStoreContactsEnabled } from "../../hooks/useStoreContacts";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--white);
`;
const UserHeader = styled.div`
  box-sizing: border-box;
  min-height: 260px;
  margin-top: 70px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
const Text = styled.div`
  min-height: 100px;
  text-align: center;
`;
const Name = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin: 0 29px;
`;
const Role = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: var(--grey700);
`;
const Body = styled.div`
  width: 100%;
  height: 100%;
  background: var(--white);
`;
const Row = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 90px;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--white);
  border-bottom: 1px solid var(--grey150);
`;
const Bold = styled.p`
  width: 80px;
  margin: 0 29px;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Grey = styled.p`
  padding-right: 29px;
  width: 100%;
  color: var(--grey700);
  text-align: end;
  overflow-x: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Close = styled(CloseIcon)`
  position: absolute;
  right: 18px;
  top: 38px;
  cursor: pointer;
`;

const User = ({ user, onClose, teamName }) => {
  const storeContactsEnabled = useStoreContactsEnabled();
  const makePhoneLink = useTelephoneLinker();
  return (
    <Container>
      <Close data-testid="close-popup" onClick={() => onClose()}></Close>
      <UserHeader>
        <Avatar fixaUid={user.fixaUid} big={true} />
        <Text title={user.role_id}>
          <Name>{user && user.given_name + " " + user.surname}</Name>
          <Role>
            <Trans>{displayRoleTranslationString(user.role_id)}</Trans>
          </Role>
        </Text>
      </UserHeader>
      <Body>
        <Row>
          <Bold>
            <Trans>teamString</Trans>
          </Bold>
          <Grey>{teamName}</Grey>
        </Row>
        {storeContactsEnabled && (
          <Row>
            <Bold>
              <Trans>ikeaStorePhoneString</Trans>
            </Bold>
            <Grey onClick={makePhoneLink(user.phone)}>{user.phone}</Grey>
          </Row>
        )}
        {user.shift && (
          <Row>
            <Bold>
              <Trans>shiftHoursString</Trans>
            </Bold>
            <Grey>{user.shift}</Grey>
          </Row>
        )}
        <Row>
          <Bold>
            <Trans>emailString</Trans>
          </Bold>
          <Grey>{user.email}</Grey>
        </Row>
      </Body>
    </Container>
  );
};

export default User;
