import React from "react";
import { CreateTaskCard } from "../Molecules/CreateTaskCard";
import { ListWrapper } from "../styles/styles";
import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { RoomsAndArticleCountResponse } from "../types/room";

interface CreateTasksProps {
  roomsArticleCount: RoomsAndArticleCountResponse[];
  tasks: RoomSettings[];
  currentArticleId?: string | undefined;
}

export function CreateTasks({
  roomsArticleCount,
  tasks,
  currentArticleId,
}: CreateTasksProps) {
  return (
    <ListWrapper>
      {roomsArticleCount.map((rac) => (
        <CreateTaskCard
          key={rac.article_id}
          task={tasks.find((task) => task.article_id === rac.article_id)}
          roomName={rac.name}
          pieces={rac.nbr_articles}
          isCurrentArticle={
            !currentArticleId ? false : rac.article_id === currentArticleId
          }
          articleId={rac.article_id}
          roomId={rac.room_id}
        />
      ))}
    </ListWrapper>
  );
}
