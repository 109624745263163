import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PartialGroup } from "../types/groups";
import { updateGroup } from "../services/groups.service";

export const useUpdateGroup = (groupId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartialGroup) => updateGroup(groupId, data),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["group", groupId],
      });
    },
  });
};
