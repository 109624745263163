import { formatWithCommasAndFilterEmpty } from "../tools";
import { Article, ArticleInfo } from "./article";

const unknown = "Unknown";

export function mapArticleInfoToArticle(articleInfo: ArticleInfo): Article {
  return {
    productArticleId: articleInfo.id,
    productArticleType: articleInfo.type || unknown,
    itemName: articleInfo.name || unknown,
    itemType:
      formatWithCommasAndFilterEmpty(articleInfo.descriptives) || unknown,
    itemColor: articleInfo.descriptives ? articleInfo.descriptives[1] : unknown,
    imageSmall: articleInfo.smallImage || articleInfo.image || unknown,
    hfb: articleInfo.hfb || unknown,
  } as Article;
}

export function mergeArticleInfoAndArticle(
  article: Article,
  articleInfo: ArticleInfo
): Article {
  return {
    ...article,
    ...mapArticleInfoToArticle(articleInfo),
  } as Article;
}
