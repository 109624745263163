import React from "react";
import useFeedbackQuestions from "../../hooks/useFeedbackQuestions";
import FeedbackForm from "./Feedback/FeedbackForm";
import { EmptyFeedback } from "./Feedback/EmptyFeedback";
import { Container } from "./Feedback/styles";
import { useTranslation } from "@coworker/locales";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

function Feedback() {
  const { t } = useTranslation();
  const [questions] = useFeedbackQuestions();
  const containerRef = React.useRef(null);
  const [currentQuestion] = questions;

  return (
    <FullScreenPopup
      noPadding
      appBarConfig={{
        title: t("shareFeedbackString"),
        actions: [],
      }}
    >
      <Container ref={containerRef}>
        {questions.length === 0 ? (
          <EmptyFeedback containerRef={containerRef} />
        ) : (
          <FeedbackForm
            containerRef={containerRef}
            question={currentQuestion}
          />
        )}
      </Container>
    </FullScreenPopup>
  );
}

export default Feedback;
