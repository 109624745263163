import header from "./header";
import simple_product_row from "./simple_product_row";
import issue from "./issue";
import location from "./location";
import description from "./description";
import { Spacer, WidgetDivider } from "@coworker/reusable/components/Widgets";
import { ProductAndStockPillsWidget } from "../../TaskForm/widgets/ProductAndStockPillsWidget";
import assignment from "./assignment";
import activity from "./activity";
import refilled from "./refilled";
import repeatable from "./repeatable";
import schedule from "./schedule";
import picking from "./picking";
import repeatable_child from "./repeatable_child";
import section from "./section";
import creator from "./creator";
import created from "./created";
import radioButtonsWithText from "./radio_buttons_with_text";
import mfaq_resolution from "./mfaq_resolution";
import mfaq_question from "./mfaq_question";
import mfaq_area from "./mfaq_area";
import images from "./images";
import checklist from "./checklist";
import taskValidation from "./taskValidation";
import priority from "./priority";
import describeTheProblem from "./describeTheProblem";
import refillType from "./refillType";
import roomSettingsTodo from "./roomSettingsTodo";
import ProofOfCompletion from "./proofOfCompletion";
import PreviousProduct from "./PreviousProduct";

export const PRODUCT_WITH_PILLS = "product_with_pills";
export const HEADER = "header";
export const PRODUCT = "product";
export const ISSUE = "issue";
export const LOCATION = "location";
export const DESCRIPTION = "description";
export const DIVIDER = "divider";
export const SPACER = "spacer";
export const ASSIGNMENT = "assignment";
export const ACTIVITY = "activity";
export const REFILLED = "refilled";
export const REPEATABLE = "repeatable";
export const SCHEDULE = "schedule";
export const PICKING = "picking";
export const REPEATABLE_CHILD = "repeatable_child";
export const SECTION = "section";
export const CREATOR = "creator";
export const CREATED = "created";
export const RADIOBUTTONSWITHTEXT = "radioButtonsWithText";
export const MFAQ_RESOLUTION = "mfaq_resolution";
export const MFAQ_QUESTION = "mfaq_question";
export const MFAQ_AREA = "mfaq_area";
export const IMAGES = "images";
export const CHECKLIST = "checklist";
export const TASKLISTVALIDATION = "taskValidation";
export const PRIORITY = "priority";
export const DESCRIBE_THE_PROBLEM = "describeTheProblem";
export const REFILL_TYPE = "refillType";
export const ROOM_SETTINGS_TODO = "roomSettingsTodo";
export const PROOF_OF_COMPLETION = "proofOfCompletion";
export const PREVIOUS_PRODUCT = "previousProduct";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [HEADER]: header,
  [PRODUCT]: simple_product_row,
  [ISSUE]: issue,
  [LOCATION]: location,
  [DESCRIPTION]: description,
  [DIVIDER]: WidgetDivider,
  [SPACER]: Spacer,
  [PRODUCT_WITH_PILLS]: ProductAndStockPillsWidget,
  [ASSIGNMENT]: assignment,
  [ACTIVITY]: activity,
  [REFILLED]: refilled,
  [REPEATABLE]: repeatable,
  [SCHEDULE]: schedule,
  [PICKING]: picking,
  [REPEATABLE_CHILD]: repeatable_child,
  [SECTION]: section,
  [CREATOR]: creator,
  [CREATED]: created,
  [RADIOBUTTONSWITHTEXT]: radioButtonsWithText,
  [MFAQ_RESOLUTION]: mfaq_resolution,
  [MFAQ_QUESTION]: mfaq_question,
  [MFAQ_AREA]: mfaq_area,
  [IMAGES]: images,
  [CHECKLIST]: checklist,
  [TASKLISTVALIDATION]: taskValidation,
  [PRIORITY]: priority,
  [DESCRIBE_THE_PROBLEM]: describeTheProblem,
  [REFILL_TYPE]: refillType,
  [ROOM_SETTINGS_TODO]: roomSettingsTodo,
  [PROOF_OF_COMPLETION]: ProofOfCompletion,
  [PREVIOUS_PRODUCT]: PreviousProduct,
};
