import React from "react";
// Skapa
import Button from "@ingka/button";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import plusIcon from "@ingka/ssr-icon/paths/plus";

interface SkapaButtonProps {
  onClick: () => void;
}

export const SkapaPlusButton = ({ onClick }: SkapaButtonProps) => {
  return (
    <Button type="primary" iconOnly ssrIcon={plusIcon} onClick={onClick} />
  );
};
