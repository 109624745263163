import { firebase } from "@coworker/common/config/firebase";
import { msalPublicClientApplication as msalInstance } from "../../core/auth/useAuth";
import { tokenScopes } from "@coworker/apprestructured/src/assets/auth/msalConfig";
import {
  AccountInfo,
  InteractionRequiredAuthError,
  BrowserAuthError,
} from "@azure/msal-browser";
import { reportMessageToSentry } from "../useConfigureSentry";

const getFirebaseToken = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  if (!token) throw new Error("No user found");
  return token;
};

const getMsalToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (!accounts[0]) {
    console.log("User is not signed in");
    reportMessageToSentry(`User is not signed in`, {
      level: "warning",
      extra: { accounts: accounts },
    });
    try {
      await msalInstance.acquireTokenRedirect({
        scopes: tokenScopes,
      });
      return null;
    } catch (error) {
      if (
        error instanceof BrowserAuthError &&
        error.errorCode === "interaction_in_progress"
      ) {
        console.log("Interaction is currently in progress. Please wait.");
        return null;
      } else {
        console.error("Error during token acquisition", error);
        reportMessageToSentry(`Error during acquireTokenRedirect`, {
          level: "warning",
          extra: { accounts: accounts },
        });
        throw error;
      }
    }
  } else {
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: tokenScopes,
        account: accounts[0] as AccountInfo,
      });
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        try {
          await msalInstance.acquireTokenRedirect({
            scopes: tokenScopes,
          });
          return null;
        } catch (loginError) {
          console.error("Login failed", loginError);
          reportMessageToSentry(`Error during acquireTokenRedirect`, {
            level: "warning",
            extra: { accounts: accounts },
          });
          return null;
        }
      } else {
        console.error("Token acquisition failed", error);
        return null;
      }
    }
  }
};

export async function getTokenAsync(isServiceCall: boolean) {
  if (isServiceCall) {
    return await getMsalToken();
  }
  return await getFirebaseToken();
}
