import React from "react";
import styled from "styled-components";
import { ReactComponent as IssueIcon } from "../assets/svg/issue_flag.svg";

const Container = styled.div`
  background: white;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
  height: 100%; /* Must not be 90px because the description text can take up several lines. */
`;
const ContentContainer = styled.div`
  min-height: 90px;
  display: flex;
  flex-grow: 1;
  padding-right: 29px;
  align-items: center;
  padding-left: 29px;
  padding-bottom: 29px;
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
`;
const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin-left: 20px;
  color: var(--grey700);
`;

const Title = styled.div`
  padding-top: 35px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin-left: 20px;
  padding-bottom: 2px;
  font-weight: 700;
  color: ${(props) =>
    props.deactivated ? "var(--grey600)" : "var(--grey900)"};
`;

const IconWrapper = styled.div`
  padding-top: 35px;
  & > svg {
    opacity: ${(props) => (props.deactivated ? ".5" : "1")};
  }
`;

const SelectIssue = ({ deactivated, issue, description, selectString }) => (
  <Container>
    <ContentContainer>
      <IconWrapper deactivated={deactivated}>
        <IssueIcon />
      </IconWrapper>
      <Stack>
        <Title deactivated={deactivated}>{issue ? issue : selectString}</Title>
        {description && <Description>{description}</Description>}
      </Stack>
    </ContentContainer>
  </Container>
);

export default SelectIssue;
