import { TaskListingTypes } from "../constants";
import { OngoingTaskLinksResponse } from "../types/taskLink";
import { useTaskLinksByArticle } from "./useTaskLinksByArticle";
import { useTaskLinks } from "./useTasksByTaskLinks";

export function useTasksByArticle(
  storeId: string | undefined,
  productArticleId: string | undefined
): OngoingTaskLinksResponse {
  const { data: taskLinks } = useTaskLinksByArticle(
    storeId || "",
    productArticleId || ""
  );
  const tasksResponse = useTaskLinks(taskLinks || [], TaskListingTypes.All);
  return tasksResponse;
}
