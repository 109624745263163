import { InsightsFilterType } from "../types/insightsFilterType";

export const INSIGHTS_FILTERS_DEFAULT: InsightsFilterType = {
  period: {
    weeksBack: 0,
    customDate: {
      startDate: "",
      endDate: "",
    },
  },
  home: {
    valueToShow: "turnover",
    sortBy: {
      type: "hfbNumber",
      value: "AWAY",
    },
  },
  graph: {
    type: "pieces",
    option: null,
  },
  deptFilters: {
    hfb: "ALL",
    salesShare: "HOME",
    orderBy: "turnover",
    orderByAsc: false,
  },
  productPreview: {
    most: "turnover",
    least: "turnover",
  },
};
