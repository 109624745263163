import { useQuery } from "@tanstack/react-query";
import { getArticleById } from "../services/articles.service";

export const useArticleById = (articleId: string | undefined) => {
  return useQuery({
    queryKey: ["article", articleId],
    queryFn: () => getArticleById(articleId || ""),
    enabled: !!articleId,
  });
};
