import { BackendServices, MfaqApiEndpoints } from "./EndpointsTypes";
import { postRequest, putRequest } from "./BaseRequests";
import {
  CreateQuestionParam,
  CreateQuestionTaskParam,
  UpdateQuestionTaskParam,
  CreateVoteParam,
  GetAllQuestionsParams,
  GetQuestionByIdParam,
  MergeQuestionsParam,
  SearchMfaqParams,
  UpdateQuestionQuestionsParam,
  GetVotesByQuestionByIdParam,
} from "./EndpointRequestSchemas";
import { Question, Vote } from "./QuestionResponse";
import { Task } from "@coworker/types/lib/tasks/base";

export async function createVote(
  questionId: string,
  storeId: string,
  voteLocation: string,
  voteValue: 1 | -1
) {
  const data = await postRequest<CreateVoteParam, string>(
    { questionId, storeId, voteLocation, voteValue },
    MfaqApiEndpoints.CREATE_VOTE,
    BackendServices.MFAQ
  );
  return data;
}

export async function getQuestionById(
  questionId: string,
  since: number,
  until: number
) {
  const data = await postRequest<GetQuestionByIdParam, Question>(
    { id: questionId, since, until },
    MfaqApiEndpoints.GET_BY_ID,
    BackendServices.MFAQ
  );
  return data;
}

export async function getVotesByQuestionById(
  questionId: string,
  area: string,
  since: number,
  until: number,
  isWholeStore: boolean
) {
  const data = await postRequest<GetVotesByQuestionByIdParam, Vote[]>(
    { questionId, area, since, until, isWholeStore },
    MfaqApiEndpoints.GET_VOTES_BY_QUESTION,
    BackendServices.MFAQ
  );
  return data;
}

export async function getAllQuestions(
  storeId: string,
  area: string,
  since: number,
  until: number,
  offset: number,
  onlyQuestionsWithTasks: boolean
) {
  const data = await postRequest<GetAllQuestionsParams, Question[]>(
    { storeId, area, since, until, offset, onlyQuestionsWithTasks },
    MfaqApiEndpoints.GET_TOP_QUESTIONS,
    BackendServices.MFAQ
  );
  return data;
}

export async function searchQuestions(
  searchTerm: string,
  storeId: string,
  area: string,
  offset = 0
) {
  const data = postRequest<SearchMfaqParams, Question[]>(
    { text: searchTerm, storeId, area, offset },
    MfaqApiEndpoints.SEARCH_QUESTIONS,
    BackendServices.MFAQ
  );
  return data;
}

export async function updateQuestion(questionId: string, questionText: string) {
  const data = putRequest<UpdateQuestionQuestionsParam, string>(
    { id: questionId, text: questionText },
    MfaqApiEndpoints.UPDATE_QUESTION_TEXT,
    BackendServices.MFAQ
  );
  return data;
}

export async function createQuestionTask(questionId: string, task: Task) {
  const data = putRequest<CreateQuestionTaskParam, string>(
    { id: questionId, task: task },
    MfaqApiEndpoints.CREATE_QUESTION_TASK,
    BackendServices.MFAQ
  );
  return data;
}

export async function updateQuestionTask(
  questionId: string,
  taskId: string,
  finishedSummary: string,
  state: string
) {
  const data = putRequest<UpdateQuestionTaskParam, string>(
    {
      id: questionId,
      taskId: taskId,
      finishedSummary: finishedSummary,
      state: state,
    },
    MfaqApiEndpoints.UPDATE_QUESTION_TASK,
    BackendServices.MFAQ
  );
  return data;
}

export async function mergeQuestions(
  questionId: string,
  secondQuestionId: string
) {
  const data = postRequest<MergeQuestionsParam, string>(
    { firstQuestionId: questionId, secondQuestionId },
    MfaqApiEndpoints.MERGE_QUESTIONS,
    BackendServices.MFAQ
  );
  return data;
}

export async function createQuestion(
  storeId: string,
  area: string,
  questionText: string
) {
  const data = await postRequest<CreateQuestionParam, string>(
    { storeId, area, text: questionText },
    MfaqApiEndpoints.CREATE_QUESTION,
    BackendServices.MFAQ
  );
  return data;
}
