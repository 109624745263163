import React from "react";
import { EXCEL_EXPORT } from "@coworker/app/src/hooks/API/useCallInternal";
import tracker from "@coworker/app/src/helpers/tracker";
import { postToTasksService } from "@coworker/app/src/hooks/API/tasks.service.helper";
import { FixaWindow, getEnvironmentId } from "@coworker/reusable";
import { functionsBaseUrl } from "@coworker/functions/src/triggers/cloud-services.helper";

interface GenerateReportParams {
  dataFetchParams: any;
  type: "insights";
  extra?: any;
}
export const useGenerateReport = () => {
  const FUNCTIONS = {
    insights: EXCEL_EXPORT,
  };

  const excelExportFunctionUrl = functionsBaseUrl(getEnvironmentId());
  const [isReportLoading, setIsReportLoading] = React.useState(false);
  const callTasksService = (window as FixaWindow)
    .finalInsightsFromTasksServiceFlag;
  const fetchReport = async ({
    dataFetchParams,
    type,
    extra,
  }: GenerateReportParams) => {
    console.log("Fecth report");
    try {
      setIsReportLoading(true);
      let downloadUrl = "";
      let filename = "";
      if (callTasksService && type === "insights") {
        await postToTasksService(
          "/v1/insights/excelExport_v0",
          { ...dataFetchParams, ...extra },
          "blob"
        ).then((result: any) => {
          downloadUrl = window.URL.createObjectURL(
            new Blob([result], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
        });

        //TODO Need to see if we need UUID in filename
        filename = "insights-exports.xlsx";
      } else {
        const bodyData = { ...dataFetchParams, ...extra };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Origin: window.location.origin,
          },
          body: JSON.stringify(bodyData),
        };
        const response = await fetch(
          `${excelExportFunctionUrl}/${FUNCTIONS["insights"]}`,
          requestOptions
        );
        if (!response.ok) {
          setIsReportLoading(false);
        }

        const excelBuffer = await response.blob();
        downloadUrl = window.URL.createObjectURL(
          new Blob([excelBuffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        filename = "insights-exports.xlsx";
      }
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", filename);
      // Append to html link element page
      document.body.appendChild(link);
      // Start download
      link.click();
      // Clean up and remove the link
      // @ts-ignore
      link?.parentNode.removeChild(link);
      setIsReportLoading(false);
      // @ts-ignore
      tracker[type].trackExport(dataFetchParams);
    } catch (error) {
      setIsReportLoading(false);
    }
  };
  return { isReportLoading: isReportLoading, fetchReport: fetchReport };
};
