import React from "react";
import { LargeSkeletonListItem } from "../Molecules/skeletons/LargeSkeletonListItem";
import { SmallSkeletonListItem } from "../Molecules/skeletons/SmallSkeletonListItem";

export const ArticleViewSkeleton = () => {
  return (
    <>
      <LargeSkeletonListItem />

      {[...Array(4)].map((_, index) => (
        <SmallSkeletonListItem key={index} />
      ))}
    </>
  );
};
