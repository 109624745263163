/**
 * Component that conditionally wraps its children with the wrapper component
 * @param {{
 *  condition: boolean,
 *  wrapper: (children: JSX.Element[]) => JSX.Element,
 *  children: JSX.Element[]
 * }} props
 */
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
