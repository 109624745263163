import React from "react";
import { useOwnImages } from "../../../core/hooks/useImages";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { StoredImage } from "../../StoredImage";
import {
  UpperRow,
  IconColumn,
  Camera,
  Title,
  ImageHolder,
  Container,
} from "../../AttachPhoto";
import { useInputPopup } from "../../InputPopup";

const ContainerMain = styled(Container)`
  padding: 32px 16px 32px 29px;
`;
const ImageContainer = styled.div`
  margin-left: 40px;
  margin-top: 24px;
`;

function Images({ taskPath }) {
  const { t } = useTranslation();
  const taskId = taskPath.replace(/\/|tasks/g, "");
  const ownImages = useOwnImages(taskId);
  const { getInput } = useInputPopup();

  const openImages = React.useCallback(() => {
    getInput("imageGallery", { data: ownImages });
  }, [ownImages, getInput]);

  return (
    !!ownImages?.length && (
      <ContainerMain active={ownImages}>
        <UpperRow>
          <IconColumn>
            <Camera />
          </IconColumn>
          <Title lblColor={ownImages} data-testid="imagesWidgetAttachedCount">
            {ownImages?.length &&
              t("photoString", {
                count: ownImages.length,
              })}
          </Title>
        </UpperRow>
        <ImageContainer>
          {ownImages?.map((image) => (
            <ImageHolder key={image.file_name} onClick={openImages}>
              <StoredImage image={image} size="small" />
            </ImageHolder>
          ))}
        </ImageContainer>
      </ContainerMain>
    )
  );
}
export default Images;
