import React from "react";
import { useTranslation } from "@coworker/locales";

function useOrdinal() {
  const { t } = useTranslation();
  return React.useCallback(
    (count) => {
      const keys = [null, "firstString", "secondString", "thirdString"];
      return count ? t(keys[count] || "nthString", { count }) : "";
    },
    [t]
  );
}

export default useOrdinal;
