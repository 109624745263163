import type { FixaWindow } from "@coworker/reusable";
import {
  legacy_createStore as createStore,
  compose,
  combineReducers,
} from "redux";
import {
  createFirestoreInstance,
  reduxFirestore,
  firestoreReducer,
} from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";
import firebase from "./firebase";

const reducers = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

// Add redux Firebase to compose
const createStoreWithFirebase = compose(
  reduxFirestore(firebase, {
    allowMultipleListeners: true,
  })
)(createStore);

const store = createStoreWithFirebase(
  reducers,
  (window as FixaWindow).__REDUX_DEVTOOLS_EXTENSION__?.()
);

// react-redux-firebase options
const firebaseConfig = {
  useFirestoreForProfile: true,
  enableClaims: false,
  userProfile: "users", // firebase root where user profiles are stored
  enableLogging: false, // enable/disable Firebase's database logging
};

export const firebaseProviderProps = {
  firebase,
  config: firebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export default store;
