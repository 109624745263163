import React from "react";
import { Trans } from "@coworker/locales";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import { issueTypeName } from "../../../constants/issueTypeName";

function Issue({ task }) {
  return (
    <DetailSection>
      <DetailSectionLeft>
        <SectionTitle>
          <Trans>issueString</Trans>
        </SectionTitle>
        <SectionText>
          {issueTypeName[task.issue_type]}
          {task.description ? " - " + task.description : ""}
        </SectionText>
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default Issue;
