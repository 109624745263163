import React from "react";
import { ActivityFilterType } from "../../../types/filterOptionsTypes";
import styles from "./activity-filters-panel.module.css";
import Accordion, { AccordionItem } from "@ingka/accordion";
import { Trans, useTranslation } from "@coworker/locales";
import { Task } from "@coworker/types/lib/tasks/base";
import {
  getDepartmentsLocations,
  getMarkethallLocations,
  getOtherLocations,
  getOtherMfaqLocations,
  getShowroomLocations,
} from "../helper";
import { FilterListView } from "./FilterListView";

interface LocationFilterProps {
  panelFilters: ActivityFilterType;
  allTasks: Task[];
  handleChange: (value: string) => void;
}

// prettier-ignore
export const LocationFilter = ({
  panelFilters,
  allTasks,
  handleChange,
}: LocationFilterProps) => {
  const { t } = useTranslation();
  const isMfaqTask = panelFilters.taskType === "mfaq";
  const hasTaskType = panelFilters.taskType;


  const showroomLocations = getShowroomLocations(allTasks, panelFilters.taskType);
  const markethallLocations = getMarkethallLocations(allTasks, panelFilters.taskType);
  const otherLocations = getOtherLocations(allTasks, panelFilters.taskType);
  const departmentsLocations = getDepartmentsLocations(allTasks, panelFilters.taskType);
  const otherMfaqLocations = getOtherMfaqLocations(allTasks, panelFilters.taskType);

  const getFilterListItemsFromLocations = (
    title: string,
    locations: { id: string; transKey: string; originalLocationValue: string }[]
  ) => {
    const filterListItems = locations.filter((location) => {
      return location.originalLocationValue !== "" && location.id !== "";
    }).map((location) => {
      return {
        id: location.id,
        title: t(location.transKey) || location.id || location.originalLocationValue,
        value: location.originalLocationValue,
        control: "checkbox" as const,
        checked:
          panelFilters.locations &&
          panelFilters.locations.includes(location.originalLocationValue),
      };
    });

    if (filterListItems.length === 0) {
      return null;
    }

    return (
      <FilterListView
        title={title}
        list={filterListItems}
        handleChange={handleChange}
      />
    );
  };

  // prettier-ignore
  return (
    <div className={styles["padding"]}>
      <Accordion padded>
        <AccordionItem
          id={"location"}
          open={false}
          title={<Trans>locationsString</Trans>}
        >
          {!isMfaqTask && hasTaskType && (
            <>
              {getFilterListItemsFromLocations("showroomString", showroomLocations)}
              {getFilterListItemsFromLocations("markethallString", markethallLocations)}
              {getFilterListItemsFromLocations("otherString", otherLocations)}
            </>
          )}
          {isMfaqTask && (
            <>
              {getFilterListItemsFromLocations("departmentsString", departmentsLocations)}
              {getFilterListItemsFromLocations("otherString", otherMfaqLocations)}
            </>
          )}
          {!hasTaskType && (
            <div className={styles["no-task-type"]}>
              <Trans>selectTaskTypeToFilterByLocationString</Trans>
            </div>
          )}
        </AccordionItem>
      </Accordion>
    </div>
  );
};
