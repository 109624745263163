import React from "react";
import { useTaskState } from "./TaskStateHelper";
import type { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";
import type { Image } from "@coworker/types/lib/tasks/base";

function isAnyLoading(images: Image[]) {
  try {
    return images.some((image) => "loading" in image);
  } catch (error) {
    return false;
  }
}

// TODO: The typing here looks wrong! This should be a TestbuyFollowupTask right?
export function useTaskStateUpdates(defaultTask: TestBuyFollowUp) {
  const { task, setTask } = useTaskState(defaultTask); // This is a oneline wrapper around useState. Probably created to make it testable?

  return React.useMemo(() => {
    function updateAssignedTo({ gid, uid }: { gid: string; uid: string }) {
      setTask({
        ...task,
        assigned_user_id: uid,
        assigned_team_id: gid,
        state: uid ? "ASSIGNED" : "UNASSIGNED",
        last_assigned_at: Date.now(),
      });
    }

    function isTaskValid() {
      if (!task.assigned_team_id && !task.assigned_user_id) return false;
      if (isAnyLoading(defaultTask.images)) return false;
      return true;
    }

    return {
      task,
      togglePriority: () =>
        setTask({ ...task, priority_flag: !task.priority_flag }),
      updateDescription: (description: string) =>
        setTask({ ...task, description }),
      updateImages: (images: Image[]) => {
        if (!isAnyLoading(images)) setTask({ ...task, images });
      },
      updateAssignedTo,
      isTaskValid,
    };
  }, [task, setTask, defaultTask.images]);
}
