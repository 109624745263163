import React from "react";
import { useRemoteImages } from "../../hooks/useRemoteImages";
import ImageSlider from "../ImageSlider";

const ImageGallery = ({ data, onClose }) => {
  const scrollables = useRemoteImages(data);

  return (
    <ImageSlider
      key="images"
      images={data}
      scrollables={scrollables}
      onClose={onClose}
    />
  );
};

export default ImageGallery;
