import React from "react";
import styles from "./task-card.module.css";
import SSRIcon from "@ingka/ssr-icon";
import arrowIcon from "@ingka/ssr-icon/paths/arrow-pair-squarepath";
import useFormatScheduled from "@coworker/app/src/hooks/useFormatScheduled";
import { CustomTask } from "@coworker/types/lib/tasks/custom";
import { useWatchedTask } from "@coworker/app/src/hooks/useWatchedTasks";
import { Task } from "@coworker/types/lib/tasks/base";
import { CardMode } from "./types";
import { CARD_MODE } from "./constants";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

interface FrequencyProps {
  task: Task;
  cardMode: CardMode;
  state: string;
}

export const Frequency = ({ task, cardMode, state }: FrequencyProps) => {
  const customTask = task as CustomTask;
  const plannedTaskParent = useWatchedTask(customTask.repeatable_task_id);

  const { previewHint: frequency } = useFormatScheduled(
    plannedTaskParent?.planned ?? customTask.planned ?? {}
  );

  const isTaskCompleted = state === taskStateOptions.COMPLETED;
  const isTaskClosed = state === taskStateOptions.CLOSED;

  return (
    <>
      {frequency && cardMode !== CARD_MODE.SIMPLE && (
        <div className={styles["frequency-container"]}>
          <SSRIcon
            className={`${styles["closed-icon"]} ${styles["frequency-icon"]} ${
              (isTaskCompleted || isTaskClosed) && styles["frequency-completed"]
            }`}
            paths={arrowIcon}
          />
          <div
            className={`${styles["frequency-text"]} ${
              (isTaskCompleted || isTaskClosed) && styles["frequency-completed"]
            }`}
          >
            {frequency}
          </div>
        </div>
      )}
    </>
  );
};
