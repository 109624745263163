import { isAndroid, isDesktop, isIOS } from "../../helpers/browserChecks";
import { scannerLog } from "./log";

export async function detectMainCamera() {
  if (!navigator.permissions) return;
  const cameraPermission = await navigator.permissions
    .query({
      name: "camera",
    })
    .catch(); // This fails on firefox: https://sentry.io/organizations/ingka/issues/3131338001/events/?project=5407140&statsPeriod=14d
  if (cameraPermission.state !== "granted") {
    await navigator.mediaDevices.getUserMedia({ video: true }); // Ask for permission manually
  }
  // Get the list of all I/O devices
  let devices;
  try {
    devices = await navigator.mediaDevices.enumerateDevices();
  } catch (error) {
    console.log("detectMainCamera", error);
    return;
  }

  // Get video inputs (cameras) and their capabilities
  const videoInputs = devices
    .filter((device) => device.kind === "videoinput")
    .map((device) => ({ device, capabilities: device.getCapabilities?.() }));

  scannerLog({ isDesktop, isIOS, isAndroid });
  if (isDesktop) return videoInputs[0]; // Desktop just has one camera

  const searchString = isAndroid ? "0" : isIOS ? "Back" : "";

  // Get the main camera
  scannerLog(videoInputs);
  return videoInputs.find(({ device, capabilities }) => {
    return (
      (capabilities?.facingMode || []).includes("environment") &&
      device.label.includes(searchString)
    );
  });
}
