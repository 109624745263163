import React from "react";
import styled from "styled-components";
import { TextArea, Button } from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import { useTextAreaPopup } from "./_common";
import { isIOS } from "../../helpers/browserChecks";

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 106px;
  padding-bottom: 10px;
`;

const Container = styled.div`
  position: fixed;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 50px;
  overflow-y: auto;
`;
const TitleText = styled.div`
  margin-top: 128px;
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--grey900);
  margin: 12px 29px 0px 29px;
  @media (min-height: ${isIOS ? "758px" : "500px"}) {
    margin: 55px 29px;
  }
`;
const StyledButton = styled(Button)`
  margin: 21px 19px 19px 30px;
  width: 100%;
`;
const Counter = styled.div`
  color: var(--grey500);
  width: 100%;
  font-size: 12px;
  margin-left: 29px;
  margin-bottom: 20px;
  margin-top: 12px;
  @media (min-height: ${isIOS ? "758px" : "500px"}) {
    margin-top: 48px;
  }
`;

const Title = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(props.initialValue);
  const containerRef = React.useRef(null);
  const { textArea, onFocus, onBlur } = useTextAreaPopup(containerRef, value, {
    lineHeight: 19,
    verticalPadding: 60,
    initialLines: 1,
  });
  const maxLength = 64;
  const currentLength = value ? value.length : 0;

  React.useEffect(() => {
    if (value && maxLength < value.length) {
      setValue(value.slice(0, maxLength));
    }
  }, [value, maxLength]);

  return (
    <Container ref={containerRef} data-testid="customTitleModal">
      <TitleText>
        <Trans>addtitleHeadlineString</Trans>
      </TitleText>
      <Counter>
        {currentLength}/{maxLength}
      </Counter>
      <form>
        <TextArea
          placeholder={t("customTitlePlaceholderString")}
          onChange={(e) => setValue(e.target.value)}
          ref={textArea}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          data-testid="titleInput"
        />

        <ButtonWrapper>
          <StyledButton
            customMargin
            type="submit"
            text={<Trans>continue2String</Trans>}
            primary={true}
            dark={true}
            disabled={!value}
            onClick={(event) => {
              event.preventDefault();
              props.onSubmit(value);
            }}
            data-testid="titleButton"
          />
        </ButtonWrapper>
      </form>
    </Container>
  );
};

export default Title;
