import { Articles } from "../../types/article";
import { Room, Rooms } from "../../types/room";

export const checkRoomName = (room: Room, rooms: Rooms) => {
  const found = rooms.find(
    (roomFromList) =>
      room.name === roomFromList.name && room.id !== roomFromList.id
  );

  if (found) return true;

  return false;
};

export const checkEmptyRoomName = (room: Room) => {
  const isRoomNameEmpty = !room.name;
  if (isRoomNameEmpty) return true;

  return false;
};

export const validationMessage = (room: Room, rooms: Rooms) => {
  if (checkRoomName(room, rooms)) {
    return `Room with name ${room.name} already exists`;
  } else if (checkEmptyRoomName(room)) {
    return "Room name cannot be empty";
  }
  return "";
};

export const getArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article ? article.itemName : "";
};

export const getFullArticleName = (articles: Articles, articleId: string) => {
  const article = articles.find((article) => article.id === articleId);
  return article
    ? `${article.productArticleId}: ${article.itemName}, ${article.itemType}`
    : "";
};
