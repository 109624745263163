import { useMemo } from "react";
import { useRoomsByStoreId } from "./useRoomsByStoreId";
import statusTypes from "@coworker/functions/src/enums/statusTypes.json";

export const useActiveRoomsByStoreId = (storeId: string) => {
  const { data: rooms, isLoading } = useRoomsByStoreId(storeId);
  const activeRooms = useMemo(() => {
    return rooms?.filter((room) => room.active === statusTypes.ACTIVE);
  }, [rooms]);
  return { activeRooms, isLoading };
};
