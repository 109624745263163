import React from "react";
import dayjs from "dayjs";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import {
  defaultCustomPeriod,
  getPeriodFilter,
  FilterKeys,
  periodIsCustom,
  periods,
} from "./filterChoices";

/**
 * Hook that calculates initial and end timestamp of the period
 */
export function usePeriodTimestamps(
  filters: FilterKeys,
  settingsPrefix = profilePreferences.MFAQ_PREFIX
) {
  const periodType = getPeriodFilter(filters) ?? ""; // TS hack, we know that periodType is always defined
  const [[startDate, endDate]] = useUserPreference(
    settingsPrefix + profilePreferences.USER_FILTERS_CUSTOM_PERIOD,
    defaultCustomPeriod()
  );
  return React.useMemo(() => {
    if (periodIsCustom(periodType)) {
      return [
        dayjs(startDate).valueOf(),
        dayjs(endDate).endOf("day").valueOf(),
      ];
    } else {
      const periodStartTs = periods
        .find((period) => period.type === periodType)
        ?.getInitialDate?.()
        .valueOf();
      return [periodStartTs, dayjs().valueOf()];
    }
  }, [endDate, periodType, startDate]);
}
