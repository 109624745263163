/**
 * Linear interpolation. Take range and a ratio of that range and calculate the value corresponding to that ratio
 * @param {Number} a range lower bound
 * @param {Number} b range upper bound
 * @param {Number} t ratio between a and b
 * @returns {Number} value corresponding to the ratio between a and b
 */
function lerp(a, b, t) {
  return (1 - t) * a + t * b;
}

/**
 * Take range and a number, return ratio of number location inside range
 * @param {Number} a range lower bound
 * @param {Number} b range upper bound
 * @param {Number} v value
 * @returns {Number} location of the value inside range, as ratio [0, 1]
 */
function inverseLerp(a, b, v) {
  return (v - a) / (b - a);
}

module.exports = { lerp, inverseLerp };
