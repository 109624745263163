export const filterSlmData = (slmData: string[], query: string) => {
  return (
    !!slmData &&
    slmData
      .filter((location: string) => {
        return location.startsWith(query);
      })
      .slice(0, 5)
  );
};
