export enum ModalTypes {
  DEFAULT_VIEW = "defaultView",
  KEEP_SELECTION = "keepSelection",
  SELECTED_ARTICLES_ACTIONS = "selectedArticlesActions",
  GROUP_ACTIONS = "groupActions",
  PTAG_ARTICLE_NUMBERS = "ptagArticleNumbers",
  PTAG_GROUP_ARTICLE_NUMBERS = "ptagGroupArticleNumbers",
  CREATE_GROUP = "createGroup",
  ADD_TO_GROUP = "addToGroup",
  EDIT_GROUP_NAME = "editGroupName",
  DELETE_GROUP = "deleteGroup",
  DELETE_SELECTED_ARTICLES = "deleteSelectedArticles",
  FOOTER_BUTTON_ACTION = "footerButtonAction",
}
