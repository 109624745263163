import React from "react";
import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { TaskCard } from "./TaskCard";
import { FullWidthButton } from "../../SkapaButton";
import { useTranslation } from "@coworker/locales";
import {
  getPassedTimeAndTranslationKey,
  getPiecesTranslation,
  isOngoing,
} from "../tools";
import { ListItemWrapper } from "../styles/styles";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { ModalContext } from "@coworker/apprestructured/src/layout/context/ModalContextProvider";
import { ModalType } from "@coworker/apprestructured/src/layout/context/modalType";

interface CreateTaskCardProps {
  task: RoomSettings | undefined;
  roomName: string;
  pieces: number;
  isCurrentArticle: boolean;
  articleId: string;
  roomId: string;
}

export function CreateTaskCard({
  task,
  roomName,
  pieces,
  isCurrentArticle,
  articleId,
  roomId,
}: CreateTaskCardProps) {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { toggleModal } = React.useContext(ModalContext);

  const headLine = `${roomName}: ${pieces} ${t(getPiecesTranslation(pieces))} ${
    isCurrentArticle ? t("selectedString") : ""
  }`;

  let subText = "";
  let buttonText = t("createString");

  let isOngoingTask = false;
  if (task) {
    const createdAt = new Date(task.created_at).toISOString();
    const timePassedAndKey = getPassedTimeAndTranslationKey(createdAt);
    isOngoingTask = isOngoing(task);

    let timePassed = `${timePassedAndKey.passedTime} ${t(
      timePassedAndKey.translationKey
    )}`;
    if (timePassedAndKey.translationKey === "lessThanDayString") {
      timePassed = t(timePassedAndKey.translationKey);
    }

    subText = `${t("lastCreatedString")}: ${
      isOngoingTask ? t("ongoingString") : timePassed
    }`;
    buttonText = isOngoingTask ? t("showTaskString") : t("createString");
  }

  const onClick = () => {
    if (isOngoingTask) {
      toggleModal({
        type: ModalType.TaskDetail,
        payload: { taskId: task?.id },
      });
    } else {
      push(`/roomsettings/${roomId}/articles/${articleId}/createtask`);
    }
  };

  const roomChevronOnClick = () => {
    push(`/roomsettings/${roomId}`);
  };

  return (
    <>
      <TaskCard
        headLine={headLine}
        subText={subText}
        control="navigational"
        rightIcon="chevronRightSmall"
        onClick={roomChevronOnClick}
      />
      <ListItemWrapper $hasBanner={false}>
        <FullWidthButton
          text={buttonText}
          onClick={onClick}
          type={isOngoingTask ? "secondary" : "primary"}
        />
      </ListItemWrapper>
    </>
  );
}
