import React from "react";
import styled from "styled-components";
import { useInputPopup } from "./InputPopup";
import { filterAndSortTeamMembers } from "../helpers/userSorters";
import { Button, OfflineScreen } from "@coworker/components";
import Avatar from "./Avatar/Avatar";
import { useTelephoneLinker } from "../hooks/useTelephoneLinker";
import { Trans, useTranslation } from "@coworker/locales";
import { displayRoleTranslationString } from "@coworker/common/helpers/user.helper";
import {
  useStoreContactsEnabled,
  useStoreUsers,
  useAsMap,
} from "../hooks/useStoreContacts";
import trackerHelper from "../helpers/tracker";
import { useStoreTeams } from "../core/hooks/useStoreTeams";
import { useFixaUID, useTeamId } from "../core/auth/useLoggedInUser";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 125px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey500);
  text-align: center;
  width: 100%;
  & > p {
    max-width: 130px;
  }
`;
const TeamMembersHeader = styled.div`
  background: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--grey200);
`;

const Body = styled.div`
  background: var(--white);
  width: 100%;
`;
const TeamTitle = styled.div`
  text-align: center;
  margin-top: 15px;
  font-size: 28px;
  font-weight: bold;
`;
const StyledButton = styled(Button)`
  margin-top: 20px;
  min-height: 40px;
  width: 122px;
  & > * {
    padding: 8px 0;
  }
`;
const Number = styled.div`
  font-weight: bold;
  color: var(--grey700);
`;
const TeamMate = styled.div`
  box-sizing: border-box;
  padding: 24px;
  color: var(--black);
  background: var(--white);
  text-decoration: none;
  border-bottom: 1px solid var(--grey200);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  width: 100%;
  height: 100%;
`;
const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 122px;
  height: 40px;
`;
const Name = styled.p`
  font-weight: bold;
`;
const Role = styled.p`
  font-weight: bold;
  color: var(--grey718);
`;
const Phones = styled.p`
  color: var(--grey718);
`;
const Shift = styled.p`
  font-weight: bold;
  color: var(--related-blue);
`;

function TeamMembersOnline() {
  const teamId = useTeamId();
  const fixaUid = useFixaUID();
  const users = useAsMap(useStoreUsers(), true);
  const teams = useStoreTeams();
  const myteam = teams.find((team) => team.id === teamId);
  const teamName = myteam?.name || "";

  const { getInput } = useInputPopup();
  const { t } = useTranslation();

  const getTeamChange = async (teams) => {
    await getInput("teamChange", {
      title: <Trans>yourString</Trans>,
      teams,
      teamId,
    });
  };

  const teamMates = React.useMemo(
    () => (users ? filterAndSortTeamMembers(users, teamId) : []),
    [teamId, users]
  );
  const teamNumber = teamMates.length;

  function getPeopleWorkingToday() {
    if (teamNumber === 1) {
      if (teamMates[0].fixa_uid !== fixaUid) {
        // Also handle the case when the current user is a user that does never get listed in this list (so all SUPERUSERs currently, and after COAPP-1080 only GLOBAL SUPERUSER will need this exception).
        return <Trans>onePersonString</Trans>;
      } else {
        return <Trans>youString</Trans>;
      }
    } else {
      return t("peopleString", { teamNumber });
    }
  }

  // teamMates is empty ONLY if the current user is a global superuser, as that user type is filtered out.
  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("teamString"),
        actions: [],
      }}
      noPadding={true}
    >
      <TeamMembersHeader>
        <TeamTitle data-testid="team-name">{teamName}</TeamTitle>
        <Number>{getPeopleWorkingToday()}</Number>
        <StyledButton
          customMargin
          data-testid="change-team"
          secondary
          dark
          text={<Trans>changeString</Trans>}
          onClick={() => {
            trackerHelper.changeTeam.viewTeamList();
            getTeamChange(teams);
          }}
        />
      </TeamMembersHeader>
      <Body>
        {teamNumber ? (
          <TeamMates teamMates={teamMates} teamName={teamName} />
        ) : (
          <Empty>
            <p>
              <Trans>membersString</Trans>
            </p>
          </Empty>
        )}
      </Body>
    </FullScreenPopup>
  );
}

function TeamMates({ teamMates, teamName }) {
  const { getInput } = useInputPopup();
  const makePhoneLink = useTelephoneLinker();

  const storeContactsEnabled = useStoreContactsEnabled();

  const getTeamMate = async (user) =>
    await getInput("user", {
      title: "",
      user,
      teamName,
      content: [],
    });

  return teamMates.map((m) => {
    return (
      <TeamMate key={m.uid ?? m.fixa_uid} onClick={() => getTeamMate(m)}>
        <Info>
          <Name>
            {m.given_name} {m.surname}
          </Name>
          <Role>
            <Trans>{displayRoleTranslationString(m.role_id)}</Trans>
          </Role>
          {storeContactsEnabled && (
            <Phones onClick={makePhoneLink(m.phone)}>{m.phone}</Phones>
          )}
          <Shift>{m.shift}</Shift>
        </Info>
        <AvatarWrapper>
          <Avatar fixaUid={m.fixa_uid} />
        </AvatarWrapper>
      </TeamMate>
    );
  });
}

export function TeamMembers({ isOnline }) {
  return isOnline ? <TeamMembersOnline /> : <OfflineScreen />;
}
