const ART_SPR_ALL_RE = /ART|SPR/g;

const PRODUCT_NUMBER_RE =
  /^\s*(s|ART|SPR|)(?:\1|)\s*(\d\d\d)\.?\s*(\d\d\d)\.?\s*(\d\d)\s*$/;

/**
 * parse product number
 * @returns {Array} Array with [short_id, type]
 */
function parseProduct(string = "") {
  const canMatch = string && string.match; // RDT doesn't support ?. so we need to take small steps.
  const match = canMatch && string.match(PRODUCT_NUMBER_RE);
  if (!match) return ["", ""];
  const [, type, ...digits] = match;
  const short_id = digits.join("");
  return [
    short_id.length === 8 ? short_id : "",
    (type === "s" ? "SPR" : type) || "",
  ];
}

/**
 * @param {string | string[]} codes
 */
function idsWithoutType(codes) {
  if (!codes) return "";
  let q = "";
  if (typeof codes === "string") {
    q = codes;
  } else if (Array.isArray(codes)) {
    q = codes.filter(Boolean).join(",");
  } else {
    q = codes.toString();
  }

  return q.replace(ART_SPR_ALL_RE, "");
}

module.exports.parseProduct = parseProduct;
module.exports.idsWithoutType = idsWithoutType;
