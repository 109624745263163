/**
 * Custom Google Analytics wrapper for Fixa
 *
 * Implementation based on https://github.com/firebase/firebase-js-sdk/blob/master/packages/analytics/src/
 */

const GtagCommand = {
  EVENT: "event",
  SET: "set",
  CONFIG: "config",
};
var shouldReportToGA = false;

function injectGtagScript(measurementId) {
  const script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script.async = true;
  document.head.appendChild(script);
}

function gtag() {
  if (shouldReportToGA) {
    window.dataLayer.push(arguments);
  }
}

function init(measurementId) {
  injectGtagScript(measurementId);

  window.dataLayer = window.dataLayer || [];
  shouldReportToGA = true;

  gtag("js", new Date());
  gtag(GtagCommand.CONFIG, measurementId);
}

async function setGAUserId(userId) {
  gtag(GtagCommand.SET, { user_id: userId });
  return Promise.resolve();
}

async function setGAUserProperties(properties) {
  const flatProperties = {};
  for (const [key, property] of Object.entries(properties)) {
    // Use dot notation for merge behavior in gtag.js
    flatProperties[`user_properties.${key}`] = property;
  }
  gtag(GtagCommand.SET, flatProperties);
  return Promise.resolve();
}

async function logEvent(eventName, eventParams) {
  gtag(GtagCommand.EVENT, eventName, eventParams);
  return Promise.resolve();
}

export const gaService = { init, setGAUserId, setGAUserProperties, logEvent };
