import {
  parseProduct,
  getProductCachedData,
} from "../services/products.service";
import { useMixedSearchProducts } from "./useSearchProducts";
import useFormatter from "./useFormatter";
import { INTERVAL } from "../constants/clientTime";

const SIX_MONTHS_IN_MILLISECONDS = (365 / 2) * INTERVAL.DAY;
function show(date) {
  const timestamp = date?.getTime() || Date.parse(date);
  return !!timestamp && timestamp - Date.now() < SIX_MONTHS_IN_MILLISECONDS;
}

const cache = {
  set: (shortId, value) => (cache[shortId] = value || cache[shortId] || ""),
};

export function useLatestSalesDate(code) {
  const [shortId] = parseProduct(code);

  const last = getProductCachedData(shortId)?.latestSalesDate;
  if (last) cache.set(shortId, last);

  // TODO: Rework this to pick End Date Sale info from Selling Range.
  // Here we just do normal search and don't need to include product number search to cover all food products etc that aren't included in either of SIK/MFS search results.
  const { data } = useMixedSearchProducts(shortId, { blockRequest: last });
  const loadedDate = data?.[0]?.latestSalesDate;
  if (loadedDate) cache.set(shortId, loadedDate);

  const { formatSalesDate } = useFormatter();
  const date = cache[shortId];
  return {
    loading: !(shortId in cache),
    latestSalesDate: show(date) ? formatSalesDate(date) : null,
  };
}
