import React from "react";
import { ModalHeader } from "@ingka/modal";
import { useTranslation } from "@coworker/locales";
import tracker from "../helpers/tracker";
import styled from "styled-components";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/focus/dist/style.css";

const Separator = styled.div`
  width: 100%;
  background-color: var(--grey200);
  height: 1px;
`;

interface SkapaModalHeaderProps {
  titleString: string;
  useSeparator?: boolean;
  onClose: () => void;
}

export function SkapaModalHeader({
  titleString,
  onClose,
  useSeparator = true,
}: SkapaModalHeaderProps) {
  const { t } = useTranslation();

  const translatedTitle = t(titleString);

  return (
    <>
      <ModalHeader
        title={translatedTitle}
        closeBtnClick={() => {
          tracker.trackDismissModal(true);
          onClose?.();
        }}
      />
      {useSeparator && <Separator />}
    </>
  );
}
