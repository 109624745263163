import React from "react";
import BlueLink, { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>개인정보처리방침</div>,
  aboutFixaText: (
    <div>
      Fixa는 매장 코워커를 위한 업무 관리 및 머천다이징 도구입니다. Fixa
      애플리케이션의 목적은 매뉴얼, 서류 또는 엑셀 기반의 활동을 대체하고 매장
      내 업무 관리 (제품 재고 보충, 편차 파악, 테스트 구매, FAQ 확인 등)의
      효율성을 높이는 것입니다. Fixa를 활용하면 완료한 업무로부터 얻은 통찰력을
      바탕으로 분석을 수행할 수도 있습니다.
      <br />
      <br />
      사용자가Fixa를 사용할 때, 잉카 그룹 (Ingka Group)은 아래와 같은 개인정보를
      수집·이용 및 관리 합니다.
    </div>
  ),
  personalDataCollectedTitle: <div>수집하는 개인정보:</div>,
  personalDataCollectedText: (
    <div>
      사용자가 Fixa를 사용할 때 Fixa는 코워커 데이터베이스(CDS)로부터 사용자의
      이름, 이메일, 직함, 업무, 전화번호(직접 입력하는 경우에 한함, 개인
      전화번호는 입력하지 마십시오), CDS에 등록된 사진, 앱 설정 언어, 매장 ID,
      국가 ID, 기기 및 운영체제 버전, Google Cloud Platform 및
      Sentry(애플리케이션 오류 추적 소프트웨어)에서 처리하는 IP 주소 등의
      개인정보를 수집합니다.
      <br />
      <br />
      코워커 프로필은 매니저/슈퍼 유저 (Super user)가 Fixa-admin(관리자 웹 앱)을
      통해 직접 생성합니다. 개인정보는 CDS에서 가져오기 때문에 활성화된
      CDS프로필을 보유한 코워커만 프로필을 생성할 수 있습니다.
    </div>
  ),
  purposeOfDataCollectionTitle: <div>개인정보 수집 목적:</div>,
  purposeOfDataCollectionText: (
    <div>
      Fixa에서 개인정보를 수집하는 목적은 Fixa 애플리케이션이 코워커 간 내부
      커뮤니케이션과 매장 코워커 간 관리 업무를 수월하게 하는 방식으로 적절하게
      작동하도록 하기 위해서입니다. 업무와 사용자를 연결함으로써 업무를 배정받은
      사용자와 업무의 생성자에게 업무 관련 업데이트 알림을 지원할 수 있습니다.
    </div>
  ),
  legalGroundsTitle: <div>개인정보처리의 법적 근거:</div>,
  legalGroundsText: (
    <div>개인정보처리에 관한 법적 근거는 정당한 이해에 있습니다.</div>
  ),
  dataWillBeStoredTitle: <div>개인정보보관:</div>,
  dataWillBeStoredText: (
    <div>
      사용자와 관련된 완료된 업무의 경우, 사용자 정보를 익명 처리한 후110일간
      보관합니다. 코워커의 개인정보는 고용 계약 기간 동안 Fixa에 보관됩니다.
      <br />
      <br />
      잉카 그룹과의 고용 계약 해지: 모든 개인정보는 CDS 프로필이 삭제된 후 1개월
      이내에 자동으로 삭제됩니다.
      <br />
      <br />
      잉카 그룹 내 업무 변경: Fixa 사용이 더이상 필요하지 않은 코워커의 경우,
      모든 개인정보는 활동 중단 시점으로부터 3개월 이내에 자동으로 삭제됩니다.
      <br />
      <br />
      또한 개인정보는 현지 정보 보안 및 개인정보처리 책임자 또는 매장 매니저의
      요청에 따라 언제든지 삭제될 수 있습니다.
    </div>
  ),
  dataControllerForPersonalDataTitle: <div>개인정보처리자:</div>,
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>이케아 코리아 (IKEA Korea LTD)</div>
  ),
  rightToPersonalInformationText: (
    <div>
      사용자는 본인의 개인정보에 관한 특정 권리를 가집니다. 해당 권리에 대한
      자세한 정보는{" "}
      <BlueLink href="https://iweof.sharepoint.com/teams/o365g_digitalisdpanddpo_retkrso/Shared Documents/Data Privacy/Co-worker Privacy Policy/Privacy Policy for co-workers_South Korea.pdf">
        쉐어포인트 (SharePoint)
      </BlueLink>{" "}
      에서 확인하시기 바랍니다. 개인정보 보호 책임자 연락처:
      <EmailLink email="Data.Privacy.KR@ikea.com" />
    </div>
  ),
};
