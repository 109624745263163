import { HEADER, LOCATION, DESCRIPTION, ASSIGNMENT } from "./_common";

import { REPEATABLE, SCHEDULE } from "../widgets";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fields: [
    HEADER,
    DESCRIPTION(),
    LOCATION(),
    ASSIGNMENT(),
    { type: REPEATABLE },
    { type: SCHEDULE },
  ],
  actions: [],
};
