import React from "react";
import styles from "./task-card.module.css";
import SSRIcon from "@ingka/ssr-icon";
import imageIcon from "@ingka/ssr-icon/paths/image";
import commentIcon from "@ingka/ssr-icon/paths/speech-bubble";
import { useAllImages } from "@coworker/app/src/hooks/useTaskImages";
import { useTaskNotes } from "@coworker/app/src/hooks/useTaskNotes";

export const ImagesAndComments = ({ taskId }: { taskId: string }) => {
  const images = useAllImages(taskId);
  const notes = useTaskNotes(taskId);
  return (
    <div className={styles["icon-wrapper"]}>
      {images.length > 0 && (
        <SSRIcon paths={imageIcon} className={styles["icon"] as string} />
      )}
      {notes.length > 0 && (
        <SSRIcon paths={commentIcon} className={styles["icon"] as string} />
      )}
    </div>
  );
};
