import React from "react";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { Trans } from "@coworker/locales";
import {
  getProductWidget,
  ASSIGN_WIDGET,
  PRIORITY_WIDGET,
  DESCRIPTION_WIDGET,
  PHOTOS_WIDGET,
} from "./_common";

const roomsettings = {
  table: "tasks",
  type: "roomsettings",
  enumType: taskTypeOptions.ROOM_SETTINGS,
  validate: (form) =>
    [
      form.product_article_id,
      form.assigned_team_id || form.refilled_by_me,
      !form.images?.some((image) => image.loading),
    ].every((x) => x),
  header: {
    newTitle: <Trans>requestString</Trans>,
    editTitle: <Trans>editTaskString</Trans>,
  },
  fields: [
    getProductWidget({
      withAvailability: false,
      disabled: () => true,
    }),
    { ...ASSIGN_WIDGET, hide: (_, { refilled_by_me }) => refilled_by_me },
    { ...PRIORITY_WIDGET, hide: (_, { refilled_by_me }) => refilled_by_me },
    {
      ...DESCRIPTION_WIDGET(true),
      hide: (_, { refilled_by_me }) => refilled_by_me,
    },
    PHOTOS_WIDGET,
  ],
};

export default roomsettings;
