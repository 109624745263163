import { useToastNotification } from "@coworker/components";
import { Trans } from "@coworker/locales";
import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ArrowIcon } from "../../assets/svg/arrow-small-forward.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-small.svg";
import {
  useWorkspacesAction,
  useWorkspacesState,
} from "../../hooks/useWorkspaces";
import AddQuestionInput from "./AddQuestionInput";
import Instructions from "../Instructions";
import {
  areaIsWholeStore,
  defaultFilters,
  describeAreaFilter,
  getAreaFilter,
  getPeriodFilter,
} from "../InputPopup/UserFiltersRow/filterChoices";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import { useInputPopup } from "../InputPopup";
import tracker from "../../helpers/tracker";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { useFeatureFlagHooks } from "./useMFAQs.featureToggle";
import FullscreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Button from "@ingka/button";
const { USER_FILTERS, MFAQ_PREFIX, DISABLE_POPUPS_MFAQ_ADD_QUESTION } =
  profilePreferences;

const Block = styled.div`
  padding: 0 24px;
  margin: 25px 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey100);
  }
  &:last-child {
    margin-bottom: 87px;
  }
  & > span {
    height: 50px;
    font-size: 18px;
  }
`;

const AreaInput = styled.div`
  height: 92px;
  font-size: 14px;
  ${({ isSelected }) =>
    isSelected
      ? css`
          color: var(--grey900);
        `
      : css`
          color: var(--grey600);
        `}
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span {
    flex-grow: 1;
  }

  & > svg {
    flex-grow: 0;
  }
`;

const QuestionTypePillsWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  & > * {
    margin-bottom: 10px;
  }
`;

const NewQuestion = () => {
  const { createQuestion } = useFeatureFlagHooks();
  const { navigationState } = useWorkspacesState();
  const { push, setNavigationState } = useWorkspacesAction();

  const store_id = useStoreId();
  const [filters] = useUserPreference(
    MFAQ_PREFIX + USER_FILTERS,
    defaultFilters
  );

  const areaFilter = getAreaFilter(filters);
  const isWholeStore = areaIsWholeStore(filters);
  const [selectedArea, setSelectedAreaState] = React.useState(
    navigationState?.selectedArea || (isWholeStore ? null : `${areaFilter}`)
  );
  const { getInput } = useInputPopup();
  const { showToast } = useToastNotification();

  const questionDataRef = React.useRef({
    store_id,
    type: "",
    area: selectedArea,
    question_text: "",
    subject: "",
  });
  const setSelectedArea = (area) => {
    setNavigationState({ ...navigationState, selectedArea: area });
    setSelectedAreaState(area);
    questionDataRef.current.area = area;
  };
  const [canCreateQuestion, setCanCreateQuestion] = React.useState(
    !!selectedArea && !!questionDataRef.current?.subject?.trim()
  );

  React.useEffect(() => {
    setCanCreateQuestion(
      !!selectedArea && !!questionDataRef.current?.subject?.trim()
    );
  }, [selectedArea]);

  const onSubmit = React.useCallback(async () => {
    if (canCreateQuestion) {
      await createQuestion(
        questionDataRef.current.store_id,
        questionDataRef.current.area || areaFilter,
        questionDataRef.current.question_text
      );
      tracker.MFAQ.trackCreateQuestion(questionDataRef.current);
      push("/customerquestions");
      showToast(<Trans>questionSucessfullyCreatedString</Trans>, <CloseIcon />);
    }
  }, [canCreateQuestion, push, showToast, createQuestion, areaFilter]);

  /**
   * @type {React.MutableRefObject<HTMLDivElement}
   */
  const pillsWrapperRef = React.useRef();
  React.useEffect(() => {
    if (selectedArea && pillsWrapperRef.current) {
      const inputElement = pillsWrapperRef.current
        .getElementsByTagName("input")
        .item(0);
      inputElement.focus();
      inputElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedArea]);

  return (
    <FullscreenPopup
      noPadding
      isActionBarHiddenOnScroll={false}
      actionBarContent={
        <Button
          data-testid="submitNewQuestion"
          disabled={!canCreateQuestion}
          type="primary"
          text={<Trans>submitString</Trans>}
          onClick={onSubmit}
        />
      }
      appBarConfig={{
        title: <Trans>addQuestionTitle</Trans>,
        actions: [],
      }}
    >
      <Instructions
        title={<Trans>mfaqSelectRightDetailsString</Trans>}
        text={<Trans>mfaqAddQuestionInstructionsString</Trans>}
        type={DISABLE_POPUPS_MFAQ_ADD_QUESTION}
        showBottomBorder
      />
      <Block
        data-testid="selectArea-popup"
        onClick={() => {
          getInput("userFilters", {
            title: <Trans>whereWasQuestionAskedString</Trans>,
            actionText: <Trans>selectAreaString</Trans>,
            hidePeriodFilters: true,
            hideWholeStore: true,
            noPersist: false,
            hideShowTasksToggle: true,
            onSetFilters: (newFilters) => {
              setSelectedArea(getAreaFilter(newFilters));
              setCanCreateQuestion(!!questionDataRef.current?.subject?.trim());
            },
            filters: [
              selectedArea ?? getAreaFilter(filters),
              getPeriodFilter(filters),
            ],
          });
        }}
      >
        <span>
          <strong>
            <Trans>areaString</Trans>
          </strong>
        </span>
        <AreaInput isSelected={!!selectedArea}>
          <span>
            {!!selectedArea ? (
              describeAreaFilter([selectedArea])
            ) : (
              <Trans>whereWasQuestionAskedString</Trans>
            )}
          </span>
          <ArrowIcon />
        </AreaInput>
      </Block>
      <Block>
        <span>
          <strong>
            <Trans>questionStringMFAQ</Trans>
          </strong>
        </span>
        <QuestionTypePillsWrapper ref={pillsWrapperRef}>
          <AddQuestionInput
            questionDataRef={questionDataRef}
            onInputTextChange={(text) =>
              setCanCreateQuestion(!!selectedArea && text?.trim().length >= 5)
            }
            areaIsWholeStore={isWholeStore}
            selectedArea={selectedArea}
          />
        </QuestionTypePillsWrapper>
      </Block>
    </FullscreenPopup>
  );
};

export default NewQuestion;
