import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInputPopup } from "../components/InputPopup";
const deepEqual = require("fast-deep-equal");

const WorkspacesStateContext = React.createContext();
const WorkspacesActionContext = React.createContext();
WorkspacesStateContext.displayName = "WorkspacesStateContext";
WorkspacesActionContext.displayName = "WorkspacesActionContext";

const makeRandomKey = () => `workspace-${Math.random()}`;

const actionTypes = {
  push: "push",
  silentPush: "silentPush",
  pop: "pop",
  replace: "replace",
  toCheckPoint: "toCheckPoint",
  changeNavigationState: "changeNavigationState",
  resetNavigationState: "resetNavigationState",
  changeWorkspace: "changeWorkspace",
  injectState: "injectState",
};

const pushAction = (path, state = {}, setCheckPoint = false) => ({
  type: actionTypes.push,
  path,
  state,
  setCheckPoint,
});
const toCheckPointAction = () => ({ type: actionTypes.toCheckPoint });
const silentPushAction = (path, state = {}) => ({
  type: actionTypes.silentPush,
  path,
  state,
});
const popAction = () => ({ type: actionTypes.pop });
const changeNavigationState = (state) => ({
  type: actionTypes.changeNavigationState,
  state,
});
const resetNavigationStateAction = () => ({
  type: actionTypes.resetNavigationState,
});
const changeWorkspaceAction = (workspace) => ({
  type: actionTypes.changeWorkspace,
  workspace,
});
const injectAction = (state) => ({
  type: actionTypes.injectState,
  state,
});
const replaceAction = (path, state) => ({
  type: actionTypes.replace,
  path,
  state,
});
function workspacesReducer(state, action) {
  switch (action.type) {
    case actionTypes.injectState: {
      return action.state;
    }
    case actionTypes.changeWorkspace: {
      const { workspace } = action;
      const { currentRoute, activeWorkspace } = state;

      const NEARBY_ROUTE_PATH = "/task/new/addon/nearby";

      if (workspace !== activeWorkspace) {
        const [nextRoute, ...newNextStack] = state.stacks[workspace];
        let oldStack = [currentRoute, ...state.stacks[activeWorkspace]];

        // Having issues with popups the new nearby addons is a route and this is
        // required to remove the stack when switching workspaces or viewing a created task
        if (currentRoute.path === NEARBY_ROUTE_PATH) {
          const { path: originalPath } =
            state.stacks[activeWorkspace][
              state.stacks[activeWorkspace].length - 1
            ];
          oldStack = [{ path: originalPath, state: {} }];
        }

        return {
          ...state,
          currentRoute: nextRoute,
          stacks: state.stacks.map((stack, i) => {
            if (workspace === i) {
              // New stack
              return newNextStack;
            } else if (activeWorkspace === i) {
              // Old stack
              return oldStack;
            }
            return stack;
          }),
          activeWorkspace: workspace,
        };
      } else {
        const newActive =
          state.stacks[workspace].length > 0
            ? state.stacks[workspace][state.stacks[workspace].length - 1]
            : currentRoute;
        return {
          ...state,
          stacks: state.stacks.map((stack, i) => {
            if (i === activeWorkspace) {
              return [];
            }
            return stack;
          }),
          currentRoute: { ...newActive, state: {} },
        };
      }
    }
    case actionTypes.push: {
      const { path, state: routeState, setCheckPoint } = action;
      const { currentRoute, activeWorkspace, stacks } = state;
      return {
        ...state,
        stacks: stacks.map((stack, idx) => {
          if (idx === activeWorkspace) {
            return [{ ...currentRoute, isCheckPoint: setCheckPoint }, ...stack];
          }
          return stack;
        }),
        currentRoute: {
          path,
          state: routeState,
        },
      };
    }
    case actionTypes.toCheckPoint: {
      const { activeWorkspace, stacks } = state;
      if (stacks[activeWorkspace].length === 0) {
        console.warn(
          "Workspaces: Can't go back: already at bottom of history stack.",
          state
        );
        return state;
      }
      if (!stacks[activeWorkspace].some(({ isCheckPoint }) => !!isCheckPoint)) {
        return workspacesReducer(state, popAction());
      }
      const checkPointIndex = stacks[activeWorkspace].findIndex(
        ({ isCheckPoint }) => !!isCheckPoint
      );
      return {
        ...state,
        stacks: stacks.map((stack, idx) => {
          if (idx === activeWorkspace) {
            return stack.slice(checkPointIndex + 1);
          }
          return stack;
        }),
        currentRoute: stacks[activeWorkspace][checkPointIndex],
      };
    }
    case actionTypes.silentPush: {
      const { path, state: routeState } = action;
      const { activeWorkspace, stacks } = state;
      return {
        ...state,
        stacks: stacks.map((stack, idx) => {
          if (idx === activeWorkspace) {
            return [{ path, state: routeState }, ...stack];
          }
          return stack;
        }),
      };
    }
    case actionTypes.pop: {
      const { activeWorkspace, stacks } = state;
      if (stacks[activeWorkspace].length === 0) {
        console.warn(
          "Workspaces: Can't go back: already at bottom of history stack.",
          state
        );
        return state;
      }
      const [prevRoute, ...prevStack] = stacks[activeWorkspace];
      return {
        ...state,
        stacks: stacks.map((stack, idx) => {
          if (idx === activeWorkspace) {
            return prevStack;
          }
          return stack;
        }),
        currentRoute: prevRoute,
      };
    }
    case actionTypes.changeNavigationState: {
      const {
        currentRoute: { path, state: prevState },
      } = state;
      return {
        ...state,
        currentRoute: {
          path,
          state: {
            ...prevState,
            ...action.state,
          },
        },
      };
    }
    case actionTypes.resetNavigationState: {
      return {
        ...state,
        currentRoute: {
          ...state.currentRoute,
          state: {},
        },
      };
    }
    case actionTypes.replace: {
      const { path, state: routeState } = action;
      return {
        ...state,
        currentRoute: {
          path,
          state: routeState,
        },
      };
    }
    default:
      return state;
  }
}

const initialState = {
  stacks: [
    [],
    [{ path: "/tasks?active=my", state: {} }],
    [{ path: "/task/new", state: {} }],
    [{ path: "/search", state: {} }],
  ],
  activeWorkspace: 0,
  initialized: true,
  currentRoute: { path: "/", state: {} },
};

const _cross = " ├─";
const _corner = " └─";
// const _vertical = " │ ";
// const _space = "   ";

function getStackDescription(stack, i) {
  let message = `Routes in stack ${i} (${stack.length} routes)\n`;
  stack.forEach(({ path }, idx) => {
    message += idx + 1 === stack.length ? _corner : _cross;
    message += ` ${path}\n`;
  });
  return `${message}\n`;
}

function printDebugMessage(state, lastChange) {
  let message = "";
  message += state.stacks.map(getStackDescription).join("");
  message += `Current route: ${state.currentRoute.path}\n`;
  message += `Current state: ${JSON.stringify(
    state.currentRoute.state,
    null,
    2
  )}
${(lastChange && "Last change originated in:") || ""}
${lastChange || ""}\n`;
  console.log(message, state);
}

export function useWorkspacesAction() {
  return React.useContext(WorkspacesActionContext);
}

export function useWorkspacesState() {
  const state = React.useContext(WorkspacesStateContext);
  if (!state) {
    console.error("This should not happen?");
    return { navigationState: {} };
  }
  return state;
}

function getLastChangeLocation() {
  const e = new Error();
  // Remove first three lines because they are irellevant
  const path = e.stack.split("\n").slice(3, 4).join("\n");
  return path;
}

export function WorkspacesProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { closePopup } = useInputPopup();
  const state = location.state || initialState;
  const locationState = React.useRef(state);
  const lastChange = React.useRef();

  React.useEffect(() => {
    if (!location.state || !location.state.initialized) {
      const searchParams = window.location.search;
      const newState = workspacesReducer(
        initialState,
        pushAction(window.location.pathname, { search: searchParams })
      );

      locationState.current = newState;
      navigate(newState.currentRoute.path, { replace: true, state: newState });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const silentPush = React.useCallback(
    (path) => {
      const key = makeRandomKey();
      const nextState = workspacesReducer(
        locationState.current,
        silentPushAction(path, { _key: key })
      );

      locationState.current = nextState;
      lastChange.current = getLastChangeLocation();
      window.debug_workspaces &&
        printDebugMessage(nextState, lastChange.current);
      if (nextState.currentRoute.path && key) {
        navigate(nextState.currentRoute.path, { state: { _key: key } });
      } else {
        console.error(`navigate("${nextState.currentRoute.path}");`);
      }
    },
    [navigate]
  );

  const replace = React.useCallback(
    (path, routeState) => {
      const nextState = workspacesReducer(
        locationState.current,
        replaceAction(path, { ...routeState, _key: makeRandomKey() })
      );

      locationState.current = nextState;
      lastChange.current = getLastChangeLocation();
      window.debug_workspaces &&
        printDebugMessage(nextState, lastChange.current);

      navigate(nextState.currentRoute.path, {
        replace: true,
        state: nextState,
      });
    },
    [navigate]
  );

  const push = React.useCallback(
    (path, routeState = {}, insertedPaths = [], setCheckPoint = false) => {
      const currentPath = locationState.current.currentRoute.path;

      if (currentPath === path) {
        return;
      }

      // Go back on current stack
      const key = makeRandomKey();

      // Silent insertion of paths to manipulate history stack
      insertedPaths.forEach((path) => {
        const nextState = workspacesReducer(
          locationState.current,
          pushAction(path, { _key: key })
        );
        locationState.current = nextState;
      });

      const finalState = workspacesReducer(
        locationState.current,
        pushAction(path, { ...routeState, _key: key }, setCheckPoint)
      );

      locationState.current = finalState;
      lastChange.current = getLastChangeLocation();
      closePopup();
      window.debug_workspaces &&
        printDebugMessage(finalState, lastChange.current);

      navigate(finalState.currentRoute.path, { state: finalState });
    },
    [closePopup, navigate]
  );

  const pop = React.useCallback(
    (times = 1, reset = false) => {
      if (window.block_pop) return;
      // Move in current stack
      let nextState = locationState.current;
      for (let i = 0; i < times; i++) {
        nextState = workspacesReducer(nextState, popAction());
      }
      if (reset)
        nextState = workspacesReducer(nextState, resetNavigationStateAction());
      locationState.current = nextState;
      lastChange.current = getLastChangeLocation();
      closePopup();
      window.debug_workspaces &&
        printDebugMessage(nextState, lastChange.current);

      navigate(nextState.currentRoute.path, { state: nextState });
    },
    [closePopup, navigate]
  );

  const setNavigationState = React.useCallback(
    (navState = {}) => {
      // Set additional state for route
      const nextState = workspacesReducer(
        locationState.current,
        changeNavigationState(navState)
      );
      if (!deepEqual(nextState, locationState.current)) {
        locationState.current = nextState;
        lastChange.current = getLastChangeLocation();
        window.debug_workspaces &&
          printDebugMessage(nextState, lastChange.current);

        navigate(nextState.currentRoute.path, {
          replace: true,
          state: nextState,
        });
      }
    },
    [navigate]
  );

  const resetNavigationState = React.useCallback(() => {
    // Set additional state for route
    const nextState = workspacesReducer(
      locationState.current,
      resetNavigationStateAction()
    );
    locationState.current = nextState;
    lastChange.current = getLastChangeLocation();
    window.debug_workspaces && printDebugMessage(nextState, lastChange.current);
    navigate(nextState.currentRoute.path, { state: nextState });
  }, [navigate]);

  const toCheckPoint = React.useCallback(() => {
    const nextState = workspacesReducer(
      locationState.current,
      toCheckPointAction()
    );
    locationState.current = nextState;
    lastChange.current = getLastChangeLocation();
    closePopup();
    window.debug_workspaces && printDebugMessage(nextState, lastChange.current);
    navigate(nextState.currentRoute.path, { state: nextState });
  }, [closePopup, navigate]);
  const injectState = React.useCallback(
    (state) => {
      // Set workspace you work with
      // dispatch(changeWorkspace(idx))
      const nextState = workspacesReducer(
        locationState.current,
        injectAction(state)
      );
      locationState.current = nextState;
      lastChange.current = getLastChangeLocation();
      closePopup();
      window.debug_workspaces &&
        printDebugMessage(nextState, lastChange.current);
      navigate(nextState.currentRoute.path, { state: nextState });
    },
    [closePopup, navigate]
  );
  const setWorkspace = React.useCallback(
    (idx) => {
      // Set workspace you work with
      // dispatch(changeWorkspace(idx))
      const nextState = workspacesReducer(
        locationState.current,
        changeWorkspaceAction(idx)
      );
      locationState.current = nextState;
      lastChange.current = getLastChangeLocation();
      closePopup();
      window.debug_workspaces &&
        printDebugMessage(nextState, lastChange.current);
      navigate(nextState.currentRoute.path, { state: nextState });
    },
    [closePopup, navigate]
  );

  React.useEffect(() => {
    // window.debug_workspaces && printDebugMessage(state, lastChange.current);
    const getMem = () =>
      JSON.parse(localStorage.getItem("workspacesSnaps") ?? "{}");
    const setMem = (mem) =>
      localStorage.setItem("workspacesSnaps", JSON.stringify(mem));
    window._workspaces = {
      inject: (state) => {
        injectState(state);
      },
      snap: (name) => {
        const mem = getMem();
        if (mem[name]) {
          console.log(`🌍 loading snap: %c${name}`, "font-weight: bold;");
          injectState(mem[name]);
        } else {
          console.log(`📒 saving snap: %c${name}`, "font-weight: bold;");
          mem[name] = state;
          setMem(mem);
        }
      },
      snaps: () => {
        const mem = getMem();
        console.log(
          `📖 saved snaps: %c${Object.keys(mem).join(", ")}`,
          "font-weight: bold;"
        );
      },
    };
  }, [state, injectState]);

  return (
    <WorkspacesActionContext.Provider
      value={{
        push,
        pop,
        toCheckPoint,
        silentPush,
        setNavigationState,
        resetNavigationState,
        setWorkspace,
        replace,
        injectState,
      }}
    >
      <WorkspacesStateContext.Provider
        value={{
          navigationPath: state.currentRoute?.path,
          navigationState: state.currentRoute?.state ?? {},
          activeWorkspace: state.activeWorkspace,
          state,
        }}
      >
        {children}
      </WorkspacesStateContext.Provider>
    </WorkspacesActionContext.Provider>
  );
}

window.debug_workspaces = false;
if (!window.Cypress && !["ci", "test"].includes(process.env.NODE_ENV)) {
  console.info(
    "To debug workspaces set 'window.debug_workspaces' to true/false"
  );
}
