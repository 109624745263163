import React from "react";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "../assets/svg/arrow-small-forward.svg";

const ArrowRight = styled(ArrowRightIcon)`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  color: var(--grey900);
  margin: 6px 0px;
  cursor: pointer;
`;

const Title = styled.div`
  line-height: 18px;
  font-size: 14px;
  color: var(--grey718);
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 3px;
`;

const ResizeAtWill = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
`;

export function SimpleProductInfo({
  title,
  description,
  formattedNumber,
  withArrow = true,
}) {
  return (
    <Container>
      <ResizeAtWill>
        <Title>
          <b>{title || ""} •</b> {description || ""}
        </Title>
        <Title>{formattedNumber}</Title>
      </ResizeAtWill>
      {withArrow && <ArrowRight />}
    </Container>
  );
}
