import React from "react";
import { useToastNotification } from "@coworker/components";
import { Trans } from "@coworker/locales";
import { updateTask } from "../services/task.service";
import tracker from "../helpers/tracker";
import { useStoreId } from "../core/auth/useLoggedInUser";

export default function useNotificationWithUndo() {
  const { showToast } = useToastNotification();
  const storeId = useStoreId();
  return React.useCallback(
    async (data, taskId, taskType) => {
      await updateTask(data, taskId, taskType, storeId);
      // undo_action_id is of format 'actiontype-timestamp',
      // so we remove the timestamp and only carry forward the action type
      const undoneAction =
        data.undo_action_id?.split("-")?.[0] ?? "unknown_action";
      tracker.trackUndo(undoneAction);
      showToast(<Trans>undoneString</Trans>);
    },
    [showToast, storeId]
  );
}
