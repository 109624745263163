import React from "react";
import BlueLink, { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <p>Déclaration de confidentialité</p>,
  aboutFixaText: (
    <div>
      Fixa est un outil de gestion des tâches et de merchandising destiné aux
      collaborateurs en magasin. L’objectif des applications FIXA est de
      remplacer les activités manuelles, sur papier ou sur Excel et d’optimiser
      la gestion des tâches en magasin (réassort d’articles, nettoyages, écarts,
      tests d’achat, questions les plus fréquemment posées, etc.).Fixa permet
      également d’effectuer des analyses grâce aux informations obtenues à
      partir des tâches terminées.
      <br />
      Lorsque vous utilisez Fixa, IKEA Belgique et le Groupe Ingka (tels que
      définis ci-dessous) recueillent certaines données personnelles vous
      concernant.
    </div>
  ),
  personalDataCollectedTitle: <p>Données personnelles collectées:</p>,
  personalDataCollectedText: (
    <div>
      Lorsque vous utilisez FIXA, le système collecte des informations vous
      concernant à partir de la base de données des collaborateurs (Co-Worker
      Database, CDS), telles que votre prénom, votre deuxième prénom, votre nom
      de famille, votre adresse e-mail, votre intitulé de poste, votre fonction,
      votre numéro de téléphone (s’il a été fourni manuellement ; votre numéro
      de téléphone personnel ne doit pas être saisi), votre photo sur CDS, votre
      préférence de langue dans l’application, votre identifiant de magasin,
      votre identifiant de pays, la version de votre appareil et de son système
      d’exploitation, ainsi que votre adresse IP traitée dans Google Cloud
      Platform et Sentry (Sentry est un logiciel de suivi des erreurs
      d’applications).
      <br />
      <br />
      Le profil du collaborateur est créé manuellement par le
      responsable/superutilisateur via Fixa-admin (une application Web
      d’administration). Les données personnelles sont extraites depuis CDS, de
      sorte que les profils ne peuvent être créés que pour les collaborateurs
      qui ont un profil CDS actif.
    </div>
  ),
  purposeOfDataCollectionTitle: <p>Objectif de la collecte de ces données:</p>,
  purposeOfDataCollectionText: (
    <p>
      L’objectif de la collecte de données personnelles dans Fixa est de
      permettre à l’application de fonctionner correctement, de manière à
      faciliter la communication interne entre les collaborateurs et à répartir
      les tâches entre eux au sein du magasin. Le fait d’avoir des tâches
      connectées à un utilisateur nous permet de prendre en charge les
      notifications de mise à jour des tâches pour les créateurs et utilisateurs
      désignés.
    </p>
  ),
  legalGroundsTitle: <p>Fondement juridique du traitement:</p>,
  legalGroundsText: (
    <p>Le fondement juridique du traitement est intérêt légitime.</p>
  ),
  dataWillBeStoredTitle: <p>Données conservées: </p>,
  dataWillBeStoredText: (
    <div>
      Nous conservons les tâches terminées avec des références aux utilisateurs
      pendant 70 jours, après quoi nous rendons les utilisateurs anonymes. Les
      données personnelles d’un collaborateur sont conservées dans Fixa pendant
      la durée du contrat de travail.
      <br />
      <br />
      Résiliation du contrat de travail avec le Groupe Ingka : Toutes les
      données personnelles sont automatiquement supprimées dans le mois suivant
      la suppression du profil CDS.
      <br />
      <br />
      Évolution de poste au sein du Groupe Ingka :Si un collaborateur n’a plus
      besoin d’utiliser Fixa, toutes les données personnelles seront
      automatiquement supprimées dans les trois mois suivant l’inactivité.
      <br />
      <br />
      Les données personnelles peuvent également être supprimées à tout moment
      sur demande auprès du responsable local de la sécurité des informations et
      de la confidentialité des données ou du directeur du magasin.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <p>Responsable du traitement des données personnelles :</p>
  ),
  dataControllerForPersonalDataText: (
    <p>IKEA Belgium NV/SA, Weiveldlaan 19, 1930 Zaventem.</p>
  ),
  rightToPersonalInformationText: (
    <div>
      Vous disposez de certains droits concernant vos données personnelles. Pour
      plus d’informations sur ces droits, veuillez consulter la page Internet
      suivante :{" "}
      <BlueLink href="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx">
        https://inside.ingka.com/&#8203;supportservices/&#8203;Dataprivacy/&#8203;Pages/&#8203;Individual-rights.aspx
      </BlueLink>
      <br />
      <br />
      Pour toute information sur vos droits ou question sur la confidentialité
      des données, veuillez contacter l’adresse suivante :
      <EmailLink email="privacy.belgium@ikea.com" />
    </div>
  ),
};
