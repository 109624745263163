import React from "react";
import styled, { keyframes } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 1.5rem;
`;

const ImageSkeleton = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: var(--grey150);
`;

const TextContainer = styled.div`
  flex-grow: 1;
  margin-top: 0.5rem;
  padding-right: 1rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const animatedWidth = keyframes`
  to {
    width: 100%;
  }
`;

const TextSkeleton = styled.div`
  width: ${(p: { width: number }) => `${p.width}%`};
  height: 0.8rem;
  background-color: var(--grey150);
  margin-bottom: 0.5rem;
  animation: ${animatedWidth} 1s ease-in-out infinite alternate;
`;

function ArticleCardSkeleton() {
  return (
    <Container>
      <ImageContainer>
        <ImageSkeleton />
      </ImageContainer>
      <TextContainer>
        <TextSkeleton width={50} />
        <TextSkeleton width={80} />
        <TextSkeleton width={30} />
      </TextContainer>
    </Container>
  );
}

export default React.memo(ArticleCardSkeleton);
