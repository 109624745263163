const closeTypeKeys = {
  INCORRECT: "closeIncorrectString",
  ALREADY_RESOLVED: "closeAlreadyResolvedString",
  NOT_IN_STOCK: "notInStockString",
  OTHER: "otherString",
};

export function getClosedTaskReason(type: string) {
  switch (type) {
    case "INCORRECT":
      return closeTypeKeys.INCORRECT;
    case "ALREADY_RESOLVED":
      return closeTypeKeys.ALREADY_RESOLVED;
    case "NOT_IN_STOCK":
      return closeTypeKeys.NOT_IN_STOCK;
    default:
      return closeTypeKeys.OTHER;
  }
}
