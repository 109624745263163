import React from "react";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import { useDebouncedFunc } from "../../hooks/useDebounce";
import tracker from "../../helpers/tracker";

/**
 * Takes video stream object and provides its constraints and a function to update them.
 * @param {MediaStream} videoStream
 * @returns {[
 *  constraints: MediaTrackConstraints,
 *  setConstraints: ({torch: boolean, zoom: number}) => Promise<MediaTrackConstraints
 * ]}
 */
export function useCameraConstraints(videoStream) {
  const [track] = videoStream?.getVideoTracks() ?? [];
  const capabilities = track?.getCapabilities();
  const [currentConstraints, setCurrentConstraints] = React.useState(
    track?.getConstraints()
  );

  const setConstraints = React.useCallback(
    async ({ torch, zoom }) => {
      const constraints = { advanced: [] };
      if (torch != null && capabilities?.torch) {
        constraints.advanced.push({ torch });
      }
      if (zoom != null && capabilities?.zoom) {
        constraints.advanced.push({ zoom });
      }
      if (track?.readyState === "live" && constraints.advanced.length > 0) {
        try {
          await track.applyConstraints(constraints);
          const newConstraints = track.getConstraints();
          setCurrentConstraints(newConstraints);
          return newConstraints;
        } catch (e) {
          return currentConstraints;
        }
      } else {
        return currentConstraints;
      }
    },
    [capabilities, currentConstraints, track]
  );

  return [currentConstraints, setConstraints];
}

/**
 * Checks whether the torch is turned on, returns false if torch is unavailable.
 * @param {MediaTrackConstraints} capabilities
 */
export function isTorchOn(capabilities) {
  const torchConstraint = capabilities?.advanced?.find?.(
    (value) => value.torch != null
  );
  return torchConstraint?.torch ?? false;
}

/**
 * Provides state for zoom level and a function to update it.
 * The updater also makes a debounced call to update the profile preference
 * @returns {[number, (newZoom: number) => void]}
 */
export function useScale() {
  const [scaleMultiplierPreference, setScaleMultiplierPreference] =
    useUserPreference(profilePreferences.SCANNER_SCALE_FACTOR, 1.2);
  const [scaleMultiplier, setScaleMultiplier] = React.useState(
    scaleMultiplierPreference
  );

  const setScaleMultiplierPreferenceDebounced = useDebouncedFunc(
    setScaleMultiplierPreference,
    1000
  );
  const zoomTrackerDebounced = useDebouncedFunc(
    tracker.barcode.logZoomUsage,
    1000
  );
  const setScaleMultiplierDebounced = (multiplier) => {
    setScaleMultiplier(multiplier);
    setScaleMultiplierPreferenceDebounced(multiplier);
    zoomTrackerDebounced(multiplier);
  };

  return [scaleMultiplier, setScaleMultiplierDebounced];
}
