import React from "react";
import styled from "styled-components";
import { Display3Tasks } from "./TaskListDisplay";
import { getFilterFunction } from "../helpers/taskFilters";
import { sortTasks } from "@coworker/reusable/helpers/taskSorters";
import NotificationCounterLink from "./NotificationCounterLink";
import { Link } from "./Link";
import { wrap } from "../helpers/mapHelpers";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { useTasksFromAnySource } from "./Tasks";
import { useTeamId, useUID, useFixaUID } from "../core/auth/useLoggedInUser";

const Loader = styled(LoaderIcon)`
  width: 30px;
  height: 30px;
  padding-left: 30px;
`;

const CardStack = styled.div`
  margin-top: 10px;
`;

const isClosed = (filter) =>
  [
    "closed",
    "created_by_me_closed",
    "completed",
    "created_by_me_completed",
  ].includes(filter);

export function useTaskSectionTasks(screenKey) {
  const uid = useUID();
  const team_id = useTeamId();
  const fixa_uid = useFixaUID();
  const filters = React.useMemo(
    () => [wrap(getFilterFunction(screenKey), { uid, team_id, fixa_uid })],
    [screenKey, uid, team_id, fixa_uid]
  );
  const [tasks, loading, hasMore] = useTasksFromAnySource(screenKey, filters);

  return React.useMemo(() => {
    sortTasks(tasks, screenKey);
    return [tasks.slice(0, 3), tasks.length, tasks, loading, hasMore];
  }, [screenKey, tasks, loading, hasMore]);
}

export function TasksSection({ filter, title, link }) {
  const [top3tasks, number, , loading, hasMore] = useTaskSectionTasks(filter);
  return (
    <CardStack data-testid={`${filter}-container`}>
      <NotificationCounterLink
        isNotificationCounter={filter === "notifications"}
        separatorBottomLine
        number={number}
        hasMoreThanNumber={hasMore}
        title={title}
        to={link}
        link={Link}
        testId={filter}
      />
      {isClosed(filter) && loading ? (
        <Loader />
      ) : (
        <Display3Tasks tasks={top3tasks} />
      )}
    </CardStack>
  );
}
