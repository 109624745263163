import React from "react";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";

import NotificationsIcon from "@ingka/ssr-icon/paths/bell";

import styles from "./notifications-link.module.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";

import { useFetchNotifications } from "@coworker/app/src/hooks/notificationHooks/useFetchNotifications";
import Button from "@ingka/button";
import { useTranslation } from "react-i18next";

const NotificationsLink = () => {
  const { data: events } = useFetchNotifications();
  const { t } = useTranslation();
  const number =
    events && events.filter((a: any) => a.is_read === false).length;
  const { push } = useWorkspacesAction();

  const handleNotificationNavigation = () => {
    push(`/tasks/activity/notifications`);
    trackerHelper.trackNotificationButtonClick();
  };
  return (
    <div className={styles["notifications-link"]}>
      <div className={styles["bell-wrapper"]}>
        <Button
          aria-label={t("notificationsString")}
          type="tertiary"
          onClick={handleNotificationNavigation}
          inverseTheme={true}
          iconOnly={true}
          ssrIcon={NotificationsIcon}
        />
        {number > 0 && <div className={styles["counter"]}>{number}</div>}
      </div>
    </div>
  );
};

export default NotificationsLink;
