import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "../Atoms/Text";
import { RoomsAndArticleCountResponse } from "../types/room";
import { getPiecesTranslation } from "../tools";

interface PlacementsTextProps {
  currentArticleId: string;
  roomsArticleCount: RoomsAndArticleCountResponse[];
}

export function PlacementsText({
  currentArticleId,
  roomsArticleCount,
}: PlacementsTextProps) {
  const { t } = useTranslation();

  return (
    <>
      {roomsArticleCount &&
        roomsArticleCount.map((room) => {
          const piecesOrPiece = t(getPiecesTranslation(room.nbr_articles));
          return room.article_id === currentArticleId ? (
            <Text
              key={`selected-${room.article_id}`}
              text={`${room.name}: ${room.nbr_articles} ${piecesOrPiece} ${t(
                "selectedString"
              )}`}
              bold
              grey
            />
          ) : (
            <Text
              key={room.article_id}
              text={`${room.name}: ${room.nbr_articles} ${piecesOrPiece}`}
              grey
            />
          );
        })}
    </>
  );
}
