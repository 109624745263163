import React from "react";
import { useTasksByStore } from "../hooks/useTasksByStore";
import {
  useStoreId,
  useTeamId,
  useUserId,
} from "../../../core/auth/useLoggedInUser";
import { Trans, useTranslation } from "@coworker/locales";
import { GreyStrip } from "../Atoms/GreyStrip";
import { ListWrapper } from "../styles/styles";
import { TaskListingFilter } from "../Molecules/TaskListingFilter";
import { RoomSettings } from "@coworker/types/lib/tasks/room_settings";
import { TaskOwner } from "../constants";
import { filterRoomSettingsListFunction } from "../tools";
import EmptyScreen from "../../EmptyScreen";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useRoomsByStoreId } from "../hooks/useRoomsByStoreId";
import { TaskCard } from "@coworker/apprestructured/src/tasks/components/TaskCard/TaskCard";

export function OngoingTasksListingView() {
  const { t } = useTranslation();
  const storeId = useStoreId();
  const fixaUid = useUserId();
  const teamId = useTeamId();
  const tasks = useTasksByStore(storeId);
  const { data: rooms } = useRoomsByStoreId(storeId);

  const [taskOwner, setTaskOwner] = React.useState<TaskOwner>(TaskOwner.Mine);

  const filteredTaskList = filterRoomSettingsListFunction(
    tasks.tasks,
    taskOwner,
    fixaUid,
    teamId
  );

  const getRoomName = (roomId: string) => {
    const room = rooms?.find((room) => room.id === roomId);
    return room?.name || "";
  };
  const roomIds = [
    ...new Set(
      filteredTaskList
        .sort((t1, t2) =>
          getRoomName(t1.room_id).localeCompare(getRoomName(t2.room_id))
        )
        .map((task) => task.room_id)
    ),
  ];

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("ongoingTasksString"),
        actions: [],
      }}
      noPadding
    >
      <TaskListingFilter setTaskOwner={setTaskOwner} />
      {roomIds.map((roomId: string) => (
        <ListWrapper key={roomId}>
          <GreyStrip text={getRoomName(roomId)} />
          {filteredTaskList
            .filter((roomSettingsTask: RoomSettings) => {
              return roomSettingsTask.room_id === roomId;
            })
            .map((roomTask: RoomSettings) => {
              return <TaskCard key={roomTask.id} task={roomTask} />;
            })}
        </ListWrapper>
      ))}
      {!filteredTaskList.length && (
        <EmptyScreen
          subTitle={<Trans>nothingString</Trans>}
          title={<Trans>noTasksFiltersString</Trans>}
          hideButton={true}
        />
      )}
    </FullScreenPopup>
  );
}
