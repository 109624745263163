import React from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import { groupByTypes, translationSwitchEnum } from "../constants/groupBy";

const GroupByTitle = styled.div`
  background-color: var(--grey100);
  height: 40px;
`;

const GroupTitleText = styled.h4`
  padding: 1rem 0 0.5rem 1.5rem;
`;

const GroupByTitleTask = ({ style, header }) => {
  const { t } = useTranslation();
  const { groupByTitle, numberOfTaskInGroup, groupByOption } = header;

  const translation =
    (groupByOption !== groupByTypes.BY_GRID_CODE ||
      groupByTitle === groupByTypes.NO_GROUPING) &&
    groupByTitle in translationSwitchEnum
      ? t(translationSwitchEnum[groupByTitle])
      : groupByTitle;

  const mainHeaderTitle =
    translation.charAt(0).toUpperCase() + translation.slice(1);
  const title =
    groupByOption === groupByTypes.BY_TASK_TYPE
      ? `${numberOfTaskInGroup} ${mainHeaderTitle} ${
          numberOfTaskInGroup > 1
            ? t("TasksString").toLowerCase()
            : t("TaskString").toLowerCase()
        }`
      : `${mainHeaderTitle}`;

  return (
    <GroupByTitle style={{ ...style }}>
      <GroupTitleText>
        {groupByTitle === groupByTypes.NO_GROUPING ? `(${title})` : title}
      </GroupTitleText>
    </GroupByTitle>
  );
};

export default GroupByTitleTask;
