import { Button, Icon } from "@coworker/components";
import React from "react";
import {
  getValidCode,
  getSupplierNumber,
} from "../../helpers/barcodeValidator";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import trackerHelper from "../../helpers/tracker";
import { isRDTDevice11 } from "@coworker/reusable";
import { HiddenInput } from "./RDTScannerHiddenInput";

const {
  barcode: { logScannerType },
} = trackerHelper;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReadyToScan = styled.div`
  text-align: center;
`;

const Text = styled.h2`
  color: black;
  font-size: 18px;
`;

const Container = styled.div`
  background: white;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
  ${({ bottomToolbar }) => bottomToolbar && `border-bottom: 90px solid black;`}
`;

const Spacer = styled.div`
  height: 5px;
`;

export default function RDTScanner({
  switchToCameraScanner,
  onBarcode,
  hasBottomToolbar = false,
}) {
  const isNewRDT = isRDTDevice11();

  const onMessageListener = React.useCallback(
    function onMessageListener(event) {
      const uri = event.data || "";
      const code = uri.split && uri.split("honeywellbarcode://")[1];
      if (!code?.length) return console.log("no code", uri);
      const short_id = code && getValidCode(code, code.length === 12);
      if (short_id) {
        const supplierNumber = getSupplierNumber(code);
        trackerHelper.barcode.logRDTCaptured(code, short_id);
        onBarcode(short_id, undefined, undefined, supplierNumber);
        logScannerType("RDT_SCANNER", code);
      } else if (code.length) {
        logScannerType("RDT_SCANNER_FAIL", code);
      }
    },
    [onBarcode]
  );

  React.useEffect(() => {
    window.addEventListener("message", onMessageListener, false);
    return () =>
      window.removeEventListener("message", onMessageListener, false);
  }, [onMessageListener]);

  const [scannerText, setScannerText] = React.useState("");
  React.useEffect(() => {
    if (isNewRDT) {
      const shortId = scannerText.substring(0, 8);
      const supplierNumber = scannerText.substring(8);

      if (shortId?.length === 8) {
        trackerHelper.barcode.logRDTCaptured(scannerText, shortId);
        onBarcode(shortId, undefined, undefined, supplierNumber);
        setScannerText("");
        logScannerType("RDT_SCANNER", shortId);
      }
    }
    // Disabling this ONLY for onBarcode
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannerText]);

  return (
    <Container bottomToolbar={hasBottomToolbar}>
      <Spacer />
      <ReadyToScan>
        <Icon family="icons" name="rdt_scanner" color="grey900" size="64" />
        <Text>
          <Trans>readyToScanString</Trans>
        </Text>
      </ReadyToScan>
      <ButtonContainer>
        <Button
          primary
          dark
          text={<Icon family="icons" name="camera" color="white" />}
          onClick={switchToCameraScanner}
          flexNoGrow
        />
        <Button
          primary
          dark
          data-testid="pushToScan"
          flexGrow
          text={
            isRDTDevice11() ? (
              <Trans>pleaseUseSideButtonScanString</Trans>
            ) : (
              <Trans>scanString</Trans>
            )
          }
          disabled
        />
        {!isRDTDevice11() && (
          <Button
            primary
            dark
            flexNoGrow
            disabled
            text={<Icon family="actions" name="torch-off" color="grey500" />}
          />
        )}
      </ButtonContainer>
      <HiddenInput setScannerText={setScannerText} />
    </Container>
  );
}
