import React, { useState } from "react";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useTasksByStore } from "../hooks/useTasksByStore";
import useFlag, { FLAGS } from "../../../hooks/useFlag";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { TwoLinedGreyBox } from "../Molecules/TwoLinedGreyBox";
import { RoomsSearchBarCombinedSearch } from "../Molecules/RoomsSearchBarCombinedSearch";
import { RoomsViewSkeleton } from "../Skeletons/RoomsViewSkeleton";
import { TitleHeader } from "../../TestBuy/CommonComponents/TitleHeader/TitleHeader";
import { useAlertsByStore } from "../hooks/useAlertsByStore";
import { useRoomsByStoreId } from "../hooks/useRoomsByStoreId";
import { useActiveRoomsByStoreId } from "../hooks/useActiveRoomsByStoreId";
import { RoomsViewRoomsList } from "../Organisms/RoomsViewRoomsList";
import { RoomsViewSearchList } from "../Organisms/RoomsViewSearchList";
import { RoomsCountText } from "../Atoms/RoomsAmountText";
import { MessageAndButton } from "../Molecules/MessageAndButton";

export function RoomsViewCombinedSearch() {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const storeId = useStoreId();
  const { data: rooms, isLoading: isRoomsLoading } = useRoomsByStoreId(storeId);
  const { activeRooms, isLoading: isActiveRoomsLoading } =
    useActiveRoomsByStoreId(storeId);
  const { data: alerts } = useAlertsByStore(storeId);

  const tasks = useTasksByStore(storeId);
  const hideForGermanyUsers = useFlag(FLAGS.GERMANY_ROLLOUT);

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const handleSearchResults = (query: string | undefined) => {
    setSearchQuery(query);
  };

  if (hideForGermanyUsers) {
    return (
      <>
        <TitleHeader title={t("roomSettingsString")} />
        <RoomsViewSkeleton />
      </>
    );
  }

  if (isRoomsLoading || isActiveRoomsLoading) {
    return (
      <>
        <TitleHeader
          title={t("roomSettingsString")}
          showSearch={false}
          onBack={() => {
            push("/");
          }}
        />

        <RoomsViewSkeleton />
      </>
    );
  }

  // No active room
  if ((rooms?.length ?? 0) > 0 && (activeRooms?.length ?? 0) === 0) {
    return (
      <MessageAndButton
        keyForMessageText={"allRoomsInactiveString"}
        keyForButtonText={"gotoAdminString"}
        buttonAction={async () => {
          window.open("https://fixa-admin.ingka.com/roomsettings");
        }}
      />
    );
  }

  // No rooms
  if ((rooms?.length ?? 0) === 0) {
    return (
      <MessageAndButton
        keyForMessageText={"noRoomsCreatedString"}
        keyForButtonText={"gotoAdminString"}
        buttonAction={async () => {
          window.open("https://fixa-admin.ingka.com/roomsettings");
        }}
      />
    );
  }

  return (
    <>
      <TitleHeader
        title={t("roomSettingsString")}
        showSearch={false}
        onBack={() => {
          push("/");
        }}
      />
      <TwoLinedGreyBox
        tabIndex={0}
        role="button"
        ariaLabel={t("ongoingTasksAriaLabelString", { count: tasks.count })}
        headLine={t("ongoingTasksString")}
        bottomleftText={`${tasks.count}`}
        rightComponent={<RightArrow />}
        onClick={() => {
          push(`/roomsettings/tasks`);
        }}
      />
      <RoomsSearchBarCombinedSearch onSearch={handleSearchResults} />

      {searchQuery ? (
        <RoomsViewSearchList
          activeRooms={activeRooms}
          query={searchQuery}
          taskLinks={tasks.taskLinks}
          alerts={alerts}
          storeId={storeId}
        />
      ) : (
        <>
          <RoomsCountText roomSettings={activeRooms?.length || 0} />
          <RoomsViewRoomsList
            activeRooms={activeRooms}
            taskLinks={tasks.taskLinks}
            alerts={alerts}
          />
        </>
      )}
    </>
  );
}
