import React from "react";
import { ShoppingToolsQuantityStepper } from "../Components/ShoppingToolsQuantityStepper";
import SSRIcon from "@ingka/ssr-icon";
import { Trans } from "@coworker/locales";
import { NO_TOOL, ShoppingToolType, TOTAL_MAX_VALUE } from "../constants";
import { CardWrapper, SubTitle } from "../Showroom/ShowroomQuantityCard";
import { ToolsCount } from "../Types";
import { isMaxToolsReachedCashline, hasNoTools } from "../helper";

interface CashlineQuantityCardProps {
  type: ShoppingToolType;
  lastShoppingTools: ToolsCount;
  setLastShoppingTools: React.Dispatch<React.SetStateAction<ToolsCount>>;
}

export const CashlineQuantityCard = ({
  type,
  lastShoppingTools,
  setLastShoppingTools,
}: CashlineQuantityCardProps) => {
  const currentToolValue = lastShoppingTools[type.name as string] ?? 0;
  const isNoTool = type.name === NO_TOOL;

  const handleIncrease = () => {
    let updatedTools = { ...lastShoppingTools } as ToolsCount;
    if (isNoTool) {
      updatedTools = { no_tool: 1 };
    } else if (isMaxToolsReachedCashline(updatedTools, 10)) {
      updatedTools[type.name] = currentToolValue + 1;
    }
    setLastShoppingTools(updatedTools);
  };

  const handleDecrease = () => {
    const updatedTools = { ...lastShoppingTools };
    updatedTools[type.name] = currentToolValue - 1;
    setLastShoppingTools(updatedTools);
  };

  return (
    <CardWrapper key={type.label}>
      <ShoppingToolsQuantityStepper
        value={currentToolValue}
        small
        minVal={0}
        maxVal={TOTAL_MAX_VALUE}
        ariaDescribedById="unique-dom-id"
        ariaDescribedBy="Type in a number or use arrow up or arrow down to change the quantity"
        onIncrease={handleIncrease}
        onDecrease={handleDecrease}
        isDisabled={hasNoTools(lastShoppingTools, type)}
      />
      <SSRIcon paths={type.icon!} style={{ margin: "0px 10px" }} />
      <SubTitle style={{ margin: !type.icon ? "0px 10px" : 0 }}>
        <Trans>{type.label}</Trans>
      </SubTitle>
    </CardWrapper>
  );
};
