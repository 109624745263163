import { isRDTDevice } from "./devices";

/**
 * This is an approximate font size calculation that works also in cases when the situation (or device) cannot do the render-then-measure-then-adjust
 * @param textLength lenght of the text shown, so "SEK123,456" has length 10
 * @returns A css clamp rule with a suitable size.
 */
export function calculateFontSize(
  textLength = 8,
  min = 14,
  max = 28,
  baseSize = isRDTDevice() ? 14 : 17
) {
  // The baseSize 17 means a max size of 17vw or 17% of screen width for an empty string.
  const offset = textLength >= baseSize ? baseSize - 2 : textLength;
  const fontSize = baseSize - offset;
  // Limit the size between 14 and 28px. Within this bound we scale depending on the longest text length.
  // The max 28px is from the design specs. The min 14px is the biggest number that still allows big RUB or JPY numbers to show without breaking on RDT.
  return `clamp(${min}px,${fontSize}vw,${max}px)`;
}

export const sign = (n?: number | undefined) => (!n ? 0 : n < 0 ? -1 : 1);
