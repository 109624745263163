import React from "react";
import TaskFormBoolean from "./boolean";
import { ReactComponent as PriorityIcon } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { Trans } from "@coworker/locales";

interface ConfigWidgetProps {
  title: JSX.Element;
  icon: JSX.Element;
  testId: string;
  overridePadding: string;
  titleMargin: string;
}

export interface TaskPriorityWidgetProps {
  isPriority: boolean;
  togglePriority: Function;
}

function TaskPriorityWidget({
  isPriority,
  togglePriority,
}: TaskPriorityWidgetProps) {
  const config: ConfigWidgetProps = {
    icon: <PriorityIcon />,
    overridePadding: "0 24px",
    title: <Trans>priorityString</Trans>,
    testId: "priorityCheckbox",
    titleMargin: "14px",
  };
  return (
    <TaskFormBoolean
      value={isPriority}
      onChange={togglePriority}
      conf={config}
    />
  );
}

export default React.memo(TaskPriorityWidget);
