import { ListViewItem } from "@ingka/list-view";
import { TeamMembersListView } from "../TeamMembersListView/TeamMembersListView";
import React from "react";
import { Team } from "../../../../../shared/types/team";
import "./teams-list-view-item.css";
import { TeamsListViewItemIcon } from "../TeamsListViewItemIcon/TeamsListViewItemIcon";

interface TeamsListViewItemProps {
  team: Team;
  index: number;
  teamMembers: any;
  handleTeamSelection: (team: Team) => void;
  selectedTeamId: string;
}

const getTeamMembersDescription = (teamMembersCount: number) => {
  return `${teamMembersCount} team member${teamMembersCount > 1 ? "s" : ""}`;
};

export const TeamsListViewItem: React.FC<TeamsListViewItemProps> = ({
  team,
  teamMembers,
  handleTeamSelection,
  index,
  selectedTeamId,
}) => {
  const [activeIndex, setActiveIndex] = React.useState<number>(-1);
  const teamMembersCount = teamMembers?.length;
  const isOpen = activeIndex === index;
  const description = getTeamMembersDescription(teamMembersCount);

  const toggleTeamMembers = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  return (
    <div>
      <div className="teams-list-view-item-group">
        <TeamsListViewItemIcon
          id={team.id}
          toggleTeamMembers={toggleTeamMembers}
          index={index}
          isOpen={isOpen}
        ></TeamsListViewItemIcon>
        <ListViewItem
          key={team.id}
          className="teams-list-view-item"
          id={team.id}
          name={team.id}
          control="radiobutton"
          controlProps={{
            checked: team.id === selectedTeamId,
            value: team.id,
          }}
          onChange={() => {
            handleTeamSelection(team);
          }}
          controlIcon={null}
          inset
          title={team.name}
          description={description}
        ></ListViewItem>
      </div>
      <TeamMembersListView
        id={`team-members-list-view-${team.id}`}
        teamMembers={teamMembers}
        isOpen={isOpen}
      ></TeamMembersListView>
    </div>
  );
};
