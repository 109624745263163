import React from "react";
import useCallInternal, { PRODUCT_MAPPER } from "./API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { create } from "zustand";
import { cleanupProductTitle } from "@coworker/reusable";
import { parseProduct } from "../services/products.service";
import { useMyStore } from "../hooks/useMyStore";
import { callInternalApi } from "./API/useCallInternal";

const useProductsStore = create((set, get) => ({ set, get }));

export function useProductsMap(shortIdsList = []) {
  const { set, get, ...stored } = useProductsStore();
  const fetchTheseIds = shortIdsList.filter((id) => !stored[id]?.name);
  const ids = fetchTheseIds.join(",");
  const [dataFromService, setDataFromService] = React.useState({});
  const primary_locale = useMyStore()?.configuration?.locale?.primary_locale;
  const callTasksServiceFlag = window.callTasksService;

  const store = useStoreId();
  const { data, loading, error } = useCallInternal(
    PRODUCT_MAPPER,
    { store, ids },
    { blockRequest: !ids || !store || callTasksServiceFlag }
  );

  const productsBasicsObj = {};
  React.useEffect(() => {
    if (callTasksServiceFlag && fetchTheseIds.length > 0) {
      callInternalApi(
        `products_basics?short_ids=${fetchTheseIds}&locale=${primary_locale}`
      ).then((response) => {
        response.forEach((basicProduct) => {
          productsBasicsObj[basicProduct.id] = basicProduct;
        });
        setDataFromService(productsBasicsObj);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fetchTheseIds), primary_locale, callTasksServiceFlag]);

  React.useEffect(() => {
    const finalData = callTasksServiceFlag ? dataFromService : data;
    if (!loading && finalData && !error) set(finalData);
  }, [loading, data, set, error, dataFromService, callTasksServiceFlag]);

  return stored;
}

export function useCleanProductTitle(someId, override) {
  const [shortId] = parseProduct(someId);
  const all = useProductsStore();
  const name = (override !== "?" && override) || all[shortId]?.name || "";
  useProductsMap(name ? [] : [shortId]);
  return cleanupProductTitle(name);
}
