import { questionTypes } from "../constants/feedbackQuestions";

export const makeFeedbackQuestions = () => [
  {
    id: "NPS-first",
    type: questionTypes.nps,
    title: "helpMakeFixaYouLoveString",
    question: "Would you recommend Fixa to other co-workers?",
    paragraphs: [
      "sharingYourThoughtsString",
      "feedbackIsCompletelyOptionalString",
    ],
    starts_at: 1,
    ends_at: 1,
  },
  {
    id: "Efficiency",
    type: questionTypes.efficiency,
    question: "Does Fixa help you work more efficiently?",
    paragraphs: ["areWeCreatingValueString"],
    textAreaPlaceholder: "efficiencyFeedbackReasonScoreOptionalString",
    options: {
      min: 1,
      max: 4,
      value: 1,
      leftLabel: "oneNotAtAllString",
      rightLabel: "fourVeryMuchString",
    },
    starts_at: 1604331834622,
    ends_at: 1697096657376,
  },
  {
    id: "Recommend",
    type: questionTypes.recommend,
    question: "Would you recommend Fixa to other co-workers?",
    starts_at: 1604331834622,
    ends_at: 1697096657376,
  },
];
