import React from "react";
import { useParams } from "react-router-dom";
import Tasks from "./Tasks";

function RelatedTasks() {
  const { id } = useParams();
  return <Tasks type="related_product_tasks" productIdFilter={id} />;
}

export default RelatedTasks;
