import { Criterion } from "@coworker/types";
import {
  TESTBUY_UPDATE_CRITERION,
  callInternal,
} from "../../../../hooks/API/useCallInternal";

export async function createOrUpdateCriterion(
  testbuyKey: string,
  testbuyArticleKey: string,
  criterion: Criterion
) {
  const result = await callInternal(TESTBUY_UPDATE_CRITERION, {
    criterion: criterion,
    testbuyKey,
    testbuyArticleKey,
  });
  return result.data;
}
