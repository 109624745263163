import React from "react";
import { Trans } from "@coworker/locales";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import {
  LOCATION_TYPE_PICKUP,
  LOCATION_TYPE_REFILL,
} from "../../../constants/locationWidgetTypes";
import { CompactIconWrapper } from "./description";
import { ReactComponent as AssignIcon } from "../../../assets/svg/test-buy-location.svg";

function Location({ task, conf }) {
  const isPickup = conf?.addon === LOCATION_TYPE_PICKUP;
  const pickupLocation = task?.pickup_location_custom || task?.pickup_location;
  const location = task?.location_custom || task?.location;
  const activeLocation = isPickup ? pickupLocation : location;

  if ((isPickup && !pickupLocation) || !(location || pickupLocation)) {
    return null;
  }

  const isRefill = conf?.addon === LOCATION_TYPE_REFILL;

  return (
    <DetailSection data-testid={`${conf?.addon}Location`}>
      {conf.shouldShowIcon && (
        <CompactIconWrapper>
          <AssignIcon />
        </CompactIconWrapper>
      )}
      <DetailSectionLeft>
        <SectionTitle>
          {(isPickup && <Trans>pickingLocationString</Trans>) ||
            (isRefill && <Trans>refillingLocationString</Trans>) || (
              <Trans>location3String</Trans>
            )}
        </SectionTitle>
        <SectionText>{activeLocation}</SectionText>
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default Location;
