import React from "react";
import styled from "styled-components";
import { TasksTodos } from "./TasksTodos";
import { useTranslation } from "@coworker/locales";
import { TaskTabsTypes } from "../../../shared/constants/taskTabsTypes";
import { useGetTasksForPreview } from "../../hooks/useGetTasksForPreview";
import { TaskPreviewItem } from "../../types/taskPreviewType";

const TasksTodosOuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px;
  padding-top: 16px;
  padding-right: 16px;
  > * {
    width: 100%;
  }
  @media (min-width: 1440px) {
    padding-top: 32px;
    gap: -16px;
    > * {
      width: 49%;
    }
  }
  margin-bottom: 16px;
`;

export const TasksPreview = () => {
  const { t } = useTranslation();

  const tasksPreview = useGetTasksForPreview();

  const openTasks = tasksPreview[TaskTabsTypes.OPEN];
  const myTasks = tasksPreview[TaskTabsTypes.MY];

  const hideViewOpenTasksButton =
    openTasks?.count === 0 && myTasks?.count === 0;

  return (
    <TasksTodosOuterContainer>
      <TasksTodos
        title={t("myString")}
        tabType={TaskTabsTypes.MY}
        tasksCount={myTasks?.count as number}
        hideViewButton={hideViewOpenTasksButton}
        tasksList={myTasks?.mappedTasksList as TaskPreviewItem[]}
        isLoading={myTasks?.isLoading as boolean}
      ></TasksTodos>
      <TasksTodos
        title={t("openTasksString")}
        tabType={TaskTabsTypes.OPEN}
        tasksCount={openTasks?.count as number}
        tasksList={openTasks?.mappedTasksList as TaskPreviewItem[]}
        isLoading={openTasks?.isLoading as boolean}
      ></TasksTodos>
    </TasksTodosOuterContainer>
  );
};
