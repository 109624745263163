export const fixaColorScheme = `:root {
  --yellow: #FFCD3F;
  --blue: #151E44;
  --darkblue: #111836;
  --blue-medium: #0058A3;
  --related-blue: #007aff;
  --blue-family: #0077c1;
  --light-blue: #4595ff;
  --blue-lighter: #0058A31A;
  --green: #0A8A00;
  --green-darker: #087500;
  --green-lighter: #0A8A001A;
  --pink: #E00751;
  --orange: #FFA524;

  --white: #ffffff;
  --black: #000000;
  --grey900: #111111;
  --grey800: #333333;
  --grey718: #484848;
  --grey700: #666666;
  --grey600: #767676;
  --grey500: #959595;
  --grey300: #c4c4c4;
  --grey200: #DFDFDF;
  --grey150: #F2F2F2;
  --grey100: #F5F5F5;
  --grey50: #FAFAFA;

  --success: #0A8A00;
  --warning: #FFA524;
  --error: #E00751;
  --new: #0058A3;
  --disabled: #929292;

  --in-stock: #009669;
  --bti-yellow: #ffd800;
  --bti-red: #ec012a;
  --white-ice: #cfdcef;

  --z-addonlocation: 1;
  --z-withinput: 0;

  --z-heading-menu: 1;

  --z-insights-1: 1;
  --z-insights-2: 2;

  --z-heading-follower: 2;
  --z-imageslider: 2;
  --z-popupmenu: 2;
  --z-previewimage: 2;
  --z-toast-2: 2;

  --z-dropdownmenu: 4;
  --z-multiselectcheckbox:4;
  --z-oneup: 100;
  --z-popup: 500;
  --z-popover: 600;
  --z-portal-container: 2;
  --z-toast: 700;
  --z-tooltip: 800;

  --height-header: 70px;
}`;
