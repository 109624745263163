import React from "react";
import styles from "./task-card.module.css";
import { CardMode } from "./types";
import { CARD_MODE } from "./constants";
import { TaskType } from "../../enums/taskTypes";

interface AddonSubtitleProps {
  cardMode: CardMode;
  type: string;
  subtitle: string;
}

export const AddonSubtitle = ({
  cardMode,
  type,
  subtitle,
}: AddonSubtitleProps) => {
  const isAddonTask = type === TaskType.ADDON;
  return (
    <>
      {cardMode !== CARD_MODE.SIMPLE && isAddonTask && (
        <div className={styles["subtitle"]}>{subtitle}</div>
      )}
    </>
  );
};
