import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { updateUser } from "../services/user.service";
import CryptoJS from "crypto-js";
import { useUserId } from "../core/auth/useLoggedInUser";

const FOUR_DIGIT_REGEX = RegExp(/^[0-9]{4}$/);
export const validPinFormat = (code) => FOUR_DIGIT_REGEX.test(code);

const KEY_ACCEPTED_TOKEN = "fixa_pin_token";
const KEY_REQUESTED_TOKEN = "fixa_pin_token_requested";
export const setRequestedToken = (token) =>
  localStorage.setItem(KEY_REQUESTED_TOKEN, token);
const setAcceptedToken = (token) =>
  localStorage.setItem(KEY_ACCEPTED_TOKEN, token);

export const acceptToken = () => {
  const requestedToken = localStorage.getItem(KEY_REQUESTED_TOKEN);
  setAcceptedToken(requestedToken);
};
export const gotToken = (token) => {
  // For old wrapper. To be removed once 1.0.11 is fully deployed.
  if (token === true) return setRequestedToken(`${Date.now()}${Math.random()}`);
  else if (token) return setRequestedToken(token);
};

export const tokenChanged = () => {
  const acceptedToken = localStorage.getItem(KEY_ACCEPTED_TOKEN);
  const requestedToken = localStorage.getItem(KEY_REQUESTED_TOKEN);
  return requestedToken && requestedToken !== acceptedToken;
};

const hashWithSaltHmacSha256 = (salt, code) => {
  const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, salt);
  hmac.update(code);
  const hash = hmac.finalize();
  return hash.toString(CryptoJS.enc.Hex);
};

export function usePinLock() {
  const uid = useUserId();
  const { storedPinHash, resetWasRequested, storedPinSalt, userUid } =
    useSelector(
      (state) => ({
        storedPinHash: state.firebase.profile.pinHash,
        resetWasRequested: !!state.firebase.profile.pinResetRequested,
        storedPinSalt: state.firebase.profile.pinSalt,
        userUid: state.firebase.profile.uid,
      }),
      shallowEqual
    );

  const storePin = (code) => {
    const bytes = new Uint8Array(25); // 200 random bits
    window.crypto.getRandomValues(bytes);
    const pinSalt = byteArrayToHex(bytes);
    const pinHash = hashWithSaltHmacSha256(pinSalt, code);
    return updateUser(uid, {
      pinHash,
      pinSalt,
      pinResetRequested: "",
    });
  };

  const updateResetRequest = React.useCallback(
    (value) =>
      userUid &&
      updateUser(uid, {
        pinResetRequested: value || "",
      }),
    [userUid, uid]
  );

  const wellFormatted =
    storedPinSalt?.length === 50 && storedPinHash?.length === 64;

  const verifyPin = React.useCallback(
    (code) =>
      wellFormatted && // verify lengths before hashing
      hashWithSaltHmacSha256(storedPinSalt, code) === storedPinHash,
    [storedPinSalt, storedPinHash, wellFormatted]
  );

  return {
    hasPin: wellFormatted,
    resetWasRequested,
    storePin,
    updateResetRequest,
    verifyPin,
  };
}

export function byteArrayToHex(bytes) {
  return [...bytes].map((byte) => byte.toString(16).padStart(2, "0")).join("");
}
