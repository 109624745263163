import React from "react";
import styled from "styled-components";
import { useTelephoneLinker } from "../hooks/useTelephoneLinker";
import { useTranslation } from "@coworker/locales";
import {
  useStoreContactsEnabled,
  useStoreContacts,
  useStoreUsers,
} from "../hooks/useStoreContacts";
import { HeaderSearchInput } from "../features/searching/HeaderSearchInput";
import { useFocus } from "../hooks/useFocus";
import { filterContactFromSearch } from "../helpers/filters";
import { useStoreTeams } from "../core/hooks/useStoreTeams";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import SSRIcon from "@ingka/ssr-icon";
import SearchIcon from "@ingka/ssr-icon/paths/magnifying-glass";

const TeamName = styled.div`
  font-size: 14px;
  font-weight: bold;
  background-color: var(--grey100);
  padding: 15px 0px 14px 28px;
`;

const ContactField = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--grey700);
  font-size: 14px;
  padding: 26px 28px;
  border-bottom: 1px solid var(--grey150);
`;

const Name = styled.div`
  margin-right: 10px;
`;

const Number = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  flex-shrink: 0;
  white-space: nowrap;
`;

const StoreContacts = () => {
  const storeContactsEnabled = useStoreContactsEnabled();
  const contacts = useStoreContacts();
  const users = useStoreUsers();
  const teams = useStoreTeams();
  const makePhoneLink = useTelephoneLinker();
  const [showSearch, setShowSearch] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const { t } = useTranslation();
  const { inputRef } = useFocus();

  if (!storeContactsEnabled || !(teams && contacts && users)) {
    return null;
  }

  const data = []
    .concat(teams)
    .filter(({ name }) => name)
    .sort(sortFunction)
    .map(({ id, name }) => {
      // find contacts and users
      const foundContacts = contacts.filter(({ team_id }) => team_id === id);
      const foundUsers = users.filter(({ team_id }) => team_id === id);
      return {
        name,
        contacts: [
          ...foundContacts.map(({ name, phone_number }) => ({
            name,
            phone_number,
          })),
        ]
          .concat(
            foundUsers.reduce((array, { given_name, surname, phone = "" }) => {
              if (phone && phone !== "") {
                return array.concat([
                  {
                    name: `${given_name} ${surname}`,
                    phone_number: phone,
                  },
                ]);
              } else {
                return array;
              }
            }, [])
          )
          .sort(sortFunction),
      };
    })
    .filter(({ contacts }) => contacts.length);

  const appBarActions = [
    {
      name: "close",
      icon: !showSearch && <SSRIcon paths={SearchIcon} />,
      onClick: () => setShowSearch(true),
      position: "right",
    },
  ];

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("storeString"),
        actions: appBarActions,
      }}
      noPadding
    >
      {showSearch && (
        <HeaderSearchInput
          inputRef={inputRef}
          onChange={(e) => setSearchString(e.target.value)}
          onClose={() => {
            setSearchString("");
          }}
          onCancel={() => {
            setSearchString("");
            setShowSearch(false);
          }}
          placeholder={t("searchContact")}
          query={searchString}
        />
      )}
      {data.map((team, idx) => (
        <React.Fragment key={idx}>
          <TeamName>{team.name}</TeamName>
          {team.contacts
            .filter((contact) => filterContactFromSearch(contact, searchString))
            .map(({ name, phone_number }, index) => {
              return (
                <ContactField key={index}>
                  <Name>{name}</Name>
                  <Number onClick={makePhoneLink(phone_number)}>
                    <span>{phone_number}</span>
                  </Number>
                </ContactField>
              );
            })}
        </React.Fragment>
      ))}
    </FullScreenPopup>
  );
};

function sortFunction(a, b) {
  return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
}

export default StoreContacts;
