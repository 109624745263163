import React from "react";
import { Trans } from "@coworker/locales";
import BasicWidget from "@coworker/components/src/components/BasicWidget";
import { useInputPopup } from "../../InputPopup";
import { pqHowList } from "@coworker/functions/src/enums/pqHowList";
import { ReactComponent as MagnifyingGlass24 } from "../../../assets/svg/icon-search-24.svg";
import { pqHowOptions } from "@coworker/functions/src/enums/pqHowOptions";

export default function TaskFormRadiobuttons({
  value,
  onChange,
  headline = <Trans>howDidYouDiscoverString</Trans>,
  list = pqHowList,
  icon = <MagnifyingGlass24 width={22} height={22} color={"grey900"} />,
  testId = "radiobuttonsWidget",
  additionalSubTitle = <></>,
}) {
  const { getInput } = useInputPopup();
  const handler = async () => {
    const data = await getInput("radiobuttons", { value, headline, list });
    if (data) onChange(data);
  };
  const [type] = value;
  const current = list.find((item) => item.value === type);
  const choiceText = current && <Trans>{current.translationKey}</Trans>;
  const content =
    (current && current?.value === pqHowOptions.OTHER && value[1]) ||
    choiceText;

  return (
    <BasicWidget
      tabIndex={0}
      onClick={handler}
      text={content}
      title={headline}
      testId={testId}
      icon={icon}
      additionalSubTitle={!type ? additionalSubTitle : <></>}
    />
  );
}
