import React from "react";
import DonutChart from "@coworker/components/src/components/DonutChart";
import Card, {
  ActivityIndicator,
  Row,
} from "@coworker/components/src/components/Card";
import { Trans } from "@coworker/locales";
import ListItem from "@coworker/components/src/components/ListItem";
import useFormatter from "../../hooks/useFormatter";
import { INSIGHTS_PLD } from "@coworker/functions/src/enums/insightsRequestTypes";
import { useCallInsightsFunction } from "../../hooks/API/useCallFirebaseFunction";

export const LCViewType = {
  pieces: "pieces",
  turnover: "turnover",
};

const LocationContribution = ({ params }) => {
  const [chartsType, setChartsType] = React.useState(LCViewType.pieces);
  const { group_by, weeksBack, today, ...destParams } = params;
  const { data, loading } = useCallInsightsFunction(INSIGHTS_PLD, {
    ...destParams,
    product_id: destParams.product_id.replace(/ART|SPR/g, ""),
  });
  const { formatCurrency } = useFormatter();
  const isTurnover = chartsType === LCViewType.turnover;
  const { currency } = data || {};
  const suffix = `${isTurnover ? "_turnover" : ""}_sum`;
  const current = data?.["this_product" + suffix] || 0;
  const all = data?.["all_products" + suffix] ?? null;
  const value = (100.0 * parseInt(current)) / parseInt(all);
  const percent = isNaN(value) || !isFinite(value) ? 0 : value;

  return (
    <Card
      title={<Trans>{`${chartsType}ContributionTitle`}</Trans>}
      action={{
        label: <Trans>{`${chartsType}ContributionActionLabel`}</Trans>,
        onClick: () => {
          switch (chartsType) {
            case LCViewType.pieces:
            default:
              setChartsType(LCViewType.turnover);
              break;
            case LCViewType.turnover:
              setChartsType(LCViewType.pieces);
              break;
          }
        },
      }}
    >
      {loading || !data ? (
        <ActivityIndicator />
      ) : (
        <>
          <DonutChart enabled={all !== null} percent={percent} centered />
          <Row>
            <ListItem title={<Trans>locationTotalPieces</Trans>}>
              {isTurnover ? formatCurrency(all, currency) : all}
            </ListItem>
            <ListItem title={<Trans>contributionPieces</Trans>} right={true}>
              {isTurnover ? formatCurrency(current, currency) : String(current)}
            </ListItem>
          </Row>
        </>
      )}
    </Card>
  );
};

export default LocationContribution;
