import React from "react";

/**
 * Provides an easy way to deal with boolean values as state.
 * @param initialState Defaults to false
 */
export function useToggle(
  initialState: boolean = false
): [boolean, () => void] {
  const [state, setState] = React.useState(initialState);

  const mounted = React.useRef(false);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const toggleState = React.useCallback(() => {
    if (mounted.current) setState((s) => !s);
  }, []);

  return [state, toggleState];
}
