import React from "react";
import Modal, { ModalFooter, ModalHeader, Prompt } from "@ingka/modal";
import Button from "@ingka/button";
import { useTranslation } from "@coworker/locales";

interface OkCancelModalProps {
  onOk: () => void;
  onCancel: () => void;
  title: string;
  text: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
}

export const OkCancelModal = ({
  onOk,
  onCancel,
  title,
  text,
  primaryButtonText,
  secondaryButtonText,
}: OkCancelModalProps) => {
  const { t } = useTranslation();
  primaryButtonText ??= t("OkString");
  secondaryButtonText ??= t("cancelString");

  return (
    <Modal handleCloseBtn={onCancel} visible={true}>
      <Prompt
        aria-label={text}
        footer={
          <ModalFooter>
            <Button
              text={secondaryButtonText}
              type="secondary"
              onClick={onCancel}
            />
            <Button text={primaryButtonText} type="primary" onClick={onOk} />
          </ModalFooter>
        }
        header={
          <ModalHeader title={title} ariaCloseTxt={secondaryButtonText} />
        }
        title=""
        titleId=""
      >
        <p>{text}</p>
      </Prompt>
    </Modal>
  );
};
