const eventObjectTypes = {
  TASK: "TASK",
  TESTBUY: "TESTBUY",
};

const eventTypes = {
  TASK_ASSIGNED: "TASK_ASSIGNED",
  TASK_ASSIGNED_TO_TEAM: "TASK_ASSIGNED_TO_TEAM",
  TASK_REASSIGNED: "TASK_REASSIGNED",
  TASK_REASSIGNED_TO_TEAM: "TASK_REASSIGNED_TO_TEAM",
  TASK_EDITED: "TASK_EDITED",
  TASK_CLOSED: "TASK_CLOSED",
  TASK_PICKED_UP: "TASK_PICKED_UP",
  TASK_COMMENTED: "TASK_COMMENTED",
  TASK_COMPLETED_WITH_CHANGES: "TASK_COMPLETED_WITH_CHANGES",
  TASK_IS_ONE_DAY_OLD: "TASK_IS_ONE_DAY_OLD",
  TEAM_TASK_IS_ONE_DAY_OLD: "TEAM_TASK_IS_ONE_DAY_OLD",
  TASK_IS_FOUR_HOURS_OLD: "TASK_IS_FOUR_HOURS_OLD",
  TEAM_TASK_IS_FOUR_HOURS_OLD: "TEAM_TASK_IS_FOUR_HOURS_OLD",
  FEEDBACK_REQUEST: "FEEDBACK_REQUEST",
  TESTBUY_REASSIGNED: "TESTBUY_REASSIGNED",
};

function isTeamEvent(eventType) {
  switch (eventType) {
    case eventTypes.TASK_ASSIGNED_TO_TEAM:
    case eventTypes.TASK_REASSIGNED_TO_TEAM:
    case eventTypes.TEAM_TASK_IS_ONE_DAY_OLD:
    case eventTypes.TEAM_TASK_IS_FOUR_HOURS_OLD:
      return true;
    default:
      return false;
  }
}

const eventActions = {
  LINK: "LINK",
};

/**
 * Used for notification messages.
 * Array contains: title (String), and message maker (function) that takes two string parameters.
 */
const eventTypeStrings = {
  [eventTypes.TASK_ASSIGNED]: [
    () => "taskAssignedString",
    (toYou) => (toYou ? "hasAssignedToYouString" : "hasAssignedString"),
  ],
  [eventTypes.TASK_ASSIGNED_TO_TEAM]: [
    () => "taskAssignedString",
    () => "hasAssignedString",
  ],
  [eventTypes.TASK_REASSIGNED]: [
    () => "taskReassignedString",
    (toYou) => (toYou ? "hasReassignedToYouString" : "hasReassignedString"),
  ],
  [eventTypes.TASK_REASSIGNED_TO_TEAM]: [
    () => "taskReassignedString",
    () => "hasReassignedString",
  ],
  [eventTypes.TASK_COMPLETED_WITH_CHANGES]: [
    () => "taskCompletedWithChangesString",
    () => "hasCompletedWithChangesString",
  ],
  [eventTypes.TASK_EDITED]: [() => "taskEditedString", () => "hasEditedString"],
  [eventTypes.TASK_CLOSED]: [
    () => "taskWasClosedString",
    () => "hasClosedString",
  ],
  [eventTypes.TASK_PICKED_UP]: [
    () => "taskPickedUpString",
    () => "hasPickedUpString",
  ],
  [eventTypes.TASK_COMMENTED]: [
    () => "taskCommentedString",
    () => "hasCommentedString",
  ],
  [eventTypes.TASK_IS_ONE_DAY_OLD]: [
    () => "taskIsOneDayOldString",
    () => "hasBecomeOneDayOldString",
  ],
  [eventTypes.TEAM_TASK_IS_ONE_DAY_OLD]: [
    () => "taskIsOneDayOldString",
    () => "hasBecomeOneDayOldTeamString",
  ],
  [eventTypes.TASK_IS_FOUR_HOURS_OLD]: [
    () => "taskIsFourHoursOldString",
    () => "hasBecomeFourHoursOldString",
  ],
  [eventTypes.TEAM_TASK_IS_FOUR_HOURS_OLD]: [
    () => "taskIsFourHoursOldString",
    () => "hasBecomeFourHoursOldTeamString",
  ],
  [eventTypes.TESTBUY_REASSIGNED]: [
    () => "testbuyReassignedString",
    (toYou) =>
      toYou ? "hasReassignedTestbuyToYouString" : "hasReassignedString",
  ],
};

/**
 * Used to send to the consumer the action text so it knows what to display
 */
const eventActionStrings = {
  [eventActions.LINK]: () => "curtainViewString",
};

module.exports = {
  eventObjectTypes,
  eventTypesList: Object.values(eventTypes),
  eventTypes,
  eventActions,
  eventTypeStrings,
  eventActionStrings,
  isTeamEvent,
};
