import React from "react";
import Pill from "@ingka/pill";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/pill/dist/style.css";
import "@ingka/focus/dist/style.css";

interface SelectablePillProps {
  label: string;
  selected: boolean;
  onClick: () => void;
}

const styles = {
  selected: {
    color: "var(--white)",
    backgroundColor: "var(--black)",
    border: "1px solid var(--black)",
  },
  unselected: {
    color: "var(--black)",
    backgroundColor: "var(--white)",
    border: "1px solid var(--black)",
  },
};

export function SelectablePill({
  label,
  selected,
  onClick,
}: SelectablePillProps) {
  return (
    <Pill
      label={label}
      selected={selected}
      onClick={() => {
        onClick();
      }}
      size="xsmall"
      style={selected ? styles.selected : styles.unselected}
    />
  );
}
