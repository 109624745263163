import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import useFormatter from "./useFormatter";
import { weekdaySorter } from "@coworker/functions/src/consts/isoWeek";

export default function useFormatScheduled({
  scheduled_start,
  scheduled_end,
  every_n_weeks,
  recurring,
  days_of_week,
}) {
  const { t } = useTranslation();
  const { formatWeekday, formatList, formatDateLong } = useFormatter();
  const startDate = scheduled_start && formatDateLong(scheduled_start);
  const endDate = scheduled_end && formatDateLong(scheduled_end);
  let summary, preview, previewHint;
  if (recurring === false) {
    summary = t("plannedSummaryString", { date: startDate });
    preview = t("plannedPreviewString", { date: startDate });
  } else if (recurring === true) {
    preview = <Trans>recurringString</Trans>;
    if (days_of_week.length === 0) {
      summary = t("recurringNotReadyString");
    } else {
      const sorted_days = [...days_of_week];
      sorted_days.sort(weekdaySorter);
      summary = t("recurringSummaryString", {
        count: every_n_weeks,
        days: formatList(sorted_days.map(formatWeekday.long)),
        start: formatDateLong(scheduled_start),
        end: formatDateLong(scheduled_end),
      });
      previewHint = t("recurringPreviewHintString", {
        count: every_n_weeks,
        days: formatList(sorted_days.map(formatWeekday.short)),
      });
    }
  }

  return { summary, preview, previewHint, startDate, endDate };
}
