/**
 * Gets value of cookie, if not found, returns false
 * @param {string} name
 */
export function getCookieByName(name) {
  const cookie =
    document.cookie
      .split(/; ?/g) // split either by ";" or "; " as some user agents separate with one and some with the other
      .find((cookieStr) => cookieStr.startsWith(name + "=")) ?? "";
  const [, value] = cookie.split("=");

  return value ?? false;
}
