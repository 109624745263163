import React from "react";
import styled from "styled-components";
import { Widget } from "./Widget";

const Container = styled.div`
  background: white;
  padding: 0 29px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
  color: var(${({ deactivated }) => (deactivated ? "--grey600" : "--grey900")});
  min-height: 90px;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  box-sizing: border-box;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-all;
`;

const IconWrapper = styled.div`
  width: 22px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  margin-right: 20px;
  justify-content: center;
  svg {
    margin: 0px auto;
    opacity: ${(props) => (props.deactivated ? ".5" : "1")};
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: var(--grey718);
  overflow: hidden;
  white-space: break-spaces;
`;

/**
 * @param {MouseEventHandler?} onClick
 * @param {JSX.Element?} icon
 * @param {JSX.Element|string|null} title
 * @param {JSX.Element|string|null} text
 * @param {boolean?} deactivated
 * @param {string} testId
 * @param {ReactNode} additionalSubTitle
 * @param {number} tabIndex
 * @returns {JSX.Element}
 */
const BasicWidget = ({
  onClick,
  icon,
  title,
  text,
  deactivated,
  testId,
  additionalSubTitle = <></>,
  tabIndex = -1,
}) => (
  <Widget onClick={onClick} data-testid={testId} tabIndex={tabIndex}>
    <Container deactivated={deactivated}>
      {icon && <IconWrapper deactivated={deactivated}>{icon}</IconWrapper>}
      <Stack>
        <Title deactivated={deactivated}>{title}</Title>
        {text && <Subtitle>{text}</Subtitle>}
        {additionalSubTitle}
      </Stack>
    </Container>
  </Widget>
);

export default BasicWidget;
