import React from "react";
import { Articles } from "../types/article";
import { GreyBoxWithText, ListItemImage } from "../styles/styles";
import styled from "styled-components";

const NumberOfImagesToShow = 3;

interface ArticlesImagesOverviewProps {
  articles: Articles;
}

const ListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ArticlesImagesOverview = ({
  articles,
}: ArticlesImagesOverviewProps) => {
  const numberOfImagesToShow =
    articles.length >= NumberOfImagesToShow
      ? NumberOfImagesToShow
      : articles.length;
  const numberOfImagesNotShown = articles.length - numberOfImagesToShow;

  return (
    <ListItemContainer>
      {articles
        .slice(0, numberOfImagesToShow)
        .map(({ imageSmall, id }, index) => (
          <ListItemImage
            key={`${id}-${index}`}
            src={imageSmall ?? ""}
            $rotation={0}
          />
        ))}
      {numberOfImagesNotShown > 0 && (
        <GreyBoxWithText
          key={`Images not shown ${numberOfImagesNotShown}`}
        >{`+${numberOfImagesNotShown}`}</GreyBoxWithText>
      )}
    </ListItemContainer>
  );
};
