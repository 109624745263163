import React from "react";
import Choice, { ChoiceItem } from "@ingka/choice";
import { RefillTypes } from "../../../constants/refillTypes";
import "@ingka/choice/dist/style.css";
import { useTranslation } from "@coworker/locales";

interface ChooseRefillTypeProps {
  selectedRefillTypeIndex: number;
  onRefillTypeSelectionChange: (selectedRefillType: RefillTypes) => void;
}

export default function ChooseRefillType({
  selectedRefillTypeIndex,
  onRefillTypeSelectionChange,
}: ChooseRefillTypeProps) {
  const { t } = useTranslation();

  const getRefillTypeTitle = (refillType: string) => {
    switch (refillType) {
      case RefillTypes.REGULAR:
        return t("refillTypeRegularString");
      case RefillTypes.FIRST_FILL:
        return t("refillTypeFirstFillString");
      default:
        return t("refillTypeRegularString");
    }
  };

  return (
    <Choice subtle preselectedIndex={selectedRefillTypeIndex}>
      {(Object.keys(RefillTypes) as Array<keyof typeof RefillTypes>).map(
        (refillType) => {
          return (
            <ChoiceItem
              key={refillType}
              data-testid={refillType + "_section"}
              id={refillType}
              title={getRefillTypeTitle(refillType)}
              onClick={(value: any) => {
                onRefillTypeSelectionChange(value);
              }}
            />
          );
        }
      )}
    </Choice>
  );
}
