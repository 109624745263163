import React from "react";
import { useCountryId, useStoreId } from "../core/auth/useLoggedInUser";
import { useInputPopup } from "../components/InputPopup";

const envs = {
  prod: "756fbea651240",
  stage: "2bf4cfca63ff2",

  test: "2bf4cfca63ff2", // Temporary -- remove after quick test is done!
  dev: "2bf4cfca63ff2", // Temporary -- remove after quick test is done!
};

const tagName = envs[process.env.REACT_APP_ENV];
const isDisabled = !tagName;

function addCustomVariable(name, id, value) {
  if (isDisabled) return;
  window._uxa = window._uxa || [];
  window._uxa.push(["setCustomVariable", id, name, value, 3]);
}

function trackPageView() {
  if (isDisabled) return;
  if (typeof CS_CONF === "undefined") {
    window._uxa = window._uxa || [];
    window._uxa.push([
      "setPath",
      window.location.pathname + window.location.hash.replace("#", "?__"),
    ]);
    var mt = document.createElement("script");
    mt.type = "text/javascript";
    mt.async = true;
    mt.src = `//t.contentsquare.net/uxa/${tagName}.js`;
    document.getElementsByTagName("head")[0].appendChild(mt);
  } else {
    window._uxa = window._uxa || [];
    window._uxa.push([
      "trackPageview",
      window.location.pathname + window.location.hash.replace("#", "?__"),
    ]);
  }
}

function trackPopup(name) {
  if (isDisabled) return;
  window._uxa = window._uxa || [];
  window._uxa.push([
    "trackPageview",
    window.location.pathname +
      window.location.hash.replace("#", "?__") +
      `?cs-popin-${name}`,
  ]);
}

export default function useContentsquare() {
  const initializedRef = React.useRef(false);
  const storeId = useStoreId();
  const country_id = useCountryId();

  const { currentType: popupType } = useInputPopup();

  React.useEffect(() => {
    if (popupType) {
      trackPopup(popupType);
      return trackPageView;
    }
  }, [popupType]);

  React.useEffect(() => {
    if (storeId && country_id) {
      initializedRef.current = true;
      window._uxa = window._uxa || [];
      addCustomVariable("store", 1, storeId);
      addCustomVariable("country", 2, country_id);
    }
  }, [storeId, country_id]);

  return {
    trackPageView,
  };
}
