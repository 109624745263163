import React from "react";
import styled from "styled-components";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import { Trans } from "@coworker/locales";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { getCurrency } from "../helper";
import { KeyNumberPad } from "./KeyNumberPad";
import { HEADER_COLOR_SCHEMA } from "../constants";
import { ReactComponent as EditIcon } from "../../../assets/svg/customize-task.svg";

interface ReceiptProps {
  totalSum: string;
  setTotalSum: React.Dispatch<React.SetStateAction<string>>;
  totalArticles: string;
  setTotalArticles: React.Dispatch<React.SetStateAction<string>>;
  showTotalSumKeyPad: boolean;
  setShowTotalSumKeyPad: React.Dispatch<React.SetStateAction<boolean>>;
  showTotalArticlesKeyPad: boolean;
  setShowTotalArticlesKeyPad: React.Dispatch<React.SetStateAction<boolean>>;
  displayWarning: boolean;
  setdisplayWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReceiptWrapper = styled.div`
  background: var(--blue-medium);
`;

const TotalArticles = styled.div`
  height: 70px;
  font-size: 16px;
  border-top: 1px solid var(--grey200);
  border-bottom: 1px solid var(--grey200);
  color: white;
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 1rem;
`;

const TotalSum = styled(TotalArticles)`
  font-size: 36px;
  border-top: none;
  border-bottom: none;
`;

const Total = styled(TotalSum)`
  font-size: 16px;
  height: 41px;
  align-items: start;
`;

const StyledEditIcon = styled(EditIcon)`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export const Receipt = ({
  totalSum,
  setTotalSum,
  totalArticles,
  setTotalArticles,
  showTotalSumKeyPad,
  setShowTotalSumKeyPad,
  showTotalArticlesKeyPad,
  setShowTotalArticlesKeyPad,
  displayWarning,
  setdisplayWarning,
}: ReceiptProps) => {
  const countryId = useCountryId();
  const currency = getCurrency(countryId);
  return (
    <>
      <SubHeaderCard
        title={<Trans>receiptString</Trans>}
        fontSize={16}
        colorSchema={HEADER_COLOR_SCHEMA.BLUE}
      />
      <ReceiptWrapper>
        <TotalArticles
          onClick={() => {
            setShowTotalArticlesKeyPad(!showTotalArticlesKeyPad);
            setShowTotalSumKeyPad(false);
          }}
        >
          <StyledEditIcon />
          {totalArticles !== "" ? totalArticles : "0"}{" "}
          <Trans>articlesLowerCaseString</Trans>
        </TotalArticles>
        <div
          onClick={() => {
            setShowTotalSumKeyPad(!showTotalSumKeyPad);
            setShowTotalArticlesKeyPad(false);
          }}
        >
          <TotalSum>
            <StyledEditIcon />
            {totalSum !== "" ? totalSum : "0"} {currency}
          </TotalSum>
          <Total>
            <Trans>totalString</Trans>
          </Total>
        </div>
        {showTotalArticlesKeyPad && (
          <KeyNumberPad
            value={totalArticles}
            setValue={setTotalArticles}
            setShowKeyNumberPad={setShowTotalArticlesKeyPad}
            title={"numberOfArticlesString"}
            showTotalSumKeyPad={showTotalSumKeyPad}
            displayWarning={displayWarning}
            setdisplayWarning={setdisplayWarning}
          />
        )}
        {showTotalSumKeyPad && (
          <KeyNumberPad
            value={totalSum}
            setValue={setTotalSum}
            setShowKeyNumberPad={setShowTotalSumKeyPad}
            showComma={true}
            title={"totalSumString"}
            currency={currency ?? ""}
            showTotalSumKeyPad={showTotalSumKeyPad}
            displayWarning={displayWarning}
            setdisplayWarning={setdisplayWarning}
          />
        )}
      </ReceiptWrapper>
    </>
  );
};
