import React from "react";
import styled from "styled-components";
import { Button } from "@coworker/components";
import { LoaderIcon } from "@coworker/reusable/Loader";

const Container = styled.div`
  background-color: var(--white);
`;

const Title = styled.div`
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px 20px 30px;
  border-bottom: 1px solid var(--grey200);
`;

const Content = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;

  > * {
    ${(props) => !props.noContentPadding && "padding: 26px 28px;"}
  }
`;

export const Row = styled.div`
  display: flex;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px 28px;
  height: 300px;
`;

const LoaderIndicator = styled(LoaderIcon)`
  width: 20px;
  height: 20px;
`;

export const ActivityIndicator = () => {
  return (
    <LoaderContainer>
      <LoaderIndicator />
    </LoaderContainer>
  );
};

const Card = ({
  title,
  children,
  action,
  noContentPadding,
  disabled,
  testId,
}) => {
  return (
    <Container data-testid={testId}>
      <SpaceBetween>
        <Title>{title}</Title>
        {action && (
          <Button
            small
            cardbtn
            text={action.label}
            onClick={action.onClick}
            disabled={disabled}
            data-testid="card_action_button"
          />
        )}
      </SpaceBetween>
      <Content noContentPadding={noContentPadding}>{children}</Content>
    </Container>
  );
};

export default Card;
