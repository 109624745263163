import React from "react";
import { useCountryId } from "../core/auth/useLoggedInUser";

const extractLangCountry = (str) => /^([a-z]{2})-([A-Z]{2})$/.exec(str);
const extractLang = (str) => /^([a-z]{2})$/.exec(str);
const extractCountry = (str) => /^([A-Z]{2})$/.exec(str);

const langCountryMap = {
  es: "ES",
  fi: "FI",
  ko: "KR",
  ja: "JP",
  nl: "NL",
  pl: "PL",
  pt: "PT",
  ru: "RU",
  sv: "SE",
};

export const countryLangMap = {
  ES: "es",
  FI: "fi",
  CA: "fr",
  FR: "fr",
  HR: "hr",
  HU: "hu",
  IT: "it",
  JP: "ja",
  DE: "de",
  KR: "ko",
  NL: "nl",
  PL: "pl",
  PT: "pt",
  RU: "ru",
  RO: "ro",
  SK: "sk",
  SL: "sl",
  SE: "sv",
};

export function useBestEffortCountryCode() {
  const country_id = useCountryId() || null;
  const countryCode = React.useMemo(() => {
    if (country_id) return country_id;

    const lang = navigator.language;
    if (extractLangCountry(lang)) {
      const [, , country] = extractLangCountry(lang);
      return country;
    } else if (extractLang(lang)) {
      const [, language] = extractLang(lang);
      return langCountryMap[language] ?? null;
    } else if (extractCountry(lang)) {
      const [, country] = extractCountry(lang);
      return country;
    }
    return null;
  }, [country_id]);

  return countryCode;
}
