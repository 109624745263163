import React from "react";
import { TestBuy } from "@coworker/types";
import {
  describeAreaFilter,
  FilterKeys,
} from "../../../../InputPopup/UserFiltersRow/filterChoices";
import { countTestBuysPerHfb, createRankItems } from "./Ranking.helper";
import { RankingShowcase } from "../RankingShowcase";

interface HfbRanksProps {
  testbuys: TestBuy[];
  allTestbuys: TestBuy[];
  filters: FilterKeys;
  isWholeStoreActive: boolean;
  totalTestbuysDone: number;
}

export const HfbRanks = ({
  testbuys,
  allTestbuys,
  filters,
  isWholeStoreActive,
  totalTestbuysDone,
}: HfbRanksProps) => {
  const [hfb, setHfb] = React.useState<string | JSX.Element>("");
  const [activeHfb, setActiveHfb] = React.useState<number>(0);

  const countedTestBuys = countTestBuysPerHfb(
    allTestbuys.filter((tb) => tb.area !== "whole=whole")
  );

  React.useEffect(() => {
    if (!isWholeStoreActive) {
      setActiveHfb(Number((hfb as string).slice(4)));
    }
  }, [isWholeStoreActive, hfb, activeHfb]);

  React.useEffect(() => {
    if (testbuys && filters) {
      setHfb(describeAreaFilter(filters));
    }
  }, [testbuys, filters]);

  if (isWholeStoreActive) return <></>;

  const offsetIntoCounts = countedTestBuys.findIndex(
    ({ area }) => area === filters[1]
  );

  const { ahead, active, behind } = createRankItems(
    countedTestBuys,
    offsetIntoCounts
  );

  return (
    <RankingShowcase
      ahead={ahead}
      active={active}
      behind={behind}
      isWholeStoreActive={isWholeStoreActive}
      offsetIntoCounts={offsetIntoCounts}
      totalTestbuysDone={totalTestbuysDone ?? 0}
    />
  );
};
