import React from "react";

interface LabelTextProps {
  text: string;
  withAsterisk?: boolean;
}

export const LabelText = ({ text, withAsterisk }: LabelTextProps) => {
  return (
    <p>
      {text}
      {withAsterisk && <span style={{ color: "#E00751" }}>*</span>}
    </p>
  );
};
