import React from "react";
import { EmailLink, IndividualRightsLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Privacy statement</div>,
  aboutFixaText: (
    <div>
      Fixa is a task management and merchandising tool for in-store co-workers.
      The FIXA application's purpose is to replace manual, paper or excel based
      activities and streamline task management in store (refilling articles,
      clean ups, deviations, test buys, most frequently asked questions etc).
      Fixa also allows for analytics through insights gained from completed
      tasks.
      <br />
      <br />
      When you use Fixa, the Ingka Group company Data Controller (as defined
      below) will collect certain personal data about you. After sign in, you
      will get access to your country specific privacy policy. For questions to
      this, reach out to <EmailLink email="fixa.support@ikea.com" /> or your
      local privacy policy leader.
    </div>
  ),
  personalDataCollectedTitle: <div>The personal data collected is:</div>,
  personalDataCollectedText: (
    <div>
      When you use FIXA, the system will collect information about you from the
      Co-Worker Database (CDS) such as your First name, middle name(s), last
      name, email, job title, role, phone number (if manually provided -
      personal phone number should not be entered), photograph from CDS,
      language preference in app, store ID, country ID, version of device and
      operating system, IP-address processed in Google Cloud Platform and Sentry
      (Sentry is an application error tracking software).
      <br />
      <br />
      Co-worker profile is manually created through Fixa-admin (an admin web
      app) by the manager/Super user. Personal data is fetched from CDS, so
      profiles can only be created for co-workers who have an active CDS
      profile.
      <br />
      <br />
      Sweden/Finland specific: We use the employee ID for the schedule feature
      which holds hours of work and absence which is stored and shown in the
      app.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>The purpose of collecting this data is:</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      The purpose of the personal data collection in Fixa is for the application
      to function properly in a way that enables easy internal communication
      between co-workers and administrate tasks between co-workers in the store.
      Having tasks connected to a user enables us to support notifications for
      updates on tasks for assigned users and creators.
      <br />
      <br />
      Sweden/Finland specific: Used for the scheduling feature in Fixa, which
      allows co-workers to view their work schedule.
    </div>
  ),
  legalGroundsTitle: <div>The legal ground for the processing is:</div>,
  legalGroundsText: (
    <div>The legal ground for the processing is legitimate interest.</div>
  ),
  dataWillBeStoredTitle: <div>The data will be stored:</div>,
  dataWillBeStoredText: (
    <div>
      We store finished tasks with references to users for 70 (or 28) days,
      whereafter they are anonymised. A co-worker's personal data is stored in
      Fixa for the duration of the employment contract.
      <br />
      <br />
      Termination of employment contract with Ingka Group: All personal data is
      being automatically deleted within 1 months after the CDS profile has been
      removed.
      <br />
      <br />
      Change of role within Ingka Group: If a co-worker no longer requires to
      use Fixa, all personal data will be automatically deleted within 3 months
      of in-activity.
      <br />
      <br />
      Personal Data may also be removed at any point in time upon request to the
      local Information Security and Data Privacy Leader or Store Manager.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>The data controller for this processing of personal data is:</div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA IT AB
      <br />
      Box 803, 251 08 Helsingborg
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      You have certain rights in relation to your personal data. Please visit
      <IndividualRightsLink /> to learn more about such rights.
    </div>
  ),
};
