import { useQuery } from "@tanstack/react-query";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { fetchLatestShoppingTools } from "../ShoppingToolsAPI";
import { INTERVAL } from "../../../constants/clientTime";

export const useFetchLatestShoppingTools = () => {
  const storeId = useStoreId();

  return useQuery({
    queryKey: ["shopping-tools-latest", storeId],
    queryFn: () => {
      return fetchLatestShoppingTools(storeId);
    },
    staleTime: INTERVAL.MINUTE * 1,
  });
};
