import React from "react";
import { useTranslation } from "@coworker/locales";
import { ArticleListItem } from "../../RoomSettings/Molecules/ArticleListItem";
import { Text } from "../../RoomSettings/Atoms/Text";
import { Reasons } from "../../RoomSettings/constants";
import { useArticlesInfo } from "../../RoomSettings/hooks/useArticlesInfo";
import { mapArticleInfoToArticle } from "../../RoomSettings/types/mappers";
import { Article } from "../../RoomSettings/types/article";
import { SimpleContainer } from "../../RoomSettings/styles/styles";

interface PreviousProductProps {
  value: {
    new_product_article_id: string;
    state: string;
    reason: string;
  };
}

export default function PreviousProduct({ value }: PreviousProductProps) {
  const { t } = useTranslation();

  const { data: articlesInfo, isLoading } = useArticlesInfo(
    value.new_product_article_id
  );

  if (isLoading) return <></>;

  const article: Article = mapArticleInfoToArticle(articlesInfo[0]);

  return (
    <>
      {value.reason === Reasons.REPLACE_PRODUCT && (
        <>
          <SimpleContainer $direction="row">
            <Text text={t("newProductString")} bold />
          </SimpleContainer>
          <ArticleListItem article={article} hideChevron={true} />
        </>
      )}
    </>
  );
}
