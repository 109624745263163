import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as ArrowBack } from "../../assets/svg/arrow-back.svg";
import { ReactComponent as IssueOther } from "@coworker/reusable/svg/three-dots.svg";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { useInputPopup } from "../../components/InputPopup";

type ButtonProps = {
  transparent?: boolean;
  isSticky?: boolean;
  onClick: React.MouseEventHandler;
};

export const TopBarButton = styled.button<ButtonProps>`
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  background-color: ${(props) => (props.transparent ? "transparent" : "white")};
  border: none;
  height: 42px;
  width: 42px;
  border-radius: 21px;
  cursor: pointer;
  svg {
    vertical-align: middle;
    ${(props) =>
      !props.isSticky && props.transparent ? "color: white" : "color: black"}
  }
`;

export function TopBarOverflowButton({
  actions = [],
  isSticky = false,
  transparent = false,
}) {
  const { getInput } = useInputPopup();
  return (
    <TopBarButton
      isSticky={isSticky}
      transparent={transparent}
      onClick={() => getInput("popupMenu", { actions })}
      data-testid="dots"
    >
      <IssueOther />
    </TopBarButton>
  );
}

export function TopBarBackButton({
  onClick,
  transparent = false,
  isSticky = false,
}: ButtonProps) {
  const { pop } = useWorkspacesAction();
  function defaultBack(event: React.MouseEvent) {
    pop();
    event.stopPropagation();
  }

  return (
    <TopBarButton
      transparent={transparent}
      isSticky={isSticky}
      tabIndex={0}
      data-testid="backButton"
      onClick={onClick || defaultBack}
    >
      <ArrowBack />
    </TopBarButton>
  );
}

const TitleContainer = styled.div<{ isSticky: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  justify-self: center;
  flex: 4;
  width: 100%;
  min-width: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
  ${({ isSticky }) =>
    !isSticky &&
    css`
      opacity: 0;
    `}
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  justify-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 12px;
  color: var(--grey718);
`;

export function TopBarTitle({
  title = "",
  subTitle = "",
  isSticky = false,
  testId = "",
}) {
  return (
    <TitleContainer isSticky={isSticky} data-testid={testId || undefined}>
      {title && <Title>{title}</Title>}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </TitleContainer>
  );
}
