import React from "react";
import BlueLink from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Integritetspolicy</div>,
  aboutFixaText: (
    <div>
      Fixa är ett verktyg för hantering av uppgifter. Fixa används av
      medarbetare i varuhusen. Syftet med Fixa-applikationerna är att ersätta
      manuella, pappersbaserade eller Excel-baserade uppgifter och att
      effektivisera hanteringen av uppgifter i varuhusen (påfyllning av
      artiklar, rensning, avvikelser, testköp, vanligaste frågor etc.). Fixa
      möjliggör även insiktsanalyser av genomförda uppgifter.
      <br />
      <br />
      När du använder Fixa samlar Ingka Group (personuppgiftsansvarig) in vissa
      personuppgifter om dig.
    </div>
  ),
  personalDataCollectedTitle: (
    <div>De personuppgifter som samlas in är följande:</div>
  ),
  personalDataCollectedText: (
    <div>
      När du använder Fixa samlar systemet in information om dig från CDS
      (Co-Worker Database), exempelvis ditt förnamn, mellannamn, efternamn,
      e-postadress, befattning, roll, telefonnummer (privata telefonnummer ska
      ej anges om du anger telefonnummer manuellt), ditt foto i CDS, din
      språkinställning i appen, ditt varuhus-ID, ditt lands ID, enhetsversion
      och operativsystem, den IP-adress som behandlas i Google Cloud Platform
      och Sentry (Sentry är en programvara för felrapportering).
      <br />
      <br />
      Medarbetarens profil skapas manuellt i Fixa-admin (en
      admin-webbapplikation) av medarbetarens chef eller av en super user.
      Personuppgifterna hämtas från CDS, vilket innebär att profiler endast kan
      skapas för medarbetare som har en aktiv CDS-profil.
      <br />
      <br />
      Fixa använder medarbetarens anställnings-ID för den schemafunktion som
      omfattar arbetstimmar och frånvaro som lagras och visas i appen.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>Syftet med att samla in dessa uppgifter är följande:</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      Syftet med insamlingen av personuppgifter i Fixa är att applikationen ska
      fungera korrekt och på ett sätt som möjliggör smidig internkommunikation
      mellan medarbetare, och enkel administration av uppgifter mellan
      varuhusets medarbetare. Att koppla uppgifter till specifika användare gör
      det möjligt att skicka aviseringar om uppgifterna andra användare.
      <br />
      <br />
      Används för schemaläggningsfunktionen i Fixa, som gör det möjligt för
      medarbetare att se sitt arbetsschema.
    </div>
  ),
  legalGroundsTitle: (
    <div>
      Den rättsliga grunden för insamling och hantering av personuppgifter:
    </div>
  ),
  legalGroundsText: (
    <div>
      Den rättsliga grunden för insamlingen och hanteringen är IKEA Svenska
      Försäljnings AB berättigade intresse.
    </div>
  ),
  dataWillBeStoredTitle: <div>Lagring av uppgifter:</div>,
  dataWillBeStoredText: (
    <div>
      Fixa lagrar slutförda uppgifter med användarreferenser i 70 dagar,
      därefter anonymiseras uppgifterna. Varje enskild medarbetares
      personuppgifter lagras i Fixa under anställningsavtalets löptid.
      <br />
      <br />
      Vid uppsägning av anställningsavtal med IKEA Svenska Försäljnings AB
      gäller följande:
      <br />
      alla personuppgifter raderas automatiskt inom en månad efter att
      CDS-profilen har tagits bort.
      <br />
      <br />
      Vid ändrad roll inom IKEA Svenska Försäljnings AB eller Ingka Group gäller
      följande:
      <br />
      om en medarbetare inte längre behöver använda Fixa raderas alla
      personuppgifter automatiskt inom tre månader efter att medarbetaren har
      slutat använda Fixa.
      <br />
      <br />
      Personuppgifter kan när som helst tas bort på begäran till lokal
      informationssäkerhets- och personuppgiftsansvarig eller aktuell
      varuhuschef.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>Personuppgiftsansvarig för denna hantering av personuppgifter är:</div>
  ),
  dataControllerForPersonalDataText: (
    <div>
      IKEA Svenska Försäljnings AB
      <br />
      Box 200
      <br />
      260 35 Ödåkra
      <br />
      Organisationsnummer 556074-7569
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      Du har särskilda rättigheter i samband med dina personuppgifter. Besök{" "}
      <BlueLink href="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx">
        https://inside.ingka.com/&#8203;supportservices/&#8203;Dataprivacy/&#8203;Pages/&#8203;Individual-rights.aspx
      </BlueLink>{" "}
      för mer information om dina rättigheter.
      <br />
      <br />
      Co-worker Privacy policy (IKEA Svenska Försäljnings AB){" "}
      <BlueLink href="https://iweof.sharepoint.com/sites/about/dh/Shared%20Documents/DigitalHubSweden/SE_Integritetspolicy%20Employees%20ENG_20200901.pdf">
        https://iweof.sharepoint.com/&#8203;sites/&#8203;about/&#8203;dh/&#8203;Shared%20Documents/&#8203;DigitalHubSweden/&#8203;SE_Integritetspolicy&#8203;%20Employees&#8203;%20ENG_20200901.pdf
      </BlueLink>
    </div>
  ),
};
