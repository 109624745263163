import * as useMFAQs from "./useMFAQs";
import * as useMFAQsService from "./useMFAQsService";

export const useFeatureFlagHooks = () => {
  if (window.callMFAQService) {
    return { ...useMFAQsService };
  } else {
    return {
      ...useMFAQs,
    };
  }
};
