import circleFilled from "@ingka/ssr-icon/paths/circle-filled";
import circleHalf from "@ingka/ssr-icon/paths/circle-half";
import circleEmpty from "@ingka/ssr-icon/paths/circle";
import circleIncorrect from "@ingka/ssr-icon/paths/incorrect";

export const getStockStatusIndicatorColor = (
  available: number,
  elevated: null | number
) => {
  if (available > 0) {
    return "green";
  } else if (available === 0 && elevated && elevated > 0) {
    return "orange";
  } else if (available === 0 && elevated === 0) {
    return "red";
  } else {
    return "grey";
  }
};

export const getStockStatusText = (
  available: number,
  elevated: null | number
) => {
  if (available > 0) {
    return "inStockTitle";
  } else if (available === 0 && elevated && elevated > 0) {
    return "lowInStockTitle";
  } else if (available === 0 && (elevated === 0 || elevated === null)) {
    return "outOfStockTitle";
  } else {
    return "loadingString";
  }
};

export const getStockStatusIcon = (
  available: number,
  elevated: null | number
) => {
  if (available > 0) {
    return circleFilled;
  } else if (available === 0 && elevated && elevated > 0) {
    return circleHalf;
  } else if (available === 0 && elevated === 0) {
    return circleEmpty;
  } else {
    return circleIncorrect;
  }
};
