import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/performance";
import "firebase/compat/storage";
import type { FixaWindow } from "@coworker/reusable";

firebase.initializeApp({
  apiKey: process.env["REACT_APP_API_KEY"],
  authDomain: process.env["REACT_APP_AUTH_DOMAIN"],
  databaseURL: process.env["REACT_APP_DATABASE_URL"],
  projectId: process.env["REACT_APP_PROJECT_ID"],
  storageBucket: process.env["REACT_APP_STORAGE_BUCKET"],
  messagingSenderId: process.env["REACT_APP_MESSAGING_SENDER_ID"],
  measurementId: process.env["REACT_APP_MEASUREMENT_ID"],
  appId: process.env["REACT_APP_APPID"],
});

//firebase.performance(); // COAPP-785 enable automatic performance tracking of PWA app load and network/function calls. Turned off temporarily as we have notices it
// contributes to a lot of lagging.

// for our live database, we would like to store data in our clients as well, which Firebase supports out of the box.
const firestore = firebase.firestore();
const storage = firebase.storage();

const functions = firebase
  .app()
  .functions(process.env["REACT_APP_FIREBASE_FUNCTIONS_REGION"]);
// If app is run locally and a local function flag is set, we set the functions endpoint to localhost, otherwise we set the default region.
if (
  process.env.NODE_ENV === "development" ||
  process.env["REACT_APP_ENV"] === "ci"
) {
  if (
    process.env["REACT_APP_LOCAL_ALL"] ||
    process.env["REACT_APP_LOCAL_STORAGE"]
  ) {
    storage.useEmulator("localhost", 9199);
  }
  if (
    process.env["REACT_APP_LOCAL_FUNCTIONS"] ||
    process.env["REACT_APP_LOCAL_ALL"]
  ) {
    functions.useEmulator("localhost", 5022);
  }

  if (
    process.env["REACT_APP_LOCAL_DATABASE"] ||
    process.env["REACT_APP_LOCAL_ALL"]
  ) {
    const firestoreSettings: firebase.firestore.Settings = {};
    if ((window as FixaWindow).Cypress) {
      // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
      firestoreSettings.experimentalForceLongPolling = true;
    }
    firestore.settings(firestoreSettings);
    firestore.useEmulator("localhost", 8080);

    // This is done so there is no warning banner from emulator. Currently there
    // is no configuration option to disable it so this is a workaround.
    const css = `
      .firebase-emulator-warning {
        display: none;
      }
    `;
    const overrideStyle = document.createElement("style");
    document.head.appendChild(overrideStyle);
    overrideStyle.type = "text/css";
    overrideStyle.appendChild(document.createTextNode(css));
  }

  if (
    process.env["REACT_APP_LOCAL_AUTH"] ||
    process.env["REACT_APP_LOCAL_ALL"]
  ) {
    firebase.auth().useEmulator("http://localhost:9099/");
  }
}

let analytics: firebase.analytics.Analytics | undefined;
if (
  process.env["REACT_APP_ENV"] === "prod" &&
  process.env["REACT_APP_PROJECT_ID"] === "ikea-coworker-app-prod" // Non-EU prod environments are handled via custom Google Analytics integration
) {
  firebase.analytics.isSupported().then((isSupported) => {
    if (isSupported) {
      analytics = firebase.analytics(); // Enable analytics and collecting of events
    }
  });
}

export { functions, firestore, firebase, analytics };
export const FieldValue = firebase.firestore.FieldValue;
export default firebase;
