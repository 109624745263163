import React from "react";
import type {
  BasicPrice,
  InternalProxyResponse,
  PricesForArticles,
} from "@coworker/types";
import useCallInternal, {
  callInternalApi,
  GET_PRICES_FOR_SHORT_IDS,
} from "./API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { useMyStore } from "../hooks/useMyStore";
import { FixaWindow } from "@coworker/reusable";
import { localeReverser } from "../helpers/locale.helper";

/**
 * Fetches all prices and currencies from SR (Selling Range), returning an array
 * of objects with the same structure as the result of usePriceAndCurrency. The array is sorted by price priority.
 */
export function usePricesForMultipleCurrencies(shortId?: string): BasicPrice[] {
  const store = useStoreId();
  const primary_locale = localeReverser(
    useMyStore()?.configuration?.locale?.primary_locale
  );
  const [response, setResponse] = React.useState<PricesForArticles>({});
  const callTasksService = (window as FixaWindow).callTasksService;

  React.useEffect(() => {
    if (shortId && callTasksService) {
      callInternalApi(
        `product_price?shortId=${shortId}&locale=${primary_locale}`
      ).then(setResponse);
    }
  }, [shortId, primary_locale, callTasksService]);

  const { data: pricesForArticles } = useCallInternal(
    GET_PRICES_FOR_SHORT_IDS,
    { store, ids: shortId },
    { blockRequest: !shortId || !store || callTasksService }
  ) as InternalProxyResponse<PricesForArticles>;

  if (shortId && store) {
    return (
      (callTasksService ? response?.[shortId] : pricesForArticles?.[shortId]) ||
      []
    );
  }

  return [];
}

/**
 * Fetches price and currency from SR (Selling Range)
 */
export function usePriceAndCurrency(shortId?: string) {
  return usePricesForMultipleCurrencies(shortId)[0];
}
