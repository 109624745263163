import { useTranslation } from "@coworker/locales";
import dayjs from "dayjs";
import { PIECE, METER } from "../constants/productUnits";
import statusTypes from "@coworker/functions/src/enums/statusTypes";
import { useLanguage } from "../core/hooks/useLanguage";
import { actorTypeKeys } from "@coworker/functions/src/enums/actorTypes";
import "dayjs/locale/en-gb";
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const localeRemaps = {
  en: "en-gb",
  no: "nb", // IKEA uses nb=Bokmal, which is the most common variant of Norwegian
};

const weekdayDates = [
  new Date("2020-01-12"), // Sunday goes first
  new Date("2020-01-06"),
  new Date("2020-01-07"),
  new Date("2020-01-08"),
  new Date("2020-01-09"),
  new Date("2020-01-10"),
  new Date("2020-01-11"),
];

// Locales list: https://github.com/iamkun/dayjs/tree/dev/src/locale
const dayjsLocales = {
  cs: () => import(/* webpackChunkName: "cs" */ "dayjs/locale/cs"),
  da: () => import(/* webpackChunkName: "da" */ "dayjs/locale/da"),
  de: () => import(/* webpackChunkName: "de" */ "dayjs/locale/de"),
  es: () => import(/* webpackChunkName: "es" */ "dayjs/locale/es"),
  eu: () => import(/* webpackChunkName: "eu" */ "dayjs/locale/eu"),
  fi: () => import(/* webpackChunkName: "fi" */ "dayjs/locale/fi"),
  fr: () => import(/* webpackChunkName: "fr" */ "dayjs/locale/fr"),
  hr: () => import(/* webpackChunkName: "hr" */ "dayjs/locale/hr"),
  hu: () => import(/* webpackChunkName: "hu" */ "dayjs/locale/hu"),
  it: () => import(/* webpackChunkName: "it" */ "dayjs/locale/it"),
  ja: () => import(/* webpackChunkName: "ja" */ "dayjs/locale/ja"),
  ko: () => import(/* webpackChunkName: "ko" */ "dayjs/locale/ko"),
  nb: () => import(/* webpackChunkName: "nb" */ "dayjs/locale/nb"),
  nl: () => import(/* webpackChunkName: "nl" */ "dayjs/locale/nl"),
  pl: () => import(/* webpackChunkName: "pl" */ "dayjs/locale/pl"),
  pt: () => import(/* webpackChunkName: "pt" */ "dayjs/locale/pt"),
  ro: () => import(/* webpackChunkName: "ro" */ "dayjs/locale/ro"),
  ru: () => import(/* webpackChunkName: "ru" */ "dayjs/locale/ru"),
  sk: () => import(/* webpackChunkName: "sk" */ "dayjs/locale/sk"),
  sl: () => import(/* webpackChunkName: "sl" */ "dayjs/locale/sl"),
  sr: () => import(/* webpackChunkName: "sr" */ "dayjs/locale/sr"),
  sv: () => import(/* webpackChunkName: "sv" */ "dayjs/locale/sv"),
  zh: () => import(/* webpackChunkName: "zh" */ "dayjs/locale/zh"),
};

function loadLocale(locale) {
  const loader = dayjsLocales[locale];
  loader && loader();
  dayjs.locale(locale);
}

export const capitalizeFirst = (text) =>
  text?.charAt ? text.charAt(0).toLocaleUpperCase() + text.slice(1) : "";

const useFormatter = () => {
  const { t } = useTranslation();
  const ikeaLocale = useLanguage() || "en_GB";
  const [intlLocale, languageCode] = formatLocale(ikeaLocale);

  const formatWeekday = {
    long: (wday) => dayjs(weekdayDates[wday]).format("dddd"),
    short: (wday) => dayjs(weekdayDates[wday]).format("ddd"),
    narrow: (wday) => dayjs(weekdayDates[wday]).format("dd"),
  };

  loadLocale(localeRemaps[languageCode] || languageCode || "en-GB");

  /**
   * Formats language code into locale code of form 'en-GB'
   * @param {string} profileLanguage language in form like 'en_GB'
   * @returns {[string, string, string]} [localeCode, languageCode, countryCode]
   */
  function formatLocale(profileLanguage) {
    const [languageCode, countryCode] = profileLanguage?.split("_") || [];
    return [`${languageCode}-${countryCode}`, languageCode, countryCode];
  }

  function toTimeAgo(timestamp, minimum) {
    if (!timestamp) return "";

    // TODO: When Tasks Service is live only do string comparison
    if (typeof timestamp === "string") {
      timestamp = new Date(timestamp);
    }

    if (minimum === "day") {
      // prevent showing hours
      const yesterdayMorningTimestamp = ((d) => {
        d.setDate(d.getDate() - 1); // Must run first, in case of summer/winter time or leap-year effects.
        return d.setHours(0, 0, 0, 0); // setHours returns milliseconds timestamp (!)
      })(new Date());

      if (timestamp > 0 && yesterdayMorningTimestamp < timestamp) {
        return t("lessThanDayString");
      }
    }

    // prevent displaying future times
    const time = dayjs(Math.min(timestamp, new Date().getTime()));
    return time.isValid() ? time.from(dayjs()) : "";
  }

  function formatNumber(value) {
    if (Number.isNaN(value) || !Number.isFinite(value)) return "";
    try {
      return new Intl.NumberFormat(intlLocale, {
        minimumFractionDigits: 0,
      }).format(value);
    } catch (e) {
      console.error("formatNumber formatting problem: ", e);
      return value;
    }
  }

  /**
   * Formats number into a short compact form according to user preferences locale
   * @param {number} value Number to format
   * @returns {string} formatted number
   * @example formatNumberShort(10000) === "10K"
   */
  function formatNumberShort(value) {
    // TODO: Why not remove the isNaN check? ["","1",Number.POSITIVE_INFINITY,-Number.NaN,false,true,undefined,null,{},[],()=>0,//].map(value=>(!Number.isFinite(value))) => all these are caught by !isFinite, did I miss something?
    if (Number.isNaN(value) || !Number.isFinite(value)) return "";
    let intl;
    try {
      // Some specific combination of Safari and locale and formatting options run into trouble.
      // Seen in https://sentry.io/organizations/ingka/issues/3515092553/
      // Safari 14.1.2 on OSX 10.14.6 in en_CA, store 529
      // https://github.com/WebKit/WebKit/blob/9cb35748705901155cb6d83963671c791046c035/Source/JavaScriptCore/runtime/IntlNumberFormat.cpp#L550-L586
      intl = Intl.NumberFormat(intlLocale, {
        compactDisplay: "short",
        notation: "compact",
        maximumSignificantDigits: 2,
      });
    } catch (error) {
      return `${value}`;
    }
    return intl.format(value);
  }

  const withoutDecimals = ["SEK"];

  function formatCurrency(value, currency) {
    if (!currency || currency === "N/A") return formatNumber(value);
    try {
      return new Intl.NumberFormat(intlLocale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
      }).format(Math.round(value, withoutDecimals.includes(currency) ? 0 : 2));
    } catch (e) {
      console.error("formatCurrency formatting problem: ", e);
      return formatNumber(value);
    }
  }

  /**
   * @param {string} first Person's first name
   * @param {string} last Person's surname
   * @returns {String} A name (capitalized) with an additional abreviated surname
   */
  function shortName(first, last) {
    const baseName = capitalizeFirst(first);
    if (!last?.length) return baseName;
    const firstOfSurname = last?.charAt(0)?.toUpperCase();
    return `${baseName} ${firstOfSurname}.`;
  }

  /**
   * @param {Object} user A potentially empty user object that contains name etc.
   * @param {String} uid Used to detect "SYSTEM" or "DELETED"
   * @returns {String} a short name for a person, or translations for "SYSTEM" or "Deleted user"
   */
  function formatFirstNameAndInitial(user, uid) {
    if (actorTypeKeys[uid]) return t(actorTypeKeys[uid]);
    const { given_name, surname, name, status } = user || {};
    if (status === statusTypes.DELETED) return t("deletedUserString");
    if (given_name || surname) return shortName(given_name, surname);
    const parts = name?.split ? name.split(" ") : [];
    if (parts[0]) return shortName(parts[0], parts[parts.length - 1]);
    return t("unknownUserString");
  }

  const formatDateLong = (date) => dayjs(date).format("MMMM D, YYYY");
  const formatDateMedium = (date) => dayjs(date).format("D MMM YYYY");
  const formatDateISO8601 = (date) => dayjs(date).format("YYYY-MM-DD");
  const formatDateShort = (date) => dayjs(date).format("DD/M");

  function productUnit(type, amount) {
    if (window.BLABLA_LANGUAGE) return "blabla";
    const n = amount && parseInt(amount);
    switch (type) {
      case METER:
      case "METERS":
        return t(n === 1 ? "meterUnit" : "metersUnit");
      case PIECE:
      case "PIECES": // some APIs return uppercase PIECE, some PIECES.
      case "pieces":
        return t(n === 1 ? "pieceUnit" : "piecesUnit");
      case "MULTI_PACK":
        return t(n === 1 ? "multiPackUnit" : "multiPacksUnit");
      case "PALLET":
        return t(n === 1 ? "palletUnit" : "palletsUnit");
      default:
        // console.log("unknown unit ", type);
        return type;
    }
  }

  /**
   * @param {Array} list
   * @returns {String} List formatted with the correct list separator. Often , or ;
   */
  // TODO: Use locale-specific separator. Try detecting via https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/toLocaleString
  const formatList = (list) => list.join(", ");

  /**
   * @param {String|Date} input A String or a Date.
   * @param {Object} format for use with toLocaleString. Default is a numeric format showing date and month.
   * @returns {String} Output from toLocaleString in the language chosen in Fixa.
   */
  const formatDate = (input, format) => {
    const date = input ? dayjs(input).toDate() : null;
    if (!date) return "";
    return date.toLocaleDateString(
      intlLocale,
      format || {
        month: "numeric",
        day: "numeric",
      }
    );
  };

  /**
   * @param {Date | number} start
   * @param {Date | number} end
   */
  const formatDateRange = (start, end) =>
    `${formatDate(start)} - ${formatDate(end)}`;

  return {
    formatLocale,
    formatDateLong,
    formatDateShort,
    formatSalesDate: (date) =>
      formatDate(date, {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      }),
    formatList,
    formatWeekday,
    formatNumber,
    formatNumberShort,
    formatCurrency,
    formatMonthname: (date) => dayjs(date).format("MMMM"),
    formatFirstNameAndInitial,
    productUnit,
    toTimeAgo,
    formatDate,
    formatDateRange,
    formatDateMedium,
    formatDateISO8601,
  };
};

export default useFormatter;
