import React from "react";

export const useFocus = () => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const setInputFocus = () => {
    inputRef.current && inputRef.current.focus();
  };
  const setInputBlur = () => {
    inputRef.current && inputRef.current.blur();
  };
  return { setInputFocus, setInputBlur, inputRef };
};
