import React from "react";
import Pill from "@ingka/pill";
import styles from "../activity-filters.module.css";
import {
  ActivityFilterType,
  FilterOptionsKeys,
} from "../../../types/filterOptionsTypes";
import { Trans } from "@coworker/locales";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";

interface CheckBoxPillProps {
  id: Partial<FilterOptionsKeys>;
  label: string;
  filters: ActivityFilterType;
  setFilters: (value: ActivityFilterType) => void;
}

export const CheckBoxPill = ({
  label,
  id,
  filters,
  setFilters,
}: CheckBoxPillProps) => {
  const fixaUID = useFixaUID();

  const [isChecked, setIsChecked] = React.useState(false);
  const filtersToAssignOnClick: Partial<ActivityFilterType> = {};
  const isCreatedBy = id === "createdBy";
  const isPriority = id === "priority";
  if (isCreatedBy) {
    filtersToAssignOnClick.createdBy = fixaUID;

    if (filters.createdBy === fixaUID) {
      filtersToAssignOnClick.createdBy = "";
    }
  }

  React.useEffect(() => {
    if (isCreatedBy && filters.createdBy === fixaUID) {
      setIsChecked(true);
    } else if (isPriority && filters.priority === true) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [filters, fixaUID, isCreatedBy, isPriority]);

  return (
    <Pill
      size="small"
      label={
        <div className={styles["checkbox-wrapper"]}>
          <Trans>{label}</Trans>
          <input
            className={styles["checkbox-input"]}
            type="checkbox"
            checked={isChecked}
          />
        </div>
      }
      onClick={() => {
        trackerHelper.trackActivityCheckboxPillClick(id);
        setIsChecked(!isChecked);
        if (isCreatedBy) {
          setFilters({
            ...filters,
            ...filtersToAssignOnClick,
          });
        } else {
          setFilters({
            ...filters,
            priority: !isChecked,
          });
        }
      }}
    />
  );
};
