import React from "react";
import styled from "styled-components";
import { Input } from "@coworker/components";
import { useTranslation, Trans } from "@coworker/locales";
import { useInputPopup } from "../InputPopup";
import { useWorkspacesAction } from "../../hooks/useWorkspaces";
import { updateUser } from "../../services/user.service";
import { usePhone, useFixaUID } from "../../core/auth/useLoggedInUser";
import { PositiveNumberKeypad } from "../PositiveNumberKeypad";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 80vh;
`;
const InputWrapper = styled.div`
  margin: 33px 29px 16px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 95px;
`;
const Text = styled.div`
  font-size: 12px;
  color: var(--grey700);
  margin: 6px 0;
`;
const Remove = styled.div`
  font-size: 14px;
  color: var(--grey900);
  margin: 8px 29px;
  text-decoration: underline;
  cursor: pointer;
`;

const PhoneNumbers = () => {
  const fixaUid = useFixaUID();
  const phone = usePhone() || "";
  const [number, setNumber] = React.useState(phone);
  const { pop } = useWorkspacesAction();
  const { t } = useTranslation();

  const { getInput } = useInputPopup();
  const deletePhone = async () => {
    if (
      await getInput("confirmation", {
        question: t("removeThisNumberQuestion"),
        description: t("surelyRemoveNumber"),
        positiveText: t("removeString"),
        negativeText: t("cancelString"),
        onClose: () => null,
      })
    ) {
      updateUser(fixaUid, { phone: "" });
      setNumber("");
      pop();
    } else {
      setNumber(phone);
    }
  };

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("ikeaPhoneNumber"),
        actions: [],
      }}
      noPadding
    >
      <Container>
        <InputWrapper>
          <Input
            testId="phoneInput"
            value={number}
            readOnly
            disabled
            onChange={() => null}
            required
            animatedText={
              phone ? (
                <Trans>ikeaPhoneNumber</Trans>
              ) : (
                <Trans>addPhoneString</Trans>
              )
            }
            max={15}
          />
          <Text>
            <Trans>enterYourPhoneForTodayString</Trans>
          </Text>
        </InputWrapper>
        <PositiveNumberKeypad
          disabled={
            !number ||
            number.trim().length === 0 ||
            number === phone ||
            number.split("").some((s) => s.match(/[a-z]/i))
          }
          initialValue={number}
          onUpdate={(value) => setNumber(value.trim().slice(0, 15))}
          onComplete={() => {
            updateUser(fixaUid, { phone: number });
            pop();
          }}
          completeTestid="addPhoneButton"
        />
        {phone && (
          <Remove data-testid="removeNumber" onClick={deletePhone}>
            <Trans>removeThisNumberString</Trans>
          </Remove>
        )}
      </Container>
    </FullScreenPopup>
  );
};

export default PhoneNumbers;
