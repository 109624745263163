import React from "react";
import { useInputPopup } from "../../components/InputPopup";
import { updateTask } from "../../services/task.service";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { useToastNotification } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import { ReactComponent as IssueOther } from "@coworker/reusable/svg/three-dots.svg";
import { ReactComponent as MistakenQuantity } from "../../assets/svg/quantity.svg";
import { ReactComponent as WrongType } from "../../assets/svg/rectangle-horizontal-double.svg";
import { RefillEditType } from "@coworker/types/lib/insights_refill_correction";
import { useTaskRefresher } from "../useWatchedTasks";

const activityTypes = require("@coworker/functions/src/enums/activityTypes");

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function useUpdateTaskRefill() {
  const uid = useUserId();
  const storeId = useStoreId();
  const { getInput } = useInputPopup();
  const { showToast } = useToastNotification();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const refreshTask = useTaskRefresher() as unknown as Function;

  const updateTaskRefill = React.useCallback(
    async (
      event,
      taskId: string,
      refill_quantity: string,
      productType: string,
      productId: string,
      setEditToggle: (enabled: boolean) => void
    ): Promise<boolean> => {
      event.stopPropagation();
      if (!taskId) return false;

      const refillEditTypeTransKey: Record<RefillEditType, string> = {
        MISTAKEN_QUANTITY: "mistakenQuantityString",
        WRONG_TYPE: "wrongTypeString",
        OTHER: "otherString",
      };

      const reason = await getInput("taskAction", {
        customTitle: t("whyAreYouEditingQuantityString"),
        content: [
          {
            icon: MistakenQuantity,
            name: t(refillEditTypeTransKey.MISTAKEN_QUANTITY),
            type: "MISTAKEN_QUANTITY",
          },
          {
            icon: WrongType,
            name: t(refillEditTypeTransKey.WRONG_TYPE),
            type: "WRONG_TYPE",
          },
          {
            icon: IssueOther,
            name: t(refillEditTypeTransKey.OTHER),
            type: "OTHER",
            descriptionRequired: true,
          },
        ],
      });

      if (!reason) return false;

      const actionId = `corrected-${new Date().getTime()}`;
      const quantity = await getInput("selectAmount", {
        title: t("piecesString"),
        oldAmountType: "PIECES",
        oldActualAmount: refill_quantity,
        productId: productId,
        productArticleType: productType,
        navigateToTaskForm: false,
        showTeamChange: false,
        initializeWithOldAmount: true,
      });

      if (!quantity) return false;

      const data = {
        action_id: actionId,
        last_editor_id: uid,
        activity_type: activityTypes.REFILL_QUANTITY_EDIT,
        // edited_at: FieldValue.serverTimestamp(), // TODO: Not used from BQ and likely not needed?
        actual_refilled_quantity: quantity.actualAmount,
        refilled_quantity_edit_from: refill_quantity,
        refilled_quantity: quantity.amount,
        refilled_type: quantity.amountType,
        refilled_edit_reason: reason.issue,
        ...(reason.description && {
          refilled_edit_description: reason.description,
        }),
      };
      setLoading(true);

      try {
        await updateTask(data, taskId, "", storeId);
        refreshTask(taskId, data, { state: "COMPLETED" });
        // Timeout required to get the updated quantity in postgres when we re-fetch data for graph and locations
        await timeout(3000);
        showToast(t("refillQuantityUpdateString"));
      } catch (error) {
        showToast(t("refillQuantityNotUpdatedString"));
        console.error("Insights refill quantity edit failed", taskId);
      }

      setEditToggle(false);

      return true;
    },
    [uid, getInput, showToast, t, refreshTask, storeId]
  );

  return {
    call: updateTaskRefill,
    loading,
  };
}
