import { useMutation, useQueryClient } from "@tanstack/react-query";
import { callInternalApi } from "../API/useCallInternal";
import { useStoreId } from "../../core/auth/useLoggedInUser";

export function useReadAllNotifications() {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  return useMutation({
    mutationFn: () => readAllNotifications(storeId),
    onSuccess: () => {
      queryClient.invalidateQueries(["notifications"]);
    },
  });
}

async function readAllNotifications(storeId: string) {
  await callInternalApi("my/events/clear", { store_id: storeId });
}
