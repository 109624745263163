interface DescribeTheProblemListType {
  type: string;
  transKey: string;
}

export const describeTheProblemList: DescribeTheProblemListType[] = [
  {
    type: "LOOK_AND_FEEL",
    transKey: "pqdLookAndFeel",
  },
  {
    type: "ASSEMBLY",
    transKey: "pqdAssembly",
  },
  {
    type: "PRODUCT_MALFUNCTION",
    transKey: "pqdProductMalfunction",
  },
  {
    type: "LABELS",
    transKey: "pqdLabels",
  },
  {
    type: "PACKAGING",
    transKey: "pqdPackaging",
  },
  {
    type: "PRODUCT_INFORMATION",
    transKey: "pqdProductInformation",
  },
  {
    type: "OTHER",
    transKey: "otherString",
  },
];
