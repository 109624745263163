import React from "react";
import { useTeamId, useStoreId } from "../core/auth/useLoggedInUser";
import { useAssignableTeams } from "../core/hooks/useAssignableTeams";
import useCallInternal from "./API/useCallInternal";
import useFormatter from "./useFormatter";
import { sign } from "@coworker/reusable/helpers/math"; // like java and others: Math.sign(x) => -1, 0, 1
import {
  getCachedInternalApi,
  getLatestCacheValue,
} from "./API/useCallInternal";
import useFlag, { FLAGS } from "./useFlag";
// The data parameter should look like:
// {
//   team_assignments_picking: {
//     [teamId0]: timestamp0,
//     "fjh1243l5ksd457076804sd": 162837498234,
//   },
//   team_assignments_refilling: {
//     [teamId1]: timestamp1,
//     [teamId2]: timestamp2,
//     "fjh1243l5ksd457076804sd": 162837498234,
//   },
// }
function useParsedSuggestions(data) {
  const { formatDateShort } = useFormatter();
  const teamId = useTeamId();
  const teams = useAssignableTeams();

  return React.useMemo(() => {
    const found = {};
    for (const which of [
      "team_assignments_picking",
      "team_assignments_refilling",
    ]) {
      const map = data?.[which] || {};
      for (const [gid, timestamp] of Object.entries(map)) {
        if (gid === teamId) continue; // No need to suggest user's own team, for now.
        const name = teams.find((team) => team.id === gid)?.name;
        if (!name) continue; // Skip teams we cannot find (as they're surely disabled or removed)
        found[gid] = [gid, [name, formatDateShort(timestamp), timestamp]];
      }
    }
    return Object.values(found).sort((a, b) => sign(b[1][2] - a[1][2]));
  }, [data, teams, formatDateShort, teamId]);
}

export function useAssignmentSuggestion(shortId = "") {
  const storeId = useStoreId();
  const callTasksService = useFlag(FLAGS.CALL_TASKS_SERVICE);
  // From cmp-fixa internalProxy:
  const recentAssignees = useCallInternal(
    "assigneeSuggestion",
    { store: storeId, short_id: shortId },
    { blockRequest: !storeId || !shortId || callTasksService }
  );

  const parsedRecentAssignees = useParsedSuggestions(recentAssignees?.data);
  // From tasks-service:
  const url = shortId ? `tasks/assignee_suggestion?short_id=${shortId}` : "";
  const [response, setResponse] = React.useState(
    getLatestCacheValue(url) || {}
  );
  React.useEffect(() => {
    if (callTasksService) {
      getCachedInternalApi(url, setResponse, 300_000); // cache 5 minutes
    }
  }, [url, callTasksService]);
  const parsedResponse = useParsedSuggestions(response);

  return callTasksService ? parsedResponse : parsedRecentAssignees;
}
