import React from "react";
import { PositionedLoaderIcon } from "@coworker/reusable/Loader";
import { TopBar } from "../../components/Layout/Heading";

interface LoadableProps {
  children: React.ReactNode;
  loading: boolean;
  topbar: React.ReactNode;
}

export function Loadable({ children, loading, topbar }: LoadableProps) {
  return (
    <>
      {topbar}
      {loading ? <PositionedLoaderIcon /> : children}
    </>
  );
}

export function PageStructure({
  loading,
  title,
  subTitle,
  children,
  rightAction,
  topContent,
}: LoadableProps & {
  title: React.ReactNode;
  subTitle: React.ReactNode;
  rightAction: React.ReactNode;
  topContent: React.ReactNode;
}) {
  return (
    <Loadable
      loading={loading}
      topbar={
        <TopBar title={title} subTitle={subTitle} rightAction={rightAction}>
          {topContent}
        </TopBar>
      }
    >
      {children}
    </Loadable>
  );
}
