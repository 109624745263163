import React from "react";
import { Trans } from "@coworker/locales";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import useFormatScheduled from "../../../hooks/useFormatScheduled";

function Recurrent({ task }) {
  const { startDate, endDate, previewHint } = useFormatScheduled(task.planned);
  return (
    <>
      <DetailSection>
        <DetailSectionLeft>
          <SectionTitle>
            <Trans>frequencyString</Trans>
          </SectionTitle>
          <SectionText data-testid="taskFrequency">{previewHint}</SectionText>
        </DetailSectionLeft>
      </DetailSection>
      <DetailSection>
        <DetailSectionLeft>
          <SectionTitle>
            <Trans>startDateString</Trans>
          </SectionTitle>
          <SectionText data-testid="taskStartDate">{startDate}</SectionText>
        </DetailSectionLeft>
      </DetailSection>
      <DetailSection>
        <DetailSectionLeft>
          <SectionTitle>
            <Trans>endDateString</Trans>
          </SectionTitle>
          <SectionText data-testid="taskEndDate">{endDate}</SectionText>
        </DetailSectionLeft>
      </DetailSection>
    </>
  );
}

function Scheduled({ task }) {
  const { startDate } = useFormatScheduled(task.planned);
  return (
    <>
      <DetailSection>
        <DetailSectionLeft>
          <SectionTitle>
            <Trans>scheduledForString</Trans>
          </SectionTitle>
          <SectionText data-testid="taskStartDate">{startDate}</SectionText>
        </DetailSectionLeft>
      </DetailSection>
    </>
  );
}

function Schedule({ task }) {
  if (!task.planned) return null;
  if (task.planned.recurring) {
    return <Recurrent task={task} />;
  }
  return <Scheduled task={task} />;
}

export default Schedule;
