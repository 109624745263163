import React from "react";
import { Trans } from "@coworker/locales";

// Skapa
import Toast from "@ingka/toast";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/toast/dist/style.css";
import "@ingka/focus/dist/style.css";

interface ToastProps {
  text?: string;
  isOpen: boolean;
  onCloseRequest: () => void;
}

export const SkapaToast = ({ text, isOpen, onCloseRequest }: ToastProps) => {
  return (
    <Toast
      style={{
        height: "70px",
        top: `calc(100vh - 70px)`,
      }}
      text={<Trans>{text}</Trans>}
      isOpen={isOpen}
      onCloseRequest={() => {
        onCloseRequest();
      }}
    />
  );
};
