import React from "react";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import activityTypes from "@coworker/functions/src/enums/activityTypes";
import refillTypes from "@coworker/functions/src/enums/refillTypes.json";
import { useWorkspacesAction, useWorkspacesState } from "../useWorkspaces";
import useForm from "../useForm";
import { splitLocation } from "../../services/locations.service";
import { useCachedBasicProduct } from "../../services/products.service";
import {
  useUserId,
  useStoreId,
  useTeamId,
} from "../../core/auth/useLoggedInUser";
import { useTaskImages } from "../../hooks/useTaskImages";
import { useWatchedTask } from "../useWatchedTasks";

export const usePrepareForm = ({
  baseTask,
  task_id,
  config,
  formType,
  isCreate,
}) => {
  const { setNavigationState } = useWorkspacesAction();
  const { navigationState } = useWorkspacesState();
  const { formData } = navigationState;

  // Instead of fetching directly from firestore, getting the data via useWatchedTask, which will work for fetching tasks data from tasks service as well.
  const task = useWatchedTask(task_id);

  const oldImages = useTaskImages(task_id, 3_000);

  const user_id = useUserId();
  const store_id = useStoreId();
  const team_id = useTeamId();

  const getInitialForm = React.useCallback(() => {
    if (formData) {
      return formData;
    }
    if (!isCreate) {
      return {
        ...task,
        activity_type: activityTypes.EDIT,
        images: oldImages,
        last_editor_id: user_id,
      };
    }

    const newTask = {
      task_type: config.enumType,
      creator_id: user_id,
      last_editor_id: user_id,
      store_id,
      archived_at: Number.MAX_SAFE_INTEGER,
      ...baseTask,
    };

    if (config.enumType === taskTypeOptions.ADDON) {
      newTask.refilled_by_me = false;
      newTask.refill_type = refillTypes.REGULAR;
    }

    return newTask;
  }, [
    baseTask,
    config.enumType,
    formData,
    isCreate,
    oldImages,
    store_id,
    user_id,
    task,
  ]);

  const { form, editFields, editField } = useForm(getInitialForm());

  React.useEffect(() => {
    const { title, ...formData } = form;
    setNavigationState({ formData });
    // We only want this when form data/object changes, and when that happens
    // router will always be fresH
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  React.useEffect(() => {
    if (navigationState) {
      const { product, location_id, location, formData } = navigationState;
      if (!formData) {
        if (product) {
          editFields({
            product_article_id: product.product,
            product_article_type: product.type,
            supplier_number: product.supplierNumber,
            scanned_supplier_number: product.supplierNumber,
          });
        }
        if (location_id) {
          editField("location", location_id);
        }
      }
      if (location) {
        const [locationCode, locationDepartment] = splitLocation(location);
        editFields({
          location,
          location_custom: locationDepartment ? "" : locationCode,
          location_department: locationDepartment ? locationDepartment : "",
          location_grid_code: locationCode,
        });
      }
    }
    // We want this only on first run
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fill in cache fields on task document for speedier task list scrolling.
  const cached = useCachedBasicProduct(form.product_article_id);
  React.useEffect(() => {
    if (
      form.product_article_id &&
      cached?.type &&
      cached?.id === form.product_article_id
    ) {
      const { type, name, panumber, smallImage, descriptives, image } = cached;
      const changes = {};
      changes.product_article_type = type ?? "";
      changes.item_name = name ?? "";
      if (panumber) changes.panumber = panumber;
      if (image || smallImage)
        changes.item = {
          image_small: smallImage || image || "",
        };

      changes.item_type = descriptives?.[0] ?? "";
      changes.item_color = descriptives?.[1] ?? "";
      // changes.product_article_type = cached.type; // Sorry, by bad, need to do more careful code reviews in the future. With more and smaller PRs.
      changes.item = {
        ...changes.item,
        measurements: descriptives?.[2] ?? "",
      };
      editFields(changes);
    }
  }, [form.product_article_id, cached, editFields]);

  const isFormValid = React.useMemo(
    () => config.validate(form, task),
    [form, config, task]
  );

  const [preparedForm, images] = React.useMemo(() => {
    const { images = [], ...formData } = form;

    const data = {
      ...formData,
    };

    data.team_assignments_refilling = !data.team_assignments_refilling
      ? {}
      : data.team_assignments_refilling;
    data.team_assignments_picking = !data.team_assignments_refilling
      ? {}
      : data.team_assignments_refilling;

    if (formData.planned && !task_id) {
      data.task_type = taskTypeOptions.PLANNED;
    }
    if (formData.refilled_by_me) {
      data.state = taskStateOptions.COMPLETED;
      data.refilled_quantity = data.requested_quantity;
      data.actual_refilled_quantity = data.actual_requested_quantity;
      data.pick_quantity = data.requested_quantity;
      data.actual_pick_quantity = data.actual_requested_quantity;
      data.refilled_type = data.requested_type;
      data.task_finisher_id = user_id;
      data.assigned_team_id = team_id;
      data.assigned_user_id = user_id; // TODO: Maybe avoid overwriting if assignment was already done!?
    } else if (formData.assigned_user_id) {
      data.state = taskStateOptions.ASSIGNED;
    } else {
      data.state = taskStateOptions.UNASSIGNED;
    }
    return [data, images];
  }, [form, user_id, team_id, task_id]);

  if ((form.images?.length ?? 0) === 0) form.images = oldImages;

  return {
    preparedForm,
    images,
    isFormValid,
    editFields,
    oldImages,
    task,
    form,
    user_id,
  };
};
