const sikLocales = [
  "ae/en",
  "at/de",
  "au/en",
  "be/fr",
  "be/nl",
  "bh/ar",
  "bh/en",
  "ca/en",
  "ca/fr",
  "ch/de",
  "ch/en",
  "ch/fr",
  "ch/it",
  "cn/en",
  "cn/zh",
  "cz/cs",
  "de/de",
  "dk/da",
  "eg/en",
  "es/ca",
  "es/en",
  "es/es",
  "es/eu",
  "es/gl",
  "fi/fi",
  "fr/fr",
  "gb/en",
  "hr/hr",
  "hu/hu",
  "ie/en",
  "in/en",
  "it/it",
  "jo/ar",
  "jo/en",
  "jp/en",
  "jp/ja",
  "kr/en",
  "kr/ko",
  "kw/ar",
  "kw/en",
  "ma/ar",
  "ma/en",
  "ma/fr",
  "my/en",
  "my/ms",
  "nl/en",
  "nl/nl",
  "no/no",
  "pl/pl",
  "pt/en",
  "pt/pt",
  "qa/en",
  "ro/ro",
  "rs/sr",
  "ru/ru",
  "sa/ar",
  "sa/en",
  "se/sv",
  "sg/en",
  "sk/sk",
  "si/sl",
  "th/en",
  "th/th",
  "ua/uk",
  "us/en",
  "cn/zh",
];

function pickSIKlocale(language, isChina) {
  const [iso639_1, countryCode] = (language && language.split("_")) || ["", ""];
  const exact = `${countryCode && countryCode.toLowerCase()}/${iso639_1}`;

  // TODO: do countries have different product ranges?
  const manualMappings = {
    it_AT: "it/it",
    sl_AT: "si/sl",
    de_NL: "de/de",
    ru_PL: "ru/ru",
    fi_SE: "fi/fi",
    es_US: "es/es",
  };

  // ikea.cn/search-api supports only cn/zh. Example: https://www.ikea.cn/api-search/cn/zh/search-box?q=%E5%95%86&c=cnweb&v=20211124
  // also returns results for query "ps" while no results are returned with locale zh/en
  if (
    (typeof window !== "undefined" && window.location.host.includes(".cn")) ||
    isChina
  )
    return "cn/zh";

  return (
    (sikLocales.includes(exact) && exact) || // Try exact match
    manualMappings[language] || // Manual mappings
    "gb/en" // 2020-06-10: These IKEA locales (2020-06-10) get en/eb: "en_SE", "en_FR", "en_HR", "en_IT", "en_RS", "en_RO", "en_AT", "en_PL", "en_RU",
  );
}

module.exports = { pickSIKlocale };
