import { useStoreId, useHashedUid } from "../../core/auth/useLoggedInUser";
import { postToTasksService } from "./tasks.service.helper";

const _clientVersion = require("@coworker/functions/src/version.json").version;

export function useCreateFeedbackV1(question, id, type) {
  const storeId = useStoreId();
  const hashedUid = useHashedUid();

  return async (rating, comment) => {
    let uidHash = "";
    uidHash = hashedUid;
    await postToTasksService(
      "/v1/feedback/create",
      {
        uidHash,
        storeId,
        question,
        questionId: id,
        questionType: type,
        comment,
        rating,
        dismissed: !!rating,
        appVersionNumber: _clientVersion,
        createdAt: new Date().toISOString(),
      },
      () => null
    );
  };
}
