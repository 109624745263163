import React from "react";
import { callInternalApi } from "./API/useCallInternal";
import { notClosedTooLongAgo } from "../helpers/taskFilters";
import { useStoreId } from "../core/auth/useLoggedInUser";

const empty = [];

export function useTasksForProduct(shortId) {
  const storeId = useStoreId();
  const [response, setResponse] = React.useState({ list: [], loading: true });
  React.useEffect(() => {
    if (shortId) {
      callInternalApi(`tasks_for_product/${shortId}`, {
        store_id: storeId,
      }).then(setResponse);
    }
  }, [shortId, storeId]);

  return React.useMemo(() => {
    const chosen = [];
    let ofWhichClosed = 0;
    let formattedCount = 0;
    const { list } = response?.data || {};
    for (const task of list || []) {
      if (task.task_type === "PRODUCT_QUALITY") continue;
      if (task.state === "COMPLETED") continue;
      if (task.state === "CLOSED") {
        if (!notClosedTooLongAgo(task)) continue; // Ignore too old closed tasks.
        ofWhichClosed += 1;
      }
      formattedCount += 1; // This is the total count, excluding PQ. And excluding CLOSED unless flag is enabled.
      chosen.push(task);
    }

    return {
      list: chosen.length ? chosen : empty,
      formattedCount,
      ofWhichClosed,
    };
  }, [response]);
}
