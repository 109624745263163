import React from "react";
import Text from "@ingka/text";
import styles from "./super-users-title.module.css";

interface SuperUsersTitleProps {
  title: string;
  subTitle: string;
}

export const SuperUsersTitle: React.FC<SuperUsersTitleProps> = ({
  title,
  subTitle,
}) => {
  return (
    <div className={styles["header-container"]}>
      <Text tagName={"h1"}>{title}</Text>
      <Text bodySize={"m"}>{subTitle}</Text>
    </div>
  );
};
