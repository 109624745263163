import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

const databaseEnvironment =
  process.env.REACT_APP_LOCAL_ALL || process.env.REACT_APP_LOCAL_DATABASE
    ? "emulated"
    : process.env.REACT_APP_ENV || "dev";

const bannerColors = {
  test: "var(--pink)",
  dev: "var(--grey700)",
  emulated: "var(--light-blue)",
  stage: "var(--yellow)",
  ci: "var(--green)",
};

const textColors = {
  dev: "var(--white)",
};

const Label = styled.div`
  padding: 2px 7px;
  font-weight: bold;
  display: inline-block;
  margin-top: 9px;
  font-size: 12px;
  @media (max-width: 580px) {
    /* RDT */
    font-size: 9px;
    margin-top: 0;
  }
  width: fit-content;
  text-transform: uppercase;
  background-color: ${bannerColors[databaseEnvironment]};
  color: ${textColors[databaseEnvironment]};
`;

const renamedEnvironment = {
  dev: "DEVELOPMENT",
  test: "QA",
  stage: <Trans>demoEnvironmentString</Trans>,
};

export function EnvironmentTag() {
  if (databaseEnvironment.includes("prod")) return null; // Never show this tag on prod environments. We've not been good at updating here when new prod environments were added, so .includes should be the most future proof option here.
  return (
    <Label>
      {renamedEnvironment[databaseEnvironment] || databaseEnvironment}
    </Label>
  );
}
