import React from "react";
import { useTranslation } from "react-i18next";
const { getI18nLocaleCode } = require("../translations");

function useModifiedTranslation() {
  const { i18n, t, ready } = useTranslation();
  const [lastIkeaLanguage, setLastIkeaLanguage] = React.useState();

  /**
   * @type {import("react-i18next").TFunction<"translation">}
   */
  const modifiedT: (key: string, options?: any) => string = React.useCallback(
    (key, options) => {
      if (window.BLABLA_LANGUAGE) {
        return "blabla";
      } else if (window.SHOW_TRANSLATION_KEYS) {
        return key;
      } else {
        return `${t(key, { ...options, returnDetails: false })}`;
      }
    },
    [t]
  );

  const changeLanguage = React.useCallback(
    (ikeaLangCode) => {
      setLastIkeaLanguage(ikeaLangCode);
      i18n.changeLanguage(getI18nLocaleCode(ikeaLangCode));
    },
    [i18n]
  );

  return React.useMemo(
    () => ({
      t: modifiedT,
      ready,
      i18n,
      changeLanguage,
      language: lastIkeaLanguage,
    }),
    [modifiedT, ready, i18n, changeLanguage, lastIkeaLanguage]
  );
}

export default useModifiedTranslation;
