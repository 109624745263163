const { pqHowOptions } = require("./pqHowOptions");

const pqHowList = [
  {
    value: pqHowOptions.CUSTOMER_TOLD,
    translationKey: "pqCustomerToldString",
    deprecated: true,
  },
  {
    value: pqHowOptions.CUSTOMER_RETURNED,
    translationKey: "pqQustomerReturnedString",
  },
  {
    value: pqHowOptions.DISPLAY_ITEM,
    translationKey: "pqDisplayItemString",
  },
  {
    value: pqHowOptions.UNPACKING,
    translationKey: "pqUnpackString",
  },
  {
    value: pqHowOptions.ASSEMBLING,
    translationKey: "pqAssembleString",
  },
  {
    value: pqHowOptions.IN_LOCATION,
    translationKey: "pqSalesLocationString",
  },
  {
    value: pqHowOptions.CUSTOMER_INFORMED,
    translationKey: "pqCustomerToldString",
    requiresText: true,
  },
  {
    value: pqHowOptions.OTHER,
    translationKey: "otherString",
    requiresText: true,
  },
  {
    value: pqHowOptions.UNPACKING_ASSEMBLING,
    translationKey: "pqUnpackOrAssembleString",
    deprecated: true,
  },
];

module.exports = { pqHowList };
