import React from "react";
import styled, { css } from "styled-components";
import { Trans } from "@coworker/locales";
import { Button } from "@coworker/components";
import { useInputPopup } from "../../components/InputPopup";
import { calculateFontSize } from "@coworker/reusable";
import { logEvent } from "../../helpers/tracker";

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 20px;
  padding-left: 12px;
  padding-right: 12px;
  background-color: var(--white);
  border-radius: 5px;
  height: 140px;
`;

const UpperLabel = styled.div`
  padding-top: 24px;
  font-size: 11px;
`;

const Title = styled.div`
  max-height: 80px;
  overflow-y: clip;
  font-weight: bold;
  font-size: 14px;
  ${({ adjustedSize }) => css`
    font-size: ${adjustedSize};
  `}
`;

const ChangeButton = styled(Button)`
  width: 106px;
  height: 34px;
  justify-content: space-between;
  border: 1px solid var(--black);
`;

const StoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export function StoreChange({ store, hideButton, onStoreChange }) {
  const { getInput } = useInputPopup();

  const openStoreList = async () => {
    logEvent("ce:insights:select_store_open");
    const selectedStore = await getInput("selectStore", {
      initialStore: store?.id,
    });
    if (selectedStore) {
      logEvent("ce:insights:select_store_picked", { selectedStore });
    } else logEvent("ce:insights:select_store_close");
    if (selectedStore) onStoreChange(selectedStore);
  };
  const title = `${store?.name || ""} ${store?.id || ""}`;
  const adjustedSize = calculateFontSize(title.length, 20, 45, 20);

  return (
    <Container data-testid="store-change-box">
      <UpperLabel>
        <Trans>currentStoreString</Trans>
      </UpperLabel>
      <StoreWrapper>
        <Title adjustedSize={adjustedSize}>{title}</Title>
        {!hideButton && (
          <ChangeButton
            text={<Trans>changeTeamString</Trans>}
            onClick={openStoreList}
          />
        )}
      </StoreWrapper>
    </Container>
  );
}
