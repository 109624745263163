import useCallSIK, { SIK_AUTOCOMPLETE } from "./API/useCallSIK";

function transformData(data) {
  const completions = data?.searchBox?.universal;
  // new field returns completions and products and we are only interested in
  // completions for now (could also do map + filter but that does two round trips)
  return (completions || []).reduce((completions, item) => {
    if (item?.completion) {
      return completions.concat(item.completion?.text);
    }
    return completions;
  }, []);
}
/**
 * Calls autocomplete function and returns data and loading states.
 *
 * @param {String} [query = '']
 * @param {Object} options
 * @param {Boolean} options.blockRequest Boolean option if the request goes through
 * @param {Number} options.cacheTimeout
 *
 * @returns {Object} Returns data and loading states.
 */
export default function useAutocompleteProducts(query = "", options = {}) {
  const { data, loading, error, dataIsFromAPI } = useCallSIK(
    SIK_AUTOCOMPLETE,
    { q: query },
    options
  );

  return {
    data: transformData(data),
    loading,
    dataIsFromAPI,
    error: error ? "Unable to load search suggestions" : "",
  };
}
