/**
 * @param {String} string Input
 * @returns {String} without diacritics and no leading or trailing spaces.
 */
export function clean(string) {
  return latinize(string).toLowerCase().trim();
}

/**
 * @param {String} string Input
 * @returns {String} with diacritics removed
 */
function latinize(string) {
  if (!string) return "";
  return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/**
 * @param {String} query Space separated words.
 * @returns {RegExp} Regexo that makes a substring match on any of the words supplied in query.
 */
export function makeMatcher(query, requireAll) {
  const cleanQuery = clean(query);
  if (!cleanQuery) return;

  const regexFormula = requireAll
    ? lookaheadAll(cleanQuery.split(wordSplit)) // Used for exclusive filtering.
    : cleanQuery.replace(wordSplit, "|"); // Used for highlighting.

  return new RegExp(regexFormula, "g");
}

/**
 * Converts string to PascalCase. Splits words using wordBoundary
 * @param {string} string
 * @param {RegExp} wordBoundary Regex to split string into words, defaults to non word characters and underscores
 */
export function pascalCase(string, wordBoundary = /[^\wå]+|_/gu) {
  return string.split(wordBoundary).map(upperFirst).join("");
}

/**
 * Locale aware and unicode compatibly capitalizes first letter of string
 * @param {string} string
 */
function upperFirst(string) {
  const [first, ...rest] = string;
  return `${first.toLocaleUpperCase()}${rest.join("")}`;
}

const wordSplit = /[\W\s]+/;
const lookaheadAll = (words) => words.map((part) => `(?=.*${part})`).join("");
