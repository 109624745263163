import type { AddonTask } from "@coworker/types/lib/tasks/addon";
import type { Task } from "@coworker/types/lib/tasks/base";
import { sign } from "./math";
import { sortingTypes } from "@coworker/types/lib/taskSortingTypes";

type TaskCreatedAt = Pick<Task, "created_at">;
type TaskFinishedAt = Pick<Task, "finished_at">;
type TaskUpdatedAt = Pick<Task, "updated_at">;
type TaskPriorityFlag = Pick<Task, "priority_flag">;
type AddonTaskLocationProperties =
  | "location_custom"
  | "location_grid_code"
  | "pickup_location_custom"
  | "pickup_location_grid_code";
type AddonTaskLocations = Pick<AddonTask, AddonTaskLocationProperties>;
type SortingProperties = TaskCreatedAt &
  TaskFinishedAt &
  TaskUpdatedAt &
  TaskPriorityFlag &
  Partial<AddonTaskLocations>;

type TaskSorterFunc = (
  first: SortingProperties,
  second: SortingProperties
) => 0 | 1 | -1;

function priorityFirst(first: TaskPriorityFlag, second: TaskPriorityFlag) {
  if (first.priority_flag && !second.priority_flag) {
    return -1;
  } else if (!first.priority_flag && second.priority_flag) {
    return 1;
  }
  return 0;
}

function closedLast(first: TaskFinishedAt, second: TaskFinishedAt) {
  if (first.finished_at && !second.finished_at) {
    return 1;
  } else if (!first.finished_at && second.finished_at) {
    return -1;
  }
  return 0;
}

function newestFirst(first: TaskCreatedAt, second: TaskCreatedAt) {
  if (!second.created_at || !first.created_at) return 0;
  // TODO: When Tasks Service is live only do string comparison
  if (typeof first.created_at === "string") {
    if (second.created_at === first.created_at) return 0;
    return second.created_at > first.created_at ? 1 : -1;
  }
  return sign(second.created_at - first.created_at);
}

function newestLast(first: TaskCreatedAt, second: TaskCreatedAt) {
  return newestFirst(second, first); // Swap first and second
}

function openPrioThenNewLastClosed(
  first: TaskCreatedAt & TaskFinishedAt & TaskPriorityFlag,
  second: TaskCreatedAt & TaskFinishedAt & TaskPriorityFlag
) {
  return (
    closedLast(first, second) ||
    priorityFirst(first, second) ||
    newestFirst(first, second)
  );
}

function prioFirstAnd(sorter: TaskSorterFunc) {
  return (first: SortingProperties, second: SortingProperties) =>
    priorityFirst(first, second) || sorter(first, second);
}

function recentlyClosedFirstThenRecentlyUpdated(
  first: TaskFinishedAt & TaskUpdatedAt,
  second: TaskFinishedAt & TaskUpdatedAt
) {
  if (second.finished_at && first.finished_at) {
    const a = sign(second.finished_at - first.finished_at);
    if (a !== 0) return a;
  }
  return sign(second.updated_at - first.updated_at);
}

const getLocationField = (task: AddonTaskLocations, prefix: "pickup_" | "") =>
  task[`${prefix}location_custom`] || task[`${prefix}location_grid_code`];

function locationAscending(isPicking: boolean) {
  const prefix = isPicking ? "pickup_" : "";
  return (first: SortingProperties, second: SortingProperties) => {
    if (isAddonTask(first) && isAddonTask(second)) {
      return getLocationField(first, prefix)?.localeCompare(
        getLocationField(second, prefix)
      ) as 0 | 1 | -1;
    } else {
      return 0;
    }
  };
}

export function createdAtAscending(
  first: Partial<TaskCreatedAt>,
  second: Partial<TaskCreatedAt>
) {
  if (first.created_at && second.created_at) {
    return sign(first.created_at - second.created_at);
  } else if (!first.created_at && second.created_at) {
    return 1;
  } else if (first.created_at && !second.created_at) {
    return -1;
  } else {
    return 0;
  }
}

export function sortTasks(
  tasks: SortingProperties[],
  activeSortFilter?: string,
  type?: string,
  sortByPickingLocation = false
) {
  const sortOption = getSorter(sortByPickingLocation, activeSortFilter, type);
  tasks.sort(sortOption);
  return tasks as Task[];
}

function getSorter(
  sortByPickingLocation: boolean,
  activeSortFilter?: string,
  type?: string
) {
  if (["closed", "created_by_me_closed"].includes(type!)) {
    return recentlyClosedFirstThenRecentlyUpdated;
  } else if ("created_by_me" === type) {
    return openPrioThenNewLastClosed;
  } else {
    if (activeSortFilter === sortingTypes.BY_GRID_CODE) {
      return prioFirstAnd(locationAscending(sortByPickingLocation));
    } else if (activeSortFilter === sortingTypes.BY_OLDEST_FIRST) {
      return prioFirstAnd(newestLast);
    } else {
      return prioFirstAnd(newestFirst);
    }
  }
}

function isAddonTask(task: Partial<Task>): task is AddonTask {
  return task.task_type === "ADDON";
}
