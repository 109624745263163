import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as IKEAlogoSVG } from "../assets/svg/IKEA-logo.svg";
import ConditionalWrapper from "./ConditionalWrapper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LogoIkea = styled(IKEAlogoSVG)`
  margin-bottom: 1rem;
  width: 3.625rem;
  ${({ splash }) =>
    splash &&
    css`
      width: 8.75rem;
    `};
  ${(props) =>
    props.sitename &&
    css`
      width: 7.5rem;
      padding-right: 25px;
      margin-right: 25px;
      border-right: 1px solid white;
      margin-bottom: 0;
    `}
`;

const LogoIkeaWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const SiteName = styled.div`
  color: var(--white);
  font-size: 18px;
  letter-spacing: -0.2px;
`;

const LogoCoWorker = styled(SiteName)`
  font-size: 14px;
  font-weight: bold;
`;

const IKEAlogo = ({ splash, sitename }) => (
  <Container>
    <ConditionalWrapper
      condition={sitename}
      wrapper={(children) => (
        <LogoIkeaWrapper>
          {children}
          <SiteName>{sitename}</SiteName>
        </LogoIkeaWrapper>
      )}
    >
      <LogoIkea sitename={sitename} splash={splash} />
    </ConditionalWrapper>
    {splash && <LogoCoWorker>{splash}</LogoCoWorker>}
  </Container>
);

export default IKEAlogo;
