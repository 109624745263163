import { useQuery } from "@tanstack/react-query";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { fetchCountOnDivision } from "../ShoppingToolsAPI";
import { INTERVAL } from "../../../constants/clientTime";
import { getStartOfDay } from "../helper";

export const useFetchCountOnDivision = (
  division: string,
  roundName: string
) => {
  const storeId = useStoreId();
  const startOfDay = getStartOfDay();
  return useQuery({
    queryKey: [
      "shopping-tools-count-on-division",
      storeId,
      division,
      roundName,
    ],
    queryFn: () => {
      return fetchCountOnDivision(storeId, division, roundName, startOfDay);
    },
    staleTime: INTERVAL.MINUTE * 1,
  });
};
