import React from "react";
import styled from "styled-components";
import { Link } from "../Link";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { AddonListItem } from "./AddonListItem";
import ListEmptyState from "./ListEmptyState";
import { Trans } from "@coworker/locales";
import { ErrorMessage } from "@coworker/components";
import { Content } from "./styles";
import ItemListLink from "./ItemListLink";
import { useInsightsEditStatus } from "../../features/insights/useInsightsEditStatus";
import { isRDTDevice } from "@coworker/reusable";
import trackerHelper from "../../helpers/tracker";

const Container = styled.div`
  background: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
`;

const List = styled.ul`
  color: var(--grey700);
  font-size: 14px;
  ${(props) => (props.isLoading ? "min-height: 453px;" : "")}
`;

const WrappedLoader = styled(LoaderIcon)`
  height: 40px;
  width: 100%;
  margin-top: 30px;
  padding: auto 0;
`;

const StyledLink = styled(Link)`
  margin-top: 25px;
  ${(props) => !props.editEnabled && "padding-bottom: 25px;"}
  display: flex;
  justify-content: start;
  align-items: flex-start;
  border-bottom: 1px solid var(--grey150);
  min-height: ${(props) => (props.editEnabled ? "80px" : "101px")};
`;

/**
 * Component that displays data for insights.
 * @param {Object} props
 * @param {Object[]} props.data Data being displayed
 * @param {String} props.title Title of the list
 * @param {String} props.link Link of the arrow next to the list title
 * @param {Number} props.total Total number of items
 * @param {Function} props.itemTransformFn Function for returning correct link
 * for a single item in the list.
 * @param {Boolean} props.loading Boolean to display loading state or not
 * @param {Boolean} props.errorLoadingProducts error state
 *
 * @example
    <AddonList
      data={data}
      title={<Trans>insightsAddonArticleListTitle</Trans>}
      link={"/insights/products"}
      total={totalAddonProducts}
      itemTransformFn={(id) => `/insights/product/${id}`}
      loading={loadingAddonProducts}
    />
 */
const AddonList = ({
  data,
  title,
  link,
  total,
  itemTransformFn,
  loading = false,
  errorLoadingProducts,
  showOnlyLocation,
  testId,
  noPadding = true,
}) => {
  const [openEditInsights, setOpenEditInsights] = React.useState({});
  const { getEditStatus } = useInsightsEditStatus();
  const editEnabled = getEditStatus();

  React.useEffect(() => {
    if (editEnabled) {
      setOpenEditInsights(null);
    }
  }, [editEnabled]);

  const hasData = data?.length;

  if (hasData && editEnabled) {
    data.sort((a, b) => b.newest_refill.localeCompare(a.newest_refill));
  }

  return !loading && !hasData ? (
    <ListEmptyState />
  ) : (
    <Content noPadding={noPadding || isRDTDevice()}>
      <Container>
        <ItemListLink title={title} link={link} total={total} testId={testId} />
        <List isLoading={loading} data-testid="insights_tab_list">
          {errorLoadingProducts && !hasData && (
            <ErrorMessage text={<Trans>searchErrorMessage</Trans>} />
          )}
          {!!data?.length &&
            data.map((item, index) => (
              <StyledLink
                key={item.id + index}
                to={editEnabled ? "" : itemTransformFn(item.product_id)}
                testId={`${testId}_${index}`}
                onClick={() => {
                  setOpenEditInsights(item.id);
                  if (openEditInsights !== item.id && editEnabled) {
                    trackerHelper.insights.corrections.openCorrectionsList(
                      item.id
                    );
                  }
                }}
                editEnabled={editEnabled}
              >
                <AddonListItem
                  index={index}
                  id={item.id}
                  showOnlyLocation={showOnlyLocation}
                  {...item}
                  openEditInsights={openEditInsights === item.id}
                />
              </StyledLink>
            ))}
          {loading && <WrappedLoader />}
        </List>
      </Container>
    </Content>
  );
};

export default AddonList;
