import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createOrUpdateShoppingtoolsSettings } from "../ShoppingToolsAPI";

export const useCreateOrUpdateShoppingtoolsSettings = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createOrUpdateShoppingtoolsSettings,
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["shopping-tools-settings"],
      });
    },
  });
};
