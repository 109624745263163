import React from "react";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { AddonSingleProductInformation } from "../../AddonSingleProductInformation";
import { StockInfoPills } from "../../StockInfoPills";
import { useFetchedBasicProduct } from "../../../hooks/useBasicProductsMap";

const Container = styled.div`
  padding: 26px 28px;
`;

export function ProductAndStockPillsWidget({
  value: { productId, productType },
}) {
  const { push } = useWorkspacesAction();
  const { basicProduct } = useFetchedBasicProduct(productId);

  return (
    <Container
      data-testid="productWithPills"
      onClick={() => {
        push(`/product/${basicProduct.type}${basicProduct.id}`);
      }}
    >
      <AddonSingleProductInformation basicProduct={basicProduct} />
      <StockInfoPills short_id={basicProduct.id} type={basicProduct.type} />
    </Container>
  );
  // TODO: Add skeleton either here or only in ProductAndStockPills?
}
