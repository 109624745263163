import React from "react";
import { LeftContainer, FlexibleRowContainer } from "../../styles/styles";
import Skeleton from "@ingka/skeleton";

export const MediumSkeletonBox = () => {
  return (
    <FlexibleRowContainer style={{ padding: "20px 24px" }}>
      <LeftContainer>
        <Skeleton height={"120px"} width={"144px"} />
      </LeftContainer>
    </FlexibleRowContainer>
  );
};
