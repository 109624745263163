import React from "react";
import QuantityStepper from "@ingka/quantity-stepper";

import "@ingka/focus/dist/style.css";
import "@ingka/quantity-stepper/dist/style.css";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";

export interface SkapaQuantityStepperControlledProps {
  ariaDescribedBy: string;
  ariaDescribedById: string;
  minVal: number;
  maxVal: number;
  onIncrease: (value: number) => void;
  onDecrease: (value: number) => void;
  small?: boolean;
  value: number;
  disabled: boolean;
}

export const SkapaQuantityStepperControlled: React.FC<
  SkapaQuantityStepperControlledProps
> = ({
  ariaDescribedBy,
  ariaDescribedById,
  small = true,
  value,
  minVal,
  maxVal,
  onDecrease,
  onIncrease,
  disabled,
}) => {
  return (
    <QuantityStepper
      small={small}
      ariaDescribedBy={ariaDescribedBy}
      ariaDescribedById={ariaDescribedById}
      minVal={minVal}
      maxVal={maxVal}
      value={value}
      onIncrease={onIncrease}
      onDecrease={onDecrease}
      ariaLabelDecrease={"Decrease"}
      ariaLabelIncrease={"Increase"}
      disabled={disabled}
    />
  );
};
