import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Håndtering af personoplysninger </div>,
  aboutFixaText: (
    <div>
      FIXA er et værktøj til opgavestyring og markedsføring for medarbejdere i
      varehuset. Formålet med FIXA appen er at erstatte manuelle, papirbaserede
      eller excel-baserede aktiviteter og strømline opgavestyring i varehuset
      (genopfyldning af varer, oprydning, afvigelser, testkøb, ofte stillede
      spørgsmål mv.).
      <br />
      FIXA kan også bruges til at lave analyser gennem indsigt fra afsluttede
      opgaver.
      <br />
      <br />
    </div>
  ),

  personalDataCollectedTitle: (
    <div>
      Når du bruger FIXA, vil IKEA A/S som den dataansvarlige (defineret
      nedenfor) indsamle personlige oplysninger om dig.
    </div>
  ),
  personalDataCollectedText: (
    <div>
      <br />
      De indsamlede personlige oplysninger er:
      <br />
      <br />
      Når du bruger FIXA, indsamler systemet oplysninger om dig fra
      medarbejderdatabasen (CDS), som f.eks. fornavn, mellemnavn(e), efternavn,
      e-mail, jobtitel, job og telefonnummer (hvis det er oplyst manuelt –
      private telefonnumre bør ikke oplyses), billede fra CDS, sprogpræference i
      appen, varehus-id, lande-id, version af enheden samt operativsystem,
      IP-adresse behandlet i Google Cloud Platform og Sentry (Sentry er
      fejlsporingssoftware til applikationer).
      <br />
      <br />
      Medarbejderprofiler oprettes manuelt gennem FIXA admin (en webapp til
      administration) af manageren/superbrugeren. Personlige oplysninger hentes
      fra CDS, så der kan kun oprettes profiler til medarbejdere, som har en
      aktiv profil på CDS.
      <br />
      <br />
    </div>
  ),
  purposeOfDataCollectionTitle: <div>Formålet med at indsamle data er:</div>,
  purposeOfDataCollectionText: (
    <div>
      Formålet med indsamlingen af personlige oplysninger i FIXA er med til at
      sikre, at applikationen fungerer korrekt, så det er nemt for medarbejdere
      at kommunikere med hinanden og administrere opgaver mellem medarbejdere i
      varehuset. Når opgaver forbindes med en bruger, kan vi sende opdaterede
      supportmeddelelser til opgaver, der er tilknyttet brugere og
      administratorer.
      <br />
      <br />
    </div>
  ),
  legalGroundsTitle: <div>Det juridiske grundlag for behandlingen er:</div>,
  legalGroundsText: (
    <div>
      Det juridiske grundlag for behandlingen er opfyldelse af kontrakt og
      legitim interesse.
      <br />
      <br />
    </div>
  ),
  dataWillBeStoredTitle: <div>Dataene opbevares:</div>,
  dataWillBeStoredText: (
    <div>
      Vi opbevarer afsluttede opgaver med referencer til brugere i 28 dage,
      hvorefter de bliver anonymiseret. En medarbejders personlige oplysninger
      opbevares i FIXA i hele ansættelsesperioden.
      <br />
      <br />
      Opsigelse af ansættelseskontrakt med IKEA A/S: Alle personlige oplysninger
      bliver automatisk slettet 1 måned efter CDS-profilen er blevet fjernet.
      <br />
      <br />
      Jobskifte hos IKEA A/S: Hvis en medarbejder ikke længere skal bruge FIXA,
      bliver alle personlige oplysninger automatisk slettet efter 3 måneders
      inaktivitet.
      <br />
      <br />
      Personlige oplysninger kan fjernes på et hvilket som helst tidspunkt,
      såfremt det opfylder kravene hertil, efter anmodning til den lokale
      informationssikkerheds- og databeskyttelsesleder.
      <br />
      <br />
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>Den dataansvarlige for behandlingen af personlige oplysninger er:</div>
  ),
  dataControllerForPersonalDataText: (
    <div>
      <span fontWeight="bold">IKEA A/S </span>
      <br />
      <br />
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      Du har visse rettigheder i forhold til dine personlige oplysninger. Gå til{" "}
      <a href="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx">
        https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx"
      </a>{" "}
      for at få mere at vide om dine rettigheder.{" "}
      <a href="https://iweof.sharepoint.com/teams/o365g_dkdigitalhub_retdkso/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2Fo365g%5Fdkdigitalhub%5Fretdkso%2FShared%20Documents%2FData%20Privacy%2FPersondata%5Flink%5FDK%2Epdf&parent=%2Fteams%2Fo365g%5Fdkdigitalhub%5Fretdkso%2FShared%20Documents%2FData%20Privacy">
        Læs de lokale retningslinjer her.
      </a>
      <br />
      <br />
      Kontaktoplysninger: <EmailLink email="erik.lindgreen@ingka.ikea.com" />
    </div>
  ),
};
