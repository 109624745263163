import styled, { css } from "styled-components";

export const navHeight = 90;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ScrollFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const Spacer = styled.div`
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `};
  flex-shrink: 0;
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BottomPadding = styled.div`
  padding: 20px;
  box-sizing: border-box;
  ${(props) =>
    props.height &&
    css`
      height: ${props.height}px;
    `};
  ${(props) =>
    props.borderTop &&
    css`
      border-top: 1px solid var(--grey150);
    `};
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;

  ${({ disabled }) =>
    disabled &&
    css`
      overflow: hidden;
      pointer-events: none;
    `}
`;
