import {
  callInternal,
  callInternalApi,
} from "@coworker/app/src/hooks/API/useCallInternal";
import { useQuery } from "@tanstack/react-query";
import {
  FixaWindow,
  isChineseEnvironment,
  getEnvironmentId,
} from "@coworker/reusable";
import { PRODUCT_MAPPER } from "@coworker/app/src/hooks/API/useCallInternal";
import { useStoreId } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useMyStore } from "@coworker/app/src/hooks/useMyStore";
import { taskServiceBaseUrl } from "@coworker/functions/src/triggers/cloud-services.helper";

const isChina = isChineseEnvironment();

export const CORE_SERVICE_URL = taskServiceBaseUrl(getEnvironmentId(), isChina);

async function fetchArticlesDetails(
  articleIds: string[],
  primaryLocale: string,
  storeId: string
) {
  if ((window as FixaWindow).callTasksService) {
    const response = (await callInternalApi(
      `products_basics?short_ids=${articleIds}&locale=${primaryLocale}`
    )) as any[];
    var productMapper: Record<string, any>[] = [];
    if (response && response?.length > 0) {
      response.map((item: any) => {
        return (productMapper[item.id] = item);
      });
    }
    return productMapper;
  } else {
    const response = await callInternal(PRODUCT_MAPPER, {
      store: storeId,
      ids: articleIds.toString(),
    });
    return response?.data;
  }
}
export function useArticlesQuery(articleIds: string[]) {
  const primaryLocale = useMyStore()?.configuration?.locale?.primary_locale;
  const storeId = useStoreId();
  return useQuery({
    queryKey: ["article", articleIds, primaryLocale],
    queryFn: async () =>
      await fetchArticlesDetails(articleIds, primaryLocale, storeId),
    staleTime: 5, // Since this data does not change frequently, it can stay fresh for a long time
    enabled: articleIds && articleIds?.length > 0,
  });
}
