import styled, { css } from "styled-components";

const LoginButton = styled.button`
  background: #ffcd3f;
  color: var(--blue);
  padding: 1.1875rem 2.6rem;
  font-size: 0.9475rem;
  font-weight: bold;
  border-radius: 3.75rem;
  transition: background 0.3s ease-in, color 0.3s ease-in;
  display: block;
  margin: 0px auto;
  cursor: pointer;
  ${({ disabled }) =>
    disabled &&
    css`
      color: #3d4153;
      background: #0d1328;
      cursor: auto;
    `}
  ${({ fullwidth }) =>
    fullwidth &&
    css`
      width: calc(100% - 2.5rem);
      padding: 1.1875rem 1.75rem;
    `}
`;

export default LoginButton;
