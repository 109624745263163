import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Datenschutzhinweis für Fixa</div>,
  aboutFixaText: (
    <div>
      Mit diesem Datenschutzhinweis erteilen wir umfassende Auskunft über
      unseren Umgang mit deinen personenbezogenen Daten und informieren dich
      über deine Rechte.
    </div>
  ),
  personalDataCollectedTitle: <div>1. Wer ist Verantwortlicher?</div>,
  personalDataCollectedText: (
    <div>
      Verantwortlicher für die Verarbeitung Deiner Daten ist die deutsche Ingka
      Gesellschaft, bei der Du angestellt bist. Für Fixa ist das die IKEA
      Deutschland GmbH &amp; Co. KG, die IKEA City Projekt GmbH oder die IKEA
      Deutschland Verkaufs GmbH. Den Datenschutzbeauftragten erreichst Du unter
      der E-Mailadresse: <EmailLink email="dsb@ikea.com" />.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>2. Zu welchem Zweck verarbeiten wir Daten von Dir?</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      Mit der Fixa-Anwendung sollen kleinere Aufgaben erstellt und den
      Mitarbeitern zugewiesen werden, damit diese die Aufgaben erledigen können.
      Um Fixa verwenden zu können verarbeitet die App deinen Vor- und Nachnamen,
      deine geschäftliche E-Mail-Adresse, deine Berufsbezeichnung, deine Rolle
      und deine bevorzugte Sprache aus dem IKEA Mitarbeiterverzeichnis (CDS).
      <br />
      <br />
      Die Aufgaben enthalten persönliche Daten und ermöglichen auch
      Benachrichtigungen für verschiedene Aktionen wie z.B. "Aufgabenerstellung"
      und "Aufgaben abgeschlossen mit Änderungen" an den jeweiligen
      Aufgabenersteller. Rechtsgrundlage für die hiermit verbundene
      Datenverarbeitung ist Art. 6 Abs. 1 b) DSGVO i.V.m. § 26 BDSG, d.h. deine
      Daten werden zur Durchführung deines Beschäftigungsverhältnisses benötigt.
      <br />
      <br />
      Die dir zugewiesenen Aufgaben werden 28 Tage nach Erledigung anonymisiert.
      Dein Profil wird gelöscht, sobald die entsprechende CDS Rolle nach
      Beendigung deines Beschäftigungsverhältnisses entfernt wird.
    </div>
  ),
  legalGroundsTitle: <div>3. Deine Rechte.</div>,
  legalGroundsText: (
    <div>
      Alle weiteren Informationen zur Verarbeitung und zum Schutz deiner Daten
      sowie deinen Rechten erhältst Du in der IKEA Datenschutzerklärung für
      Mitarbeiter, die Du in Deiner Personalabteilung einsehen kannst.
    </div>
  ),
  dataWillBeStoredTitle: "",
  dataWillBeStoredText: "",
  dataControllerForPersonalDataTitle: "",
  dataControllerForPersonalDataText: "",
  rightToPersonalInformationText: "",
};
