import React from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "@coworker/locales";
import { DeliveryContainer } from "@coworker/components";
import { useAvailableElevatedStock } from "../hooks/useAvailableElevatedStock";
import { useExpectedDelivery } from "../hooks/useExpectedDelivery";
import { parseProduct } from "../services/products.service";
import { StockIndicator } from "../features/stock";

const StockText = styled.div`
  font-size: 14px;
  line-height: 1.29;
  color: var(--black);
`;

const StockContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 13px;
`;

const StockString = styled.span`
  color: var(--grey700);
`;

const StockAmount = styled.span`
  font-weight: bold;
`;

// Only used from the ScanPopover "half-sheet"
export function StockInfoForHalfsheet({ full_id }) {
  const { t } = useTranslation();
  const { available, elevated, color } = useAvailableElevatedStock(full_id);
  const [short_id] = parseProduct(full_id);
  const { formattedDelivery } = useExpectedDelivery(short_id);

  return (
    <StockContainer>
      <StockIndicator color={color} size={10} marginRight={12} marginTop={4} />
      {available !== "" ? (
        <StockText>
          <StockAmount>{available}</StockAmount>{" "}
          {String(elevated).length > 0 && (
            <StockString>({elevated})</StockString>
          )}
          {formattedDelivery && (
            <DeliveryContainer>
              {t("expectedDelivery")}: {formattedDelivery}
            </DeliveryContainer>
          )}
        </StockText>
      ) : (
        <StockText>
          <StockString>
            <Trans>noStockInfoString</Trans>
          </StockString>
        </StockText>
      )}
    </StockContainer>
  );
}
