import styled from "styled-components";
import { Link } from "../../Link";
import { Button } from "@coworker/components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--white);
  height: 100%;
  overflow-y: auto;
  justify-content: space-between;
`;

export const Title = styled.div`
  background: var(--white);
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
`;

export const ButtonWrapper = styled.div`
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  flex-shrink: 0;
`;

export const StyledLink = styled(Link)`
  padding-top: 5px;
  padding-bottom: 22px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--light-blue);
`;

export const StyledButton = styled(Button)`
  width: 90%;
`;

export const SliderContainer = styled.div`
  margin: 0 19px;
  margin-bottom: 34px;
`;

export const QuestionTitle = styled.div`
  font-size: 16px;
  line-height: 23px;
  color: var(--grey900);
  margin: 20px 28px;
  font-weight: 700;
`;

export const ParagraphText = styled.p`
  color: var(--grey718);
  margin-bottom: 20px;
  padding: 0px 28px;
  font-size: 14px;
  line-height: 21px;
`;

export const HalfButtonLeft = styled(StyledButton)`
  margin: 0 12px 0 20px;
`;

export const HalfButtonRight = styled(StyledButton)`
  margin: 0 20px 0 12px;
`;

export const HeaderTitle = styled.div`
  background: var(--white);
  font-size: 28px;
  font-weight: bold;
  padding: 15px 28px 20px 28px;
`;
