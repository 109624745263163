import { useQuery } from "@tanstack/react-query";
import { getRoomsByStoreId } from "../services/rooms.service";

export const useRoomsByStoreId = (storeId: string | undefined) => {
  return useQuery({
    queryKey: ["rooms", { id: storeId }],
    queryFn: () => getRoomsByStoreId(storeId || ""),
    enabled: !!storeId,
  });
};
