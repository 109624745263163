import { Criterion } from "@coworker/types";
import { postToTestbuyService } from "../../../../hooks/API/testbuy.service";

interface CreateOrUpdateCriterionRes {
  id: string;
}

export async function createOrUpdateCriterion(
  _testbuyKey: string,
  testbuyArticleKey: string,
  criterion: Criterion
) {
  await postToTestbuyService(
    "criteria/update_criterion",
    {
      criterion,
      articleId: testbuyArticleKey,
    },
    (data: CreateOrUpdateCriterionRes[]) => data
  );
}
