import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Container, OverflowBackground } from "./CommonComponents";
import { Button, Input } from "@coworker/components";

export const TitleText = styled.div`
  margin-top: 128px;
  font-size: 16px;
  font-weight: bold;
  color: var(--grey900);
  margin: 33px 29px 0px 29px;
`;
const SubTitleText = styled.div`
  margin-top: 128px;
  font-size: 12px;
  color: var(--grey600);
  margin: 12px 29px 0px 29px;
`;
export const StyledButton = styled(Button)`
  width: 100px;
  margin-bottom: 20px;
  align-self: end;
  background-color: var(--white);
  border: 1px solid var(--grey600);
  margin-right: 29px;
`;

export const InputWrapper = styled.div`
  margin: 0px 29px 20px 30px;
`;

const MFAQresolution = (props) => {
  const [value, setValue] = React.useState(props?.initialResolution);

  return (
    <>
      <OverflowBackground onClick={props.onClose} />
      <Container>
        <TitleText>
          <Trans>mfaqResolution</Trans>
        </TitleText>
        <SubTitleText>
          <Trans>mfaqResolutionSubTitle</Trans>
        </SubTitleText>
        <InputWrapper>
          <Input
            testId="mfaqResolutionInput"
            name="resolutionInput"
            autoFocus
            value={value}
            onChange={(e) => setValue(e)}
          ></Input>
        </InputWrapper>
        <StyledButton
          text={<Trans>doneString</Trans>}
          onClick={(event) => {
            event.preventDefault();
            props.onSubmit(value);
          }}
        ></StyledButton>
      </Container>
    </>
  );
};

export default MFAQresolution;
