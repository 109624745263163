import { CriterionKey } from "@coworker/types";
import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";

export function testbuyCreateTaskFactory(
  uid: string,
  store_id: string,
  articleId: string,
  articleName: string,
  location: string,
  articleImageUrl: string,
  testbuy_id: string,
  failedCriteria: CriterionKey[]
): TestBuyFollowUp {
  return {
    task_type: "TESTBUY_FOLLOW_UP",
    creator_id: uid,
    last_editor_id: uid,
    store_id,
    priority_flag: false,
    product_article_id: articleId,
    product_article_type: "ART",
    location,
    location_grid_code: location,
    assigned_team_id: "",
    assigned_user_id: "",
    state: "UNASSIGNED",
    description: "",
    item_name: articleName,
    created_at: Date.now(),
    last_assigned_at: 0,
    updated_at: 0,
    images: [],
    item: {
      measurements: "",
      image_small: articleImageUrl,
    },
    item_type: "",
    item_color: "",
    testbuy_id,
    failedCriteria,
    checklist: failedCriteria.map((criterion) => {
      return { key: criterion, isDone: false };
    }),
  };
}
