import { useQuery } from "@tanstack/react-query";
import { getRoomById } from "../services/rooms.service";

export const useRoomById = (roomId: string | undefined) => {
  return useQuery({
    queryKey: ["room", roomId],
    queryFn: () => getRoomById(roomId || ""),
    enabled: !!roomId,
  });
};
