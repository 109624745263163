import { chinaProject } from "./devices";

/**
 * Generates the correct environmentId to be used for generating backend service baseUrls and such
 * In china it returns the resource group (`"dev" | "prod"`),
 * and in global it return the GCP project id (something like `ikea-coworker-app-dev`)
 */
export function getEnvironmentId(): string {
  return (
    chinaProject() ??
    process.env["GCLOUD_PROJECT"] ??
    process.env["REACT_APP_PROJECT_ID"] ??
    ""
  );
}
