import React from "react";
import styled from "styled-components";
import { Link } from "../Link";

const NotificationWrapper = styled(Link)`
  padding: 30px 28px 30px 10px;
  border-bottom: 1px solid var(--grey100);
  display: flex;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: var(--grey900);
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UnreadIndicator = styled.div`
  height: 5px;
  width: 5px;
  background: var(--error);
  border-radius: 5px;
  margin: 8px 0px;
`;

const LeftSection = styled.div`
  width: 19px;
  height: 21px;
  display: flex;
  flex-shrink: 0;
`;

const MainSection = styled.div`
  flex-grow: 1;
  overflow: hidden;
  min-height: 42px;
`;
const TitleRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Time = styled.div`
  font-size: 12px;
  line-height: 21px;
  color: var(--grey718);
  padding-left: 3px;
  flex-shrink: 0;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: var(--grey718);
  &::first-letter {
    text-transform: capitalize;
  }
`;

function NotificationCard({
  link,
  onClickHandler,
  title,
  time,
  subtitle,
  showIndicator,
}) {
  return (
    <NotificationWrapper
      to={link}
      onClick={onClickHandler}
      testId="notificationWrapper"
    >
      <LeftSection>
        {showIndicator && (
          <UnreadIndicator data-testid="notificationIndicator" />
        )}
      </LeftSection>
      <MainSection>
        <TitleRow>
          <Title data-testid="notificationTitle">{title}</Title>
          <Time data-testid="notificationTime">{time}</Time>
        </TitleRow>
        <Subtitle data-testid="notificationSubtitle">{subtitle}</Subtitle>
      </MainSection>
    </NotificationWrapper>
  );
}

export default NotificationCard;
