import { useQuery } from "@tanstack/react-query";
import { getEDSData } from "../services/articles.service";

export const useEDSData = (countryId: string, articleNumbers: string) => {
  return useQuery({
    queryKey: ["edsData", countryId, articleNumbers],
    queryFn: () => getEDSData(countryId, articleNumbers),
    enabled: !!countryId && !!articleNumbers,
    cacheTime: 1000 * 60 * 15, // 15 minutes cache time
    staleTime: 1000 * 60 * 15, // 15 minutes stale time
  });
};
