import Switch from "@ingka/switch";
import "@ingka/focus/dist/style.css";
import "@ingka/switch/dist/style.css";

/**
 *
 * @param {{id:string, checked?:boolean|undefined, disabled?:boolean|undefined, onChange:(...args:any[]) => any, testId:string}} param0
 */
export function SkapaSwitch({ id, checked, disabled, onChange, testId }) {
  return (
    <Switch
      subtle
      onChange={onChange}
      readOnly={!onChange}
      checked={checked}
      disabled={disabled}
      data-testid={testId}
      // Required by Skapa
      id={id}
      value={id}
    />
  );
}
