import Skeleton from "@ingka/skeleton";
import styled from "styled-components";

export const NotificationsSkeleton = () => {
  const NotificationsSkeleton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    width: 100%;
  `;

  return (
    <>
      {[...Array(10)].map((_, index) => (
        <NotificationsSkeleton>
          <Skeleton
            key={index}
            style={{ height: "70px", width: "100%", marginBottom: "10px" }}
          />
        </NotificationsSkeleton>
      ))}
    </>
  );
};
