import React, { ReactElement } from "react";
import { useSpring, animated, config } from "react-spring";
import { useDrag } from "react-use-gesture";
import styled, { css } from "styled-components";
import { Link } from "./Link";
import { SlidingButtonProps } from "./SlidingButton";
import { type InterpolatorConfig } from "@react-spring/types";
import { isChineseEnvironment } from "@coworker/reusable";

const RANGE_ITERATION_BASIS = 100;
const RANGE_ITERATION_MAX = RANGE_ITERATION_BASIS * 3;
const PER_BUTTON_DRAGGABLE_BASIS = 28; // Draggable buttons percentage basis size.

const StyledWrapper = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
`;

const AnimatedWrapper = animated(StyledWrapper);

type StyledLinkProps = {
  isSelected: boolean;
  isDraggable: boolean;
  closedState: boolean;
};

const StyledLink = styled(Link)<StyledLinkProps>`
  width: 100%;
  ${({ isSelected }) =>
    css`
      background: var(--${isSelected ? "grey100" : "white"});
    `}
  ${(props) => props.closedState && "background: var(--grey50);"}
  border-bottom: 1px solid var(--grey150);
  box-sizing: border-box;
  display: block;
  ${({ isDraggable }) =>
    isDraggable &&
    `
    position: absolute;
    left: 0;
    height: 100%;
  `};
`;

const AnimatedLink = animated(StyledLink);
const isChina = isChineseEnvironment();

const buzzIf = (enabled: boolean) => {
  if (!enabled) return;
  try {
    if (!isChina) {
      window.navigator.vibrate(0);
      window.navigator.vibrate(50);
    }
  } catch (ignored) {} // Avoid errors if not allowed.
};

export const calculateCardInterpolatorConfig = (
  leftButtonsCount: number,
  rightButtonsCount: number,
  leftExpandable: boolean = false
): InterpolatorConfig => {
  const range = [0];
  const output = ["0%"];

  if (rightButtonsCount > 0) {
    range.unshift(-(rightButtonsCount * RANGE_ITERATION_BASIS));
    output.unshift(`-${rightButtonsCount * PER_BUTTON_DRAGGABLE_BASIS}%`);
  }

  if (leftButtonsCount > 0) {
    range.push(RANGE_ITERATION_BASIS * 2);
    output.push(`${leftButtonsCount * PER_BUTTON_DRAGGABLE_BASIS}%`);

    // If button is expandable add additional points
    if (leftExpandable) {
      range.push(RANGE_ITERATION_BASIS * 3);
      output.push("100%");
    }
  }

  return {
    range,
    output,
    extrapolate: "clamp",
  };
};

export const calculateButtonInterpolatorConfig = (
  isLeft: boolean,
  isExpandable: boolean = false
): InterpolatorConfig => {
  let range;
  let output;
  let args: InterpolatorConfig;

  if (isLeft) {
    range = [0, RANGE_ITERATION_BASIS * 2];
    output = ["0%", `${PER_BUTTON_DRAGGABLE_BASIS}%`];

    if (isExpandable) {
      range.push(RANGE_ITERATION_BASIS * 3);
      output.push("100%");
    }

    args = {
      map: Math.abs,
      range,
      output,
      extrapolate: "clamp",
    };
  } else {
    range = [-RANGE_ITERATION_BASIS, 0];
    output = [`${PER_BUTTON_DRAGGABLE_BASIS}%`, "0%"];

    args = {
      // Right buttons contain no `map` override
      range,
      output,
      extrapolate: "clamp",
    };
  }

  return args;
};

export interface SlidingWrapperProps {
  className?: string;
  style?: object;
  isDraggable?: boolean;
  swipeBinding?: boolean;
  children: JSX.Element;
  dataType: string;
  dataTestId: string;
  dataId: string;
  animatedLinkRedirect: string;
  animatedLinkClosed: boolean;
  leftButtons: ReactElement<SlidingButtonProps>[];
  leftButtonExpandable?: boolean;
  rightButtons: ReactElement<SlidingButtonProps>[];
  wrapperRef: React.MutableRefObject<any>;
}

const SlidingWrapper = ({
  className,
  style,
  isDraggable = true,
  swipeBinding = true,
  children,
  dataType,
  dataTestId,
  dataId,
  animatedLinkRedirect,
  animatedLinkClosed,
  leftButtons = [],
  leftButtonExpandable = false,
  rightButtons = [],
  wrapperRef, // Needed due to handleClickOutside()
}: SlidingWrapperProps) => {
  /* Drag Implementation Start */
  const positionRef = React.useRef(false);
  /* useDrag state options at https://use-gesture.netlify.com/docs/state */
  const [springProps, setSpring] = useSpring(() => ({
    config: config.default,
    x: 0,
    onRest() {
      // Event which fires when swiped to rightmost side
      if (
        springProps.x.animation.to === RANGE_ITERATION_MAX &&
        leftButtons.length &&
        leftButtons[0]
      ) {
        leftButtons[0].props.onClick();
      }
    },
  }));

  React.useEffect(() => {
    /* Reset drag animation when the user taps outside the dragged container */
    function handleClickOutside(event: Event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSpring({ x: 0 });
      }
    }
    const documentElement = document.documentElement;
    documentElement.addEventListener("touchstart", handleClickOutside);
    return () =>
      documentElement.removeEventListener("touchstart", handleClickOutside);
  }, [setSpring, wrapperRef]);

  const bind = useDrag(
    /* useDrag state options at https://use-gesture.netlify.com/docs/state */
    ({ down, movement: [mx], cancel }) => {
      if (mx > 200) {
        buzzIf(!positionRef.current);
        cancel && cancel();
        positionRef.current = true;
        leftButtonExpandable && setSpring({ x: RANGE_ITERATION_MAX });
      } else if (mx > 100) {
        buzzIf(!positionRef.current);
        positionRef.current = true;
        setSpring({ x: RANGE_ITERATION_BASIS * 2 });
      } else if (mx < -100) {
        buzzIf(!positionRef.current);
        positionRef.current = true;
        setSpring({ x: -RANGE_ITERATION_BASIS });
      } else if (!down) {
        positionRef.current = false;
        setSpring({ x: 0 });
      } else {
        positionRef.current = false;
        setSpring({ x: mx });
      }
    },
    { threshold: 30, axis: "x", enabled: isDraggable }
  );

  /* Drag Implementation End */
  const leftAnimatedButtons = React.useMemo(() => {
    // Leftmost button completes actions with expand
    // if `leftButtonExpandable` is enabled
    // others are handled by onClick directly
    const handleExpand = () => setSpring({ x: RANGE_ITERATION_MAX });

    const leftButtonWidth = springProps.x.to(
      calculateButtonInterpolatorConfig(true, leftButtonExpandable)
    );
    const secondButtonWidth = springProps.x.to(
      calculateButtonInterpolatorConfig(true)
    );

    return (
      <>
        {leftButtons.map((existingButton, index) =>
          React.cloneElement(existingButton, {
            style: {
              ...existingButton.props.style,
              width: index === 0 ? leftButtonWidth : secondButtonWidth,
            },
            onClick:
              index === 0 && leftButtonExpandable
                ? handleExpand
                : existingButton.props.onClick,
            key: `${dataId}-l-${index}`,
          })
        )}
      </>
    );
  }, [dataId, leftButtonExpandable, leftButtons, setSpring, springProps.x]);

  const rightAnimatedButtons = React.useMemo(() => {
    const rightButtonWidth = springProps.x.to(
      calculateButtonInterpolatorConfig(false)
    );

    return (
      <>
        {rightButtons.map((existingButton, index) =>
          React.cloneElement(existingButton, {
            style: { ...existingButton.props.style, width: rightButtonWidth },
            key: `${dataId}-r-${index}`,
          })
        )}
      </>
    );
  }, [dataId, rightButtons, springProps]);

  return (
    <AnimatedWrapper
      className={className}
      data-type={dataType}
      data-testid={dataTestId}
      data-id={dataId}
      {...(swipeBinding && bind())}
      style={{ ...style }}
      ref={wrapperRef}
    >
      {leftAnimatedButtons}
      <AnimatedLink
        isDraggable={isDraggable}
        style={
          isDraggable
            ? {
                x: springProps.x.to(
                  calculateCardInterpolatorConfig(
                    leftButtons.length,
                    rightButtons.length,
                    leftButtonExpandable
                  )
                ),
              }
            : null
        }
        key={dataId}
        to={animatedLinkRedirect}
        closedState={animatedLinkClosed && "true"}
        setCheckPoint
      >
        {children}
      </AnimatedLink>
      {rightAnimatedButtons}
    </AnimatedWrapper>
  );
};

export default SlidingWrapper;
