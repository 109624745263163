import React from "react";

export const policy = {
  title: <div>Personvernerklæring</div>,
  aboutFixaText: (
    <div>
      Fixa er et oppgavestyrings- og varehandelsverktøy for medarbeidere i
      butikken. Fixa-applikasjonens formål er å erstatte manuelle, papir- eller
      excel-baserte aktiviteter og effektivisere oppgavehåndteringen i butikken
      (påfylling av artikler, opprydding, avvik, testkjøp, vanlige spørsmål
      osv.). Fixa tillater også analyse gjennom innsikt oppnådd fra fullførte
      oppgaver.
      <br />
      <br />
      Når du bruker Fixa, vil Ingka Group-selskapet Data Controller (som
      definert nedenfor) samle inn visse personopplysninger om deg.
    </div>
  ),
  personalDataCollectedTitle: <div>Personopplysningene som samles inn er:</div>,
  personalDataCollectedText: (
    <div>
      Når du bruker Fixa, samler systemet inn informasjon om deg fra
      medarbeiderdatabasen (CDS), som for eksempel fornavn, mellomnavn,
      etternavn, e-post, stillingstittel, rolle, telefonnummer (hvis manuelt
      angitt - personlig telefonnummer bør ikke skrives inn), bilde fra CDS,
      språkpreferanse i app, enhets-ID, land-ID, versjon av mobil enhet og
      operativsystem, IP-adresse behandlet i Google Cloud Platform og Sentry
      (Sentry er applikasjonen for sporing av feil).
      <br />
      <br />
      Medarbeiderprofilen opprettes manuelt gjennom Fixa - admin (en
      nettapplikasjon for administratorer) av lederen/superbrukeren. Personlige
      data hentes fra CDS, slik at profiler bare kan opprettes for kolleger som
      har en aktiv CDS-profil.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>Formålet med å samle inn disse dataene er:</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      Formålet med innsamling av personopplysninger i Fixa er at applikasjonen
      skal fungere ordentlig på en måte som muliggjør enkel intern kommunikasjon
      mellom medarbeidere og administrative oppgaver mellom medarbeidere i
      butikken. Ved å ha oppgaver koblet til en bruker kan vi støtte varsler om
      oppdateringer av oppgaver for tilordnede brukere og skapere.
    </div>
  ),
  legalGroundsTitle: <div>Det juridiske grunnlaget for behandlingen er:</div>,
  legalGroundsText: (
    <div>Det juridiske grunnlaget for behandlingen er legitim interesse.</div>
  ),
  dataWillBeStoredTitle: <div>Dataene vil bli lagret:</div>,
  dataWillBeStoredText: (
    <div>
      Vi lagrer ferdige oppgaver med referanser til brukere i 70 dager,
      hvoretter de er anonymisert. En medarbeiders personopplysninger lagres i
      Fixa så lenge ansettelseskontrakten varer.
      <br />
      <br />
      Oppsigelse av arbeidsavtale med Ingka Group: Alle personopplysninger
      slettes automatisk innen 1 måned etter at CDS-profilen er fjernet.
      <br />
      <br />
      Rolleendring i Ingka Group: Hvis en medarbeider ikke lenger trenger å
      bruke Fixa, slettes alle personopplysninger automatisk innen 3 måneder
      etter aktivitet.
      <br />
      <br />
      Personopplysninger kan også fjernes når som helst på forespørsel til den
      lokale lederen for informasjonssikkerhet og personvern eller market
      manager.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>
      Behandlingsansvarlig for denne behandlingen av personopplysninger er:
    </div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>IKEA AS</div>
  ),
  rightToPersonalInformationText: (
    <div>
      Du har visse rettigheter i forhold til dine personopplysninger. Besøk{" "}
      <a href="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx">
        https://inside.ingka.com/&#8203;supportservices/&#8203;Dataprivacy/&#8203;Pages/&#8203;Individual-rights.aspx
      </a>{" "}
      for å lære mer om slike rettigheter.
    </div>
  ),
};
