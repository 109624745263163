import React from "react";
import { AssignToUserOrGroup } from "../AssignToUserOrGroup";
import { useTranslation } from "@coworker/locales";
import { SkapaModalHeader } from "../SkapaModalHeader";
import trackerHelper from "../../helpers/tracker";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import { useActiveUsers } from "../../hooks/useActiveUsers";
import styled from "styled-components";
import Search from "@ingka/search";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/search/dist/style.css";
import "@ingka/focus/dist/style.css";
import { useTeamId, useUserId } from "../../core/auth/useLoggedInUser";
import { useAssignableTeams } from "../../core/hooks/useAssignableTeams";
import { useAssignmentSuggestion } from "../../hooks/useAssignmentSuggestion";

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 85%;
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
`;

const OverflowBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grey800);
  opacity: 0.8;
`;

const SearchContainer = styled.div`
  margin: 24px;
`;

function NewAssign(props) {
  const assignableTeams = useAssignableTeams(true);
  const userId = useUserId();
  const teamId = useTeamId();

  const [assignToMyTeamOpened, setAssignToMyTeamOpenedInner] =
    useUserPreference(profilePreferences.ASSIGN_TO_MY_TEAM_OPENED, false);
  const setAssignToMyTeamOpened = React.useCallback(
    (change) => {
      trackerHelper.task.trackAssignToMyTeamOpenedToggle(change);
      setAssignToMyTeamOpenedInner(change);
    },
    [setAssignToMyTeamOpenedInner]
  );

  const activeUsers = useActiveUsers();

  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = React.useState("");

  const suggestions = useAssignmentSuggestion(props.shortId);

  return (
    <>
      <OverflowBackground
        onClick={() => {
          trackerHelper.trackDismissModal(false);
          props?.onClose?.();
        }}
        data-testid="assignToBackdrop"
      />
      <Container>
        <React.Fragment>
          <SkapaModalHeader
            titleString="assignString"
            data-testid="heading"
            onClose={props.onClose}
          />
          <SearchContainer data-testid="searchContainer">
            <Search
              placeholder={t("searchBasicString")}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              data-testid="searchQuery"
              onClear={(e) => {
                setSearchQuery("");
              }}
            />
          </SearchContainer>
          <AssignToUserOrGroup
            {...props}
            assignToMyTeamOpened={assignToMyTeamOpened}
            setAssignToMyTeamOpened={setAssignToMyTeamOpened}
            users={activeUsers}
            assignableTeams={assignableTeams}
            me={{ id: userId, group: teamId }}
            handoff={props.handoff}
            flow={props.flow}
            onKeepTaskClick={trackerHelper.task.trackKeepTaskWithMeClick}
            spacer={false}
            searchQuery={searchQuery}
            suggestions={suggestions}
          />
        </React.Fragment>
      </Container>
    </>
  );
}

export default NewAssign;
