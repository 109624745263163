import React from "react";
import BlueLink, { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Dichiarazione sulla Privacy</div>,
  aboutFixaText: (
    <div>
      Fixa è uno strumento di gestione dei compiti e del merchandising per i
      co-workers di negozio. Lo scopo delle applicazioni Fixa è quello di
      sostituire le attività manuali, cartacee o basate su excel e semplificare
      la gestione dei compiti in negozio (rifornimento di articoli, pulizie,
      deviazioni, acquisti di prova, domande più frequenti, ecc). Fixa permette
      anche l'analisi attraverso gli insights ottenuti dalle attività
      completate. <br />
      <br />
      Quando utilizzi Fixa, la società del Gruppo Ingka Group che opera come
      Titolare del Trattamento (come meglio in seguito definito) raccoglierà i
      seguenti dati personali relativi a te.
    </div>
  ),
  personalDataCollectedTitle: <div>I dati personali raccolti sono:</div>,
  personalDataCollectedText: (
    <div>
      Quando usi Fixa, il sistema raccoglierà informazioni su di te dal
      Co-Worker Database (CDS), come nome, cognome, email, qualifica, ruolo,
      numero di telefono (se fornito manualmente - il numero di telefono
      personale non deve essere inserito), la tua fotografia reperita dal CDS,
      la preferenza della lingua nell'app, store ID, ID del paese, la versione
      del dispositivo utilizzato e il sistema operativo, indirizzo IP elaborato
      in Google Cloud Platform e Sentry (Sentry è un software di monitoraggio
      degli errori dell'applicazione).
      <br />
      <br /> Il profilo del co-worker è creato manualmente attraverso Fixa-admin
      (un'applicazione web di amministrazione) dal manager/super user. I dati
      personali sono recuperati da CDS, quindi i profili possono essere creati
      solo per i co-workers che hanno un profilo CDS attivo.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>La finalità per cui vengono raccolti i tuoi dati è:</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      La finalità per cui vengono raccolti i tuoi dati personali in Fixa è
      quella di permettere il corretto funzionamento dell'applicazione in modo
      da rendere possibile una facile comunicazione interna tra i co-workers ed
      organizzare i compiti tra i co-workers all’interno del negozio. Avere
      compiti connessi tramite un user ci permette di supportare le notifiche
      per gli aggiornamenti di tali tasks assegnati, e di gestire le
      comunicazioni tra utente e “creatore” dei medesimi
    </div>
  ),
  legalGroundsTitle: (
    <div>La base giuridica per il trattamento dei dati personali è:</div>
  ),
  legalGroundsText: (
    <div>
      La base giuridica per il trattamento dei dati personali è il legittimo
      interesse del Titolare.
    </div>
  ),
  dataWillBeStoredTitle: <div>I dati raccolti verranno conservati:</div>,
  dataWillBeStoredText: (
    <div>
      Conserviamo i dati relativi ai tasks raggiunti dai co-workers per 70
      giorni, dopodiché gli stessi verranno resi anonimi. I dati personali dei
      co-workers saranno conservati in Fixa per tutta la durata del rapporto di
      lavoro. <br />
      <br />
      In caso di cessazione del contratto di lavoro con Ingka Group: tutti i
      dati personali vengono cancellati automaticamente entro 1 mese dalla
      rimozione del profilo CDS.
      <br />
      <br />
      In caso di cambio di ruolo all'interno del Gruppo Ingka: se un co-worker
      non ha più necessità di utilizzare la Fixa, tutti i dati personali saranno
      automaticamente cancellati entro 3 mesi dall'inserimento dell’attività in
      Fixa.
      <br />
      <br />I dati personali possono anche essere rimossi in qualsiasi momento
      su richiesta dell’ISDP Leader o dello Store Manager.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>Il Titolare del trattamento dei dati come sopra descritti è:</div>
  ),
  dataControllerForPersonalDataText: <div>IKEA Italia Retail S.r.l.</div>,
  rightToPersonalInformationText: (
    <div>
      Per quanto riguarda i diritti da Te esercitabili in relazione ai Tuoi
      diritti in relazione ai dati personali di cui sopra, Ti invitiamo a
      visionare l’apposita sezione “
      <BlueLink href="https://iweof.sharepoint.com/sites/work/icw/it/SitePages/Il%20mio%20lavoro%20quotidiano-Il-mio-contratto.aspx">
        Il mio contratto
      </BlueLink>
      “ oppure richiedere al Titolare estratto integrale degli articoli riferiti
      ai diritti esercitabili. I suddetti diritti possono essere esercitati
      secondo quanto stabilito dal Regolamento inviando, anche, una email al
      seguente indirizzo <EmailLink email="privacy.it@ingka.ikea.com" />.
      <br />
      <br />
      Di seguito i riferimenti per contattare il
      <br />
      <br />
      <b>Titolare Titolare del TrattamentoIKEA</b>
      <br />
      Italia Retail S.r.l.
      <br />
      Strada Provinciale 208, n. 320061 Carugate (MI).
      <br />
      <br />
      <b>Data Protection Officer (DPO)</b>
      <br />
      Avv. Matteo Alessandro Paganivia Turati 26, 20121, Milano (MI)
      <br />
      dpo.retail.it@ikea.com
    </div>
  ),
};
