import AddOnIcon from "@ingka/ssr-icon/paths/add-on";
import InsightsIcon from "@ingka/ssr-icon/paths/data-chart";
import TestBuyIcon from "@ingka/ssr-icon/paths/clipboard-checkmark";
import RoomSettingIcon from "@ingka/ssr-icon/paths/room-layout";
import ShoppingToolsIcon from "@ingka/ssr-icon/paths/shopping-bag";
import CustomerMFAQIcon from "@ingka/ssr-icon/paths/speech-bubble";
import ProductQualityReportIcon from "@ingka/ssr-icon/paths/box-cancel";
import ProductIssueIcon from "@ingka/ssr-icon/paths/chair";
import CustomerTaskIcon from "@ingka/ssr-icon/paths/pencil";

import { TaskTypesRoutes } from "../../../../home/constants/taskTypesRoutes";

const NavigationLabelsKeys = {
  ADDON: "addonString",
  INSIGHTS: "addonInsightsString",
  TEST_BUY: "testBuyString",
  ROOM_SETTINGS: "roomSettingsString",
  MEDIA_MAINTENANCE_ROOM_SETTINGS: "mediaMaintenanceRoomSettingsString",
  SHOPPING_TOOLS: "shoppingToolsString",
  CUSTOMER_MFAQ: "customerQuestionsString",
  PRODUCT_QUALITY_REPORT: "productQualityReportString",
  PRODUCT_ISSUE: "productString",
  CUSTOM_TASK: "customString",
};

export type NavigationItem = {
  id: string;
  orderIndex: number;
  icon: (prefix?: string) => React.SVGProps<SVGElement>[];
  labelTranslationKey: string;
  href?: string;
  taskType?: string;
};

export const NavigationItemsConfig: NavigationItem[] = [
  {
    id: "add-on",
    orderIndex: 9,
    icon: AddOnIcon,
    labelTranslationKey: NavigationLabelsKeys.ADDON,
    taskType: TaskTypesRoutes.ADDON,
  },
  {
    id: "insights",
    orderIndex: 8,
    icon: InsightsIcon,
    labelTranslationKey: NavigationLabelsKeys.INSIGHTS,
    href: "/insights",
  },
  {
    id: "test-buy",
    orderIndex: 7,
    icon: TestBuyIcon,
    labelTranslationKey: NavigationLabelsKeys.TEST_BUY,
    href: "/testbuy",
  },
  {
    id: "room-setting",
    orderIndex: 6,
    icon: RoomSettingIcon,
    labelTranslationKey: NavigationLabelsKeys.MEDIA_MAINTENANCE_ROOM_SETTINGS,
    href: "/roomsettings",
  },
  {
    id: "shopping-tools",
    orderIndex: 5,
    icon: ShoppingToolsIcon,
    labelTranslationKey: NavigationLabelsKeys.SHOPPING_TOOLS,
    href: "/shoppingtools",
  },
  {
    id: "customer-mfaq",
    orderIndex: 4,
    icon: CustomerMFAQIcon,
    labelTranslationKey: NavigationLabelsKeys.CUSTOMER_MFAQ,
    href: "/customerquestions",
  },
  {
    id: "product-quality-report",
    orderIndex: 3,
    icon: ProductQualityReportIcon,
    labelTranslationKey: NavigationLabelsKeys.PRODUCT_QUALITY_REPORT,
    taskType: TaskTypesRoutes.PRODUCT_QUALITY,
  },
  {
    id: "product-issue",
    orderIndex: 2,
    icon: ProductIssueIcon,
    labelTranslationKey: NavigationLabelsKeys.PRODUCT_ISSUE,
    taskType: TaskTypesRoutes.PRODUCT_ISSUE,
  },
  {
    id: "customer-task",
    orderIndex: 1,
    icon: CustomerTaskIcon,
    labelTranslationKey: NavigationLabelsKeys.CUSTOM_TASK,
    taskType: TaskTypesRoutes.CUSTOM,
  },
];
