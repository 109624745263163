import React from "react";
import { ListViewItem } from "@ingka/list-view";
import { Articles } from "../types/article";
import { PiecesText } from "../Atoms/PiecesText";
import { RightArrow } from "../Atoms/Icons/RightArrow";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import {
  CenterContainer,
  FlexContainer,
  RightContainer,
} from "../styles/styles";
import { Text } from "../Atoms/Text";
import styled from "styled-components";

const ItemDivider = styled.div`
  padding: 8px 24px 8px 0px;
`;

interface ArticleInGroupsProps {
  articles: Articles;
}

export function ArticleInGroups({ articles }: ArticleInGroupsProps) {
  const { push } = useWorkspacesAction();

  const relevantArticles = articles
    .filter((a) => !!a.groupName)
    .sort((a1, a2) => (a1.groupName ?? "").localeCompare(a2.groupName ?? ""));

  const addOnContent = (
    <>
      {relevantArticles.map((article) => (
        <div
          key={article.groupId}
          onClick={() =>
            push(
              `/roomsettings/groups/${article.groupId}?roomId=${article.roomId}`
            )
          }
        >
          <ItemDivider>
            <FlexContainer $direction={"row"}>
              <CenterContainer $centerText={false}>
                <div style={{ display: "flex" }}>
                  <Text text={article.groupName ?? ""} bold />
                </div>
                <PiecesText pieces={article.nbrArticles} />
              </CenterContainer>
              <RightContainer>
                <RightArrow />
              </RightContainer>
            </FlexContainer>
          </ItemDivider>
        </div>
      ))}
    </>
  );

  if (relevantArticles.length === 0) return <></>;

  return <ListViewItem addOn={addOnContent} />;
}
