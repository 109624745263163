import React from "react";
import PinCodeForm from "./PinCodeForm";
import { usePinLock } from "../../hooks/usePinLock";

export default function PinCodeEnter({ onClose }) {
  const [errorMessage, setErrorMessage] = React.useState(false);
  const { verifyPin } = usePinLock();

  function checkCode(code) {
    if (verifyPin(code)) {
      onClose();
    } else {
      setErrorMessage("pinYouEnteredIsIncorrectString");
    }
  }

  return (
    <PinCodeForm
      error={errorMessage}
      onPinEntered={checkCode}
      showLogout
      title={"typeInPinToUnlockString"}
      instructions={"enter4DigitPinCodeString"}
      onClose={onClose}
    />
  );
}
