import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import Loading, { LoadingLinear } from "@ingka/loading";
import { LargeText, SmallText } from "../Overview/NewReview";
import "@ingka/loading/dist/style.css";
import "@ingka/skeleton/dist/style.css";
import { TodaysProgressSkeleton } from "../Components/Skeletons/TodaysProgressSkeleton";
import { useFetchTodaysProgress } from "../Hooks/useFetchTodaysProgress";
import { getFormatedRound, getFormattedArea } from "../helper";

export const Container = styled.div`
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: auto;
  border-top: 1px solid var(--grey150);
`;

export const TextWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
`;

export const TodaysProgress = () => {
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";
  const title = activeArea !== "wholeStore" ? activeArea : "";
  const activeRound =
    localStorage.getItem("shoppingToolsRoundFilter") ?? "allRounds";
  const area = getFormattedArea(activeArea);
  const round = getFormatedRound(activeRound);
  const { data: todaysProgress, isFetching } = useFetchTodaysProgress(
    area,
    round
  );

  if (isFetching) {
    return <TodaysProgressSkeleton />;
  }

  return (
    <Container>
      <TextWrapper>
        <SmallText>
          <Trans>todaysProgressString</Trans>
          {title && (
            <>
              {" - "}
              <Trans>{title}String</Trans>
            </>
          )}
        </SmallText>
        <LargeText>{`${
          todaysProgress === 100
            ? `${todaysProgress}% 🎉`
            : `${todaysProgress}%`
        }`}</LargeText>
      </TextWrapper>
      <Loading text="" style={{ width: "90%" }}>
        <LoadingLinear
          style={{ height: "16px" }}
          loadingValue={todaysProgress ?? 0}
          loadingMax={100}
        />
      </Loading>
    </Container>
  );
};
