import { Trans } from "@coworker/locales";
import { CriterionKey } from "@coworker/types";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Wrench } from "../../../../assets/svg/wrench.svg";
import { sortCriteriaKeyDisplayOrder } from "./CriteriaHelper";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;
const CriterionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
`;
const Text = styled.div`
  font-size: 14px;
`;
const BulletIcon = styled.div`
  padding: 1rem;
  height: 22px;
  min-width: 22px;
  font-size: 18px;
  font-weight: bold;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

const WrenchIcon = styled(Wrench)`
  padding: 1rem;
`;

export interface ChecklistWidgetProps {
  criterionKeys: CriterionKey[];
}

export default function ChecklistWidget({
  criterionKeys,
}: ChecklistWidgetProps) {
  return (
    <div>
      <Container data-testid="checklistToFix">
        <TitleContainer>
          <WrenchIcon /> <Trans>checklistToFixString</Trans>
        </TitleContainer>
        {sortCriteriaKeyDisplayOrder(criterionKeys).map((criterionKey) => (
          <CriterionContainer key={criterionKey}>
            <BulletIcon>‣</BulletIcon>
            <Text>
              <Trans>tb{criterionKey}</Trans>
            </Text>
          </CriterionContainer>
        ))}
      </Container>
    </div>
  );
}
