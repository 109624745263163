import React from "react";
import {
  acceptToken,
  gotToken,
  usePinLock,
  tokenChanged,
} from "../../hooks/usePinLock";
import { useInputPopup } from "../InputPopup";

export default function PinCodeController() {
  const { hasPin, updateResetRequest, resetWasRequested } = usePinLock();
  const { getInput } = useInputPopup();
  const [showingPinCheckScreen, setShowingPinCheckScreen] =
    React.useState(false);

  const checkPin = React.useCallback(async () => {
    if (hasPin && !showingPinCheckScreen) {
      setShowingPinCheckScreen(true);
      await getInput("pinCode", { nonClosable: true });
      setShowingPinCheckScreen(false);
      acceptToken();
    }
  }, [getInput, showingPinCheckScreen, hasPin]);

  const alreadyReset = React.useRef(false); // Only ask once, after login.
  React.useEffect(() => {
    if (!hasPin) {
      // Show Create your PIN screen if user has not set PIN yet.
      getInput("pinCodeReset", { firstSet: true, nonClosable: true }).then(() =>
        acceptToken()
      );
    } else if (resetWasRequested && !alreadyReset.current) {
      // If reset was requested show that screen (once)
      alreadyReset.current = true;
      getInput("pinCodeReset", { resetRequested: true }).then(() => {
        updateResetRequest(false);
        acceptToken();
      });
    } else if (tokenChanged()) {
      // Show pin lock screen when expected
      checkPin();
    }

    // Ignored: resetRequested - to avoid deleting the reset request right after adding it!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInput, hasPin, updateResetRequest]);

  // Handle messages that should trigger showing the pin lock screen
  React.useEffect(() => {
    function messageHandler(event) {
      const forceNewToken = event?.data === "fixa_pin_code_request"; // TODO: Remove after 1.0.11 wrapper is fully deployed.
      const token = String(event?.data).split(/fixa_pin_token=/)[1]; // Wrapper changes token on timeout / restart, triggering pin check even if the Fixa client browser process would think it's logged in.
      gotToken(token || forceNewToken);
      if (tokenChanged()) checkPin();
    }
    window.addEventListener("message", messageHandler, false);
    return () => window.removeEventListener("message", messageHandler, false);
  }, [getInput, checkPin, hasPin]);

  return null;
}
