import React from "react";
import styles from "./task-card.module.css";
import { CardMode } from "./types";
import { CARD_MODE } from "./constants";
import { TaskType } from "../../enums/taskTypes";

interface TitlesProps {
  title: string;
  subtitle: string;
  cardMode: CardMode;
  type: string;
  productDescripion: string;
}

export const Titles = ({
  title,
  subtitle,
  cardMode,
  type,
  productDescripion,
}: TitlesProps) => {
  const isAddonTask = type === TaskType.ADDON;

  return (
    <>
      <div className={styles["title"]}>{title}</div>
      {cardMode !== CARD_MODE.SIMPLE && (
        <>
          {isAddonTask ? (
            <div className={styles["product-description"]}>
              {productDescripion}
            </div>
          ) : (
            <div className={styles["subtitle"]}>{subtitle}</div>
          )}
        </>
      )}
    </>
  );
};
