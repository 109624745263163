import React from "react";

// Browsers that don't have IntersectionObserver get a stub instead.
const observerStub = { disconnect: () => null, observe: () => null };

export function useIntersect({ root = null, rootMargin, threshold }) {
  const [entry, updateEntry] = React.useState({ isInitial: true });
  const [target, setTarget] = React.useState(null);

  const observer = React.useRef(
    "IntersectionObserver" in window && IntersectionObserver?.constructor
      ? new IntersectionObserver(([entry]) => updateEntry(entry), {
          root,
          rootMargin,
          threshold,
        })
      : observerStub // We simply don't support Safari 10.x and 12.0
  );

  React.useEffect(() => {
    const { current } = observer;
    // In some case the `new IntersectionObserver(...)` might return `null`
    // instead of `{disconnect: Function, observe: Function}` so we protect from that.
    if (current) {
      current.disconnect();

      if (target) current.observe(target);
    }

    return () => current && current.disconnect();
  }, [target]);

  return [setTarget, entry, target];
}
