import React from "react";

export const policy = {
  title: <div>Заявлениеоконфиденциальности</div>,
  aboutFixaText: (
    <div>
      Fixa - это инструмент управления задачами и мерчендайзинга для сотрудников
      магазина. Цель приложений Fixa - заменить ручные, бумажные или
      Excel-действия и упростить управление задачами в магазине (пополнение
      артикулов, очистка, отклонения, тестовые покупки, наиболее часто
      задаваемые вопросы и т. д.). Fixa также позволяет проводить аналитику на
      основе информации, полученной в результате выполненных задач.
      <br />
      <br />
      Когда вы будете использовать Fixa, Оператор данных Группы компаний Ингка
      (как определено ниже) будет собирать определенные персональные данные о
      вас.
    </div>
  ),
  personalDataCollectedTitle: <div>Собираемые персональные данные:</div>,
  personalDataCollectedText: (
    <div>
      При использовании Fixa, система будет собирать информацию о вас из базы
      данных сотрудников (CDS), такую как ваше имя, отчество (имена), фамилия,
      адрес электронной почты, должность, роль, номер телефона (если данные
      предоставлены вручную - номер личного телефона вводить не следует),
      фотография из CDS, языковые настройки в приложении, идентификатор
      магазина, идентификатор страны, версия устройства и операционной системы,
      IP-адрес, обрабатываемый в облачной платформе Google Cloud Platform и
      Sentry (Sentry - это программа для отслеживания ошибок приложений).
      <br />
      <br />
      Профиль сотрудника создается вручную через Fixa-admin (веб-приложение
      администратора) менеджером/суперпользователем. Персональные данные
      извлекаются из CDS, поэтому профили могут быть созданы только для
      сотрудников, у которых есть активный профиль в CDS.
    </div>
  ),
  purposeOfDataCollectionTitle: <div>Цель сбора этих данных:</div>,
  purposeOfDataCollectionText: (
    <div>
      Целью сбора личных данных в Fixa является обеспечение правильного
      функционирования приложения таким образом, чтобы облегчить внутреннюю
      коммуникацию между сотрудниками магазина и управлять задачами,
      распределенными между ними. Наличие задач, связанных с пользователем,
      позволяет нам поддерживать уведомления об обновлениях задач для
      назначенных пользователей и разработчиков.
    </div>
  ),
  legalGroundsTitle: <div>Правовое основание для обработки:</div>,
  legalGroundsText: (
    <div>Правовое основание для обработки - законный интерес.</div>
  ),
  dataWillBeStoredTitle: <div>Данные будут храниться:</div>,
  dataWillBeStoredText: (
    <div>
      Мы храним выполненные задачи со ссылками на пользователей в течение 70
      дней, после чего они анонимизируются. Персональные данные сотрудника
      хранятся в Fixa на время действия трудового договора.
      <br />
      <br />
      Расторжение трудового договора с Группой компаний Ингка: Все личные данные
      автоматически удаляются в течение 1 месяца после удаления профиля CDS.
      <br />
      <br />
      Смена роли в Группе компаний Ингка: Если сотруднику больше не требуется
      использовать Fixa, все личные данные будут автоматически удалены в течение
      3 месяцев после его использования.
      <br />
      <br />
      Персональные данные также могут быть удалены в любой момент по запросу
      локального руководителя службы информационной безопасности и
      конфиденциальности данных или директора магазина.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>Ответственным за обработку персональных данных является:</div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>ООО «ИКЕА ДОМ»</div>
  ),
  rightToPersonalInformationText: (
    <div>
      У вас есть определенные права в отношении ваших персональных данных. Для
      ознакомления пожалуйста, пройдите по ссылке{" "}
      <a href="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx">
        https://inside.ingka.com/&#8203;supportservices/&#8203;Dataprivacy/&#8203;Pages/&#8203;Individual-rights.aspx
      </a>
      , Если вы хотите узнать больше о том, как обрабатываются ваши персональные
      данные, и о ваших правах в отношении них, ознакомьтесь с используемыми для
      сохранения конфиденциальности персональных данных правилами компании ООО
      «ИКЕА ДОМ», размещенными на веб-сайте{" "}
      <a href="https://iweof.sharepoint.com/teams/o365g_countryitrussia_retruso/InformationSecurityAndDataPrivacy/SitePages/DataPrivacy.aspx">
        RU local data privacy HUB
      </a>
      . Контактная информация:{" "}
      <a href="mailto:digital.isdp.ru@ingka.ikea.com">
        digital.isdp.ru@ingka.ikea.com
      </a>
    </div>
  ),
};
