import { DashboardAnnouncement } from "./DashboardAnnouncement";
/**
 * Features that we want to announce to users with a banner, not just in release notes.
 * @type {{[featureFlag: string]: {featureNameKey: string, detailsView: JSX.Element}}}
 */
export const announceableFeatures = {
  DASHBOARD_ANNOUNCEMENT: {
    featureNameKey: "announcementDashboardTitle",
    detailsView: DashboardAnnouncement,
  },
};

/**
 * @param {function} filter function
 * @returns {string[]} The keys of announceableFeatures filtered to only include what's currently enabled.
 */
export function filteredAnnouncementKeys(filter) {
  return Object.keys(announceableFeatures).filter(filter);
}
