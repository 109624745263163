import React from "react";
import styled from "styled-components";
import { formatFullIdWithDots } from "../formatters/product.formatter";

const GreyBar = styled.div`
  font-size: 12px;
  font-weight: bold;
  padding: 4px 12px;
  background: var(--grey100);
  margin-bottom: 8px;
  margin-right: auto;
  width: min-content;
`;

export function ProductNumber({ fullId }: { fullId: string }) {
  return <GreyBar>{formatFullIdWithDots(fullId)}</GreyBar>;
}
