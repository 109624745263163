import React from "react";
import useFormatter from "../hooks/useFormatter";
import { useStoreUsers } from "../hooks/useStoreContacts";
import { Trans } from "@coworker/locales";

function SubtitleReportedBy({ creator_id, created_at }) {
  const storeUsers = useStoreUsers();
  const { formatDateShort, formatFirstNameAndInitial } = useFormatter();
  const userId = (userItem) => userItem.fixa_uid;
  const user = storeUsers?.find((item) => userId(item) === creator_id);
  const reporter_name = formatFirstNameAndInitial(user, creator_id);
  return (
    <div>
      <Trans>reportedByString</Trans> {reporter_name},{" "}
      {formatDateShort(created_at)}
    </div>
  );
}

export default SubtitleReportedBy;
