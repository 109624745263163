import React from "react";
import styled from "styled-components";
import { PoseGroup } from "react-pose";
import { revealFromBelow } from "../helpers/animationHelper";
import { StoredImage } from "./StoredImage";
import { Trans } from "@coworker/locales";

const Background = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--grey900);
  z-index: var(--z-previewimage);
  display: flex;
  flex-direction: column;
`;

const PosedBackground = revealFromBelow(Background);

const PreviewHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 28px 10px 28px;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  flex-shrink: 0px;
`;

const HeaderTitle = styled.div`
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: white;
`;

const HeaderButton = styled.button`
  line-height: 21px;
  font-size: 14px;
  color: white;
  cursor: pointer;
  background: none;
  border: none;
`;

const ImagePreviewContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageHolder = styled.div`
  margin-right: 9px;
  margin-bottom: 9px;
  overflow: hidden;
  display: inline-flex;
  vertical-align: top;
  width: 100%;
  height: 100%;
`;

function PreviewImage(props) {
  return (
    <PoseGroup animateOnMount>
      {props.image && (
        <PosedBackground key="preview">
          <PreviewHeader>
            <HeaderButton onClick={props.onClose}>
              <Trans>cancelString</Trans>
            </HeaderButton>
            <HeaderTitle>
              <Trans>imageString</Trans>
            </HeaderTitle>
            <HeaderButton onClick={props.onDelete}>
              <Trans>deleteString</Trans>
            </HeaderButton>
          </PreviewHeader>
          <ImagePreviewContainer>
            <ImageHolder>
              <StoredImage fullscreen image={props.image} dark />
            </ImageHolder>
          </ImagePreviewContainer>
        </PosedBackground>
      )}
    </PoseGroup>
  );
}

export default PreviewImage;
