import React from "react";
import { ReactComponent as IsDisplayedIcon } from "../../../../assets/svg/test-buy-displayed.svg"; //
import { ReactComponent as IsCleanIcon } from "../../../../assets/svg/test-buy-clean.svg"; //
import { ReactComponent as DoesHaveTagIcon } from "../../../../assets/svg/test-buy-tag.svg";
import { ReactComponent as BuyInstructionIcon } from "../../../../assets/svg/test-buy-tag-filled-in.svg";
import { ReactComponent as PlacedInRangeAreaIcon } from "../../../../assets/svg/stack.svg";
import { ReactComponent as IsAvailableAsExpectedIcon } from "../../../../assets/svg/test-buy-location.svg"; //
import { CriterionKey } from "@coworker/types";

export default function useCriterionSVG(criterionKey: CriterionKey) {
  switch (criterionKey) {
    case "IsItDisplayed":
      return <IsDisplayedIcon />;
    case "IsItCleanAndPerfect":
      return <IsCleanIcon />;
    case "DoesItHaveTag":
      return <DoesHaveTagIcon />;
    case "AreBuyInstructionClear":
      return <BuyInstructionIcon />;
    case "IsItPlacedAndGroupInRangeArea":
      return <PlacedInRangeAreaIcon />;
    case "IsItAvailableInIndicatedPosition":
      return <IsAvailableAsExpectedIcon />;
    default:
      return <></>;
  }
}
