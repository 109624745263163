import React from "react";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import activityTypes from "@coworker/functions/src/enums/activityTypes";
import trackerHelper from "../../helpers/tracker";
import {
  callInternal,
  MFAQ_CREATE_TASK,
  callInternalApi,
} from "../../hooks/API/useCallInternal";
import { createTask as createTaskInTheNewTasksService } from "../../hooks/API/tasks.service.helper";
import { useTaskRefresher } from "../useWatchedTasks";
import { createQuestionTask } from "../../components/MFAQ/BackendApiRepository/MfaqRepository";
import { useStoreId } from "../../core/auth/useLoggedInUser";

function addMissingItemDetailsService(taskData) {
  let additionalTaskData = {};
  if (taskData.task_type === "ADDON") {
    if (typeof taskData.item_name === "undefined") {
      additionalTaskData.item_name = "";
    }
    if (typeof taskData.item_type === "undefined") {
      additionalTaskData.item_type = "";
    }
    if (typeof taskData.item_color === "undefined") {
      additionalTaskData.item_color = "";
    }
    return additionalTaskData;
  }
  return {};
}

function replaceInvalidWithEmptyString(task) {
  const arr = Object.entries(task).map(([key, val]) => {
    if (val === undefined) {
      return [key, ""];
    } else {
      return [key, val];
    }
  });

  return arr.reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: value,
    };
  }, {});
}

/**
 * @return {FirestoreRef} ref to the created task
 */
async function makeAndReturnTaskId(taskData, images) {
  if (taskData.task_type === "ADDON" && !taskData.picked) {
    if (taskData.pickup_location_custom !== "") {
      taskData.searchable_location = taskData.pickup_location_custom;
    } else {
      taskData.searchable_location = taskData.pickup_location;
    }
  } else if (taskData.task_type === "MFAQ_FOLLOW_UP") {
    taskData.searchable_location = taskData.question_area;
  } else if (taskData.location_custom !== "") {
    taskData.searchable_location = taskData.location_custom;
  } else {
    taskData.searchable_location = taskData.location;
  }

  if (taskData.task_type === "ADDON") {
    if (taskData.activity_type === activityTypes.CREATE_AND_COMPLETE) {
      taskData.task_type_detail = "REFILL";
    } else {
      taskData.task_type_detail = "PICK_AND_REFILL";
    }
  } else if (taskData.task_type === "CUSTOM") {
    if (taskData.repeatable_task_id !== "") {
      taskData.task_type_detail = "AUTO_GENERATED";
    } else {
      taskData.task_type_detail = "REGULAR";
    }
  } else {
    taskData.task_type_detail = taskData.task_type;
  }

  const response = await callInternalApi(
    `create/${taskData.planned ? "repeatable_task" : "task"}`,
    { taskData, store_id: taskData.store_id },
    false,
    true
  );
  const id = response?.data?.id;
  const collectionName = taskData.planned ? "repeatable_tasks" : "tasks";
  if (images?.length > 0 && id) {
    await callInternalApi(
      "create/images",
      {
        list: images,
        parentPath: `${collectionName}/${id}/images`,
        store_id: taskData.store_id,
      },
      false,
      true
    );
  }
  return id;
}

export function useCreateTask() {
  const refreshTask = useTaskRefresher();

  const storeId = useStoreId();
  const createTask = React.useCallback(
    async (formData, images) => {
      const taskData = {
        created_at: Date.now(),
        ...formData,
        activity_type: formData.assigned_user_id
          ? activityTypes.CREATE_AND_ASSIGN_TO_USER
          : activityTypes.CREATE_AND_ASSIGN_TO_TEAM,
      };
      if (taskData.planned) {
        trackerHelper.plannedTasks.created(taskData.planned);
      }

      const cleanedTask = replaceInvalidWithEmptyString(taskData);
      if (
        cleanedTask.state === taskStateOptions.COMPLETED &&
        cleanedTask.activity_type === activityTypes.CREATE_AND_ASSIGN_TO_USER
      ) {
        cleanedTask.activity_type = activityTypes.CREATE_AND_COMPLETE;
      }
      let id = "";
      if (window.callTasksService) {
        id = await createTaskInTheNewTasksService(cleanedTask, images);
      } else {
        let modifiedTaskData = { ...cleanedTask };
        if (window.forwardToTasksService) {
          modifiedTaskData = {
            ...cleanedTask,
            also_written_to_pgsql_8297: true,
          };
        }
        id = await makeAndReturnTaskId(modifiedTaskData, images);
        if (window.forwardToTasksService) {
          const additionalTaskData = addMissingItemDetailsService(cleanedTask);
          let modifiedTaskDataService = {
            ...cleanedTask,
            ...additionalTaskData,
          };
          await createTaskInTheNewTasksService(
            modifiedTaskDataService,
            images,
            id
          );
        }
      }

      const { task_type, state } = cleanedTask;
      const assigned_status =
        taskStateOptions.ASSIGNED === state
          ? "Assigned to user"
          : "Assigned to team";

      if (
        task_type === taskTypeOptions.ADDON &&
        state === taskStateOptions.COMPLETED
      ) {
        trackerHelper.trackCreateTask({
          ...cleanedTask,
          task_id: id,
          assigned_status,
        });
        trackerHelper.trackRefillAddonTask({
          ...cleanedTask,
          task_id: id,
        });
        trackerHelper.trackCompleteTask(
          { ...cleanedTask, task_id: id },
          { create_and_complete: true }
        );
      } else {
        trackerHelper.trackCreateTask({
          ...cleanedTask,
          task_id: id,
          assigned_status,
        });
      }

      const isMfaqTask = taskData.task_type === taskTypeOptions.MFAQ_FOLLOW_UP;
      // We set finished_at field with valid date while creating a task due to Elasticsearch requiring the field while querying. Refer to aggregateTasksByInterval in es.helpers.
      if (isMfaqTask) {
        if (window.callMFAQService) {
          createQuestionTask(taskData.question_id, {
            task_id: id,
            description: taskData.description,
            area: taskData.question_area,
            state: taskData.state,
          });
        } else {
          await callInternal(MFAQ_CREATE_TASK, {
            question_id: taskData.question_id,
            task_id: id,
            created_at: Date.now(),
            area: taskData.question_area,
            finished_summary: "",
            state: taskData.state,
            description: taskData.description,
            finished_at: Date.now(),
            store_id: storeId,
          });
        }
      }

      refreshTask(id, taskData, {});
      return id;
    },
    [refreshTask, storeId]
  );

  return { call: createTask };
}
