import React from "react";
import styled, { css } from "styled-components";
import posed from "react-pose";

const InputGroup = styled.div`
  width: 100%;
  height: 50px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  ${({ additionalSpace }) =>
    additionalSpace &&
    css`
      height: 75px;
    `}
  ${({ dark }) =>
    dark &&
    css`
      background-color: var(--blue);
    `}
`;

const Label = styled.label`
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  color: var(--grey700);
  margin-bottom: 8px;
  transform-origin: 0 0;
  pointer-events: none;
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--grey500);
    `}
  ${({ dark, disabled }) =>
    dark &&
    css`
      color: var(--white);
      ${disabled &&
      css`
        color: var(--white-ice);
      `}
    `}
`;

const AnimatedLabel = posed(Label)({
  touched: {
    y: 0,
    scale: 1,
  },
  empty: {
    y: "27px",
    scale: 1.33,
  },
});

const InputField = styled.input`
  height: 24px;
  outline: none;
  font-size: 16px;
  line-height: 1.25;
  padding-bottom: 1px;
  border-bottom: 1px solid var(--grey500);
  caret-color: var(--new);
  :focus {
    padding-bottom: 0;
    border-bottom: 2px solid var(--blue);
  }
  :disabled {
    border-bottom: 1px solid var(--grey200);
    color: var(--grey500);
  }
  ${({ dark }) =>
    dark &&
    css`
      color: var(--white);
      background: var(--blue);
      border-bottom: 1px solid var(--white);
      caret-color: var(--white);
      :focus {
        border-bottom: 2px solid var(--yellow);
      }
      :disabled {
        border-bottom: 1px solid var(--white-ice);
        color: var(--white-ice);
      }
    `}
  ${({ error }) =>
    error &&
    css`
      border-color: var(--error);
      :focus {
        border-color: var(--error);
      }
    `}
`;

const Suggestion = styled.p`
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  color: var(--grey700);
  margin-top: 7px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--grey500);
    `}
  ${({ dark, disabled }) =>
    dark &&
    css`
      color: white;
      ${disabled &&
      css`
        color: var(--white-ice);
      `}
    `}
`;

const ErrorMessage = styled.p`
  height: 16px;
  font-size: 12px;
  line-height: 1.33;
  color: var(--error);
  margin-top: 7px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--grey500);
    `}
  ${({ dark, disabled }) =>
    dark &&
    css`
      color: white;
      ${disabled &&
      css`
        color: var(--white-ice);
      `}
    `}
`;

const Input = React.forwardRef(
  (
    {
      error,
      suggestion,
      dark,
      disabled,
      required,
      name = "test",
      animatedText,
      phone,
      value = "",
      onChange,
      autoFocus,
      autoComplete,
      max,
      testId,
    },
    ref
  ) => {
    const [touched, setTouched] = React.useState(false);

    const setValue = React.useCallback(
      (event) => {
        onChange(event.target.value);
        setTouched(true);
      },
      [onChange, setTouched]
    );

    const onFocus = React.useCallback(() => {
      setTouched(true);
    }, [setTouched]);

    const onBlur = React.useCallback(() => {
      if (value) {
        const newValue = value.trim();
        if (value !== newValue) {
          onChange(newValue);
        }
      }
      setTouched(false);
    }, [onChange, value]);
    return (
      <InputGroup
        additionalSpace={error || (suggestion && touched)}
        dark={dark}
        data-testid={testId}
      >
        <AnimatedLabel
          pose={touched || value ? "touched" : "empty"}
          dark={dark}
          disabled={disabled}
          htmlFor={name}
        >
          {animatedText}
          {animatedText && !required && "(optional)"}
        </AnimatedLabel>
        {phone ? (
          <InputField
            ref={ref}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            value={value}
            onChange={setValue}
            onFocus={onFocus}
            onBlur={onBlur}
            dark={dark}
            required={required}
            name={name}
            id={name}
            error={!!error}
            type="tel"
            pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
            maxLength={max}
          />
        ) : (
          <InputField
            ref={ref}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            disabled={disabled}
            value={value}
            onChange={setValue}
            onFocus={onFocus}
            onBlur={onBlur}
            dark={dark}
            required={required}
            name={name}
            id={name}
            error={!!error}
            maxLength={max}
          />
        )}
        {((touched && suggestion && !error) || null) && (
          <Suggestion dark={dark}>{suggestion}</Suggestion>
        )}
        {(error || null) && <ErrorMessage dark={dark}>{error}</ErrorMessage>}
      </InputGroup>
    );
  }
);

export default Input;
