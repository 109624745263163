import React from "react";
import Modal, { ModalHeader, Theatre } from "@ingka/modal";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/modal/dist/style.css";
import "@ingka/focus/dist/style.css";

interface SkapaTheatreProps {
  modalOpen: boolean;
  handleCloseBtn: () => void;
  children: React.ReactNode;
}

export const SkapaTheatre = ({
  modalOpen,
  handleCloseBtn,
  children,
}: SkapaTheatreProps) => {
  return (
    <Modal visible={modalOpen} handleCloseBtn={handleCloseBtn}>
      <Theatre header={<ModalHeader />}>{children}</Theatre>
    </Modal>
  );
};
