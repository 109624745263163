import React from "react";
import { useParams } from "react-router";
import { useArticlesByGroupId } from "../hooks/useArticlesByGroupId";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { ArticleViewSkeleton } from "../Skeletons/ArticleViewSkeleton";
import { useInputPopup } from "../../InputPopup";
import { useTranslation } from "@coworker/locales";
import { CenterContainerWithGap, ListWrapper } from "../styles/styles";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { useSearchParams } from "react-router-dom";
import { MessageAndButton } from "../Molecules/MessageAndButton";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { FullWidthButton } from "../../SkapaButton";
import { PTagArticleSummaryForClipboardModal } from "../Organisms/Modals/PTagArticleSummaryForClipboardModal";
import { ModalTypes } from "../types/views";
import { useQueryClient } from "@tanstack/react-query";
import SSRIcon from "@ingka/ssr-icon";
import PencilIcon from "@ingka/ssr-icon/paths/pencil";
import { GroupActionsModal } from "../Organisms/Modals/GroupActionModal";
import { Group } from "../types/groups";
import { EditGroupModal } from "../Organisms/Modals/EditGroupModal";
import { useGroupsByRoomId } from "../hooks/useGroupsByRoomId";
import { DeleteGroupModal } from "../Organisms/Modals/DeleteGroupModal";

export function GroupView() {
  const { groupId } = useParams();
  const [queryParams] = useSearchParams();
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();
  const { getInput } = useInputPopup();
  const queryClient = useQueryClient();

  const [currentModalType, setCurrentModalType] = React.useState<ModalTypes>(
    ModalTypes.DEFAULT_VIEW
  );

  const roomId = queryParams.get("roomId") ?? undefined;

  const { data: articles } = useArticlesByGroupId(groupId);
  const { data: groups } = useGroupsByRoomId(roomId);

  const group = groups?.find((group) => group.id === groupId);

  const fullScreenPopup = (children: React.ReactNode) => (
    <FullScreenPopup
      appBarConfig={{
        title: group?.name ?? t("loadingString"),
        actions: [
          {
            name: "settings",
            icon: <SSRIcon paths={PencilIcon} />,
            onClick: () => setCurrentModalType(ModalTypes.GROUP_ACTIONS),
            position: "right",
          },
        ],
        onBack: () => {
          push(`/roomsettings/tabs/${roomId}?showGroupsTab=true`);
        },
      }}
      actionBarContent={
        <CenterContainerWithGap $centerText={true}>
          <FullWidthButton
            type="primary"
            text={t("addArticlesToGroupString")}
            onClick={async () => {
              await getInput("addMultipleArticles", {
                roomId,
                groupId,
              });
              await queryClient.invalidateQueries([
                "articlesByGroup",
                { id: groupId },
              ]);
            }}
          />
          <FullWidthButton
            type="secondary"
            text={t("createPTagPrintListString")}
            onClick={() => {
              setCurrentModalType(ModalTypes.PTAG_GROUP_ARTICLE_NUMBERS);
            }}
          />
        </CenterContainerWithGap>
      }
      noPadding
    >
      {children}
    </FullScreenPopup>
  );

  if (!articles || !groups) {
    return <>{fullScreenPopup(<ArticleViewSkeleton />)}</>;
  }

  if (!articles || articles?.length === 0) {
    return (
      <>
        {fullScreenPopup(
          <MessageAndButton
            keyForMessageText={"noArticlesInGroupString"}
            keyForButtonText={"addArticlesString"}
            buttonAction={async () => {
              await getInput("addMultipleArticles", {
                roomId,
                groupId,
              });
              await queryClient.invalidateQueries([
                "articlesByGroup",
                { id: groupId },
              ]);
            }}
          />
        )}
      </>
    );
  }

  return (
    <>
      {fullScreenPopup(
        <>
          <ListWrapper>
            {articles.map((article) => (
              <ArticleListItem
                key={article.id}
                article={article}
                hideChevron={true}
                showActionRow={true}
              />
            ))}
          </ListWrapper>
          <PTagArticleSummaryForClipboardModal
            selectedArticles={articles}
            modalVisibility={
              currentModalType === ModalTypes.PTAG_GROUP_ARTICLE_NUMBERS
            }
            onTriggerNewModal={(modalType: ModalTypes) => {
              setCurrentModalType(modalType);
            }}
          />
          <GroupActionsModal
            modalVisibility={currentModalType === ModalTypes.GROUP_ACTIONS}
            onTriggerNewModal={(modalType: ModalTypes) => {
              setCurrentModalType(modalType);
            }}
            roomId={roomId ?? ""}
            currentGroup={group as Group}
            newGroupEditModal={true}
          />
          <EditGroupModal
            modalVisibility={currentModalType === ModalTypes.EDIT_GROUP_NAME}
            currentGroup={group as Group}
            onTriggerNewModal={(modalType: ModalTypes) => {
              setCurrentModalType(modalType);
              queryClient.invalidateQueries(["groupsByRoom", roomId]);
            }}
            groups={groups || []}
            roomId={roomId || ""}
          />
          <DeleteGroupModal
            modalVisibility={currentModalType === ModalTypes.DELETE_GROUP}
            roomId={roomId || ""}
            allArticles={articles}
            currentGroup={group}
            onTriggerNewModal={(_modalType: ModalTypes) => {
              push(`/roomsettings/tabs/${roomId}?showGroupsTab=true`);
            }}
          />
        </>
      )}
    </>
  );
}
