import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { fetchAllProgressByRound } from "../ShoppingToolsAPI";
import { INTERVAL } from "../../../constants/clientTime";
import { getStartOfDay } from "../helper";

export const useFetchAllProgressByRound = () => {
  const queryClient = useQueryClient();
  const storeId = useStoreId();
  const startOfDay = getStartOfDay();
  return useQuery({
    queryKey: ["shopping-tools-progress-by-round", storeId],
    queryFn: () => {
      return fetchAllProgressByRound(storeId, startOfDay);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["shopping-tools-count-on-division"]);
      queryClient.invalidateQueries([
        "shopping-tools-divisions-count-by-round",
      ]);
    },
    staleTime: INTERVAL.MINUTE * 1,
  });
};
