import React from "react";
import styles from "./simple-header.module.css";

interface SimpleHeaderProps {
  displayAllScreens?: boolean;
}
export const SimpleHeader: React.FC<SimpleHeaderProps> = ({
  displayAllScreens,
}) => {
  return (
    <div
      className={`${styles["simple-main-header"]} ${
        displayAllScreens ? styles["display-all"] : ""
      }`}
    >
      <div className={styles["simple-main-header-text"]}>Fixa</div>
    </div>
  );
};
