import React, { SVGProps } from "react";
import Button from "@ingka/dual-button";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/dual-button/dist/style.css";

interface SkapaDualButtonProps {
  orientation: "horizontal" | "vertical";
  ssrIconFirst: (prefix?: string | undefined) => SVGProps<SVGElement>[];
  ssrIconSecond: (prefix?: string | undefined) => SVGProps<SVGElement>[];
  onClickFirst: () => void;
  onClickSecond: () => void;
}

function SkapaDualButton({
  orientation,
  ssrIconFirst,
  ssrIconSecond,
  onClickFirst,
  onClickSecond,
}: SkapaDualButtonProps) {
  return (
    <Button
      type="primary"
      small={true}
      orientation={orientation}
      ssrIconFirst={ssrIconFirst}
      ssrIconSecond={ssrIconSecond}
      onClickFirst={onClickFirst}
      onClickSecond={onClickSecond}
    />
  );
}

export default SkapaDualButton;
