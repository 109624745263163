import React from "react";
import styled from "styled-components";

const Element = styled.div`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--black);
  padding: 20px 29px 9px 29px;
  ${(props) => props.clickable && `cursor: pointer;`}
  ${(props) => props.uppercase && `text-transform: uppercase;`}
`;

const noop = () => null;
function SearchSuggestion({
  text,
  onClick = noop,
  clickable,
  uppercase = false,
}) {
  return (
    <Element
      onClick={onClick}
      clickable={clickable}
      uppercase={uppercase}
      data-testid={`suggestion-${text}`}
    >
      {text}
    </Element>
  );
}

export default SearchSuggestion;
