import React from "react";
import { useLatestSalesDate } from "../hooks/useLatestSalesDate";
import { Trans } from "@coworker/locales";
import { SmallItemSkeleton } from "@coworker/components";
import styled from "styled-components";

const LatestSalesDateContainer = styled.div`
  margin-left: 22px;
  padding-top: 7px;
  font-size: 14px;
`;

const LatestSalesDateString = styled.span`
  margin-left: 2px;
`;

export default function LatestSalesDate({ articleId }: { articleId: string }) {
  const { loading, latestSalesDate } = useLatestSalesDate(articleId);
  return !loading ? (
    <>
      {!!latestSalesDate && (
        <LatestSalesDateContainer>
          <Trans>outgoingProductString</Trans>:
          <LatestSalesDateString>{latestSalesDate}</LatestSalesDateString>
        </LatestSalesDateContainer>
      )}
    </>
  ) : (
    <SmallItemSkeleton />
  );
}
