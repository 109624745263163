import { SettingsKeyType } from "./Components/SettingsListView";
import {
  ActiveRoundObject,
  ChartData,
  SettingsDefault,
  SettingsResponse,
  ShoppingToolsTypeObject,
  ToolsCount,
} from "./Types";
import {
  DIVISION_HASH,
  FILTER_PERIOD_HASH,
  ROUND_HASH,
  TERTIALS,
  TOTAL_MAX_COUNTED_VALUE,
  TOTAL_MAX_VALUE,
  Tertial,
  Timestamps,
  TOTAL_ROUND_VALUE,
  typesOfShoppingToolsList,
  ShoppingToolType,
  NO_TOOL,
} from "./constants";
import { temporaryCurrency } from "./temporaryCurrency";
import dayjs from "dayjs";

export const filterShoppingToolsOnRound = (
  shoppingToolsArray: ShoppingToolsTypeObject[],
  roundName: string | undefined
): number => {
  const splitRound = shoppingToolsArray?.filter(
    (rounds) => rounds.round_name === roundName
  );
  if (splitRound?.length < TOTAL_MAX_COUNTED_VALUE) {
    return splitRound?.length;
  }
  return TOTAL_MAX_COUNTED_VALUE;
};

export const getStartOfDay = (): number => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today.getTime();
};

export const calculateShoppingToolsCount = (
  shoppingToolsArray: ShoppingToolsTypeObject[],
  division: string | undefined
): number => {
  const roundCount = shoppingToolsArray?.filter(
    (Round) => Round.division === division
  );

  const roundNameCheckMorning = filterShoppingToolsOnRound(
    roundCount,
    ROUND_HASH?.MORNING?.round
  );
  const roundNameCheckAfternoon = filterShoppingToolsOnRound(
    roundCount,
    ROUND_HASH?.AFTERNOON?.round
  );
  const roundNameCheckMidday = filterShoppingToolsOnRound(
    roundCount,
    ROUND_HASH?.MIDDAY?.round
  );

  const combinedResult =
    roundNameCheckAfternoon + roundNameCheckMidday + roundNameCheckMorning;
  return combinedResult;
};
export const calculateProgressPercentage = (
  rounds: ShoppingToolsTypeObject[]
): number => {
  const showroomCount = calculateShoppingToolsCount(
    rounds,
    DIVISION_HASH.SHOWROOM?.division
  );
  const markethallCount = calculateShoppingToolsCount(
    rounds,
    DIVISION_HASH.MARKETHALL?.division
  );
  const cashlineCount = calculateShoppingToolsCount(
    rounds,
    DIVISION_HASH.CASHLINE?.division
  );

  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";
  const combinedCount = showroomCount + markethallCount + cashlineCount;
  let percentage;

  if (activeArea === "showroomOTW") {
    percentage = Math.round((showroomCount / TOTAL_ROUND_VALUE) * 100);
  } else if (activeArea === "markethallOTW") {
    percentage = Math.round((markethallCount / TOTAL_ROUND_VALUE) * 100);
  } else if (activeArea === "otherAreaCashline") {
    percentage = Math.round((cashlineCount / TOTAL_ROUND_VALUE) * 100);
  } else {
    percentage = Math.round((combinedCount / TOTAL_MAX_VALUE) * 100);
  }

  return percentage;
};

export function findSettingsObject(
  settingsList: SettingsDefault[] | SettingsResponse[],
  division: string
) {
  return settingsList?.find(
    (obj: SettingsDefault | SettingsResponse) => obj.division === division
  );
}

export function getCurrency(countryId: string) {
  return temporaryCurrency.find((country) => country.countryId === countryId)
    ?.currency;
}
export const groupCurrency1 = {
  countryList: ["EUR", "CHF", "GBP", "USD"],
  currencyBlockAmount: 2000,
};
export const groupCurrency2 = {
  countryList: ["INR", "JPY", "RSD"],
  currencyBlockAmount: 300000,
};
export const groupCurrency3 = {
  countryList: ["PLN", "RON"],
  currencyBlockAmount: 10000,
};
export const groupCurrency4 = {
  countryList: ["AUD", "CAD"],
  currencyBlockAmount: 3000,
};
export const groupCurrency5 = {
  countryList: ["CZK"],
  currencyBlockAmount: 50000,
};
export const groupCurrency6 = {
  countryList: ["HUF"],
  currencyBlockAmount: 700000,
};
export const groupCurrency7 = {
  countryList: ["KRW"],
  currencyBlockAmount: 2500000,
};

export function getGroupCurrencyBlockAmount(currency: string): number {
  const currencyGroups = [
    groupCurrency1,
    groupCurrency2,
    groupCurrency3,
    groupCurrency4,
    groupCurrency5,
    groupCurrency6,
    groupCurrency7,
  ];

  for (const group of currencyGroups) {
    if (group.countryList.includes(currency)) {
      return group.currencyBlockAmount;
    }
  }

  return 20000;
}

export function scrollToTop(): void {
  const scrollableContainer = document.querySelector("div[id=main-content]");
  if (scrollableContainer) {
    scrollableContainer.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }
}

export function addShowroomToolsToMarkethall(
  settingsList: SettingsDefault[] | SettingsResponse[],
  activeSettings: SettingsDefault | SettingsResponse
) {
  const activeShowroomSettings: SettingsDefault | SettingsResponse =
    findSettingsObject(settingsList, DIVISION_HASH.SHOWROOM?.division ?? "")!;

  const foundMarkethallTools = Object.keys(activeSettings ?? []).filter(
    (key) => (activeSettings as SettingsKeyType)[key] === true
  );

  const foundShowroomTools = Object.keys(activeShowroomSettings ?? []).filter(
    (key) => (activeShowroomSettings as SettingsKeyType)[key] === true
  );

  const updatedMarkethallSettings = [...foundShowroomTools];

  for (const item of foundMarkethallTools) {
    if (!updatedMarkethallSettings.includes(item)) {
      updatedMarkethallSettings.push(item);
    }
  }

  return updatedMarkethallSettings;
}

export function getChartData(
  allTools: ChartData[],
  area: string | undefined,
  t: Function
) {
  const tools = [
    {
      label: "noToolString",
      percentage: getToolData(allTools, area, "no_tool"),
      backgroundColor: "#DFDFDF",
    },
    {
      label: "yellowBagString",
      percentage: getToolData(allTools, area, "yellow_bag"),
      backgroundColor: "#F9DC4A",
    },
    {
      label: "blueBagToolString",
      percentage: getToolData(allTools, area, "blue_bag"),
      backgroundColor: "#4294CD",
    },
    {
      label: "yellowBasketString",
      percentage: getToolData(allTools, area, "yellow_basket"),
      backgroundColor: "#BC5825",
    },
    {
      label: "strollerString",
      percentage: getToolData(allTools, area, "stroller"),
      backgroundColor: "#24579E",
    },
    {
      label: "bagTrolleyString",
      percentage: getToolData(allTools, area, "bag_trolley"),
      backgroundColor: "#DF8CB1",
    },
    {
      label: "trolleyString",
      percentage: getToolData(allTools, area, "trolley"),
      backgroundColor: "#87B33E",
    },
    {
      label: "furnitureTrolleyString",
      percentage: getToolData(allTools, area, "furniture_trolley"),
      backgroundColor: "#2C673C",
    },
  ];

  const toolsWithValues = tools.filter((tool) => tool.percentage > 0);

  return {
    labels: toolsWithValues.map(
      (tool) => `${t(tool.label)}: ${tool.percentage}%`
    ),
    datasets: [
      {
        data: toolsWithValues.map((tool) => tool.percentage),
        backgroundColor: toolsWithValues.map((tool) => tool.backgroundColor),
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };
}

export function getToolData(
  allTools: ChartData[],
  area: string | undefined,
  tool: string
) {
  const dataForArea = allTools?.find((toolData) => toolData?.division === area);

  if (!dataForArea) return 0;

  const shoppingTools =
    area === DIVISION_HASH.SHOWROOM?.division
      ? dataForArea.initialShoppingTools
      : dataForArea.lastShoppingTools;

  const toolCount = shoppingTools?.[tool] ?? 0;

  return (
    Math.round(
      (toolCount /
        Object.values(shoppingTools).reduce((acc, curr) => acc + curr, 0)) *
        1000
    ) / 10
  );
}

export function createTimestampsFromDates(
  startDate: string | undefined,
  endDate: string | undefined
): Timestamps {
  const startDateTimestamp = Date.parse(startDate ?? "");

  const endDateParse = Date.parse(endDate ?? "");
  const endDateTimestamp = endDateParse + 24 * 60 * 60 * 1000 - 1;

  const timestamps = {
    startDate: startDateTimestamp,
    endDate: endDateTimestamp,
  };

  return timestamps;
}

export function getTertialYear(
  tertialNumber: number | undefined,
  period: string
): number {
  let currentYear = dayjs().year();
  if (tertialNumber === 2 && period === FILTER_PERIOD_HASH.LAST_TERTIAL) {
    currentYear--;
  }

  return currentYear;
}

export function getTertialTimestamps(
  currentTertial: Tertial | undefined,
  period: string
): Timestamps {
  let currentTertialNumber = currentTertial?.tertialNumber;
  if (period === FILTER_PERIOD_HASH.LAST_TERTIAL) {
    if (currentTertialNumber && currentTertial?.tertialNumber !== 1) {
      currentTertialNumber--;
    } else {
      currentTertialNumber = 3;
    }
  }

  const foundTertial = TERTIALS.find(
    (tertial) => tertial.tertialNumber === currentTertialNumber
  );

  const currentYear = getTertialYear(foundTertial?.tertialNumber, period);

  const timestamps = createTimestampsFromDates(
    `${currentYear}-${foundTertial?.startDate}`,
    `${currentYear}-${foundTertial?.endDate}`
  );

  return timestamps;
}

export function getTertialByPeriod(
  tertial: Tertial | undefined,
  period: string
): Timestamps {
  const currentYear = getTertialYear(tertial?.tertialNumber, period);

  const periodTertial = {
    tertialNumber: tertial?.tertialNumber,
    startDate: `${currentYear}-${tertial?.startDate}`,
    endDate: `${currentYear}-${tertial?.endDate}`,
  };

  const tertialWithTimestamps = getTertialTimestamps(periodTertial, period);

  return tertialWithTimestamps;
}

export function getCurrentTertial() {
  const today = dayjs().format("MM-DD");

  const currentTertial = TERTIALS.find(
    (tertial: Tertial) => tertial.startDate <= today && tertial.endDate >= today
  );

  return currentTertial;
}

export function findTertial(period: string): Timestamps | undefined {
  const currentTertial = getCurrentTertial();

  const periodTertial = getTertialByPeriod(currentTertial, period);

  return periodTertial;
}

export function getStartAndEndDateForChartData(
  period: string,
  customStartDate?: string | undefined,
  customEndDate?: string | undefined
): Timestamps {
  let startDate;
  let endDate;
  if (period === FILTER_PERIOD_HASH.TODAY) {
    const today = dayjs().format("YYYY-MM-DD");
    const timestamps = createTimestampsFromDates(today, today);

    startDate = timestamps.startDate;
    endDate = timestamps.endDate;
  } else if (
    period === FILTER_PERIOD_HASH.THIS_TERTIAL ||
    period === FILTER_PERIOD_HASH.LAST_TERTIAL
  ) {
    const tertial = findTertial(period);

    startDate = tertial?.startDate;
    endDate = tertial?.endDate;
  } else {
    const timestamps = createTimestampsFromDates(
      customStartDate,
      customEndDate
    );

    startDate = timestamps.startDate;
    endDate = timestamps.endDate;
  }
  return {
    startDate,
    endDate,
  };
}

export function getPeriodTitle(
  activePeriod: string,
  customStartDate: string,
  customEndDate: string
): string {
  if (activePeriod !== "customTitle") return `${activePeriod}String`;

  return `${dayjs(customStartDate).format("DD/MM")} - ${dayjs(
    customEndDate
  ).format("DD/MM")}`;
}

export function getRoundData(value: number) {
  let color: string = "";
  let level: number = 0;
  let maxValue: number = 0;
  let displayValue: number = 0;
  let displayMaxValue: number = 0;
  let isLevelCompleted: boolean = false;
  let isFirstStarReached: boolean = false;

  const currentValue = typeof value === "string" ? parseFloat(value) : value;

  // Level 1
  if (currentValue >= 0 && currentValue <= 50) {
    color = "#0058A3";
    maxValue = 50;
    level = 1;
    displayValue = currentValue;
    displayMaxValue = maxValue;
    isFirstStarReached = currentValue >= 25;
    isLevelCompleted = currentValue === 50;
    // Level 2
  } else if (currentValue > 50 && currentValue <= 100) {
    color = "#80B75E";
    maxValue = 100;
    level = 2;
    displayValue = currentValue - 50;
    displayMaxValue = maxValue - 50;
    isFirstStarReached = currentValue >= 75;
    isLevelCompleted = currentValue === 100;
    // Level 3
  } else if (currentValue > 100 && currentValue <= 150) {
    color = "#CB6AF9";
    maxValue = 150;
    level = 3;
    displayValue = currentValue - 100;
    displayMaxValue = maxValue - 100;
    isFirstStarReached = currentValue >= 125;
    isLevelCompleted = currentValue === 150;
    // Level 4
  } else {
    color = "#484848";
    maxValue = 200;
    level = 4;
    displayValue = currentValue - 150;
    displayMaxValue = maxValue - 150;
    isFirstStarReached = currentValue >= 175;
    isLevelCompleted = currentValue >= 200;
  }

  return {
    color,
    currentValue,
    maxValue,
    level,
    displayValue,
    displayMaxValue,
    isLevelCompleted,
    isFirstStarReached,
  };
}

export function cleanKeyNumberPadValue(value: string) {
  value = value || "";
  value = value.replace(/[^0-9,.]/g, "");
  value = value.replace(/,/g, ".");

  return value;
}

export function getFormattedArea(activeArea: string) {
  switch (activeArea) {
    case "showroomOTW":
      return "showroom";
    case "markethallOTW":
      return "markethall";
    case "otherAreaCashline":
      return "cashline";
    default:
      return "";
  }
}

export function checkLastShoppingToolsValues(lastShoppingTools: {
  [key: string]: number;
}) {
  return Object.values(lastShoppingTools).every((value) => value === 0);
}

export function sortAndDisableSettingsList(toolsToBeDisabledList: string[]) {
  const removedSettingsTools = typesOfShoppingToolsList().filter(
    (setting) => !toolsToBeDisabledList.includes(setting.name)
  );

  const mySettingsList = typesOfShoppingToolsList().filter(
    (setting) =>
      !removedSettingsTools.some(
        (removedSettings) => setting.name === removedSettings.name
      )
  );

  const sortedSettingsList = [...mySettingsList, ...removedSettingsTools];
  return sortedSettingsList;
}

export function checkActiveRound(round: string): ActiveRoundObject {
  const roundTimings = {
    [ROUND_HASH.MORNING.round]: { start: 7, end: 13 },
    [ROUND_HASH.MIDDAY.round]: { start: 11, end: 16 },
    [ROUND_HASH.AFTERNOON.round]: { start: 15, end: 21 },
  } as { [key: string]: { start: number; end: number } };
  const timings = roundTimings[round];
  if (!timings) {
    return { isActiveRound: false, startTime: 0, endTime: 0 };
  }
  const currentHour = dayjs().hour();
  const active = currentHour >= timings.start && currentHour < timings.end;
  return {
    isActiveRound: active,
    startTime: timings.start,
    endTime: timings.end,
  };
}

export function hasNoTools(
  lastShoppingTools: ToolsCount,
  type: ShoppingToolType
): boolean {
  return (lastShoppingTools.no_tool ?? 0) > 0 && type.name !== NO_TOOL;
}
export function checkActiveRoundFilter(title: string) {
  if (title.includes("String")) {
    title = title.replace("String", "");
  }
  const activeRound =
    localStorage.getItem("shoppingToolsRoundFilter") ?? "allRounds";
  return title === activeRound || activeRound === "allRounds";
}
export function getFormatedRound(round: string) {
  if (round === "allRounds") return "";
  if (round.includes("Round")) {
    round = round.replace("Round", "");
  }

  return round;
}

export function isMaxToolsReachedCashline(
  cashlineTools: ToolsCount,
  toolLimit: number
): boolean {
  let totalCashlineTools = 0;
  Object.values(cashlineTools).forEach((count) => {
    totalCashlineTools += count;
  });
  return totalCashlineTools < toolLimit ? true : false;
}
