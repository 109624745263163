import React from "react";
import styled from "styled-components";
import { PositiveNumberKeypad } from "../PositiveNumberKeypad";
import { Trans } from "@coworker/locales";

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 50px;
  overflow-y: auto;
`;
const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
  color: var(--grey900);
  margin: 55px 29px;
  margin-bottom: 0px;
`;

const AmountWrapper = styled.input`
  font-size: 16px;
  line-height: 40px;
  width: 100%;
  padding: 0px 0px 0px 10px;
  border: 1px solid var(--grey900);
`;

const InputWrapper = styled.div`
  margin: 10px 40px 10px 29px;
`;

const CapInfo = styled.div`
  font-size: 12px;
  color: var(--grey600);
`;

const Number = ({ value, bigTitle, instruction, onSubmit, maxLength = 11 }) => {
  const [chosen, setChosen] = React.useState(value || "");
  const containerRef = React.useRef(null);

  // wbr so keypad does not jump around.
  const CapText = () => {
    if (chosen.length >= maxLength) {
      return <Trans>noMoreDigitsCanBeEntered</Trans>;
    } else {
      return <wbr />;
    }
  };

  return (
    <Container ref={containerRef} data-testid="dateStampModal">
      <Title>{bigTitle}</Title>

      <InputWrapper>
        <AmountWrapper value={chosen} readOnly data-testid="dateStampInput" />
        <CapInfo>
          <CapText />
        </CapInfo>
      </InputWrapper>

      <PositiveNumberKeypad
        initialValue={chosen}
        disabled={!chosen}
        onUpdate={(value) => setChosen(value)}
        onComplete={() => {
          onSubmit(chosen);
        }}
        completeTestid="dateStampButton"
        maxLength={maxLength}
      />

      {instruction}
    </Container>
  );
};

export default Number;
