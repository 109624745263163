/**
 * Shared between front and backend.
 * Returns a filter predicate that can be used in .map or with if
 * @param {String} query The user's location query (what they already started to input)
 * @returns {Boolean} true if `code`
 */
function makePartialMatcher(query) {
  const queryWords = query.toUpperCase().split(" ");
  return (locationCode) => {
    if (!locationCode || !locationCode.toUpperCase) return false;
    const locationWords = locationCode.toUpperCase().split(" ");
    return locationWords.some((locationWord) =>
      queryWords.some((queryWord) => locationWord.startsWith(queryWord))
    );
  };
}

module.exports.makePartialMatcher = makePartialMatcher;
