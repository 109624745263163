import {
  PQDescribeTheProblemOptions,
  PQDescribeTheProblemTag,
} from "@coworker/types/lib/tasks/constants";

export type OptionListItem = {
  type: PQDescribeTheProblemOptions;
  translationKey: string;
  tags: TagTranslationKey[];
};

export type TagTranslationKey = {
  tag: PQDescribeTheProblemTag;
  translationKey: string;
};

export const DescribeTheProblemList: OptionListItem[] = [
  {
    type: "LOOK_AND_FEEL",
    translationKey: "pqdLookAndFeel",
    tags: [
      {
        tag: "CRACKING_BREAKING",
        translationKey: "pqdLFCrackingBreaking",
      },
      { tag: "DENTS", translationKey: "pqdLFDents" },
      {
        tag: "SPOTS_STAINS",
        translationKey: "pqdLFSpotsStains",
      },
      {
        tag: "LOOSE_THREAD",
        translationKey: "pqdLFLooseThread",
      },
      {
        tag: "POOR_LAQUERING",
        translationKey: "pqdLFPoorLaquering",
      },
    ],
  },
  {
    type: "ASSEMBLY",
    translationKey: "pqdAssembly",
    tags: [
      {
        tag: "PARTS_MISSING",
        translationKey: "pqdAPartsMissing",
      },
      {
        tag: "PARTS_BROKEN",
        translationKey: "pqdAPartsBroken",
      },
      {
        tag: "WRONG_DRILLING",
        translationKey: "pqdAWrongDrilling",
      },
      {
        tag: "POOR_WELDING",
        translationKey: "pqdAPoorWelding",
      },
      {
        tag: "WRONG_DIMENSIONS",
        translationKey: "pqdAWrongDimensions",
      },
    ],
  },
  {
    type: "PRODUCT_MALFUNCTION",
    translationKey: "pqdProductMalfunction",
    tags: [
      {
        tag: "ELECTRIC_PARTS_STOPPED_WORKING",
        translationKey: "pqdPMElectricPartsStoppedWorking",
      },
      {
        tag: "ELECTRIC_PARTS_NOT_WORKING",
        translationKey: "pqdPMElectricPartsNotWorking",
      },
      {
        tag: "LEAKAGE_AFTER_USAGE",
        translationKey: "pqdPMLeakageAfterUsage",
      },
      {
        tag: "MECHANICAL_PARTS_STOPPED_WORKING",
        translationKey: "pqdPMMechanicalPartsStopperWorking",
      },
      {
        tag: "MECHANICAL_PARTS_NOT_WORKING",
        translationKey: "pqdPMMechanicalPartsNotWorking",
      },
    ],
  },
  {
    type: "LABELS",
    translationKey: "pqdLabels",
    tags: [
      {
        tag: "WRONG_UNREADABLE_LABEL",
        translationKey: "pqdLWrongUnreadableLabel",
      },
      {
        tag: "LABEL_MISSING",
        translationKey: "pqdLLabelMissing",
      },
      {
        tag: "LABEL_PLACED_INCORRECTLY",
        translationKey: "pqdLLabelPlacedIncorrectly",
      },
      {
        tag: "LABEL_COMMES_OF_TOO_EASILY",
        translationKey: "pqdLLabelComesOffTooEasily",
      },
      {
        tag: "LABEL_TOO_HARD_TO_REMOVE",
        translationKey: "pqdLLabelToHardToRemove",
      },
    ],
  },
  {
    type: "PACKAGING",
    translationKey: "pqdPackaging",
    tags: [
      {
        tag: "PACKAGING_BREAKS",
        translationKey: "pqdPPackagingBreaks",
      },
      {
        tag: "PACKAGING_OPENS",
        translationKey: "pqdPPackagingOpens",
      },
      {
        tag: "PACKAGING_NOT_PROTECTING_PRODUCT",
        translationKey: "pqdPPackagingNotProtectingProduct",
      },
      {
        tag: "PACKAGING_HARD_TO_TRANSPORT",
        translationKey: "pqdPPackagingHardToTransport",
      },
      {
        tag: "PACKAGING_HARD_TO_HANDLE",
        translationKey: "pqdPPackagingHardToHandle",
      },
    ],
  },
  {
    type: "PRODUCT_INFORMATION",
    translationKey: "pqdProductInformation",
    tags: [
      {
        tag: "WRONG_PRODUCT_INFO",
        translationKey: "pqdPIWrongProductInfo",
      },
      {
        tag: "UNCLEAR_PRODUCT_INFO",
        translationKey: "pqdPIUnclearProductInfo",
      },
      {
        tag: "WRONG_PRODUCT_INFO_ONLINE",
        translationKey: "pqdPIWrongProductInfoOnline",
      },
      {
        tag: "WRONG_PRODUCT_INFO_IN_CATALOGUE",
        translationKey: "pqdPIWrongProductInfoInCatalogue",
      },
    ],
  },
  {
    type: "OTHER",
    translationKey: "otherString",
    tags: [
      {
        tag: "SOUND_NOICE",
        translationKey: "pqdOSoundNoice",
      },
      {
        tag: "SMELL_ODOUR",
        translationKey: "pqdOSmellOdour",
      },
      {
        tag: "WRONG_SIZE_SHAPE",
        translationKey: "pqdOWronSizeShape",
      },
      {
        tag: "PRODUCT_IS_UNSTABLE",
        translationKey: "pqdOProductIsUnstable",
      },
    ],
  },
];
