import {
  TestBuyProductWithCriteria,
  ArticleCompletionState,
  CompletedArticleState,
  Criterion,
} from "@coworker/types";

const isOk = (criterion: Criterion) => criterion.state === "OK";
const isAnswered = (criterion: Criterion) => criterion.state !== "NOT_ANSWERED";

export const allCriteriaAnswered = (criteria: Criterion[]) =>
  criteria.every(isAnswered);

export const anyCriteriaAnswered = (criteria: Criterion[]) =>
  criteria.some(isAnswered);

export const allCriteriaOk = (criteria: Criterion[]) => criteria.every(isOk);

const isFollowupTaskMissing = (article: TestBuyProductWithCriteria) =>
  !article.followUpTaskId &&
  allCriteriaAnswered(article.criteria) &&
  !allCriteriaOk(article.criteria);

export const getArticleCompletionStatus = (
  article: TestBuyProductWithCriteria
): ArticleCompletionState => {
  if (!anyCriteriaAnswered(article.criteria)) return "NotStarted";
  if (!allCriteriaAnswered(article.criteria)) return "Started";
  if (isFollowupTaskMissing(article)) return "FollowUpTaskMissing";
  return "Completed";
};

export const partitionArticlesByStatus = (
  articles: TestBuyProductWithCriteria[]
): Record<CompletedArticleState, TestBuyProductWithCriteria[]> => {
  const initialObj: Record<
    CompletedArticleState,
    TestBuyProductWithCriteria[]
  > = {
    SUCCESS: [],
    FAIL: [],
  };

  return articles.reduce((res, article) => {
    if (allCriteriaOk(article.criteria)) {
      res.SUCCESS.push(article);
    } else {
      res.FAIL.push(article);
    }
    return res;
  }, initialObj);
};
