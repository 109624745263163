import React from "react";
import styled, { css } from "styled-components";
import AvatarImage from "./AvatarImage";
import { ReactComponent as ArrowUp } from "@coworker/components/src/assets/svg/icon-arrow-small-up.svg";
import { ReactComponent as ArrowDown } from "@coworker/components/src/assets/svg/icon-arrow-small-down.svg";
import { ReactComponent as Circle } from "@coworker/components/src/assets/svg/circle.svg";
import { ReactComponent as TickedCircle } from "@coworker/components/src/assets/svg/radio-button-check.svg";
import { Highlighter } from "@coworker/components/src/components/Highlighter";
import {
  clean,
  makeMatcher,
} from "@coworker/components/src/helpers/string.helpers";
import { Trans, useTranslation } from "@coworker/locales";
import { WidgetDivider } from "@coworker/reusable/components/Widgets";
import { logEvent } from "../helpers/tracker";

const Container = styled.div`
  background: var(--white);
  width: 100%;
  ${({ overflow }) => overflow && `overflow-y: auto`}
  ${({ spacer }) => spacer && `min-height: calc(100% - 70px)`}
`;

const Row = styled.button`
  display: flex;
  background: var(--white);
  cursor: pointer;
  flex-direction: row;
  width: 100%;
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
      line-height: ${props.height};
    `}
  ${(props) =>
    props.bottomBorder &&
    css`
      border-bottom: 1px solid var(--grey150);
    `}
  ${(props) =>
    props.topBorder &&
    css`
      border-top: 1px solid var(--grey150);
    `}
`;

const GrowText = styled.div`
  flex-grow: 1;
  ${(props) => props.padLeft && `padding-left: ${props.padLeft};`}
  font-size: 14px;
  ${(props) => props.bold && "font-weight: bold"}
  overflow: hidden;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 15px;
`;

const SubLine = styled.div`
  margin-top: -70px;
  color: var(--grey718);
`;

const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  margin-top: 15px;
  margin-left: 18px;
`;
const Border = styled.div`
  background: var(--grey150);
  min-height: 10px;
`;
const Keep = styled.div`
  font-size: 14px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 29px;
  cursor: pointer;
`;

const FoldOutButton = styled.div`
  width: 50px;
  height: 50px;
  padding-top: 18px;
  padding-left: 3px;
`;

const SelectCircle = styled(Circle)`
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 18px;
  opacity: 0.22;
  color: var(--grey500);
`;
const TickedSelectedCircle = styled(TickedCircle)`
  width: 25px;
  height: 25px;
  vertical-align: middle;
  margin-right: 18px;
`;

const Spacer = styled.div`
  height: 70px;
  width: 100%;
`;

const TitleOnGray = styled.div`
  margin-left: 24px;
  font-size: 14px;
  color: var(--grey718);
  margin-top: 12px;
  min-height: 24px;
`;

function Arrow({ isUp, callback, testId }) {
  const Icon = isUp ? ArrowUp : ArrowDown;
  return (
    <Icon onClick={(e) => callback(e.target.value)} data-testid={testId} />
  );
}

function SelectButton({ selected, onClick, testId }) {
  return (
    <div onClick={onClick} data-testid={testId}>
      {selected ? <TickedSelectedCircle /> : <SelectCircle />}
    </div>
  );
}

function Avatar({ id, fixaUid, testId }) {
  return (
    <AvatarWrapper data-testid={testId}>
      <AvatarImage uid={id} fixaUid={fixaUid} />
    </AvatarWrapper>
  );
}

function TeamHeadline({
  title,
  subTitle,
  onClickName,
  right,
  left,
  topBorder,
  bottomBorder,
  testId,
  searchQuery = "",
}) {
  const matchAny = makeMatcher(searchQuery, false);
  return (
    <Row
      height={"90px"}
      bottomBorder={bottomBorder}
      topBorder={topBorder}
      data-testid={testId}
    >
      {left}
      <GrowText
        onClick={onClickName}
        padLeft={"29px"}
        bold
        data-testid={testId + "-name"}
      >
        <Highlighter regexp={matchAny} text={title} />
        {!!subTitle && <SubLine>{subTitle}</SubLine>}
      </GrowText>
      {right}
    </Row>
  );
}

function sortByName(
  { name: oneName = "" } = {},
  { name: anotherName = "" } = {}
) {
  return (
    oneName
      ?.toLocaleLowerCase()
      ?.localeCompare(anotherName?.toLocaleLowerCase()) || 0
  );
}

const getTestId = (testId, group, index) =>
  testId === "me" || testId === "group"
    ? testId
    : `${testId || "team-member"}${index}${group ? `-${group}` : ""}`;

function TeamMembers({
  members,
  me,
  checkIfSelected,
  handoff,
  doSubmit,
  group = "",
  searchQuery = "",
}) {
  return members.map((item, index) => {
    const userId = item.fixa_uid;
    const baseTestId = getTestId(item.test, group, index);
    const userBaseId = me.id === userId ? "me" : baseTestId;
    const matchAny = makeMatcher(searchQuery, false);
    const fixaUid = item.fixa_uid;
    return (
      ((!!handoff && userId !== me.id) || !handoff) && (
        <Row
          key={userId || item.gid || index}
          data-testid={userBaseId}
          onClick={() => {
            doSubmit({
              uid: userId,
              gid: item.team_id,
              name: item.name,
            });
            logEvent("ce:assign_to", { type: "coworker", coworker: userId });
          }}
          height={"69px"}
        >
          <Avatar
            id={fixaUid}
            fixaUid={fixaUid}
            testId={userBaseId + "-avatar"}
          />
          <GrowText
            padLeft={"23px"}
            height={"70px"}
            data-testid={userBaseId + "-name"}
          >
            <Highlighter regexp={matchAny} text={item.name} />
          </GrowText>
          <SelectButton selected={checkIfSelected(userId || item.gid)} />
        </Row>
      )
    );
  });
}

export function AssignToUserOrGroup({
  assignees,
  users,
  assignableTeams,
  me,
  onSubmit,
  onKeepTaskClick,
  handoff,
  flow,
  assignToMyTeamOpened,
  setAssignToMyTeamOpened,
  disableTeamAssignment = false,
  spacer = true,
  searchQuery = "",
  suggestions,
  isPqr = false,
  overflow = true,
}) {
  if (isPqr) {
    assignableTeams = assignableTeams.filter(
      (team) => team.is_product_quality === true
    );
  }

  const matchAll = makeMatcher(searchQuery, true);
  const matcher = (string) => !matchAll || matchAll.test(clean(string));
  const { uid, gid } = assignees || {};
  const checkIfSelected = (id) => (uid ? id === uid : gid && id === gid);

  const { t } = useTranslation();
  const myTeamName =
    assignableTeams?.find((team) => team.id === me.group)?.name ||
    t("myTeamString");

  function membersForTeam(users, gid, matcher) {
    const chosen = users.filter(
      (user) => user.team_id === gid && matcher(user.name)
    );
    chosen.sort(sortByName);
    return chosen;
  }

  const doSubmit = React.useCallback(
    (item) => {
      onSubmit({
        name: item.name,
        uid: item.uid || "",
        gid: item.gid || item.team_id,
      });
    },
    [onSubmit]
  );

  const Team = ({ i, group }) => {
    const members = membersForTeam(users, group.id, matcher);
    const [open, setOpen] = React.useState(
      () =>
        checkIfSelected(group.id) ||
        members.some((user) => checkIfSelected(user.fixa_uid)) ||
        (!!searchQuery && members.length > 0)
    );

    const chooseTeam = () => {
      if (disableTeamAssignment) return;
      doSubmit({
        name: group.name,
        gid: group.id,
        uid: "",
      });
      logEvent("ce:assign_to", { type: "team", team: group.id });
    };

    if (!members.length || !matcher(group.name)) {
      return null;
    }

    return (
      <>
        <TeamHeadline
          title={group.name}
          bottomBorder={i + 1 === assignableTeams.length && !open}
          topBorder
          id={group.id}
          left={
            <FoldOutButton>
              <Arrow
                callback={() => setOpen(!open)}
                isUp={open}
                testId={`${group.name}-expand`}
              />
            </FoldOutButton>
          }
          onClickName={chooseTeam}
          right={
            !disableTeamAssignment && (
              <SelectButton
                onClick={chooseTeam}
                selected={checkIfSelected(group.id)}
                testId={`group-select-${group.id}`}
              />
            )
          }
          testId={"group-" + i}
          searchQuery={searchQuery}
        />
        {open && (
          <TeamMembers
            members={members}
            me={me}
            handoff={handoff}
            checkIfSelected={checkIfSelected}
            doSubmit={doSubmit}
            group={i}
            searchQuery={searchQuery}
          />
        )}
      </>
    );
  };

  const renderedTeams = React.useMemo(() => {
    const sortedGroups = assignableTeams
      .filter((team) => team.id !== me.group)
      .sort(sortByName);
    return sortedGroups.map((group, i) => (
      <Team key={`team-${group.id}`} group={group} i={i} />
    ));

    // searchQuery is a hidden dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignableTeams, searchQuery]);
  const myTeamFoldout = (
    <FoldOutButton>
      <Arrow
        callback={() => setAssignToMyTeamOpened(!assignToMyTeamOpened)}
        isUp={assignToMyTeamOpened}
        testId={"myTeam" + (assignToMyTeamOpened ? "Close" : "Open")}
      />
    </FoldOutButton>
  );
  const chooseMyTeam = () => {
    if (disableTeamAssignment) return;
    doSubmit({
      name: t("teamString"),
      gid: me.group,
      uid: "",
    });
    logEvent("ce:assign_to", { type: "team", team: "mine" });
  };
  const myTeamMembers = membersForTeam(users, me.group, matcher);

  const myTeamOpened =
    assignToMyTeamOpened || (!!searchQuery && myTeamMembers.length > 0);

  return (
    <Container
      handoff={handoff}
      data-testid="assignBackground"
      spacer={spacer}
      overflow={overflow}
    >
      {!handoff && !flow && spacer && <Spacer />}
      {handoff && (
        <>
          <Border />
          <Keep
            data-testid="keepSection"
            onClick={() => {
              if (typeof onKeepTaskClick === "function") {
                onKeepTaskClick();
              }
              doSubmit({
                name: t("meString"),
                uid: me.id,
                gid: me.group,
              });
              logEvent("ce:assign_to", { type: "coworker", coworker: "me" });
            }}
          >
            <p>{<Trans>keepString</Trans>}</p>
            <SelectButton
              selected={checkIfSelected(me.id)}
              data-testid="keepButton"
            />
          </Keep>
          <Border />
        </>
      )}

      <AssignmentSuggestions
        suggestions={suggestions}
        doSubmit={doSubmit}
        checkIfSelected={checkIfSelected}
      />

      {(myTeamMembers.length > 0 || matcher(myTeamName)) && (
        <>
          <TeamHeadline
            title={myTeamName}
            subTitle={<Trans>myCurrentTeamString</Trans>}
            id={me.group}
            left={myTeamFoldout}
            onClickName={chooseMyTeam}
            right={
              !disableTeamAssignment && (
                <SelectButton
                  onClick={chooseMyTeam}
                  selected={checkIfSelected(me.group)}
                  testId={"my-team-select"}
                />
              )
            }
            key={me.group}
            testId="group"
            topBorder
            bottomBorder
            searchQuery={searchQuery}
          />
        </>
      )}
      {myTeamOpened && (
        <TeamMembers
          members={myTeamMembers}
          me={me}
          handoff={handoff}
          checkIfSelected={checkIfSelected}
          doSubmit={doSubmit}
          searchQuery={searchQuery}
        />
      )}

      {renderedTeams}
    </Container>
  );
}

const SuggestionText = styled(GrowText)`
  line-height: 20px;
  margin-top: 12px;
`;

const MostRecently = styled.span`
  color: var(--grey718);
`;

function AssignmentSuggestions({ suggestions, doSubmit, checkIfSelected }) {
  if (!suggestions?.map) return null;
  const rows = suggestions.slice(0, 3).map(([gid, [text, subtext]]) => (
    <Row
      height="69px"
      key={gid}
      onClick={() => {
        doSubmit({ gid });
        logEvent("ce:assign_to", { type: "suggested", team: gid });
      }}
    >
      <SuggestionText padLeft="23px" height="70px">
        {text}
        <br />
        <MostRecently>
          <Trans>mostRecentlyString</Trans> {subtext}
        </MostRecently>
      </SuggestionText>
      <SelectButton selected={checkIfSelected(gid)} />
    </Row>
  ));

  if (!rows.length) return null;

  return (
    <>
      <TitleOnGray style={{ marginTop: "0px" }}>
        <Trans>assignedRecentlyString</Trans>
      </TitleOnGray>
      {rows}
      <WidgetDivider />
    </>
  );
}
