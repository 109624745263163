import React from "react";
import BlueLink, { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <p>Privacyverklaring</p>,
  aboutFixaText: (
    <div>
      Fixa is een taakbeheer- en merchandisingtool voor winkelmedewerkers. Het
      doel van de Fixa-toepassingen is de activiteiten die nu handmatig, op
      papier of in Excel gebeuren te vervangen en het taakbeheer in de winkel te
      stroomlijnen (aanvullen van artikels, opruimingen, afwijkingen,
      testaankopen, veelgestelde vragen, enz.). Met Fixa zijn ook analyses
      mogelijk door inzichten uit taken die afgewerkt zijn.
      <br />
      <br />
      Wanneer je Fixa gebruikt, verzamelen IKEA Belgium en de Ingka Group
      bepaalde persoonsgegevens van jou (zoals hieronder gedefinieerd).
    </div>
  ),
  personalDataCollectedTitle: (
    <p>De volgende persoonsgegevens worden verzameld:</p>
  ),
  personalDataCollectedText: (
    <div>
      Wanneer je Fixa gebruikt, verzamelt het systeem informatie over jou uit de
      Co-Worker Database (CDS), zoals je voornaam, tussenna(a)m(en),
      familienaam, e-mail, functietitel, functie, telefoonnummer (indien
      handmatig verstrekt - voer geen persoonlijk telefoonnummer in), foto uit
      CDS, taalvoorkeuren in app, winkel-ID, land-ID, versie van het apparaat en
      besturingssysteem, IP-adres verwerkt in Google Cloud-platform en Sentry
      (Sentry is software voor het traceren van applicatiefouten).
      <br />
      <br />
      Het profiel van de medewerker wordt handmatig door de
      manager/supergebruiker aangemaakt via Fixa-admin (een admin webapp).
      Persoonsgegevens worden opgehaald uit CDS, dus profielen kunnen alleen
      worden aangemaakt voor medewerkers die een actief CDS-profiel hebben.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <p>Het doel voor het verzamelen van de gegevens is:</p>
  ),
  purposeOfDataCollectionText: (
    <p>
      Het doel voor het verzamelen van deze gegevens in Fixa is om de applicatie
      goed te laten werken, op een manier die eenvoudige interne communicatie
      tussen medewerkers en administratieve taken tussen medewerkers in de
      winkel mogelijk maakt. Door taken aan een gebruiker te koppelen, kunnen we
      meldingen voor het bijwerken van taken ondersteunen voor toegewezen
      gebruikers en makers.
    </p>
  ),
  legalGroundsTitle: <p>De rechtsgrond voor de verwerking is:</p>,
  legalGroundsText: (
    <p>De rechtsgrond voor de verwerking is legitiem belang.</p>
  ),
  dataWillBeStoredTitle: <p>De gegevens worden bewaard:</p>,
  dataWillBeStoredText: (
    <div>
      Gedurende 70 dagen bewaren we voltooide taken met verwijzing naar de
      gebruikers. Daarna worden ze geanonimiseerd. De persoonsgegevens van een
      medewerker worden in Fixa opgeslagen voor de duur van de
      arbeidsovereenkomst.
      <br />
      <br />
      Beëindiging van de arbeidsovereenkomst met de Ingka Group: Alle
      persoonsgegevens worden automatisch verwijderd binnen één maand na
      verwijdering van het CDS-profiel.
      <br />
      <br />
      Verandering van functie binnen de Ingka Group: Als een medewerker Fixa
      niet langer hoeft te gebruiken, worden alle persoonsgegevens automatisch
      verwijderd binnen drie maanden inactiviteit.
      <br />
      <br />
      Persoonsgegevens kunnen ook op elk moment worden verwijderd via een
      verzoek aan de lokale Information Security and Data Privacy Leader
      (manager voor informatiebeveiliging en gegevensprivacy) of de Store
      Manager.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <p>De verwerkingsverantwoordelijke voor deze persoonsgegevens is:</p>
  ),
  dataControllerForPersonalDataText: (
    <p>IKEA Belgium NV/SA, Weiveldlaan 19, 1930 Zaventem.</p>
  ),
  rightToPersonalInformationText: (
    <div>
      <br />
      Met betrekking tot je persoonsgegevens heb je bepaalde rechten. Raadpleeg{" "}
      <BlueLink href="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx">
        https://inside.ingka.com/&#8203;supportservices/&#8203;Dataprivacy/&#8203;Pages/&#8203;Individual-rights.aspx
      </BlueLink>{" "}
      voor meer informatie over deze rechten.
      <br />
      <br />
      Contactinformatie voor vragen over gegevensprivacy en -rechten:
      <EmailLink email="privacy.belgium@ikea.com " />
    </div>
  ),
};
