import productIssueForm from "./product_issue";
import addonEditForm from "./addon_edit";
import addonChangesForm from "./addon_changes";
import addonCreateForm from "./addon_single";
import customForm from "./custom";
import plannedForm from "./planned";
import productQualityForm from "./product_quality";
import mfaqFollowUpForm from "./mfaq_followup";
import roomsettingsForm from "./roomsettings_edit";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  product: productIssueForm,
  addon_edit: addonEditForm,
  addon_changes: addonChangesForm,
  addon_create: addonCreateForm,
  custom: customForm,
  planned: plannedForm,
  product_quality: productQualityForm,
  mfaq_followup: mfaqFollowUpForm,
  roomsettings_edit: roomsettingsForm,
};
