import styled from "styled-components";
import IngkaButton from "@ingka/button";

const DoNotGrow = styled.div`
  padding: 17px 24px;
  text-align: center;
  position: flex;
  top: 56px;
  background-color: white;
  z-index: 1;
`;

export function FullWidthButton(props) {
  return <IngkaButton type="primary" {...props} />;
}

export function NarrowButton(props) {
  return (
    <DoNotGrow>
      <IngkaButton type="primary" {...props} />
    </DoNotGrow>
  );
}
