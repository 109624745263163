import React from "react";
import ListView from "@ingka/list-view";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/aspect-ratio-box/dist/style.css";
import "@ingka/image/dist/style.css";
import "@ingka/switch/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/list-view/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/status/dist/style.css";
import { Team } from "../../../../../shared/types/team";
import { useActiveUsers } from "@coworker/app/src/hooks/useActiveUsers";
import { TeamsListViewItem } from "../TeamsListViewItem/TeamsListViewItem";
import styles from "./teams-list-view.module.css";

interface TeamsListViewProps {
  activeTeams: Team[];
  handleTeamSelection: (team: Team) => void;
  selectedTeamId: string;
}

export const TeamsListView: React.FC<TeamsListViewProps> = ({
  activeTeams,
  handleTeamSelection,
  selectedTeamId,
}) => {
  const activeStoreUsers = useActiveUsers();

  return (
    <ListView
      id={"teams-list-view"}
      className={styles["teams-list-view"]}
      size="medium"
    >
      {activeTeams.map((team: Team, index: number) => {
        const teamMembers = activeStoreUsers?.filter(
          (user: any) => user.team_id === team.id
        );

        return (
          <TeamsListViewItem
            team={team}
            index={index}
            teamMembers={teamMembers}
            handleTeamSelection={handleTeamSelection}
            selectedTeamId={selectedTeamId}
          ></TeamsListViewItem>
        );
      })}
    </ListView>
  );
};
