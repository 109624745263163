import React from "react";
import { useAuth } from "@coworker/app/src/core/auth/useAuth";
import Main from "./components/Main";
import { MainUnauthenticated } from "@coworker/apprestructured/src/MainUnauthenticated";

export function App() {
  const { isAuthenticated, authError, isAuthInProgress } = useAuth();
  return isAuthenticated ? (
    <Main />
  ) : (
    <MainUnauthenticated
      authInProgress={isAuthInProgress}
      authError={authError}
    ></MainUnauthenticated>
  );
}
