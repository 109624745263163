import React from "react";
import { useTranslation } from "@coworker/locales";
import styled from "styled-components";

const TitleText = styled.div`
  min-height: 22px;
  display: flex;
  align-items: center;
`;

const TitleUnit = styled.div`
  text-align: right;
  min-height: 22px;
  display: flex;
  align-items: center;
`;

const TitleOuterContainer = styled.div`
  background: var(--white);
  border-bottom: 1px solid var(--grey200);
`;

const TitleInnerContainer = styled.div`
  margin: 8px 24px;
  min-height: 23px;
  background: var(--white);
  font-family: "Noto IKEA Latin";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--grey718);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SpaceContainer = styled.div`
  height: 8px;
  background: var(--grey100);
`;

interface LocationGroupTitleProps {
  groupTitle: string;
  showUnit: boolean;
}

export function LocationGroupTitle({
  groupTitle,
  showUnit,
}: LocationGroupTitleProps) {
  const { t } = useTranslation();
  return (
    <div>
      <SpaceContainer />
      <TitleOuterContainer>
        <TitleInnerContainer>
          <TitleText>{t(groupTitle)}</TitleText>
          {showUnit && <TitleUnit>{t("maxCapacityString")}</TitleUnit>}
        </TitleInnerContainer>
      </TitleOuterContainer>
    </div>
  );
}
