import { createNote } from "../../../services/note.service";
import { Reasons } from "../constants";
import { roomSettingsCreateTaskFactory } from "../ObjectFactories/TaskFactory";
import { Article } from "../types/article";

export const createAutoGeneratedTaskAsCompleted = async (
  userId: string,
  reason: Reasons,
  roomId: string,
  roomName: string,
  storeId: string,
  article: Article,
  new_product_article_id: string,
  noteText: string,
  createTask: (task: any) => Promise<string> // NOSONAR
) => {
  const dummyTask = roomSettingsCreateTaskFactory(
    userId,
    roomId,
    article.id,
    article.itemName,
    article.supplierNumber,
    article.dateStamp,
    article.nbrArticles,
    false,
    false,
    false,
    false,
    roomName,
    reason,
    article.productArticleId,
    storeId,
    "",
    article.itemType,
    article.itemColor,
    " ",
    article.imageSmall,
    false
  );

  if (reason === Reasons.REPLACE_PRODUCT) {
    dummyTask.new_product_article_id = new_product_article_id;
  }

  dummyTask.state = "COMPLETED";

  const taskId = await createTask(dummyTask);

  await createNote(
    taskId,
    {
      text: noteText,
      creator_id: userId,
    },
    [],
    storeId
  );
};
