import React from "react";
import styled from "styled-components";
import SSRIcon from "@ingka/ssr-icon";
import noticeRedSmall from "@ingka/ssr-icon/paths/notice-red-small";

const WarningTextWithIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: #e00751;
  gap: 5px;
  font-size: 14px;
`;

interface WarningTextWithIconProps {
  text: string;
}

export const WarningTextWithIcon = ({ text }: WarningTextWithIconProps) => {
  return (
    <WarningTextWithIconContainer>
      <SSRIcon paths={noticeRedSmall} />
      <p>{text}</p>
    </WarningTextWithIconContainer>
  );
};
