import React from "react";
import Pill from "@ingka/pill";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/pill/dist/style.css";
import "@ingka/focus/dist/style.css";

export function SkapaPill(props) {
  return <Pill size="xsmall" {...props} />;
}
