import React from "react";
import Text from "@ingka/text";
import SkapaButton from "@ingka/button";
import ArrowRight from "@ingka/ssr-icon/paths/arrow-right-from-base";
import {
  useGivenName,
  useSurname,
  useRoleId,
  useFixaUID,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { capitalizeFirst } from "@coworker/app/src/hooks/useFormatter";
// @ts-ignore
import { displayRoleTranslationString } from "@coworker/common/helpers/user.helper";
import styles from "./profile-header.module.css";
import { Trans } from "@coworker/locales";
import { Avatar } from "../../../../shared/components/Avatar/Avatar";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";

export const ProfileHeader: React.FC = () => {
  const { push } = useWorkspacesAction();
  const firstName = useGivenName();
  const lastName = useSurname();
  const roleId = useRoleId();
  const fixaUid = useFixaUID();
  const fullName = [firstName, lastName].map(capitalizeFirst).join(" ");
  React.useState(false);
  const onLogout = async () => {
    // resetTaskListFilters();
    push("/logout");
  };
  return (
    <div className={styles["profile-header"]}>
      <div>
        <Avatar isBigImage={true} fixaUid={fixaUid}></Avatar>
      </div>
      <div className={styles["profile-header-info"]}>
        <Text tagName="h2" headingSize="m">
          {fullName}
        </Text>
        <Text
          tagName="h3"
          headingSize="xs"
          className={styles["text-gap"] as string}
        >
          <Trans>{displayRoleTranslationString(roleId)}</Trans>
        </Text>
        <SkapaButton
          type="secondary"
          key="logOutButton"
          text={<Trans>logoutString</Trans>}
          small={true}
          fluid={true}
          onClick={onLogout}
          ssrIcon={ArrowRight}
          iconPosition="trailing"
        ></SkapaButton>
      </div>
    </div>
  );
};
