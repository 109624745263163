import { parseProduct } from "./products.service";

// When range is updated, copy & paste from excel sheet directly here below
const sfmArtNumbers = `
00124784
00143099
00150909
00150928
00151273
00154200
00218419
00218787
00229031
00269932
00271058
00286267
00288890
00288900
00293927
00295158
00306816
00308626
00346407
00384729
00412690
00421208
00424612
00426277
00426649
00473184
00487794
00869300
01010320
01189210
01189420
01220037
01220079
02889000
10101032
10122421
10124694
10154252
10158622
10243277
10259621
10291758
10296015
10308626
10324240
10340546
10347623
10347859
10374923
10385568
10392818
10394577
10408782
10412312
10412699
10424602
10427177
10433866
10436271
10437690
10472928
10518399
11894200
20028890
20118942
20226767
20271203
20293926
20294247
20308094
20347627
20359724
20361519
20374908
20374927
20384728
20394440
20405542
20412689
20421207
20426648
20431758
20432362
20440673
20457791
30047001
30101031
30148514
30201648
30226314
30228817
30236266
30237176
30243276
30264400
30295920
30301964
30311883
30324239
30340545
30347858
30351280
30374291
30374922
30377807
30384742
30405532
30414268
30434030
30437689
31015880
31035011
31051121
31051180
31051185
31096064
31107034
31117013
31159408
31293000
32035000
33086913
40028889
40075970
40129020
40201676
40212482
40226766
40250148
40269930
40284389
40293925
40296014
40297891
40338702
40346434
40353387
40361212
40363862
40374295
40374926
40386972
40405541
40421206
40425959
40427128
40436892
40440672
40449272
40472074
40487787
41645303
43700505
50074630
50122419
50228816
50236265
50245731
50271051
50296004
50359770
50374290
50377156
50384703
50385571
50400915
50405531
50427750
50437688
50444330
50447654
60118921
60122555
60154424
60196495
60198495
60225695
60245740
60247466
60255381
60260311
60265851
60311886
60324577
60340544
60359722
60360037
60360042
60361211
60361517
60363861
60374893
60377820
60392854
60405540
60415544
60415624
60424647
60436891
60437616
60438994
60449327
60466543
60479277
61027701
62220028
62220078
62221025
62221033
62221098
62221099
62230014
62230026
62230032
62240078
70150920
70154254
70218779
70261824
70288102
70291760
70308628
70324242
70338376
70354309
70360334
70374289
70374294
70384698
70389950
70401320
70409825
70412757
70415548
70422544
70422761
70424736
70427122
70427749
70431483
70437381
71044998
73324582
80028571
80150627
80150910
80218788
80232925
80295507
80306817
80340543
80346408
80360022
80360041
80361210
80380591
80385555
80393188
80412691
80415543
80424646
80425981
80427782
80438993
80466542
80473137
90101033
90168325
90261823
90273557
90294244
90295192
90296016
90306987
90308627
90324241
90340547
90351003
90359693
90360583
90374900
90374919
90389671
90415608
90422760
90452078
90625970
91625970
99650130
`;

const manualExtraSfmArtNumbers = `
00201678
20483592
30310223
33058600
50149777
50310222
80201679
00331217
00331284
00331533
00334428
00343927
00346285
00348642
00390221
00429817
00486186
10331274
10338973
10339010
10339086
10339091
10342376
10348038
10356354
10359526
10453958
10469087
20149774
20331179
20348636
20358593
20361524
20361557
20415409
20437977
20453081
20460883
20464249
20465244
30331560
30339014
30339066
30339071
30339231
30346627
30348631
30363363
30363589
30415404
40331041
40331201
40334412
40338995
40348659
40360547
40433308
40437976
50339065
50347174
50359534
50364125
50448069
50469090
60331257
60331304
60339003
60339017
60339041
60347197
60347649
60358591
60363371
60437975
60480558
70331308
70331501
70338475
70339007
70359528
70443283
70447686
70469089
70480553
70487795
80331218
80331303
80331572
80339021
80486187
90331152
90331213
90339011
90339068
90339087
90347563
90437974
90480547
90447685
90486455
90485521
20485826
40485514
90480514
40480521
20480560
70485409
00494431
20497438
80494427
80494432
20497443
40497526
40497437
00494426
30479938
60495140
50479937
00497509
00514919
20514918
40514917
60514916
70520097
50506177
40506192
60506172
10524692
90524688
30524691
70524694
70526283
90526282
50534236
90534239
10534238
80534051
30534812
20534054
40486349
70487856
20514918
90499207
41645307
62230007
40497437
00524211
70541354
00524391
70546375
`;

let sfmArtNumbersSet;

/**
 * Extracts a specific segment from an EAN-13 or EAN-14 barcode string, or processes the string using a custom parse function for other cases.
 *
 * This function checks the length of the input string to determine if it conforms to the EAN-13 or EAN-14 format, including or excluding a checksum digit.
 * For EAN-14 barcodes, it skips the first two characters and returns the next 8 characters. For EAN-13 barcodes, it skips the first character and returns the next 8 characters.
 * If the input string does not conform to these formats, it falls back to a custom parsing function `parseProduct`, returning the first element of its output array.
 *
 * @param {string} string - The input string to be processed, expected to be an EAN-13 or EAN-14 barcode or other string format.
 * @returns {string} The extracted 8-character segment from an EAN barcode, or the first element from the `parseProduct` function's output array for other cases.
 */
export function extractFromEAN(string) {
  // Check for EAN-14 format
  const isEAN14 = string?.length === 14;
  const isEAN12or13 = [12, 13].includes(string?.length);
  const fallback = parseProduct(string)[0];

  if (isEAN14) {
    return string.substring(2, 10);
  }

  // Check for EAN-13 or EAN-12 format
  if (isEAN12or13) {
    return string.substring(1, 9);
  }

  // Fallback for other cases using parseProduct
  return fallback;
}

/***
 * This function is used know when to prefill Pickup location = sfmHomeLocation (see below)
 * @param {String} string tha can be EAN-13, full_id, or short_id
 * @returns {String} Empty string if not detected as SFM product.
 */
export function sfmArticleNumber(string) {
  if (!sfmArtNumbersSet) {
    sfmArtNumbersSet = new Set(
      (manualExtraSfmArtNumbers + "\n" + sfmArtNumbers).split(/\s+/g)
    );
    sfmArtNumbersSet.delete(""); // The split above trims lines. Then just remove "" and this Set is good to go.
  }
  const code = extractFromEAN(string);
  return (sfmArtNumbersSet.has(code) && code) || "";
}

export const sfmHomeLocation = "Swedish Food Market";
