import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { RoundCard } from "../Rounds/RoundCard";
import { SubHeaderCard } from "../Components/SubHeaderCard";
import IngkaButton from "@ingka/button";
import styled from "styled-components";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useParams } from "react-router-dom";
import { DIVISION_HASH, HEADER_COLOR_SCHEMA } from "../constants";
import { ShowroomQuantitySteppers } from "./ShowroomQuantitySteppers";
import { useFetchDivisionsCountByRound } from "../Hooks/useFetchDivisionsCountByRound";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import SSRIcon from "@ingka/ssr-icon";
import SettingsIcon from "@ingka/ssr-icon/paths/gear";

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ShowroomOTW = () => {
  const [currentTotalValue, setCurrentTotalValue] = React.useState<number>(0);

  const { id } = useParams();
  const { push } = useWorkspacesAction();
  const { t } = useTranslation();

  const { data: startValue, isFetching } = useFetchDivisionsCountByRound(
    DIVISION_HASH.SHOWROOM?.division ?? "",
    id ?? ""
  );

  React.useEffect(() => {
    setCurrentTotalValue(startValue);
  }, [startValue]);

  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <SSRIcon paths={SettingsIcon} />,
      onClick: () => push("/shoppingtools/settings"),
      position: "right",
    },
  ];

  return (
    <FullScreenPopup
      appBarConfig={{
        title: (
          <>
            <Trans>showroomOTWString</Trans> - <Trans>{`${id}String`}</Trans>
          </>
        ),
        actions: appBarActions,
        onBack: () => push("/shoppingtools/progress"),
      }}
      noPadding
    >
      <SubHeaderCard
        title={<Trans>customerTookToolString</Trans>}
        fontSize={16}
        colorSchema={HEADER_COLOR_SCHEMA.GREY}
      />
      <ShowroomQuantitySteppers
        round={id ?? ""}
        currentTotalValue={currentTotalValue}
        setCurrentTotalValue={setCurrentTotalValue}
      />
      <RoundCard
        title={DIVISION_HASH.SHOWROOM?.transKey ?? ""}
        currentValue={currentTotalValue}
        showNav={false}
        background="secondary"
        whiteBarBackground
        loading={isFetching && !startValue}
      />
      <ButtonWrapper>
        <IngkaButton
          aria-label={t("goToMarkethallAriaString")}
          type="primary"
          disabled={currentTotalValue < 50}
          style={{ margin: "15px 0px", width: "80%" }}
          text={<Trans>goToMarkethallString</Trans>}
          onClick={() => push(`/shoppingtools/progress/markethall/${id}`)}
        />
      </ButtonWrapper>
    </FullScreenPopup>
  );
};
