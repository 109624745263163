import React from "react";
import BlueLink, { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Politique de confidentialité</div>,
  aboutFixaText: (
    <div>
      Fixa est un outil de gestion des tâches et de merchandising pour les
      collaborateurs en magasin. Le but de l’application Fixa est de remplacer
      les activités manuelles, papiers ou excel, et rationaliser la gestion des
      tâches en magasin (remplissage des articles, nettoyages, déviation, achat
      test, questions fréquemment posées etc.). Fixa permet également de faire
      des analyses grâce aux informations obtenues à partir des tâches
      effectuées.
      <br />
      <br />
      Lorsque vous utilisez Fixa, le responsable du traitement (tel que défini
      ci-dessous) recueillera certaines données personnelles vous concernant.
    </div>
  ),
  personalDataCollectedTitle: (
    <div>Les données personnelles collectées sont:</div>
  ),
  personalDataCollectedText: (
    <div>
      Lorsque vous utilisez Fixa, le système collecte des informations vous
      concernant à partir de la base de données des collaborateurs (CDS) telles
      que votre prénom, deuxième(s) prénom(s), nom de famille, email, intitulé
      du poste, rôle, numéro de téléphone (s’il a été fourni manuellement - le
      numéro de téléphone personnel ne doit pas être saisi), photographie issue
      de CDS, langue de préférence dans l'application, l’identifiant du magasin,
      l’identifiant du pays, version de l’appareil et système opérateur,
      l’adresse IP traitée dans la plateforme Google Cloud et Sentry (Sentry est
      le logiciel de suivi des erreurs de l’application).
      <br />
      <br />
      Le profil « du collaborateur est créé manuellement via Fixa-admin (une web
      application d'administration) par le gestionnaire/administrateur. Les
      données personnelles sont extraites de CDS, de sorte que les profils ne
      peuvent être créés que pour les collaborateurs qui ont un profil CDS
      actif.
    </div>
  ),
  purposeOfDataCollectionTitle: <div>Finalité du traitement:</div>,
  purposeOfDataCollectionText: (
    <div>
      La finalité de la collecte des données personnelles est le bon
      fonctionnement de l’application, faciliter la communication interne entre
      les collaborateurs IKEA, l’attribution des tâches aux collaborateurs du
      magasin. Le fait que les tâches soit attribuées à un utilisateur nous
      permet la mise en place de notification de la création et de l’attribution
      des tâches.
    </div>
  ),
  legalGroundsTitle: <div>Base légale du traitement:</div>,
  legalGroundsText: (
    <div>le fondement juridique du traitement est l’intérêt légitime</div>
  ),
  dataWillBeStoredTitle: (
    <div>Durée de conservation des données personnelles:</div>
  ),
  dataWillBeStoredText: (
    <div>
      Nous stockons les tâches terminées avec des références aux utilisateurs
      pendant 70 jours, après quoi elles sont anonymisées. Les données
      personnelles d'un collaborateur sont conservées dans Fixa pendant toute la
      durée du contrat de travail.
      <br />
      <br />
      Au terme du contrat de travail avec Meubles IKEA France SAS, toutes les
      données personnelles du collaborateur sont automatiquement supprimées de
      la solution dans un délai d'un mois après la suppression du profil CDS.
      <br />
      <br />
      En cas de changement de rôle au sein du Groupe Ingka : Si un collaborateur
      n'a plus besoin d’utiliser Fixa ses données personnelles seront
      automatiquement supprimées après 3 mois d'inactivité.
      <br />
      <br />
      Les données personnelles peuvent également être mises à la disposition du
      collaborateur, rectifiées ou supprimées à sa demande auprès du service
      local de la sécurité de l'information et de la confidentialité des données
      ou du directeur de magasin
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>Le responsable du traitement des données personnelles est:</div>
  ),
  dataControllerForPersonalDataText: <div>Meubles IKEA France SAS</div>,
  rightToPersonalInformationText: (
    <div>
      <h4>Politique relative aux Cookies:</h4>
      <div>
        L’application Fixa utilise des cookies pour son Fonctionnement. Un
        "cookie" est un témoin de connexion qui est déposé sur disque dur d’un
        appareil et lu par le serveur du site ou de l’application web visité. Il
        permet le fonctionnement de la solution mais également de réaliser des
        statisques ou des analyses sur l’usage de l’application. Il contient
        généralement le nom du serveur qui l’a déposé, un identifiant sous forme
        de numéro unique et une date d’expiration. Ci-après les cookie utilisés
        ;
        <br /> _cs_c -&gt; ContentSquare (utilisé par ContentSquare pour
        enregistrer le consentement de l’utilisateur à être suivi)
        <br />
        _cs_s -&gt; ContentSquare(contient le nombre de page vues au cours de la
        session pour ContentSquare)
        <br />
        _cs_id -&gt; ContentSquare (Contient l’identifiant d’utilisateur
        ContentSquare)
        <br />
        _ga_S8Q7V4RB8J -&gt; GA (Pour stocker et compter les pages vues-{" "}
        <BlueLink href="https://cookiedatabase.org/cookie/google-analytics/_ga_/">
          https://cookiedatabase.org/cookie/google-analytics/_ga_/
        </BlueLink>
        )
        <br />
        _ga -&gt; GA (pour stocker et compter les pages vues-{" "}
        <BlueLink href="https://cookiedatabase.org/cookie/google-analytics/_ga_/">
          https://cookiedatabase.org/cookie/google-analytics/_ga/
        </BlueLink>
        )
        <br />
        ak_bmsc -&gt; Akamai (Ce cookie est utilisé parAkamai pour optimiser la
        sécurité de l’applicationen distinguant les humains des robots{" "}
        <BlueLink href="https://cookiedatabase.org/cookie/akamai/ak_bmsc/">
          https://cookiedatabase.org/cookie/akamai/ak_bmsc/
        </BlueLink>
        )
        <br />
        bm_sv -&gt; Akamai (Ce cookie est nécessaire pour la fonction cache de
        Akamai. Un cache est utilisé par un site web pour optimiser letemps de
        réponse entre le visiteur et le site.)
        <br />
        akacd_Fixa-PROD -&gt; Akamai RT -&gt; Akamai (Lepluginroundtrip (RT)
        mesure le temps de chargement de la page ou tout autre tempsassocié à la
        page)
      </div>
      <h4>Politique relative aux Cookies:</h4>
      <div>
        Les collaborateurs dont les données personnelles sont traitées par
        l’application Fixa disposent de certains droits spécifiques, pour
        exercer ces droits, le collaborateur peut se rapprocher directement de
        l’équipe de protection de données personnelles via l’adresse
        <EmailLink email="cil.ikeafrance@ikea.com" />. Ci-après les droits
        spécifiques des collaborateurs relatifs au traitement de leur données
        personnelles ;
        <br />
        <br />
        <b>- Le droit d’accès</b> qui permet de demander à IKEA d’obtenir une
        copie des données personnelles traitées pendant la durée du contrat de
        travail en adressant une demande comme indiqué ci-dessus et en précisant
        la liste des données auxquelles le collaborateur souhaite avoir accès.
        <br />
        <br />
        <b>- Le droit de rectification</b> permet de demander à IKEA la
        modification de vos informations inexactes sur la base de données de
        l’application Fixa. A cet effet, les équipent en charge de l’application
        s’engagent à rectifier, supprimer ou compléter les données personnelles
        qui sont erronées, inutiles, incomplètes ou obsolètes au regard de la
        finalité du traitement, soit de sa propre initiative si elle dispose de
        l’information, soit à votre demande.
        <br />
        <br />
        <b>- Le droit à l’effacement</b> permet de demander à IKEA d’effacer vos
        informations contenues dans la base de données de Fixa, sous réserve du
        respect d’obligations légales contraires.
        <br />
        <br />
        <b>- Le droit de limitation du traitement</b> permet à un collaborateur
        de demander la suspension d’un traitement le concernant le temps d’une
        vérification.
        <br />
        <br />
        <b>- Le droit d’introduire une réclamation auprès de la CNIL</b> grâce
        auquel le collaborateur peut introduire une réclamation s’il considère
        que le traitement de données à caractère personnel le concernant
        constitue une violation à la règlementation. Pour avoir des informations
        supplémentaires sur vos droits, dirigezvous sur le site web de la
        Commission Nationale de l’Informatique et des Libertés.
      </div>
    </div>
  ),
};
