import React from "react";
import { isFinishedState } from "../../../constants/taskState";
import { useTranslation } from "@coworker/locales";
import useFormatter from "../../../hooks/useFormatter";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import {
  getAmountByProductUnit,
  getAmounts,
  getPiecesInformation,
} from "../../../helpers/taskHelper";

function formattedAmountTexts({ task, productUnit, t }) {
  const withPiecesExplanation = (values) => {
    const [actual, amount, type, what] = values;
    const value = actual ?? getAmountByProductUnit(amount, type, task);

    const start = `${value} ${productUnit(type, value)}`;
    const explanation = getPiecesInformation(type, amount, t);
    return `${start}${what ? " " + what : ""} ${explanation}`;
  };

  const { refilled, picked, requested } = getAmounts(task);
  const all = [
    refilled && refilled.concat(t("refilledLowercaseString")),
    picked && picked.concat(t("pickedLowercaseString")),
    requested &&
      requested.concat(
        refilled || picked ? [t("requestedLowercaseString")] : []
      ),
  ].filter((x) => x);

  const [firstValues, ...additionalValues] = all.map(withPiecesExplanation);
  const first = firstValues;
  const additional = additionalValues.join("\n");
  return { first, additional };
}

/**
 * Returns title of the widget.
 * @param {Object} task task object
 * @returns {string} Refilled, Requested or Picked title.
 */
export function getInformationTitle({ task, t }) {
  const completedOrClosed = isFinishedState(task.state);
  if (completedOrClosed) {
    return t("refilledString");
  } else if (!completedOrClosed && task.picked) {
    return t("pickedString");
  } else {
    return t("requestedString");
  }
}

/**
 * Returns latest know information of how many pieces will be refilled.
 * @param {Object} task task object
 * @returns {string} picked, requested or refilled amount.
 */
export function getMainInformation({ task, t, productUnit }) {
  return formattedAmountTexts({ task, productUnit, t }).first;
}

/**
 * Returns history of addon picking.
 * @param {Object} task task object
 * @returns {string} all quantities prior to the last one in branckets.
 */
export function getAdditionalInformation({ task, t, productUnit }) {
  return formattedAmountTexts({ task, productUnit, t }).additional;
}

function Refilled({ task }) {
  const { t } = useTranslation();
  const { productUnit } = useFormatter();
  const { first, additional } = formattedAmountTexts({
    task,
    productUnit,
    t,
  });

  return (
    <DetailSection data-testid="requestedSection">
      <DetailSectionLeft>
        <SectionTitle>{getInformationTitle({ task, t })}</SectionTitle>
        <SectionText>
          {first}
          <br />
          <pre>{additional}</pre>
        </SectionText>
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default Refilled;
