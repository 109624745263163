import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import ProductInfoRow from "../ProductInfoRow";
import { useCleanProductTitle } from "../../hooks/useProductsMap";
import { ControlledRefillAndTurnover } from "@coworker/components";
import RefillsByLocation from "./RefillsByLocation";
import { useCallInsightsFunction } from "../../hooks/API/useCallFirebaseFunction";
import { PRODUCT_REFILLS_BY_LOCATION } from "@coworker/functions/src/enums/insightsRequestTypes";
import { useStoreId } from "../../core/auth/useLoggedInUser";
import { useInsightsFetchParams } from "./helpers";
import { parseProduct } from "../../services/products.service";
import { useInsightsEditStatus } from "../../features/insights/useInsightsEditStatus";
import { ReactComponent as Location } from "../../assets/svg/location.svg";

const Number = styled.p`
  font-weight: bold;
  margin-right: 9px;
  min-width: 20px;
  color: var(--black);
  line-height: 25px;
`;
const Content = styled.div`
  color: var(--black);
  display: grid;
  grid-template-columns: [first] ${(props) =>
      props.editEnabled ? "42px" : "30px"}[line2] auto [last];
  padding: 0px 22px 20px 28px;
`;
const Container = styled.div`
  display: grid;
  width: 100%;
`;
const LocationIcon = styled(Location)`
  padding-right: 20px;
  margin-top: 25px;
`;
const WrapIcon = styled.div`
  grid-row-start: 1;
  grid-row-end: 3;
`;
export function AddonListItem({
  id,
  index,
  title,
  subtitle,
  refilled,
  turnover,
  showOnlyLocation,
  openEditInsights,
}) {
  const { getEditStatus } = useInsightsEditStatus();
  const editEnabled = getEditStatus();
  const { location_id, product_id } = useParams();
  const [short_id] = parseProduct(product_id);
  const store_id = useStoreId();
  // Support for Insights corrections
  const [dataFetchParams, , isFetchingSavedFilters] = useInsightsFetchParams({
    store_id,
    location_id,
    product_id,
  });

  const { data: refills } = useCallInsightsFunction(
    PRODUCT_REFILLS_BY_LOCATION,
    {
      store_id,
      start_date: dataFetchParams.start_date,
      location: id,
      product_id: short_id,
      end_date: dataFetchParams.end_date,
      hfb: dataFetchParams.hfb,
      hfb_request_type: dataFetchParams.hfb_request_type,
      isFetchingSavedFilters,
    },
    { blockRequest: !editEnabled }
  );

  return (
    <>
      <Container>
        <Content editEnabled={editEnabled}>
          <WrapIcon>
            {" "}
            {editEnabled ? <LocationIcon /> : <Number>{index + 1}</Number>}
          </WrapIcon>

          <ProductInfoRow
            insights
            art={!showOnlyLocation && id}
            subtitle={subtitle}
            title={useCleanProductTitle(id, title)}
            imageLoader
            showOnlyLocation={showOnlyLocation}
            editInsightsEnabled={editEnabled}
            openEditInsights={openEditInsights}
          />
          <ControlledRefillAndTurnover
            turnover={turnover}
            refilled={refilled}
            showOnlyLocation={showOnlyLocation}
            editEnabled={editEnabled}
          />
        </Content>
        {refills && openEditInsights && editEnabled && (
          <RefillsByLocation refills={refills.records}></RefillsByLocation>
        )}
      </Container>
    </>
  );
}
