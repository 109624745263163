import React from "react";
import { Trans } from "@coworker/locales";
import { NewReview } from "./NewReview";
import { AppBar } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import { ChartCard } from "../Chart/ChartCard";
import { Filters } from "../Filters/Filters";
import styled from "styled-components";
import SSRIcon from "@ingka/ssr-icon";
import SettingsIcon from "@ingka/ssr-icon/paths/gear";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid var(--grey150);
`;

export const ShoppingToolsOverview = () => {
  const { push } = useWorkspacesAction();
  const activeArea =
    localStorage.getItem("shoppingToolsAreaFilter") ?? "wholeStore";

  const appBarActions: AppBarAction[] = [
    {
      name: "close",
      icon: <SSRIcon paths={SettingsIcon} />,
      onClick: () => push("/shoppingtools/settings"),
      position: "right",
    },
  ];
  return (
    <div>
      <AppBar
        title={<Trans>shoppingToolsHeaderString</Trans>}
        actions={appBarActions}
        onBack={() => push("/")}
      />
      <NewReview />
      <Filters showPeriod showRound={false} />
      {(activeArea === "showroomOTW" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>showroomOTWString</Trans>}
          showProgress
          area={"showroom"}
        />
      )}
      {activeArea === "wholeStore" && <Line />}
      {(activeArea === "markethallOTW" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>markethallOTWString</Trans>}
          showProgress
          area={"markethall"}
        />
      )}
      {activeArea === "wholeStore" && <Line />}
      {(activeArea === "otherAreaCashline" || activeArea === "wholeStore") && (
        <ChartCard
          title={<Trans>otherAreaCashlineString</Trans>}
          area={"cashline"}
        />
      )}
    </div>
  );
};
