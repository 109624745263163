import preferences from "@coworker/functions/src/enums/profilePreferences.json";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";

/**
 * @deprecated use `useUserPreference(preferences.LANGUAGE)` instead
 */
export function useLanguage(): string {
  const [userLanguage] = useUserPreference(preferences.LANGUAGE, "");
  return userLanguage;
}
