import React from "react";
import useFlag, { FLAGS } from "../useFlag";
import { functions } from "@coworker/common/config/firebase";
import { useAPIRequest } from "./useAPIRequest";
import dayjs from "dayjs";
import { postToTasksService } from "./tasks.service.helper";
/**
  All functions being called from out app.
 */
export const EXCHANGE_TOKEN = "exchangeToken";
export const EXCHANGE_ACCESS_TOKEN = "exchangeAccessToken";

const getFunctionRequestPromise = (functionName, body) => {
  return () =>
    functions
      .httpsCallable(functionName)({
        ...body,
        _clientVersion: require("@coworker/functions/src/version.json").version,
      })
      .then((res) => res.data);
};

/**
 *
 * @param {String} functionName
 * @param {Object} body
 * @param {Object} options
 * @param {Number} [options.cacheTimeout = 0] Number (in ms) that the data will
 * be cached
 * @param {Boolean} options.blockRequest Used for blocking later subsequent requests
 * to the same endpoint
 */
export default function useCallFirebaseFunction(
  functionName,
  body,
  options = {}
) {
  return useAPIRequest(
    getFunctionRequestPromise(functionName, body),
    `${functionName}:${JSON.stringify(body)}`,
    options
  );
}

const INSIGHTS = "insights";
export function useCallInsightsFunction(type, body, options = {}) {
  const [insightsList, setInsightsList] = React.useState([]);
  const finalInsightsFromTasksServiceFlag =
    useFlag(FLAGS.CALL_TASKS_SERVICE_INSIGHTS) ||
    window.finalInsightsFromTasksServiceFlag; // || finalInsightsFromTasksServiceFlag is not necessary, but a nice speedup.
  const badParams = [body.start_date, body.end_date].some(
    (date) => !dayjs(date, true).isValid() // https://stackoverflow.com/a/68965345/296639
  );
  const block =
    finalInsightsFromTasksServiceFlag === undefined ||
    !body?.store_id ||
    badParams ||
    !!body.isFetchingSavedFilters ||
    body.isFetchingSavedFilters === undefined;

  // Note: The below could be a deep-equality debounce, but we have no hook prepared -- and such a hook would look very close the the below code anyway. The hack to stringify and then parse inside is hacky, but lets us avoid ignoring linter warnings. And eliminates risk of again sending hundreds of API requests due to re-triggering the hook as body is a fresh object on every render.
  const stringBody = JSON.stringify(body);
  const data = React.useMemo(
    () => ({ ...JSON.parse(stringBody), insights_request_type: type }),
    [stringBody, type]
  );
  const resultFunction = useCallFirebaseFunction(INSIGHTS, data, {
    ...options,
    blockRequest:
      block || options.blockRequest || finalInsightsFromTasksServiceFlag, // block request to firebase function if we are calling tasks-service
  });
  //Call tasks-service to fetch insights data
  React.useEffect(() => {
    const getInsightsData = async () => {
      await postToTasksService("/v1/insights/insights_v0", data).then(
        (result) => {
          setInsightsList({
            data: result,
            dataIsFromAPI: true,
            loading: false,
            error: "",
          });
        }
      );
    };

    if (finalInsightsFromTasksServiceFlag && !block) {
      getInsightsData();
    }
  }, [finalInsightsFromTasksServiceFlag, data, block]);
  return finalInsightsFromTasksServiceFlag ? insightsList : resultFunction;
}
