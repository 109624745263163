import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

const TextArea = styled.div`
  font-size: 14px;
  font-weight: 700;
  margin: 10px 26px;
`;

const Divider = styled.div`
  border-top: solid 1px var(--grey200);
  background-color: var(--grey100);
  width: 100%;
  height: 6px;
`;

interface ViewingPartsOfTotalProps {
  partCount: number;
  totalCount: number;
  itemsString: string;
}

export function ViewingPartsOfTotal({
  partCount,
  totalCount,
  itemsString,
}: ViewingPartsOfTotalProps) {
  return (
    <>
      <TextArea>
        <Trans>viewingString</Trans>
        {` ${partCount}/${totalCount} `}
        <Trans>{itemsString}</Trans>
      </TextArea>
      <Divider />
    </>
  );
}
