import { TestBuy } from "@coworker/types";
import { COMPLETED_STATUS } from "../../../constants";

export function countTestBuysPerHfb(allTestbuys: TestBuy[]) {
  const allCompletedTestBuys = allTestbuys?.filter(
    (tb) => tb.status === COMPLETED_STATUS
  );

  const unique = [...new Set(allCompletedTestBuys?.map((item) => item.area))];

  const groupedbyArea = unique.map((hfb) => {
    const numberOfTestbuys: number =
      allCompletedTestBuys?.filter((testbuy) => testbuy.area === hfb).length ||
      0;
    return { area: hfb, numberOfTestbuys };
  });

  groupedbyArea.sort((a, b) => b.numberOfTestbuys - a.numberOfTestbuys);
  return groupedbyArea;
}

interface CountedTestBuysProps {
  area: string;
  numberOfTestbuys: number;
}

function makeRankItemProps(
  offsetIntoCounts: number,
  countedTestBuys: CountedTestBuysProps[]
) {
  const hfb = countedTestBuys[offsetIntoCounts];

  const uniqueRanks = [
    ...new Set(countedTestBuys.map((item) => item.numberOfTestbuys)),
  ];

  const rank =
    uniqueRanks
      .map((uniqueRank) => uniqueRank)
      .indexOf(hfb?.numberOfTestbuys || 0) + 1;

  return {
    rank,
    hfb: Number(hfb?.area.slice(6)),
    value: hfb?.numberOfTestbuys ?? 0,
  };
}

export function createRankItems(
  countedTestBuys: CountedTestBuysProps[],
  offsetIntoCounts: number
) {
  const ahead = makeRankItemProps(offsetIntoCounts - 1, countedTestBuys);
  const active = makeRankItemProps(offsetIntoCounts, countedTestBuys);
  const behind = makeRankItemProps(offsetIntoCounts + 1, countedTestBuys);
  return {
    ahead,
    active,
    behind,
  };
}
