import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { TextArea, Button, RadioButton } from "@coworker/components";
import { PleaseFillIn } from "../Reusable/PleaseFillIn";
import { useTranslation } from "@coworker/locales";
import { useTextAreaPopup } from "./_common";
import { pqHowOptions } from "@coworker/functions/src/enums/pqHowOptions";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  min-height: 100%;
  height: 100%;
  width: 100%;
  background: var(--white);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Row = styled.button`
  display: flex;
  padding: 0 28px;
  min-height: 90px;
  background: var(--white);
  cursor: pointer;
  flex-direction: row;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
`;

const GrowText = styled.div`
  flex-grow: 1;
  font-size: 14px;
  overflow: hidden;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 15px;
`;

const Headline = styled.div`
  width: 100%;
  padding: 82px 59px 45px 28px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: -0.0042em;
  color: var(--grey900);
`;

function SingleChoiceItem({ checked, text, onClick, testId }) {
  return (
    <Row onClick={onClick} data-testid={testId}>
      <GrowText>{text}</GrowText>
      <RadioButton checked={checked} readOnly />
    </Row>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 6px 0px;
`;

const StyledButton = styled(Button)`
  margin: 0 19px;
  width: 100%;
`;

const StyledArea = styled(TextArea)`
  flex-grow: 1;
  overflow-y: auto;
  ${({ hide }) => hide && `display: none;`}
  border-bottom: 2px solid var(--new);
  margin-top: 27px;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-left: 28px;
  margin-right: 28px;
  width: calc(100% - 56px);

  -webkit-scrollbar-width: none;
  -moz-scrollbar-width: none;
  -ms-scrollbar-width: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: scroll;
`;
const PleaseFillInArea = styled.div`
  margin-top: 8px;
`;

export default function RadioButtons({
  headline = "headline",
  list = [],
  value,
  onSubmit,
  testParams,
}) {
  const [initialType, otherText] = value;
  const [type, setType] = React.useState(initialType);
  const [text, setText] = React.useState(otherText || "");
  const [buttonPressed, setButtonPressed] = React.useState(
    testParams?.buttonPressed || false
  );
  const containerRef = React.useRef(null);
  const { textArea, onFocus, onBlur, focusToTextArea } = useTextAreaPopup(
    containerRef,
    text,
    {
      lineHeight: 20,
      scrollToBottomOnInit: false,
    }
  );
  const { t } = useTranslation();

  return (
    <Container ref={containerRef}>
      <ScrollContainer>
        <Headline>{headline}</Headline>
        {list
          .filter((x) => !x?.deprecated)
          .map((item) => (
            <SingleChoiceItem
              key={item.value}
              text={<Trans>{item.translationKey}</Trans>}
              checked={type === item.value}
              testId={item.value}
              onClick={() => {
                setType(item.value);
                if (item?.requiresText && textArea.current) {
                  focusToTextArea();
                }
              }}
            />
          ))}
        <StyledArea
          hide={type !== pqHowOptions.OTHER}
          noBorder
          noPadding
          data-testid="otherTextInput"
          placeholder={t("addNoteString")}
          maxLength={750}
          onChange={(e) => setText(e.target.value)}
          ref={textArea}
          value={text}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </ScrollContainer>
      <PleaseFillInArea>
        <PleaseFillIn
          show={!type && buttonPressed}
          alternateWarning="pleaseMakeASelectionString"
          centerText={true}
          testId="makeASelectionWarning"
        />
      </PleaseFillInArea>
      <ButtonWrapper>
        <StyledButton
          customMargin
          type="submit"
          text={<Trans>continue2String</Trans>}
          primary
          dark
          onClick={(event) => {
            setButtonPressed(true);
            if (type) {
              event.preventDefault();
              onSubmit([type, text]);
            }
          }}
          data-testid="continueButton"
        />
      </ButtonWrapper>
    </Container>
  );
}
