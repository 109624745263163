import { TaskState } from "@coworker/types/lib/tasks/constants";
import { ChecklistItem } from "@coworker/types/lib/tasks/testbuy_followup";
import type { Task } from "@coworker/types/lib/tasks/base";
import { fetchAPI } from "../../../../hooks/API/testbuy.service";
import { makeServiceHeaders } from "../../../../core/hooks/fetch.helpers";
import { TASKS_SERVICE_URL } from "../../../../hooks/API/tasks.service.helper";

interface TaskData {
  articleId: string;
  taskId: string;
  state: TaskState;
  finishedAt?: number | undefined;
}
const buildProductFollowUpTasks = (
  data: TaskModified,
  productId: string,
  taskId: string
) => ({
  articleId: productId,
  taskId,
  state: data.state,
  finishedAt: data.finished_at,
});

type TaskWithoutImagesId = Omit<Task, "images">;

type additionalProperties = {
  checklist: ChecklistItem[];
  failed_criteria: string[];
  has_images: false;
  has_notes: false;
  item_name: string;
  panumber: null;
  product_article_currency: string;
  product_article_id: string;
  product_article_price: number;
  product_article_type: string;
  state: string;
  task_id: string;
  task_type: string;
  testbuy_id: string;
  id: string;
};

type TaskModified = TaskWithoutImagesId & additionalProperties;

type TaskResult = {
  tasks: TaskModified[];
};

interface ArticleTaskPairs {
  productId: string;
  followUpTaskId: string;
}

export const fetchTestbuy = async (id: string | undefined) => {
  return (await fetchAPI(`testbuy/${id}`, "GET")) || [];
};

export const fetchTestbuys = async (
  uid: string | undefined,
  area: string | undefined,
  storeId: string | undefined,
  sinceTs: string | undefined,
  untilTs: string | undefined,
  divisionId: string | undefined
) => {
  return (
    (await fetchAPI("testbuy/get_testbuys", "POST", {
      uid,
      area,
      storeId,
      since: sinceTs,
      until: untilTs,
      divisionId,
    })) || []
  );
};

export const fetchTestbuyTasks = async (id: string) => {
  let tasks: TaskData[] = [];

  await fetchAPI(`article/tasks/${id}`, "GET").then(
    async (data: ArticleTaskPairs[]) => {
      if (data && data.length > 0) {
        const ids = data
          .map((task: ArticleTaskPairs) => task.followUpTaskId)
          .join(",");
        await fetch(`${TASKS_SERVICE_URL}/v1/tasks/by_ids?ids=${ids}`, {
          method: "GET",
          headers: await makeServiceHeaders(),
        })
          .then(async (response) => await response.json())
          .then((testbuyTasks: TaskResult) => {
            const tasksData = testbuyTasks.tasks.filter(
              (task) => task !== null
            );
            if (tasksData) {
              tasks = tasksData.map((task: TaskModified) =>
                buildProductFollowUpTasks(
                  task,
                  task.product_article_id,
                  task.id
                )
              ) as TaskData[];
            }
          });
      }
    }
  );
  return tasks as TaskData[];
};

export const fetchCountriesWith4A1KData = async () => {
  return (await fetchAPI(`acesking/getAcesAndKingCountries`, "POST")) || [""];
};
