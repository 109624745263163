import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Container } from "./_common";
import { SimpleProductInfo } from "@coworker/components";
import { formatFullIdWithDots, formatWithCommas } from "@coworker/reusable";
import { useFetchedBasicProduct } from "../../../hooks/useBasicProductsMap";

export const TaskTitle = styled.div`
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: var(--grey900);
`;

export default function TaskFormSimpleProduct({
  value: { productId: short_id, productType: type },
  onChange,
}) {
  const { basicProduct } = useFetchedBasicProduct(short_id);
  const name = basicProduct?.name || "";
  const description = formatWithCommas(basicProduct?.descriptives || []);

  return short_id ? (
    <Container data-testid="productDetailWidget">
      <TaskTitle>
        <Trans>product2String</Trans>
      </TaskTitle>
      <SimpleProductInfo
        title={name}
        description={description}
        formattedNumber={formatFullIdWithDots(short_id)}
        withArrow={false}
      />
    </Container>
  ) : null;
}
