import React from "react";
import TaskCardLink from "./TaskCardLink";
import VirtualTaskListDisplay from "./VirtualTaskListDisplay";
import { createTaskGroupList } from "../helpers/taskGrouping";
import trackerHelper from "../helpers/tracker.js";
import { useUserId, useTeamId } from "../core/auth/useLoggedInUser";

export function Display3Tasks({ tasks }) {
  const uid = useUserId();
  const team_id = useTeamId();
  return tasks.map((task) => (
    <TaskCardLink
      isDraggable={false}
      uid={uid}
      team_id={team_id}
      key={task?.id}
      task={task}
      testId="task_card"
      onClickTrack={trackerHelper.top3TaskCard}
    />
  ));
}

// TODO: Figure out and clean up: There are some drag/gesture things hiding in `...rest`. Maybe the `bind` related stuff.
const TaskListDisplay = ({ tasks, tabType, limit, groupBy, ...rest }) => {
  const uid = useUserId();
  const team_id = useTeamId();

  const addonFilter = rest.addonFilterOption?.[0];
  const items = React.useMemo(
    () => createTaskGroupList(tasks, groupBy, addonFilter),
    [groupBy, tasks, addonFilter]
  );

  if (limit) return <Display3Tasks tasks={tasks.slice(0, 3)} />;

  return (
    <>
      <VirtualTaskListDisplay
        uid={uid}
        team_id={team_id}
        groupBy={groupBy}
        items={items}
        tabType={tabType}
        {...rest}
      />
    </>
  );
};

export default TaskListDisplay;
