export enum TaskTypesFilters {
  ALL = "all",
  PICK_AND_REFILL = "pickAndRefill",
  REFILL = "refill",
  PRODUCT_ISSUE = "product",
  PRODUCT_QUALITY = "product_quality",
  TESTBUY_FOLLOW_UP = "testbuyfollowup",
  MFAQ_FOLLOW_UP = "mfaqfollowup",
  CUSTOM = "custom",
  RECURRING = "recurring",
  OTHER = "other",
}
