import React from "react";
import { ListViewItem } from "@ingka/list-view";
import { useTranslation } from "@coworker/locales";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useImagesBySignedUrl } from "../hooks/useImagesBySignedUrl";
import {
  getEDSAlertCount,
  getOngoingTaskCount,
  getRoomMetaInfo,
  getSalesStopAlertCount,
} from "./RoomListItem.helper";
import { HeadLine } from "../Atoms/HeadLine";
import { TaskLinks } from "../types/taskLink";
import { AlertResponse, Room } from "../types/room";
import { FixaWindow } from "@coworker/reusable";

interface RoomListItemProps {
  room: Room;
  taskLinks: TaskLinks;
  alerts: AlertResponse[];
}

export function RoomListItem({ room, taskLinks, alerts }: RoomListItemProps) {
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();

  const signedUrlImages = useImagesBySignedUrl(room);

  const ongoingTaskCount = getOngoingTaskCount(taskLinks, room.id);

  const edsAlertCount = getEDSAlertCount(alerts, room.id);

  const salesStopAlertCount = getSalesStopAlertCount(alerts, room.id);

  const mainImageUrl = signedUrlImages[0]?.url || "";

  return (
    <ListViewItem
      title={
        <HeadLine
          main={room.name}
          sub1={room.main_article_name_1}
          sub2={room.main_article_name_2}
        />
      }
      image={mainImageUrl}
      description={getRoomMetaInfo(
        ongoingTaskCount,
        edsAlertCount,
        salesStopAlertCount,
        t
      )}
      control={"navigational"}
      controlIcon={"chevronRightSmall"}
      onClick={() => {
        if ((window as FixaWindow).showNewGrouping) {
          push(`/roomsettings/tabs/${room.id}`);
        } else {
          push(`/roomsettings/${room.id}`);
        }
      }}
      emphasised
      inset
    />
  );
}
