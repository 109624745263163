import { Icon, useToastNotification } from "@coworker/components";
import { Trans } from "@coworker/locales";
import React from "react";
import { useInputPopup } from "../../InputPopup";
import { getAssigneesPopup } from "../../TaskForm/common/popup.helpers";
import {
  callInternal,
  TESTBUY_ACTIVE_ON_USER,
  TESTBUY_CLOSE,
  TESTBUY_REASSIGN,
} from "../../../hooks/API/useCallInternal";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { SUCCESS_TOAST } from "@coworker/components/src/components/ToastNotification";
import { ReactComponent as Quantity } from "../../../assets/svg/quantity.svg";
import { ReactComponent as Location } from "../../../assets/svg/location.svg";
import { ReactComponent as Other } from "../../../assets/svg/issue_other.svg";
import closeReasonTypeOptions from "@coworker/functions/src/testbuy/closeReasonTypes.json";
import { useFixaUID } from "../../../core/auth/useLoggedInUser";

export const testBuyCloseReasons = [
  {
    icon: Location,
    name: <Trans>tbCloseReasonHFB</Trans>,
    type: closeReasonTypeOptions.HFB,
  },
  {
    icon: Quantity,
    name: <Trans>tbCloseReasonAmount</Trans>,
    type: closeReasonTypeOptions.AMOUNT,
  },
  {
    icon: Other,
    name: <Trans>issueNameOtherString</Trans>,
    type: closeReasonTypeOptions.OTHER,
    descriptionRequired: true,
  },
];

export interface PopupMenuProps {
  id: string | undefined;
}

export function useTestbuyOptionsMenu({ id }: PopupMenuProps): {
  getOptionsMenu: () => void;
  loading: boolean;
} {
  const { getInput } = useInputPopup();
  const { push } = useWorkspacesAction();
  const { showToast } = useToastNotification();
  const [loading, setLoading] = React.useState(false);
  const [assigneePopUpRequested, setAssigneePopUpRequested] =
    React.useState(false);
  const [closeReasonPopUpRequested, setCloseReasonPopUpRequested] =
    React.useState(false);
  const fixaUid = useFixaUID();

  React.useEffect(() => {
    const onAssigneeConfirmed = async (assignee: {
      gid: string;
      uid: string;
    }) => {
      // First check if selected assignee already has active test buy.
      const { data: hasActiveTestBuy } =
        assignee &&
        (await callInternal(TESTBUY_ACTIVE_ON_USER, {
          uid: assignee.uid,
          fixaUid: assignee.uid,
        }));

      if (!hasActiveTestBuy) {
        setLoading(true);
        await callInternal(TESTBUY_REASSIGN, {
          testbuyId: id,
          assignee: assignee.uid,
        });
        setLoading(false);

        // When transfer is completed, redirect back to TB list.
        push("/testbuy");
        showToast(
          <Trans>tbReassigned</Trans>,
          undefined,
          undefined,
          5,
          SUCCESS_TOAST
        );
      } else {
        await getInput("confirmation", {
          question: <Trans>thereWasAnIssueString</Trans>,
          description: <Trans>userAlreadyHasActiveTBString</Trans>,
          positiveText: <Trans>OkString</Trans>,
        });
        setAssigneePopUpRequested(true);
      }
    };

    const openAssigneePopup = async () => {
      await getAssigneesPopup({
        getInput,
        onChange: onAssigneeConfirmed,
        lastAssignedTeamId: "",
        disableTeamAssignment: true,
      });
    };

    if (assigneePopUpRequested) {
      setAssigneePopUpRequested(false);
      openAssigneePopup();
    }
  }, [assigneePopUpRequested, getInput, id, push, showToast, fixaUid]);

  React.useEffect(() => {
    const openCloseReasonPopup = async () => {
      const closeReason = await getInput("selectIssue", {
        title: <Trans>whatString</Trans>,
        content: testBuyCloseReasons,
      });

      if (closeReason) {
        setLoading(true);
        await callInternal(TESTBUY_CLOSE, {
          testbuyId: id,
          reasonType: closeReason.issue,
          ...(closeReason.description && {
            closeReasonDescription: closeReason.description,
          }),
        });
        setLoading(false);

        // When transfer is completed, redirect back to TB list.
        push("/testbuy");
        showToast(
          <Trans>tbClosed</Trans>,
          undefined,
          undefined,
          5,
          SUCCESS_TOAST
        );
      }
    };

    if (closeReasonPopUpRequested) {
      setCloseReasonPopUpRequested(false);
      openCloseReasonPopup();
    }
  }, [closeReasonPopUpRequested, getInput, id, push, showToast]);

  const actions = [
    {
      onClick: () => setAssigneePopUpRequested(true),
      title: <Trans>reassignString</Trans>,
      enum: "REASSIGN",
      icon: (
        <Icon
          size={16}
          family="actions"
          name="recurring"
          color="grey900"
          className="removeHeight"
        />
      ),
    },
    {
      isHidden: false,
      isDisabled: false,
      onClick: () => setCloseReasonPopUpRequested(true),
      title: <Trans>closeActionString</Trans>,
      enum: "CLOSE",
      icon: (
        <Icon
          size={16}
          family="actions"
          name="close-large"
          color="grey900"
          className="removeHeight"
        />
      ),
    },
  ];
  const getOptionsMenu = () => getInput("popupMenu", { actions });

  return { getOptionsMenu, loading };
}
