import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  background: white;
  padding: 30px 0;
  width: 100%;
  border-top: ${(props) =>
    props.border ? "1px solid var(--grey150)" : "none"};
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version */
  flex-shrink: 0;
  flex-grow: 1;
  ${(props) => props.noborder && "border: none;"}
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
`;

const RadioButton = styled.div`
  display: flex;
  flex-direction: column;
  ${({ flexWidth }) =>
    css`
      flex: 0 0 ${flexWidth}%;
    `}
  margin-bottom: 15px;
  min-height: 83px;

  & input {
    display: none;
  }
  & input[type="radio"]:checked + label {
    & svg {
      path:last-of-type {
        fill: #111;
      }
    }
  }
`;

const Label = styled.label`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Text = styled.span`
  pointer-events: none;
  font-size: 14px;
  font-weight: bold;
  // This used to be 10ch but it doesn't fit with all languages
  max-width: 95%;
  text-align: center;
`;

const IconWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  padding: 26px 0px;

  cursor: pointer;
  width: 73px;
  height: 73px;
  border-radius: 50%;
  background: ${(props) =>
    props.checked ? "var(--yellow)" : "var(--grey150)"};
`;

const Selector = ({
  content,
  checked,
  onClick = () => {},
  noborder,
  numberOfColumns,
  ...props
}) => {
  return (
    <Container border={!noborder}>
      <InnerContainer data-testid="radioButtonContainer">
        {content.map((button, index) => (
          <RadioButton
            key={index}
            data-testid={`radioButton${index}`}
            data-type={button.type}
            flexWidth={100 / numberOfColumns}
          >
            <Label {...props} onClick={() => onClick(button.type)}>
              <IconWrapper
                data-testid={`icon${index}`}
                checked={checked === button.type}
              >
                <button.icon />
              </IconWrapper>
              <Text>{button.name}</Text>
            </Label>
          </RadioButton>
        ))}
      </InnerContainer>
    </Container>
  );
};

export default Selector;
