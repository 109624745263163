import React from "react";
import RadioButtonGroup, {
  RadioButtonGroupProps,
} from "@ingka/radio-button-group";

import "@ingka/focus/dist/style.css";
import "@ingka/forms/dist/style.css";

export function SkapaRadioButtonGroup(props: RadioButtonGroupProps) {
  return <RadioButtonGroup {...props} />;
}
