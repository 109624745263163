import React from "react";
import { Trans } from "@coworker/locales";
import { useInputPopup } from "../../InputPopup";
import { SelectIssue } from "@coworker/components";
import { Widget } from "@coworker/components/src/components/Widget";

const defaultValue = {
  type: null,
  description: null,
};
const toTypeMap = (issues) =>
  issues.reduce((all, curr) => ({ ...all, [curr.type]: curr }), {});

const filterIssues = (issues) => {
  return issues.filter((i) => i.type !== "POOR_ASSEMBLY");
};

export default function TaskFormIssue({
  value: { issue, description } = defaultValue,
  onChange,
  conf: { issues = [] } = {},
}) {
  // Germany rollout does not require the product issue type 'POOR_ASSEMBLY' ref: COAPP-7299
  const hideForGermanyUsers = window.hideForGermanyUsers;
  const { getInput } = useInputPopup();
  const selectIssue = React.useCallback(async () => {
    const data = await getInput("selectIssue", {
      title: <Trans>selectIssueString</Trans>,
      issue,
      description: description,
      content: hideForGermanyUsers ? filterIssues(issues) : issues,
    });
    if (data) {
      //Removed the Ref, since it is being used in other place to open the popup using getInput
      onChange({ issue: data.issue, description: data.description });
    }
  }, [issue, description, getInput, onChange, issues, hideForGermanyUsers]);

  return (
    <Widget data-testid="selectIssue" tabIndex={0} onClick={selectIssue}>
      <SelectIssue
        issue={issue ? toTypeMap(issues)[issue].name : ""}
        description={description}
        deactivated={false}
        selectString={<Trans>selectIssueString</Trans>}
      />
    </Widget>
  );
}
