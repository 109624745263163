import { useQuery } from "@tanstack/react-query";
import { fetchTodaysProgress } from "../ShoppingToolsAPI";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { INTERVAL } from "../../../constants/clientTime";
import { getStartOfDay } from "../helper";

export const useFetchTodaysProgress = (division: string, round: string) => {
  const storeId = useStoreId();
  const startOfDay = getStartOfDay();
  return useQuery({
    queryKey: ["shopping-tools-todays-progress", storeId, division, round],
    queryFn: () => {
      return fetchTodaysProgress(storeId, division, startOfDay, round);
    },
    staleTime: INTERVAL.MINUTE * 1,
  });
};
