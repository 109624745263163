import { Task } from "@coworker/types/lib/tasks/base";
import { TaskType } from "../../../tasks/enums/taskTypes";
import { MFAQFollowUp } from "@coworker/types/lib/tasks/mfaq_followup";

function getLocationForTask(
  task: Task,
  prefix: string
): { readableLocation: string; originalLocationValue: string } {
  let location = "";

  // TODO @Oliver: Remove this switch statement as all that is used now is searchable_location
  switch (task.task_type) {
    case TaskType.CUSTOM:
      location = task.location_custom ?? "";
      break;
    case TaskType.MFAQ_FOLLOW_UP:
      const mfaqTask = task as MFAQFollowUp;
      const area = mfaqTask.question_area?.split("=")[1] ?? "";
      if (prefix === "depts") {
        location = mfaqTask.question_area?.includes(prefix) ? `HFB${area}` : "";
      } else if (prefix === "other") {
        location = mfaqTask.question_area?.includes(prefix) ? area : "";
      }
      break;
    case TaskType.ADDON:
      location = task.searchable_location ?? "";
      break;
    default:
      location = task.searchable_location ?? "";
  }

  if (
    prefix &&
    !location.startsWith(prefix) &&
    task.task_type !== TaskType.MFAQ_FOLLOW_UP
  ) {
    return {
      readableLocation: "",
      originalLocationValue: task.searchable_location ?? "",
    };
  } else {
    return {
      readableLocation: location,
      originalLocationValue: task.searchable_location ?? "",
    };
  }
}

const taskToLocationsCachePerGroupAndTaskType: Record<
  string,
  { id: string; transKey: string; originalLocationValue: string }[]
> = {};

function mapTasksToLocations(
  tasks: Task[],
  prefix: string,
  selectedTaskType: string
): { id: string; transKey: string; originalLocationValue: string }[] {
  const cacheKey = prefix + selectedTaskType;
  if (taskToLocationsCachePerGroupAndTaskType[cacheKey]) {
    return taskToLocationsCachePerGroupAndTaskType[cacheKey] ?? [];
  }

  const uniqueLocations = new Set<string>();
  const resultLocations = tasks
    .map((task) => getLocationForTask(task, prefix))
    .filter((location) => {
      if (location && !uniqueLocations.has(location.readableLocation)) {
        uniqueLocations.add(location.readableLocation);
        return true;
      }
      return false;
    })
    .map((location) => ({
      id: location.readableLocation,
      transKey: location.readableLocation,
      originalLocationValue: location.originalLocationValue,
    }));

  taskToLocationsCachePerGroupAndTaskType[cacheKey] = resultLocations;
  return resultLocations;
}

export function getShowroomLocations(
  tasks: Task[],
  selectedTaskType: string
): { id: string; transKey: string; originalLocationValue: string }[] {
  return mapTasksToLocations(tasks, "S0", selectedTaskType);
}

export function getMarkethallLocations(
  tasks: Task[],
  selectedTaskType: string
): { id: string; transKey: string; originalLocationValue: string }[] {
  return mapTasksToLocations(tasks, "M1", selectedTaskType);
}

export function getOtherLocations(
  tasks: Task[],
  selectedTaskType: string
): { id: string; transKey: string; originalLocationValue: string }[] {
  const cacheKey = "other-2-" + selectedTaskType;
  if (taskToLocationsCachePerGroupAndTaskType[cacheKey]) {
    return taskToLocationsCachePerGroupAndTaskType[cacheKey] ?? [];
  }

  const uniqueLocations = new Set<string>();
  const resultLocations = tasks
    .map((task) => getLocationForTask(task, ""))
    .filter((location) => {
      if (
        location &&
        !uniqueLocations.has(location.readableLocation) &&
        !location.readableLocation.startsWith("S0") &&
        !location.readableLocation.startsWith("M1")
      ) {
        uniqueLocations.add(location.readableLocation);
        return true;
      }
      return false;
    })
    .map((location) => ({
      id: location.readableLocation,
      transKey: location.readableLocation,
      originalLocationValue: location.originalLocationValue,
    }));

  taskToLocationsCachePerGroupAndTaskType[cacheKey] = resultLocations;
  return resultLocations;
}

export function getDepartmentsLocations(
  tasks: Task[],
  selectedTaskType: string
): { id: string; transKey: string; originalLocationValue: string }[] {
  return mapTasksToLocations(tasks, "depts", selectedTaskType);
}

export function getOtherMfaqLocations(
  tasks: Task[],
  selectedTaskType: string
): { id: string; transKey: string; originalLocationValue: string }[] {
  return mapTasksToLocations(tasks, "other", selectedTaskType);
}
