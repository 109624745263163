import { Icon } from "@coworker/components";
import { Trans } from "@coworker/locales";
import React from "react";
import styled from "styled-components";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import SwipeLeftImg from "../../../assets/images/TB-article-swipe-left.svg";
import SwipeRightImg from "../../../assets/images/TB-article-swipe-right.svg";

const Container = styled.div`
  margin: 15px auto 0px auto;
  width: 90vw;
  background-color: var(--grey100);
  border-radius: 5px;
`;

const ContentWrapper = styled.div`
  padding: 15px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const Description = styled.span`
  font-size: 12px;
`;

const ImageWrapper = styled.div`
  display: grid;
`;

const SwipeImage = styled.img`
  margin-top: 15px;
  width: 100%;
  grid-column: 1;
  grid-row: 1;
`;

const AnimatedSwipeImage = styled(SwipeImage)`
  @keyframes fade {
    0% {
      opacity: 1;
    }
    45% {
      opacity: 1;
    }
    55% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  animation-name: fade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 10s;
  animation-direction: alternate;
`;

export const ReplaceArticlesGuide: React.FC = () => {
  const [hideGuide, setHideGuide] = useUserPreference(
    profilePreferences.HIDE_REPLACE_ARTICLES_GUIDE,
    false
  );

  return (
    <>
      {!hideGuide && (
        <Container>
          <ContentWrapper>
            <TitleWrapper>
              <b>
                <Trans>swipeToReplaceArticlesString</Trans>
              </b>
              <IconWrapper onClick={() => setHideGuide(true)}>
                <Icon
                  family="actions"
                  name="close-large"
                  size={20}
                  color="black"
                />
              </IconWrapper>
            </TitleWrapper>
            <Description>
              <Trans>swipeLeftOrRightToReplaceString</Trans>
            </Description>
            <ImageWrapper>
              <AnimatedSwipeImage src={SwipeLeftImg} />
              <SwipeImage src={SwipeRightImg} />
            </ImageWrapper>
          </ContentWrapper>
        </Container>
      )}
    </>
  );
};
