import React from "react";
import useFlag from "./useFlag";
import { getCachedInternalApi } from "../hooks/API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";

export function useTaskNotes(taskId: string) {
  const [result, setResult] = React.useState([]);
  const storeId = useStoreId();
  const mounted = React.useRef(true);
  React.useEffect(() => {
    if (taskId) {
      getCachedInternalApi(
        `task/notes?id=${taskId}`,
        ({ data }: any) => mounted.current && setResult(data?.list || []),
        60_000,
        false,
        "TASKS_SERVICE",
        {
          store_id: storeId,
        }
      );
    }
  }, [taskId, storeId]);
  React.useEffect(() => {
    // Cleanup to avoid "Can't perform a React state update on an unmounted component."
    return () => void (mounted.current = false);
  }, []);
  return result;
}

export function useTaskHasNotes(taskId: string) {
  const enabled = useFlag("ENABLE_TASKCARD_ICONS");
  return !!useTaskNotes(enabled ? taskId : "").length;
}
