import { useMutation } from "@tanstack/react-query";
import { uploadImageToCloud } from "../services/images.service";

export const useUploadImageToCloud = () => {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: uploadImageToCloud,
    // onSuccess: () => {
    //   return queryClient.invalidateQueries({ queryKey: ["imagesByRoom"] });
    // },
  });
};
