import React, { useContext } from "react";
import Avatar from "@coworker/app/src/components/Avatar/Avatar";
import { logEvent } from "@coworker/app/src/helpers/tracker";
import { useFixaUID } from "@coworker/app/src/core/auth/useLoggedInUser";
import { useWorkspacesAction } from "@coworker/app/src/hooks/useWorkspaces";

import TabNavigation from "./components/TabNavigation/TabNavigation";
import GlobalSearch from "./components/GlobalSearch/GlobalSearch";
import HejAppSwitcher from "./components/HejAppSwitcher/HejAppSwitcher";

import "./main-header.css";
import HamburgerMenu from "./components/HamburgerMenu/HamburgerMenu";
import { FixaWindow } from "@coworker/reusable";
import { ModalAction, ModalContext } from "../../context/ModalContextProvider";
import { ModalType } from "../../context/modalType";
import { Profile } from "../../../home/components/Profile/Profile";
import NotificationsLink from "./components/NotificationsLink/NotificationsLink";
import trackerHelper from "@coworker/app/src/helpers/tracker";
import { useTranslation } from "@coworker/locales";
import Button from "@ingka/button";

const handleProfileLinkClick = (toggleModal: (action: ModalAction) => void) => {
  logEvent("ce:hej_profile_link_clicked");
  toggleModal({ type: ModalType.Profile });
};

const MainHeader = () => {
  const fixaUid = useFixaUID();
  const { push } = useWorkspacesAction();
  const hideForGermany = (window as FixaWindow).hideForGermanyUsers;
  const { t } = useTranslation();
  const { toggleModal } = useContext(ModalContext);

  return (
    <div className="main-header">
      <div className="left-side">
        <>
          <HamburgerMenu />
          <h1
            tabIndex={0}
            role={"button"}
            aria-label={t("goToHomePageString")}
            onClick={() => push("/")}
          >
            Fixa
          </h1>
          <TabNavigation />
        </>
      </div>
      <div className="right-side">
        <GlobalSearch />
        <Button
          inverseTheme
          aria-label={t("goToProfilePageString")}
          type="tertiary"
          onClick={() => {
            handleProfileLinkClick(toggleModal);
            trackerHelper.trackProfileButtonClick();
          }}
          className="profile-link"
        >
          <Avatar fixaUid={fixaUid} big={false} />
        </Button>
        <NotificationsLink />
        <Profile />
        {/* @ts-ignore */}
        {!hideForGermany && <HejAppSwitcher />}
      </div>
    </div>
  );
};

export default MainHeader;
