import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { ReactComponent as SmallXIcon } from "@coworker/components/src/assets/svg/icon-close-small-crop.svg";

const Container = styled.div`
  display: flex;
  flex-direction: row;

  background-color: white;
  padding-top: 20px;
  padding-left: 29px;
  padding-right: 28px;
  height: 50px;
  ${({ noBottomMargin }) => !noBottomMargin && `margin-bottom: 20px;`}
`;

const SearchInputField = styled.input`
  height: 20px;
  width: 100%;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  caret-color: var(--new);

  :focus {
    outline: none;
  }
`;

const PlaceholderText = styled.div`
  position: absolute;
  color: var(--grey200);
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
  user-select: none;
  pointer-events: none;
`;

const TextButton = styled.div`
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
  line-height: 20px;
  // margin-right: 28px;
  margin-left: 9px;
  height: 20px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const SmallX = styled(SmallXIcon)`
  cursor: pointer;
  margin-top: 20px;
  background-color: var(--grey100);
  border-radius: 12px;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  margin-right: 5px;
  margin-left: 14px;
`;

const LeftPart = styled.div`
  position: relative;
  flex-grow: 1;
  & > * {
    margin-top: 20px;
  }
`;

export const HeaderSearchInput = ({
  inputRef,
  onCancel,
  onChange,
  onClose,
  placeholder,
  query,
  showDone = false,
  onDone,
  maxLength,
  noBottomMargin,
}) => {
  return (
    <Container data-testid="search" noBottomMargin={noBottomMargin}>
      <LeftPart>
        <PlaceholderText data-testid="searchPlaceholder">
          {!query && placeholder}
        </PlaceholderText>
        <SearchInputField
          data-testid="searchInput"
          maxLength={maxLength}
          autoFocus
          ref={inputRef}
          value={query}
          onChange={onChange}
        />
      </LeftPart>
      {query && <SmallX data-testid="searchClear" onClick={onClose} />}
      {!showDone && (
        <TextButton data-testid="searchCancel" onClick={onCancel}>
          <Trans>cancelString</Trans>
        </TextButton>
      )}
      {showDone && (
        <TextButton onClick={onDone} data-testid="searchDone">
          <Trans>doneString</Trans>
        </TextButton>
      )}
    </Container>
  );
};
