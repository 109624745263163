import React, { useState, useEffect } from "react";
import Checkbox from "@ingka/checkbox";
import ListView, { ListViewItem } from "@ingka/list-view";
import checkmarkBox from "@ingka/ssr-icon/paths/checkmark-box";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { Trans, useTranslation } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { SimpleListContainer } from "../../RoomSettings/Molecules/SimpleListContainer";
import { Reasons } from "../../RoomSettings/constants";
import { updateTask } from "../../../services/task.service";

interface RoomSettingsTodoProps {
  value: {
    id: string;
    is_ptag: boolean;
    is_new_article: boolean;
    is_ptag_handled: boolean;
    is_new_article_handled: boolean;
    state: string;
    pieces: number;
    reason: string;
  };
}

export default function RoomSettingsTodo({ value }: RoomSettingsTodoProps) {
  const { t } = useTranslation();
  const [isHandled, setIsHandled] = useState({
    is_ptag_handled: value.is_ptag_handled,
    is_new_article_handled: value.is_new_article_handled,
  });

  const writeOffArticleString =
    value.pieces > 1 ? (
      <Trans count={value.pieces}>writeOffArticlesWithSGFString</Trans>
    ) : (
      t("writeOffArticleWithSGFString")
    );

  const checkRender = () => {
    if (value.is_new_article)
      return { renderNewArticle: true, renderPtag: true };

    if (value.is_ptag) return { renderNewArticle: false, renderPtag: true };

    return { renderNewArticle: false, renderPtag: false };
  };

  useEffect(() => {
    setIsHandled({
      is_ptag_handled: value.is_ptag_handled,
      is_new_article_handled: value.is_new_article_handled,
    });
  }, [value.is_ptag_handled, value.is_new_article_handled]);

  return (
    <>
      {value.state === "ASSIGNED" &&
        value.reason === Reasons.DAILY_MAINTENANCE && (
          <Widget
            data-testid={"roomSettingsTodoWidget"}
            style={{
              backgroundColor: "var(--grey100)",
              borderTop: "1px solid var(--grey200)",
              borderBottom: "1px solid var(--grey200)",
              overflow: "hidden",
            }}
          >
            <ListView id="rs-task-todo-list" size={"small"}>
              <ListViewItem
                title={`${t("todoBasicString")} ${
                  value.pieces > 1 ? `(${value.pieces} ${t("piecesUnit")})` : ""
                }`}
                leadingIcon={checkmarkBox}
                emphasised
                inset
              />
            </ListView>

            {checkRender()?.renderNewArticle && (
              <SimpleListContainer
                headline={`${t("getNewArticleString")} ${
                  value.pieces > 1 ? `${value.pieces}x` : ""
                }`}
                subtext={writeOffArticleString as string}
                leftcontent={
                  <Checkbox
                    id="is_new_article_handled"
                    value={String(isHandled.is_new_article_handled)}
                    checked={isHandled.is_new_article_handled}
                    subtle
                    onChange={() => {
                      const newValue = !isHandled.is_new_article_handled;
                      setIsHandled((prev) => ({
                        ...prev,
                        is_new_article_handled: newValue,
                      }));
                      updateTask(
                        { is_new_article_handled: newValue },
                        value.id,
                        taskTypeOptions.ROOM_SETTINGS
                      );
                    }}
                  />
                }
              />
            )}

            {checkRender()?.renderPtag && (
              <SimpleListContainer
                headline={`${t("printPTagString")} ${
                  value.pieces > 1 ? `${value.pieces}x` : ""
                }`}
                subtext={t("selectRightCarrierInPtagString")}
                leftcontent={
                  <Checkbox
                    id="is_ptag_handled"
                    value={String(isHandled.is_ptag_handled)}
                    checked={isHandled.is_ptag_handled}
                    subtle
                    onChange={() => {
                      const newValue = !isHandled.is_ptag_handled;
                      setIsHandled((prev) => ({
                        ...prev,
                        is_ptag_handled: newValue,
                      }));
                      updateTask(
                        { is_ptag_handled: newValue },
                        value.id,
                        taskTypeOptions.ROOM_SETTINGS
                      );
                    }}
                  />
                }
              />
            )}
          </Widget>
        )}
    </>
  );
}
