import React from "react";
import { SkapaModalHeader } from "../SkapaModalHeader"; // TODO: Move to reusable?
import styled from "styled-components";
import { logEvent } from "../../helpers/tracker";
import { Trans } from "@coworker/locales";
import { useNameFormatter } from "../../hooks/useActiveUsers";

// Skapa components
import Choice, { ChoiceItem, type ChoiceItemTitle } from "@ingka/choice";
import { FullWidthButton } from "../SkapaButton"; // TODO: Move to reusable?
import { Container, OverflowBackground } from "./CommonComponents"; // TODO: Move to reusable?

// Skapa styles
import "@ingka/choice/dist/style.css";

const Separator = styled.div`
  background-color: var(--grey100);
  height: 16px;
`;

const Margin = styled.div`
  margin: 24px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

interface ChosenTeamBoxProps {
  text: ChoiceItemTitle;
}

function ChosenTeamBox({ text }: ChosenTeamBoxProps) {
  return (
    <Choice preselectedIndex={0} subtle>
      <ChoiceItem
        id="0"
        title={text}
        onClick={() => logEvent("ce:confirm_assignment:blind_click")}
      />
    </Choice>
  );
}

interface ConfirmAssignmentProps {
  teamId: string;
  onClose: () => void;
  onSubmit: (reassign: boolean) => void;
}

/**
 * Resolves (calls onSubmit) with true ONLY when reassign button is pressed
 * All other cases just closes the dialog and returns false or undefined.
 */
export function ConfirmAssignment({
  onClose,
  onSubmit,
  teamId,
}: ConfirmAssignmentProps) {
  const { teamNameFor } = useNameFormatter();
  const teamName = teamNameFor(teamId);
  return (
    <>
      <OverflowBackground
        data-testid="background"
        onClick={() => {
          logEvent("ce:confirm_assignment:close_overflow");
          onClose();
        }}
      />
      <Container data-testid="confirmScreen">
        <SkapaModalHeader
          titleString="assignString"
          onClose={() => {
            logEvent("ce:confirm_assignment:x");
            onClose();
          }}
        />

        <Margin>
          <Title>
            <Trans>selectedTeamString</Trans>
          </Title>
          <ChosenTeamBox text={teamName} />
        </Margin>

        <FullWidthButton
          style={{ margin: "-24px 24px 16px 24px" }}
          type="secondary"
          text={<Trans>changeString</Trans>}
          onClick={() => {
            logEvent("ce:confirm_assignment:change");
            onSubmit(true); // return true only when we want to reassign
          }}
        />
        <Separator />
        <FullWidthButton
          text={<Trans>confirmString</Trans>}
          onClick={() => {
            logEvent("ce:confirm_assignment:confirm");
            onClose();
          }}
        />
      </Container>
    </>
  );
}
