import React from "react";
import { Trans } from "@coworker/locales";
import { useTaskOptionPopupMenu } from "../features/tasks/useTaskOptionPopupMenu";

// SVGs
import { ReactComponent as PickupIcon } from "../assets/svg/add-task.svg";
import { ReactComponent as CompleteWithChangesIcon } from "../assets/svg/customize-task.svg";
import { ReactComponent as EditIcon } from "../assets/svg/more-task.svg";
// TODO: Deduplicate, since check-task.svg is identical to check.svg except for the fill color
import { ReactComponent as CompleteIcon } from "../assets/svg/check-task.svg";
import trackerHelper from "../helpers/tracker";
import {
  SlidingButton,
  SlidingButtonIcon,
  SlidingButtonText,
} from "./SlidingButton";

export function CompleteButton({ style, onClick }) {
  const handler = React.useCallback(() => {
    onClick();
    trackerHelper.taskSwipeButtons.trackComplete();
  }, [onClick]);
  return (
    <SlidingButton
      onClick={handler}
      style={style}
      background={"var(--green-darker)"}
    >
      <SlidingButtonIcon>
        <CompleteIcon />
      </SlidingButtonIcon>
      <SlidingButtonText>
        <Trans>completeButton</Trans>
      </SlidingButtonText>
    </SlidingButton>
  );
}

export function CompleteWithChangesButton({ style, onClick }) {
  const handler = React.useCallback(() => {
    onClick();
    trackerHelper.taskSwipeButtons.trackCompleteWithChanges();
  }, [onClick]);
  return (
    <SlidingButton onClick={handler} style={style} background={"var(--green)"}>
      <SlidingButtonIcon>
        <CompleteWithChangesIcon />
      </SlidingButtonIcon>
      <SlidingButtonText>
        <Trans>completeWithChangesButton</Trans>
      </SlidingButtonText>
    </SlidingButton>
  );
}

export function MoreButton({ style, task }) {
  const { getTaskMenu } = useTaskOptionPopupMenu(task);
  const handleMore = React.useCallback(
    (event) => {
      event.stopPropagation();
      getTaskMenu();
      trackerHelper.taskSwipeButtons.trackOpenMenu();
    },
    [getTaskMenu]
  );

  return (
    <SlidingButton
      onClick={handleMore}
      style={{ ...style, marginLeft: "auto" }}
    >
      <SlidingButtonIcon>
        <EditIcon />
      </SlidingButtonIcon>
      <SlidingButtonText>
        <Trans>moreButton</Trans>
      </SlidingButtonText>
    </SlidingButton>
  );
}

export function PickupButton({ style, onClick }) {
  const handler = React.useCallback(() => {
    onClick();
    trackerHelper.taskSwipeButtons.trackPickup();
  }, [onClick]);
  return (
    <SlidingButton onClick={handler} style={style}>
      <SlidingButtonIcon>
        <PickupIcon />
      </SlidingButtonIcon>
      <SlidingButtonText>
        <Trans>pickupTaskButton</Trans>
      </SlidingButtonText>
    </SlidingButton>
  );
}
