import React from "react";
import styled from "styled-components";
import { ArticleList, ArticleListProps } from "./ArticleList";
import { ReactComponent as ArrowOpen } from "../../../assets/svg/arrow_down_small.svg";
import { getCompletedArticlesProps } from "./TestBuyDetailsHelper";
import { Trans } from "@coworker/locales";

const ShowCompletedListContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem 1.5rem 1rem 2rem;
  border-bottom: 1px solid var(--grey150);
  border-top: 1rem solid var(--grey150);
`;

const ToggleIcon = styled.div`
  margin-left: auto;
`;
const ArrowClosed = styled(ArrowOpen)`
  transform: rotate(180deg);
  transform-origin: center;
`;

export default function CompletedArticleList({
  articles,
  testbuyId,
}: ArticleListProps) {
  const [shouldShowCompletedTasks, setShouldShowCompletedTasks] =
    React.useState(false);

  const completeArticlesProps = getCompletedArticlesProps(articles, testbuyId);
  return (
    //This div exist due to render issues on RDT
    <div data-testid="completed-article-list">
      <ShowCompletedListContainer
        onClick={() => {
          setShouldShowCompletedTasks(!shouldShowCompletedTasks);
        }}
      >
        <p>
          {shouldShowCompletedTasks ? (
            <Trans count={completeArticlesProps.articles.length}>
              hideCompleted
            </Trans>
          ) : (
            <Trans count={completeArticlesProps.articles.length}>
              showCompleted
            </Trans>
          )}
        </p>
        <ToggleIcon>
          {shouldShowCompletedTasks ? <ArrowOpen /> : <ArrowClosed />}
        </ToggleIcon>
      </ShowCompletedListContainer>
      {shouldShowCompletedTasks && <ArticleList {...completeArticlesProps} />}
    </div>
  );
}
