import React from "react";
import { Trans } from "@coworker/locales";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import issueTypeOptions from "@coworker/functions/src/enums/issueTypes.json";

export const issueTypeTitle = {
  [taskTypeOptions.PRODUCT_ISSUE]: {
    [issueTypeOptions.INVENTORY]: <Trans>issueTitleInventoryString</Trans>,
    [issueTypeOptions.DAMAGED_OR_BROKEN]: (
      <Trans>issueTitleDamagedString</Trans>
    ),
    [issueTypeOptions.POOR_ASSEMBLY]: <Trans>issueTitleAssemblyString</Trans>,
    [issueTypeOptions.DIRTY]: <Trans>issueTitleDirtyString</Trans>,
    [issueTypeOptions.MISSING_PARTS]: (
      <Trans>issueTitleMissingPartsString</Trans>
    ),
    [issueTypeOptions.NO_PRICE_TAG]: <Trans>issueTitleNoPriceTagString</Trans>,
    [issueTypeOptions.RACKING]: <Trans>issueTitleRackingString</Trans>,
    [issueTypeOptions.OUT_OF_STOCK]: <Trans>issueTitleOutOfStockString</Trans>,
    [issueTypeOptions.MISSING_DISPLAY_MODEL]: (
      <Trans>issueNameMissingDisplayModelString</Trans>
    ),
    [issueTypeOptions.OTHER]: <Trans>issueTitleOtherString</Trans>,
  },
  [taskTypeOptions.ADDON]: {
    default: <Trans>addonTitleString</Trans>,
  },
  [taskTypeOptions.CUSTOM]: {
    default: <Trans>customString</Trans>,
  },
  [taskTypeOptions.PLANNED]: {
    default: <Trans>plannedString</Trans>,
  },
  [taskTypeOptions.PRODUCT_QUALITY]: {
    default: <Trans>productQualityReportString</Trans>,
  },
  [taskTypeOptions.MFAQ_FOLLOW_UP]: {
    default: <Trans>mfaqFollowUpTitle</Trans>,
  },
  [taskTypeOptions.TESTBUY_FOLLOW_UP]: {
    default: <Trans>testBuyFollowUpString</Trans>,
  },
  [taskTypeOptions.ROOM_SETTINGS]: {
    default: <Trans>roomSettingsString</Trans>,
  },
};
