import styled from "styled-components";

const Toast = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: var(--white);
  background: var(--black);
  height: 30px;
  width: 100%;
  cursor: pointer;
  z-index: var(--z-toast-2);
  flex-shrink: 0;
`;

export default Toast;
