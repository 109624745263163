import React from "react";
import styled from "styled-components";
import ActivityLink from "./ActivityLink";

const Number = styled.div`
  min-width: 21px;
  height: 21px;
  margin: 0 12px;
  box-sizing: border-box;
  padding: 0px 4px;
  ${(props) =>
    props.isNotificationCounter
      ? `
        border-radius: 10.5px;
        background-color: var(--error);
        color: var(--white);
      `
      : "color: var(--grey500);"}
  font-size: 12px;
  font-weight: bold;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.33px;
`;

const NotificationCounterLink = ({
  number,
  hasMoreThanNumber = false,
  isNotificationCounter,
  separatorBottomLine,
  title,
  to,
  testId,
}) => {
  const badge = (number > 0 || !isNotificationCounter) && (
    <Number
      isNotificationCounter={isNotificationCounter}
      data-testid="notifications-counter-count"
    >
      {number}
      {hasMoreThanNumber && "+"}
    </Number>
  );
  return (
    <ActivityLink
      linkText={title}
      badge={badge}
      to={to}
      testId={testId}
      separatorBottomLine={separatorBottomLine}
    />
  );
};

export default NotificationCounterLink;
