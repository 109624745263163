import { useQuery } from "@tanstack/react-query";
import { getTaskLinksByStore } from "../services/tasks.service";

export const useTaskLinksByStore = (storeId: string | undefined) => {
  return useQuery({
    queryKey: ["taskLinksByStore", storeId],
    queryFn: () => getTaskLinksByStore(storeId || ""),
    enabled: !!storeId,
  });
};
