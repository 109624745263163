import React from "react";
import styled from "styled-components";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { formatProductIdWithDots } from "@coworker/reusable";
import { useBasicProductFromTask } from "../services/products.service";
import SubtitleReportedBy from "./SubtitleReportedBy";
import { ElidedText } from "@coworker/reusable/components/ElidedText";

const TaskSubtitle = styled.h2`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  color: var(--grey700);
`;

export function Subtitles({ task, location, hideProduct }) {
  const isAddonOrProductIssue = [
    taskTypeOptions.ADDON,
    taskTypeOptions.PRODUCT_ISSUE,
  ].includes(task.task_type);
  const isCustomTask = task.task_type === taskTypeOptions.CUSTOM;
  const isProductQuality = task.task_type === taskTypeOptions.PRODUCT_QUALITY;
  const isMFAQFollowup = task.task_type === taskTypeOptions.MFAQ_FOLLOW_UP;
  const isTestbuyFollowup =
    task.task_type === taskTypeOptions.TESTBUY_FOLLOW_UP;
  const isRoomSettings = task.task_type === taskTypeOptions.ROOM_SETTINGS;

  const { title, productType, productDescription } = useBasicProductFromTask(
    !isCustomTask && task,
    {
      includeDescription: isAddonOrProductIssue || isRoomSettings,
      includeType: isProductQuality,
    }
  );

  if (isAddonOrProductIssue || isRoomSettings) {
    const formattedProductId = formatProductIdWithDots(task.product_article_id);
    return (
      <React.Fragment>
        {!hideProduct && (
          <TaskSubtitle>
            <b>
              {`${title || ""} • ${formattedProductId}`}
              {title && productDescription && formattedProductId && <br />}
            </b>
            <ElidedText>{productDescription || ""}</ElidedText>
          </TaskSubtitle>
        )}
        {location}
      </React.Fragment>
    );
  } else if (isCustomTask) {
    return task.description ? (
      <TaskSubtitle>
        <ElidedText>{task.description}</ElidedText>
      </TaskSubtitle>
    ) : null;
  } else if (isProductQuality) {
    return (
      <TaskSubtitle>
        {!hideProduct && (
          <ElidedText>
            <b>{title || ""} • </b> <span>{productType || ""}</span>
          </ElidedText>
        )}
        <SubtitleReportedBy
          creator_id={task.creator_id}
          created_at={task.created_at}
        />
      </TaskSubtitle>
    );
  } else if (isMFAQFollowup) {
    return <TaskSubtitle>{task.question_text}</TaskSubtitle>;
  } else if (isTestbuyFollowup) {
    const formattedProductId = formatProductIdWithDots(task.product_article_id);
    return (
      <React.Fragment>
        <TaskSubtitle>
          <b>
            {`${title || ""} • ${formattedProductId}`}
            {title && productDescription && formattedProductId && <br />}
          </b>
          <ElidedText>{productDescription || ""}</ElidedText>
        </TaskSubtitle>
        {location}
      </React.Fragment>
    );
  } else {
    return null;
  }
}
