import React, { ReactElement } from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { PieChart } from "../Chart/PieChart";
import styled from "styled-components";
import { chartOptions } from "./ChartOptions";
import {
  getChartData,
  getPeriodTitle,
  getStartAndEndDateForChartData,
} from "../helper";
import { ChartSkeleton } from "../Components/Skeletons/ChartSkeleton";
import { ChartData } from "../Types";
import { ResizeObserver as ResizeObserverPolyfill } from "@juggle/resize-observer";
import { useFetchChartData } from "../Hooks/useFetchChartData";

interface ChartCardProps {
  title: ReactElement;
  showProgress?: boolean;
  area?: string;
}

const Container = styled.div`
  width: 90%;
  padding: 1.5rem 0;
  margin: auto;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
`;

const ChartCardHeader = styled.span`
  font-size: 14px;
  font-weight: 700;
`;

const SubChartCardHeader = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: var(--grey718);
`;

const ChartWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const NoData = styled.div`
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  font-size: 14px;
`;

export const ChartCard = ({ title, area }: ChartCardProps) => {
  const { t } = useTranslation();

  const activePeriod =
    localStorage.getItem("shoppingToolsPeriodFilter") ?? "today";
  const customStartDate = localStorage.getItem(
    "shoppingToolsStartDatePeriodFilter"
  )!;
  const customEndDate = localStorage.getItem(
    "shoppingToolsEndDatePeriodFilter"
  )!;

  const dates = getStartAndEndDateForChartData(
    activePeriod,
    customStartDate,
    customEndDate
  );

  const { data: allTools, isFetching } = useFetchChartData(
    dates.startDate,
    dates.endDate
  );

  const periodTitle = getPeriodTitle(
    activePeriod,
    customStartDate,
    customEndDate
  );

  if (isFetching) {
    return (
      <Container>
        <TitleWrapper>
          <ChartCardHeader>{title}</ChartCardHeader>
          <SubChartCardHeader>
            <Trans>{periodTitle}</Trans>
          </SubChartCardHeader>
        </TitleWrapper>
        <ChartSkeleton />
      </Container>
    );
  }

  if (!allTools) return null;

  const divisionData = allTools.find(
    (tool: ChartData) => tool?.division === area
  );

  const chartData = getChartData(allTools, area, t);

  if (typeof window !== "undefined") {
    window.ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill;
  }

  return (
    <Container>
      <TitleWrapper>
        <ChartCardHeader>{title}</ChartCardHeader>
        <SubChartCardHeader>
          <Trans>{periodTitle}</Trans>
        </SubChartCardHeader>
      </TitleWrapper>
      {divisionData?.initialShoppingTools || divisionData?.lastShoppingTools ? (
        <>
          <ChartWrapper>
            <PieChart data={chartData} options={chartOptions} />
          </ChartWrapper>
        </>
      ) : (
        <NoData>
          <Trans>noDataString</Trans>
        </NoData>
      )}
    </Container>
  );
};
