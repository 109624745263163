import React from "react";
import { WidgetRow } from "../PaddedRow";
import { SkapaSwitch } from "../../../components/SkapaSwitch";

interface ToggleFieldProps {
  title: React.ReactNode;
  onChange: (value: boolean) => void;
  image?: string | React.ReactNode;
  value?: boolean;
  testId?: string;
}

export function ToggleField({
  title,
  onChange,
  image,
  value = false,
  testId = "toggle-field",
}: ToggleFieldProps) {
  return (
    <WidgetRow
      className="WidgetView"
      image={image}
      middle={title}
      right={
        <SkapaSwitch
          id=""
          testId={testId}
          checked={value}
          onChange={onChange}
        />
      }
      testId={testId || "toggle-field-widget"}
      disableRightArrow={true}
    />
  );
}
