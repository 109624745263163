import { isIOS } from "../../helpers/browserChecks";
import { isRDTDevice } from "@coworker/reusable";
import {
  blockBrowserScannerKey,
  getLocalDeviceConfig,
} from "@coworker/common/helpers/devices";

// List of devices that should not use the browser API based scanner
const blockedDevices = ["SM-A405FN"];

/**
 * Checks if the app should use the browser API based scanner
 * @returns {boolean}
 */
export default function useBrowserScanner() {
  return (
    !blocksBrowserScanner() &&
    !isRDTDevice() &&
    !isIOS &&
    !getLocalDeviceConfig(blockBrowserScannerKey) &&
    "BarcodeDetector" in window
  );
}

export function blocksBrowserScanner() {
  return blockedDevices.some((device) => navigator.userAgent.includes(device));
}
