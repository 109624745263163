import React from "react";

export const policy = {
  title: <div>Política de Privacidade</div>,
  aboutFixaText: (
    <div>
      Fixa é uma ferramenta de merchandising e de gestão de tarefas para os
      colaboradores da loja. As aplicações Fixa têm como finalidade substituir
      atividades manuais ou baseadas em papel ou Excel e agilizar a gestão de
      tarefas a nível da loja (reposição de artigos, limpezas, desvios, compras
      de teste, perguntas frequentes, etc.). Fixa também permite obter análises
      através dos dados obtidos das tarefas concluídas.
      <br />
      <br />
      Quanto utilizas Fixa, a empresa do Grupo Ingka Responsável pelo Tratamento
      de Dados (conforme definido abaixo) recolhe alguns dados pessoais sobre
      ti.
    </div>
  ),
  personalDataCollectedTitle: <div>Os dados pessoais recolhidos são:</div>,
  personalDataCollectedText: (
    <div>
      Quanto utilizas Fixa, o sistema recolhe informações tuas a partir da
      Co-Worker Database (CDS), tal como o teu nome próprio, nome do meio,
      apelido, e-mail, nome da função, função, número de telefone (caso tenha
      sido fornecido manualmente: o número de telefone pessoal não deve ser
      introduzido), fotografia incluída na CDS, idioma preferencial na
      aplicação, ID da loja, ID do país, versão do dispositivo e do sistema
      operativo, endereço de IP processado no Google Cloud Platform e Sentry
      (Sentry é a aplicação de software de rastreio de erros).
      <br />
      <br />O perfil de colaborador é criado manualmente através de Fixa-admin
      (uma aplicação de administração na web) pelo responsável direto/superuser.
      Os dados pessoais são obtidos a partir da CDS, pelo que os perfis só podem
      ser criados para colaboradores com um perfil de CDS ativo.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>A finalidade da recolha destes dados é:</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      A finalidade da recolha de dados pessoais na Fixa é o funcionamento
      adequado da aplicação, de forma a permitir uma comunicação interna fácil
      entre colaboradores e a administração de tarefas entre colaboradores na
      loja. Ter tarefas associadas a um utilizador permite-nos dar notificações
      de atualizações das tarefas aos criadores e aos utilizadores atribuídos às
      mesmas.
    </div>
  ),
  legalGroundsTitle: <div>O fundamento jurídico para o tratamento é:</div>,
  legalGroundsText: (
    <div>O fundamento jurídico para o tratamento é o interesse legítimo.</div>
  ),
  dataWillBeStoredTitle: <div>Os dados serão armazenados:</div>,
  dataWillBeStoredText: (
    <div>
      Armazenamos as tarefas concluídas com referências a utilizadores durante
      70 dias. Após essa data, são tornadas anónimas. Os dados pessoais de um
      colaborador são armazenados na Fixa durante a validade do respetivo
      contrato de trabalho.
      <br />
      <br />
      Resolução do contrato de trabalho com o Grupo Ingka: Todos os dados
      pessoais são automaticamente eliminados até 1 mês após a remoção do perfil
      de CDS.
      <br />
      <br />
      Mudança de função no Grupo Ingka: Se um colaborador deixar de precisar de
      utilizar a aplicação Fixa, todos os seus dados pessoais serão eliminados
      automaticamente após 3 meses de inatividade.
      <br />
      <br />
      Os dados pessoais também podem ser removidos a qualquer altura mediante
      pedido ao responsável local em Segurança da Informação e Privacidade dos
      Dados, através do e-mail: privacidade@ikea.com.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>O responsável pelo tratamento de dados pessoais é:</div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA Portugal - Móveis e Decoração, Lda.
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      Tens direitos em relação aos teus dados pessoais. Para saberes mais sobre
      esses direitos, visita{" "}
      <a href="https://inside.ingka.com/supportservices/Dataprivacy/Pages/Individual-rights.aspx">
        https://inside.ingka.com/&#8203;supportservices/&#8203;Dataprivacy/&#8203;Pages/&#8203;Individual-rights.aspx
      </a>
      . <br />
      <a href="https://inside.ingka.com/careerlearning/Documents/Pol%C3%ADtica_de_Privacidade_de_Colaboradores_IKEA_PT.pdf">
        https://inside.ingka.com/&#8203;careerlearning/&#8203;Documents/&#8203;Pol%C3%ADtica_de_Privacidade_de_Colaboradores_IKEA_PT.pdf
      </a>
      <br />
      <a href="https://inside.ingka.com/careerlearning/Documents/Pol%C3%ADtica_de_Privacidade_de_Colaboradores_IKEA_EN.pdf">
        https://inside.ingka.com/&#8203;careerlearning/&#8203;Documents/&#8203;Pol%C3%ADtica_de_Privacidade_de_Colaboradores_IKEA_EN.pdf
      </a>
      <br />
      Informações de contacto:{" "}
      <a href="mailto:privacidade@ikea.com">privacidade@ikea.com</a>
    </div>
  ),
};
