import { policy as AT } from "./AT";
import { policy as AU } from "./AU";
import { policy as CH } from "./CH";
import { policy as DE } from "./DE";
import { policy as ES } from "./ES";
import { policy as FI } from "./FI";
import { policy as FR } from "./FR";
import { policy as GB } from "./GB";
import { policy as IE } from "./IE";
import { policy as IN } from "./IN";
import { policy as IT } from "./IT";
import { policy as JP } from "./JP";
import { policy as KR } from "./KR";
import { policy as NL } from "./NL";
import { policy as NO } from "./NO";
import { policy as PL } from "./PL";
import { policy as PT } from "./PT";
import { policy as RU } from "./RU";
import { policy as SE } from "./SE";
import { policy as US } from "./US";
import { policy as BE_NL } from "./BE_NL";
import { policy as BE_EN } from "./BE_EN";
import { policy as BE_FR } from "./BE_FR";
import { policy as CN } from "./CN";
import { policy as CZ } from "./CZ";
import { policy as SK } from "./SK";
import { policy as HU } from "./HU";
import { policy as HR } from "./HR";
import { policy as RO } from "./RO";
import { policy as SI } from "./SI";
import { policy as RS } from "./RS";
import { policy as CA } from "./CA";
import { policy as DK } from "./DK";

export const policyContent = {
  AT,
  AU,
  CH,
  DE,
  ES,
  FI,
  FR,
  GB,
  IE,
  IN,
  IT,
  JP,
  KR,
  NL,
  NO,
  PL,
  PT,
  RU,
  SE,
  US,
  BE_NL,
  BE_EN,
  BE_FR,
  CN,
  CZ,
  SK,
  HU,
  HR,
  RO,
  SI,
  RS,
  CA,
  DK,
};
