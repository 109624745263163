import { type SnakeToCamelCaseNested } from "./snakeCamelCaseConverters";

// This handles basic cases but if we ever use abbreviations it also slides them apart: thisIsSPARTA => this_is_s_p_a_r_t_a
export function camelToSnake(key: string) {
  let result = (key.charAt(0) || "").toLowerCase();

  for (let i = 1; i < key.length; i++) {
    const ch = key.charAt(i);
    result += ch === ch.toUpperCase() ? "_" + ch.toLowerCase() : ch;
  }

  return result;
}

/**
 * Takes an object with snake_case properties and proxies it so that it can be accessed with camelCase property names.
 * @param snakeCased An object with snake_case properties
 */
export function camelProxy<T extends object>(snakeCased: T) {
  return new Proxy<T>(snakeCased, {
    get: (target, key: string) => (target as any)[camelToSnake(key)],
  }) as SnakeToCamelCaseNested<T>;
}

export function deepCopyAndConvertToSnakeCase(arrayOfObjects: any[]) {
  function convertObjectToSnakeCase(obj: any) {
    const newObj: { [key: string]: any } = {}; // Add index signature to newObj
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const snakeKey = camelToSnake(key);
        newObj[snakeKey] =
          typeof obj[key] === "object" && obj[key] !== null
            ? convertObjectToSnakeCase(obj[key])
            : obj[key];
      }
    }
    return newObj;
  }

  const copiedArray = [];
  for (const obj of arrayOfObjects) {
    const snakeCaseObject = convertObjectToSnakeCase(obj);
    copiedArray.push(JSON.parse(JSON.stringify(snakeCaseObject)));
  }

  return copiedArray;
}
