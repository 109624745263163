import React from "react";
import { ReactComponent as InformationCircle } from "../assets/svg/information-circle.svg";
import styled, { css } from "styled-components";
import { Trans } from "@coworker/locales";
import { BlueLink } from "./Link";
import { ElidedText } from "@coworker/reusable/components/ElidedText";
import { useTaskListFilters } from "./InputPopup/TaskListFilters";

const extraCSS = css`
  margin: 24px 24px 24px 22px;
  width: 70%;
`;

const StyledInformationCircle = styled(InformationCircle)`
  color: var(--blue-medium);
  width: 14px;
  height: 14px;
  aspect-ratio: 1 / 1;
`;

const StyledElidedText = styled(ElidedText)`
  text-align: left;
`;

const Subtext = styled.div`
  color: var(--grey718);
  font-weight: normal;
`;

/**
 * @param {{ relatedTasksCount:number|"", ofWhichClosed?:number|"",productId:string, i18nKey?:string }}
 */
export function RelatedTasksLink({
  relatedTasksCount,
  ofWhichClosed,
  productId,
  i18nKey,
}) {
  const { resetTaskListFilters } = useTaskListFilters();

  if (!relatedTasksCount) return null;

  const to = relatedTasksCount ? `/tasks/related/${productId}` : "";
  return (
    <>
      <StyledInformationCircle />
      <BlueLink addon css={extraCSS} to={to} onClick={resetTaskListFilters}>
        <StyledElidedText>
          {ofWhichClosed && ofWhichClosed !== relatedTasksCount ? (
            <>
              <div>
                <Trans count={relatedTasksCount}>
                  {i18nKey || "relatedTasksCountString"}
                </Trans>
              </div>
              <Subtext>
                <Trans count={ofWhichClosed}>ofWhichRecentlyClosedString</Trans>
              </Subtext>{" "}
            </>
          ) : ofWhichClosed ? (
            <Trans count={ofWhichClosed}>onlyRecentlyClosedString</Trans>
          ) : (
            <Trans count={relatedTasksCount}>
              {i18nKey || "relatedTasksCountString"}
            </Trans>
          )}
        </StyledElidedText>
      </BlueLink>
    </>
  );
}
