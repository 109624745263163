import posed from "react-pose";
import { type FixaWindow, isRDTDevice } from "@coworker/reusable";
import React from "react";

const duration = isRDTDevice() || (window as FixaWindow).Cypress ? 0 : 300;

const posedConfig = {
  enter: { y: 0, transition: { ease: "easeIn", duration } },
  exit: { y: "100%", transition: { ease: "easeOut", duration } },
};

export function revealFromBelow<T>(Component: React.FC<T>) {
  return posed(Component)(posedConfig) as React.FC<T>;
}

export function animatedCurtain<T>(
  Component: React.ComponentType<T>,
  height = 60
) {
  return posed(Component)({
    draggable: "y",
    dragBounds: { top: -100, bottom: 0 },
    dragEnd: { transition: posed["inertia"] },
    hide: { y: "-100%" },
    enter: { y: 0, transition: { ease: "easeIn", duration } },
    exit: { y: -height, transition: { ease: "easeIn", duration } },
  });
}
