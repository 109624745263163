import React from "react";
import styled from "styled-components";
import { TextArea, Button } from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import { useTextAreaPopup } from "./_common";
import { FULLSCREEN_MAX_TEXT_LENGTH } from "../../constants/textInput";

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 106px;
  padding-bottom: 10px;
`;

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
  top: 0;
  left: 0;
  background: var(--white);
  margin-top: 50px;
  overflow-y: auto;
`;
const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: bold;
  color: var(--grey900);
  margin: 55px 29px;
  margin-bottom: 0px;
`;
const StyledButton = styled(Button)`
  margin: 21px 19px 19px 30px;
  width: 100%;
`;
const Grower = styled.div`
  flex-grow: 1;
`;

const StyledArea = styled(TextArea)`
  flex-grow: 1;
  overflow-y: auto;
`;
const Description = (props) => {
  const [value, setValue] = React.useState(props.initialValue || "");
  const containerRef = React.useRef(null);
  const { textArea, onFocus, onBlur } = useTextAreaPopup(containerRef, value);
  const { t } = useTranslation();

  return (
    <Container ref={containerRef} data-testid="descriptionModal">
      <Title>{props.descriptionTitle}</Title>
      <Grower />
      <StyledArea
        data-testid="descriptionInput"
        maxLength={FULLSCREEN_MAX_TEXT_LENGTH}
        placeholder={t("additionalString")}
        onChange={(e) => setValue(e.target.value)}
        ref={textArea}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <Grower />
      <ButtonWrapper>
        <StyledButton
          customMargin
          type="submit"
          text={<Trans>continue2String</Trans>}
          primary={true}
          dark={true}
          disabled={!props.optional ? !value : false}
          onClick={(event) => {
            event.preventDefault();
            props.onSubmit(value);
          }}
          data-testid="descriptionButton"
        />
      </ButtonWrapper>
    </Container>
  );
};

export default Description;
