import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <p>Declarație de confidențialitate </p>,
  aboutFixaText: (
    <div>
      Fixa este un instrument de gestionare a sarcinilor și de merchandising
      pentru angajații din magazine. Scopul aplicației FIXA este acela de a
      înlocui activitățile manuale, pe hârtie sau pe bază de Excel și de a
      eficientiza gestionarea sarcinilor în magazine (recompletarea articolelor,
      curățare, abateri, teste de cumpărare, întrebări frecvente etc.). De
      asemenea, Fixa permite generarea de analize pe baza informațiilor obținute
      din sarcinile finalizate.
      <br />
      <br />
      Atunci când utilizezi Fixa, operatorul de date al companiei din cadrul
      Grupului Ingka (așa cum este definit mai jos) va colecta anumite date cu
      caracter personal despre tine.
    </div>
  ),

  personalDataCollectedTitle: <p>Datele cu caracter personal colectate:</p>,
  personalDataCollectedText: (
    <div>
      Atunci când utilizezi FIXA, sistemul va colecta informații despre tine din
      baza de date a angajaților (Co-Worker Database – CDS), cum ar fi
      prenumele, al doilea prenume, numele de familie, adresa de e-mail,
      denumirea postului, funcția, numărul de telefon (dacă este furnizat
      manual, nu trebuie introdus numărul de telefon personal), fotografia din
      CDS, preferința lingvistică din aplicație, ID-ul magazinului, ID-ul țării,
      versiunea dispozitivului și a sistemului de operare, adresa IP procesată
      în Google Cloud Platform și Sentry (Sentry este un software de urmărire a
      erorilor din aplicații).
      <br />
      <br />
      Profilul de angajat este creat manual prin Fixa-admin (o aplicație web de
      administrare) de către manager/superutilizator. Datele cu caracter
      personal sunt preluate din CDS, deci se pot crea profiluri doar pentru
      angajații care au un profil activ în CDS.
    </div>
  ),
  purposeOfDataCollectionTitle: <p>Scopul colectării acestor date:</p>,
  purposeOfDataCollectionText: (
    <p>
      Scopul colectării de date cu caracter personal în Fixa este asigurarea
      funcționării adecvate a aplicației, astfel încât să permită comunicarea
      internă ușoară între angajați și administrarea sarcinilor între angajații
      din magazin. Conectarea sarcinilor cu un utilizator ne permite să folosim
      notificări pentru actualizări privind sarcinile pentru utilizatorii cărora
      le sunt atribuite și creatori.
    </p>
  ),
  legalGroundsTitle: <p>Temeiul juridic al prelucrării:</p>,
  legalGroundsText: (
    <p>Temeiul juridic al prelucrării este interesul legitim.</p>
  ),
  dataWillBeStoredTitle: <p>Cât timp sunt păstrate datele:</p>,
  dataWillBeStoredText: (
    <div>
      Stocăm sarcinile finalizate cu referire la utilizatori timp de 70 de zile,
      după care acestea sunt anonimizate. Datele cu caracter personal ale unui
      angajat sunt stocate în Fixa pe durata contractului său de muncă.
      <br />
      <br />
      Încetarea contractului de muncă cu Grupul Ingka: <br />
      Toate datele cu caracter personal sunt șterse automat în termen de o lună
      de la eliminarea profilului din CDS.
      <br />
      <br />
      Schimbarea funcției în cadrul Grupului Ingka: Dacă un angajat nu mai are
      nevoie să utilizeze Fixa, toate datele sale cu caracter personal vor fi
      șterse automat după 3 luni de inactivitate.
      <br />
      <br />
      De asemenea, datele cu caracter personal pot fi șterse în orice moment
      prin adresarea unei solicitări către liderul local pentru securitatea
      informațiilor și confidențialitatea datelor sau către managerul de
      magazin.
      <br />
      <br />
    </div>
  ),

  dataControllerForPersonalDataTitle: (
    <p>
      Operatorul de date pentru această prelucrare a datelor cu caracter
      personal este:{" "}
    </p>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA Romania, Şos. Bucureşti Ploieşti Nr. 42A
      <br />
      Sector 1, Bucureşti
      <br />
      <br />
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      Beneficiezi de anumite drepturi în ceea ce privește datele tale cu
      caracter personal. Vizitează pentru a afla mai multe despre aceste
      drepturi.
      <br />
      <br />
      Informații de contact:{" "}
      <EmailLink email="data.protection.ro@ingka.ikea.com" />
    </div>
  ),
};
