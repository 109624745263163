import React from "react";
import styled from "styled-components";
import { ReactComponent as Icon } from "./svg/loader.svg";

export const LoaderIcon = styled(Icon)`
  width: 40px;
  height: 40px;
`;

export const PositionedLoaderIcon = styled(Icon)`
  width: 40px;
  height: 40px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ShortLine = styled.div`
  background-image: linear-gradient(to top, var(--grey100), var(--grey150));
  width: 114px;
  height: 15px;
  border-radius: 8px;
  padding-left: 29px;
`;

// $topMargin is a transient prop that styled-components does not pass down to the DOM
// https://styled-components.com/docs/api#transient-props (avoids error in tests)
const Skeleton = styled.div<{ $topMargin: number }>`
  margin-top: ${({ $topMargin = 15 }) => $topMargin || 15}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TwoShortLinesSkeleton = ({ topMargin }: { topMargin: number }) => (
  <Skeleton $topMargin={topMargin}>
    <ShortLine />
    <ShortLine />
  </Skeleton>
);
