import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";

/**
 * @param {number | ""} number
 */
export function textForStockItemsAmount(amount) {
  if (amount === "") return <Trans>noStockInfoString</Trans>;
  if (amount === 0) return <Trans>notStockTitle</Trans>;
  if (amount > 0 && amount < Number.MAX_SAFE_INTEGER)
    return <Trans>inStockTitle</Trans>;
  else return "";
}

const StockBadge = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  background: ${({ color }) => `var(${color})`};
`;

export function StockIndicator({
  color = "transparent",
  size = 10,
  marginRight = 0,
  marginTop = 0,
  marginLeft = 0,
}) {
  return (
    <StockBadge
      color={color}
      size={size}
      marginRight={marginRight}
      marginTop={marginTop}
      marginLeft={marginLeft}
      data-testid={"stock-indicator" + color}
    />
  );
}
