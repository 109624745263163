const ASIA_STORELIST =
  "AU 006 377 451 460 919 , JP 189 359 392 447 448 486 496 509 647 651 671 887 , KR 373 522 582 602 , IN 501 545 578 593 629 ";
const US_STORELIST =
  "US 026 027 028 042 064 067 103 145 152 153 154 156 157 158 162 165 166 167 168 170 175 183 207 209 210 211 212 213 215 327 347 374 379 399 409 410 411 462 488 508 511 535 536 537 560 569 570 921 CA 216 256 257 529 ";

/**
 * Returns {region, projectId} for the production region that a store belongs to.
 */
async function prodConfigForStore(storeId) {
  // TODO: to be decided via Firestore /meta/globalConfig
  if (ASIA_STORELIST.includes(storeId)) {
    return { region: "asia-east2", projectId: "ingka-fixa-as-prod" };
  }
  if (US_STORELIST.includes(storeId)) {
    return { region: "us-west3", projectId: "ingka-fixa-us-prod" };
  }
  return { region: "europe-west1", projectId: "ikea-coworker-app-prod" };
}

function inMyRegionChecker(storeId) {
  if (!storeId) return true;
  if (typeof process === "undefined") return true; // If process goes missing, just let the whole list through, for now.
  const projectId = process?.env?.REACT_APP_PROJECT_ID;
  // TODO: fetch instead of using hardcoded lists.
  switch (projectId) {
    case "ingka-fixa-as-prod":
      return ASIA_STORELIST.includes(storeId);
    case "ingka-fixa-us-prod":
      return US_STORELIST.includes(storeId);
    default:
      return (
        !ASIA_STORELIST.includes(storeId) && !US_STORELIST.includes(storeId)
      );
  }
}

module.exports = { prodConfigForStore, inMyRegionChecker };
