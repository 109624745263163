import React from "react";

import { ArticleListItemActionRow } from "./ArticleListItemActionRow";
import { DeleteArticleModal } from "../Organisms/Modals/DeleteArticleModal";
import "../styles/RoomSettings.css";
import {
  Article,
  ArticleIdNbrArticles,
  ArticleInfo,
  ArticlesInfo,
  ArticleWithRoomCount,
  EdsData,
  SalesStopData,
  isArticleWithRoomCount,
} from "../types/article";
import "@ingka/commercial-message/dist/style.css";
import { Trans, useTranslation } from "@coworker/locales";
import { useQueryClient } from "@tanstack/react-query";
import { useRoomById } from "../hooks/useRoomById";
import { logEvent } from "../../../helpers/tracker";
import { useToastNotification } from "@coworker/components";
import { useCreateTask } from "../../../hooks/TaskActions/useCreateTask";
import { useUserId } from "../../../core/auth/useLoggedInUser";
import { createAutoGeneratedTaskAsCompleted } from "./ArticleListItemActionRow.helper";
import { mergeArticleInfoAndArticle } from "../types/mappers";
import { Reasons } from "../constants";
import { fetchArticlesInfo } from "../../../hooks/API/tasks.service.helper";
import { useInputPopup } from "../../InputPopup";
import { useUpdateArticle } from "../hooks/useUpdateArticle";
import { ArticleListItemInner } from "./ArticleListItemInner";
import { PleaseFillIn } from "../../Reusable/PleaseFillIn";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";

interface ArticleListItemProps {
  article: Article | ArticleWithRoomCount;
  isMainProduct?: (articleId: string) => boolean;
  isBackupForProduct?: (articleId: string) => boolean;
  hasOngoingTask?: (articleId: string) => boolean;
  showActionRow?: boolean;
  hideChevron?: boolean;
  edsData?: EdsData | null;
  salesStopData?: SalesStopData | null;
  selectArticleIdsMode?: boolean;
  isSelected?: boolean;
  onSelectArticleIdNbrArticles?: (
    articleIdNbrArticles: ArticleIdNbrArticles
  ) => void;
  onArticleUpdate?: (article: Article, isSkeleton: boolean) => void;
}

export function ArticleListItem({
  article,
  isMainProduct,
  isBackupForProduct,
  hasOngoingTask,
  showActionRow,
  hideChevron,
  edsData,
  salesStopData,
  selectArticleIdsMode,
  isSelected,
  onSelectArticleIdNbrArticles,
  onArticleUpdate,
}: ArticleListItemProps) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data: room } = useRoomById(article?.roomId || "");

  const [bottomAlertMessage, setBottomAlertMessage] =
    React.useState<string>("");
  const [currentNbrArticles, setCurrentNbrArticles] = React.useState<number>(
    article?.nbrArticles || 0
  );
  const [suppressItemClick, setSuppressItemClick] =
    React.useState<boolean>(false);
  const [trashcanModalOpen, setTrashcanModalOpen] = React.useState(false);

  const { showToast } = useToastNotification();
  const { call: createTask } = useCreateTask();
  const userId = useUserId();
  const { getInput } = useInputPopup();
  const { push, pop } = useWorkspacesAction();

  const updateArticle = useUpdateArticle(article.id);

  const handleQuantityChange = async (value: number) => {
    logEvent("ce:MM_articles_quantity_changed");
    if (selectArticleIdsMode && article && currentNbrArticles !== value) {
      setCurrentNbrArticles(value);
      if (value > article.nbrArticles) {
        setBottomAlertMessage(
          t("moveExpandedArticlesInfoString", { newCount: value })
        );
      } else if (value < article.nbrArticles) {
        setBottomAlertMessage(
          t("moveReducedArticlesInfoString", {
            moveCount: value,
            remainingCount: article.nbrArticles - value,
          })
        );
      } else {
        setBottomAlertMessage("");
      }
      if (!!onSelectArticleIdNbrArticles) {
        onSelectArticleIdNbrArticles({
          articleId: article.id,
          nbrArticles: value,
        });
      }
      return;
    }

    if (article.nbrArticles === value) return;
    try {
      article.nbrArticles = value;
      await updateArticle.mutateAsync({
        ...article, //Roomsettings-service expects a full article object when updating
        nbrArticles: value,
      });

      if (article.isFromArticleView) {
        await queryClient.invalidateQueries(["articlesByRoom"]);
        await queryClient.invalidateQueries(["roomsAndArticleCount"]);
        await queryClient.invalidateQueries([
          "articlesByRoomAndProductArticleId",
        ]);
      }
    } catch (_error) {
      alert(t("searchErrorString"));
    }
  };

  const createAutoGeneratedTaskWithReason = async (
    reason: Reasons,
    productArticleId: string
  ) => {
    if (!room) return;
    await createAutoGeneratedTaskAsCompleted(
      userId,
      reason,
      room.id,
      room.name,
      room.store_id,
      article,
      productArticleId,
      t("taskIsAutoCompletedNoteString"),
      createTask
    );
  };

  const handleReplaceArticle = async () => {
    const scanResult = await getInput("scansearch", {
      start: new Date(),
    });

    if (!!scanResult?.product) {
      onArticleUpdate && onArticleUpdate(article, true);

      const articlesInfo: ArticlesInfo = await fetchArticlesInfo(
        scanResult.product
      );

      if (articlesInfo.length > 0) {
        const newArticle = mergeArticleInfoAndArticle(
          article,
          articlesInfo[0] as ArticleInfo
        );
        await updateArticle.mutateAsync(newArticle);

        await createAutoGeneratedTaskWithReason(
          Reasons.REPLACE_PRODUCT,
          newArticle.productArticleId
        );

        await queryClient.invalidateQueries(["articlesByRoom"]);
        await queryClient.invalidateQueries([
          "articlesByGroup",
          { id: article.groupId },
        ]);
        onArticleUpdate && onArticleUpdate(newArticle, false);

        showToast(<Trans>articleReplacedString</Trans>);
      } else {
        onArticleUpdate && onArticleUpdate(article, false);
      }
    }
  };

  if (!article) return <></>;

  const isActionRowVisible =
    selectArticleIdsMode && !isSelected ? false : showActionRow;

  return (
    <>
      <ArticleListItemInner
        article={article}
        isMainProduct={(isMainProduct && isMainProduct(article.id)) || false}
        isBackupForProduct={
          (isBackupForProduct && isBackupForProduct(article.id)) || false
        }
        hasOngoingTask={(hasOngoingTask && hasOngoingTask(article.id)) || false}
        showActionRow={showActionRow || false}
        hideChevron={hideChevron || false}
        edsData={edsData}
        salesStopData={salesStopData}
        selectArticleIdsMode={selectArticleIdsMode || false}
        isSelected={isSelected || false}
        onSelectArticleIdNbrArticles={onSelectArticleIdNbrArticles}
        currentNbrArticles={currentNbrArticles}
        onClick={() => {
          if (hideChevron || selectArticleIdsMode || suppressItemClick) {
            setSuppressItemClick(false);
            return;
          }
          if (isArticleWithRoomCount(article)) {
            push(`/roomsettings/articles/search/${article.productArticleId}`);
          } else {
            push(`/roomsettings/articles/${article.id}`);
          }
        }}
      >
        {isActionRowVisible && (
          <ArticleListItemActionRow
            defaultValue={article?.nbrArticles || 0}
            onQuantityChange={(value) => handleQuantityChange(value)}
            selectArticleIdsMode={selectArticleIdsMode || false}
            onReplaceArticle={async () => {
              await handleReplaceArticle();
              logEvent("ce:MM_articles_selected_switchproduct");
            }}
            onDeleteArticle={() => {
              setTrashcanModalOpen(true);
              setSuppressItemClick(true);
              logEvent("ce:MM_articles_selected_deleteproduct");
            }}
          />
        )}
        {!!bottomAlertMessage && isSelected && (
          <div
            style={{
              display: "flex",
              gap: "16px",
              marginTop: "8px",
              marginLeft: "68px",
            }}
          >
            <PleaseFillIn
              show={!!bottomAlertMessage}
              alternateWarning={bottomAlertMessage}
            />
          </div>
        )}
      </ArticleListItemInner>
      <DeleteArticleModal
        modalOpen={trashcanModalOpen}
        article={article}
        onModalOpenChange={setTrashcanModalOpen}
        postDeleteFunction={async () => {
          await createAutoGeneratedTaskWithReason(Reasons.REMOVE_ARTICLE, "");
          showToast(<Trans>articleDeletedString</Trans>);
          await queryClient.invalidateQueries(["articlesByRoom"]);
          await queryClient.invalidateQueries(["articlesByGroup"]);
          if (article.isFromArticleView) {
            pop();
          }
        }}
        deleteByArticleId={true}
      />
    </>
  );
}
