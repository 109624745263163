import React from "react";
import { useTranslation } from "@coworker/locales";
import { useParams } from "react-router";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { fullRoomName } from "../tools";
import { AppBarAction } from "@coworker/apprestructured/src/layout/components/AppBar/AppBar";
import SSRIcon from "@ingka/ssr-icon";
import SettingsIcon from "@ingka/ssr-icon/paths/gear";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { useRoomById } from "../hooks/useRoomById";
import { RoomViewSkeleton } from "../Skeletons/RoomViewSkeleton";
import { useImagesByRoomId } from "../hooks/useImagesByRoomId";
import { RoomImagesCarousel } from "../Molecules/RoomImagesCarousel";
import styled from "styled-components";
import Image from "@ingka/image";
import { UploadImageButton } from "../Molecules/UploadImageButton";
import { RoomTabs } from "../Organisms/RoomTabs";
import { ModalTypes } from "../types/views";
import { FullWidthButton } from "../../SkapaButton";
import { PleaseFillIn } from "../../Reusable/PleaseFillIn";
import { ArticleIdNbrArticles, Articles } from "../types/article";
import { RoomArticlesModals } from "../Organisms/Modals/RoomArticlesModals";
import { Group } from "../types/groups";
import { useInputPopup } from "../../InputPopup";
import { useQueryClient } from "@tanstack/react-query";
import { useGroupsByRoomId } from "../hooks/useGroupsByRoomId";
import { useArticlesByRoomId } from "../hooks/useArticlesByRoomId";

const SVGResizer = styled.div`
  .image__icon {
    width: 140px;
    height: 140px;
  }
`;

export const TabbedRoomView = () => {
  const { roomId } = useParams();
  const { t } = useTranslation();
  const { push } = useWorkspacesAction();
  const { getInput } = useInputPopup();
  const queryClient = useQueryClient();

  const { data: room } = useRoomById(roomId || "");
  const { data: roomImages } = useImagesByRoomId(roomId || "");
  const { data: groups } = useGroupsByRoomId(roomId ?? "");

  const { data: activeArticles } = useArticlesByRoomId(
    room?.id || "",
    "ACTIVE"
  );

  const [modalVisibleByType, setModalVisibleByType] =
    React.useState<ModalTypes>(ModalTypes.DEFAULT_VIEW);
  const [currentTab, setCurrentTab] =
    React.useState<string>("article-list-tab");
  const [selectedArticles, setSelectedArticles] = React.useState<Articles>([]);
  const [isSelectArticlesMode, setIsSelectArticlesMode] = React.useState(false);
  const [warningText, setWarningText] = React.useState("");

  const selectedArticleIdsToArticles = (
    selectedArticleIdsNbArticles: ArticleIdNbrArticles[]
  ) => {
    return !activeArticles || !selectedArticleIdsNbArticles
      ? []
      : (selectedArticleIdsNbArticles
          .map((item) => {
            const foundArticle = activeArticles.find(
              (article) => article.id === item.articleId
            );
            if (foundArticle) foundArticle.newNbrArticles = item.nbrArticles;
            return foundArticle;
          })
          .filter((article) => !!article) as Articles);
  };

  let fetchSelectedArticleIdsFunction: () => ArticleIdNbrArticles[] = () => [];

  const appBarActions: AppBarAction[] = [
    {
      name: "settings",
      icon: <SSRIcon paths={SettingsIcon} />,
      onClick: () => push(`/roomsettings/${room?.id}/settings`),
      position: "right",
    },
  ];

  const roomHeader = fullRoomName(
    room?.name,
    room?.main_article_name_1,
    room?.main_article_name_2
  );
  if (!room) {
    return (
      <FullScreenPopup
        appBarConfig={{
          title: roomHeader,
          actions: appBarActions,
          onBack: () => {
            push("/roomsettings");
          },
        }}
        noPadding
      >
        <RoomViewSkeleton />
      </FullScreenPopup>
    );
  }

  return (
    <FullScreenPopup
      isActionBarHiddenOnScroll={false}
      actionBarContent={
        <>
          {currentTab === "groups-tab" && (
            <FullWidthButton
              text={t("createGroupString")}
              onClick={() => setModalVisibleByType(ModalTypes.CREATE_GROUP)}
              type={"primary"}
            />
          )}
          {currentTab === "article-list-tab" && !isSelectArticlesMode && (
            <FullWidthButton
              text={t("addArticlesString")}
              onClick={async () => {
                await getInput("addMultipleArticles", {
                  roomId,
                  groupId: "",
                });
                await queryClient.invalidateQueries(["articlesByRoom"]);
              }}
              type={"primary"}
            />
          )}
          {currentTab === "article-list-tab" && isSelectArticlesMode && (
            <>
              <PleaseFillIn
                show={!!warningText}
                alternateWarning={warningText}
                centerText={true}
              />
              <FullWidthButton
                text={t("continue2String")}
                onClick={async () => {
                  const selectedArticleIds = fetchSelectedArticleIdsFunction();
                  if (selectedArticleIds.length > 0) {
                    setModalVisibleByType(ModalTypes.SELECTED_ARTICLES_ACTIONS);
                    setSelectedArticles(
                      selectedArticleIdsToArticles(selectedArticleIds)
                    );
                  } else {
                    setWarningText(t("selectArticlesToContinueString"));
                  }
                }}
                type={"primary"}
              />
            </>
          )}
        </>
      }
      appBarConfig={{
        title: roomHeader,
        actions: appBarActions,
        onBack: () => {
          push("/roomsettings");
        },
      }}
      noPadding
    >
      <>
        {roomImages && roomImages.length > 0 ? (
          <RoomImagesCarousel room={room} />
        ) : (
          <div style={{ position: "relative", display: "inline-block" }}>
            {roomImages && roomImages.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "80%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1,
                }}
              >
                <SVGResizer>
                  <Image />
                </SVGResizer>
                <UploadImageButton
                  roomId={room.id}
                  text={t("addPhotoString")}
                />
              </div>
            )}
          </div>
        )}
        <RoomTabs
          room={room}
          activeArticles={activeArticles || []}
          isSelectArticlesMode={isSelectArticlesMode}
          getSelectedArticleIds={(
            fetchFunction: () => ArticleIdNbrArticles[]
          ) => {
            fetchSelectedArticleIdsFunction = fetchFunction;
          }}
          setSelectArticleMode={() => {
            setIsSelectArticlesMode(!isSelectArticlesMode);
            setWarningText("");
          }}
          allArticlesInRoom={activeArticles || []}
          onCurrentTabChange={(tab: string) => setCurrentTab(tab)}
          groups={groups || []}
        />
        {modalVisibleByType !== ModalTypes.DEFAULT_VIEW && (
          <RoomArticlesModals
            selectedArticles={selectedArticles}
            allArticles={activeArticles || []}
            currentModalType={modalVisibleByType}
            onModalTypeChange={(modalType: ModalTypes) => {
              if (modalType === ModalTypes.DEFAULT_VIEW) {
                setIsSelectArticlesMode(false);
              }
              setWarningText("");
              setModalVisibleByType(modalType);
            }}
            groups={groups || []}
            storeId={room.store_id}
            roomId={room.id ?? ""}
            currentGroup={{} as Group}
          />
        )}
      </>
    </FullScreenPopup>
  );
};
