import React from "react";
import styled from "styled-components";
import {
  RefillAmountSkeleton,
  WideSkeleton,
  Loader,
} from "@coworker/components";
import { getTextWidth } from "../helpers";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

const GraphContainerWrapper = styled.div``;

const RightText = styled.div.attrs(({ textWidth }) => ({
  style: {
    minWidth: `${textWidth + 3}px`,
  },
}))`
  margin-left: 24px;
  text-align: right;
`;

const BarContainer = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 8px;
`;

const TaskContainer = styled.div`
  width: 100%;
`;
const RefillBarsContainer = styled.div`
  width: 100%;
  font-size: 12px;
  color: var(--grey718);
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  margin: 8px 16px;
`;
const LeftText = styled.div`
  text-align: center;
  min-width: 70px;
  padding-right: 8px;
  ${({ isHighlighted }) => isHighlighted && "font-weight: bold"}
`;

const RedBarWrapper = styled.div.attrs(({ $previousPercent }) => ({
  style: { width: `${$previousPercent}%` },
}))`
  height: 100%;
  border-radius: inherit;
  background: var(--grey150);
`;

const BarFiller = styled.div.attrs(
  ({ currentPercent, isGreen, isHighlighted }) => ({
    style: {
      width: `${currentPercent}%`,
      backgroundColor: `var(${
        isHighlighted ? "--new" : isGreen ? "--success" : "--error"
      })`,
    },
  })
)`
  border-radius: inherit;
  height: 100%;
`;

const HangLeft = styled.span`
  margin-left: -12px;
  margin-right: 8px;
`;

const RedBar = ({ previousPercent, currentPercent }) => (
  <RedBarWrapper $previousPercent={previousPercent}>
    <BarFiller currentPercent={(100.0 * currentPercent) / previousPercent} />
  </RedBarWrapper>
);

function renderBar(barWidths, isHighlighted) {
  const [primaryBarWidth, secondaryBarWidth] = barWidths;
  if (!primaryBarWidth && !secondaryBarWidth) return null;
  if (!secondaryBarWidth || secondaryBarWidth <= primaryBarWidth)
    return (
      <BarFiller
        isGreen
        isHighlighted={isHighlighted}
        currentPercent={primaryBarWidth}
      />
    );
  return (
    <RedBar
      currentPercent={primaryBarWidth}
      previousPercent={secondaryBarWidth}
    />
  );
}

const BarLabel = ({ label, isHighlighted }) => {
  return (
    <LeftText isHighlighted={isHighlighted}>
      {isHighlighted ? <HangLeft>•</HangLeft> : null}
      {label}
    </LeftText>
  );
};

/**
 * @param {boolean} loading
 * @param {array} bars
 * @param {array?} tasksOnQuestion
 * @param {boolean} displayTasksOnGraph
 * @returns {JSX.Element}
 */
function GraphContainer({
  loading,
  bars,
  tasksOnQuestion,
  formatDate,
  displayTasksOnGraph,
}) {
  const isPartial = React.useMemo(() => {
    return bars?.some?.(({ isPartial }) => isPartial);
  }, [bars]);

  const textWidth = React.useMemo(() => {
    return Math.max(
      ...bars.map(({ formattedPrimaryValue }) =>
        getTextWidth(`${formattedPrimaryValue}${isPartial && "*"}`)
      )
    );
  }, [bars, isPartial]);
  return (
    <GraphContainerWrapper data-testid="graph_container">
      {loading ? (
        <Loader>
          <RefillAmountSkeleton /> <WideSkeleton />
        </Loader>
      ) : (
        <RefillBarsContainer>
          {bars.map(
            ({
              key,
              label,
              formattedPrimaryValue,
              primaryBarWidth,
              secondaryBarWidth,
              isHighlighted,
              isPartial,
            }) => (
              <React.Fragment key={key}>
                <Row>
                  <BarLabel label={label} isHighlighted={isHighlighted} />
                  <BarContainer>
                    {renderBar(
                      [primaryBarWidth, secondaryBarWidth],
                      isHighlighted
                    )}
                  </BarContainer>
                  <RightText textWidth={textWidth}>
                    {formattedPrimaryValue}
                    {isPartial && "*"}
                  </RightText>
                </Row>
                {displayTasksOnGraph && (
                  <TasksOnGraph
                    interval={key}
                    tasksOnQuestion={tasksOnQuestion}
                    formatDate={formatDate}
                  />
                )}
              </React.Fragment>
            )
          )}
        </RefillBarsContainer>
      )}
    </GraphContainerWrapper>
  );
}

function TasksOnGraph({ tasksOnQuestion, formatDate, textWidth, interval }) {
  if (!tasksOnQuestion?.length) return null;

  const tasksMatchingInterval = tasksOnQuestion.filter((task) => {
    if (
      interval === task.created_at ||
      interval === task.finished_at ||
      interval === task.weekNumber
    ) {
      return task;
    }
  });

  if (!tasksMatchingInterval.length) return null;

  const getTaskDate = (task) => {
    return formatDate(
      task.state === taskStateOptions.COMPLETED ||
        task.state === taskStateOptions.CLOSED
        ? task.finished_at
        : task.created_at
    );
  };
  // CLOSED - Summary (Closed) || Summary (Completed) || Description
  const getTaskDetail = (task) => {
    return task.state === taskStateOptions.COMPLETED ||
      task.state === taskStateOptions.CLOSED
      ? `${task.state === taskStateOptions.CLOSED ? `${task.state} - ` : ""}${
          task.finished_summary
        }`
      : task.description;
  };

  return tasksMatchingInterval.map((task, index) => (
    <Row key={index}>
      <BarLabel label={getTaskDate(task)} />
      <TaskContainer>{getTaskDetail(task)}</TaskContainer>
      <RightText textWidth={textWidth} />
    </Row>
  ));
}

export default GraphContainer;
