import React from "react";
import styled, { css } from "styled-components";
import TaskCardGroupColorBand from "./TaskCardGroupColorBand";

const Container = styled.div`
  padding: 30px 28px;
  display: flex;
  align-items: center;
  min-height: 61px;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  ${({ padLeft }) =>
    padLeft &&
    css`
      padding-left: 65px;
    `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const Title = styled.h1`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  color: var(--grey900);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Grow = styled.div`
  flex-grow: 1;
`;

export function TaskCard({
  title,
  titleIcons,
  avatar,
  badges,
  subtitles,
  indicators,
  padLeft,
  groupByColor,
  isLast,
  trackClick,
}) {
  return (
    <Container padLeft={padLeft} onClick={trackClick}>
      <TaskCardGroupColorBand color={groupByColor} isLast={isLast} />
      <ContentContainer>
        {badges}
        <Title data-testid="task_title">
          {title}
          {!!titleIcons && (
            <>
              <Grow />
              {titleIcons}
            </>
          )}
        </Title>
        {subtitles}
        {indicators}
      </ContentContainer>
      {avatar}
    </Container>
  );
}
