import { create } from "zustand";
import { extendWithGetters } from "@coworker/reusable/helpers/zustandHelpers";

interface EditStatusState {
  editStatus: boolean;
}

const store = extendWithGetters(
  create<EditStatusState>()(() => ({
    editStatus: false,
  }))
);

export function useInsightsEditStatus() {
  return {
    ...store.getters,
    setEditStatus: (editStatus: boolean) => store.setState({ editStatus }),
  };
}
