import React from "react";
import { MediumSkeletonBox } from "../Molecules/skeletons/MediumSkeletonBox";
import { MediumSkeletonListItem } from "../Molecules/skeletons/MediumSkeletonListItem";

export const RoomsViewSkeleton = () => {
  return (
    <>
      <MediumSkeletonBox />

      {[...Array(4)].map((_, index) => (
        <MediumSkeletonListItem key={index} />
      ))}
    </>
  );
};
