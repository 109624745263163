import { ChartOptions } from "chart.js";

export const chartOptions: ChartOptions<"pie"> = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0,
  },
  events: [],
  plugins: {
    legend: {
      position: "right",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        boxHeight: 10,
        boxWidth: 10,
        padding: 15,
      },
    },
  },
};
