import { callInternalApi } from "@coworker/app/src/hooks/API/useCallInternal";
import { useQuery } from "@tanstack/react-query";
import {
  useFixaUID,
  useStoreId,
  useTeamId,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { TaskTabsTypes } from "../../shared/constants/taskTabsTypes";
import { methodForTaskList } from "@coworker/app/src/services/tasks.service";

async function fetchTasks(
  taskTabsType: TaskTabsTypes,
  fixaUid: string,
  teamId?: string,
  storeId?: string
) {
  const method =
    taskTabsType && methodForTaskList(taskTabsType, teamId as string);
  const result = await callInternalApi(method, {
    fixaUid,
    store_id: storeId,
    team_id: teamId,
  });
  return result?.data;
}
export function useTasksQuery(taskTabsType: TaskTabsTypes) {
  const teamId = useTeamId();
  const fixaUid = useFixaUID();
  const storeId = useStoreId();
  return useQuery({
    queryKey: ["tasks", taskTabsType, fixaUid, teamId, storeId],
    queryFn: async () =>
      await fetchTasks(taskTabsType, fixaUid, teamId, storeId),
    staleTime: 0, // Since this data does not change frequently, it can stay fresh for a long time
  });
}
