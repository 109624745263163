import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";
import { PaddedContainer } from "../styles/styles";

interface SearchResultCountTextProps {
  count: number;
}

export function SearchResultCountText({ count }: SearchResultCountTextProps) {
  const { t } = useTranslation();

  return (
    <PaddedContainer $py="8px" $px="24px">
      <Text text={`${t("searchResultString", { count })}`} grey />
    </PaddedContainer>
  );
}
