import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { describeAreaFilter } from "../../InputPopup/UserFiltersRow/filterChoices";
import { sortUpvotesByArea } from "./QuestionsDetailPage.helper";
import { VotesByArea } from "../BackendApiRepository/QuestionResponse";

const OtherAreas = styled.div`
  margin-bottom: 8px;

  & > p {
    padding: 17px 24px;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
  }
`;

const AreaText = styled.strong`
  flex: 8;
`;

const AreaCount = styled.strong`
  flex: 2;
`;

const AreaList = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: 24px;
`;

const AreaListItem = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding-block: 16px;
`;

const HeaderItem = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  font-size: 14px;
`;

interface OtherAreasQuestionProps {
  upVotesByArea: VotesByArea[];
}

export default function OtherAreasComponent({
  upVotesByArea,
}: OtherAreasQuestionProps) {
  const upVotesByAreaSorted = sortUpvotesByArea(upVotesByArea);
  return (
    <OtherAreas>
      <p>
        <Trans>allAreasString</Trans>
      </p>
      <AreaList>
        <HeaderItem>
          <AreaCount>
            <Trans>voteCountString</Trans>
          </AreaCount>
          <AreaText>
            <Trans>areaString</Trans>
          </AreaText>
        </HeaderItem>
        {upVotesByAreaSorted?.map((areaUpvotes: VotesByArea) => {
          return (
            <AreaListItem key={areaUpvotes.area}>
              <AreaCount>{areaUpvotes.votes_unresolved}</AreaCount>
              <AreaText>
                <Trans>{describeAreaFilter([areaUpvotes?.area], true)}</Trans>
              </AreaText>
            </AreaListItem>
          );
        })}
      </AreaList>
    </OtherAreas>
  );
}
