import React from "react";
import { Trans } from "@coworker/locales";
import BasicWidget from "@coworker/components/src/components/BasicWidget";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import {
  describeAreaFilter,
  defaultFilters,
} from "../../InputPopup/UserFiltersRow/filterChoices";

import { ReactComponent as Location } from "../../../assets/svg/location.svg";

const { MFAQ_PREFIX, USER_FILTERS } = profilePreferences;

export function TaskFormMfaqArea({ area }) {
  const [filters] = useUserPreference(
    MFAQ_PREFIX + USER_FILTERS,
    defaultFilters
  );
  const isPickupOrComplete = area;
  const areaForQuestion = describeAreaFilter(isPickupOrComplete ?? filters);

  return (
    <BasicWidget
      testId="task-creation-mfaq-area"
      title={areaForQuestion}
      text={!isPickupOrComplete && <Trans>questionsAskedString</Trans>}
      icon={isPickupOrComplete && <Location />}
    />
  );
}
