import React from "react";
import { isFinishedState } from "../constants/taskState";
import { Trans } from "@coworker/locales";
import { Badge } from "@coworker/components";
import styled, { css } from "styled-components";
import taskTypes from "@coworker/functions/src/enums/taskType.json";
import { isOverdue } from "../helpers/taskHelper";

const Row = styled.div`
  display: flex;
`;

const PriorityBadge = styled(Badge)`
  ${({ isPQR }) =>
    isPQR
      ? css`
          background-color: var(--pink);
          color: var(--white);
        `
      : css`
          background-color: var(--yellow);
        `}
  ${(props) =>
    props.isClosed &&
    css`
      background: var(--grey200);
    `}
`;

const OverdueBadge = styled(Badge)`
  background-color: var(--pink);
  color: var(--white);
  &:not(:first-child) {
    margin-left: 6px;
  }
`;

const LastInSeriesBadge = styled(Badge)`
  background-color: var(--light-blue);
  color: var(--white);
  &:not(:first-child) {
    margin-left: 6px;
  }
`;

export default function Badges({ task, skipFinishedState = false }) {
  const isProductQualityReport = task.task_type === taskTypes.PRODUCT_QUALITY;
  return (
    <Row>
      {task.priority_flag && (
        <PriorityBadge
          isClosed={!skipFinishedState && isFinishedState(task.state)}
          isPQR={isProductQualityReport}
        >
          <Trans>
            {isProductQualityReport ? "safetyAlarmString" : "priorityString"}
          </Trans>
        </PriorityBadge>
      )}
      {isOverdue(task) &&
        (!isFinishedState(task.state) || skipFinishedState) && (
          <OverdueBadge>
            <Trans>overdueString</Trans>
          </OverdueBadge>
        )}
      {task.last_occurrence && (
        <LastInSeriesBadge>
          <Trans>lastOccurrenceString</Trans>
        </LastInSeriesBadge>
      )}
    </Row>
  );
}
