import React from "react";
import styled from "styled-components";

const GroupColorBandComponent = styled.div`
  height: 100%;
  width: 0.5rem;
  margin-left: 0.5rem;
  background-color: var(--${(props) => props.color});
  position: absolute;
  left: 0%;
  top: 0%;
`;

const LastGroupColorBandComponent = styled(GroupColorBandComponent)`
  height: 90%;
  border-radius: 0px 0px 30px 30px;
`;

function TaskCardGroupColorBand({ color = "", isLast }) {
  return !isLast ? (
    <GroupColorBandComponent color={color} />
  ) : (
    <LastGroupColorBandComponent color={color} />
  );
}

export default TaskCardGroupColorBand;
