const LongTermPriorityOptions = [
  "Living with children",
  "Small space living",
  "Organise your living",
];

export function getLongTermPriorityOptions() {
  return LongTermPriorityOptions.map((value) => ({
    value,
    name: value,
  }));
}
