import React from "react";
import { Trans } from "@coworker/locales";
import {
  DetailSection,
  DetailSectionLeft,
  SectionText,
  SectionTitle,
} from "./_common";
import useFormatter from "../../../hooks/useFormatter";

function Creator({ task, title }) {
  const { formatDateMedium } = useFormatter();
  return (
    <DetailSection>
      <DetailSectionLeft>
        <SectionTitle>{title || <Trans>dateCreatedString</Trans>}</SectionTitle>
        <SectionText data-testid="taskCreator">
          {formatDateMedium(task.created_at)}
        </SectionText>
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default Creator;
