import { formatProductIdWithDots } from "@coworker/reusable";
import { TestBuyProduct } from "@coworker/types";
import React from "react";
import styled from "styled-components";
import {
  ArticleDetailsCardContent,
  ElideDescription,
  Image,
  ImageContainer,
  TaskContainer,
  TextContainer,
} from "./TestBuyArticleCard";

interface CustomTestBuyArticleCardProps {
  article: TestBuyProduct;
}

const Container = styled(ArticleDetailsCardContent)`
  border-bottom: 1px solid var(--grey150);
  width: calc(100% - 40px);
`;

export const CustomTestBuyArticleCard = ({
  article,
}: CustomTestBuyArticleCardProps) => {
  return (
    <Container>
      <TaskContainer>
        <ImageContainer>
          <Image allQuestionsAnswered={false} src={article.smallImage} />
        </ImageContainer>
        <TextContainer>
          <strong>
            {article.name}{" "}
            {article.location !== "no_location" && (
              <>
                {"•"} {article.location}
              </>
            )}
          </strong>
          <ElideDescription>
            {formatProductIdWithDots(article.shortId)}, {article.description}
          </ElideDescription>
        </TextContainer>
      </TaskContainer>
    </Container>
  );
};
