// Vendor
import React from "react";
import { Trans } from "@coworker/locales";
// Enums
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import {
  splitLocation,
  useTaskFormDefaultPickupFields,
} from "../../../services/locations.service";
import {
  ASSIGN_WIDGET,
  PRIORITY_WIDGET,
  getProductWidget,
  AMOUNT_WIDGET,
  DESCRIPTION_WIDGET,
  PHOTOS_WIDGET,
} from "./_common";
import {
  BOOLEAN_FLAG,
  DIVIDER,
  LOCATION,
  TUTORIALS,
  REFILL_TYPE,
} from "../widgets";
import { ReactComponent as CheckIcon } from "../../../assets/svg/check.svg";
import { LOCATION_TYPE_PICKUP } from "../../../constants/locationWidgetTypes";
import trackerHelper from "../../../helpers/tracker";
import { refillTypeMapper } from "../common/fieldMappers.helpers";

const addon = {
  table: "tasks",
  type: "addon",
  enumType: taskTypeOptions.ADDON,
  createCurtainMessage: <Trans>curtainRefilledString</Trans>,
  validate: (form) =>
    [
      form.product_article_id,
      form.assigned_team_id || form.refilled_by_me,
      form.location || form.location_custom,
      form.pickup_location || form.pickup_location_custom,
      form.requested_quantity,
      !form.images?.some((image) => image.loading),
    ].every((x) => x),
  fieldsFilledCount: () => 0,
  useReactToChanges: function (taskId, task, onChange) {
    const pickupFields = useTaskFormDefaultPickupFields(
      // Needed only for new tasks, so skip when taskId is defined.
      !taskId && !(task.pickup_location || task.pickup_location_custom) && task
    );
    React.useEffect(() => {
      if (pickupFields) {
        onChange(pickupFields); // Set a default pickup location when creating a new task
      }
      // Ignored: onChange
      // eslint-disable-next-line
    }, [pickupFields]);

    React.useEffect(() => {
      if (task.product_article_price_missing ?? true) {
        // If product_article_price_missing === undefined || product_article_price_missing === null, we set it to true
        onChange({ product_article_price_missing: true });
      }
      // Ignored: onChange, task.product_article_price_missing
      // eslint-disable-next-line
    }, []);
  },
  header: {
    newTitle: <Trans>requestString</Trans>,
    editTitle: <Trans>editTaskString</Trans>,
  },
  fields: [
    getProductWidget({
      withAvailability: true,
      disabled: () => true,
    }),
    {
      type: LOCATION,
      conf: {
        optional: null,
        locationString: <Trans>pickupLocationString</Trans>,
        addon: LOCATION_TYPE_PICKUP,
        popupName: "addonLocation",
      },
      hide: (_, { picked }) => picked,
      values: {
        in: ({
          pickup_location,
          pickup_location_custom,
          product_article_id,
          product_article_type,
        }) => {
          const [code, department] = splitLocation(pickup_location);

          return {
            code,
            department,
            custom: pickup_location_custom,
            articleId: product_article_id,
            articleType: product_article_type,
          };
        },
        out: ({ code, department, custom, section }) => {
          let locationText = code || "";
          if (department || section)
            locationText += ` - ${department || section}`;

          let departmentText = "";
          if (department || section)
            departmentText = `${department || section}`;

          return {
            pickup_location: custom ? "" : locationText,
            pickup_location_grid_code: custom ? "" : code,
            pickup_location_department: custom ? "" : departmentText,
            pickup_location_custom: custom ? custom : "",
          };
        },
      },
    },
    {
      type: LOCATION,
      conf: {
        optional: null,
        locationString: <Trans>refillLocationString</Trans>,
        addon: "refill",
        popupName: "addonLocation",
      },
      hide: (_, { picked }) => picked,
      values: {
        in: ({
          location,
          location_custom,
          product_article_id,
          product_article_type,
        }) => {
          const [code, department] = splitLocation(location);

          return {
            code,
            department,
            custom: location_custom,
            articleId: product_article_id,
            articleType: product_article_type,
          };
        },
        out: ({ code, department, custom, section }) => {
          let locationText = code || "";
          if (department || section)
            locationText += ` - ${department || section}`;

          let departmentText = "";
          if (department || section)
            departmentText = `${department || section}`;

          return {
            location: custom ? "" : locationText,
            location_grid_code: custom ? "" : code,
            location_department: custom ? "" : departmentText,
            location_custom: custom ? custom : "",
          };
        },
      },
    },
    {
      type: REFILL_TYPE,
      conf: {
        optional: null,
        popupName: "refillType",
        deactivated: false,
        overridePadding: "24px",
      },
      values: {
        in: ({ refill_type: refillType }) => ({
          refillType,
        }),
        out: refillTypeMapper,
      },
    },
    AMOUNT_WIDGET,
    {
      type: BOOLEAN_FLAG,
      conf: {
        title: <Trans>markAsCompleteString</Trans>,
        icon: <CheckIcon />,
        testId: "markedAsComplete",
      },
      values: {
        in: ({ refilled_by_me }) => refilled_by_me,
        out: (refilled_by_me) => {
          trackerHelper.task.trackMarkAsCompleteToggle(refilled_by_me);
          return !refilled_by_me
            ? { refilled_by_me, refilled_quantity: null, pick_quantity: null }
            : { refilled_by_me };
        },
      },
    },
    {
      type: TUTORIALS,
      conf: {
        title: <Trans>markingAsCompleteString</Trans>,
        text: <Trans>markingAsCompleteDetailString</Trans>,
        type: profilePreferences.DISABLE_POPUPS_MARK_AS_COMPLETE,
        combineWithTop: true,
      },
    },
    { type: DIVIDER, hide: (_, { refilled_by_me }) => refilled_by_me },

    { ...ASSIGN_WIDGET, hide: (_, { refilled_by_me }) => refilled_by_me },
    { ...PRIORITY_WIDGET, hide: (_, { refilled_by_me }) => refilled_by_me },
    {
      ...DESCRIPTION_WIDGET(true),
      hide: (_, { refilled_by_me }) => refilled_by_me,
    },
    PHOTOS_WIDGET,
  ],
};

export default addon;
