import React from "react";
import { scannerLog } from "./log";
import { CAPTURE_SIZE, OVERSCAN_SIZE } from "./Overlay";

/**
 * Calculates cropping data to use when calling `createImageBitmap` with the provided video element.
 * @param {HTMLVideoElement} video
 * @returns {[left: number, top: number, width: number, height: number]}
 */
export function useVideoCrop(video) {
  const { width, videoWidth, videoHeight } = video ?? {};
  return React.useMemo(() => {
    // Scale is needed to adjust between actual video pixels and device pixels that get rendered
    const scale = Math.max((videoWidth / width) || 0, 1);
    scannerLog(
      `VideoHeight: ${videoHeight}, VideoWidth: ${videoWidth}, Width: ${width}`
    );

    const reticuleWidth = (CAPTURE_SIZE[0] + 2 * OVERSCAN_SIZE[0]) * scale;
    const reticuleHeight = (CAPTURE_SIZE[1] + 2 * OVERSCAN_SIZE[1]) * scale;

    const left = (videoWidth - reticuleWidth) / 2;
    const top = (videoHeight - reticuleHeight) / 2;
    scannerLog(
      `Reticule: [${left}, ${top}, ${reticuleWidth}, ${reticuleHeight}], Scale: ${scale}`
    );
    return [left, top, reticuleWidth, reticuleHeight];
  }, [videoWidth, width, videoHeight]);
}
