import React from "react";
import { Round } from "./Round";
import { ROUND_HASH } from "../constants";
import { useFetchAllProgressByRound } from "../Hooks/useFetchAllProgressByRound";
import { checkActiveRound } from "../helper";

export const Rounds = () => {
  const { data: countValues, isFetching } = useFetchAllProgressByRound();
  return (
    <>
      <Round
        round={ROUND_HASH.MORNING?.round ?? ""}
        title={ROUND_HASH.MORNING?.transKey ?? ""}
        roundCounts={countValues?.morning}
        loading={isFetching}
        activeRound={checkActiveRound(ROUND_HASH.MORNING?.round ?? "")}
      />
      <Round
        round={ROUND_HASH.MIDDAY?.round ?? ""}
        title={ROUND_HASH.MIDDAY?.transKey ?? ""}
        roundCounts={countValues?.midday}
        loading={isFetching}
        activeRound={checkActiveRound(ROUND_HASH.MIDDAY?.round ?? "")}
      />
      <Round
        round={ROUND_HASH.AFTERNOON?.round ?? ""}
        title={ROUND_HASH.AFTERNOON?.transKey ?? ""}
        roundCounts={countValues?.afternoon}
        loading={isFetching}
        activeRound={checkActiveRound(ROUND_HASH.AFTERNOON?.round ?? "")}
      />
    </>
  );
};
