import React from "react";
import styled from "styled-components";

const SubtitleWrapper = styled.div`
  font-size: 14px;
  font-weight: bold;
  background-color: var(--grey100);
  padding: 7px 0px 7px 28px;
`;

export default function TaskFormSubtitle({ value }) {
  return (
    <SubtitleWrapper data-testid="sectionSubtitle">{value}</SubtitleWrapper>
  );
}
