import React from "react";
import styled from "styled-components";
import Button from "@ingka/button";
import filtersIcon from "@ingka/ssr-icon/paths/filters";
import { Trans } from "@coworker/locales";
import Pill from "@ingka/pill";

const Container = styled.div`
  padding: 24px 20px;
  align-items: center;
  overflow: auto hidden;
  white-space: nowrap;
  background: white;
  @media screen and (min-width: 1000px) {
    padding: 0px;
    margin: 24px 0px;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Name = styled.p`
  font-size: 12px;
  color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
`;

const Filter = ({
  onClick,
  productName,
  time,
  quantity,
  hfb,
  level,
  value,
  share,
  notAll,
  home_sort,
  home_sort_value,
  show_home_sort_value,
}) => {
  const notStore = !window.location.href.split("/").includes("store");

  return (
    <Container>
      {productName && <Name>{productName.toUpperCase()}</Name>}
      <Content>
        <Button type="primary" ssrIcon={filtersIcon} onClick={onClick} small>
          <Trans>allFiltersString</Trans>
        </Button>
        <Pill
          onClick={onClick}
          data-testid="insights_filter_time"
          size="small"
          label={time}
        />
        {level === 1 && notStore && (
          <>
            <Pill
              onClick={onClick}
              data-testid="insights_filter_value"
              size="small"
              label={value}
            />
            <Pill
              onClick={onClick}
              data-testid="insights_filter_sort"
              size="small"
              label={home_sort}
            />
            {show_home_sort_value && (
              <Pill
                onClick={onClick}
                data-testid="insights_filter_home_sort_value"
                size="small"
                label={home_sort_value}
              />
            )}
          </>
        )}
        {level > 1 && (
          <Pill
            onClick={onClick}
            disabled={level > 2}
            data-testid="insights_filter_quantity"
            size="small"
            label={quantity}
          />
        )}
        {level > 1 && notAll && (
          <Pill
            onClick={onClick}
            disabled={level > 2}
            data-testid="insights_filter_share"
            size="small"
            label={share}
          />
        )}
        {level > 1 && (
          <Pill
            onClick={onClick}
            disabled={level > 2}
            data-testid="insights_filter_hfb"
            size="small"
            label={hfb}
          />
        )}
      </Content>
    </Container>
  );
};

export default Filter;
