import React, { useEffect, useState } from "react";
import { msalHejConfiguration } from "../../../../../assets/auth/msalConfig";
import { PublicClientApplication } from "@azure/msal-browser";
// TODO: Remove the dependency when we decide on the new tracking solution
import trackerHelper from "@coworker/app/src/helpers/tracker";

import "@ingka-group-digital/cwep-hej-bygglek-webc";
import {
  initHejComponent,
  HejEventsHandler,
} from "@ingka-group-digital/cwep-hej-core";
import {
  HejPublishedWebc,
  HejPublishedEvents,
} from "@ingka-group-digital/cwep-hej-types";
import { pickTranslationFile } from "./translations/pickTranslationFile";

import Button from "@ingka/button";
import SquareGridIcon from "@ingka/ssr-icon/paths/square-grid-3x3-filled";

import style from "./hej-app-switcher.module.css";
import { useTranslation } from "react-i18next";

interface HejAppSwitcherProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  profileClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "hej-appswitcher-webc": HejAppSwitcherProps;
    }
  }
}

// This event is to handle updating of the state when the app switcher is closed
// As the initHejComponent cannot be called inside of a component,
// we need to use this event to update the state via the window object event
const hejAppSwitcherClosedEvent = new Event("hejAppSwitcherClosed");

initHejComponent({
  name: "Fixa",
  msalConfig: msalHejConfiguration,
  events: {
    emit: (
      component: HejPublishedWebc,
      eventName: HejPublishedEvents,
      ...params: any[]
    ) => {
      if (component === "hej-appswitcher-webc") {
        trackerHelper.trackHejComponentEvent(eventName, params);
      }

      if (
        component === "hej-appswitcher-webc" &&
        eventName === "AppSwitcherClosed"
      ) {
        window.dispatchEvent(hejAppSwitcherClosedEvent);
      }
    },
  } as HejEventsHandler,
  apiScopeList: ["favorite", "link", "user"],
  logOutHandler: () => {
    const pca = new PublicClientApplication({
      auth: { clientId: msalHejConfiguration.auth.clientId },
    });
    pca.initialize().then(() => {
      pca.logoutRedirect();
    });
  },
  translationProvider: pickTranslationFile(),
});

const HejAppSwitcher = () => {
  const [opened, setOpened] = useState(false);
  const { t } = useTranslation();
  const toggleOpened = () => {
    setOpened(!opened);
    trackerHelper.trackHejComponentSquareGridIconClick();
  };

  useEffect(() => {
    const handleHejAppSwitcherClosed = () => {
      setOpened(false);
    };

    window.addEventListener("hejAppSwitcherClosed", handleHejAppSwitcherClosed);

    return () => {
      window.removeEventListener(
        "hejAppSwitcherClosed",
        handleHejAppSwitcherClosed
      );
    };
  }, []);

  return (
    <div className={style["hej-app-switcher"]}>
      <Button
        aria-label={t("appSwitcherString")}
        ssrIcon={SquareGridIcon}
        onClick={toggleOpened}
        iconOnly
        inverseTheme
        type="tertiary"
      />
      {opened && <hej-appswitcher-webc></hej-appswitcher-webc>}
    </div>
  );
};

export default HejAppSwitcher;
