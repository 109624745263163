import React from "react";
import styled from "styled-components";
import { ReactComponent as CameraIcon } from "../../assets/svg/camera.svg";
import { Trans } from "@coworker/locales";
import { useInputPopup } from "../InputPopup";

const Container = styled.div`
  background: var(--white);
  padding: 20px 31px;
  color: var(--grey500);
`;

const NotesSectionTitle = styled.div`
  flex-grow: 1;
  line-height: 21px;
  font-size: 14px;
`;

const Camera = styled(CameraIcon)`
  width: 24px;
  height: 20px;
  margin: 0px auto;
`;

const IconColumn = styled.div`
  width: 22px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  margin-left: 16px;
  justify-content: center;
  flex-direction: column;
  margin: 0px 5px;
`;

const UpperRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

function AddNote({ taskId, setLocalImages, localImages, setCreatedNote }) {
  const { getInput } = useInputPopup();

  return (
    <Container data-testid="addNoteSection">
      <UpperRow
        onClick={() => {
          getInput("addNote", {
            title: <Trans>addNoteString</Trans>,
            setCreatedNote,
            setLocalImages,
            localImages,
            taskId,
          });
        }}
      >
        <NotesSectionTitle>
          <Trans>addNoteString</Trans>
        </NotesSectionTitle>
        <IconColumn>
          <Camera />
        </IconColumn>
      </UpperRow>
    </Container>
  );
}

export default AddNote;
