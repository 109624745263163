const present = (text) => text && !["", "-"].includes(text.trim());
const firstPresent = (list) => list.find(present) || "";
const allPresent = (list) => list.filter(present);
exports.present = present;
exports.allPresent = allPresent;
exports.firstPresent = firstPresent;

function firstArrayWithContent(arrays) {
  for (const arr of arrays) {
    if (arr && arr.find && firstPresent(arr)) return arr;
  }
  return [];
}
exports.firstArrayWithContent = firstArrayWithContent;

function inChunks(chunkSize, list) {
  const array = [];
  for (let i = 0; i < list.length; i += chunkSize) {
    array.push(list.slice(i, i + chunkSize));
  }
  return array;
}
exports.inChunks = inChunks;
