import React from "react";
import { useTranslation } from "@coworker/locales";
import { CenterContainer, Title } from "../styles/styles";

export const NoDataMessage = () => {
  const { t } = useTranslation();

  return (
    <CenterContainer $centerText style={{ padding: "8px" }}>
      <p>{t("noArticlesString")}</p>
      <Title>{t("addNewOrUpdateFiltersString")}</Title>
    </CenterContainer>
  );
};
