import React from "react";
import Tabs, { Tab, TabPanel } from "@ingka/tabs";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/carousel/dist/style.css";
import "@ingka/tabs/dist/style.css";
import "@ingka/focus/dist/style.css";

function SkapaTabs({ tabs, active, onTabChanged, noTabPanelPadding = false }) {
  const skapatabs = tabs.map(({ key, title, testId = "testId1" }) => (
    <Tab
      key={key}
      text={title}
      onClick={() => onTabChanged(key)}
      tabPanelId={key}
      data-testid={testId}
    />
  ));

  const skapatabPanels = tabs.map(({ key, content }) => (
    <TabPanel
      key={key}
      tabPanelId={key}
      style={{ padding: noTabPanelPadding && 0 }}
    >
      {key === active ? content : null}
    </TabPanel>
  ));

  return (
    <Tabs tabs={skapatabs} tabPanels={skapatabPanels} activeTab={active} />
  );
}

export default SkapaTabs;
