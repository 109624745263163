import React from "react";
import styled from "styled-components";
import { Navigate } from "react-router-dom";
import { announceableFeatures } from "./announcements";
import {
  useWorkspacesState,
  useWorkspacesAction,
} from "../../hooks/useWorkspaces";
import { ReactComponent as ArrowBack } from "../../assets/svg/arrow-back.svg";

const AnnouncementHeading = styled.div`
  display: flex;
  flex-direction: column;
  height: 191px;
  background-color: var(--yellow);
`;

const BackContainer = styled.div`
  width: 100%;
`;

const IconCircleBackground = styled.div`
  margin: 30px;
  background-color: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.span`
  font-size: 64px;
  padding-left: 30px;
`;

export function AnnouncementPage() {
  const { navigationState } = useWorkspacesState();
  const { pop } = useWorkspacesAction();
  const FeatureDetailedAnnouncement =
    announceableFeatures[navigationState.feature]?.detailsView;

  if (!FeatureDetailedAnnouncement) return <Navigate to="/" />;

  return (
    <div>
      <AnnouncementHeading>
        <BackContainer>
          <IconCircleBackground onClick={() => pop()}>
            <ArrowBack />
          </IconCircleBackground>
        </BackContainer>
        <Title>
          <span role="img" aria-label="Congratulations">
            🎉
          </span>
        </Title>
      </AnnouncementHeading>
      <FeatureDetailedAnnouncement />
    </div>
  );
}
