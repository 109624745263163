import { InternalProxyResponse } from "@coworker/types";
import React from "react";
import useCallInternal, {
  MFS_SEARCH,
  callInternalApi,
} from "../useCallInternal";

type Parameters = {
  query: string;
  page: number;
  store_id: string;
};

type Options = {
  blockRequest: boolean;
};

type MFSResult = {
  Items: unknown[];
  NumFound: number;
};
type MFSResponse = {
  Result: MFSResult;
};

export function useTasksServiceMFSSearch(
  query: string,
  callTaskService: boolean
) {
  const [response, setResponse] = React.useState<MFSResponse>({
    Result: {
      Items: [],
      NumFound: 0,
    },
  });
  React.useEffect(() => {
    if (callTaskService)
      callInternalApi("products/mfs_search?query=" + query).then(setResponse);
  }, [query, callTaskService]);
  return React.useMemo(
    () => ({
      loading: false,
      isBlocker: false,
      data: processMFSSearchProductsResult(response.Result),
    }),
    [response]
  );
}

export function useCallMFSSearchProducts(
  parameters: Parameters,
  options: Options
) {
  const { query, page, store_id } = parameters;
  const bu = store_id?.split?.("#")?.[0] || "";

  const response = useCallInternal(
    MFS_SEARCH,
    {
      bu,
      page,
      query,
    },
    { ...options, blockRequest: options.blockRequest || !bu }
  ) as InternalProxyResponse<MFSResponse>;

  const stringifiedResult = JSON.stringify(response.data?.Result);
  return React.useMemo(
    () => ({
      loading: response.loading,
      isBlocked: response.isBlocked,
      data: processMFSSearchProductsResult(response.data?.Result),
    }),
    // Making sure that the result is only re-rendered if response.data.Result changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifiedResult, response.loading, response.isBlocked]
  );
}

function processMFSSearchProductsResult(result: MFSResult) {
  if (!result) return undefined;
  return {
    items: result.Items,
    total: result.NumFound,
  };
}
