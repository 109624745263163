import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { taskStateFilterTypes } from "@coworker/types/lib/taskStateFilterTypes";
import { FilterItem } from "./FilterItem";

const Title = styled.div`
  margin: 10px 29px;
  font-weight: bold;
  font-size: 18px;
`;

export const taskStateFilterItems = [
  {
    type: taskStateFilterTypes.ALL_STATES,
    translation: "allString",
  },
  {
    type: taskStateFilterTypes.ONGOING,
    translation: "ongoingTasksString",
  },
  {
    type: taskStateFilterTypes.RECENT,
    translation: "recentlyClosedTasksString",
  },
];

interface TaskStateFilterProps {
  filterSetter: (filterType: string) => void;
  selectedFilter: string;
}

export function TaskStateFilter({
  filterSetter,
  selectedFilter,
}: TaskStateFilterProps) {
  return (
    <React.Fragment>
      <Title>
        <Trans>statusString</Trans>
      </Title>
      {taskStateFilterItems.map((item) => (
        <FilterItem
          key={item.type}
          content={<Trans>{item.translation}</Trans>}
          filterType={item.type}
          filterSetter={filterSetter}
          checked={selectedFilter?.includes(item.type)}
        />
      ))}
    </React.Fragment>
  );
}
