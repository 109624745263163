import React from "react";

import styles from "./action-bar.module.css";

interface ActionBarProps {
  children: React.ReactNode;
  hidden: boolean;
}

export const ActionBar = ({ hidden, children }: ActionBarProps) => {
  return (
    <div
      className={`${styles["action-bar"]} ${hidden ? styles["hidden"] : ""}`}
    >
      {children}
    </div>
  );
};
