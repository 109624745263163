import React from "react";

export default function useMounted() {
  const mounted = React.useRef(true);
  React.useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  return mounted;
}

// Use case:
// function MyComponent() {
//   const [result, setResult] = React.useState();
//   const mounted = useMounted();
//   async function someAsynchronousAction() {
//     const newResult = await getResult();
//     if (mounted.current) {
//       setResult(newResult);
//     }
//   }
// }

export function useMountedState(initialValue) {
  const [state, setState] = React.useState(initialValue);
  const mounted = useMounted();
  return React.useMemo(
    () => [
      state,
      (value) => {
        if (mounted.current) setState(value);
      },
      mounted,
    ],
    [state, setState, mounted]
  );
}
