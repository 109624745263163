import React from "react";

import styles from "./full-color-section.module.css";

interface FullColorSectionProps {
  children: React.ReactNode;
  color: "green" | "blue";
}

const FullColorSection = ({ children, color }: FullColorSectionProps) => {
  return (
    <div className={`${styles["full-color-section"]} ${styles[color]}`}>
      {children}
    </div>
  );
};

export default FullColorSection;
