import {
  PRODUCT,
  HEADER,
  ISSUE,
  LOCATION,
  ASSIGNMENT,
  ACTIVITY,
} from "./_common";

import { PickUpTask, CompleteTask } from "../actions/index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fields: [HEADER, PRODUCT, ISSUE, LOCATION(), ASSIGNMENT(), ACTIVITY],
  actions: [PickUpTask, CompleteTask],
};
