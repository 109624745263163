import React from "react";
import styled from "styled-components";
import { ReactComponent as RepeatableIcon } from "../assets/svg/repeatable.svg";

const Container = styled.div`
  background: white;
  padding: 0 29px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
  height: 100%;
  color: ${(props) =>
    props.deactivated ? "var(--grey600)" : "var(--grey900)"};
`;
const CompactContainer = styled.div`
  min-height: 90px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
`;
const CompactTitle = styled.h2`
  font-size: 14px;
  font-weight: normal;
  padding-top: 35px;
  line-height: 1.5;
  margin-left: 16px;
  padding-bottom: 2px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 700;
`;
const CompactIconWrapper = styled.div`
  padding-top: 32px;
  padding-left: 2px;
  svg {
    opacity: ${(props) => (props.deactivated ? ".5" : "1")};
  }
`;
const Optional = styled.div`
  opacity: 0.58;
  font-size: 14px;
  line-height: 24px;
  flex-grow: 0;
  font-weight: 400;
`;

const AddRepeatable = ({
  deactivated,
  repeatableString,
  optional,
  ...props
}) => (
  <Container deactivated={deactivated}>
    <CompactContainer>
      <CompactIconWrapper deactivated={deactivated}>
        <RepeatableIcon />
      </CompactIconWrapper>
      <CompactTitle>
        <p>{props.value && !props.none ? props.value : repeatableString}</p>
        <Optional className="optional">{optional}</Optional>
      </CompactTitle>
    </CompactContainer>
  </Container>
);

export default AddRepeatable;
