import React from "react";
import Modal, { ModalHeader, Prompt, ModalFooter } from "@ingka/modal";
import Button from "@ingka/button";
import { useTranslation } from "@coworker/locales";

interface CreateTaskModalProps {
  modalVisibility: boolean;
  onModalChange: (visibility: boolean) => void;
  onCancel: () => void;
}

export const CreateTaskModal = ({
  modalVisibility,
  onModalChange,
  onCancel,
}: CreateTaskModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible={modalVisibility}
      handleCloseBtn={() => {
        onModalChange(!modalVisibility);
      }}
    >
      <Prompt
        titleId="mainOrBackupProductWarning"
        title=""
        header={<ModalHeader ariaCloseTxt="Close" title={t("warningString")} />}
        footer={
          <ModalFooter>
            <Button onClick={() => onCancel()}>{t("noString")}</Button>
            <Button type={"primary"}>{t("yesString")}</Button>
          </ModalFooter>
        }
      >
        <p>{t("isProductMainOrBackupWarningString")}</p>
        <p style={{ marginTop: "10px" }}>{t("sureToContinueString")}</p>
      </Prompt>
    </Modal>
  );
};
