import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../assets/svg/arrow-back.svg";

export const Wrapper = styled.div`
  position: relative;
  background: var(--grey150);
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  color: var(--grey900);
`;

export const Content = styled.div`
  > *:not(:last-child) {
    margin-bottom: 10px;
  }
  ${(props) =>
    props.noPadding ? `padding: 0px` : `padding:10px`}
`;

export const Header = styled.div`
  display: flex;
  background: var(--white);
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  padding-top: 25px;
  height: 40px;
  line-height: 50px;
`;

export const LeftHeaderButton = styled.button`
  background: transparent;
  padding-left: 8px;
  padding-top: 10px;
  cursor: pointer;
`;

export const StyledBackIcon = styled(BackIcon)`
  margin-left: 11px;
`;

export const CenterText = styled.div`
  text-align: center;
  flex-grow: 1;
  padding-right: 44px;
`;
