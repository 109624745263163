/**
 * @param {Data} date
 * @returns {String} ISO-formatted date
 */
export function isoDate(date) {
  const string = date.toISOString ? date.toISOString() : String(date);
  return string.slice(0, 10);
}

/**
 * @param {String} offsetInDays
 * @returns {String} ISO-formatted date and tome
 */
export function nowAsISOWithOffsetInDays(offsetInDays) {
  const timeToConvert = Date.now() + offsetInDays * 24 * 3600 * 1000;
  return new Date(timeToConvert).toISOString();
}
