import dayjs from "dayjs";
import {
  AREA_TAB,
  periods,
  others,
  departments,
  WHOLE_STORE_FILTER,
} from "./FilterChoiceData";
import { allDivisions } from "../../TestBuy/constants";

export {
  AREA_TAB,
  areaTabs,
  periods,
  others,
  departments,
  WHOLE_STORE_FILTER,
} from "./FilterChoiceData";

export type FilterKeys = [string, string, string] | [string, string] | [string];
export interface Filter {
  type: string;
  name: JSX.Element;
  tab?: string;
  shortName?: string;
  transKey?: string;
  getInitialDate?: () => dayjs.Dayjs;
}
export type FilterMatcher = (param: Filter) => boolean;

export const defaultFilters: FilterKeys = [
  periods[0]!.type,
  WHOLE_STORE_FILTER[0]!.type,
  "",
];

export function findChoice(matcher: FilterMatcher): Filter | undefined {
  return (
    departments.find(matcher) ||
    others.find(matcher) ||
    WHOLE_STORE_FILTER.find(matcher) ||
    periods.find(matcher)
  );
}

export const isPeriod = (item: string) => item.startsWith("period=");
export const isDivision = (item: any) =>
  allDivisions.map((division) => division.divisionShortName).includes(item);

export const describeDivision = (item: string) =>
  allDivisions.find((division) => division.divisionShortName === item)
    ?.translationString;

export function describeFilter(key: string, preferLong = false) {
  const choice = findChoice(({ type }) => type === key);
  if (preferLong) {
    return choice?.name || choice?.shortName || key;
  } else {
    return choice?.shortName || choice?.name || key;
  }
}

export const getAreaFilter = (filters: FilterKeys) =>
  filters.find((filter) => !isPeriod(filter));

export const getPeriodFilter = (filters: FilterKeys) => filters.find(isPeriod);

export const getDivisionFilter = (filter: FilterKeys) =>
  filter.find(isDivision);

export function getPeriodTransKey(filters: FilterKeys) {
  const period = getPeriodFilter(filters);
  const choice = findChoice(({ type }) => type === period);
  return choice?.transKey;
}

export const describeAreaFilter = (filters: FilterKeys, preferLong = false) => {
  const area = getAreaFilter(filters);
  return area ? describeFilter(area, preferLong) : "";
};

export function initialAreaTab(filters: FilterKeys) {
  const areaChoice = getAreaFilter(filters);
  return findChoice(({ type }) => type === areaChoice)?.tab || AREA_TAB.DEPTS;
}

/**
 * Check whether the given area filter is the whole store filter
 * @param filtersOrAreaKey Either area key (e.g. "depts=01") or filters array from preferences
 * @example
 * areaIsWholeStore("whole=whole") // true
 * areaIsWholeStore("depts=01") // false
 * areaIsWholeStore(["period=7days", "whole=whole"]) // true
 * areaIsWholeStore(["period=7days", "depts=01"]) // false
 */
export function areaIsWholeStore(
  filtersOrAreaKey: string | FilterKeys | undefined
): boolean {
  if (!filtersOrAreaKey) return false;
  if (typeof filtersOrAreaKey === "string") {
    return filtersOrAreaKey === WHOLE_STORE_FILTER[0]?.type;
  } else {
    return getAreaFilter(filtersOrAreaKey) === WHOLE_STORE_FILTER[0]?.type;
  }
}

export const periodIsCustom = (periodType: string) =>
  periodType === "period=custom";

// Default custom dates are last 7 days
export const defaultCustomPeriod = () => [
  periods
    .find((period) => period.type === "period=7days")
    ?.getInitialDate?.()
    .format("YYYY-MM-DD"),
  dayjs().format("YYYY-MM-DD"),
];
