import React from "react";
import styled from "styled-components";
import { RadioButton } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import {
  OptionsGroup,
  Title,
  Option,
  OptionContent,
  Text,
  Radio,
} from "./CommonComponents";

const EmptyDepartments = styled.div`
  padding: 0px 28px 20px 28px;
`;

function DepartmentsFilter({
  loading,
  departmentOptions,
  disabled,
  department,
  onDepartmentsChange,
}) {
  const { t } = useTranslation();

  const displayHFB = (hfb) => {
    if (!hfb) return t("allHFBsString");

    return t(`hfb${hfb}String`);
  };

  return (
    <OptionsGroup disabled={disabled}>
      <Title>HFB</Title>
      {!loading && !departmentOptions.length && (
        <EmptyDepartments>
          {t("noDepartmantsAvailableForFilter")}
        </EmptyDepartments>
      )}
      {loading ? (
        <Option>
          <OptionContent>
            <Text>{t("loadingString")}</Text>
          </OptionContent>
        </Option>
      ) : (
        departmentOptions.map((option) => {
          return (
            <Option
              data-testid={`hfb_${option}`}
              disabled={disabled}
              key={option}
              onClick={() => {
                onDepartmentsChange(option);
              }}
            >
              <OptionContent>
                <Text>
                  {option !== "ALL" && option !== "OT" && option + " • "}
                  {displayHFB(option)}
                  {loading && t("loadingString")}
                </Text>

                <Radio>
                  <RadioButton readOnly checked={department === option} />
                </Radio>
              </OptionContent>
            </Option>
          );
        })
      )}
    </OptionsGroup>
  );
}

export default DepartmentsFilter;
