import { makeServiceHeaders } from "../../core/hooks/fetch.helpers";
import { SHOPPINGTOOLS_SERVICE_URL } from "./ShoppingTools.service.helper";
import {
  AllProgressByRound,
  ChartData,
  CountOnDivision,
  DeleteShoppingToolsBody,
  SettingsDefault,
  SettingsResponse,
  ShoppingToolsBody,
  ShoppingToolsCount,
  ShoppingToolsTypeObject,
} from "./Types";

export const createShoppingTool = async (shoppingTool: ShoppingToolsBody) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/create-shoppingtools`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify(shoppingTool),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteShoppingTool = async (
  shoppingTool: DeleteShoppingToolsBody
) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/delete-shoppingtools-on-tool`,
      {
        method: "DELETE",
        headers: await makeServiceHeaders(),
        body: JSON.stringify(shoppingTool),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }
  } catch (error) {
    console.error(error);
  }
};

export const fetchTodaysProgress = async (
  storeId: string,
  division: string,
  startOfDay: number,
  round: string
) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/fetch-todays-progress`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify({ storeId, startOfDay, division, round }),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data = await response.json();
    return data.todaysProgress as number;
  } catch (error) {
    return console.error(error);
  }
};

export const fetchShoppingToolsCountOnDivision = async (
  storeId: string,
  division: string,
  roundName: string,
  startOfDay: number
) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/fetch-shoppingtools-count-on-division`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify({ storeId, division, roundName, startOfDay }),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data = (await response.json()) as ShoppingToolsCount[];
    return data;
  } catch (error) {
    return console.error(error);
  }
};

export const fetchLatestShoppingTools = async (storeId: string) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/fetch-latest-shoppingtools-by-store-id`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify({ storeId }),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data = (await response.json()) as ShoppingToolsTypeObject;
    return data;
  } catch (error) {
    return console.error(error);
  }
};

export const fetchDivisionsCountByRound = async (
  storeId: string,
  division: string,
  roundName: string,
  startOfDay: number
) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/fetch-divisions-count-by-round`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify({ storeId, division, roundName, startOfDay }),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data = await response.json();
    return data.count ?? 0;
  } catch (error) {
    return console.error(error);
  }
};

export const fetchCountOnDivision = async (
  storeId: string,
  division: string,
  roundName: string,
  startOfDay: number
) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/fetch-shoppingtools-count-on-division`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify({ storeId, division, roundName, startOfDay }),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data = (await response.json()) as CountOnDivision;
    return data;
  } catch (error) {
    return console.error(error);
  }
};

export const fetchAllProgressByRound = async (
  storeId: string,
  startOfDay: number
) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/fetch-all-progress-by-round`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify({ storeId, startOfDay }),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data = (await response.json()) as AllProgressByRound;
    return data;
  } catch (error) {
    return console.error(error);
  }
};

export const fetchChartData = async (
  storeId: string,
  startDate: number,
  endDate: number
) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/shoppingtools/fetch-chart-data`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify({ storeId, startDate, endDate }),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data = (await response.json()) as ChartData[];
    return data;
  } catch (error) {
    return console.error(error);
  }
};

export const fetchShoppingToolsSettings = async (storeId: string) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/settings/fetch-settings-on-store-id`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify({ store_id: storeId }),
      }
    );

    if (!response.ok) {
      throw new Error("Respnse was not ok.");
    }

    const data = (await response.json()) as
      | SettingsResponse[]
      | SettingsDefault[];
    return data;
  } catch (error) {
    return console.error(error);
  }
};

export const createOrUpdateShoppingtoolsSettings = async (
  createOrUpdateSettings: SettingsDefault[] | SettingsResponse[]
) => {
  try {
    const response = await fetch(
      `${SHOPPINGTOOLS_SERVICE_URL}/settings/change-settings`,
      {
        method: "POST",
        headers: await makeServiceHeaders(),
        body: JSON.stringify(createOrUpdateSettings),
      }
    );

    if (!response.ok) {
      throw new Error("Response was not ok.");
    }
  } catch (error) {
    console.error(error);
  }
};
