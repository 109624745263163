/**
 * This is whatever the user's phone is using.
 * To consider: Could we get bugs from timezone-travelling users? Consider New Zealand, US west, etc. Or if some user keeps their phone timezone on something else then where they live.
 * Longer term we should probably remove this and instead keep one timezone per store.
 */
export const defaultTimezone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || "";

/**
 * Interval values in milliseconds.
 */
export const INTERVAL = {
  MINUTE: 60 * 1000,
  HOUR: 3600 * 1000,
  DAY: 3600 * 1000 * 24,
  WEEK: 3600 * 1000 * 24 * 7,
};
