import React from "react";
import styled from "styled-components";
import { RadioButton } from "@coworker/components";

const RadioButtonWrapper = styled.div`
  min-width: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Filter = styled.div`
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 29px;
  padding-right: 39px;
`;
const FilterName = styled.div`
  margin-right: 20px;
  font-size: 14px;
`;

interface FilterItemProps {
  filterType: string;
  content: JSX.Element;
  filterSetter: (filterType: string) => void;
  checked: boolean;
}

export function FilterItem({
  filterType,
  content,
  filterSetter,
  checked,
}: FilterItemProps) {
  return (
    <Filter
      key={filterType}
      data-testid={`filter-${filterType}`}
      onClick={() => filterSetter(filterType)}
    >
      <FilterName>{content}</FilterName>
      <RadioButtonWrapper key={filterType}>
        <RadioButton
          key={filterType}
          readOnly
          checked={checked}
          data-testid={filterType + "Button"}
        />
      </RadioButtonWrapper>
    </Filter>
  );
}
