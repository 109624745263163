import React from "react";
import BlueLink, { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Política de privacidad de Fixa</div>,
  aboutFixaText: (
    <div>
      Fixa es una herramienta de gestión de tareas y merchandising para los/as
      colaboradores/as de las tiendas. El objetivo de las aplicaciones Fixa es
      reemplazar las actividades que se realizan manualmente, en papel o en
      Excel, y agilizar la gestión de tareas en la tienda, como la reposición de
      artículos, la limpieza, las desviaciones, los ensayos de compra y las
      preguntas frecuentes, entre otras. Fixa también permite hacer análisis de
      la información obtenida de las tareas ya completadas.
      <br />
      <br />
      IKEA IBERICA S.A.U. es el responsable del tratamiento de datos personales
      en Fixa. Cuando utilices Fixa, IKEA IBÉRICA S.A.U. tal como se explica a
      continuación recopilará algunos datos personales.
    </div>
  ),
  personalDataCollectedTitle: (
    <div>Los datos personales que se recopilan son:</div>
  ),
  personalDataCollectedText: (
    <div>
      Al usar Fixa, el sistema accederá a tu información personal desde la base
      de datos de los/as colaboradores/as (CDS) y recopilará datos como tu
      nombre, tu segundo nombre, tus apellidos, tu correo electrónico, tu
      puesto, tu función, tu número de teléfono (en el caso de que se inserte
      manualmente; no se debe introducir el número de teléfono personal), la
      fotografía de CDS, tu preferencia de idioma en la aplicación, el ID de la
      tienda, el ID del país, la versión del dispositivo y del sistema
      operativo, y la dirección IP procesada en Google Cloud Platform y Sentry
      (Sentry es un software de seguimiento de errores de aplicación).
      <br />
      <br />
      El mando o superusuario debe crear manualmente el perfil de colaborador/a
      a través de Fixa-admin (una aplicación web de administración). Los datos
      personales se obtienen de CDS, por lo que solo se pueden crear perfiles
      para los/as colaboradores/as que tengan un perfil de CDS activo.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>El objetivo de recopilar estos datos es:</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      Recopilamos datos personales en Fixa para que la aplicación funcione
      correctamente y facilite la comunicación interna entre los/as
      colaboradores/as y sus tareas administrativa en la tienda. Al vincular
      tareas a un usuario podemos estar al día de todas las novedades de las
      tareas asignadas a usuarios y creadores.
    </div>
  ),
  legalGroundsTitle: (
    <div>El fundamento jurídico para el procesamiento es:</div>
  ),
  legalGroundsText: (
    <div>La base legal para tratar los datos es el contrato</div>
  ),
  dataWillBeStoredTitle: <div>Los datos se almacenarán:</div>,
  dataWillBeStoredText: (
    <div>
      Almacenamos las tareas completadas asignadas a usuarios durante 70 días;
      después, se harán anónimas. Los datos personales de los/as
      colaboradores/as se almacenan en Fixa hasta la rescisión del contrato
      laboral.
      <br />
      <br />
      Rescisión del contrato laboral con el Grupo Ingka: Todos los datos
      personales se eliminan automáticamente en un plazo de un mes tras la
      eliminación del perfil de CDS.
      <br />
      <br />
      Cambio de función en el Grupo Ingka: Si un/a colaborador/a deja de
      utilizar Fixa, todos los datos personales se eliminan automáticamente
      después de que su perfil haya estado inactivo durante 3 meses.
      <br />
      <br />
      Podrás ejercer los derechos de acceso, rectificación, supresión, derecho a
      solicitar la limitación del tratamiento de tus datos personales, y el
      derecho a presentar una reclamación ante la Agencia de Protección de
      Datos. Para ello podrás gestionar tu petición a través del correo
      electrónico <EmailLink email="tusdatos@ikea.com" />.
    </div>
  ),
  dataControllerForPersonalDataTitle: "",
  dataControllerForPersonalDataText: "",
  rightToPersonalInformationText: (
    <div>
      IKEA ha designado una Delegada de Protección de Datos. En el caso de que
      quieras consultar cuestiones relativas al tratamiento de tus datos
      personales puedes escribirnos al correo tusdatos@ikea.com indicando el
      motivo de tu consulta.
      <br />
      <br />
      Si quieres más información acerca de cómo IKEA trata tus datos puedes
      encontrarla en el portal de ISDP en Intranet:{" "}
      <BlueLink href="https://inside.ingka.com/methodsstrategies/RoutinesWorkingMethods/GovernanceRiskManagement/RiskComplianceSpain/ISDPSpain/Pages/default.aspx">
        https://inside.ingka.com/&#8203;methodsstrategies/&#8203;RoutinesWorkingMethods/&#8203;GovernanceRiskManagement/&#8203;RiskComplianceSpain/&#8203;ISDPSpain/&#8203;Pages/&#8203;default.aspx
      </BlueLink>
    </div>
  ),
};
