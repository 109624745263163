import React from "react";
import styled from "styled-components";
//import Checkbox from "@ingka/checkbox";

const CheckboxWrapper = styled.div`
  z-index: var(--z-multiselectcheckbox);
  align-self: center;
  margin-left: 16px;
  & input {
    height: 24px;
    width: 24px;
  }
`;

const MultiSelectCheckbox = ({ value, onClick, checked }) => (
  <CheckboxWrapper>
    <input
      type="checkbox"
      value={value}
      checked={checked}
      readOnly
      onClick={onClick}
      data-testid="multiSelectCheckbox"
    />
  </CheckboxWrapper>
);
export default MultiSelectCheckbox;
