import React from "react";
import { getCachedInternalApi } from "../../hooks/API/useCallInternal";
import { useStoreId } from "../auth/useLoggedInUser";
type Result = { data: { list: boolean } };

export function useOwnImages(taskId: string) {
  const [result, setResult] = React.useState(false);
  const storeId = useStoreId();
  React.useEffect(() => {
    if (taskId) {
      getCachedInternalApi(
        `task/images?id=${taskId}`,
        ({ data }: Result) => setResult(data?.list),
        60_000,
        false,
        "TASKS_SERVICE",
        {
          store_id: storeId,
        }
      );
    }
  }, [taskId, storeId]);
  return result;
}

export function useNoteImages(taskId: string, noteId: string) {
  const [result, setResult] = React.useState(false);
  const storeId = useStoreId();
  React.useEffect(() => {
    if (taskId) {
      getCachedInternalApi(
        `note/images?id=${taskId}&noteId=${noteId}`,
        ({ data }: Result) => setResult(data?.list),
        60_000,
        false,
        "TASKS_SERVICE",
        {
          store_id: storeId,
        }
      );
    }
  }, [noteId, taskId, storeId]);
  return result;
}
