import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import { Icon } from "@coworker/components";

const CompactIconWrapper = styled.div`
  width: 22px;
  height: 24px;
  margin-right: 21px;
  margin-top: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  svg {
    margin: 0px auto;
  }
`;

function RadioButtonsWithText({
  task,
  conf: {
    field = "description",
    fieldText = "description_text",
    titleTranslationKey = "description4String",
    icon,
    iconSize = 20,
    options,
  },
}) {
  if (!task[field]) {
    return null;
  }
  const selectedOption = options.find((option) => option.value === task[field]);
  const text = selectedOption?.requiresText ? (
    task[fieldText]
  ) : (
    <Trans>{selectedOption.translationKey}</Trans>
  );

  return (
    <DetailSection>
      {icon && (
        <CompactIconWrapper>
          <Icon size={iconSize} family="icons" name={icon} color={"grey900"} />
        </CompactIconWrapper>
      )}
      <DetailSectionLeft>
        <SectionTitle>
          <Trans>{titleTranslationKey}</Trans>
        </SectionTitle>
        <SectionText data-testid="taskRadioButtonWithText">{text}</SectionText>
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default RadioButtonsWithText;
