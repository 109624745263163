import { StoreApi, UseBoundStore } from "zustand";

/***
 * Benefits of using this:
 * For this kind of situation, already with only a single boolean,
 *   const store = extendWithGetters(zustand<EditStatusState>()(() => ({ status: false })));
 *   export function useInsightsEditStatus() {
 *     return {
 *      ...store.getters,
 *
 * we can opt to export `store` directly but named as a hook, and then call it as a hook, so useSomeStore(state => state.status)
 * which is relevant only as long as the store has a single update mode (all or no parts of the store are updated together).
 *
 * In most cases it's still simpler to then just return { ...store.getters } and always make a new object, but inside the object all the references are static.
 */

type Getters<T> = {
  [K in keyof T as `get${Capitalize<string & K>}`]: () => T[K];
};

type WithGetters<S> = S extends { getState: () => infer T }
  ? S & { getters: Getters<T> }
  : never;

export function extendWithGetters<S extends UseBoundStore<StoreApi<unknown>>>(
  _store: S
) {
  const store = _store as WithGetters<typeof _store>;
  const storeState = store.getState();
  if (isObjectType(storeState)) {
    store.getters = {};
    for (const key of Object.keys(storeState)) {
      const getterName = `get${key[0]?.toUpperCase()}${key.substring(1)}`;
      (store.getters as any)[getterName] = () =>
        store((s) => (isObjectType(s) ? s[key as keyof typeof s] : s));
    }
  }
  return store;
}

function isObjectType(store: unknown): store is object {
  return typeof store === "object" && store !== null;
}
