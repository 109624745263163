import React from "react";
import styled from "styled-components";
import { useTranslation, Trans } from "@coworker/locales";

const Container = styled.div`
  height: 100%;
  margin: 0px 29px;
`;

const Title = styled.h1`
  margin: 51px 0;
`;

export function EmptyFeedback() {
  // this hook is used duo to the heart not showing up (<3)
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t("allDoneString")}</Title>
      <p>{t("thankYouForFeedbackString")}</p>
      <p style={{ marginTop: "3em" }}>
        <i>
          <Trans>support2021MessageString</Trans>
        </i>
      </p>
    </Container>
  );
}
