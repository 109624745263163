import { useQuery } from "@tanstack/react-query";
import { getSalesStopData } from "../services/articles.service";

export const useSalesStopData = (storeId: string, articleNumbers: string) => {
  return useQuery({
    queryKey: ["salesStopData", storeId, articleNumbers],
    queryFn: () => getSalesStopData(storeId, articleNumbers),
    enabled: !!storeId && !!articleNumbers,
    cacheTime: 1000 * 60 * 15, // 15 minutes cache time
    staleTime: 1000 * 60 * 15, // 15 minutes stale time
  });
};
