import React from "react";
import styled from "styled-components";
import { ConfirmationPrompt } from "@coworker/components";
import posed, { PoseGroup } from "react-pose";
import { revealFromBelow } from "../../helpers/animationHelper";
import { isRDTDevice } from "@coworker/reusable";

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--black);
  opacity: 0.7;
  z-index: 102;
`;

const PosedBackdrop = posed(Backdrop)({});

const PosedConfirmation = revealFromBelow(ConfirmationPrompt);

function Confirmation({
  onSubmit,
  onClose,
  onCancel,
  question,
  description,
  negativeText,
  positiveText,
  swapButtons,
}) {
  const [mounted, setMounted] = React.useState(true);
  const callbackRef = React.useRef(null);
  const withUnmountDelay = React.useCallback((fn) => {
    setMounted(false);
    callbackRef.current = fn;
  }, []);
  const isRDT = isRDTDevice();

  return (
    <PoseGroup
      animateOnMount={!isRDT}
      onRest={() => callbackRef.current && callbackRef.current()}
    >
      {mounted && (
        <PosedBackdrop
          data-testid="backdrop"
          key="backdrop"
          onClick={() => withUnmountDelay(onClose)}
          animateOnMount={!isRDT}
        />
      )}
      {mounted && (
        <PosedConfirmation
          animateOnMount={!isRDT}
          key="confirmation"
          onConfirm={() => withUnmountDelay(() => onSubmit(true))}
          onDeny={() => withUnmountDelay(() => onCancel(false))}
          question={question}
          description={description}
          negativeText={negativeText}
          positiveText={positiveText}
          swapButtons={swapButtons}
        />
      )}
    </PoseGroup>
  );
}

export default Confirmation;
