const {
  getI18nLocaleCode,
  translations,
  ikeaLocales,
  baseLanguage,
} = require("./translations");
export { getI18nLocaleCode, translations, ikeaLocales, baseLanguage };
export { default as i18n } from "./util/i18n";
export { default as Trans } from "./util/Trans";
export { default as useTranslation } from "./util/useTranslation";
export { I18nextProvider, initReactI18next } from "react-i18next";
export { languageName } from "./util/languageName";
