import { ChecklistItem } from "@coworker/types/lib/tasks/testbuy_followup";
import {
  callInternal,
  TASK_TOGGLE_CHECKLIST_ITEM,
} from "../../../hooks/API/useCallInternal";
import { sortingIndexValue } from "../../TestBuy/ArticleDetailPage/CriterionComponent/CriteriaHelper";
import { updateTask } from "../../../services/task.service";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";

export function sortChecklistCriteriaDisplayOrder(
  checklist: ChecklistItem[]
): ChecklistItem[] {
  return [...checklist].sort(
    (first, second) =>
      sortingIndexValue(first.key) - sortingIndexValue(second.key)
  );
}

export const toggleChecklistItem = (
  taskKey: string,
  checklistKey: string,
  isDone: boolean
) => {
  // @ts-ignore
  const avoidFirebase = window.callTestbuyService;
  if (avoidFirebase) {
    const body = {
      markDoneChecklistKey: "",
      markNotDoneChecklistKey: "",
    };
    if (isDone) body.markDoneChecklistKey = checklistKey;
    if (!isDone) body.markNotDoneChecklistKey = checklistKey;
    updateTask(body, taskKey, taskTypeOptions.TESTBUY_FOLLOW_UP);
  } else {
    const toggleData = {
      taskKey,
      checklistKey,
      isDone,
    };
    callInternal(TASK_TOGGLE_CHECKLIST_ITEM, toggleData);
  }
};
