import React from "react";
import styled from "styled-components";
import { shallowEqual } from "react-redux";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { VariableSizeList } from "react-window";
import { delayed } from "../helpers/delayed";
import TaskCardLink from "./TaskCardLink";
import GroupByTitleTask from "./GroupByTitleTask";
import { useMultiSelectForTab } from "../hooks/useMultiSelect";
import { ProductHeader } from "./ProductHeader";
import { ViewingPartsOfTotal } from "./Reusable/ViewingPartsOfTotal";
import { onlyTasksFilter } from "../helpers/taskFilters";
import { logEvent } from "../helpers/tracker";
import { useHasNewNotesCheck } from "../hooks/useUnreads";

const DAY = 24 * 3600_000;

const VirtualScrollPositionContext = React.createContext(null);

const Container = styled.div`
  height: 100%;
  overflow: hidden;
`;

const areTasksEqual = (prevProps, nextProps) => {
  const next = nextProps.data;
  const prev = prevProps.data;
  // Rerender is needed when addon task filter options changes.
  // Rerender is needed when the items list changes (sorting)
  return (
    prev.isGroupHeader === next.isGroupHeader &&
    prev.isProductHeader === next.isProductHeader &&
    shallowEqual(prev.items, next.items) &&
    shallowEqual(prev.groupBy, next.groupBy) &&
    shallowEqual(prev.addonFilterOption, next.addonFilterOption)
  );
};

const ListItemRenderer = React.memo(({ index, style, data }) => {
  const item = data.items[index];

  if (item.isProductHeader) {
    return <ProductHeader key={`related${item.fullId}`} fullId={item.fullId} />;
  }

  if (item.isGroupHeader) {
    return (
      <GroupByTitleTask
        key={index + item.groupByTitle}
        style={style}
        groupBy={data.groupBy}
        header={item}
      />
    );
  }
  if (item.isViewingXOfYTasks) {
    return (
      <ViewingPartsOfTotal
        partCount={item.count}
        totalCount={item.totalCount}
        itemsString={"TasksString"}
      />
    );
  }

  return (
    <TaskCardLink
      testId={`virtualListItem${index}`}
      key={item.id + item.group}
      task={item}
      style={style}
      isDraggable={data.isDraggable}
      uid={data.uid}
      team_id={data.team_id}
      hasNewNote={!!data.hasNewNotes?.(item)}
      addonFilterOption={data.addonFilterOption}
      tabType={data.tabType}
      onClickTrack={() => {
        if (item?.state === taskStateOptions.CLOSED) {
          const age = Math.floor((Date.now() - (item?.finished_at || 0)) / DAY);
          if (age) logEvent("ce:taskcard:tap_closed_task", { age });
        }
      }}
    />
  );
}, areTasksEqual);

const VirtualTaskListDisplay = ({
  items = [],
  uid,
  team_id,
  isDraggable,
  addonFilterOption, // this was used to trigger rerender and get the location bolding right.
  filter, // What task type in filters that is selected
  groupBy,
  tabType,
  totalTasksCount,
}) => {
  const hasNewNotes = useHasNewNotesCheck();
  const [scrollRef, onScroll] = React.useContext(VirtualScrollPositionContext);

  const [containerHeight, setContainerHeight] = React.useState(0);
  const containerRef = React.useRef();
  const listRef = React.useRef();
  const { showingMultiSelect, selectedTasksCount } =
    useMultiSelectForTab(tabType);

  const containerKey = `${showingMultiSelect ? 1 : 0}${groupBy}${filter.join(
    ""
  )}${addonFilterOption.join("")}`;

  React.useLayoutEffect(() => {
    // When going back from task detail, bottom navigation pops up and makes the calculation incorrect.
    // We wait until the stack is empty to calculate height.
    setTimeout(() => {
      containerRef.current &&
        setContainerHeight(containerRef.current.clientHeight);
    }, 0);

    const prevScroll =
      scrollRef.current[window.location.pathname + window.location.search];

    if (prevScroll && listRef.current) {
      listRef.current.scrollTo(prevScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This trickery for Virtual list height was added in the initial implementation: https://github.com/ingka-group-digital/cmp-fixa/commit/eded45e890d91d0e518e017d000baad036fca1d9
  // via a PR in the previous GitHub enterprise instance, here: https://git.build.ingka.ikea.com/CoworkerApp/ikea-coworker-mvp/pull/1075
  React.useEffect(() => {
    let mounted = true;
    const delayedHandleResize = delayed(function handleResize() {
      // We need to resize our virtual scroll container whenever screen is resized so it will not overflow/underflow. In order to
      // make the calculation correct, we need to first reset it back to zero and then check container height that is based on
      // available space between the header and footer. If we did not reset the height, it would not work for resizing to smaller screens as container
      // would take the height of their children, which at that point would be higher than the container.
      if (!mounted) return;
      setContainerHeight(0);
      setTimeout(() => {
        if (
          mounted &&
          containerRef.current &&
          containerRef.current.clientHeight !== containerHeight
        ) {
          setContainerHeight(containerRef.current.clientHeight);
        }
      }, 0);
    }, 200);

    window.addEventListener("resize", delayedHandleResize);
    return () => {
      window.removeEventListener("resize", delayedHandleResize);
      mounted = false;
    };

    // NOTE: This was originally set up to run on each render, which means putting it into useEffect has very little effect (pun?)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onScrollEvent({ scrollOffset }) {
    if (containerRef.current) {
      onScroll({
        type: "push",
        location: window.location.pathname + window.location.search,
        value: scrollOffset,
      });
    }
  }

  // our virtual scroll needs to know height of every element in the list in advance. We need to calculate the height based
  // on its parameters and do the best approximation possible to now screw up the scrolling.
  function getCardHeight(index) {
    const cardItem = items[index] || {};
    let cardHeight = 121;

    if (cardItem.isGroupHeader || cardItem.isViewingXOfYTasks) return 50;
    if (cardItem.isProductHeader) return 118;

    const task = cardItem;
    // Flags
    if (task.priority_flag) cardHeight += 31;
    if (task.task_type === taskTypeOptions.PLANNED) cardHeight += 31;
    if (task.state === taskStateOptions.COMPLETED || hasNewNotes(task))
      cardHeight += 43;

    // Newly added texts
    if (task.state === taskStateOptions.CLOSED) {
      cardHeight += 31; // First line: dd/mm closed by (team name)
      if (task.close_type) cardHeight += 31; // Close type, translated
      if (task.close_reason) cardHeight += 31; // Optional free text user-entered explanation
    }

    if (task.type === taskTypeOptions.PRODUCT_QUALITY) cardHeight += 20; // Extra space for the "Reported by ... " line.
    if (!["my", "open"].includes(tabType)) cardHeight += 16; // Extra space to allow for some padding. Needed everywhere except on My and Open task lists we need space for "Assigned to: (long team na...)"

    // More or less lines of text to be expected
    if (task.task_type === taskTypeOptions.ADDON && task.item?.measurements) {
      cardHeight += tabType !== "related_product_tasks" ? 20 : 0;
    }

    return cardHeight;
  }
  // Multi selections is on, we remove Tabs and navBar from the view hence virtual task height needs to be adjusted.
  // when the button displayed need to reduce the virtual task height so we don't hide part of the last task.

  const navBarHeight = 90;
  const tabsHeight = 70;
  const additionalHeightMultiSelect =
    showingMultiSelect && !selectedTasksCount
      ? containerHeight + navBarHeight + tabsHeight
      : selectedTasksCount
      ? containerHeight + tabsHeight
      : containerHeight;

  const viewingfXOfYTasksItem = {
    isViewingXOfYTasks: true,
    count: onlyTasksFilter(items).length,
    totalCount: totalTasksCount,
  };

  const deleteFirstIfIsViewingXOfYTasks =
    items.length > 0 && items[0].isViewingXOfYTasks;

  items.splice(
    0,
    deleteFirstIfIsViewingXOfYTasks ? 1 : 0,
    viewingfXOfYTasksItem
  );

  return (
    <Container ref={containerRef} data-testid="virtualList" key={containerKey}>
      {!!items.length && (
        <VariableSizeList
          ref={listRef}
          height={
            showingMultiSelect ? additionalHeightMultiSelect : containerHeight
          }
          itemCount={items.length}
          itemSize={getCardHeight}
          onScroll={onScrollEvent}
          itemData={{
            // This is base data that is shared between all renders.
            items, // This is always an array
            uid,
            team_id,
            hasNewNotes,
            isDraggable,
            addonFilterOption,
            groupBy,
            tabType,
          }}
          width={"100%"}
        >
          {ListItemRenderer}
        </VariableSizeList>
      )}
    </Container>
  );
};

function reducer(state, action) {
  switch (action.type) {
    case "push":
      return {
        ...state,
        [action.location]: action.value,
      };
    default:
      return state;
  }
}

const VirtualScrollPositionProvider = ({ children }) => {
  const scrollRef = React.useRef({});

  function onScroll(action) {
    scrollRef.current = reducer(scrollRef.current, action);
  }

  return (
    <VirtualScrollPositionContext.Provider value={[scrollRef, onScroll]}>
      {children}
    </VirtualScrollPositionContext.Provider>
  );
};

export { VirtualScrollPositionProvider };
export default VirtualTaskListDisplay;
