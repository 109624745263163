import React from "react";
import { refilter, useGetTaskFilters } from "../../components/Tasks";
import {
  stringifyListOfUpdatedAt,
  useCachedTasks,
} from "../../services/tasks.service";

export function useCachedFilteredTasks(screenKey = "") {
  const { list, loading } = useCachedTasks(screenKey);
  const filters = useGetTaskFilters(screenKey, "");
  const filteredList = refilter(filters, list);
  const typeFilteredList = React.useMemo(
    () => refilter([filters[0]], list),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stringifyListOfUpdatedAt(list), filters]
  );
  return { list: filteredList, typeFilteredList, loading };
}
