import { Trans } from "@coworker/locales";
import { CompletedArticleState } from "@coworker/types";
import React from "react";
import styled from "styled-components";

const Container = styled.header`
  width: 100%;
  min-height: 68px;
  margin: 0px 24px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

interface ArticlesHeaderProps {
  count: number;
  type: CompletedArticleState;
}

const ArticlesHeader = ({ count, type }: ArticlesHeaderProps) => {
  const headerTransKey = React.useMemo(
    () => (type === "SUCCESS" ? "okWithNumberString" : "notOkWithNumberString"),
    [type]
  );

  return (
    <Container>
      <Trans count={count}>{headerTransKey}</Trans>
    </Container>
  );
};

export default React.memo(ArticlesHeader);
