import React, { createContext } from "react";
import {
  ScrollDirectionType,
  NO_SCROLL,
} from "@coworker/app/src/hooks/useScrollDirection";

interface ModalContextProps {
  scrollDirection: ScrollDirectionType;
  setScrollDirection: React.Dispatch<React.SetStateAction<ScrollDirectionType>>;
}

export const MainContentScrollDirectionContext =
  createContext<ModalContextProps>({
    scrollDirection: NO_SCROLL,
    setScrollDirection: () => {},
  });

const MainContentScrollDirectionContextProvider: React.FC = ({ children }) => {
  const [scrollDirection, setScrollDirection] =
    React.useState<ScrollDirectionType>(NO_SCROLL);

  return (
    <MainContentScrollDirectionContext.Provider
      value={{ scrollDirection, setScrollDirection }}
    >
      {children}
    </MainContentScrollDirectionContext.Provider>
  );
};

export default MainContentScrollDirectionContextProvider;
