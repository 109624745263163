import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div> Izjava o zaštiti privatnosti</div>,
  aboutFixaText: (
    <div>
      Fixa je alat za upravljanje zadacima i prodajno predstavljanje za
      zaposlenike koji rade u robnoj kući. Svrha aplikacije FIXA jest zamjena
      ručnih aktivnosti (bilo na papiru ili u aplikaciji Excel) te
      pojednostavljenje upravljanja zadacima u robnoj kući (dopuna proizvoda,
      čišćenje, testne kupovine, često postavljana pitanja itd.). Fixa omogućuje
      i analitiku putem uvida stečenih na temelju dovršenih zadataka.
      <br />
      <br />
      Kada upotrebljavaš aplikaciju Fixa, voditelj obrade podataka u sklopu
      tvrtke Ingka grupa (kao što je definirano u nastavku) prikupljat će
      određene osobne podatke o tebi.
    </div>
  ),

  personalDataCollectedTitle: (
    <div>Prikupljaju se sljedeći osobni podaci: </div>
  ),
  personalDataCollectedText: (
    <div>
      Kada upotrebljavaš aplikaciju FIXA, sustav će prikupljati podatke o tebi
      iz Baze podataka o zaposlenicima (Co-Worker Database, CDS) kao što su ime,
      srednje ime, prezime, adresa e-pošte, naziv radnog mjesta, uloga,
      telefonski broj (ako je ručno naveden – osobni telefonski brojevi ne bi se
      trebali unositi), fotografija iz CDS-a, preferencija jezika u aplikaciji,
      ID robne kuće, verzija uređaja i operacijskog sustava, IP adresa koja se
      obrađuje na Googleovoj platformi u oblaku i platformi Sentry (Sentry je
      softver za praćenje grešaka u aplikacijama).
      <br />
      <br />
      Voditelj/superkorisnik ručno izrađuje profil zaposlenika putem aplikacije
      Fixa-admin (administratorske internetske aplikacije). Osobni podaci
      dohvaćaju se iz CDS-a, pa je profile moguće izraditi samo za zaposlenike
      koji imaju aktivan profil u CDS-u.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>Svrha je prikupljanja navedenih podataka sljedeća:</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      Osobni se podaci u aplikaciji Fixa prikupljaju radi pravilnog
      funkcioniranja aplikacije i to na način da omogućuje jednostavnu internu
      komunikaciju među zaposlenicima i administraciju zadataka među
      zaposlenicima u robnoj kući. Povezivanje zadataka s korisnikom omogućuje
      nam da podržimo slanje obavijesti o ažuriranjima zadataka dodijeljenim
      korisnicima i autorima.
    </div>
  ),
  legalGroundsTitle: <div>Pravna je osnova za obradu podataka sljedeća:</div>,
  legalGroundsText: (
    <div>Pravna je osnova za obradu podataka legitiman interes.</div>
  ),
  dataWillBeStoredTitle: <div>Podaci će se čuvati:</div>,
  dataWillBeStoredText: (
    <div>
      Dovršene zadatke s referencama na korisnike čuvamo 70 dana, a nakon toga
      postaju anonimni. Osobni podaci zaposlenika čuvaju se u aplikaciji Fixa
      tijekom trajanja ugovora o radu.
      <br />
      <br />
      Raskid ugovora o radu s Ingka grupom: Svi osobni podaci automatski se
      brišu jedan mjesec nakon uklanjanja računa iz CDS-a.
      <br />
      <br />
      Promjena uloge unutar Ingka grupe: Ako zaposlenik više ne mora
      upotrebljavati aplikaciju Fixa, svi osobni podaci automatski se brišu
      nakon tri mjeseca neaktivnosti.
      <br />
      <br />
      Osobni se podaci mogu ukloniti i u bilo kojem trenutku na zahtjev lokalnog
      voditelja zaduženog za informacijsku sigurnost i privatnost podataka ili
      voditelja robne kuće.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>Voditelj obrade podataka u ovom slučaju obrade podataka jest:</div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA Croatia,
      <br />
      Alfreda Nobela 2,
      <br />
      10361, Otok Svibovski, Zagreb
      <br />
      <br />
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      Imaš određena prava kada je riječ o tvojim osobnim podacima. Posjeti
      stranicu i saznaj više o tim pravima.
      <br />
      <br />
      Podaci za kontakt: <EmailLink email="data.protection.hr@ingka.ikea.com" />
    </div>
  ),
};
