import React from "react";
import { ArticleWithRoomCount } from "../types/article";
import { ListWrapper } from "../styles/styles";
import { ArticleListItem } from "../Molecules/ArticleListItem";
import { TaskLinks } from "../types/taskLink";
import { hasOngoingTask } from "../Molecules/RoomArticles.helper";
import { useEDSData } from "../hooks/useEDSData";
import { useCountryId } from "../../../core/auth/useLoggedInUser";
import { joinProductArticleIds } from "../tools";
import { useSalesStopData } from "../hooks/useSalesStopData";

interface RoomsViewArticleListProps {
  articles: ArticleWithRoomCount[];
  taskLinks: TaskLinks;
  storeId: string;
}

export const RoomsViewArticleList = ({
  articles,
  taskLinks,
  storeId,
}: RoomsViewArticleListProps) => {
  const countryId = useCountryId();
  const articleNumbers = joinProductArticleIds(articles);
  const { data: edsData } = useEDSData(countryId, articleNumbers);
  const { data: salesStopData } = useSalesStopData(storeId, articleNumbers);

  return (
    <ListWrapper>
      {articles &&
        articles.map((article, index) => (
          <ArticleListItem
            key={`${article.id}-${index}`}
            article={article}
            isMainProduct={() => false}
            isBackupForProduct={() => false}
            hasOngoingTask={() => hasOngoingTask(article.id, taskLinks)}
            edsData={
              edsData?.find(
                (eds: { productArticleId: string }) =>
                  eds.productArticleId === article.productArticleId
              ) ?? null
            }
            salesStopData={
              salesStopData?.find(
                (salesStop) =>
                  salesStop.productArticleId === article.productArticleId
              ) ?? null
            }
            selectArticleIdsMode={false}
            isSelected={false}
            showActionRow={false}
          />
        ))}
    </ListWrapper>
  );
};
