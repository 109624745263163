const iOS_1to12 = /iPad|iPhone|iPod/.test(navigator.platform);
const iOS13_iPad =
  navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
const iOS1to12quirk = function () {
  var audio = new Audio(); // temporary Audio object
  audio.volume = 0.5; // has no effect on iOS <= 12
  return audio.volume === 1;
};
// All iOS versions up to and including iOS 13
const isIOS = !window.MSStream && (iOS_1to12 || iOS13_iPad || iOS1to12quirk());

const isChromePWA =
  window.navigator.vendor.includes("Google") &&
  window.matchMedia("(display-mode: standalone)").matches;

// Opera 8.0+
const isOpera =
  (!!window.opr && !!window.opr.addons) ||
  navigator.userAgent.indexOf(" OPR/") >= 0 ||
  !!window.opera;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== "undefined";

// Safari 3.0+ "[object HTMLElementConstructor]"
const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(!window["safari"] || window.safari.pushNotification);

// Internet Explorer 6-11
const isIEorEdge = /*@cc_on!@*/ false || document.documentMode;

const isChrome =
  window.chrome && !isIEorEdge && !isOpera && !isFirefox && !isSafari; // Expected working on Chrome 1-85+

const isUnsupportedBrowser =
  (!isIOS || !IntersectionObserver) && !isChromePWA && !isChrome; // We show this warning only if not iOS AND not Chrome.

const isAndroid = /Android/i.test(navigator.userAgent);
const isDesktop = !isAndroid && !isIOS;

export { isUnsupportedBrowser, isIOS, isChrome, isAndroid, isDesktop };
