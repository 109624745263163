import React from "react";
import styled from "styled-components";
import { useAvatarImage } from "../hooks/API/avatar.service";

const AvatarPhoto = styled.img`
  min-width: ${(props: any) => (props.big ? "157px" : "40px")};
  min-height: ${(props: any) => (props.big ? "157px" : "40px")};
  max-width: ${(props: any) => (props.big ? "157px" : "40px")};
  max-height: ${(props: any) => (props.big ? "157px" : "40px")};
  border-radius: 50%;
  object-fit: cover;
`;

interface AvatarImageProps {
  fixaUid: string;
  big: boolean;
  onClick: () => void;
}

interface AvatarPhotoProps {
  src: string;
  big: boolean;
  onClick: () => void;
  "data-testid": string;
}

function AvatarImage(props: AvatarImageProps) {
  const photoUrl = useAvatarImage(props.fixaUid);

  const avatarPhotoProps: AvatarPhotoProps = {
    src: photoUrl,
    big: props.big,
    onClick: props.onClick,
    "data-testid": "avatarPhoto",
  };

  return <AvatarPhoto {...avatarPhotoProps} />;
}

export default AvatarImage;
