import React from "react";
import { useTranslation } from "@coworker/locales";
import { RefillTypes } from "../../../constants/refillTypes";
import {
  DetailSection,
  DetailSectionLeft,
  SectionTitle,
  SectionText,
} from "./_common";
import { AddonTask } from "@coworker/types/lib/tasks/addon";

interface RefillTypeProps {
  task: AddonTask;
}

function RefillType({ task }: RefillTypeProps) {
  const { t } = useTranslation();

  const getRefillTypeText = (refillType?: string) => {
    switch (refillType) {
      case RefillTypes.REGULAR:
        return t("refillTypeRegularString");
      case RefillTypes.FIRST_FILL:
        return t("refillTypeFirstFillString");
      default:
        return t("refillTypeRegularString");
    }
  };
  return (
    <DetailSection data-testid="refillTypeSection">
      <DetailSectionLeft>
        <SectionTitle>{t("refillTypeString")}</SectionTitle>
        <SectionText>{getRefillTypeText(task?.refill_type)}</SectionText>
      </DetailSectionLeft>
    </DetailSection>
  );
}

export default RefillType;
