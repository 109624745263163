import React from "react";
import { callInternalApi } from "./API/useCallInternal";
import { create } from "zustand";
import { useStoreId, useTeamId, useUserId } from "../core/auth/useLoggedInUser";
import { methodForTaskList } from "../services/tasks.service";

const MAX_TASK_AGE = 1_000;
const MAX_LIST_AGE = 10_000;

const useTasksStore = create((set, get) => ({
  mergeTasks: set,
  getTask: (id) => get(id)[id],
  findStaleTasks: (ids) => {
    return ids.filter((id) => {
      const { fetched_at } = get(id)[id] || {};
      // Fetch if not fetched or too stale
      return !fetched_at || fetched_at < Date.now() - MAX_TASK_AGE;
    });
  },
}));

const useListsStore = create((set, get) => ({
  mergeLists: set,
  get,
  getList: (id) => get(id)[id] || {},
}));

async function fetchTasksById(ids, set, storeId, teamId) {
  const fetched_at = Date.now();
  const path = `tasks/by_ids?ids=${ids.join(",")}`;
  const response = await callInternalApi(path, {
    store_id: storeId,
    team_id: teamId,
  });

  const list = response?.data?.tasks || response?.list || response?.tasks || [];
  if (list.length) {
    const freshTasks = {};
    for (const task of list) {
      freshTasks[task.id] = Object.assign(task, { fetched_at });
    }
    set(freshTasks); // Will merge new into the existing :)
  }
}

// TODO: reuse the fetched_at approach also in getCachedInternalApi

export function useWatchedTask(taskId) {
  const storeId = useStoreId();
  const teamId = useTeamId();
  const { mergeTasks, getTask, findStaleTasks } = useTasksStore();
  const [isStale] = findStaleTasks([taskId]);
  React.useEffect(() => {
    if (isStale) fetchTasksById([taskId], mergeTasks, storeId, teamId);
  }, [taskId, mergeTasks, isStale, storeId, teamId]);
  return getTask(taskId);
}

export function useListCache(method = "", maxAge = MAX_LIST_AGE) {
  const { getList, mergeLists, ...stored } = useListsStore();
  const toCache = React.useCallback(
    (key, value) => {
      mergeLists({ [key]: { cached: value, fetched_at: Date.now() } });
    },
    [mergeLists]
  );
  const found = stored[method] || {};
  const { fetched_at, cached } = found;
  const doRefetch = !fetched_at || fetched_at < Date.now() - maxAge;
  return { cached, doRefetch, toCache };
}

/**
 * @returns {Promise} Resolves after refetching a single task
 * Used when we know the TASK or LIST is stale,
 * i.e. after pressing a Pickup button while we still stay on the same screen
 *  and wait for the task state to update, so we can then press Complete.
 */
export function useTaskRefresher() {
  const { getTask, mergeTasks } = useTasksStore();
  const { getList, mergeLists } = useListsStore();
  const uid = useUserId();
  const teamId = useTeamId();
  return React.useCallback(
    (taskId, changes, original) => {
      // Specific task
      if (taskId) {
        const found = getTask(taskId);
        mergeTasks({ [taskId]: { ...found, fetched_at: 0 } });
      }
      // Lists
      const isMine = ({ assigned_user_id: id }) => uid === id;
      const mark = (method) => {
        const { cached } = getList(method);
        if (cached) mergeLists({ [method]: { cached } }); // mark stale: remove fetched_at
      };
      if (original.creator_id === uid) mark(methodForTaskList("created_by_me"));
      if (isMine(changes) !== isMine(original)) {
        mark(methodForTaskList("my", teamId));
        mark(methodForTaskList("open", teamId));
      }
      // TODO: Also handle activities-lists.
    },
    [getTask, mergeTasks, getList, mergeLists, teamId, uid]
  );
}
