import React from "react";
import { useTranslation } from "@coworker/locales";
import { isIOS } from "../../../helpers/browserChecks";
import { Button } from "@coworker/components";
import styled, { css } from "styled-components";
import { useTextAreaPopup } from "../_common";
import { FULLSCREEN_MAX_TEXT_LENGTH } from "../../../constants/textInput";

const ExpandableEmptyFlexFull = styled.div`
  flex-shrink: 10000;
  width: 100%;
  flex-grow: 10000;
`;

const StyledTextArea = styled.textarea`
  resize: none;
  width: calc(100% - 52px);
  margin: 5px 0px;
  padding: 0px 26px;
  margin-top: 96px;
  overflow: hidden;
  flex-grow: 1;
  font-size: 24px;
  line-height: 32px;
  color: black;
  /* for iOS we make stuff smaller at 758 pixels as this is 500 + keyboard height */
  @media (min-height: ${isIOS ? "758px" : "500px"}) {
    margin: 122px 0px 26px 0px;
  }
`;

const SubmitButton = styled(Button)`
  margin: 8px;
  flex-shrink: 0;
  /* for iOS we make stuff smaller at 758 pixels as this is 500 + keyboard height */
  @media (min-height: ${isIOS ? "758px" : "500px"}) {
    margin: 20px;
  }
`;

const AddNoteContainer = styled.div<{ focused?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  /* On iOS we need to reduce Container height as the keyboard popping up will not reduce it
  https://craiggrummitt.com/2019/02/22/getting-the-keyboard-height/ */
  ${({ focused }) =>
    focused &&
    isIOS &&
    css`
      height: calc(100% - 258px);
    `}
`;

const ScrollContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  position: relative;
`;
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

function Note({
  value = "",
  onAddNote,
  isFormInvalid,
  onValueChanged,
  children,
}: {
  value: string;
  onAddNote?: (event: React.MouseEvent) => void;
  isFormInvalid?: () => boolean;
  onValueChanged?: React.ChangeEventHandler<HTMLTextAreaElement>;
  children: React.ReactNode;
}) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { textArea, onBlur } = useTextAreaPopup(containerRef, value, {
    lineHeight: 32,
    verticalPadding: 0, // we use margin instead of padding for this one
    initialLines: 3,
  });

  const { t } = useTranslation();

  return (
    <AddNoteContainer>
      <ScrollContainer ref={containerRef}>
        <FlexContainer>
          <StyledTextArea
            data-testid="note-textarea"
            autoFocus
            onBlur={onBlur}
            maxLength={FULLSCREEN_MAX_TEXT_LENGTH}
            ref={textArea}
            placeholder={t("placeholder2String")}
            value={value}
            onChange={onValueChanged}
          />
          <ExpandableEmptyFlexFull onClick={() => textArea?.current?.focus()} />
          {children}
        </FlexContainer>
      </ScrollContainer>
      <SubmitButton
        customMargin
        primary
        dark
        disabled={isFormInvalid && isFormInvalid()}
        onClick={onAddNote}
        text={t("doneString")}
        data-testid="note-submit"
      />
    </AddNoteContainer>
  );
}

export default Note;
