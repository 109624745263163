import React from "react";
import { ListItemContainer } from "../styles/styles";
import { SelectablePill } from "../Atoms/SelectablePill";
import { useTranslation } from "@coworker/locales";
import { TaskOwner } from "../constants";

interface TaskListingFilterProps {
  setTaskOwner: (taskOwner: TaskOwner) => void;
}

export function TaskListingFilter({
  setTaskOwner: taskFilter,
}: TaskListingFilterProps) {
  const { t } = useTranslation();
  const [mineSelected, setMineSelected] = React.useState<boolean>(true);
  const [teamSelected, setTeamSelected] = React.useState<boolean>(false);
  const [allSelected, setAllSelected] = React.useState<boolean>(false);

  return (
    <ListItemContainer>
      <SelectablePill
        label={t("myString")}
        selected={mineSelected}
        onClick={() => {
          setMineSelected(true);
          setTeamSelected(false);
          setAllSelected(false);
          taskFilter(TaskOwner.Mine);
        }}
      />
      <SelectablePill
        label={t("myTeamString")}
        selected={teamSelected}
        onClick={() => {
          setMineSelected(false);
          setTeamSelected(true);
          setAllSelected(false);
          taskFilter(TaskOwner.Team);
        }}
      />
      <SelectablePill
        label={t("userTaskFilterAllString")}
        selected={allSelected}
        onClick={() => {
          setMineSelected(false);
          setTeamSelected(false);
          setAllSelected(true);
          taskFilter(TaskOwner.All);
        }}
      />
    </ListItemContainer>
  );
}
