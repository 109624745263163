import React from "react";
import { useInputPopup } from "../../components/InputPopup/index";
import { useOnline } from "../../core/hooks/useOnline";
import { useMultiSelectForTab } from "../../hooks/useMultiSelect";
import {
  callInternal,
  callInternalApi,
  UPDATE_TASK,
} from "../../hooks/API/useCallInternal";
import { Trans, useTranslation } from "@coworker/locales";
import activityTypes from "@coworker/functions/src/enums/activityTypes";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import {
  dropTypeKeys,
  dropTypeOptions,
} from "@coworker/functions/src/enums/dropTypes";
import {
  closeTypeKeys,
  closeTypeOptions,
} from "@coworker/functions/src/enums/closeTypes";
import { useToastNotification } from "@coworker/components";
import trackerHelper from "../../helpers/tracker";
import { ReactComponent as Time } from "../../assets/svg/time.svg";
import { ReactComponent as Issue } from "../../assets/svg/issue.svg";
import { ReactComponent as IssueOther } from "@coworker/reusable/svg/three-dots.svg";
import { ReactComponent as InfoCircle } from "../../assets/svg/information-circle.svg";
import { ReactComponent as Incorrect } from "../../assets/svg/incorrect.svg";
import { ReactComponent as AlreadyResolved } from "../../assets/svg/test-buy.svg";
import { ReactComponent as NotInStock } from "../../assets/svg/not-in-stock.svg";
import {
  useUserId,
  useTeamId,
  useStoreId,
} from "../../core/auth/useLoggedInUser";
import { reportMessageToSentry } from "../../hooks/useConfigureSentry";

const { CLOSE, COMPLETE, REASSIGN, REASSIGN_TEAM, DROP, PICK_UP } =
  activityTypes;

export function useMultiselectOverflowMenu(count, type, selectedTaskIds) {
  const { getInput, closePopup } = useInputPopup();
  const { showToast } = useToastNotification();
  const uid = useUserId();
  const teamId = useTeamId();
  const storeId = useStoreId();
  const { isOnline } = useOnline();
  const { cancel } = useMultiSelectForTab(type);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation();
  const failedTasks = [];

  const makeBaseFields = (
    activity_type,
    assigned_user_id,
    assigned_team_id
  ) => ({
    activity_type,
    assigned_user_id,
    assigned_team_id,
    last_editor_id: uid,
    state:
      activity_type === CLOSE
        ? taskStateOptions.CLOSED
        : activity_type === COMPLETE
        ? taskStateOptions.COMPLETED
        : assigned_user_id
        ? taskStateOptions.ASSIGNED
        : taskStateOptions.UNASSIGNED,
  });

  const makeTaskChanger = (taskOption, data) => {
    switch (taskOption) {
      case DROP:
        return () => ({
          ...makeBaseFields(DROP, "", teamId),
          drop_type: data.issue,
          drop_description: data.description,
        });
      case PICK_UP:
        return () => makeBaseFields(PICK_UP, uid, teamId);
      case REASSIGN:
        const reassignTeamOrUser =
          data.gid && data.uid ? REASSIGN : REASSIGN_TEAM;
        return () => makeBaseFields(reassignTeamOrUser, data.uid, data.gid);
      case CLOSE:
        return () => ({
          ...makeBaseFields(CLOSE, uid, teamId),
          close_type: data.issue,
          close_description: data.description,
        });
      case COMPLETE:
        return () => makeBaseFields(COMPLETE, uid, teamId);
      default:
    }
  };
  const shouldProcess = React.useRef(true);

  const showResults = (failedTasksCount) => {
    failedTasksCount && failedTasksCount <= count
      ? showToast(
          t("nTasksNotChangedString", {
            number: count - failedTasksCount,
            count: count,
          }),
          <InfoCircle />,
          () => getInput("multiSelectPopup")
        )
      : showToast(<Trans count={count}>nTasksChangedString</Trans>);
  };

  const processTasks = async (taskIds, taskOption, activity) => {
    const whenApplyChangeFails = (error, data, taskId) => {
      const info = {
        Activity: data.activity_type,
        State: data.state,
        user_team_id: teamId,
        taskId,
      };
      console.log("info", info, "error", error); // Just local console so it appears in Sentry if we run into any other additional problem.
      if (
        (data.state === taskStateOptions.CLOSED && activity === CLOSE) ||
        (data.state === taskStateOptions.COMPLETED && activity === COMPLETE) ||
        (data.state === taskStateOptions.UNASSIGNED && activity === DROP)
      ) {
        console.log(info); // Not important - just local console, so it appears in Sentry if we run into any other additional problem.
      } else {
        reportMessageToSentry(
          "Error processing tasks in multiselect mode.",
          info
        );
      }
      failedTasks.push(taskId);
    };

    const applyChange = (data, taskId) => {
      if (window.callTasksService || window.forwardToTasksService) {
        callInternalApi("update/task", {
          taskId,
          updateData: data,
        }).catch((error) => whenApplyChangeFails(error, data, taskId));
        if (!window.forwardToTasksService) return;
      }
      callInternal(UPDATE_TASK, {
        data,
        taskId,
        store_id: storeId,
        team_id: teamId,
      }).catch((error) => whenApplyChangeFails(error, data, taskId));
    };

    const taskChanger = makeTaskChanger(taskOption, activity);
    const changes = taskChanger();
    const makeChanges = async () => {
      await Promise.all(taskIds.map((taskId) => applyChange(changes, taskId)));
      trackerHelper.multipleSelect.logProcessed(
        taskIds.length,
        failedTasks.length,
        taskOption
      );

      setLoading(false);
      const failedTasksCount = failedTasks.length;
      showResults(failedTasksCount); // Note: Maybe skip showing results in some cases?
      cancel(); // Empty list and set multiSelect to false
    };

    console.log("Multiselect: making changes", taskIds, taskOption, activity);
    if (taskOption === CLOSE || taskOption === COMPLETE) {
      // show toast
      //include on destroy calls makeChanges
      shouldProcess.current = true;
      showToast(
        <Trans>multiSelectProcessing</Trans>,
        <Trans>undoString</Trans>,
        () => {
          shouldProcess.current = false;
          setLoading(false);
        },
        undefined,
        undefined,
        () => {
          if (shouldProcess.current) makeChanges();
        }
      );
    } else {
      await makeChanges();
    }
  };

  const completeTask = {
    isDisabled: !isOnline,
    onClick: async () => {
      setLoading(true);
      await processTasks(selectedTaskIds, COMPLETE, uid);
      //We need to close the popup here to avoid disabling the layout.
      closePopup();
    },
    title: <Trans>completeTasksString</Trans>,
    enum: COMPLETE,
  };

  const dropTask = {
    isDisabled: !isOnline,
    onClick: async () => {
      const dropTaskReason = await getInput("taskAction", {
        customTitle: <Trans>whyAreYouDroppingString</Trans>,
        content: [
          {
            icon: Time,
            name: <Trans>{dropTypeKeys.END_OF_SHIFT}</Trans>,
            type: dropTypeOptions.END_OF_SHIFT,
          },
          {
            icon: Issue,
            name: <Trans>{dropTypeKeys.ISSUE}</Trans>,
            type: dropTypeOptions.ISSUE,
          },
          {
            icon: IssueOther,
            name: <Trans>{dropTypeKeys.OTHER}</Trans>,
            type: dropTypeOptions.OTHER,
            descriptionRequired: true,
          },
        ],
      });
      if (!dropTaskReason) return;
      setLoading(true);
      await processTasks(selectedTaskIds, DROP, dropTaskReason);
    },
    title: <Trans>dropTasksString</Trans>,
    enum: DROP,
  };

  const pickupTask = {
    isDisabled: !isOnline,
    onClick: async () => {
      setLoading(true);
      await processTasks(selectedTaskIds, PICK_UP, uid);
      //We need to close the popup here to avoid disabling the layout.
      closePopup();
    },
    title: <Trans>pickTasksString</Trans>,
    enum: PICK_UP,
  };

  const closeTask = {
    isDisabled: !isOnline,
    onClick: async () => {
      const closeTaskReason = await getInput("taskAction", {
        customTitle: <Trans>whyRUClosingString</Trans>,
        content: [
          {
            icon: Incorrect,
            name: <Trans>{closeTypeKeys.INCORRECT}</Trans>,
            type: closeTypeOptions.INCORRECT,
          },
          {
            icon: AlreadyResolved,
            name: <Trans>{closeTypeKeys.ALREADY_RESOLVED}</Trans>,
            type: closeTypeOptions.ALREADY_RESOLVED,
          },
          {
            icon: NotInStock,
            name: <Trans>{closeTypeKeys.NOT_IN_STOCK}</Trans>,
            type: closeTypeOptions.NOT_IN_STOCK,
          },
          {
            icon: IssueOther,
            name: <Trans>{closeTypeKeys.OTHER}</Trans>,
            type: closeTypeOptions.OTHER,
            descriptionRequired: true,
          },
        ],
      });
      if (!closeTaskReason) return;
      setLoading(true);
      await processTasks(selectedTaskIds, CLOSE, closeTaskReason);
    },
    title: <Trans>closeTasksString</Trans>,
    enum: CLOSE,
  };

  const isMyTasks = type === "my";
  const actions = [
    {
      title: (
        <h4>
          <Trans count={count}>applyNTasksString</Trans>
        </h4>
      ),
    },
    isMyTasks ? dropTask : pickupTask,
    completeTask,
    {
      isDisabled: !isOnline,
      onClick: async () => {
        const assignTo = await getInput("selectAssignees", {});
        if (!assignTo) return;
        setLoading(true);
        await processTasks(selectedTaskIds, REASSIGN, assignTo);
      },
      title: <Trans>reassignTasksString</Trans>,
      enum: REASSIGN,
    },
    closeTask,
  ];
  const getTaskMenu = () => getInput("popupMenu", { actions });

  return { loading, getTaskMenu };
}
