/**
 * Type codified in the types package
 * @type {Record<import("@coworker/types/lib/task_constants").PQHowOptions, string>}
 */
const pqHowOptions = {
  CUSTOMER_RETURNED: "CUSTOMER_RETURNED",
  DISPLAY_ITEM: "DISPLAY_ITEM",
  CUSTOMER_TOLD: "CUSTOMER_TOLD",
  CUSTOMER_INFORMED: "CUSTOMER_INFORMED",
  UNPACKING_ASSEMBLING: "UNPACKING_ASSEMBLING",
  UNPACKING: "UNPACKING",
  ASSEMBLING: "ASSEMBLING",
  IN_LOCATION: "IN_LOCATION",
  OTHER: "OTHER",
};

module.exports = { pqHowOptions };
