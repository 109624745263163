import { TestBuyProductWithCriteria } from "@coworker/types";

export function articleFactory() {
  const article: TestBuyProductWithCriteria = {
    id: "",
    shortId: "",
    name: "",
    description: "",
    smallImage: "",
    price: 0,
    currency: "",
    location: "",
    criteria: [],
  };
  return article;
}
