import React from "react";
import styled from "styled-components";
import Skeleton from "@ingka/skeleton";

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  margin: auto;
  padding: 0.5rem;
`;

const Wrapper = styled.div`
  margin: 0.8rem 0;
`;

export const QuantitySteppersSkeleton = () => {
  return (
    <Container>
      <Wrapper>
        <Skeleton width="100%" height="34px" />
      </Wrapper>
      <Wrapper>
        <Skeleton width="100%" height="34px" />
      </Wrapper>
      <Wrapper>
        <Skeleton width="100%" height="34px" />
      </Wrapper>
      <Wrapper>
        <Skeleton width="100%" height="34px" />
      </Wrapper>
      <Wrapper>
        <Skeleton width="100%" height="34px" />
      </Wrapper>
    </Container>
  );
};
