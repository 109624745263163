import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteShoppingTool } from "../ShoppingToolsAPI";

export const useDeleteShoppingTool = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deleteShoppingTool,
    onSuccess: () => {
      queryClient.invalidateQueries(["shopping-tools-progress-by-round"]);
      queryClient.invalidateQueries(["shopping-tools-chart-data"]);
      queryClient.invalidateQueries(["shopping-tools-todays-progress"]);
      queryClient.invalidateQueries(["shopping-tools-latest"]);
    },
  });
};
