import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  font-size: 14px;
  flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
  justify-content: ${(props) =>
    props.horizontal ? "space-between" : "initial"};
  flex: 1;
`;

export const ListContainer = styled.div`
  ${Container} {
    padding: 13px 28px;

    &:first-child {
      padding-top: 26px;
    }

    &:last-child {
      padding-bottom: 26px;
    }
  }
`;

const Title = styled.div`
  ${({ right }) =>
    right
      ? css`
          text-align: right;
        `
      : css`
          text-align: left;
        `},
  ${({ inverse }) =>
    inverse
      ? css`
          font-weight: bold;
        `
      : css`
          color: var(--grey700);
        `}
`;

const Content = styled.div`
  font-weight: bold;
  ${({ inverse }) =>
    inverse
      ? css`
          color: var(--grey700);
        `
      : css`
          font-weight: bold;
        `}
  ${({ right }) =>
    right
      ? css`
          text-align: right;
        `
      : css`
          text-align: left;
        `},
`;

const ListItem = ({
  title,
  children,
  inverse = false,
  horizontal = false,
  right = false,
}) => {
  return (
    <Container horizontal={horizontal}>
      {title && (
        <Title inverse={inverse} right={right}>
          {title}
        </Title>
      )}
      {children && (
        <Content inverse={inverse} right={right}>
          {children}
        </Content>
      )}
    </Container>
  );
};

export default ListItem;
