import React from "react";
import styled from "styled-components";

const Chart = styled.div`
  position: relative;
  margin-top: ${(props) => props.topMargin}px;
  width: ${(props) => props.width}px;
  align-self: ${(props) => (props.centered ? "center" : "unset")};
`;

const ChartCaption = styled.div`
  width: 100%;
  position: absolute;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1.5;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Number = styled.div`
  font-size: 70px;
  line-height: 70px;
  position: relative;
`;

const Percent = styled.div`
  font-size: 20px;
  line-height: 1;
  margin-top: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

function TwoColorDonut({
  innerRadius = 100,
  radius = 140,
  primaryColor = "#0a8a00",
  baseColor = "#E5E5E5",
  percent,
}) {
  const quotient = percent >= 0 && percent <= 100.0 ? percent / 100.0 : 0;
  const strokeWidth = radius - innerRadius;
  const r = innerRadius + strokeWidth;
  const circumference = 2 * Math.PI * r;
  return (
    <svg
      width="100%"
      viewBox={`-${strokeWidth / 2} -${strokeWidth / 2} ${
        strokeWidth + 2 * r
      } ${strokeWidth + 2 * r}`}
    >
      <circle
        r={r}
        cx={r}
        cy={r}
        fill="transparent"
        stroke={baseColor}
        strokeWidth={strokeWidth}
      />
      <circle
        r={r}
        cx={r}
        cy={r}
        fill="transparent"
        stroke={primaryColor}
        strokeWidth={strokeWidth}
        strokeDasharray={`${quotient * circumference} ${circumference}`}
        transform={`rotate(-90) translate(-${2 * r})`}
      />
    </svg>
  );
}

const DonutChart = ({
  width = 300,
  innerRadius = 100,
  radius = 140,
  title,
  percent = 100,
  failurePercent = 0,
  enabled,
  topMargin = 16,
}) => {
  return (
    <Chart
      width={width}
      topMargin={topMargin}
      centered
      data-testid="insight_pie_chart"
    >
      <TwoColorDonut
        percent={enabled ? percent : null}
        innerRadius={innerRadius}
        radius={radius}
        primaryColor={percent < failurePercent ? "red" : "green"}
      />
      <ChartCaption>
        {title && <Row>{title}</Row>}
        {!enabled ? null : (
          <Row>
            <Number>{Math.round(percent)}</Number>
            <Percent>%</Percent>
          </Row>
        )}
      </ChartCaption>
    </Chart>
  );
};

export default DonutChart;
