import React from "react";
import styles from "./task-card.module.css";
import SSRIcon from "@ingka/ssr-icon";
import { useTranslation } from "@coworker/locales";
import peopleIcon from "@ingka/ssr-icon/paths/people";
import { useLocation } from "react-router-dom";
import { useStoreTeams } from "@coworker/app/src/core/hooks/useStoreTeams";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

interface TeamNameProps {
  state: string;
  assignedTeamId: string;
}

export const Assignee = ({ state, assignedTeamId }: TeamNameProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  const teams = useStoreTeams();
  const teamName =
    teams.find((team) => team.id === assignedTeamId)?.name ??
    t("unknownTeamString");

  const isTaskClosed = state === taskStateOptions.CLOSED;

  return (
    <>
      {(location.pathname === "/activity" ||
        location.search === "?active=activity") &&
        !isTaskClosed && (
          <div className={styles["assignee-wrapper"]}>
            <SSRIcon
              className={styles["assignee-icon"] as string}
              paths={peopleIcon}
            />
            {teamName}
          </div>
        )}
    </>
  );
};
