import { DivisionsForDB } from "./../../../../../../packages/types/lib/testbuy.d";
import {
  DEFAULT_DIVISION_AMOUNT,
  DIVISION_HASH,
} from "./../../../TestBuy/constants";
import { Division, TypesOfDivisions } from "@coworker/types";

export function showTab(
  tabKey: TypesOfDivisions | undefined,
  divisions: Division[]
): boolean {
  //filters out unique division_id:s in divisions and checks if the tabKey exists
  const uniqueDivisionIds = new Set();

  if (divisions) {
    for (const division of divisions) {
      const { division_id } = division;
      if (!uniqueDivisionIds.has(division_id)) {
        uniqueDivisionIds.add(division_id);
      }
    }
  }
  return uniqueDivisionIds.has(tabKey);
}

function getRandomHfb(divisionList: Division[]) {
  const randomIndex = Math.floor(Math.random() * divisionList.length);
  return divisionList[randomIndex];
}

export function saveChosenDivision(
  wholeDivision: Division[],
  currentCheckedButton: string,
  activeDivisionsTab: string
) {
  const division: string[] = [];
  if (wholeDivision?.length > 0) {
    if (wholeDivision.length > DEFAULT_DIVISION_AMOUNT!) {
      for (let i = 0; i < DEFAULT_DIVISION_AMOUNT!; ) {
        const randomHfb = getRandomHfb(wholeDivision);
        if (randomHfb?.hfb && !division.includes(randomHfb.hfb)) {
          division.push(randomHfb.hfb);
          i++;
        }
      }
    } else {
      wholeDivision.forEach((object) => division.push(object.hfb));
    }
  } else {
    division.push(currentCheckedButton.slice(4));
  }
  const divisionsForDb = buildDivision(activeDivisionsTab, division);
  return divisionsForDb;
}
function buildDivision(
  activeDivisionsTab: string,
  hfbs: string[]
): DivisionsForDB {
  const depts = "depts=";
  const wholeStore = "whole=whole";

  if (activeDivisionsTab === DIVISION_HASH.WHOLESTORE?.divisionShortName) {
    activeDivisionsTab = activeDivisionsTab.replace(
      DIVISION_HASH.WHOLESTORE.divisionShortName,
      wholeStore
    );
  }
  hfbs = [...hfbs].map((hfb) => depts + hfb);
  const divisions: DivisionsForDB = {
    division_id: activeDivisionsTab,
    hfbs: hfbs,
  };
  return divisions;
}

export function handleDivisionList(
  value: string,
  divisions: Division[]
): Division[] | [] {
  let returnDivisions: Division[] = [];
  switch (true) {
    case value.includes("store"):
      returnDivisions = divisions;
      break;
    case value.startsWith("whole"):
      const divisionId = value.slice(-2);
      returnDivisions = divisions?.filter(
        (div: Division) => div.division_id === divisionId
      );
      break;
    default:
      returnDivisions = [];
      break;
  }

  return returnDivisions;
}
