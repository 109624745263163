import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Pie } from "react-chartjs-2";

interface LineProps {
  options: ChartOptions<"pie">;
  data: ChartData<"pie">;
}

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = ({ options, data }: LineProps) => {
  return <Pie data={data} options={options} />;
};
