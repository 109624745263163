const DAY = 24 * 3600 * 1000;

module.exports.ARCHIVE_OFFSET = 7 * DAY;
module.exports.NEARBY_PRODUCTS_OFFSET = 21 * DAY;
module.exports.NOTIFICATION_OFFSET = 7 * DAY;
module.exports.CLOSED_TASKS_OFFSET = 7 * DAY;
module.exports.LOCATIONS_FROM_TASKS_OFFSET = 28 * DAY;
module.exports.RELATED_TASKS_MAX_CLOSED = 7 * DAY;
module.exports.RELATED_TASKS_MAX_FINISHED = 10 * DAY;
module.exports.USER_EVENT_TTL = 14 * DAY;

// This is a date also set in the Tasks Service
module.exports.AVeryLargeDate = "3000-01-01T00:00:00.000Z";

module.exports.FINISHED_TASKS_OFFSET_28_DAYS = 28 * DAY; // AT, DE (DK?)
module.exports.FINISHED_TASKS_OFFSET_70_DAYS = 70 * DAY; // All other countries.
