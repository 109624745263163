import React from "react";
import {
  getCachedInternalApi,
  getLatestCacheValue,
} from "./API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";

export function useEvents() {
  const storeId = useStoreId();
  const [response, setResponse] = React.useState(
    getLatestCacheValue("my/events") || []
  );
  React.useEffect(() => {
    getCachedInternalApi(`my/events`, setResponse, 0, false, "CORE_SERVICE", {
      store_id: storeId,
    });
  }, [storeId]);
  return response?.data?.list || [];
}
