import dayjs from "dayjs";
import { getSalesWeekFromDate } from "../Insights/helpers";
import { Vote } from "./BackendApiRepository/QuestionResponse";

interface MFAQFollowUpTaskType {
  area: string;
  created_at: string;
  description: string;
  finished_at: string;
  finished_summary: string;
  state: string;
  task_id: string;
  totalTasks: number;
  weekNumber: string;
}

interface AggregatedVotesType {
  weekNumber?: number;
  bucketStartDate?: string;
  key: number;
  value: number;
}

export function getMFAQFollowUpTasks(tasks: MFAQFollowUpTaskType[]) {
  return tasks?.map((task) => {
    const weekNumber = getSalesWeekFromDate(dayjs(new Date(task.created_at)));
    return {
      ...task,
      created_at: dayjs(task.created_at).format("YYYY-MM-DD"),
      finished_at: dayjs(task.finished_at).format("YYYY-MM-DD"),
      weekNumber,
      totalTasks: tasks.length,
    };
  });
}

export function aggregateVotes(votes: Vote[], interval: string) {
  const data = votes?.reduce((result: AggregatedVotesType[], vote: Vote) => {
    const voteValue = vote.vote_value;

    if (interval === "day") {
      const bucketStartDate = dayjs(vote.created_at).format("YYYY-MM-DD");
      const existingItem = result.find(
        (resultItem) => resultItem.bucketStartDate === bucketStartDate
      );

      if (existingItem) {
        existingItem.value += voteValue;
      } else {
        result.push({
          bucketStartDate,
          key: Date.parse(vote.created_at),
          value: voteValue,
        });
      }
    } else if (interval === "week") {
      const weekNumber = getSalesWeekFromDate(dayjs(vote.created_at));
      const existingItem = result.find(
        (resultItem) => resultItem.weekNumber === weekNumber
      );

      if (existingItem) {
        existingItem.value += voteValue;
      } else {
        result.push({
          weekNumber,
          key: Date.parse(vote.created_at),
          value: voteValue,
        });
      }
    }

    return result;
  }, []);

  return data;
}
