import { useQuery } from "@tanstack/react-query";
import { useStoreId } from "../../../../core/auth/useLoggedInUser";
import { getAllLocationsFromSLM } from "../../../../services/locations.service";
import { INTERVAL } from "../../../../constants/clientTime";

export const useFetchSlmData = () => {
  const storeId = useStoreId();
  return useQuery({
    queryKey: ["fetchSlmData", storeId],
    queryFn: async () => await getAllLocationsFromSLM(storeId),
    staleTime: INTERVAL.HOUR * 2,
  });
};
