import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <p>Vyhlásenie o ochrane osobných údajov </p>,
  aboutFixaText: (
    <div>
      FIXA je nástroj na správu úloh a podporu predaja pre zamestnancov v
      obchodných domoch. Účelom aplikácie FIXA je nahradiť manuálne, papierové
      činnosti alebo činnostiv Exceli a zefektívniť správu úloh v obchodnom dome
      (dopĺňanie výrobkov, upratovanie, zmeny, skúšobné nákupy, najčastejšie
      otázky atď.).
      <br />
      Fixa tiež umožňuje analýzu prostredníctvom názorov získaných zo splnených
      úloh.
      <br />
      <br />
      Keď používaš aplikáciu FIXA, Ingka Group ako správca údajov (ako je
      definované nižšie) zhromažďuje niektoré tvoje osobné údaje.
    </div>
  ),

  personalDataCollectedTitle: <p>Zhromažďované osobné údaje:</p>,
  personalDataCollectedText: (
    <div>
      Pri používaní nástroja FIXA bude systém o tebe zhromažďovať informácie z
      databázy zamestnancov (CDS), ako je krstné meno, stredné meno (mená),
      priezvisko, e-mail, pracovná pozícia, úloha, telefónne číslo (ak je
      poskytnuté manuálne - osobné telefónne číslo by sa nemalo zadávať),
      fotografia z CDS, jazykové preferencie v aplikácii, identifikačné číslo
      obchodného domu, identifikačné číslo krajiny, verzia zariadenia a operačný
      systém, IP adresa spracovaná na platforme Google Cloud a Sentry (Sentry je
      softvér na sledovanie chýb v aplikácii).
      <br />
      <br />
      Profil zamestnanca manuálne vytvára vedúci/správca prostredníctvom správcu
      Fixa (správcu webovej aplikácie). Osobné údaje sa získavajú z CDS, takže
      profily je možné vytvoriť len pre zamestnancov, ktorí majú aktívny profil
      CDS.
    </div>
  ),
  purposeOfDataCollectionTitle: <p>Účel zhromažďovania týchto údajov:</p>,
  purposeOfDataCollectionText: (
    <p>
      Účelom zhromažďovania osobných údajov v nástroji FIXA je správne
      fungovanie aplikácie spôsobom, ktorý umožňuje jednoduchú internú
      komunikáciu medzi zamestnancami a spravovanie úloh medzi zamestnancami v
      obchodnom dome. Pripojenie úloh k používateľovi nám umožňuje podporovať
      upozornenia na aktualizácie úloh pre pridelených používateľov a tvorcov.
    </p>
  ),
  legalGroundsTitle: <p>Právny základ spracovávania:</p>,
  legalGroundsText: <div>Právny základ spracovávania je oprávnený záujem.</div>,
  dataWillBeStoredTitle: <p>Údaje sa budú uchovávať:</p>,
  dataWillBeStoredText: (
    <div>
      Dokončené úlohy s referenciami na používateľov uchovávame 70 dní, potom sa
      anonymizujú. Osobné údaje zamestnancov sa uchovávajú v nástroji FIXA po
      dobu trvania pracovnej zmluvy.
      <br />
      <br />
      Ukončenie pracovnej zmluvy so spoločnosťou Ingka Group:
      <br />
      Všetky osobné údaje sa automaticky odstránia do 1 mesiaca po odstránení
      profilu CDS.
      <br />
      <br />
      Zmena pozície v rámci spoločnosti Ingka Group:Ak zamestnanec už viac
      nepotrebuje používať nástroj FIXA, všetky osobné údaje budú automaticky
      odstránené do 3 mesiacov od nečinnosti.
      <br />
      <br />
      Osobné údaje sa môžu tiež kedykoľvekodstrániťna žiadosť miestneho vedúceho
      v oblasti bezpečnosti informácií, vedúceho ochrany osobných údajov alebo
      vedúceho obchodného domu.
      <br />
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <p>Prevádzkovateľ údajov pre toto spracovanie osobných údajov je:</p>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA Bratislava, s.r.o,
      <br />
      Ivanská cesta 18, 821 04 Bratislava
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      <br />V súvislosti so svojimi osobnými údajmi máš určité práva. Viac
      informácií o týchto právach nájdeš v:{" "}
      <a href="https://iweof.sharepoint.com/:b:/t/o365g_privacyczhusk_retczso/EVDUQQDAHJNGsZhLy5vm3koBg00wT1w2t65jJyglc94jdA?e=Qst8ab">
        https://iweof.sharepoint.com/:b:/t/o365g_privacyczhusk_retczso/EVDUQQDAHJNGsZhLy5vm3koBg00wT1w2t65jJyglc94jdA?e=Qst8ab
      </a>
      <br />
      <br />
      Kontaktné údaje: <EmailLink email="data.privacy.sk@ikea.com" />
      <br />
      <br />
    </div>
  ),
};
