import React from "react";
import styled from "styled-components";
import "@ingka/inline-message/dist/style.css";
import InlineMessage from "@ingka/inline-message";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";
import { RefillTypes } from "../../../constants/refillTypes";
import { useTranslation } from "@coworker/locales";

const MessageBoxContainer = styled.div`
  box-sizing: border-box;
  background: var(--white);
  border-top: 1px solid var(--grey200);
  border-bottom: 1px solid var(--grey200);
  padding: 17px 35px;
  padding-left: 26px;
  padding-right: 10px;
`;

interface MessageBoxProps {
  selectedRefillType: RefillTypes;
}

export default function MessageBox({ selectedRefillType }: MessageBoxProps) {
  const { t } = useTranslation();

  const getInfoMessage = () => {
    switch (selectedRefillType) {
      case RefillTypes.REGULAR:
        return t("refillTypeRegularInfoString");
      case RefillTypes.FIRST_FILL:
        return t("refillTypeFirstFillInfoString");
    }
  };
  return (
    <MessageBoxContainer>
      <InlineMessage
        subtle
        body={getInfoMessage()}
        ssrIcon={informationCircle}
      />
    </MessageBoxContainer>
  );
}
