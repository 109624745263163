import {
  DescribeTheProblemList,
  OptionListItem,
} from "../constants/DescribeTheProblemList";
import {
  PQDescribeTheProblemOptions,
  PQDescribeTheProblemTag,
} from "@coworker/types/lib/tasks/constants";

export const getTypeListEntry = (
  type: PQDescribeTheProblemOptions
): OptionListItem | undefined => {
  if (!type) {
    return;
  }
  return DescribeTheProblemList.find((item) => item.type === type);
};

export const getBadgeTranslationKeys = (
  type: PQDescribeTheProblemOptions,
  selectedTags: PQDescribeTheProblemTag[]
): string[] => {
  const actualTranslationKeys = Array<string>();

  const typeListEntry = getTypeListEntry(type);
  const translationKeys =
    typeListEntry &&
    selectedTags &&
    selectedTags.map(
      (selectedTag) =>
        typeListEntry.tags.find((tag) => tag.tag === selectedTag)
          ?.translationKey
    );

  if (translationKeys) {
    translationKeys.forEach((key: string | undefined) => {
      if (key) actualTranslationKeys.push(key);
    });
  }
  return actualTranslationKeys;
};
