import SSRIcon from "@ingka/ssr-icon";
import chevronDown from "@ingka/ssr-icon/paths/chevron-down";
import chevronUp from "@ingka/ssr-icon/paths/chevron-up";
import React from "react";
import styles from "./teams-list-view-item-icon.module.css";
interface TeamsListViewItemIconProps {
  id: string;
  toggleTeamMembers: (index: number) => void;
  isOpen: boolean;
  index: number;
}

export const TeamsListViewItemIcon: React.FC<TeamsListViewItemIconProps> = ({
  isOpen,
  id,
  toggleTeamMembers,
  index,
}) => {
  return (
    <div
      className={styles["teams-list-view-item-icon"]}
      id={id}
      onClick={() => {
        toggleTeamMembers(index);
      }}
    >
      <SSRIcon paths={isOpen ? chevronUp : chevronDown}></SSRIcon>
    </div>
  );
};
