import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { BasicWidget } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";
import { ReactComponent as InformationCircle } from "../../../assets/svg/information-circle.svg";
import trackerHelper from "../../../helpers/tracker";

const Blue = styled.div`
  color: var(--new);
`;

export default function LearnAboutSafetyAlarm() {
  const { getInput } = useInputPopup();
  return (
    <div
      onClick={() => {
        trackerHelper.safetyAlarmInfo.open();
        getInput("infoAboutSafetyAlarm", {
          title: <Trans>safetyAlarmString</Trans>,
        }).then((x) => {
          if (x === undefined) trackerHelper.safetyAlarmInfo.closeX();
        });
      }}
    >
      <BasicWidget
        icon={<InformationCircle color="var(--new)" />}
        title={
          <Blue>
            <Trans>safetyAlarmLearnMoreString</Trans>
          </Blue>
        }
      />
    </div>
  );
}
