import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { ReactComponent as SearchIcon } from "@coworker/components/src/assets/svg/icon-search.svg";
import { ReactComponent as CancelIcon } from "@coworker/components/src/assets/svg/icon-close-large.svg";

const Container = styled.div`
  position: relative;
  display: flex;
  padding: 0 19px;
  padding-top: 20px;
  align-items: center;
`;

const RoundedButton = styled.button`
  background: var(--grey100);
  border-radius: 28px;
  cursor: pointer;
  height: 55px;
  position: relative;
  flex-grow: 1;
`;

const ButtonIcon = styled(SearchIcon)`
  width: 52px;
  cursor: pointer;
  position: absolute;
  left: 12px;
  top: 3px;
`;

const ButtonText = styled.span`
  position: absolute;
  left: 60px;
  top: 17px;
  color: var(--grey700);
  font-size: 16px;
  font-weight: bold;
`;

const CancelButton = styled.button`
  width: 40px;
  height: 40px;
  background: var(--grey100);
  border-radius: 50%;
  position: relative;
  margin-right: 7px;
  margin-left: 23px;
  cursor: pointer;
`;

const CloseIcon = styled(CancelIcon)`
  color: var(--grey900);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const HeaderSearchButton = ({ onClick, onCancel }) => (
  <Container data-testid="searchButton">
    <RoundedButton onClick={onClick} data-testid="actualSearchButton">
      <ButtonIcon />
      <ButtonText>
        <Trans>searchString</Trans>
      </ButtonText>
    </RoundedButton>
    {!!onCancel && (
      <CancelButton onClick={onCancel} data-testid="searchButtonIcon">
        <CloseIcon />
      </CancelButton>
    )}
  </Container>
);
