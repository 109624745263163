import React from "react";
import { TaskFormMfaqArea } from "../../TaskForm/widgets/mfaqArea";

function TaskDetailMfaqArea({ task }) {
  const filters = ["period=4weeks"];
  task?.question_area && filters.push(task?.question_area);

  return <TaskFormMfaqArea area={filters} />;
}
export default TaskDetailMfaqArea;
