import React from "react";
import { Trans, useTranslation } from "@coworker/locales";
import { Link } from "../Link";
import EmptyScreen from "../EmptyScreen";
import Notification from "./Notification";
import { useIntersect } from "@coworker/components";
import styled from "styled-components";
import { useTeamId, useUserId } from "../../core/auth/useLoggedInUser";
import { useStoreTeams } from "../../core/hooks/useStoreTeams";
import { useStoreUsers, useAsMap } from "../../hooks/useStoreContacts";
import { useReadNotification } from "../../hooks/notificationHooks/useReadNotification";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import { useFetchNotifications } from "../../hooks/notificationHooks/useFetchNotifications";
import { NotificationsSkeleton } from "./NotificationsSkeleton";
import { useReadAllNotifications } from "../../hooks/notificationHooks/useReadAllNotifications";

const ELEMENTS_INITIAL = 10;
const ELEMENTS_SCROLL_INCREMENT = 5;

const ScrollMarker = styled.div`
  display: block;
  flex-grow: 0;
  width: 100%;
  min-height: 2px;
`;

function Notifications({ type }) {
  const uid = useUserId();
  const teamId = useTeamId();
  const teams = useStoreTeams();
  const users = useAsMap(useStoreUsers(), true);

  const { data: notificationsList, isFetching } = useFetchNotifications();
  const readNotification = useReadNotification();
  const readAllNotifications = useReadAllNotifications();

  const { t } = useTranslation();

  const [limit, setLimit] = React.useState(ELEMENTS_INITIAL);
  const [indicators, setIndicators] = React.useState([]);
  const containerRef = React.useRef();
  const [intersectRef, entry] = useIntersect({
    threshold: 0,
  });

  const union = React.useMemo(() => {
    if (!notificationsList) return [];
    const unreadNotifications = notificationsList
      ?.filter((event) => !event.is_read)
      .map((event) => event.instance_id);
    return [...new Set(indicators.concat(unreadNotifications))];
  }, [notificationsList, indicators]);

  React.useEffect(() => {
    setIndicators(union);
    // Ignored: union
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(union)]);

  const shownNotifications = notificationsList?.slice?.(0, limit) || [];
  React.useEffect(() => {
    if (entry.isIntersecting && limit < notificationsList?.length) {
      setLimit((currentLimit) => currentLimit + ELEMENTS_SCROLL_INCREMENT);
    }
    // Ignored: limit, events.length
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.isIntersecting]);

  const readAll = () => {
    readAllNotifications.mutateAsync();
    setIndicators([]);
  };
  const appBarActions = [
    {
      name: "readAllNotifications",
      onClick: readAll,
      position: "right",
      icon: <Trans>readAllString</Trans>,
    },
  ];
  return (
    <>
      <FullScreenPopup
        appBarConfig={{
          title: t("notificationsString"),
          actions: appBarActions,
        }}
        noPadding
      >
        <div ref={containerRef}>
          {!isFetching &&
            containerRef?.current &&
            shownNotifications?.length &&
            shownNotifications.map((event) => (
              <Notification
                event={event}
                key={event.id}
                uid={uid}
                teamId={teamId}
                users={users}
                teams={teams}
                containerElement={containerRef.current}
                showIndicator={indicators.includes(event.instance_id)}
                onClickHandler={() => {
                  readNotification.mutateAsync(
                    `${event.type}#${event.instance_id}`
                  );
                }}
              />
            ))}
        </div>

        {limit < notificationsList?.length && (
          <ScrollMarker ref={intersectRef} />
        )}
        {isFetching && <NotificationsSkeleton />}
        {!notificationsList?.length && !isFetching && (
          <EmptyScreen
            filter={type}
            subTitle={<Trans>notificationsEmptySubtitleString</Trans>}
            title={<Trans>notificationsEmptyTitleString</Trans>}
            action={{
              path: "/task/new",
              text: <Trans>createString</Trans>,
            }}
            link={Link}
          />
        )}
      </FullScreenPopup>
    </>
  );
}

export default Notifications;
