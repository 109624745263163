import { useAPIRequest } from "./useAPIRequest";
const { parseJson } = require("@coworker/functions/src/utils/cia");

const getCallUrlRequestPromise = (url, requestOptions) => () =>
  fetch(url, requestOptions).then(parseJson);

export default function useCallUrl(
  url,
  requestOptions = {},
  options = {},
  processResponseFunction
) {
  return useAPIRequest(
    getCallUrlRequestPromise(url, requestOptions),
    `${url}:${JSON.stringify(requestOptions)}`,
    options,
    processResponseFunction
  );
}
