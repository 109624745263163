import React from "react";
import styled from "styled-components";
import { TaskCard } from "@coworker/components";
import { Trans } from "@coworker/locales";
import Badges from "./Badges";
import { Indicators } from "./Indicators";
import { Subtitles } from "./Subtitles";
import { TaskCardLocation } from "./TaskCardLocation";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import { issueTypeTitle } from "./issueTypeTranslations";
import useFormatter from "../hooks/useFormatter";
import { closeTypeKeys } from "@coworker/functions/src/enums/closeTypes";
import { useNameFormatter } from "../hooks/useActiveUsers";
import { ElidedText } from "@coworker/reusable/components/ElidedText";
import { ReactComponent as SpeechBubble } from "../assets/svg/icon-speech-bubble.svg";
import { ReactComponent as ImageAttached } from "../assets/svg/icon-image-attached.svg";
import { useTaskHasNotes } from "../hooks/useTaskNotes";
import { useTaskHasImages } from "../hooks/useTaskImages";
import { useTeamId } from "../core/auth/useLoggedInUser";
import { getTranslationKeyFromFlags } from "./RoomSettings/tools";

const SpeechBubbleIcon = styled(SpeechBubble)``;
const ImagesAttachedIcon = styled(ImageAttached)``;

export function makeTitle(task) {
  switch (task.task_type) {
    case taskTypeOptions.ADDON:
      return task.picked ||
        task.state === taskStateOptions.COMPLETED ||
        task.state === taskStateOptions.CLOSED ? (
        <Trans>refillString</Trans>
      ) : (
        <Trans>addonTitleString</Trans>
      );

    case taskTypeOptions.PRODUCT_QUALITY:
      return <Trans>productQualityReportString</Trans>;

    case taskTypeOptions.PRODUCT_ISSUE:
      return (
        issueTypeTitle[task.task_type][task.issue_type] ||
        issueTypeTitle[task.task_type].default
      );
    case taskTypeOptions.MFAQ_FOLLOW_UP:
      return <Trans>mfaqFollowUpTitle</Trans>;
    case taskTypeOptions.TESTBUY_FOLLOW_UP:
      return <Trans>testBuyFollowUpString</Trans>;
    case taskTypeOptions.ROOM_SETTINGS:
      return (
        <Trans values={{ room_name: task.room_name }}>
          {getTranslationKeyFromFlags(task)}
        </Trans>
      );
    // For taskTypeOptions.CUSTOM and taskTypeOptions.PLANNED
    default:
      return task.custom_title || <Trans>unknownString</Trans>;
  }
}

const ClosedAndWhy = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: var(--grey700);
`;

const CloseTypeText = styled.div`
  color: var(--error);
`;

export function TaskCardWithData({
  addonFilterOption = [],
  padLeft = undefined,
  task,
  uid,
  hasNewNote = false,
  onClickTrack = () => null,
  tabType = "",
}) {
  const teamId = useTeamId();
  const locationLine = (
    <TaskCardLocation task={task} addonFilterOption={addonFilterOption} />
  );

  const trackClick = React.useCallback(
    () => onClickTrack && onClickTrack({ id: task.id }),
    [onClickTrack, task.id]
  );

  const { teamNameFor } = useNameFormatter();
  const { formatDateShort } = useFormatter();
  const closedByAndWhy = task.state === taskStateOptions.CLOSED && (
    <ClosedAndWhy>
      <div>
        <b>{formatDateShort(task.finished_at)}</b> <Trans>closedByString</Trans>{" "}
        <b>{teamNameFor(teamId)}</b>
      </div>
      <CloseTypeText>
        <Trans>{closeTypeKeys[task.close_type]}</Trans>
      </CloseTypeText>
      <ElidedText>
        <i>{task.close_description}</i>
      </ElidedText>
    </ClosedAndWhy>
  );

  const isOpenOrMy = ["my", "open"].includes(tabType);
  const assignedTo = !isOpenOrMy && (
    <ClosedAndWhy>
      <ElidedText>
        <b>
          <Trans>assignedToColonString</Trans>
        </b>
        {teamNameFor(task.assigned_team_id)}
      </ElidedText>
    </ClosedAndWhy>
  );

  // TODO: Once we migrate to tasks-service we should remove the useTaskHas... hooks completely.
  const hasComments = useTaskHasNotes(task.id) || !!task.has_notes;
  const hasImages = useTaskHasImages(task.id) || !!task.has_images;
  const titleIcons = React.useMemo(() => {
    return (
      <>
        {hasComments && <SpeechBubbleIcon />}
        {hasImages && <ImagesAttachedIcon />}
      </>
    );
  }, [hasComments, hasImages]);

  return (
    <TaskCard
      key={task.id}
      title={makeTitle(task)}
      titleIcons={(hasComments || hasImages) && titleIcons}
      badges={<Badges task={task} />}
      groupByColor={task.color}
      isLast={task.lastInGroup}
      trackClick={trackClick}
      subtitles={
        <>
          <Subtitles
            task={task}
            hideProduct={tabType === "related_product_tasks"}
            location={locationLine}
          />
          {closedByAndWhy}
          {assignedTo}
        </>
      }
      indicators={<Indicators task={task} hasNewNote={hasNewNote} />}
      padLeft={padLeft}
    />
  );
}
