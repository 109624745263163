import React from "react";
import { makeFeedbackQuestions } from "../constants/hardcodedQuestions"; // Will be fetched from Firestore later.
import { useUserPreference } from "./useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";

function isActive(question) {
  const now = Date.now();
  return now < question.ends_at && now >= question.starts_at;
}

let emptyArray = [];
export default function useFeedbackQuestions() {
  const [answered, setAnsweredPreference] = useUserPreference(
    profilePreferences.FEEDBACK_QUESTION_LIST,
    emptyArray
  );
  const unanswered = React.useMemo(() => {
    const remaining = makeFeedbackQuestions().filter(
      (question) => !answered.includes(question.id) && isActive(question)
    );
    return remaining.length ? remaining : emptyArray;
  }, [answered]);

  const setAnswered = React.useCallback(
    (key) => {
      setAnsweredPreference(answered.concat(key));
    },
    [answered, setAnsweredPreference]
  );

  return [unanswered, setAnswered];
}
