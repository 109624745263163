import React from "react";
import styled, { css } from "styled-components";
import noPreviewImg from "../assets/images/no-preview.png";
import { RotatedImage } from "./RotatedImage";
import { LoaderIcon } from "@coworker/reusable/Loader";
import { useRemoteImages } from "../hooks/useRemoteImages";

const StyledLoaderIcon = styled(({ dark, size, ...props }) => (
  <LoaderIcon {...props} />
))`
  height: 20px;
  width: 20px;
  color: black;
  margin: auto;
  ${({ dark }) =>
    dark &&
    css`
      color: white;
    `}
  ${({ size }) =>
    size === "large" &&
    css`
      width: 120px;
      height: 120px;
    `}
`;

/**
 * @param {"large" | "medium" | "small" | "original"} size Wanted image size
 * @param {boolean} dark Only used in PreviewImage.
 * @param {boolean} fullscreen Used in PreviewImage and ImageSlider.
 * @param {boolean} taskHeader Only when used in header.
 */
export function StoredImage({
  size = "large",
  image = {},
  mediumLoader = false,
  fullscreen = false,
  taskHeader = false,
  dark = false,
}) {
  const [src, setSrc] = React.useState(image.src || "");
  React.useEffect(() => {
    if (image.src) setSrc(image.src); // Support for showing a product image, where we don't need to wait for backend response.
  }, [image]);

  const [url] = useRemoteImages([image], size);
  React.useEffect(() => {
    if (url) setSrc(url);
  }, [url]);

  const showingOriginal = !!image.src || !(src || "").includes("/resized/");
  return src ? (
    <RotatedImage
      src={src}
      alt={image.file_name}
      orientation={showingOriginal ? image.orientation : 1} // if resized then also the rotation is normalized to 1 on the backend.
      fullScreenTransform={!!(fullscreen && showingOriginal)}
      fullscreen={fullscreen}
      taskHeader={taskHeader}
      onError={() => setSrc(noPreviewImg)}
    />
  ) : (
    <StyledLoaderIcon dark={dark} size={(mediumLoader && "medium") || size} />
  );
}
