import React from "react";
import { parseProduct } from "../services/products.service";
import useCallInternal, { PRODUCT_MAPPER } from "./API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { useBasicProductFromSIK } from "./useSearchProducts";
import { listShortIds, useCachedTasks } from "../services/tasks.service";
import { callInternalApi } from "./API/useCallInternal";
import { useMyStore } from "../hooks/useMyStore";
import { localeReverser } from "../helpers/locale.helper";

// Cache in-memory for less re-renders, and in localStorage for later
const cache = {};
const keyFor = (id) => `cache/bp/${id}`;
export const readLocalProductCache = (id) => {
  const found = cache[id]?.name
    ? cache[id]
    : JSON.parse(localStorage?.getItem(keyFor(id)));
  return found?.name ? found : null;
};

function goodChange(key, before, after) {
  switch (key) {
    case "smallImage":
      return !before || (before.includes("_s5.jpg") && after.includes("S2."));
    case "descriptives":
      return !before || (after && before?.join("") !== after?.join(""));
    case "updated_at":
    case "source":
      return;
    default:
      return before !== after;
  }
}

export function writeLocalProductCache(id, data, whereFrom) {
  if (!id || !data) return;

  // Any incoming empty property is not allowed to overwrite existing properties. Needed to prevent MFS search results from removing panumber.
  const currentlyStored = readLocalProductCache(id);
  let basicProduct = { ...(currentlyStored || {}) };
  let diff = false;
  for (const key of Object.keys(data)) {
    if (data[key] && goodChange(key, basicProduct[key] || "", data[key])) {
      basicProduct[key] = data[key];
      diff = true;
    }
  }

  // The writes to localStorage here via setItem does not go beyond 300, so well below any troublesome levels. And each item stored in cache is ~100 bytes.
  if (currentlyStored ? diff : whereFrom) {
    try {
      localStorage?.setItem(keyFor(id), JSON.stringify(basicProduct));
    } catch (error) {}
  }

  return (cache[id] = basicProduct);
}

function idsToFetch(short_ids) {
  const found = new Set();
  (short_ids || []).forEach(
    (id) => !id || found.has(id) || readLocalProductCache(id) || found.add(id)
  );
  return [...found].sort();
}

export function useFetchBasicProducts(short_ids, whereFrom) {
  const [dataFromService, setDataFromService] = React.useState({});
  const primary_locale = localeReverser(
    useMyStore()?.configuration?.locale?.primary_locale
  );
  const callTasksServiceFlag = window.callTasksService;
  const store = useStoreId();
  const neededIds = React.useMemo(
    () => idsToFetch(short_ids),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(short_ids)]
  );
  React.useEffect(() => {
    if (neededIds.length)
      console.log("prefetch: needed product ids", neededIds);
  }, [neededIds]);
  const ids = neededIds.join(",");
  const { data, loading } = useCallInternal(
    PRODUCT_MAPPER,
    { store, ids },
    { blockRequest: !ids || !store || callTasksServiceFlag }
  );

  React.useEffect(() => {
    const productsBasicsObj = {};
    if (callTasksServiceFlag && ids.length > 0) {
      callInternalApi(
        `products_basics?short_ids=${ids}&locale=${primary_locale}`
      ).then((response) => {
        response.forEach((basicProduct) => {
          productsBasicsObj[basicProduct.id] = basicProduct;
        });
        setDataFromService(productsBasicsObj);
      });
    }
  }, [ids, primary_locale, callTasksServiceFlag]);

  neededIds.forEach((id) => {
    const item = callTasksServiceFlag ? dataFromService?.[id] : data?.[id];
    writeLocalProductCache(id, item, whereFrom);
  });

  return loading;
}

export function usePrefetchProductInfo() {
  useCachedTasks("my", "usePrefetchProductInfo");
  useCachedTasks("open", "usePrefetchProductInfo");
  useFetchBasicProducts(listShortIds(), "prefetch");
}

const emptyProduct = { descriptives: [] };

/**
 * @param {string} short_id
 * @returns {{loading:boolean, basicProduct:{name: string, id: string, type:string, descriptives:string[],descriptivesWithoutKindAndColor, smallImage:string, panumber: string }}}
 */
export function useFetchedBasicProduct(some_id) {
  const [short_id] = parseProduct(some_id);
  const item = readLocalProductCache(short_id) || emptyProduct;
  const loading = useFetchBasicProducts(!item?.name && [short_id], some_id);
  return { loading, basicProduct: item };
}

/**
 * @param {string} short_id
 * @returns {{loading:boolean,error:boolean,basicProduct?:{name: string, id: string, type:"ART"|"SPR", descriptives:string[], smallImage:string, panumber: string }}}
 */
export function useFastBasicProduct(some_id) {
  const [short_id] = parseProduct(some_id);
  const found = useBasicProductFromSIK(short_id);
  const fetched = useFetchedBasicProduct(short_id);
  const loading = !found && fetched?.loading;
  const error = !found && fetched?.error;

  return { basicProduct: fetched?.basicProduct || found, loading, error };
}
