import {
  PRODUCT,
  HEADER,
  LOCATION,
  ASSIGNMENT,
  ACTIVITY,
  DESCRIPTION,
} from "./_common";
import { REFILLED, PICKING, REFILL_TYPE } from "../widgets";
import {
  LOCATION_TYPE_PICKUP,
  LOCATION_TYPE_REFILL,
} from "../../../constants/locationWidgetTypes";
import {
  PickUpTask,
  CompleteTask,
  CompletePicking,
  CompleteWithChanges,
  CompletePickingWithChanges,
} from "../actions/index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fields: [
    HEADER,
    PRODUCT,
    { type: REFILLED },
    LOCATION(LOCATION_TYPE_PICKUP),
    LOCATION(LOCATION_TYPE_REFILL),
    { type: REFILL_TYPE },
    ASSIGNMENT(),
    { type: PICKING },
    DESCRIPTION(),
    ACTIVITY,
  ],
  actions: [
    PickUpTask,
    CompleteTask,
    CompletePicking,
    CompletePickingWithChanges,
    CompleteWithChanges,
  ],
};
