import React, { PropsWithChildren } from "react";

import "../styles/RoomSettings.css";
import { ListItemWrapper } from "../styles/styles";
import {
  Article,
  ArticleIdNbrArticles,
  EdsData,
  SalesStopData,
} from "../types/article";
import "@ingka/commercial-message/dist/style.css";
import { MediumSkeletonListItem } from "./skeletons/MediumSkeletonListItem";
import { ArticleListItemBanner } from "./ArticleListItemBanner";
import { ArticleListItemDetails } from "./ArticleListItemDetails";

interface ArticleListItemInnerProps {
  article: Article;
  isMainProduct: boolean;
  isBackupForProduct: boolean;
  hasOngoingTask: boolean;
  showActionRow: boolean;
  hideChevron: boolean;
  edsData?: EdsData | null | undefined;
  salesStopData?: SalesStopData | null | undefined;
  selectArticleIdsMode: boolean;
  isSelected: boolean;
  onSelectArticleIdNbrArticles?:
    | ((articleIdNbrArticles: ArticleIdNbrArticles) => void)
    | undefined;
  onArticleUpdate?:
    | ((article: Article, isSkeleton: boolean) => void)
    | undefined;
  currentNbrArticles: number;
  onClick: () => void;
}

export function ArticleListItemInner({
  article,
  isMainProduct,
  isBackupForProduct,
  hasOngoingTask,
  showActionRow,
  hideChevron,
  edsData,
  salesStopData,
  selectArticleIdsMode,
  isSelected,
  onSelectArticleIdNbrArticles,
  currentNbrArticles,
  onClick,
  children,
}: PropsWithChildren<ArticleListItemInnerProps>) {
  const hasBanner = () => {
    if (!article) return false;
    if (isMainProduct) return true;
    if (isBackupForProduct) return true;
    if (hasOngoingTask) return true;
    if (edsData) return true;
    if (salesStopData) return true;
    return false;
  };

  if (!article) return <></>;

  const innerComponent = (
    <>
      <ArticleListItemBanner
        isMainProduct={isMainProduct}
        isBackupForProduct={isBackupForProduct}
        hasOngoingTask={hasOngoingTask}
        edsData={edsData}
        salesStopData={salesStopData}
      />

      <ArticleListItemDetails
        article={article}
        showActionRow={showActionRow}
        selectArticleIdsMode={selectArticleIdsMode}
        onSelectedArticleChange={() => {
          if (onSelectArticleIdNbrArticles) {
            onSelectArticleIdNbrArticles({
              articleId: article.id,
              nbrArticles: currentNbrArticles,
            });
          }
        }}
        isSelected={!!isSelected}
        hideChevron={!!hideChevron}
      />

      {children}
    </>
  );

  return (
    <ListItemWrapper $hasBanner={hasBanner()} onClick={onClick}>
      {article.isSkeleton && <MediumSkeletonListItem />}
      {!article.isSkeleton && innerComponent}
    </ListItemWrapper>
  );
}
