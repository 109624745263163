import {
  TestBuyProductWithCriteria,
  AddFollowUpParameters,
  ReplaceArticleRequestParams,
  ReplaceArticleResponseApiResponse,
  InternalProxyResponse,
} from "@coworker/types";
import useCallInternal, {
  callInternal,
  TESTBUY_ADD_FOLLOW_UP_TASK_ID,
  TESTBUY_GET_ARTICLES_WITH_CRITERIA,
  TESTBUY_GET_ARTICLE_WITH_CRITERIA,
  TESTBUY_REPLACE_ARTICLE,
  TESTBUY_ARTICLES_NUMBER_FOR_AREA,
} from "../../../../hooks/API/useCallInternal";
import { articleFactory } from "../ObjectFactories/ArticleFactory";
import React from "react";
import { useStoreId } from "../../../../core/auth/useLoggedInUser";

export function useGetArticleById(
  testbuyKey: string,
  testbuyArticleKey: string
): { data: TestBuyProductWithCriteria; loading: boolean } {
  const response = useCallInternal(TESTBUY_GET_ARTICLE_WITH_CRITERIA, {
    testbuyKey,
    testbuyArticleKey,
  });

  return { data: response.data ?? articleFactory(), loading: response.loading };
}

export async function getArticleById(
  testbuyKey: string,
  testbuyArticleKey: string
): Promise<InternalProxyResponse<TestBuyProductWithCriteria>> {
  return await callInternal(TESTBUY_GET_ARTICLE_WITH_CRITERIA, {
    testbuyKey,
    testbuyArticleKey,
  });
}

export function useTestBuyArticles(testbuyKey: string | undefined): {
  articles: TestBuyProductWithCriteria[];
  loading: boolean;
} {
  const response = useCallInternal(
    TESTBUY_GET_ARTICLES_WITH_CRITERIA,
    { testbuyKey },
    { blockRequest: !testbuyKey }
  );

  return { articles: response.data ?? [], loading: response.loading };
}

export async function addFollowUpTaskId(
  testbuyKey: string,
  articleKey: string,
  followUpTaskId: string
) {
  const params: AddFollowUpParameters = {
    testbuyKey,
    articleKey,
    followUpTaskId,
  };
  await callInternal(TESTBUY_ADD_FOLLOW_UP_TASK_ID, params);
}

export async function replaceArticle(
  testBuyKey: string,
  oldArticleId: string,
  newArticleId: string,
  storeId: string,
  locale: string
): Promise<ReplaceArticleResponseApiResponse> {
  const params: ReplaceArticleRequestParams = {
    testBuyKey,
    oldArticleId,
    newArticleId,
    storeId,
    locale,
  };

  return await callInternal(TESTBUY_REPLACE_ARTICLE, params);
}

export const useReplaceArticle = (
  testbuyId: string,
  activeArticleId: string,
  locale: string
): [
  TestBuyProductWithCriteria | undefined,
  boolean,
  boolean,
  React.Dispatch<React.SetStateAction<string | undefined>>
] => {
  const storeId: string = useStoreId();
  const [newArticleId, setNewArticleId] = React.useState<string>();
  const [newArticle, setNewArticle] =
    React.useState<TestBuyProductWithCriteria>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const replaceAndObtainNewArticle = async (newArticleId: string) => {
      setError(false);
      setLoading(true);

      try {
        const response = await replaceArticle(
          testbuyId,
          activeArticleId,
          newArticleId,
          storeId,
          locale
        );
        const newArticleData = await getArticleById(testbuyId, response.data);

        setNewArticle(newArticleData.data);
      } catch (error) {
        setError(true);
      }

      setLoading(false);
      setError(false);
    };

    newArticleId &&
      newArticleId !== activeArticleId &&
      replaceAndObtainNewArticle(newArticleId);
  }, [activeArticleId, newArticleId, storeId, testbuyId, locale]);

  return [newArticle, loading, error, setNewArticleId];
};

export function useNumberOfArticles(
  storeId: string | undefined,
  area: string | undefined
): { numberOfArticlesForArea: number; loading: boolean } {
  const response = useCallInternal(
    TESTBUY_ARTICLES_NUMBER_FOR_AREA,
    {
      storeId,
      area,
    },
    { blockRequest: !storeId || !area }
  );

  return { numberOfArticlesForArea: response.data, loading: response.loading };
}
