export const locationMapper = ({
  code,
  department,
  custom,
  section,
  homeLocation,
  primaryLocation,
}) => {
  let locationText = code || "";
  if (department || section) locationText += ` - ${department || section}`;

  let departmentText = "";
  if (department || section) departmentText = `${department || section}`;

  return {
    location: custom ? "" : locationText,
    location_grid_code: custom ? "" : code,
    location_department: custom ? "" : departmentText,
    location_custom: custom ? custom : "",
    home_location: homeLocation ?? "",
    primary_location: primaryLocation ?? "",
  };
};

export const amountMapper = ({
  amount: requested_quantity,
  actualAmount: actual_requested_quantity,
  amountType: requested_type,
}) => ({
  requested_quantity,
  actual_requested_quantity,
  requested_type,
});

export const assigneeMapper = ({
  gid: assigned_team_id,
  uid: assigned_user_id,
}) => ({
  assigned_team_id,
  assigned_user_id,
});

export const productIssueTypeMapper = ({ issue: issue_type, description }) => ({
  issue_type,
  description,
});

export const refillTypeMapper = ({ refillType: refill_type }) => ({
  refill_type,
});
