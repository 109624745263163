import React from "react";

export function useTextAreaPopup(
  containerRef,
  value,
  {
    focusOnMount = true,
    lineHeight = 19,
    verticalPadding = 60,
    initialLines = 3,
    scrollToTopOfTextarea = false,
    headerHeight = 70,
    scrollToBottomOnInit = true,
  } = {}
) {
  /**
   * @type React.RefObject<HTMLTextAreaElement> | null
   */
  const textArea = React.useRef();
  const [inFocus, setInFocus] = React.useState(false);

  const scrollToBottom = React.useCallback(
    function (delayed = false, targetScrollHeight = Number.MAX_SAFE_INTEGER) {
      if (containerRef && containerRef.current) {
        if (delayed) {
          setTimeout(() => {
            // In tests the container was already unmounted and then threw an error
            // that current is null
            if (containerRef && containerRef.current) {
              containerRef.current.scrollTop = targetScrollHeight;
            }
          }, 200);
        } else {
          containerRef.current.scrollTop = targetScrollHeight;
        }
      }
    },
    [containerRef]
  );

  const focusToTextArea = React.useCallback(
    function focusToTextArea() {
      if (textArea && textArea.current) {
        setTimeout(() => {
          if (textArea && textArea.current) {
            textArea.current.focus();

            if (value) {
              textArea.current.selectionStart = value.length;
              textArea.current.selectionEnd = value.length;
            }
          }
        }, 200);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onBlur = React.useCallback(() => {
    setTimeout(() => {
      if (textArea.current) {
        textArea.current.style.cssText = `height: ${
          lineHeight * initialLines + verticalPadding
        }px`;
      }
    }, 10);
  }, [textArea, lineHeight, verticalPadding, initialLines]);

  const onFocus = React.useCallback(() => {
    if (scrollToTopOfTextarea && containerRef.current && textArea.current) {
      containerRef.current.scrollTop = scrollToBottom(
        true,
        textArea.current.offsetTop - headerHeight - verticalPadding / 2
      );
    } else {
      scrollToBottom(true);
    }
  }, [
    scrollToBottom,
    containerRef,
    textArea,
    scrollToTopOfTextarea,
    headerHeight,
    verticalPadding,
  ]);

  React.useEffect(() => {
    focusOnMount && focusToTextArea();
    if (scrollToBottomOnInit) {
      scrollToBottom(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Solution for autosizing was found here: https://codepen.io/vsync/pen/frudD
  React.useEffect(() => {
    if (textArea.current) {
      let savedValue = textArea.current.value;
      textArea.current.value = "";
      textArea.current.baseScrollHeight = textArea.current.scrollHeight;
      textArea.current.value = savedValue;
    }
  }, [textArea]);

  React.useEffect(() => {
    function autosize(key) {
      // this "auto" (or something else with similar effects) is needed in
      // between changing heights. Why?
      // this.style.cssText = "height:auto";
      // this.style.cssText = "height:" + (this.scrollHeight + 2) + "px";
      // scrollToBottom();
      const prevHeight = textArea.current.clientHeight;
      const prevScrollTop = containerRef.current
        ? containerRef.current.scrollTop
        : 0;
      const minRows = initialLines;
      textArea.current.rows = minRows;
      let rows = Math.ceil(
        (textArea.current.scrollHeight -
          minRows * lineHeight -
          verticalPadding) /
          lineHeight
      );
      textArea.current.rows = Math.max(rows + minRows, 1);
      const heightDiff = textArea.current.clientHeight - prevHeight;
      scrollToBottom(false, prevScrollTop + heightDiff);
    }
    function focus() {
      this.style.cssText = "height:unset";
      setInFocus(true);
    }

    const node = textArea.current;
    node.addEventListener("keyup", autosize);
    node.addEventListener("focus", autosize);
    node.addEventListener("focus", focus);

    return () => {
      node.removeEventListener("keyup", autosize);
      node.removeEventListener("focus", autosize);
      node.removeEventListener("focus", focus);
    };
  }, [scrollToBottom, lineHeight, verticalPadding, containerRef, initialLines]);

  return {
    onFocus,
    onBlur,
    inFocus,
    textArea,
    scrollToBottom,
    focusToTextArea,
  };
}
