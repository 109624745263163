import React from "react";
import TestbuyFormPhotos from "./photos";
import { ReactComponent as PriorityIcon } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { Image } from "@coworker/types/lib/tasks/base";
interface ConfigWidgetProps {
  //Where does it get icon from?
  icon: JSX.Element;
  lblColor: string;
  overridePadding: string;
}

export interface TaskUploadPhotosWidgetProps {
  images: Image[];
  updateImages: Function;
  taskId: string;
}

//Wrapper to help migrate to typescript, when changing something please use this instead.
export default function TaskUploadPhotosWidget({
  taskId,
  updateImages,
  images,
}: TaskUploadPhotosWidgetProps) {
  const config: ConfigWidgetProps = {
    icon: <PriorityIcon />,
    lblColor: "var(--grey900)",
    overridePadding: "32px 16px 32px 24px",
  };

  return (
    <TestbuyFormPhotos
      conf={config}
      formType={"testbuy"}
      value={images}
      onChange={updateImages}
      taskPath={`/tasks/${taskId}`}
      last={undefined}
    />
  );
}
