import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

const { CLOSED, COMPLETED, UNASSIGNED, ASSIGNED } = taskStateOptions;

export function isFinishedState(state) {
  return [CLOSED, COMPLETED].includes(state);
}

export function isAssignedOrUnassigned(state) {
  return [UNASSIGNED, ASSIGNED].includes(state);
}
