import React from "react";
import styled from "styled-components";
import { Button } from "@coworker/components";
import { TopBarPopup } from "../../Layout/Heading";
import { Trans } from "@coworker/locales";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { useInsightsFetchParams } from "../../Insights/helpers";
import { useCallInsightsFunction } from "../../../hooks/API/useCallFirebaseFunction";
import { INSIGHTS_HOME_PAGE } from "@coworker/functions/src/enums/insightsRequestTypes";
import FilterOptionsGroup from "./FilterOptionsGroup";
import DepartmentsFilter from "./DepartmentsFilter";
import CustomTimePeriodOption from "./CustomTimePeriodOption";
const { HOME, AWAY } = require("@coworker/functions/src/enums/hfbRequestTypes");

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #ffffff;
  background: var(--white);
  padding-bottom: 70px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background: var(--white);
  position: fixed;
  bottom: 0;
  left: 0;
`;
const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 19px;
`;

const sortDepartments = (a, b) => {
  if (a === b) return 0;
  if (a === "ALL") return -1;
  if (b === "ALL") return 1;
  if (Number(a) > Number(b)) return 1;
  if (Number(b) > Number(a)) return -1;
  return 0;
};

const InsightsFilters = ({
  onSubmit,
  onClose,
  defaults,
  options,
  activeHFB = { data: [] },
  level,
  dataFetchParams,
  onChangeInsightsParams,
}) => {
  const store_id = useStoreId();
  const [nonLandingPageSort, setNonLandingPageSort] = React.useState(
    defaults.quantity
  );
  const [displayedValue, setDisplayedValue] = React.useState(defaults.value);
  const [displayedValueSource, setDisplayedValueSource] = React.useState(
    defaults.share
  );
  const [period, setPeriod] = React.useState(defaults.time);
  const [sortValue, setSortValue] = React.useState(defaults.home_sort);
  const [sortValueSource, setSortValueSource] = React.useState(
    defaults.home_sort_value
  );
  const [department, setDepartment] = React.useState(defaults.department);

  const [updatedParams, , isFetchingSavedFilters] = useInsightsFetchParams({
    store_id,
  });

  const { loading, data: fetchedActiveHFBs } = useCallInsightsFunction(
    INSIGHTS_HOME_PAGE,
    {
      ...updatedParams,
      hfb_request_type: displayedValueSource.key,
      isFetchingSavedFilters,
    }
  );

  const listOfHFBs =
    loading || !fetchedActiveHFBs ? activeHFB.data : fetchedActiveHFBs;

  const listOfActiveHFBs = React.useMemo(() => {
    return Object.entries(listOfHFBs || {})
      .filter(([key, hfb]) => {
        if (displayedValueSource.key === HOME) {
          return hfb.home_pieces > 0;
        } else if (displayedValueSource.key === AWAY) {
          return hfb.away_pieces > 0;
        }
        return false;
      })
      .map(([key, value]) => {
        return key;
      });
  }, [listOfHFBs, displayedValueSource.key]);

  const disableApply = () => {
    if (level === 2) return !listOfActiveHFBs.includes(department) || loading;
    if (level !== 2) return false;
  };
  const insightsLandingPage = level === 1;

  const sortedDepartments = [...listOfActiveHFBs].sort(sortDepartments);
  const storeOverview = window.location.href.split("/").includes("store");
  const allDepartments = department === "ALL";

  return (
    <Container>
      <TopBarPopup
        title={<Trans>refineString</Trans>}
        testId="cancelFilters"
        onClose={() => {
          // reset insights params if you close the filters
          onChangeInsightsParams(defaults);
          onClose();
        }}
      />
      <FilterOptionsGroup
        titleTranslationString="periodString"
        selectedOptionKey={period.data}
        onSelectedOptionChange={(option) => {
          if (period.data !== option.data) {
            setPeriod({
              name: option.name,
              data: option.data,
              active: [],
            });
          }
        }}
        options={options?.time}
        testIdPrefix="time"
      />
      <CustomTimePeriodOption
        selectedPeriod={period}
        onSelectedPeriodChange={setPeriod}
      />
      {insightsLandingPage && !storeOverview && (
        <>
          <FilterOptionsGroup
            titleTranslationString="insightValueString"
            selectedOptionKey={displayedValue?.key}
            onSelectedOptionChange={setDisplayedValue}
            options={options?.value}
            testIdPrefix="value"
          />
          <FilterOptionsGroup
            titleTranslationString="sortedByString"
            selectedOptionKey={sortValue.key}
            onSelectedOptionChange={setSortValue}
            options={options?.home_sort}
            testIdPrefix="value"
          />

          {sortValue.key !== "hfbNumber" && (
            <FilterOptionsGroup
              titleTranslationString="sortedByValueString"
              selectedOptionKey={sortValueSource.key}
              onSelectedOptionChange={setSortValueSource}
              options={options?.home_sort_value}
              testIdPrefix="value"
            />
          )}
        </>
      )}
      {!insightsLandingPage && !allDepartments && (
        <FilterOptionsGroup
          titleTranslationString="insightShareString"
          disabled={level > 2}
          selectedOptionKey={displayedValueSource.key}
          onSelectedOptionChange={setDisplayedValueSource}
          options={options?.share}
          testIdPrefix="value"
        />
      )}
      {!insightsLandingPage && (
        <FilterOptionsGroup
          titleTranslationString="sortByTitleString"
          disabled={level > 2}
          selectedOptionKey={nonLandingPageSort.key}
          onSelectedOptionChange={(option) => {
            setNonLandingPageSort({
              name: option.name,
              key: option.key,
              ...option.data,
            });
          }}
          options={options?.quantity}
          testIdPrefix="quantity"
        />
      )}
      {!insightsLandingPage && (
        <DepartmentsFilter
          loading={loading}
          departmentOptions={sortedDepartments}
          disabled={level > 2}
          department={department}
          onDepartmentsChange={setDepartment}
        />
      )}
      <Footer>
        <StyledButton
          customMargin
          data-testid="applyFilters"
          type="submit"
          text={<Trans>applyString</Trans>}
          primary
          dark
          onClick={() => {
            onSubmit({
              time: period,
              quantity: nonLandingPageSort,
              department,
              value: displayedValue,
              share: displayedValueSource,
              home_sort: sortValue,
              home_sort_value: sortValueSource,
            });
          }}
          disabled={disableApply()}
        />
      </Footer>
    </Container>
  );
};

export default InsightsFilters;
