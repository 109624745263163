import React from "react";
import {
  CenterContainer,
  LeftContainer,
  FlexibleRowContainer,
} from "../../styles/styles";
import Skeleton from "@ingka/skeleton";

export const SmallSkeletonListItem = () => {
  return (
    <FlexibleRowContainer style={{ padding: "20px 24px" }}>
      <LeftContainer>
        <Skeleton height={"24px"} width={"24px"} />
      </LeftContainer>
      <CenterContainer $centerText={false} style={{ gap: "5px" }}>
        <Skeleton height={"14px"} width={"164px"} />
        <Skeleton height={"14px"} width={"71px"} />
      </CenterContainer>
    </FlexibleRowContainer>
  );
};
