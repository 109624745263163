import React from "react";
import { INTERVAL } from "../constants/clientTime";
import { parseProduct } from "../services/products.service";
import useCallInternal, {
  STOCK,
  getCachedInternalApi,
} from "./API/useCallInternal";
import { useStoreId } from "../core/auth/useLoggedInUser";
import { FLAGS } from "./useFlag";
import useFlag from "../hooks/useFlag";
import { FixaWindow } from "@coworker/reusable";

function getIndicatorColor(
  loading: boolean,
  available: number,
  elevated: null | number
) {
  if (loading) {
    return "transparent";
  } else if (available > 0) {
    return "--success";
  } else if (available === 0 && elevated && elevated > 0) {
    return "--warning";
  } else if (available === 0 && elevated === 0) {
    return "--error";
  } else {
    return "--grey500";
  }
}

interface StockInfo {
  available: number;
  elevated: number | null;
  loading: boolean;
}

export function useAvailableElevatedStock(full_id: string) {
  const store = useStoreId();
  const [short_id, type] = parseProduct(full_id);
  const callTasksServiceFlag = useFlag(FLAGS.CALL_TASKS_SERVICE);
  const callTasksService =
    (window as FixaWindow).callTasksService || callTasksServiceFlag;
  const { data, loading: loadingFirebase } = useCallInternal(
    STOCK,
    { store, short_id, type },
    {
      blockRequest: !store || !type || !short_id || callTasksService,
      cacheTimeout: 3 * INTERVAL.MINUTE,
    }
  );

  const [response, setResponse] = React.useState({ loading: true });
  React.useEffect(() => {
    const [short_id, type] = parseProduct(full_id);
    if (callTasksService) {
      getCachedInternalApi(
        short_id && type
          ? `products/stock_and_elevated?full_id=${full_id}`
          : "",
        setResponse,
        3 * INTERVAL.MINUTE,
        true
      );
    }
  }, [full_id, callTasksService]);

  let { available, elevated, loading } = callTasksService
    ? (response as StockInfo)
    : data || {};
  loading = callTasksService ? loading : loadingFirebase;
  if (available) available = parseInt("" + available);
  if (elevated) elevated = parseInt("" + elevated);
  return React.useMemo(() => {
    const color = getIndicatorColor(loading, available, elevated);
    return { loading, available, elevated, color } as StockInfo;
  }, [loading, available, elevated]);
}
