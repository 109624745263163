import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { permissionActions } from "@coworker/common/services/permissions.service";
import { ReactComponent as ProductIcon } from "../assets/svg/product-icon.svg";
import { ReactComponent as AddonIcon } from "../assets/svg/addon-icon.svg";
import { ReactComponent as CustomIcon } from "../assets/svg/customize.svg";
import { ReactComponent as ProductQualityIcon } from "../assets/svg/product-quality-task.svg";
import { useProductQualityTeamId } from "../hooks/useProductQualityTeamId";
import { FixaWindow } from "@coworker/reusable";
import { useFlagsState } from "../hooks/useFlag";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  @media (min-width: 1440px) {
    flex-direction: row;
  }
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`;
const IconArea = styled.div`
  width: 22px;
  margin-left: 22px;
  margin-top: 10px;
  & > svg {
    @media (min-width: 1440px) {
      height: 40px;
      width: 40px;
    }
  }
  @media (min-width: 740px) {
    margin-left: 32px;
  }
  @media (min-width: 1440px) {
    margin-left: 100px;
  }
`;
const Text = styled.h1`
  padding-left: 29px;
  padding-right: 10px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  @media (min-width: 740px) {
    margin-left: 63px;
    font-size: 24px;
  }
  @media (min-width: 1440px) {
    margin-left: 66px;
    font-size: 36px;
  }
`;
const ClickableContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  border-bottom: 1px solid var(--grey100);

  &:last-of-type {
    border-bottom: none;
  }
`;

type RowType = "product" | "addon" | "product_quality" | "custom";
interface RowProps {
  row: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >;
    text: React.ReactElement;
    type: RowType;
  };
  onClick: React.MouseEventHandler<HTMLDivElement>;
  testId: string;
}

const Row = ({ row, onClick, testId }: RowProps) => (
  <ClickableContainer data-testid={testId} onClick={onClick}>
    <Wrapper>
      <IconArea data-testid={`${testId}-icon`}>
        <row.icon />
      </IconArea>
      <Text data-testid={`${testId}-text`}>{row.text}</Text>
    </Wrapper>
  </ClickableContainer>
);

interface CreateTaskProps {
  onCreateTask: (type: RowType, updateLocationState: boolean) => Promise<void>;
}

export const CreateTask = ({ onCreateTask }: CreateTaskProps) => {
  const { setOfFlags } = useFlagsState();
  const hideForGermanyUsers = (window as FixaWindow).hideForGermanyUsers;

  function canPerformAction(permissionAction: string) {
    return setOfFlags.has(permissionAction);
  }

  const isProductQualityTeamId = !!useProductQualityTeamId();

  return (
    <Container>
      {canPerformAction(permissionActions.CREATE_PRODUCT_ISSUE_TASK) && (
        <Row
          testId="createProductIssue"
          key="product"
          row={{
            icon: ProductIcon,
            text: <Trans>issueTitleOtherString</Trans>,
            type: "product",
          }}
          onClick={() => onCreateTask("product", true)}
        />
      )}
      {canPerformAction(permissionActions.CREATE_ADD_ON_TASK) && (
        <Row
          testId="createAddon"
          key="addon"
          row={{
            icon: AddonIcon,
            text: <Trans>addonOrderString</Trans>,
            type: "addon",
          }}
          onClick={() => onCreateTask("addon", true)}
        />
      )}
      {isProductQualityTeamId && !hideForGermanyUsers && (
        <Row
          testId="createProductQuality"
          key="product_quality"
          row={{
            icon: ProductQualityIcon,
            text: <Trans>productQualityReportString</Trans>,
            type: "product_quality",
          }}
          onClick={() => onCreateTask("product_quality", true)}
        />
      )}
      {canPerformAction(permissionActions.CREATE_CUSTOM_TASK) && (
        <Row
          testId="createCustom"
          key="custom"
          row={{
            icon: CustomIcon,
            text: <Trans>customString</Trans>,
            type: "custom",
          }}
          onClick={() => onCreateTask("custom", false)}
        />
      )}
    </Container>
  );
};
