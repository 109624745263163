import React from "react";
import type { Task } from "@coworker/types/lib/tasks/base";
import type { AddonTask } from "@coworker/types/lib/tasks/addon";
import type { HttpsCallableResult } from "@firebase/functions";
import { callInternalApi } from "../hooks/API/useCallInternal";
import {
  useFixaUID,
  useStoreId,
  useTeamId,
} from "../core/auth/useLoggedInUser";
import { useListCache } from "../hooks/useWatchedTasks";

const STALE_AFTER = 10_000;

export function stringifyListOfUpdatedAt(list: Task[]) {
  if (!list?.length) return "-";
  return JSON.stringify(list.map((x) => x.updated_at));
}

// Caching support for tasks-service
export type TaskServiceListReponse<T> = {
  list: T[];
  count: number | null;
  loading: boolean;
};
export type ListResponse = TaskServiceListReponse<object>;
export type ApiV0ListResponse = { data: ListResponse };
export type ListCallback = (value?: ListResponse) => void;
export type ApiV0ListResponsePromise =
  | Promise<ApiV0ListResponse>
  | Promise<void>
  | Promise<HttpsCallableResult>;

export const cachedLists: { [key: string]: ListResponse } = {};

export const fromCache = (method = "") => {
  const found = cachedLists[method];
  if (found?.list?.length) return found; // TODO: Support endpoints that do not return .list
  return {} as ListResponse;
};

export const last_at = {} as { [key: string]: number };

export const fetching: { [key: string]: ApiV0ListResponsePromise } = {};

// Specific for Task lists
const by_selector = (selector: string, teamId = "") =>
  `tasks/by_selector?selector=${selector}&teamId=${teamId}`;

export function methodForTaskList(filterOption: string, teamId: string) {
  switch (filterOption) {
    case "created_by_me_planned":
    case "planned":
      return by_selector("planned");

    case "created_by_me":
    case "created_by_me_in_progress":
    case "created_by_me_open":
    case "created_by_me_completed":
    case "created_by_me_closed":
      return by_selector("created_by_me");

    case "all_ongoing":
      return by_selector("all_ongoing");
    case "all_closed":
      return by_selector("all_closed");
    case "all_completed":
      return by_selector("all_completed");

    case "in_progress":
      if (!teamId) return "";
      return by_selector("team_ongoing", teamId);
    case "closed":
      if (!teamId) return "";
      return by_selector("team_closed", teamId);
    case "completed":
      if (!teamId) return "";
      return by_selector("team_completed", teamId);

    case "my":
      return by_selector("mine");
    case "open":
      if (!teamId) return "";
      return by_selector("open", teamId);
    case "related_product_tasks":
      // NOTE: Currently handled with additional wrapping via useTasksForProduct
      return "";
    case "":
      return ""; // too early render - nothing to do
    default:
      console.error(`unrecognised filterOption ${filterOption}`);
      return "";
  }
}

// Basic Product Info prefetch support
const shortIds = new Set<string>();
export const listShortIds = () => [...shortIds].filter(Boolean);
const collectShortIds = (tasks: AddonTask[]) =>
  tasks.forEach(({ product_article_id: shortId }) => shortIds.add(shortId));

// Tracks ongoing fetch to avoid parallell requests for the same data
const ongoing = {} as { [key: string]: Promise<ApiV0ListResponse> };

export function useCachedTasks(filterOption = "", who = "") {
  const teamId = useTeamId();
  const fixaUid = useFixaUID();
  const storeId = useStoreId();
  const method = filterOption && methodForTaskList(filterOption, teamId);

  const maxAge = who === "usePrefetchProductInfo" ? 600_000_000 : STALE_AFTER;
  const { cached, doRefetch, toCache } = useListCache(method, maxAge);

  // Product info prefetch support.
  if (ongoing[method] && "mine open".includes(filterOption)) {
    ongoing[method]!.then(({ data: { list } }) =>
      collectShortIds(list as AddonTask[])
    );
  }

  React.useEffect(() => {
    if (!method || (cached && !doRefetch)) return;
    if (!ongoing[method])
      ongoing[method] = callInternalApi(method, {
        fixaUid,
        team_id: teamId,
        store_id: storeId,
      });
    ongoing[method]!.then(({ data: { list } }) => {
      if (list && cached !== list) toCache(method, list);
      delete ongoing[method];
    });
  }, [
    filterOption,
    cached,
    method,
    toCache,
    doRefetch,
    who,
    fixaUid,
    storeId,
    teamId,
  ]);
  return { list: cached || [], loading: false };
}
