import React from "react";
import { Trans } from "@coworker/locales";
import {
  ErrorMessage,
  GraphHeader,
  GraphContainer,
  Loader,
  RefillAmountSkeleton,
  WideSkeleton,
} from "@coworker/components";
import styled from "styled-components";
import Card from "@coworker/components/src/components/Card";

const ErrorMessageWrapper = styled.div`
  padding-left: 15px;
  padding-bottom: 10px;
`;

const GraphGroupWrapper = styled.div`
  & > * {
    margin-top: 30px;
  }
  & > *:first-child {
    margin-top: 0px;
  }
`;

const WarningMessage = styled.div`
  margin: 0 16px 20px auto;
  font-size: 12px;
  font-style: italic;
  color: var(--grey700);
`;

export default function MultipleRefillGraphs({
  title,
  actionText,
  onClick,
  loading,
  formattedStartDate: start,
  formattedEndDate: end,
  totalLabelText,
  primaryTotal,
  graphGroups,
  warning,
  error,
}) {
  return (
    <Card
      testId="insightsRefillGraphContainer"
      noContentPadding
      title={title}
      action={{ label: actionText, onClick }}
      disabled={loading}
    >
      {!loading && !error && (
        <GraphHeader
          formattedTotalSum={primaryTotal}
          graphTitle={
            <Trans start={start} end={end} i18nKey="sumForCustomDateString">
              Estimated from refills on {{ start }} until {{ end }}
            </Trans>
          }
          totalLabelText={totalLabelText}
        />
      )}
      {loading && !graphGroups.length ? (
        <Loader>
          <RefillAmountSkeleton /> <WideSkeleton />
        </Loader>
      ) : (
        <GraphGroupWrapper>
          {graphGroups.map(({ bars, Header }, i) => {
            return (
              <div key={i}>
                {Header}
                <GraphContainer bars={bars} loading={loading} />
              </div>
            );
          })}
        </GraphGroupWrapper>
      )}
      {warning && <WarningMessage>*{warning}</WarningMessage>}
      {error && (
        <ErrorMessageWrapper>
          <ErrorMessage text={<Trans>insightsGraphError</Trans>} />
        </ErrorMessageWrapper>
      )}
    </Card>
  );
}
