import { useQuery } from "@tanstack/react-query";
import { getArticlesByGroupId } from "../services/groups.service";

export const useArticlesByGroupId = (groupId: string | undefined) => {
  return useQuery({
    queryKey: ["articlesByGroup", { id: groupId }],
    queryFn: () => getArticlesByGroupId(groupId || ""),
    enabled: !!groupId,
  });
};
