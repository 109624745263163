import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { ReactComponent as PencilIcon } from "../../../assets/svg/pencil.svg";
import { DescribeTheProblemProps } from "../../../types/describeTheProblem";
import {
  getTypeListEntry,
  getBadgeTranslationKeys,
} from "../../../helpers/describeTheProblem";

import CommercialMessage from "@ingka/commercial-message";

const Container = styled.div`
  background: white;
  padding: 0 29px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
  color: var(--grey900);
  min-height: 90px;
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  box-sizing: border-box;
  flex-direction: row;
  user-select: text;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  width: 100%;
  margin: 0px 0px 4px 0px;
`;

const TypeTitle = styled(Title)`
  color: var(--grey718);
`;

const IconWrapper = styled.div`
  width: 22px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  margin-right: 20px;
  justify-content: center;
  svg {
    margin: 0px auto;
    opacity: 1;
  }
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 24px 0px;
`;

const Subtitle = styled.div`
  font-size: 14px;
  color: var(--grey718);
  margin: 0px 0px 4px 0px;
  white-space: pre-wrap;
  word-wrap: break-all;
`;

const Badges = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Badge = styled(CommercialMessage)`
  background: var(--grey100);
  font-size: 12px;
  margin: 4px 8px 4px 0px;
  padding: 2px 8px;
  font-weight: 700;
`;

export default function DescribeTheProblem({ value }: DescribeTheProblemProps) {
  const { t } = useTranslation();
  const typeTranslationString = getTypeListEntry(
    value.pq_describe_the_problem_type
  )?.translationKey;
  const badgeTextKeys = getBadgeTranslationKeys(
    value.pq_describe_the_problem_type,
    value.pq_describe_the_problem_tags
  );
  return (
    <Widget data-testid={"describeTheProblemWidget"}>
      <Container>
        <IconWrapper>
          <PencilIcon width={22} height={22} />
        </IconWrapper>
        <Stack>
          <Title>
            <Trans>describeProblemString</Trans>
          </Title>
          {typeTranslationString && (
            <TypeTitle>
              <Trans>{typeTranslationString}</Trans>
            </TypeTitle>
          )}
          {value.description && <Subtitle>{value.description}</Subtitle>}
          {badgeTextKeys && (
            <Badges>
              {badgeTextKeys.map((textKey) => (
                <Badge message={t(textKey)} key={textKey} />
              ))}
            </Badges>
          )}
        </Stack>
      </Container>
    </Widget>
  );
}
