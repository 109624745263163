import React from "react";
import styled from "styled-components";

const Content = styled.div`
  height: 83px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 27px;
  background: var(--white);
  border-top: 1px solid var(--grey150);
`;

const Title = styled.p`
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
`;
const Description = styled.p`
  color: #484848;
  font-size: 14px;
`;

const LocationItem = ({ location }) => (
  <Content data-testid="insights_location_detail">
    <Title>{location[0]}</Title>
    <Description>{decodeURIComponent(location[1])}</Description>
  </Content>
);

export default LocationItem;
