import React from "react";
import Card, {
  ActivityIndicator,
} from "@coworker/components/src/components/Card";
import ListItem, {
  ListContainer,
} from "@coworker/components/src/components/ListItem";
import { Trans } from "@coworker/locales";
import {
  splitLocation,
  useLocationCapacity,
} from "../../services/locations.service";
import useFormatter from "../../hooks/useFormatter";
import { useCallInsightsFunction } from "../../hooks/API/useCallFirebaseFunction";
import { INSIGHTS_REFILL_DATES } from "@coworker/functions/src/enums/insightsRequestTypes";
import { parseProduct } from "../../services/products.service";

const ProductLocationDetails = ({ params }) => {
  const { group_by, weeksBack, today, start_date, end_date, ...destParams } =
    params;

  const { data, loading } = useCallInsightsFunction(INSIGHTS_REFILL_DATES, {
    ...destParams,
    product_id: destParams.product_article_code,
  });
  const [short_id, type] = parseProduct(destParams.product_id);
  const { toTimeAgo } = useFormatter();

  const isLoading = loading;

  const gridcode = splitLocation(destParams.location)[0];
  const maxInLocation = useLocationCapacity(gridcode, `${type}${short_id}`);

  return (
    <Card title={<Trans>productLocationDetailsTitle</Trans>} noContentPadding>
      {isLoading || !data ? (
        <ActivityIndicator />
      ) : (
        <ListContainer>
          {maxInLocation ? (
            <ListItem inverse horizontal title={<Trans>maxInLocation</Trans>}>
              {maxInLocation}
            </ListItem>
          ) : null}
          {data?.last_fill_date && (
            <ListItem inverse horizontal title={<Trans>lastRefill</Trans>}>
              {toTimeAgo(new Date(data.last_fill_date).getTime(), "day")}
            </ListItem>
          )}
        </ListContainer>
      )}
    </Card>
  );
};

export default ProductLocationDetails;
