import React from "react";
import { useTranslation } from "@coworker/locales";
import { Text } from "./Text";

interface PiecesTextProps {
  pieces: number;
}

export const PiecesText = ({ pieces }: PiecesTextProps) => {
  const { t } = useTranslation();
  const piecesString =
    pieces !== 1 ? "piecesCountString_plural" : "piecesCountString";
  return <Text text={t(piecesString, { count: pieces })} />;
};
