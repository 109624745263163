import React from "react";
import styles from "./team-selection-header.module.css";
import IKEALogo from "../../../assets/IKEALogo.svg";
import { SimpleHeader } from "../../../../layout/components/SimpleHeader/SimpleHeader";
import { Trans } from "@coworker/locales";
import { useMyStore } from "@coworker/app/src/hooks/useMyStore";

export const TeamSelectionHeader = () => {
  const currentStore = useMyStore();
  return (
    <div>
      <SimpleHeader />
      <div className={styles["header-wrapper"]}>
        <div className={styles["header-container-text"]}>
          <p className={styles["current-store"]}>
            <Trans>currentStoreString</Trans>
          </p>
          <h2
            className={styles["store-name"]}
          >{`${currentStore?.name} - ${currentStore?.id}`}</h2>
          <p className={styles["contact-store-super-user-message"]}>
            <Trans>contactStoreSuperUserString</Trans>
          </p>
        </div>
        <div className={styles["logo-wrapper"]}>
          <img
            className={styles["header-logo"]}
            src={IKEALogo}
            alt="IKEA logo"
          />
          <h4>Fixa</h4>
        </div>
      </div>
    </div>
  );
};
