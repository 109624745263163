import React from "react";
import styled from "styled-components";
import Skeleton from "@ingka/skeleton";

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 10px;
`;
const LeftColumnContainer = styled(ColumnContainer)`
  flex-grow: 0;
`;
const RightColumnContainer = styled(ColumnContainer)`
  flex-grow: 1;
`;

const SkeletonBox = styled(Skeleton)`
  width: 48px !important;
  height: 48px;
`;
const SkeletonRow = styled(Skeleton)`
  width: 100%;
  height: 12px;
  margin: 2px !important;
`;
export function RoomsViewSearchListItemSkeleton() {
  return (
    <RowContainer>
      <LeftColumnContainer>
        <SkeletonBox />
      </LeftColumnContainer>
      <RightColumnContainer>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </RightColumnContainer>
    </RowContainer>
  );
}
