import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PartialArticle } from "../types/article";
import { updateArticle } from "../services/articles.service";

export const useUpdateArticle = (articleId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PartialArticle) => updateArticle(articleId, data),
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["article", articleId],
      });
    },
  });
};
