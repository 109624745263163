import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { Button } from "@coworker/components";
import { ReactComponent as GraphIcon } from "../../assets/svg/dataChart.svg";
import { ReactComponent as LinkIcon } from "../../assets/svg/linkOut.svg";
import { generateLivligProductLink } from "@coworker/reusable";
import tracker from "../../helpers/tracker";
import { featureNames } from "@coworker/functions/src/enums/featureNames";

const Container = styled.div`
  font-size: 14px;
  background: var(--white);
  max-width: 100vw;
  padding: 17px 21px;
`;
const Inner = styled.div`
  padding: 20px;
  background: var(--grey100);
  border-radius: 5px;
`;
const Top = styled.div`
  font-weight: bold;
  display: flex;
`;
const Title = styled.div`
  margin-left: 20px;
`;
const Description = styled.div`
  margin-top: 10px;
`;
const Bottom = styled.div`
  margin-left: 40px;
`;

const LivligLinkIcon = styled(LinkIcon)`
  margin-left: 15px;
`;

const LinkButton = styled(Button)`
  margin: 10px 0px 0px 0px;
  justify-content: space-between;
  border: 1px solid var(--black);
  & > * {
    padding: 12px 23px;
  }
`;

// - It is important to have 'livligLinkClicked' as a global variable. Purpose of 'livligLinkClicked'
// is to keep track on if the Livlig Link has been klicked or not. If not, then the trackLinkDisplay
// should be tracked. See Task:
// https://jira.digital.ingka.com/browse/COAPP-5311
// - The trackLinkDisplay is done in the unmounting (the return function of useEffect) of the cvomponent
// i.e. when the component stops being shown.
// - If 'livligLinkClicked' is moved into the component, then it will be problematic as the component is rerendered
// several time during the drawing process thus, generating a lot of logs,
let livligLinkClicked = false;

const LivligLinkBox = ({ storeId, shortId }) => {
  const livligUrl = generateLivligProductLink(storeId, shortId);

  const trackLinkDisplaySentIfLivligLinkHasNotBeenClicked = (
    _storeId,
    _shortId
  ) => {
    if (!livligLinkClicked) {
      tracker.livlig.trackLinkDisplay(
        _storeId,
        _shortId,
        featureNames.INSIGHTS
      );
    }
    // As 'livligLinkClicked' is global, it has to be reset.
    livligLinkClicked = false;
  };

  React.useEffect(() => {
    return () => {
      trackLinkDisplaySentIfLivligLinkHasNotBeenClicked(storeId, shortId);
    };
  }, [storeId, shortId]); // Array so that the unmount is only called once.

  return (
    <Container>
      <Inner>
        <Top>
          <GraphIcon />
          <Title>
            <Trans>livligLinkTitle</Trans>
          </Title>
        </Top>
        <Bottom>
          <Description>
            <Trans>livligLinkDescription</Trans>
          </Description>
          <LinkButton
            data-testid="livligLinkButton"
            text={
              <>
                <Trans>livligLinkGoto</Trans>
                <LivligLinkIcon />
              </>
            }
            onClick={() => {
              livligLinkClicked = true;
              tracker.livlig.trackLinkClick(
                storeId,
                shortId,
                featureNames.INSIGHTS
              );
              livligUrl && window.open(livligUrl, "_blank");
            }}
          />
        </Bottom>
      </Inner>
    </Container>
  );
};

export default LivligLinkBox;
