import React from "react";
import styled, { css } from "styled-components";
import { Trans } from "@coworker/locales";
import rightArrowIcon from "@ingka/ssr-icon/paths/chevron-right";
import starIcon from "@ingka/ssr-icon/paths/star-filled";
import SSRIcon from "@ingka/ssr-icon";
import { SmallText } from "../Overview/NewReview";
import Skeleton from "@ingka/skeleton";
import "@ingka/loading/dist/style.css";
import "@ingka/skeleton/dist/style.css";
import { getRoundData } from "../helper";
import { ActiveRoundObject } from "../Types";

interface RoundCardProps {
  title: string | undefined;
  currentValue: number | undefined;
  onClick?: () => void;
  showNav?: boolean;
  background?: string;
  whiteBarBackground?: boolean;
  loading?: boolean;
  activeRound?: ActiveRoundObject;
}
const Container = styled.div<{ $background: string; isActiveRound: boolean }>`
  height: 107px;
  border-top: 1px solid var(--grey200);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({ $background }) =>
    $background === "primary" ? "var(--white)" : "var(--grey100)"};
  pointer-events: ${({ isActiveRound }) => (isActiveRound ? "auto" : "none")};
  opacity: ${({ isActiveRound }) => (isActiveRound ? 1 : 0.2)};
`;

const TitleWrapper = styled.div`
  width: 90%;
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: 400;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 90%;
  position: relative;
`;

const ValueWrapper = styled(BarWrapper)`
  justify-content: space-between;
  margin-top: -7px;
`;

const CompletedBar = styled.div<{
  value: number;
  background: string;
  isLevelCompleted: boolean;
}>`
  margin: 10px 0px;
  height: 11px;
  ${(props) =>
    css`
      border-radius: ${props.isLevelCompleted ? "5px" : "5px 0px 0px 5px"};
    `}
  ${(props) =>
    css`
      background: ${props.background};
    `}
    ${(props) =>
    css`
      width: ${props.value * 2}%;
    `}
`;

const RemainingBar = styled(CompletedBar)<{
  whiteBarBackground: boolean;
}>`
  ${(props) =>
    css`
      border-radius: ${props.value === 50 ? "5px" : "0px 5px 5px 0px"};
    `}
  ${(props) =>
    css`
      background: ${props.whiteBarBackground
        ? "var(--white)"
        : "var(--grey100)"};
    `}
`;

const FirstCheckPoint = styled.div<{ showNav: boolean }>`
  height: 8px;
  width: 8px;
  background: #d9d9d9;
  border-radius: 50%;
  position: absolute;
  ${(props) =>
    css`
      margin-right: ${props.showNav ? "22px" : "0px"};
    `}
`;

const LastCheckPoint = styled(FirstCheckPoint)<{ showNav: boolean }>`
  ${(props) =>
    css`
      right: ${props.showNav ? "12px" : "3px"};
    `}
`;

export const RoundCard = ({
  title,
  currentValue = 0,
  onClick,
  showNav = true,
  background = "primary",
  whiteBarBackground = false,
  loading,
  activeRound = { isActiveRound: true } as ActiveRoundObject,
}: RoundCardProps) => {
  if (loading) {
    return (
      <Container
        $background={background}
        isActiveRound={activeRound?.isActiveRound}
      >
        <TitleWrapper style={{ marginBottom: "5px" }}>
          <Title>
            <Trans>{title}</Trans>
          </Title>
        </TitleWrapper>
        <Skeleton width="90%" height="52px" />
      </Container>
    );
  }

  const roundData = getRoundData(currentValue);
  return (
    <Container
      onClick={onClick}
      $background={background}
      isActiveRound={activeRound.isActiveRound}
    >
      <TitleWrapper>
        <Title>
          <Trans>{title}</Trans>
        </Title>
      </TitleWrapper>
      <BarWrapper>
        <CompletedBar
          value={roundData.displayValue}
          isLevelCompleted={roundData.isLevelCompleted}
          background={roundData.color}
        />
        {!roundData.isFirstStarReached && <FirstCheckPoint showNav={showNav} />}
        {!roundData.isLevelCompleted && <LastCheckPoint showNav={showNav} />}
        <RemainingBar
          value={roundData.displayMaxValue - roundData.displayValue}
          isLevelCompleted={roundData.isLevelCompleted}
          background={whiteBarBackground ? "var(--white)" : "var(--grey200)"}
          whiteBarBackground={whiteBarBackground}
        />
        {showNav && (
          <SSRIcon paths={rightArrowIcon} style={{ margin: "0 0 5px 10px" }} />
        )}
      </BarWrapper>
      <ValueWrapper>
        <div>
          <SSRIcon
            paths={starIcon}
            color={
              roundData.isFirstStarReached ? roundData.color : "var(--grey200)"
            }
          />
          <SSRIcon
            paths={starIcon}
            color={
              roundData.isLevelCompleted ? roundData.color : "var(--grey200)"
            }
          />
          <SmallText
            style={{
              color: roundData.isLevelCompleted
                ? roundData.color
                : "var(--disabled)",
              fontWeight: 700,
              marginLeft: "2px",
            }}
          >
            <Trans>levelString</Trans> {roundData.level}{" "}
            {roundData.isLevelCompleted && <Trans>completedLowerString</Trans>}
          </SmallText>
        </div>
        <SmallText
          style={{
            marginRight: showNav ? "34px" : "0px",
            color: "var(--grey600)",
          }}
        >
          {roundData.currentValue} / {roundData.maxValue}
        </SmallText>
      </ValueWrapper>
    </Container>
  );
};
