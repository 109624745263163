import React from "react";
import { Trans } from "@coworker/locales";
import styled from "styled-components";

const Container = styled.div`
  background-color: var(--grey100);
  width: 100%;
  height: 100%;
  padding: 0px 108px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--black);
  font-weight: bold;
`;

const Subtitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: var(--black);
`;

const ListEmptyState = (props) => {
  return (
    <Container>
      <Subtitle>
        <Trans>nothingString</Trans>
      </Subtitle>
      <Title>
        <Trans>cantFindAnything</Trans>
      </Title>
    </Container>
  );
};

export default ListEmptyState;
