/**
 * Removes any fixa specific suffix from the ikea store id, aka BU number
 * @param {string} storeString
 * @returns {string}
 */
function extractBUfromFixaStoreId(storeString) {
  const virtualStoreRegex = /[$#]/;
  if (!storeString || !storeString.split) return "";
  return storeString.split(virtualStoreRegex)[0];
}

module.exports.extractBUfromFixaStoreId = extractBUfromFixaStoreId;
