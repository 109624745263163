import { Article, Articles, PartialArticle } from "../../types/article";
import {
  addArticlesBulk,
  deleteArticleById,
  updateArticle,
} from "../../services/articles.service";
import { addArticle } from "../../services/articles.service";

export async function handleArticleUpdateWithNewArticleNumber(
  article: Article,
  newGroupId: string,
  articles: Articles,
  invalidateQueries: () => Promise<void>
) {
  const newNbrArticles = article?.newNbrArticles ?? article.nbrArticles;

  const existingTargetArticle = articles.find(
    (art) =>
      art.productArticleId === article.productArticleId &&
      newGroupId === art.groupId
  );

  if (!!existingTargetArticle) {
    existingTargetArticle.nbrArticles += newNbrArticles;
    if (newNbrArticles >= article.nbrArticles) {
      // All articles in the group are moved to another group
      await updateArticle(existingTargetArticle.id, existingTargetArticle);
      await deleteArticleById(article.id);
    } else {
      // Partial articles in are moved to another group
      article.nbrArticles -= newNbrArticles;
      await updateArticle(article.id, article);
      await updateArticle(existingTargetArticle.id, existingTargetArticle);
    }
  } else {
    if (newNbrArticles >= article.nbrArticles) {
      // All articles in the group are moved to another group
      article.nbrArticles = newNbrArticles;
      article.groupId = newGroupId;
      await updateArticle(article.id, article);
    } else {
      // Partial articles in are moved to another group
      article.nbrArticles = article.nbrArticles - newNbrArticles;
      await updateArticle(article.id, article);
      const newArticleInSelectedGroup = {
        ...article,
        nbrArticles: newNbrArticles,
        groupId: newGroupId,
      } as PartialArticle;
      await addArticle(newArticleInSelectedGroup as Article);
    }
  }
  await invalidateQueries();
}

export function reduceToUniqueArticlesBasedOnField(
  articles: Article[],
  fieldExtractor: (article: Article) => string
): Article[] {
  return articles.reduce((acc, cur) => {
    const existingArticle = acc.find(
      (article) => fieldExtractor(article) === fieldExtractor(cur)
    );
    if (!existingArticle) {
      acc.push(cur);
    }
    return acc;
  }, [] as Article[]);
}

export async function addUniqueArticlesToGroup(
  articles: Article[],
  groupId: string
): Promise<void> {
  const remappedArticles = articles.map((article) => ({
    ...article,
    nbrArticles: 1,
    groupId: groupId,
  }));

  await addArticlesBulk(
    reduceToUniqueArticlesBasedOnField(
      remappedArticles,
      (article) => article.productArticleId
    ),
    groupId || ""
  );
}

export function getAllArticlesBasedOnProductArticleId(
  completeSetOfArticles: Articles,
  articlesWithProductArticleId: Articles
): Articles {
  return completeSetOfArticles.filter((completeArticle) =>
    articlesWithProductArticleId.find(
      (articlesWithProductArticleId) =>
        articlesWithProductArticleId.productArticleId ===
        completeArticle.productArticleId
    )
  );
}
