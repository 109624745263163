import React from "react";
import styled from "styled-components";
import TaskCreationRow from "./TaskCreationRow";
import { ReactComponent as SofaIcon } from "../assets/svg/sofa.svg";
import { ReactComponent as OpenIcon } from "../assets/svg/arrow-small-forward.svg";
import { ReactComponent as DownIcon } from "../assets/svg/icon-arrow-small-down.svg";
import { ReactComponent as UpIcon } from "../assets/svg/icon-arrow-small-up.svg";
import { ImageSkeleton } from "@coworker/reusable/components/ImageSkeleton";
import { Trans } from "@coworker/locales";
import { TwoShortLinesSkeleton } from "@coworker/reusable/Loader";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.insights && "margin-top: -15px"}
`;
const RefilledContainer = styled.div`
  margin-left: ${(props) => props.margin};
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const GrayText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  color: var(--grey700);
`;

const Title = styled.div`
  font-weight: bold;
  color: var(--black);
`;
const ProductImage = styled.img`
  height: 70px;
`;
const InsightImage = styled.img`
  height: 70px;
  min-width: 73px;
  max-width: 110px;
  margin-right: -35px;
`;
const Refilled = styled.div`
  display: flex;
  ${(props) =>
    !props.editEnabled && "justify-content: space-between;margin-top: 20px;"}
  align-items: center;
  line-height: 1.5;
`;
const Turnover = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TextColor = styled.p`
  color: var(--grey718);
`;
const DataText = styled.p`
  color: var(--grey718);
  ${(props) => props.editEnabled && "margin-top: -50px;"}
`;
const WrappedOpenIcon = styled(OpenIcon)`
  margin-right: -8px;
`;

const noop = () => null;

export function ControlledProductDetails({
  title,
  subtitle,
  descriptive,
  formattedNumber,
  onClick = noop,
  insights,
  showOnlyLocation,
  smallImage,
  editEnabled,
  openEditInsights,
}) {
  return (
    <Container margin={insights && !showOnlyLocation}>
      {!!insights && !showOnlyLocation && (
        <InsightImage
          data-testid="productImage"
          src={smallImage}
          insights={insights}
        />
      )}
      <TaskCreationRow
        data-testid="productContent"
        onClick={onClick}
        minheight={showOnlyLocation ? "" : "75px"}
        left={!insights && <SofaIcon />}
        showOnlyLocation={showOnlyLocation}
        right={
          insights ? null : smallImage ? (
            <ProductImage data-testid="productImage" src={smallImage} />
          ) : (
            <ImageSkeleton size={72} />
          )
        }
      >
        {insights ? (
          <TitleWrapper>
            <Title data-testid="productTitle">{title}</Title>
            {editEnabled ? (
              openEditInsights ? (
                <UpIcon />
              ) : (
                <DownIcon />
              )
            ) : (
              <WrappedOpenIcon />
            )}
          </TitleWrapper>
        ) : (
          <TitleWrapper>
            <Title data-testid="productTitle">{editEnabled ? "" : title}</Title>
          </TitleWrapper>
        )}
        <GrayText data-testid="productDescription">
          {editEnabled ? "" : subtitle || descriptive}
        </GrayText>
        <GrayText data-testid="productNumber">{formattedNumber}</GrayText>
      </TaskCreationRow>
    </Container>
  );
}

export function ControlledRefillAndTurnover({
  refilled,
  turnover,
  showOnlyLocation,
  editEnabled,
}) {
  return refilled === null ? (
    <TwoShortLinesSkeleton />
  ) : (
    <RefilledContainer margin={showOnlyLocation ? "0px" : "85px"}>
      <Refilled editEnabled={editEnabled}>
        <TextColor>
          {editEnabled ? (
            <Trans>insightsTotalRefilledString</Trans>
          ) : (
            <Trans>insightPircesRefilledString</Trans>
          )}
        </TextColor>
        <DataText data-testid="refilled_value">
          {editEnabled && ` : `} {refilled}{" "}
          {editEnabled && <Trans>pcsString</Trans>}
        </DataText>
      </Refilled>
      {!editEnabled && (
        <Turnover>
          <TextColor>
            <Trans>insightTurnoverString</Trans>
          </TextColor>
          <DataText data-testid="turnover_value">{turnover}</DataText>
        </Turnover>
      )}
    </RefilledContainer>
  );
}
