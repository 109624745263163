import React from "react";

/**
 * @returns {{width: number height: number}}
 */
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: undefined,
    height: undefined,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

/**
 * Provides the window dimensions, the ratio between the bigger and the smaller dimension and scanning frame location
 */
export function useWindowProperties() {
  const { height, width } = useWindowSize();
  return React.useMemo(() => {
    const isLandscape = height <= width;
    const ratio = isLandscape ? width / height : height / width;
    return {
      size: { height, width },
      ratio,
    };
  }, [height, width]);
}
