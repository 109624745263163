import { defaultTimezone } from "../../../constants/clientTime";
import { useTranslation } from "@coworker/locales";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export function useFetchLatestShoppingToolsSessionTime(
  timestamp: number
): string {
  const { t } = useTranslation();
  dayjs.extend(utc);
  dayjs.extend(timezone);

  if (timestamp === 0) {
    return t("notStartedString");
  }

  const utcDate = dayjs.utc(timestamp).tz(defaultTimezone);
  const currentTime = dayjs();
  const isToday = utcDate.isSame(currentTime, "day");

  const timeDifferences = {
    hours: currentTime.diff(utcDate, "hours"),
    days: currentTime.diff(utcDate, "days"),
    weeks: currentTime.diff(utcDate, "weeks"),
    months: currentTime.diff(utcDate, "months"),
    years: currentTime.diff(utcDate, "years"),
  };

  const getTimeString = (
    difference: number,
    singularKey: string,
    pluralKey: string
  ) =>
    `${difference} ${difference === 1 ? t(singularKey) : t(pluralKey)} ${t(
      "agoString"
    )}`;

  if (timeDifferences.hours < 24) {
    return isToday
      ? t("ongoingString")
      : getTimeString(timeDifferences.hours, "hourString", "hoursString");
  }

  if (timeDifferences.days < 7) {
    return getTimeString(timeDifferences.days, "dayString", "daysString");
  }

  if (timeDifferences.weeks <= 4 && timeDifferences.months === 0) {
    return getTimeString(
      timeDifferences.weeks,
      "weekString",
      "weekString_plural"
    );
  }

  if (timeDifferences.months < 12) {
    return getTimeString(timeDifferences.months, "monthString", "monthsString");
  }

  return getTimeString(timeDifferences.years, "yearString", "yearsString");
}
