import React from "react";
import BlueLink from "../PrivacyStatementComponents";

export const policy = {
  title: <div>Privacy statement</div>,
  aboutFixaText: (
    <div>
      Fixa is a task management and merchandising tool for in-store co-workers.
      The Fixa application's purpose is to replace manual, paper or excel based
      activities and streamline task management in store (refilling articles,
      clean ups, deviations, test buys, most frequently asked questions etc.)
      Fixa also allows for analytics through insights gained from completed
      tasks.
      <br />
      <br />
      When you use Fixa, the Ingka Group company Data Controller (as defined
      below) willcollect certain personal data about you.
    </div>
  ),
  personalDataCollectedTitle: <p>The personal data collected is:</p>,
  personalDataCollectedText: (
    <div>
      When you use Fixa, the system will collectinformation about you from the
      Co-Worker Database (CDS) such as your First name, middle name(s), last
      name, email, job title, role, phone number (if manually provided -
      personal phone number should not be entered), photograph from CDS,
      language preference in app, store ID, country ID, version of device and
      operating system, IP-address processed in Google Cloud Platform and Sentry
      (Sentry is an application error tracking software.)
      <br />
      <br />
      Co-worker profile is manually created through Fixa-admin (an admin web
      app) by the manager/Superuser. Personal data is fetched from CDS, so
      profiles can only be created for co-workers who have an active CDS
      profile.
    </div>
  ),
  purposeOfDataCollectionTitle: <p>The purpose of collecting this data is:</p>,
  purposeOfDataCollectionText: (
    <div>
      The purpose of the personal data collection in Fixa is for the application
      to function properly in a way that enables easy internal communication
      between co-workers and administrate tasks between co-workers in the store.
      Having tasks connected to a user enables us to support notifications for
      updates on tasks for assigned users and creators.
    </div>
  ),
  legalGroundsTitle: <p>The legal ground for the processing is:</p>,
  legalGroundsText: (
    <div>
      Contract, using Fixa is an integral part of the tasks co-workers are
      required to perform.
    </div>
  ),
  dataWillBeStoredTitle: <p>The data will be stored:</p>,
  dataWillBeStoredText: (
    <div>
      We store finished tasks with references to users for 70 days, whereafter
      they are anonymised. A co-worker's personal data is stored in Fixa for the
      duration of the employment contract.
      <br />
      <br />
      Termination of employment contract with Ingka Group: All personal data is
      being automatically deleted within 1 months after the CDS profile has been
      removed.
      <br />
      <br />
      Change of role within Ingka Group: If a co-worker no longer requires using
      Fixa, all personal data will be automatically deleted within 3 months of
      in-activity.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>
      The data controller for the processing of personal data in Fixa is:
    </div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA AG, Müslistrasse 16, 8957 Spreitenbach
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      In the document “Privacy Information for Co-workers” you can read more
      about your data privacy in relation to co-worker personal data. The latest
      version of this document is available here:
      <br />
      <BlueLink href="https://iweof.sharepoint.com/teams/o365g_digitalswitzerland_retchso/SitePages/Data-Privacy.aspx">
        Data Privacy Information - Switzerland
      </BlueLink>
    </div>
  ),
};
