import React from "react";
import { EmailLink } from "../PrivacyStatementComponents";

export const policy = {
  title: <div> Adatvédelmi nyilatkozat</div>,
  aboutFixaText: (
    <div>
      A Fixa egy feladatkezelési és értékesítési eszköz áruházi munkatársak
      számára. A Fixa alkalmazás célja, hogy felváltsa a kézzel vezetett, papír
      vagy excel alapú tevékenységeket és optimalizálja az áruházi
      feladatkezelést (termékek feltöltése, készleteltérések, próbavásárlások,
      GYIK, stb.). A Fixa emellett az elvégzett feladatok alapján analitika
      lehívását is lehetővé teszi.
      <br />A Fixa használatakor az Ingka Csoport vállalati adatkezelője (az
      alábbiakban meghatározottak szerint) bizonyos személyes adatokat gyűjt a
      munkatársról.
    </div>
  ),

  personalDataCollectedTitle: (
    <div>A gyűjtött személyes adatok köre a következő:</div>
  ),
  personalDataCollectedText: (
    <div>
      A Fixa használata során a rendszer a CDS-ből (Co-Worker Database =
      Munkatársakra vonatkozó adatbázis) adatokat gyűjt a felhasználóra
      vonatkozóan, amelyek a keresztnév, középső név, vezetéknév, beosztás,
      telefonszám (Figyelem: amennyiben ennek kitöltése nem automatikusan
      történik, magántelefonszámot ne adj meg!), a CDS-ben tárolt fénykép, az
      alkalmazás használatához választott nyelv, áruházi szám, országkód, eszköz
      verziója és operációs rendszere, a Google Cloud Platformon és a Sentry-ben
      (a Sentry egy applikációs hibakereső szoftver) nyilvántartott IP-cím.
      <br />
      <br />A munkatárs profilját a vezető/Super user készíti el személyesen a
      Fixa-admin (admin web applikáció) segítségével. A személyes adatok a
      CDS-ből kerülnek lekérésre, így csak olyan munkatársra vonatkozóan
      készülhet profil, aki aktív CDS profillal rendelkezik.
    </div>
  ),
  purposeOfDataCollectionTitle: <div>Ezen adatok gyűjtésének célja:</div>,
  purposeOfDataCollectionText: (
    <div>
      A Fixa-ban a személyes adatok gyűjtése az applikáció megfelelő működésének
      biztosítása céljából történik, annak érdekében, hogy a munkatársak között
      egyszerű belső kommunikációt tegyen lehetővé és az áruházi feladatok
      összehangolt elvégzését biztosítsa a munkatársak számára. A felhasználó
      feladatokhoz való hozzárendelése révén a feladatokra vonatkozó információk
      változásáról értesítést tudunk küldeni mind a feladatokhoz rendelt
      felhasználók, mind a feladatot kijelölők számára.
    </div>
  ),
  legalGroundsTitle: <div>Az adatkezelés jogalapja:</div>,
  legalGroundsText: <div>Az adatkezelés jogalapja a jogszerű érdek.</div>,
  dataWillBeStoredTitle: <div>Az adatok tárolása:</div>,
  dataWillBeStoredText: (
    <div>
      Az elvégzett feladatokat a felhasználókra való hivatkozással 70 napig
      tároljuk, amely időtartamot követően az adatok anonimizálásra kerülnek. A
      munkatársakra vonatkozó személyes adatokat a munkaszerződés időtartama
      alatt a Fixa-ban tároljuk.
      <br />
      <br />
      z Ingka Csoporttal fennálló munkaszerződés felmondása: A CDS profil
      törlését követő 1 hónapon belül minden személyes adat automatikusan
      törlésre kerül.
      <br />
      <br />
      Másik pozíció betöltése az Ingka Csoporton belül: Amennyiben a munkatárs
      számára munkaköre betöltéséhez már nem szükséges a Fixa használata, az
      utolsó felhasználást követő 3 hónapon belül az összes személyes adata
      törlésére sor kerül.
      <br />
      <br />A Személyes Adatok törlésére a munkavállaló kérése alapján bármikor
      sor kerülhet a helyi adatbiztonságért felelős vezetőhöz vagy
      áruházvezetőhöz eljuttatott kérelem alapján.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>A személyes adatok ennek keretében történő adatkezelője:</div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      IKEA Lakberendezési Kft.,
      <br />
      1148 Budapest, Örs vezér tere 22.
      <br />
      <br />
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      A személyes adataidra vonatkozóan jogokkal rendelkezel. További részletes
      információkat a munkatársakra vonatkozó adatkezelési szabályzatban
      találsz:{" "}
      <a href="https://iweof.sharepoint.com/:b:/t/o365g_privacyczhusk_retczso/EZbUcWqtESZFgm7cgVui7uIBSqYhYudup6FXyOvtJ78lyw?e=4OUHdc">
        https://iweof.sharepoint.com/:b:/t/o365g_privacyczhusk_retczso/EZbUcWqtESZFgm7cgVui7uIBSqYhYudup6FXyOvtJ78lyw?e=4OUHdc
      </a>
      <br />
      <br />
      Kapcsolat: <EmailLink email="data.privacy.hu@ikea.com" />
    </div>
  ),
};
