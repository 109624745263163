const actorTypes = {
  SYSTEM: "SYSTEM",
};

const actorTypeKeys = {
  SYSTEM: "systemString",
};

module.exports = {
  actorTypeKeys,
  actorTypes,
};
