import SkapaTabs from "@coworker/app/src/components/InputPopup/SkapaTabs";
import { useTranslation } from "@coworker/locales";
import React from "react";
import styles from "./task-state-tabs.module.css";
import trackerHelper from "@coworker/app/src/helpers/tracker";

type TasksCount = {
  open: number | string;
  inProgress: number | string;
  completed: number | string;
  closed: number | string;
};

interface TaskStateTabsProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  tasksCount: TasksCount;
}

export const TaskStateTabs = ({
  activeTab,
  setActiveTab,
  tasksCount,
}: TaskStateTabsProps) => {
  const { t } = useTranslation();

  const tabs = [
    {
      title: `${t("teamOpenString")} ${tasksCount.open}`,
      key: "OPEN",
    },
    {
      title: `${t("inProgressString")} ${tasksCount.inProgress}`,
      key: "IN_PROGRESS",
    },
    {
      title: `${t("completd3String")} ${tasksCount.completed}`,
      key: "COMPLETED",
    },
    {
      title: `${t("teamClosedString")} ${tasksCount.closed}`,
      key: "CLOSED",
    },
  ];

  const tabChangedHandler = (tabKey: string) => {
    setActiveTab(tabKey);
    trackerHelper.trackActivityTabClick(tabKey);
  };

  return (
    <div className={styles["task-state-tabs"]}>
      <SkapaTabs
        tabs={tabs}
        active={activeTab}
        onTabChanged={tabChangedHandler}
        noTabPanelPadding
      />
    </div>
  );
};
