import React, { useEffect, useState } from "react";
import { Room } from "../../types/room";
import { useRoomsByStoreId } from "../../hooks/useRoomsByStoreId";
import styled from "styled-components";
import { useRoomById } from "../../hooks/useRoomById";
import { RoomImagesListItem } from "../../Molecules/RoomImagesListItem";
import { useArticlesByRoomId } from "../../hooks/useArticlesByRoomId";
import Modal, { ModalFooter, ModalHeader, Sheets } from "@ingka/modal";
import Button from "@ingka/button";
import FormField from "@ingka/form-field";
import InputField from "@ingka/input-field";
import Select, { Option } from "@ingka/select";
import Switch from "@ingka/switch";
import TextArea from "@ingka/text-area";
import tag from "@ingka/ssr-icon/paths/tag";
import filledStar from "@ingka/ssr-icon/paths/star-filled";
import star from "@ingka/ssr-icon/paths/star";
import paintbrush from "@ingka/ssr-icon/paths/paintbrush";
import family from "@ingka/ssr-icon/paths/family-gender-mixed";
import stairs from "@ingka/ssr-icon/paths/stairs";
import flag from "@ingka/ssr-icon/paths/flag";
import bike from "@ingka/ssr-icon/paths/bike";
import home from "@ingka/ssr-icon/paths/home";
import locationPinSmall from "@ingka/ssr-icon/paths/location-pin-small";
import { useUpdateRoom } from "../../hooks/useUpdateRoom";
import { useTranslation } from "@coworker/locales";
import { getDepartmentsOptions } from "../../../../helpers/departmentsOptions";
import { getStyleGroupOptions } from "../../../../helpers/styleGroupOptions";
import { getLivingSituationOptions } from "../../../../helpers/livingSituationOptions";
import { getLongTermPriorityOptions } from "../../../../helpers/longTermPriorityOptions";
import { getPricingDataOptions } from "../../../../helpers/pricingDataOptions";
import {
  checkEmptyRoomName,
  checkRoomName,
  getArticleName,
  getFullArticleName,
  validationMessage,
} from "./SettingsModal.helper";
import { MessageModal } from "./MessageModal";
import { LabelText } from "../../Atoms/LabelText";
import { WarningTextWithIcon } from "../../Atoms/WarningTextWithIcon";
import { OkCancelModal } from "./OkCancelModal";

const PaddedHorizontalContainer = styled.div`
  padding: 0 1rem;
`;

const ModalItemWrapper = styled.div`
  > * + * {
    margin-top: 20px;
  }
  padding: 20px 0;
`;

const FlexSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface SettingsModalProps {
  roomId: string;
  isVisible: boolean;
  onClose: () => void;
}

export const SettingsModal = function ({
  roomId,
  isVisible,
  onClose,
}: SettingsModalProps) {
  const { t } = useTranslation();
  const { data: roomData } = useRoomById(roomId || "");
  const { data: roomArticles } = useArticlesByRoomId(roomId);
  const [showModalMessage, setShowModalMessage] = useState<string>("");
  const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
  const [roomIsUpdated, setRoomIsUpdated] = useState(false);
  const updateRoomMutation = useUpdateRoom(roomId);
  const [room, setRoom] = useState<Room>({
    created_at: "",
    updated_at: "",
    id: "",
    store_id: "",
    name: "",
    style_group: "",
    hf_ltp: "",
    pricing: "",
    active: "ACTIVE",
    nbr_articles: "",
    main_article_id_1: "",
    main_article_id_2: "",
    main_article_name_1: "",
    main_article_name_2: "",
  });
  const { data: rooms } = useRoomsByStoreId(room.store_id);

  useEffect(() => {
    if (roomData) {
      setRoom({ ...roomData });
    }
  }, [roomData]);

  const onSubmit = async () => {
    try {
      await updateRoomMutation.mutateAsync(room);
      onClose();
    } catch (error) {
      console.log(error);
      setShowModalMessage(t("couldNotUpdateRoomTryAgainString"));
    }
  };

  const onCloseWarning = () => {
    if (roomIsUpdated) {
      setShowConfirmCloseModal(true);
    } else {
      onClose();
    }
  };

  const saveRoomChanges = (saveRoom: Room) => {
    setRoomIsUpdated(true);
    setRoom(saveRoom);
  };

  if (!room) {
    return <></>;
  }

  return (
    <>
      <Modal visible={isVisible} handleCloseBtn={onCloseWarning}>
        <Sheets
          labelledById="edit-room-setting"
          header={<ModalHeader title={t("editRoomSettingsString")} />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                onClick={onSubmit}
                disabled={
                  !rooms ||
                  checkEmptyRoomName(room) ||
                  checkRoomName(room, rooms) ||
                  !roomIsUpdated
                }
              >
                Save details
              </Button>
            </ModalFooter>
          }
        >
          <PaddedHorizontalContainer>
            <ModalItemWrapper>
              <FormField
                shouldValidate={
                  !rooms ||
                  checkEmptyRoomName(room) ||
                  checkRoomName(room, rooms)
                }
                validation={{ msg: validationMessage(room, rooms ?? []) }}
                style={{ paddingRight: "12px" }}
              >
                <InputField
                  id={"media_name"}
                  type={"text"}
                  label={<LabelText text={t("mediaNameString")} withAsterisk />}
                  value={room.name}
                  ssrIcon={tag}
                  iconPosition={"leading"}
                  onChange={(event) => {
                    saveRoomChanges({
                      ...room,
                      name: event.target.value,
                    });
                  }}
                />
              </FormField>

              <LabelText text={t("photosString")} />
              <RoomImagesListItem room={room} />

              <Select
                id={"main_article_id_1"}
                label={`${t("mainProductBasicString")} #1`}
                value={room.main_article_id_1}
                ssrIcon={filledStar}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const articleId =
                    event.target.selectedIndex === 0 ? "" : event.target.value;
                  saveRoomChanges({
                    ...room,
                    main_article_id_1: articleId,
                    main_article_name_1: getArticleName(
                      roomArticles ?? [],
                      articleId
                    ),
                  });
                }}
              >
                {roomArticles
                  ?.filter(
                    (article) =>
                      ![
                        room.main_article_id_2,
                        room.backup_article_id_1,
                        room.backup_article_id_2,
                      ].includes(article.id)
                  )
                  .map((article) => (
                    <Option
                      key={article.id}
                      value={article.id}
                      name={getFullArticleName(roomArticles ?? [], article.id)}
                    />
                  ))}
              </Select>
              <FlexSpaceBetween>
                <LabelText text={"Active"} />
                <Switch
                  id="status"
                  checked={room.active === "ACTIVE"}
                  value={room.active || ""}
                  onChange={() => {
                    saveRoomChanges({
                      ...room,
                      active: room.active === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                    });
                  }}
                  disabled={!room.name}
                />
              </FlexSpaceBetween>
              {!room.name && (
                <WarningTextWithIcon text="To activate a room, it is required to provide a name" />
              )}
              <Select
                id={"backup_article_id_1"}
                label={`${t("backupForProductString")} #1`}
                value={room.backup_article_id_1 ?? ""}
                ssrIcon={star}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const articleId =
                    event.target.selectedIndex === 0 ? "" : event.target.value;
                  saveRoomChanges({
                    ...room,
                    backup_article_id_1: articleId,
                    backup_article_name_1: getArticleName(
                      roomArticles ?? [],
                      articleId
                    ),
                  });
                }}
              >
                {roomArticles
                  ?.filter(
                    (article) =>
                      ![
                        room.main_article_id_1,
                        room.main_article_id_2,
                        room.backup_article_id_2,
                      ].includes(article.id)
                  )
                  .map((article) => (
                    <Option
                      key={article.id}
                      value={article.id}
                      name={getFullArticleName(roomArticles ?? [], article.id)}
                    />
                  ))}
              </Select>
              <Select
                id={"main_article_id_2"}
                label={`${t("mainProductBasicString")} #2`}
                ssrIcon={filledStar}
                value={room.main_article_id_2}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const articleId =
                    event.target.selectedIndex === 0 ? "" : event.target.value;
                  saveRoomChanges({
                    ...room,
                    main_article_id_2: articleId,
                    main_article_name_2: getArticleName(
                      roomArticles ?? [],
                      articleId
                    ),
                  });
                }}
              >
                {roomArticles
                  ?.filter(
                    (article) =>
                      ![
                        room.main_article_id_1,
                        room.backup_article_id_1,
                        room.backup_article_id_2,
                      ].includes(article.id)
                  )
                  .map((article) => (
                    <Option
                      key={article.id}
                      value={article.id}
                      name={getFullArticleName(roomArticles ?? [], article.id)}
                    />
                  ))}
              </Select>
              <Select
                id={"backup_article_id_2"}
                label={`${t("backupForProductString")} #2`}
                value={room.backup_article_id_2 ?? ""}
                ssrIcon={star}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const articleId =
                    event.target.selectedIndex === 0 ? "" : event.target.value;
                  saveRoomChanges({
                    ...room,
                    backup_article_id_2: articleId,
                    backup_article_name_2: getArticleName(
                      roomArticles ?? [],
                      articleId
                    ),
                  });
                }}
              >
                {roomArticles
                  ?.filter(
                    (article) =>
                      ![
                        room.main_article_id_1,
                        room.backup_article_id_1,
                        room.main_article_id_2,
                      ].includes(article.id)
                  )
                  .map((article) => (
                    <Option
                      key={article.id}
                      value={article.id}
                      name={getFullArticleName(roomArticles ?? [], article.id)}
                    />
                  ))}
              </Select>
              <TextArea
                id={"media_story"}
                label={t("mediaStoryString")}
                style={{ height: "100px" }}
                value={room.media_story ?? ""}
                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                  saveRoomChanges({
                    ...room,
                    media_story: event.target.value,
                  });
                }}
              />
              <Select
                id={"style_group"}
                label={t("styleGroupString")}
                value={room.style_group}
                ssrIcon={paintbrush}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  saveRoomChanges({
                    ...room,
                    style_group:
                      event.target.selectedIndex === 0
                        ? ""
                        : event.target.value,
                  });
                }}
              >
                {getStyleGroupOptions().map((styleGroup) => (
                  <Option {...styleGroup} key={styleGroup.value} />
                ))}
              </Select>
              <Select
                id={"living_situation"}
                label={t("livingSituationString")}
                ssrIcon={family}
                value={room.living_situation ?? ""}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  saveRoomChanges({
                    ...room,
                    living_situation:
                      event.target.selectedIndex === 0
                        ? ""
                        : event.target.value,
                  });
                }}
              >
                {getLivingSituationOptions().map((livingSituation) => (
                  <Option {...livingSituation} key={livingSituation.value} />
                ))}
              </Select>
              <Select
                id={"hf_ltp"}
                label={t("longtermPriorityString")}
                value={room.hf_ltp}
                ssrIcon={flag}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  saveRoomChanges({
                    ...room,
                    hf_ltp:
                      event.target.selectedIndex === 0
                        ? ""
                        : event.target.value,
                  });
                }}
              >
                {getLongTermPriorityOptions().map((hfltp) => (
                  <Option {...hfltp} key={hfltp.value} />
                ))}
              </Select>
              <Select
                id={"pricing"}
                label={t("priceLevelString")}
                value={room.pricing}
                ssrIcon={stairs}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  saveRoomChanges({
                    ...room,
                    pricing:
                      event.target.selectedIndex === 0
                        ? ""
                        : event.target.value,
                  });
                }}
              >
                {getPricingDataOptions().map((pricing) => (
                  <Option {...pricing} key={pricing.value} />
                ))}
              </Select>
              <FormField style={{ paddingRight: "12px" }}>
                <InputField
                  id={"life_at_home_activity"}
                  type={"text"}
                  label={t("lifeHomeActivityString")}
                  value={room.laha ?? ""}
                  ssrIcon={bike}
                  iconPosition={"leading"}
                  onChange={(event) => {
                    saveRoomChanges({
                      ...room,
                      laha: event.target.value,
                    });
                  }}
                  style={{ paddingRight: "12px" }}
                />
              </FormField>
              <FormField style={{ paddingRight: "12px" }}>
                <InputField
                  id={"living_conditions"}
                  type={"text"}
                  label={t("livingConditionsString")}
                  value={room.living_conditions ?? ""}
                  ssrIcon={home}
                  iconPosition={"leading"}
                  onChange={(event) => {
                    saveRoomChanges({
                      ...room,
                      living_conditions: event.target.value,
                    });
                  }}
                />
              </FormField>
              <Select
                id={"hfb"}
                label={t("hfbLocationInStoreString")}
                value={room.hfb ?? ""}
                ssrIcon={locationPinSmall}
                onChange={(event) => {
                  saveRoomChanges({
                    ...room,
                    hfb: event.target.value,
                  });
                }}
              >
                {getDepartmentsOptions(t).map((department) => (
                  <Option {...department} key={department.value} />
                ))}
              </Select>
            </ModalItemWrapper>
          </PaddedHorizontalContainer>
        </Sheets>
      </Modal>
      {!!showModalMessage && (
        <MessageModal
          message={showModalMessage}
          closeModal={() => {
            setShowModalMessage("");
          }}
        />
      )}
      {showConfirmCloseModal && (
        <OkCancelModal
          onOk={onClose}
          onCancel={() => {
            setShowConfirmCloseModal(false);
          }}
          title={"confirmCloseString"}
          text={"allChangesLostCloseString"}
        />
      )}
    </>
  );
};
