import { getRoomById, updateRoom } from "../services/rooms.service";

export async function updateMainAndBackupArticles(
  roomId: string,
  articleIds: string[]
) {
  const room = await getRoomById(roomId);

  if (room) {
    const updatedRoom = { ...room };
    for (const articleId of articleIds) {
      if (articleId === room.main_article_id_1) {
        updatedRoom.main_article_id_1 = "";
        updatedRoom.main_article_name_1 = "";
      }
      if (articleId === room.main_article_id_2) {
        updatedRoom.main_article_id_2 = "";
        updatedRoom.main_article_name_2 = "";
      }
      if (articleId === room.backup_article_id_1) {
        updatedRoom.backup_article_id_1 = "";
        updatedRoom.backup_article_name_1 = "";
      }
      if (articleId === room.backup_article_id_2) {
        updatedRoom.backup_article_id_2 = "";
        updatedRoom.backup_article_name_2 = "";
      }
    }

    const roomMainAndBackupArticles = [
      room.main_article_id_1,
      room.main_article_id_2,
      room.backup_article_id_1,
      room.backup_article_id_2,
    ];
    const hasChanged = roomMainAndBackupArticles.some(
      (articleId) => articleId && articleIds.includes(articleId)
    );
    if (hasChanged) await updateRoom(room.id, updatedRoom);
  }
}
