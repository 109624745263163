import { TaskListingTypes } from "../constants";
import { OngoingTaskLinksResponse } from "../types/taskLink";
import { useTaskLinksByStore } from "./useTaskLinksByStore";
import { useTaskLinks } from "./useTasksByTaskLinks";

export function useTasksByStore(
  storeId: string | undefined
): OngoingTaskLinksResponse {
  const { data: taskLinks } = useTaskLinksByStore(storeId || "");
  const tasksResponse = useTaskLinks(taskLinks || [], TaskListingTypes.Ongoing);
  return tasksResponse;
}
