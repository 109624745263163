// Vendor
import React from "react";
import { Trans } from "@coworker/locales";
import taskTypeOptions from "@coworker/functions/src/enums/taskType.json";
import {
  ASSIGN,
  PHOTOS,
  PRODUCT,
  DATE_STAMP,
  DESCRIPTION,
  AMOUNT,
  CUSTOM_TITLE,
  RADIOBUTTONS,
  SAFETY_ALARM_INFO,
  REPEATABLE,
  SCHEDULE,
  SUPPLIER_NUMBER,
  BOOLEAN_FLAG,
  OPTIONAL_LOCATION,
  MFAQ_AREA,
  MFAQ_QUESTION,
  MFAQ_GRAPH_CARD,
  DESCRIBE_THE_PROBLEM,
} from "../widgets";
import { ReactComponent as PriorityIcon } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { splitLocation } from "../../../services/locations.service";
import { recurringTaskOptions } from "@coworker/functions/src/enums/recurringTaskOptions";
import { defaultTimezone } from "../../../constants/clientTime";
import { pqHowOptions } from "@coworker/functions/src/enums/pqHowOptions";

export const ASSIGN_WIDGET = {
  type: ASSIGN,
  conf: {},
  values: {
    in: ({ assigned_team_id: gid, assigned_user_id: uid, refilled_by_me }) => ({
      gid,
      uid,
      refilled_by_me,
    }),
    out: ({ gid: assigned_team_id, uid: assigned_user_id }) => ({
      assigned_team_id,
      assigned_user_id,
    }),
  },
};

export const DATE_STAMP_WIDGET = {
  type: DATE_STAMP,
  conf: {},
  values: {
    in: ({ pq_date_stamp }) => pq_date_stamp,
    out: (pq_date_stamp) => ({ pq_date_stamp }),
  },
};

export const SUPPLIER_NUMBER_WIDGET = {
  type: SUPPLIER_NUMBER,
  conf: {},
  values: {
    in: ({ supplier_number, supplier_number_unknown }) => ({
      supplier_number,
      supplier_number_unknown,
    }),
    out: ({ supplier_number, supplier_number_unknown }) => ({
      supplier_number,
      supplier_number_unknown,
    }),
  },
};

export const OPTIONAL_LOCATION_WIDGET = {
  type: OPTIONAL_LOCATION,
  conf: {},
  values: {
    in: ({ location_custom }) => location_custom,
    out: (location_custom) => ({
      location_custom,
    }),
  },
};

export const MFAQ_AREA_WIDGET = {
  type: MFAQ_AREA,
};
export const MFAQ_GRAPH_CARD_WIDGET = {
  type: MFAQ_GRAPH_CARD,
};
export const RADIOBUTTONS_WIDGET = {
  type: RADIOBUTTONS,
  values: {
    in: ({ pq_discovered_type, pq_discovered_other_text }) => [
      pq_discovered_type,
      pq_discovered_other_text,
    ],
    out: ([type, text]) => ({
      pq_discovered_type: type,
      pq_discovered_other_text: type === pqHowOptions.OTHER ? text : "",
    }),
  },
};

export const SAFETY_ALARM_INFO_WIDGET = {
  type: SAFETY_ALARM_INFO,
};

export const CUSTOM_TITLE_WIDGET = {
  type: CUSTOM_TITLE,
  values: {
    in: ({ custom_title }) => custom_title,
    out: (custom_title) => ({ custom_title }),
  },
};

export const REPEATABLE_WIDGET = {
  type: REPEATABLE,
  conf: {},
  hide: (taskId, data) => data.task_type === taskTypeOptions.CUSTOM && taskId,
  values: {
    in: ({ repeatable }) => repeatable,
    out: (repeatable) => ({ repeatable }),
  },
};

export const SCHEDULE_WIDGET = {
  type: SCHEDULE,
  conf: {},
  hide: (taskId, data) => data.task_type === taskTypeOptions.CUSTOM && taskId,
  values: {
    in: ({
      frequency = "none", // TODO: move this default
      planned: {
        days_of_week,
        scheduled_start,
        scheduled_end,
        every_n_weeks,
        recurring,
        timezone = defaultTimezone,
      } = {},
    }) => ({
      frequency,
      days_of_week,
      scheduled_start,
      scheduled_end,
      every_n_weeks,
      recurring,
      timezone,
    }),
    out: ({
      frequency,
      days_of_week,
      scheduled_start,
      scheduled_end,
      every_n_weeks,
      recurring,
      timezone = defaultTimezone,
    }) => {
      if (frequency === recurringTaskOptions.PLANNED) {
        if (recurring) {
          return {
            frequency,
            planned: {
              timezone,
              days_of_week,
              scheduled_start,
              scheduled_end,
              every_n_weeks,
              recurring,
            },
          };
        } else {
          return {
            frequency,
            planned: {
              timezone,
              scheduled_start,
              recurring,
            },
          };
        }
      } else {
        return { frequency };
      }
    },
  },
};

export const PRIORITY_WIDGET = {
  type: BOOLEAN_FLAG,
  conf: {
    title: <Trans>priorityString</Trans>,
    icon: <PriorityIcon />,
    testId: "priorityCheckbox",
  },
  values: {
    in: ({ priority_flag }) => priority_flag,
    out: (priority_flag) => ({ priority_flag }),
  },
};

export const DESCRIPTION_WIDGET = (
  optional,
  field = "description",
  descriptionTitleTranslationKey,
  icon,
  lblColor
) => ({
  type: DESCRIPTION,
  conf: { optional, descriptionTitleTranslationKey, icon, lblColor },
  values: {
    in: (input) => input[field],
    out: (output) => ({ [field]: output }),
  },
});

export const PHOTOS_WIDGET = {
  type: PHOTOS,
  conf: {},
  values: {
    in: ({ images }) => images,
    out: (images) => ({ images }),
  },
};

export const AMOUNT_WIDGET = {
  type: AMOUNT,
  conf: {},
  values: {
    in: ({
      requested_quantity: quantity,
      actual_requested_quantity: actualQuantity,
      requested_type: type,
      product_article_id: productId,
      product_article_type: articleType,
      location,
      pieces_per_multi_pack: piecesPerMultiPack,
      pieces_per_pallet: piecesPerPallet,
    }) => ({
      quantity,
      actualQuantity,
      type,
      productId,
      location: splitLocation(location)[0],
      articleType,
      piecesPerMultiPack,
      piecesPerPallet,
    }),
    out: ({
      amount: requested_quantity,
      actualAmount: actual_requested_quantity,
      amountType: requested_type,
    }) => ({
      requested_quantity,
      actual_requested_quantity,
      requested_type,
    }),
  },
};

export const MFAQ_QUESTION_WIDGET = {
  type: MFAQ_QUESTION,
};

export const DESCRIBE_THE_PROBLEM_WIDGET = {
  type: DESCRIBE_THE_PROBLEM,
  conf: {},
  values: {
    in: ({
      pq_describe_the_problem_type,
      description,
      pq_describe_the_problem_tags,
    }) => ({
      pq_describe_the_problem_type,
      description,
      pq_describe_the_problem_tags,
    }),
    out: ({
      pq_describe_the_problem_type,
      description,
      pq_describe_the_problem_tags,
    }) => ({
      pq_describe_the_problem_type,
      description,
      pq_describe_the_problem_tags,
    }),
  },
};

export const getProductWidget = (conf = {}, type = PRODUCT) => ({
  type,
  conf,
  disabled: conf.disabled,
  values: {
    in: ({
      product_article_id: productId,
      product_article_type: productType,
    }) => {
      return { productId, productType };
    },
    out: (
      {
        productId: product_article_id,
        articleType: product_article_type,
        name: item_name,
        type: item_type,
        color: item_color,
        item,
        panumber,
      },
      task
    ) => ({
      product_article_id,
      product_article_type,
      item_name,
      item_type,
      item_color,
      item,
      panumber:
        panumber ||
        (product_article_id === task?.product_article_id && task?.panumber) ||
        "",
    }),
  },
});
