import { Trans } from "@coworker/locales";
import { ReactComponent as Incorrect } from "../../../assets/svg/incorrect.svg";
import {
  closeTypeKeys,
  closeTypeOptions,
} from "@coworker/functions/src/enums/closeTypes";
import { ReactComponent as AlreadyResolved } from "../../../assets/svg/test-buy.svg";
import { ReactComponent as NotInStock } from "../../../assets/svg/not-in-stock.svg";
import { ReactComponent as IssueOther } from "@coworker/reusable/svg/three-dots.svg";
import React from "react";
import { useInputPopup } from "../index";

const useCloseTaskReasonPopupMenu = () => {
  const { getInput } = useInputPopup();

  function getCloseTaskPopup() {
    return getInput("taskAction", {
      customTitle: <Trans>whyRUClosingString</Trans>,
      content: [
        {
          icon: Incorrect,
          name: <Trans>{closeTypeKeys.INCORRECT}</Trans>,
          type: closeTypeOptions.INCORRECT,
        },
        {
          icon: AlreadyResolved,
          name: <Trans>{closeTypeKeys.ALREADY_RESOLVED}</Trans>,
          type: closeTypeOptions.ALREADY_RESOLVED,
        },
        {
          icon: NotInStock,
          name: <Trans>{closeTypeKeys.NOT_IN_STOCK}</Trans>,
          type: closeTypeOptions.NOT_IN_STOCK,
        },
        {
          icon: IssueOther,
          name: <Trans>{closeTypeKeys.OTHER}</Trans>,
          type: closeTypeOptions.OTHER,
          descriptionRequired: true,
        },
      ],
    });
  }

  return { getCloseTaskPopup };
};

export default useCloseTaskReasonPopupMenu;
