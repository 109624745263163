import { useQuery } from "@tanstack/react-query";
import { useStoreId } from "../../../core/auth/useLoggedInUser";
import { fetchChartData } from "../ShoppingToolsAPI";
import { INTERVAL } from "../../../constants/clientTime";

export const useFetchChartData = (
  startDate: number | undefined,
  endDate: number | undefined
) => {
  const storeId = useStoreId();

  return useQuery({
    queryKey: ["shopping-tools-chart-data", storeId, startDate, endDate],
    queryFn: () => {
      return fetchChartData(storeId, startDate ?? 0, endDate ?? 0);
    },
    staleTime: INTERVAL.MINUTE * 1,
  });
};
