import React from "react";
import {
  LeftContainer,
  ListItemContainer,
  RightContainer,
  Row,
} from "../styles/styles";
import { Text } from "../Atoms/Text";
import { Trans, useTranslation } from "@coworker/locales";
import Checkbox from "@ingka/checkbox";
import { getArticlesCountText } from "../tools";
import { Articles } from "../types/article";

interface SelectArticlesProps {
  selectedCount: number;
  piecesCount: number;
  searchCount: number;
  isSelected: boolean;
  onSelectAll: (selectAllChecked: boolean) => void;
  allArticlesInRoom: Articles;
}

export function SelectArticles({
  selectedCount,
  piecesCount,
  searchCount,
  isSelected,
  onSelectAll,
  allArticlesInRoom,
}: SelectArticlesProps) {
  const { t } = useTranslation();
  const [selectAllChecked, setSelectAllChecked] = React.useState(false);

  const isFilter = allArticlesInRoom.length !== searchCount;
  const articlesCountText = getArticlesCountText(
    t,
    isSelected,
    allArticlesInRoom.length !== searchCount,
    selectedCount,
    isFilter ? allArticlesInRoom.length : searchCount,
    searchCount,
    piecesCount
  );

  return (
    <ListItemContainer>
      <LeftContainer>
        <Text text={articlesCountText}></Text>
      </LeftContainer>
      <RightContainer>
        {isSelected && (
          <Row>
            <Trans>selectAllString</Trans>
            &nbsp;&nbsp;
            <Checkbox
              id={"select-all-checkbox"}
              value="selectAll"
              checked={selectAllChecked}
              onChange={() => {
                const newSelectAllChecked = !selectAllChecked;
                setSelectAllChecked(newSelectAllChecked);
                onSelectAll(newSelectAllChecked);
              }}
            />
          </Row>
        )}
      </RightContainer>
    </ListItemContainer>
  );
}
