import { useTranslation } from "@coworker/locales";
import React from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../../assets/svg/icon-search.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--grey500);
`;

const Input = styled.input`
  font-size: 16px;
  height: 40px;
  margin-left: -10px;
`;

export function SearchInput({
  value,
  setValue,
}: {
  value: string | undefined;
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SearchIcon />
      <Input
        data-testid="search-input"
        id="areaInput"
        placeholder={t("searchBasicString")}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Wrapper>
  );
}
