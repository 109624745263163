import { TypeOfArticle } from "@coworker/types";
import { AMOUNTS, ARTICLE_HASH, DEFAULT_AMOUNT } from "../constants";

type Props = {
  loading: boolean;
  newAmountOfArticles: number;
  numberOfArticlesForArea: number;
  setNumberOfArticles: React.Dispatch<React.SetStateAction<number>>;
  typeOfArticles: TypeOfArticle;
};

export function countNumbersOfArticles({
  loading,
  newAmountOfArticles,
  numberOfArticlesForArea,
  setNumberOfArticles,
  typeOfArticles,
}: Props) {
  if (!loading) {
    if (
      newAmountOfArticles > 0 &&
      numberOfArticlesForArea !== newAmountOfArticles
    ) {
      setNumberOfArticles(newAmountOfArticles);
    } else if (typeOfArticles?.type === ARTICLE_HASH.CUSTOM?.type) {
      setNumberOfArticles(0);
    } else if (
      !AMOUNTS.includes(numberOfArticlesForArea) &&
      typeOfArticles?.type !== ARTICLE_HASH.CUSTOM?.type
    ) {
      setNumberOfArticles(DEFAULT_AMOUNT);
    } else {
      setNumberOfArticles(numberOfArticlesForArea);
    }
  }
}
