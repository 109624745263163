import React from "react";
import ListView, { ListViewItem } from "@ingka/list-view";
import "./team-members-list-view.css";
import { displayRoleTranslationString } from "@coworker/common/helpers/user.helper";
import { useTranslation } from "react-i18next";
interface TeamMembersListViewProps {
  id: string;
  teamMembers: any;
  isOpen: boolean;
}

export const TeamMembersListView: React.FC<TeamMembersListViewProps> = ({
  id,
  teamMembers,
  isOpen,
}) => {
  const { t } = useTranslation();
  return isOpen ? (
    <ListView id={id} size="medium">
      {teamMembers?.map((teamMember: any) => {
        return (
          <ListViewItem
            className="teams-members-list-view-item"
            key={teamMember.fixa_uid}
            id={teamMember.fixa_uid}
            controlIcon={null}
            inset
            title={teamMember.name}
            addOn={t(displayRoleTranslationString(teamMember.role_id))}
          />
        );
      })}
    </ListView>
  ) : null;
};
