import React from "react";
import { useToastNotification } from "@coworker/components";
import useUndoneNotification from "../../components/useUndoneNotification";
import { updateTask } from "../../services/task.service";
import { useInputPopup } from "../../components/InputPopup";
import activityTypes from "@coworker/functions/src/enums/activityTypes";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import trackerHelper from "../../helpers/tracker";
import { Trans } from "@coworker/locales";
import { useStoreId, useUserId } from "../../core/auth/useLoggedInUser";
import { useTaskRefresher } from "../useWatchedTasks";

export const useUndoPickTask = ({ task, afterUndo }) => {
  const undoCurtain = useUndoneNotification();
  const uid = useUserId();
  const refreshTask = useTaskRefresher();

  const undoTask = React.useCallback(
    async (actionId) => {
      const data = {
        last_editor_id: uid,
        assigned_user_id: uid,
        state: taskStateOptions.ASSIGNED,
        undo_action_id: actionId || "",
        pick_quantity: null,
        actual_pick_quantity: null,
        pick_type: "",
        picked: false,
        task_type_detail: "PICK_AND_REFILL",
        searchable_location:
          task.pickup_location_custom ?? task.pickup_location,
      };

      await undoCurtain(data, task.id, task.task_type);
      refreshTask(task.id, data, task);
      afterUndo();
    },
    [afterUndo, task, uid, undoCurtain, refreshTask]
  );

  return {
    call: undoTask,
  };
};

export const usePickTask = (task, taskId, config = {}) => {
  const { undo, afterUndo = () => {} } = config;
  const storeId = useStoreId();
  const uid = useUserId();
  const { showToastWithUndo } = useToastNotification();
  const { getInput } = useInputPopup();
  const { call: undoTask } = useUndoPickTask({
    task: { ...task, id: taskId },
    afterUndo,
  });
  const refreshTask = useTaskRefresher();

  const pickTask = React.useCallback(async () => {
    const actionId = `picked-${new Date().getTime()}`;
    const handoff = await getInput("handoff", {});
    if (!handoff) return console.error("pickTask: handoff missing");
    let activityType = activityTypes.PICKED;

    if (
      handoff.gid !== task.assigned_team_id ||
      (!handoff.uid && handoff.gid === task.assigned_team_id)
    ) {
      activityType = activityTypes.PICKED_AND_REASSIGNED_TEAM;
    } else if (handoff.uid && handoff?.uid !== task.assigned_user_id) {
      activityType = activityTypes.PICKED_AND_REASSIGNED_USER;
    }

    const data = {
      last_editor_id: uid,
      assigned_user_id: handoff.uid || "",
      assigned_team_id: handoff.gid,
      state:
        handoff.uid === "" || handoff.uid === undefined
          ? taskStateOptions.UNASSIGNED
          : taskStateOptions.ASSIGNED,
      action_id: actionId,
      activity_type: activityType,
      // The onTaskUpdate trigger also deletes undo_action_id, but in the case that this backend change has not propagated to the frontend we must make sure to also signal this from the frontend!
      undo_action_id: "",
      picked: true,
      pick_quantity: task.requested_quantity,
      actual_pick_quantity: task.actual_requested_quantity,
      pick_type: task.requested_type,
      task_type_detail: "REFILL",
      searchable_location: task.location,
    };

    await updateTask(data, taskId, "", storeId);
    refreshTask(taskId, data, task);

    trackerHelper.trackPickAddonTask(
      { ...task, task_id: taskId, pick_quantity: task.requested_quantity },
      false
    );

    if (
      [
        activityTypes.PICKED_AND_REASSIGNED_TEAM,
        activityTypes.PICKED_AND_REASSIGNED_USER,
      ].includes(activityType)
    ) {
      trackerHelper.trackHandoffTask(taskId, handoff.uid, handoff.gid);
    }

    if (!undo) return;

    showToastWithUndo(
      handoff.uid === uid ? (
        <Trans>pickingNotificationString</Trans>
      ) : (
        <Trans>pickingNotification2String</Trans>
      ),
      () => undoTask(actionId)
    );

    return true;
  }, [
    undoTask,
    refreshTask,
    undo,
    getInput,
    uid,
    showToastWithUndo,
    task,
    taskId,
    storeId,
  ]);

  return {
    call: pickTask,
  };
};
