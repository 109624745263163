import { isRDTDevice11, RDT_HIDDEN_INPUT_ID } from "@coworker/reusable";
import React from "react";
import styled from "styled-components";

const HiddenInputWrapper = styled.div`
  width: 0;
  overflow: hidden;
`;

export function HiddenInput({
  setScannerText,
}: {
  setScannerText: React.Dispatch<React.SetStateAction<string>>;
}) {
  const isNewRDT = isRDTDevice11();

  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    setTimeout(() => {
      if (isNewRDT && divRef.current) {
        divRef.current.focus();
      }
    }, 0);
  }, [isNewRDT]);

  const htmlCustomAttrs = { virtualkeyboardpolicy: "manual" };

  return isNewRDT ? (
    <HiddenInputWrapper>
      <div
        ref={divRef}
        id={RDT_HIDDEN_INPUT_ID}
        inputMode="text"
        contentEditable
        onFocus={(e) => {
          e.target.innerHTML = "";
        }}
        onInput={(e) => {
          const target = e.target as HTMLDivElement;
          if (target.innerText.trim().length === 13) {
            setScannerText(target.innerText.trim());
            target.blur();
          }
        }}
        {...htmlCustomAttrs}
      />
    </HiddenInputWrapper>
  ) : null;
}
