const SIK_CLIENT_NAME = "fixa";
const SIK_EUROPE = "https://sik.search.blue.cdtapps.com";
const SIK_CHINA = "https://search.ikea.cn";
const SIK_BASE_URL = (isChina) => (isChina ? SIK_CHINA : SIK_EUROPE);

// Release:
// https://release-log-viewer-dot-ikea-search-eu-1-prod.ey.r.appspot.com/47ba72e518775a2ae0997559fae546b99309953510f911ea06020ece06a72b72
// https://confluence.build.ingka.ikea.com/pages/viewpage.action?spaceKey=GP&title=SIK+API+versions
const SIK_API_VERSION = "20240110";

module.exports = {
  SIK_EUROPE,
  SIK_CHINA,
  SIK_API_VERSION,
  SIK_CLIENT_NAME,
  SIK_BASE_URL,
};
