import React, { useState } from "react";
import styled from "styled-components";
import Search from "@ingka/search";
import ListView, { ListViewItem } from "@ingka/list-view";
import { Header } from "@coworker/components";
import { useTranslation } from "@coworker/locales";
import { departments } from "./UserFiltersRow/FilterChoiceData";
import {
  InputPopupContainer,
  InputPopupContentBox,
} from "../RoomSettings/styles/styles";
import { StickyButtonMenu } from "../RoomSettings/Molecules/StickyButtonMenu";

const SearchBarContainer = styled.div`
  .search__clear {
    position: absolute;
  }
`;

interface HFBLocationsProps {
  localHFB: string;
  onSubmit: (data: string) => void;
}

export const HFBLocations = ({ localHFB, onSubmit }: HFBLocationsProps) => {
  const { t } = useTranslation();
  const [selectedHFB, setSelectedHFB] = useState<string>(localHFB);
  const [searchInput, setSearchInput] = useState<string>("");
  const [HFBs, setHFBs] = useState(departments);

  const handleSearch = (e: InputEvent) => {
    const searchInput = (e.target as HTMLInputElement).value;
    setSearchInput((e.target as HTMLInputElement).value);

    setHFBs(
      departments.filter(
        (department) =>
          department.hfbNumber.includes(searchInput) ||
          department.shortName.toLowerCase().includes(searchInput.toLowerCase())
      )
    );
  };

  const handleClear = () => {
    setSearchInput("");
    setHFBs(departments);
  };

  const handleReset = () => {
    setSelectedHFB("all");
    onSubmit("all");
  };

  const handleDone = () => {
    onSubmit(selectedHFB);
  };

  return (
    <>
      <Header
        title={t("selectHFBString")}
        onBack={handleDone}
        showBack
        border
      />

      <InputPopupContainer>
        <SearchBarContainer>
          <Search
            id="hfb-search"
            value={searchInput}
            onChange={(e: InputEvent) => handleSearch(e)}
            onClear={handleClear}
            placeholder={t("searchBasicString")}
          />
        </SearchBarContainer>

        {HFBs && (
          <InputPopupContentBox>
            <ListView id={"all-hfbs"} size={"small"} showDivider={false}>
              <ListViewItem
                onChange={() => setSelectedHFB("all")}
                title={t("wholeStoreString").toUpperCase()}
                control={"radiobutton"}
                controlProps={{
                  value: "all",
                  checked: selectedHFB === "all",
                }}
              />
            </ListView>
            {HFBs.map(({ hfbNumber, name }) => (
              <ListView
                id={hfbNumber}
                key={hfbNumber}
                size={"small"}
                showDivider={false}
              >
                <ListViewItem
                  onChange={() => setSelectedHFB(hfbNumber)}
                  title={name}
                  control={"radiobutton"}
                  controlProps={{
                    value: hfbNumber,
                    checked: selectedHFB === hfbNumber,
                  }}
                />
              </ListView>
            ))}
          </InputPopupContentBox>
        )}

        <StickyButtonMenu onReset={handleReset} onDone={handleDone} />
      </InputPopupContainer>
    </>
  );
};
