import React from "react";
import { WidgetRow } from "../PaddedRow";
import styled from "styled-components";
import { useInputPopup } from "../../../components/InputPopup";

const MiddleWrapper = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

interface PillSelectionProps<ChoiceT> {
  title: React.ReactNode;
  choices: ChoiceT[];
  selected?: ChoiceT | undefined;
  onConfirm: (value: ChoiceT) => void;
  onClose?: () => void;
  image?: string | React.ReactNode;
  testId?: string;
  closePopupWhenSelected: boolean;
}

export function PillSelection<ChoiceT>({
  title,
  choices,
  selected,
  onConfirm,
  onClose,
  image,
  testId,
  closePopupWhenSelected,
}: PillSelectionProps<ChoiceT>) {
  const { getInput } = useInputPopup();
  const selectValue = async () => {
    await getInput("pickFromSelection", {
      title,
      choices,
      selected,
      onConfirm,
      onClose,
      closePopupWhenSelected,
    });
  };

  return (
    <WidgetRow
      className="WidgetView"
      image={image}
      middle={
        <MiddleWrapper>
          <b>{selected}</b>
          <p>{title}</p>
        </MiddleWrapper>
      }
      right={<></>}
      onClick={selectValue}
      testId={testId || "pill-choices-widget"}
    ></WidgetRow>
  );
}
