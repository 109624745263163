import React from "react";
import styled from "styled-components";
import { Trans } from "@coworker/locales";
import { closedReasonFilterTypes } from "@coworker/types/lib/closedReasonFilterTypes";
import { FilterItem } from "./FilterItem";

const Title = styled.div`
  margin: 10px 29px;
  font-weight: bold;
  font-size: 18px;
`;

export const closedReasonFilterItems = [
  {
    type: closedReasonFilterTypes.ALL_CLOSED_REASONS,
    translation: "allClosedReasonsString",
  },
  {
    type: closedReasonFilterTypes.NOT_IN_STOCK,
    translation: "notInStockString",
  },
  {
    type: closedReasonFilterTypes.INCORRECT,
    translation: "closeIncorrectString",
  },
  {
    type: closedReasonFilterTypes.ALREADY_RESOLVED,
    translation: "closeAlreadyResolvedString",
  },
  {
    type: closedReasonFilterTypes.OTHER,
    translation: "otherString",
  },
];

interface ClosedReasonFilterProps {
  filterSetter: (filterType: string) => void;
  selectedFilter: string;
}

export function ClosedReasonFilter({
  filterSetter,
  selectedFilter,
}: ClosedReasonFilterProps) {
  return (
    <React.Fragment>
      <Title>
        <Trans>closedReasonString</Trans>
      </Title>
      {closedReasonFilterItems.map((item) => (
        <FilterItem
          key={item.type}
          content={<Trans>{item.translation}</Trans>}
          filterType={item.type}
          filterSetter={filterSetter}
          checked={selectedFilter?.includes(item.type)}
        />
      ))}
    </React.Fragment>
  );
}
