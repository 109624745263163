interface TemporaryCurrencyProps {
  countryId: string;
  currency: string;
}

export const temporaryCurrency: TemporaryCurrencyProps[] = [
  // Austria
  {
    countryId: "AT",
    currency: "EUR",
  },
  // Australia
  {
    countryId: "AU",
    currency: "AUD",
  },
  // Belgium
  {
    countryId: "BE",
    currency: "EUR",
  },
  // Canada
  {
    countryId: "CA",
    currency: "CAD",
  },
  // Switzerland
  {
    countryId: "CH",
    currency: "CHF",
  },
  // Czech Republic
  {
    countryId: "CZ",
    currency: "CZK",
  },
  // Germany
  {
    countryId: "DE",
    currency: "EUR",
  },
  // Denmark
  {
    countryId: "DK",
    currency: "DKK",
  },
  // Spain
  {
    countryId: "ES",
    currency: "EUR",
  },
  // Finland
  {
    countryId: "FI",
    currency: "EUR",
  },
  // France
  {
    countryId: "FR",
    currency: "EUR",
  },
  // United Kingdom
  {
    countryId: "GB",
    currency: "GBP",
  },
  // Croatia
  {
    countryId: "HR",
    currency: "HRK",
  },
  // Hungary
  {
    countryId: "HU",
    currency: "HUF",
  },
  // Ireland
  {
    countryId: "IE",
    currency: "EUR",
  },
  // India
  {
    countryId: "IN",
    currency: "INR",
  },
  // Italy
  {
    countryId: "IT",
    currency: "EUR",
  },
  // Japan
  {
    countryId: "JP",
    currency: "JPY",
  },
  // South Korea
  {
    countryId: "KR",
    currency: "KRW",
  },
  // Netherlands
  {
    countryId: "NL",
    currency: "EUR",
  },
  // Norway
  {
    countryId: "NO",
    currency: "NOK",
  },
  // Poland
  {
    countryId: "PL",
    currency: "PLN",
  },
  // Portugal
  {
    countryId: "PT",
    currency: "EUR",
  },
  // Romania
  {
    countryId: "RO",
    currency: "RON",
  },
  // Serbia
  {
    countryId: "RS",
    currency: "RSD",
  },
  // Sweden
  {
    countryId: "SE",
    currency: "SEK",
  },
  // Slovenia
  {
    countryId: "SI",
    currency: "EUR",
  },
  // Slovakia
  {
    countryId: "SK",
    currency: "EUR",
  },
  // United States
  {
    countryId: "US",
    currency: "USD",
  },
  // China
  {
    countryId: "CN",
    currency: "CNY",
  },
];
