import React from "react";
import styled from "styled-components";
import AggregatedVotesGraph from "../AggregatedVotesGraph";
import { useTranslation, Trans } from "@coworker/locales";
import {
  describeAreaFilter,
  getAreaFilter,
  getPeriodFilter,
  getPeriodTransKey,
  periodIsCustom,
  WHOLE_STORE_FILTER,
} from "../../InputPopup/UserFiltersRow/filterChoices";
import { usePeriodTimestamps } from "../../InputPopup/UserFiltersRow/usePeriodTimestamps";
import { BasicWidget } from "@coworker/components";
import { MFAQVotesSkeleton } from "@coworker/components/src/components/ItemSkeleton";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useFeatureFlagHooks } from "../useMFAQs.featureToggle";
import useFormatter from "../../../hooks/useFormatter";

const CardWrapper = styled.div`
  width: 100%;
  background-color: var(--grey100);
  display: flex;
  flex-direction: column;

  p {
    background-color: white;
    text-align: center;
    line-height: 4em;
  }
`;

const Card = styled.div`
  margin: 9px;
  background-color: var(--white);
  width: calc(100% - 18px);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled(BasicWidget)`
  flex-grow: 1;
  width: 100%;
`;

const GraphSummary = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px;

  & > strong {
    font-size: 36px;
    line-height: 36px;
    margin-top: 5px;
  }

  & > span {
    font-size: 12px;
  }
`;

const VotesGraph = styled(AggregatedVotesGraph)`
  flex-grow: 1;
  width: 100%;
`;

export default function VotesGraphCard({
  question_id,
  votesFudgeFactor,
  totalUpvotes,
  filters,
  isWholeStore,
  displayTasksOnGraph = false,
  tasksOnQuestion,
}) {
  const { t } = useTranslation();
  const { formatDateRange, formatDateShort } = useFormatter();
  const interval = useGroupType(filters);
  const [startDate, endDate] = usePeriodTimestamps(filters);
  const isCustomPeriod = periodIsCustom(getPeriodFilter(filters));
  const area = getAreaFilter(filters);
  /**
   * @type {{loading: boolean data: {key: number, value: number}[]}}
   * */

  const { useGetAggregatedVotes } = useFeatureFlagHooks();

  const { loading, data } = useGetAggregatedVotes(
    question_id,
    interval,
    startDate,
    endDate,
    area,
    isWholeStore
  );

  const cardTitle = describeAreaFilter(
    isWholeStore ? [WHOLE_STORE_FILTER[0].type] : filters
  );

  const translatedPeriod = isCustomPeriod
    ? formatDateRange(startDate, endDate)
    : t(getPeriodTransKey(filters));

  return (
    <CardWrapper>
      <Card>
        <CardTitle
          title={cardTitle}
          text={<Trans>questionsAskedString</Trans>}
        />
        {loading ? (
          <MFAQVotesSkeleton isDays={interval === "day"} />
        ) : (
          <>
            <GraphSummary>
              <span>
                <Trans values={{ period: translatedPeriod }}>
                  sumFromString
                </Trans>
              </span>
              <strong data-testid="graph-vote-count">
                {safeSum(totalUpvotes, votesFudgeFactor)}
              </strong>
              {displayTasksOnGraph && (
                <span>
                  {tasksOnQuestion &&
                    !!tasksOnQuestion.length &&
                    t("tasksString", {
                      count: tasksOnQuestion.length,
                    })}
                </span>
              )}
            </GraphSummary>
            <VotesGraph
              data={data}
              startDate={startDate}
              endDate={endDate}
              interval={interval}
              isCustomPeriod={isCustomPeriod}
              offset={votesFudgeFactor}
              tasksOnQuestion={tasksOnQuestion}
              displayTasksOnGraph={displayTasksOnGraph}
              formatDateShort={formatDateShort}
            />
          </>
        )}
      </Card>
    </CardWrapper>
  );
}

function safeSum(a, b) {
  const sum = a + b;
  return isNaN(sum) ? "" : sum;
}

/**
 * Calculate how votes should be grouped based on filters.
 * @param {string[]} filters
 * @returns {"day"|"week"}
 */
export function useGroupType(filters) {
  const period = getPeriodFilter(filters);
  const [customGrouping] = useUserPreference(
    profilePreferences.MFAQ_PREFIX +
      profilePreferences.USER_FILTERS_GRAPH_OPTIONS
  );

  if (periodIsCustom(period) && customGrouping) {
    return customGrouping.toLowerCase();
  }
  return period.endsWith("days") ? "day" : "week";
}
