import React from "react";
import styled from "styled-components";
import { useTranslation } from "@coworker/locales";
import "@ingka/svg-icon/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/button/dist/style.css";
import "@ingka/focus/dist/style.css";
import { ReactComponent as RectangleHorizontalThin } from "../../assets/svg/rectangle-horizontal-thin.svg";
import { ReactComponent as ExclamationSignIcon } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { Icon } from "@coworker/components";
import { ContentContainer, Stack, StackVertical } from "./CommonComponents";
import amountTypeOptions from "@coworker/functions/src/enums/amountTypes";
import SkapaInputField from "./shared/SkapaInputField";

const { PALLET_SETTINGS, MULTI_PACK_SETTINGS, MULTI_PACK, PALLET, PIECES } =
  amountTypeOptions;

const isSettingsTab = (active) =>
  [MULTI_PACK_SETTINGS, PALLET_SETTINGS].includes(active);

const GoToSettings = styled.div`
  text-decoration: underline;
  color: var(--related-blue) !important;
  height: 52px;
  font-size: 16px;
  line-height: 44px;
`;

const WarningMessage = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
`;

const WarningIcon = styled(ExclamationSignIcon)`
  margin-right: 20px;
  margin-bottom: -6px;
  color: var(--orange);
`;

function AmountTypeIcon({ selectedAmountType }) {
  switch (selectedAmountType) {
    case MULTI_PACK_SETTINGS:
    case MULTI_PACK:
      return (
        <Icon size={24} family="icons" name="rectangle_horizontal_double" />
      );
    case PALLET_SETTINGS:
    case PALLET:
      // Not using Icon component for RectangleHorizontalThin, since it has some custom styles in SVG
      return (
        <StackVertical>
          <RectangleHorizontalThin />
          <RectangleHorizontalThin />
          <RectangleHorizontalThin />
        </StackVertical>
      );
    case PIECES:
    default:
      return <Icon size={24} family="icons" name="rectangle_horizontal" />;
  }
}

export function AmountPopupMultiPack({
  maxAmount,
  oldAmountSettings,
  goToSettings,
  activeTab,
  inputValue,
  helperText,
  totalPieces,
  dataTestId,
  maxAmountExceeded,
}) {
  const { t } = useTranslation();
  const containerRef = React.useRef(null);
  const selectedAmountType = activeTab;
  const getSetting = (type) => {
    if (type === MULTI_PACK) {
      return oldAmountSettings?.pieces_per_multi_pack;
    }
    if (type === PALLET) {
      return oldAmountSettings?.pieces_per_pallet;
    }
  };
  const hasSetting = (type) => getSetting(type) > 0;

  const isPackOrPallet = [MULTI_PACK, PALLET].includes(selectedAmountType);

  const amountTypeButtons = [
    { text: t("piecesUnit"), value: PIECES, "data-testid": PIECES },
    { text: t("multiPackUnit"), value: MULTI_PACK, "data-testid": MULTI_PACK },
    { text: t("palletUnit"), value: PALLET, "data-testid": PALLET },
    {
      text: t("multipackSettingsUnit"),
      value: MULTI_PACK_SETTINGS,
      "data-testid": MULTI_PACK_SETTINGS,
    },
    {
      text: t("palletSettingsUnit"),
      value: PALLET_SETTINGS,
      "data-testid": PALLET_SETTINGS,
    },
  ];

  const formatSuffix = (type) => {
    return [MULTI_PACK, PALLET].includes(type) && !hasSetting(type)
      ? ""
      : amountTypeButtons.find(({ value }) => value === type)?.text;
  };

  const displayWarningMessage = () => {
    if (!(maxAmount && totalPieces > maxAmount)) return "";

    if (isSettingsTab(selectedAmountType)) return "";

    if (hasSetting(selectedAmountType) || !isPackOrPallet) {
      if (maxAmountExceeded) maxAmountExceeded();
      return (
        <WarningMessage>
          <WarningIcon />
          {t("exceedingMaxPiecesString")}
        </WarningMessage>
      );
    }

    return "";
  };

  const inputFieldProps = {
    value: inputValue,
    "data-testid": `amountInput_${dataTestId}`,
    suffixLabel: formatSuffix(selectedAmountType),
    prefixLabel: <AmountTypeIcon selectedAmountType={selectedAmountType} />,
    inputMode: "none",
    readOnly: true,
  };

  const formFieldProps = {
    fieldHelper: {
      msg: helperText,
    },
  };

  return (
    <>
      <ContentContainer ref={containerRef}>
        <Stack
          onClick={() => {
            if (!hasSetting(selectedAmountType)) {
              containerRef.current.dispatchEvent(
                new KeyboardEvent("keydown", { key: "ArrowRight" })
              );
            }
          }}
        >
          {isPackOrPallet && !hasSetting(selectedAmountType) ? (
            <GoToSettings data-testid="settingsWarning" onClick={goToSettings}>
              {t("goToSettingsString")}
            </GoToSettings>
          ) : (
            <SkapaInputField
              inputFieldProps={inputFieldProps}
              formFieldProps={formFieldProps}
            />
          )}
        </Stack>
      </ContentContainer>
      {displayWarningMessage()}
    </>
  );
}
