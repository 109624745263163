// Like setTimout, but as a promise
const wait = (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

/*
 * If promise takes more than milliseconds to resolve, we reject instead
 * - The closest library function I found (but much more capable/complex) is https://github.com/sindresorhus/p-timeout
 *
 * Test like this:
 * await withTimeout(delay(5), 10).then(()=>"ok").catch(() =>"timeout!");
 * await withTimeout(delay(50), 10).then(()=>"ok").catch(() => "timeout!");
 */
const withTimeout = (promise, milliseconds) =>
  Promise.race([promise, wait(milliseconds).then(() => Promise.reject())]);

module.exports = { wait, withTimeout };
