import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { UserPreferences } from "@coworker/types";
import userPreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { getEnvironmentId, isChineseEnvironment } from "@coworker/reusable";
import { coreServiceBaseUrl } from "@coworker/functions/src/triggers/cloud-services.helper";
import { useFixaUID } from "../core/auth/useLoggedInUser";
import React from "react";
import { makeServiceHeaders } from "../core/hooks/fetch.helpers";

const CORE_SERVICE_URL = coreServiceBaseUrl(
  getEnvironmentId(),
  isChineseEnvironment()
);

async function fetchUserPreferences(userId: string): Promise<UserPreferences> {
  const url = `${CORE_SERVICE_URL}/users/${userId}/preferences`;
  let preferences;
  let headers: HeadersInit;

  try {
    if (typeof makeServiceHeaders !== "function") {
      console.error("makeServiceHeaders is undefined");
      return {};
    }

    headers = await makeServiceHeaders();
  } catch (error) {
    console.error("Error creating service headers:", error);
    return {};
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    preferences = await response.json();
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }

  return preferences?.preferences as UserPreferences;
}

/**
 * Fetches all user preferences for current user
 * @returns React query result where the `data` property contains user preferences
 */
export function useUserPreferences(settingName?: string) {
  const fixaUid = useFixaUID();
  const result = useQuery({
    queryKey: ["userPreferences", fixaUid, settingName],
    queryFn: async () => await fetchUserPreferences(fixaUid),
    staleTime: 1000 * 60 * 5, // 5 minutes
  });
  if (result.data?.["language"]) {
    localStorage.setItem("hej-language", result.data?.["language"] as string);
  }
  return result;
}

async function makeSetMultiplePreferences(
  userId: string,
  update: Partial<UserPreferences>
) {
  const response = await fetch(
    `${CORE_SERVICE_URL}/users/${userId}/preferences`,
    {
      method: "PUT",
      headers: await makeServiceHeaders(),
      body: JSON.stringify({ update }),
    }
  );
  if (!response.ok) {
    throw new Error("Bad network response when updating user preferences!");
  }

  const newPreferences = await response.json();
  return newPreferences as UserPreferences;
}

/**
 * A React query mutation that updates the user preferences object.
 * Also handles optimistic updates of the `userPreferences` query,
 * as well as reverting on error and invalidating the queries when updates happen.
 *
 * Do not use this low level hook to update a single preference, use `useUserPreference` instead!
 * @example ```
 * const updatePreferences = useUpdateUserPreferences();
 * updatePreferences.mutate({myPreferenceName: newValue})
 * updatePreferences.mutate({preference1: value1, preference2: value2});
 * ```
 */
export function useUpdateUserPreferences(settingName: string) {
  const queryClient = useQueryClient();
  const fixaUid = useFixaUID();

  const queryKey = ["userPreferences", fixaUid, settingName];
  const mutation = useMutation<
    Partial<UserPreferences>,
    unknown,
    { [x: string]: any },
    { previousPreferences: UserPreferences }
  >({
    mutationKey: queryKey,
    mutationFn: (update) => makeSetMultiplePreferences(fixaUid, update),
    onMutate: async (update) => {
      await queryClient.cancelQueries({ queryKey });
      const previousPreferences = queryClient.getQueryData(
        queryKey
      ) as UserPreferences;

      // Optimistic update of the preferences on the client
      queryClient.setQueryData<UserPreferences>(queryKey, (old) => {
        return { ...old, ...update };
      });

      return { previousPreferences };
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data["preferences"]);
    },
    // On mutation error, restore previous state
    onError: (_err, _update, ctx) =>
      queryClient.setQueryData(queryKey, ctx?.previousPreferences),
  });

  return mutation;
}

/**
 * Used to load and save a preference from the `user_preferences` table.
 * @param settingName Which setting to fetch
 * @param fallback If the setting does not exist, default to this value
 * @example ```
 * const [showSomeScreen, setShowSomeScreen] = useUserPreference(mfaq.shouldShowScreen, true);
 * if (showSomeScreen) render(...);
 * someButton.onClick(() => setShowSomeScreen(false));
 * ```
 */
export function useUserPreference<SettingType>(
  settingName: string,
  fallback: SettingType
): [SettingType, (value: SettingType) => void, boolean] {
  const { data: preferences, isLoading } = useUserPreferences(settingName);
  const mutation = useUpdateUserPreferences(settingName);

  const setPreference = React.useCallback(
    (newValue) => {
      mutation.mutate({ [settingName]: newValue });
      // Save language to local storage for Hej components initialization
      if (settingName === userPreferences.LANGUAGE) {
        localStorage?.setItem("hej-language", newValue);
      }
    },
    [mutation, settingName]
  );

  return [
    (preferences?.[settingName] as SettingType) ?? fallback,
    setPreference,
    isLoading,
  ];
}
