import {
  HEADER,
  PRODUCT_WITH_PILLS,
  LOCATIONWITHICON,
  DIVIDER,
  SPACER,
  DESCRIPTION,
  ASSIGNMENT,
  PRIORITY,
  IMAGES,
  CHECKLIST,
  ACTIVITY,
  TASKLISTVALIDATION,
} from "./_common";

import { PickUpTask, CompleteTask } from "../actions/index";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fields: [
    HEADER,
    PRODUCT_WITH_PILLS,
    SPACER(20),
    LOCATIONWITHICON,
    DIVIDER,
    DESCRIPTION("description", "description4String", "description"),
    ASSIGNMENT("profileIcon"),
    PRIORITY,
    IMAGES,
    CHECKLIST,
    ACTIVITY,
    TASKLISTVALIDATION,
  ],
  actions: [PickUpTask, CompleteTask],
};
