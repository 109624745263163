import React from "react";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { useUserPreference } from "../hooks/useProfilePreferencesQuery";
import { Trans } from "@coworker/locales";
import { InfoBox } from "@coworker/components";
import styled from "styled-components";

const BottomBorder = styled.div`
  border-bottom: 1px solid var(--grey100);
`;

const Instructions = ({
  title = <Trans>pickingOnlyString</Trans>,
  text = <Trans>pickingOnlyTextString</Trans>,
  type = profilePreferences.DISABLE_POPUPS_ERROR,
  showBottomBorder = false,
}) => {
  const [userPreferenceInstructions, setUserPreferenceInstructions] =
    useUserPreference(type, false);

  if (userPreferenceInstructions) return <></>;
  return (
    <>
      <InfoBox
        text={text}
        title={title}
        onClose={() => {
          setUserPreferenceInstructions(true);
        }}
      />
      {showBottomBorder && <BottomBorder />}
    </>
  );
};

export default Instructions;
