import React from "react";
import {
  ChecklistItem,
  TestBuyFollowUp,
} from "@coworker/types/lib/tasks/testbuy_followup";
import { WithDoneState } from "./taskValidation";
import { toggleChecklistItem } from "./checklistWidgetHelper";

export const useChecklist = (
  task: WithDoneState<TestBuyFollowUp>,
  checklist: ChecklistItem[],
  setChecklist: React.Dispatch<React.SetStateAction<ChecklistItem[]>>,
  taskId: string
) => {
  const toggleCriterion = (id: string) => {
    setChecklist((state: ChecklistItem[]) => {
      return state.map((stateItem: ChecklistItem) => {
        if (stateItem.key === id) {
          toggleChecklistItem(taskId, stateItem.key, !stateItem.isDone);
          return { ...stateItem, isDone: !stateItem.isDone };
        } else {
          return stateItem;
        }
      });
    });
  };

  React.useEffect(() => {
    checklist.every((item: ChecklistItem) => item.isDone)
      ? task.setIsDone(true)
      : task.setIsDone(false);
  }, [checklist, task]);

  return {
    checklist,
    toggleCriterion,
  };
};
