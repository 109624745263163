import config_en_US from "./en-US.json";
import config_sv_SE from "./sv-SE.json";
import config_pl_PL from "./pl-PL.json";
import config_da_DK from "./da-DK.json";
import config_de_DE from "./de-DE.json";
import config_es_ES from "./es-ES.json";
import config_fr_FR from "./fr-FR.json";
import config_it_IT from "./it-IT.json";
import config_nl_NL from "./nl-NL.json";
import config_pt_PT from "./pt-PT.json";
import config_ru_RU from "./ru-RU.json";
import config_sl_SI from "./sl-SI.json";
import config_zh_CN from "./zh-CN.json";
import config_cs_CZ from "./cs-CZ.json";
import config_hu_HU from "./hu-HU.json";
import config_ja_JP from "./ja-JP.json";
import config_ko_KR from "./ko-KR.json";
import config_sk_SK from "./sk-SK.json";
import config_sr_Latn from "./sr-Latn.json";
import config_eu_ES from "./eu-ES.json";
import config_hr_HR from "./hr-HR.json";
import config_de_AT from "./de-AT.json";
import config_fr_CA from "./fr-CA.json";
import config_ro_RO from "./ro-RO.json";
import config_nb_NO from "./nb-NO.json";
import config_fi_FI from "./fi-FI.json";
import config_en_GB from "./en-GB.json";

if (localStorage?.getItem("hej-language") === undefined) {
  localStorage.setItem("hej-language", "en-US");
}

export const pickTranslationFile = () => {
  const language = localStorage.getItem("hej-language") || "en-US";

  switch (language) {
    case "en_US":
      return config_en_US;
    case "pl_PL":
      return config_pl_PL;
    case "sv_SE":
      return config_sv_SE;
    case "da_DK":
      return config_da_DK;
    case "de_DE":
      return config_de_DE;
    case "es_ES":
      return config_es_ES;
    case "fr_FR":
      return config_fr_FR;
    case "it_IT":
      return config_it_IT;
    case "nl_NL":
      return config_nl_NL;
    case "pt_PT":
      return config_pt_PT;
    case "ru_RU":
      return config_ru_RU;
    case "sl_SI":
      return config_sl_SI;
    case "zh_CN":
      return config_zh_CN;
    case "cs_CZ":
      return config_cs_CZ;
    case "hu_HU":
      return config_hu_HU;
    case "ja_JP":
      return config_ja_JP;
    case "ko_KR":
      return config_ko_KR;
    case "sk_SK":
      return config_sk_SK;
    case "sr_Latn":
      return config_sr_Latn;
    case "eu_ES":
      return config_eu_ES;
    case "hr_HR":
      return config_hr_HR;
    case "de_AT":
      return config_de_AT;
    case "fr_CA":
      return config_fr_CA;
    case "ro_RO":
      return config_ro_RO;
    case "nb_NO":
      return config_nb_NO;
    case "fi_FI":
      return config_fi_FI;
    case "en_GB":
      return config_en_GB;
    default:
      return config_en_US;
  }
};
