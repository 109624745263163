import Text from "@ingka/text";
import React from "react";
import styles from "./profile-section.module.css";
import { Trans } from "@coworker/locales";
interface ProfileSectionHeaderProps {
  title: string;
}

export const ProfileSectionHeader: React.FC<ProfileSectionHeaderProps> = ({
  title,
}) => {
  return (
    <div>
      <div className={styles["section-header"]}>
        <Text
          className={styles["section-header-text"] as string}
          tagName="h3"
          headingSize="xs"
        >
          <Trans>{title}</Trans>
        </Text>
      </div>
    </div>
  );
};
