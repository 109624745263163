import React from "react";
import { useTranslation } from "@coworker/locales";
import { Widget } from "@coworker/components/src/components/Widget";
import { AddDescription } from "@coworker/components";
import { useInputPopup } from "../../InputPopup";

/**
 * @deprecated This method has been moved to typescript and will be removed in the future (AddDescriptionWidget)
 */
export default function TaskFormDescription({
  value,
  onChange,
  conf: {
    optional,
    descriptionTitleTranslationKey = "descriptionAddString",
    icon,
    lblColor,
    overridePadding,
  },
  last,
  additionalSubTitle,
}) {
  const { getInput } = useInputPopup();
  const { t } = useTranslation();
  const getDescription = async () => {
    const desc = await getInput("description", {
      initialValue: value,
      title: "",
      descriptionTitle: t(descriptionTitleTranslationKey),
      optional,
    });
    if (desc) {
      onChange(desc);
    }
  };

  return (
    <Widget
      onClick={getDescription}
      data-testid="descriptionWidget"
      tabIndex={0}
    >
      <AddDescription
        value={value}
        deactivated={!value}
        descriptionTitle={t(descriptionTitleTranslationKey)}
        optional={optional}
        noBottomBorder={last}
        icon={icon}
        lblColor={lblColor}
        overridePadding={overridePadding}
        activeIcon={icon}
        additionalSubTitle={
          !value && window.location.pathname === "/task/new/mfaq_followup" ? (
            additionalSubTitle
          ) : (
            <></>
          )
        }
      />
    </Widget>
  );
}
