import React from "react";
import styles from "./task-card.module.css";
import { Trans } from "@coworker/locales";
import { Task } from "@coworker/types/lib/tasks/base";
import { CardMode } from "./types";
import { CARD_MODE } from "./constants";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";

interface PriorityProps {
  task: Task;
  state: string;
  cardMode: CardMode;
}

export const Priority = ({ task, state, cardMode }: PriorityProps) => {
  const isTaskCompleted = state === taskStateOptions.COMPLETED;

  return (
    <>
      {task.priority_flag && (
        <div className={styles["priority-wrapper"]}>
          <div
            className={`${styles["priority-dot"]} ${
              isTaskCompleted && styles["completed"]
            }`}
          />
          <div
            className={`${styles["priority"]} ${
              isTaskCompleted && styles["completed"]
            }`}
          >
            {cardMode !== CARD_MODE.SIMPLE && (
              <>
                {task.task_type === "PRODUCT_QUALITY" ? (
                  <Trans>safetyAlarmString</Trans>
                ) : (
                  <Trans>priorityString</Trans>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
