import React from "react";
import { Text } from "./Text";
import { fullRoomName } from "../tools";

interface HeadLineProps {
  main?: string | undefined;
  sub1?: string | undefined;
  sub2?: string | undefined;
  bold?: boolean | undefined;
}

export function HeadLine({ main, sub1, sub2, bold }: HeadLineProps) {
  bold = bold === undefined ? true : bold;
  let headLine = fullRoomName(main, sub1, sub2);

  return <Text text={headLine} bold={bold} />;
}
