import React from "react";
import styled from "styled-components";
import Skeleton from "@ingka/skeleton";
import "@ingka/loading/dist/style.css";
import "@ingka/skeleton/dist/style.css";
import { Container } from "../../Overview/NewReview";

const Label = styled(Skeleton)`
  width: 200px !important;
  height: 17px;
`;

const Time = styled(Skeleton)`
  width: 250px !important;
  height: 39px;
  margin: 10px 0px;
`;

const Button = styled(Skeleton)`
  width: 143px !important;
  height: 56px;
  border-radius: 30px;
`;

export const NewReviewSkeleton = () => {
  return (
    <Container>
      <Label />
      <Time />
      <Button />
    </Container>
  );
};
