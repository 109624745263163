import React from "react";
import styled, { css } from "styled-components";

const StyledCheckbox = styled.div`
  appearance: none;
  cursor-events: none;
  cursor: none;
  position: relative;
  cursor: pointer;
  margin-bottom: 24px;
  margin-right: 24px;
  ${(props) =>
    props.focus &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: -4px;
        left: -6px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        border: ${(props) =>
          props.error ? "1px solid var(--error)" : "1px solid var(--grey500)"};
        border: ${(props) =>
          props.checked && !props.error && "1px solid var(--grey900)"};
        border: ${(props) => props.light && "1px solid var(--white)"};
        border: ${(props) =>
          props.light && props.error && "1px solid var(--yellow)"};
      }
    `}
`;
const Input = styled.input`
  position: absolute;
  cursor: pointer;
  appearance: none;
  top: 0px;
  left: -2px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid var(--grey500);
  border: ${(props) =>
    props.error ? "1px solid var(--error)" : "1px solid var(--grey500)"};
  border: ${(props) => props.light && "1px solid var(--white)"};
  border: ${(props) => props.light && props.error && "1px solid var(--yellow)"};
  border: ${(props) =>
    props.isPressed &&
    !props.light &&
    !props.error &&
    "1px solid var(--grey700)"};
`;
const Check = styled.div`
  cursor: pointer;
  position: absolute;
  background: var(--grey900);
  background: ${(props) => props.error && "var(--error)"};
  background: ${(props) => props.light && "var(--white)"};
  background: ${(props) => props.light && props.error && "var(--yellow)"};
  top: 4px;
  left: 2px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  &:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    top: -4px;
    left: -4px;
    width: 22px;
    height: 22px;
  border: ${(props) => !props.checked && "1px solid black"}
    border: ${(props) => props.error && "1px solid var(--error)"};
    border: ${(props) =>
      !props.checked && props.light && "1px solid var(--white)"};
    border: ${(props) =>
      !props.checked &&
      props.light &&
      props.error &&
      "1px solid var(--yellow)"};
  }
`;

const RadioButton = (props) => {
  const { error, light, checked, ...inputProps } = props;
  const [isFocused, setFocus] = React.useState(false);
  const [isPressed, setPressed] = React.useState(false);

  // const focus = React.useCallback((event) => {
  //   setFocus(true);
  // }, []);

  const blur = React.useCallback((event) => {
    setFocus(false);
  }, []);

  const dontFocus = () => {
    setPressed(true);
  };

  const allowFocus = () => {
    setPressed(false);
  };

  return (
    <StyledCheckbox
      focus={!isPressed && isFocused}
      error={error}
      light={light}
      checked={checked}
    >
      <label>
        <Input
          {...inputProps}
          type="checkbox"
          onBlur={blur}
          onMouseDown={dontFocus}
          onMouseUp={allowFocus}
          error={error}
          light={light}
          checked={checked}
          isPressed={isPressed}
        />
        {checked && <Check error={error} light={light} />}
      </label>
    </StyledCheckbox>
  );
};

export default RadioButton;
