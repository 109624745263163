export function getSubscription() {
  return new Promise((resolve, reject) => {
    navigator.serviceWorker.ready.then((swRegistration) => {
      if (swRegistration.pushManager) {
        swRegistration.pushManager
          .getSubscription()
          .then((subscription) => {
            resolve(subscription);
          })
          .catch((err) => {
            if (
              "Notification" in window &&
              Notification.permission === "denied"
            ) {
              reject("Permission for notifications was denied");
            } else {
              reject(err);
            }
          });
      }
    });
  });
}

export function createSubscription() {
  const publicKey = process.env.REACT_APP_NOTIFICATION_PUBLIC_KEY;
  return new Promise((resolve, reject) => {
    if (!navigator.serviceWorker) {
      return console.error("createSubscription: no serviceWorker");
    }
    navigator.serviceWorker.ready.then((swRegistration) => {
      swRegistration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlB64ToUint8Array(publicKey),
        })
        .then((subscription) => {
          resolve(subscription);
        })
        .catch((err) => {
          if (
            "Notification" in window &&
            Notification.permission === "denied"
          ) {
            reject("Permission for notifications was denied");
          } else {
            reject(err);
          }
        });
    });
  });
}

export function removeSubscription() {
  const promise = new Promise((resolve, reject) => {
    if (!navigator.serviceWorker) {
      return reject("no serviceWorker");
    }
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      if (registrations.length === 0) {
        // Service worker registration was never started (e.g. because not a prod environment) - the
        // navigator.serviceWorker.ready promise below would never resolve and this would block user
        // logout code flow. So we just resolve here.
        resolve();
      } else {
        navigator.serviceWorker.ready
          .then((swRegistration) => {
            if (!swRegistration?.pushManager?.getSubscription) {
              return reject("missing pushManager.getSubscription");
            }
            swRegistration.pushManager
              .getSubscription()
              .then((subscription) => {
                if (subscription) {
                  subscription.unsubscribe();
                  resolve(subscription);
                } else {
                  resolve();
                }
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => reject(error));
      }
    });
  });
  return promise.catch((error) =>
    console.error("removeSubscription error:", error)
  );
}

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
