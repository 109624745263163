import React from "react";
import styled from "styled-components";
import {
  TestBuyProductWithCriteria,
  InternalProxyResponse,
} from "@coworker/types";
import { TestBuyArticleProgressBar } from "./TestBuyArticleProgressBar";
import { ReactComponent as OpenIcon } from "../../../../assets/svg/arrow-small-forward.svg";
import { ReactComponent as CheckmarkIcon } from "../../../../assets/svg/checkmark.svg";
import { ReactComponent as WarningTriangle } from "@coworker/reusable/svg/priority-exclamation-sign.svg";
import { getArticleCompletionStatus } from "../article.helpers";
import { Trans } from "@coworker/locales";
import {
  useTestbuyTasks as useTestbuyTasksFS,
  useTestbuy as useTestbuyFS,
} from "../Repositories/testbuy.repository";
import {
  useTestbuyTasks as useTestbuyTasksPG,
  useTestbuy as useTestbuyPG,
} from "../Repositories/useTestbuyService";
import { FollowupTaskDetails } from "./FollowupTaskDetails";
import ArticleCardSkeleton from "./ArticleCardSkeleton";
import { isDateWithinLastWeek } from "../helpers";
import SlidingWrapper from "../../../SlidingWrapper";
import { ChangeWithButton, GetNewButton } from "./TestBuyArticleCardButtons";
import { useInputPopup } from "../../../InputPopup";
import { useToastNotification } from "@coworker/components";
import { ElidedText } from "@coworker/reusable/components/ElidedText";
import {
  formatProductIdWithDots,
  isChineseEnvironment,
} from "@coworker/reusable";
import { useReplaceArticle as useReplaceArticleFS } from "../Repositories/article.repository.service";
import { useReplaceArticle as useReplaceArticlePG } from "../Repositories/useArticleService";
import {
  LIGHT_TOAST,
  WARNING_TOAST,
} from "@coworker/components/src/components/ToastNotification";
import {
  callInternal,
  TESTBUY_GET_RANDOM_ARTICLES,
} from "../../../../hooks/API/useCallInternal";
import { TYPES_OF_ARTICLES } from "../../constants";
import { postToTestbuyService } from "../../../../hooks/API/testbuy.service";
import { useCountryId } from "../../../../core/auth/useLoggedInUser";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import { useMyStore } from "../../../../hooks/useMyStore";
import { useUserPreference } from "../../../../hooks/useProfilePreferencesQuery";

export const Image = styled.img`
  width: 4rem;
  height: 4rem;
  opacity: ${(props: { allQuestionsAnswered: boolean }) =>
    props.allQuestionsAnswered ? "0.2" : "1"};
`;

export const ImageContainer = styled.div`
  position: relative;
`;
const IsCompletedSvg = styled.div`
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
`;

export const ElideDescription = styled(ElidedText)`
  color: var(--grey718);
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  height: 100%;
  font-size: 14px;
`;
const NavigationIcon = styled.div`
  margin-left: auto;
`;
const StyledSlidingWrapper = styled(SlidingWrapper)`
  min-height: 110px;
  position: relative;
`;
export const ArticleDetailsCardContent = styled.div`
  padding: 20px;
`;
export const TaskContainer = styled.div`
  display: flex;
  align-items: center;
`;
const FollowUpTaskIdWarning = styled.div`
  padding-left: 5.3rem;
  font-size: 12px;
  color: var(--error);
`;
export interface TestbuyArticleCardProps {
  testbuyId: string;
  article: TestBuyProductWithCriteria;
  allArticleNumbers?: string[];
  showNavigationIcon: boolean;
  showAsCompleted?: boolean;
  isInteractive: boolean;
  loading?: boolean;
  onChangeArticle?(): Promise<void>;
}

export const TestBuyArticleCard = ({
  article,
  allArticleNumbers,
  testbuyId,
  showNavigationIcon,
  showAsCompleted,
  isInteractive,
  loading,
  onChangeArticle,
}: TestbuyArticleCardProps) => {
  const isChina = isChineseEnvironment();
  const { getInput } = useInputPopup();
  const { showToast } = useToastNotification();
  const wrapperRef = React.useRef(null);
  const [activeArticle, setActiveArticle] = React.useState(article);
  const countryId = useCountryId();
  const store = useMyStore();
  const storeConfiguration = store?.configuration || {};
  const [userLanguage] = useUserPreference(
    profilePreferences.LANGUAGE,
    storeConfiguration?.locale?.primary_locale
  );
  // @ts-ignore
  const avoidFirebase = window.callTestbuyService;
  const useReplaceArticle = avoidFirebase
    ? useReplaceArticlePG
    : useReplaceArticleFS;
  const useTestbuy = avoidFirebase ? useTestbuyPG : useTestbuyFS;
  const useTestbuyTasks = avoidFirebase ? useTestbuyTasksPG : useTestbuyTasksFS;
  const [
    replacementArticle,
    replacementInProgress,
    replacementError,
    replaceCurrentArticleWith,
  ] = useReplaceArticle(testbuyId, activeArticle.shortId, userLanguage);

  React.useEffect(() => {
    if (
      replacementArticle &&
      replacementArticle.shortId !== activeArticle.shortId
    ) {
      // Update all article number for scanner
      if (allArticleNumbers) {
        const indexOfCurrentArticleId = allArticleNumbers.indexOf(
          activeArticle.shortId
        );
        indexOfCurrentArticleId !== -1 &&
          (allArticleNumbers[indexOfCurrentArticleId] =
            replacementArticle.shortId);
      }
      setActiveArticle(replacementArticle);
    }
  }, [activeArticle.shortId, allArticleNumbers, replacementArticle]);

  React.useEffect(() => {
    if (replacementArticle) {
      onChangeArticle?.();
    }
  }, [replacementArticle, onChangeArticle]);

  React.useEffect(() => {
    replacementError &&
      showToast(
        <Trans>couldNotUseThisArticleString</Trans>,
        undefined,
        undefined,
        5,
        WARNING_TOAST
      );
  }, [replacementError, showToast]);

  const task = useTestbuyTasks(testbuyId)?.find?.(
    (t) => t.articleId === activeArticle.shortId
  );

  const { testbuy, loading: testbuyLoading } = useTestbuy(testbuyId);

  const articleStatus = getArticleCompletionStatus(article);
  const allQuestionsAnswered =
    articleStatus === "Completed" || articleStatus === "FollowUpTaskMissing";

  const animatedLeftButtons = [
    <ChangeWithButton
      onClick={async () => {
        const newArticle: {
          product: string;
          supplierNumber: string;
          type: string;
        } = await getInput("scansearch", {
          start: Date.now(),
          withHistory: false,
          hideSPR: true, // TODO: Remove this parameter when SLM starts supporting returning locations for SPR articles.
          forbiddenArticles: allArticleNumbers,
          onForbiddenArticle: () =>
            showToast(
              <Trans>articleAlreadyInTestbuyString</Trans>,
              undefined,
              undefined,
              5,
              LIGHT_TOAST
            ),
        });

        if (newArticle) {
          const { product: newArticleId } = newArticle;
          replaceCurrentArticleWith(newArticleId);
        }
      }}
    />,
  ];

  const animatedRightButtons = isChina
    ? []
    : [
        <GetNewButton
          onClick={async () => {
            const area = testbuy?.area ?? "";
            const storeId = testbuy?.storeId ?? "";
            let randomArticleIds: string[] = [];
            if (area && storeId) {
              if (avoidFirebase) {
                const servicePromise = new Promise(async (resolve) => {
                  try {
                    await postToTestbuyService(
                      "testbuy/random_articles",
                      {
                        numberOfArticles: 6,
                        area,
                        storeId,
                        locale: userLanguage,
                        typeOfArticles:
                          testbuy?.typeOfArticles ?? TYPES_OF_ARTICLES[1],
                        countryId,
                      },
                      (data: TestBuyProductWithCriteria[]) => resolve(data)
                    );
                  } catch (error) {
                    resolve("Could not get new Article");
                  }
                });

                const randomArticleData = await servicePromise;

                if (Array.isArray(randomArticleData)) {
                  randomArticleIds = randomArticleData.map(
                    (art: TestBuyProductWithCriteria) => art.shortId
                  );
                } else {
                  console.log("RandomData is not an array.");
                }
              } else {
                const response: InternalProxyResponse<
                  TestBuyProductWithCriteria[] | null
                > = await callInternal(TESTBUY_GET_RANDOM_ARTICLES, {
                  numberOfArticles: 3,
                  area,
                  storeId,
                  typeOfArticles:
                    testbuy?.typeOfArticles ?? TYPES_OF_ARTICLES[1],
                  countryId,
                  locale: userLanguage,
                });
                randomArticleIds =
                  response?.data?.map(
                    (art: TestBuyProductWithCriteria) => art.shortId
                  ) || [];
              }
              const articleId = getNoneTakenArticle(randomArticleIds);

              if (articleId) {
                replaceCurrentArticleWith(articleId);
                return;
              } else {
                showToast(
                  <>
                    <Trans>taskErrorMessage</Trans>
                    <br />
                    <Trans>tryAgainString</Trans>
                  </>,
                  undefined,
                  undefined,
                  5,
                  WARNING_TOAST
                );
              }
            }
          }}
        />,
      ];

  function getNoneTakenArticle(randomArticleIds: string[] | undefined) {
    for (const randomArticleId of randomArticleIds ?? new Array<string>()) {
      const existingArticle = allArticleNumbers?.find(
        (number) => number === randomArticleId
      );
      if (!existingArticle) {
        return randomArticleId;
      } else {
        console.log("New article not available");
      }
    }
    return "";
  }

  // Only enable swiping for articles which have not been worked on
  const swipingEnabled = isInteractive && articleStatus === "NotStarted";

  function getRedirectLink() {
    if (
      window.location.pathname !== `/testbuy/${testbuyId}/article/${article.id}`
    ) {
      return showAsCompleted &&
        task?.taskId &&
        isDateWithinLastWeek(task?.finishedAt)
        ? `/task/${task.taskId}`
        : `/testbuy/${testbuyId}/article/${activeArticle.id}`;
    } else return "";
  }

  if (loading || replacementInProgress || testbuyLoading)
    return <ArticleCardSkeleton />;
  return (
    //This div exist due to render issues on RDT
    <div>
      <StyledSlidingWrapper
        isDraggable={swipingEnabled}
        swipeBinding={swipingEnabled}
        dataType={articleStatus}
        dataTestId={`articleDetailsCard-${activeArticle.shortId}`}
        dataId={activeArticle.shortId}
        animatedLinkRedirect={getRedirectLink()}
        animatedLinkClosed={false}
        leftButtons={animatedLeftButtons}
        rightButtons={animatedRightButtons}
        wrapperRef={wrapperRef}
      >
        <ArticleDetailsCardContent>
          <TaskContainer>
            <ImageContainer>
              <Image
                allQuestionsAnswered={!showAsCompleted && allQuestionsAnswered}
                src={activeArticle.smallImage}
              />
              {!showAsCompleted && allQuestionsAnswered && (
                <IsCompletedSvg>
                  {articleStatus === "Completed" && <CheckmarkIcon />}
                  {articleStatus === "FollowUpTaskMissing" && (
                    <WarningTriangle />
                  )}
                </IsCompletedSvg>
              )}
            </ImageContainer>
            <TextContainer>
              <strong>
                {activeArticle.name}{" "}
                {activeArticle.location !== "no_location" && (
                  <>
                    {"•"} {activeArticle.location}
                  </>
                )}
              </strong>
              <ElideDescription>
                {formatProductIdWithDots(activeArticle.shortId)},{" "}
                {activeArticle.description}
              </ElideDescription>
              {showAsCompleted && (
                <FollowupTaskDetails taskState={task?.state} />
              )}
            </TextContainer>
            {showNavigationIcon && (
              <NavigationIcon>
                <OpenIcon />
              </NavigationIcon>
            )}
          </TaskContainer>
          {!showAsCompleted && articleStatus !== "NotStarted" && (
            <TestBuyArticleProgressBar criteriaArray={activeArticle.criteria} />
          )}
          {!showAsCompleted && articleStatus === "FollowUpTaskMissing" && (
            <FollowUpTaskIdWarning>
              <Trans>createFollowUpTaskWarning</Trans>
            </FollowUpTaskIdWarning>
          )}
        </ArticleDetailsCardContent>
      </StyledSlidingWrapper>
    </div>
  );
};
