import React from "react";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import { InfoBox } from "@coworker/components";

export default function TaskFormInstructions({ conf }) {
  const [userPreferenceInstructions, setUserPreferenceInstructions] =
    useUserPreference(conf.type, false);

  return (
    !userPreferenceInstructions && (
      <InfoBox
        {...conf}
        onClose={() => {
          setUserPreferenceInstructions(true);
        }}
      />
    )
  );
}
