import React from "react";

export const policy = {
  title: <div>Polityka Prywatności Fixa</div>,
  aboutFixaText: (
    <div>
      Fixa to narzędzie do zarządzania zadaniami i merchandisingu dla
      pracowników sklepu. Celem wdrożenia aplikacji Fixa jest zastąpienie
      czynności wykonywanych ręcznie, na papierze lub w Excelu oraz usprawnienie
      zarządzania zadaniami w sklepie (uzupełnianie artykułów, sprzątanie,
      odchylenia, zakupy testowe, najczęściej zadawane pytania itp.).
      <br />
      <br />
      Fixa pozwala również na analitykę poprzez wgląd w statystyki ukończonych
      zadań.
      <br />
      <br />
      Kiedy korzystasz z Fixa, administrator danych (zgodnie z definicją
      poniżej) będzie gromadzić pewne dane osobowe o Tobie.
    </div>
  ),
  personalDataCollectedTitle: <div>Jakie dane osobowe zbieramy:</div>,
  personalDataCollectedText: (
    <div>
      Kiedy korzystasz z Fixa, system gromadzi informacje o Tobie z bazy
      pracowników (CDS), takie jak Twoje imię (imiona), nazwisko, adres e-mail,
      stanowisko, numer telefonu (jeśli wprowadzono numer ręcznie - nie należy
      wprowadzać prywatnego numeru telefonu), zdjęcie z CDS, preferencje
      językowe w aplikacji, identyfikator sklepu, identyfikator kraju, wersja
      urządzenia i systemu operacyjnego, adres IP przetwarzany w Google Cloud
      Platform i Sentry (Sentry to oprogramowanie do śledzenia błędów
      aplikacji).
      <br />
      <br />
      Profil pracownika jest tworzony ręcznie za pomocą Fixa-admin
      (administracyjnej aplikacji internetowej) przez kierownika/superusera.
      Dane osobowe są pobierane z CDS, więc profile można tworzyć tylko dla
      pracowników, którzy mają aktywny profil CDS.
    </div>
  ),
  purposeOfDataCollectionTitle: (
    <div>Cel zbierania i przetwarzania danych:</div>
  ),
  purposeOfDataCollectionText: (
    <div>
      Celem przetwarzania danych osobowych w Fixa jest zapewnienie prawidłowego
      działania aplikacji w sposób umożliwiający łatwą komunikację wewnętrzną
      pomiędzy pracownikami oraz administrowanie zadaniami pomiędzy pracownikami
      w sklepie. Przypisanie zadań do konkretnych użytkowników umożliwia
      przesyłanie aktualizacji statusów zadań do odpowiednich osób. Celem
      przetwarzania danych osobowych jest również zapewnienie kontroli dostępu
      do informacji przechowywanych w Fixa dla zachowania odpowiedniego poziomu
      bezpieczeństwa tych informacji.
    </div>
  ),
  legalGroundsTitle: <div>Jaka jest podstawa prawna przetwarzania:</div>,
  legalGroundsText: (
    <div>
      Podstawą prawną jest prawnie uzasadniony interes administratora (Art.6
      ust.1 lit.f) RODO).
    </div>
  ),
  dataWillBeStoredTitle: (
    <div>Jak długo będziemy przetwarzać dane osobowe:</div>
  ),
  dataWillBeStoredText: (
    <div>
      Informacje dotyczące ukończonych zadań są przechowywane przez 70 dni a
      następnie są anonimizowane. Dane osobowe pracowników przechowywane są do
      momentu rozwiązania umowy o pracę.
      <br />
      <br />
      Rozwiązanie umowy o pracę: Wszystkie dane osobowe z Fixa są usuwane w
      ciągu 1 miesiąca od usunięcia konta CDS.
      <br />
      <br />
      Zmiana stanowiska w ramach Grupy Ingka: Jeśli na nowym stanowisku
      pracownik nie ma potrzeby korzystania z Fixa, wszelkie dane osobowe tego
      pracownika są usuwane w ciągu 3 miesięcy od ostatniej aktywności.
      <br />
      <br />
      Dane osobowe mogą również zostać usunięte na wniosek pracownika. W tym
      celu skontaktuj się ze swoim Inspektorem Ochrony Danych pod adresem{" "}
      <a href="mail.com:prywatnosc@ikea.com">prywatnosc@ikea.com</a>.
    </div>
  ),
  dataControllerForPersonalDataTitle: (
    <div>Kto jest administratorem danych:</div>
  ),
  dataControllerForPersonalDataText: (
    <div style={{ fontWeight: "bold" }}>
      Administratorem Danych przetwarzanych w Fixa jest IKEA Retail Sp. z o.o.,
      Pl. Szwedzki 3, 05-090 Raszyn.
    </div>
  ),
  rightToPersonalInformationText: (
    <div>
      W związku z wykorzystywaniem Twoich danych osobowych, przysługują Ci
      określone prawa, takie jak m.in. prawo dostępu do swoich danych, do
      żądania ich usunięcia czy do ich aktualizacji.
      <br />
      <br />
      Szczegółowe informacje znajdziesz na stronie:{" "}
      <a href="https://inside.ingka.com/methodsstrategies/Policies/PoliciesforIKEARetail/Pages/Przetwarzanie-danych-osobowych-pracownik%C3%B3w-IKEA.aspx">
        https://inside.ingka.com/&#8203;methodsstrategies/&#8203;Policies/&#8203;PoliciesforIKEARetail/&#8203;Pages/&#8203;Przetwarzanie-danych-osobowych-pracownik%C3%B3w-IKEA.aspx
      </a>
      <br />
      <br />
      Dane kontaktowe Inspektora Ochrony Danych:{" "}
      <a href="mailto:prywatnosc@ikea.com">prywatnosc@ikea.com</a>
    </div>
  ),
};
