import React from "react";
import { CenterContainer, FlexibleRowContainer } from "../../styles/styles";
import Skeleton from "@ingka/skeleton";

export const LargeSkeletonBox = () => {
  return (
    <FlexibleRowContainer style={{ padding: "20px 24px" }}>
      <CenterContainer $centerText={false}>
        <Skeleton height={"362px"} width={"314px"} />
      </CenterContainer>
    </FlexibleRowContainer>
  );
};
