import { TestBuyFollowUp } from "@coworker/types/lib/tasks/testbuy_followup";
import React from "react";
import { useCreateTask } from "../../../hooks/TaskActions/useCreateTask";
import { useToggle } from "../../../hooks/useToggle";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { addFollowUpTaskId as addFollowUpTaskIdFS } from "../CommonComponents/Repositories/article.repository.service";
import { useAddFollowUpTaskId as addFollowUpTaskIdPG } from "../CommonComponents/Repositories/useArticleService";

export function useCreateTestbuyTask() {
  const { call: createTask } = useCreateTask();
  const [loading, toggleLoading] = useToggle(false);
  const { push } = useWorkspacesAction();
  // @ts-ignore
  const avoidFirebase = window.callTestbuyService;

  const addFollowUpTaskId = avoidFirebase
    ? addFollowUpTaskIdPG
    : addFollowUpTaskIdFS;
  const onCreateClick = React.useCallback(
    async (task: TestBuyFollowUp, articleKey: string, testbuyId: string) => {
      toggleLoading();

      const createdTaskId = await createTask(task, task.images);
      await addFollowUpTaskId(testbuyId, articleKey, createdTaskId);
      toggleLoading();
      push(`/testbuy/${testbuyId}`);
    },
    [createTask, push, toggleLoading, addFollowUpTaskId]
  );
  return { loading, onCreateClick };
}
