import React from "react";
import { useNavigate } from "react-router-dom";
import { firebase, functions } from "@coworker/common/config/firebase";
import {
  IPublicClientApplication,
  AccountInfo,
  InteractionStatus,
  PublicClientApplication,
  InteractionRequiredAuthError,
  BrowserAuthError,
} from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import statusTypes from "@coworker/functions/src/enums/statusTypes.json";
import { logoutUser } from "../../services/user.service";
import { useLoggedInUser } from "./useLoggedInUser";
import { EXCHANGE_ACCESS_TOKEN } from "../../hooks/API/useCallFirebaseFunction";
import trackerHelper from "../../helpers/tracker";
import versionJson from "@coworker/functions/src/version.json";
import {
  msalConfiguration,
  tokenScopes,
} from "@coworker/apprestructured/src/assets/auth/msalConfig";
import { isChineseEnvironment } from "@coworker/reusable";
import { LOGIN_PATH, PRIVACY_PATH, LOGOUT_PATH } from "../../paths";
import { upsertUserByEmail } from "@coworker/apprestructured/src/login/hooks/useUpsertUserByEmail";

const AFTER_LOGIN_REDIRECT_URL_KEY = "fixa.afterLoginRedirectUrl";
const IS_CHINA = isChineseEnvironment();

type UserPurgeStatus = "None" | "InProgress" | "Success";
type MsalSignInStatus = "Initializing" | "Initialized";
type MsalTokenAcquisitionStatus = "None" | "InProgress" | "Done";
type FirebaseSignInStatus =
  | "None"
  | "Initializing"
  | "Initialized"
  | "InProgress"
  | "Success"
  | "Error";

type AuthState = {
  isAuthenticated: boolean;
  msalAccount: AccountInfo | null;
  msalSignInStatus: MsalSignInStatus;
  azureAccessToken: string | null;
  silentMsalTokenAcquisitionStatus: MsalTokenAcquisitionStatus;
  firebaseAuthUid: string | null;
  firebaseSignInStatus: FirebaseSignInStatus;
  userPurgeStatus: UserPurgeStatus;
  error: string | null;
};

type AuthReducerAction =
  | {
      type: "handleMsalSignInStateChange";
      payload: {
        msalAccount: AccountInfo | null;
      };
    }
  | {
      type: "handleAzureAccessTokenStateChange";
      payload:
        | {
            status: Extract<MsalTokenAcquisitionStatus, "InProgress">;
          }
        | {
            status: Extract<MsalTokenAcquisitionStatus, "Done">;
            azureAccessToken: string;
          };
    }
  | {
      type: "handleUserPurgeStateChange";
      payload: {
        status: Extract<UserPurgeStatus, "InProgress" | "Success">;
      };
    }
  | {
      type: "handleFirebaseSignInStateChange";
      payload:
        | {
            status: Extract<
              FirebaseSignInStatus,
              "Initializing" | "Initialized" | "InProgress"
            >;
          }
        | {
            status: Extract<FirebaseSignInStatus, "Success">;
            firebaseAuthUid: string;
          }
        | {
            status: Extract<FirebaseSignInStatus, "Error">;
            error: string;
          };
    }
  | {
      type: "handleAuthStateChange";
      payload: {
        isAuthenticated: boolean;
      };
    };

type AuthReducerDispatch = React.Dispatch<AuthReducerAction>;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  msalAccount: null,
  msalSignInStatus: "Initializing",
  azureAccessToken: null,
  silentMsalTokenAcquisitionStatus: "None",
  firebaseAuthUid: null,
  firebaseSignInStatus: "None",
  userPurgeStatus: "None",
  error: null,
};

function authReducer(state: AuthState, action: AuthReducerAction): AuthState {
  switch (action.type) {
    case "handleMsalSignInStateChange":
      const msalAccount = action.payload.msalAccount;
      return {
        ...state,
        msalAccount,
        msalSignInStatus: "Initialized",
        userPurgeStatus: msalAccount ? state.userPurgeStatus : "None",
      };
    case "handleAzureAccessTokenStateChange":
      const newAzureAccessTokenState: AuthState = {
        ...state,
        silentMsalTokenAcquisitionStatus: action.payload.status,
        azureAccessToken: null,
      };
      if (action.payload.status === "Done") {
        newAzureAccessTokenState.azureAccessToken =
          action.payload.azureAccessToken;
      }
      return newAzureAccessTokenState;
    case "handleUserPurgeStateChange":
      return {
        ...state,
        userPurgeStatus: action.payload.status,
        isAuthenticated: false,
      };
    case "handleFirebaseSignInStateChange":
      const newFirebaseSignInState: AuthState = {
        ...state,
        firebaseSignInStatus: action.payload.status,
        firebaseAuthUid: null,
        error: null,
      };
      if (action.payload.status === "Success") {
        newFirebaseSignInState.firebaseAuthUid = action.payload.firebaseAuthUid;
      } else if (action.payload.status === "Error") {
        newFirebaseSignInState.error = action.payload.error;
      }
      return newFirebaseSignInState;
    case "handleAuthStateChange":
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      };
    default:
      return state;
  }
}

export const msalPublicClientApplication = new PublicClientApplication(
  msalConfiguration
);

export const msalLogin = async () => {
  return await msalPublicClientApplication.loginRedirect();
};

export const msalLogout = async (instance: any) => {
  await instance.logout({
    postLogoutRedirectUri: `${window.location.origin}${LOGIN_PATH}`,
  });
};

export function useAuth() {
  const [authState, authReducerDispatch] = React.useReducer(
    authReducer,
    initialAuthState
  );
  const {
    instance: msalInstance,
    accounts: msalAccounts,
    inProgress: msalInteractionStatus,
  } = useMsal();

  const msalAccount = useAccount(msalAccounts[0] ?? {});
  const { setLoggedInUser, resetLoggedInUser } = useLoggedInUser();
  const navigate = useNavigate();
  const [userDataFromService, setUserDataFromService] = React.useState({});

  const handleAuthUserUpdate = React.useCallback(
    (userData: any) => {
      const profile = userData;
      if (profile && profile.status === statusTypes.ACTIVE) {
        setUserDataFromService(userData);
        setLoggedInUser(profile);
      } else {
        authReducerDispatch({
          type: "handleFirebaseSignInStateChange",
          payload: {
            status: "Error",
            error:
              "Login error.Profile is not active. Please contact your Store Super User.",
          },
        });
        throw new Error("Login error. Your Profile is not active.");
      }
    },
    [setLoggedInUser]
  );

  const fetchUserProfile = React.useCallback(async (email: string) => {
    return await upsertUserByEmail(email);
  }, []);

  React.useEffect(() => {
    if (authState.msalSignInStatus !== "Initialized") {
      return;
    }

    if (
      authState.msalAccount &&
      window.location.pathname.startsWith(LOGOUT_PATH)
    ) {
      // User initiated logout flow by clicking on the logout button. Pause state changes in useAuth
      // hook until logout is fully complete and the user is redirected to the login page.
      return;
    }

    if (!authState.msalAccount && authState.userPurgeStatus === "None") {
      handleUnauthenticatedUserCoreService(
        authReducerDispatch,
        resetLoggedInUser
      );
      return;
    }

    if (
      !authState.msalAccount &&
      authState.userPurgeStatus === "Success" &&
      !(
        window.location.pathname.startsWith(LOGIN_PATH) ||
        window.location.pathname.startsWith(PRIVACY_PATH)
      )
    ) {
      saveAfterLoginRedirectUrl();
      navigate(LOGIN_PATH);
      return;
    }

    if (
      authState.msalAccount &&
      !authState.azureAccessToken &&
      authState.silentMsalTokenAcquisitionStatus === "None"
    ) {
      acquireMsalTokenSilent(
        authReducerDispatch,
        msalInstance,
        authState.msalAccount,
        msalInteractionStatus
      );
      return;
    }

    if (authState.msalAccount && authState.azureAccessToken) {
      if (
        !IS_CHINA &&
        !authState.firebaseAuthUid &&
        authState.firebaseSignInStatus === "Initialized"
      ) {
        signInToFirebase(authReducerDispatch, authState.azureAccessToken);
        return;
      }

      if (IS_CHINA || (authState.firebaseAuthUid && !IS_CHINA)) {
        if (authState.firebaseSignInStatus !== "Error") {
          if (window.location.pathname.startsWith(LOGIN_PATH)) {
            const afterLoginRedirectUrl =
              sessionStorage.getItem(AFTER_LOGIN_REDIRECT_URL_KEY) || "/";
            sessionStorage.removeItem(AFTER_LOGIN_REDIRECT_URL_KEY);
            navigate(afterLoginRedirectUrl);
            return;
          }
        }
        // Edge cases we have username has firstname.lastname@ikea.com, myIdentity is working on to fix the upn, so it is a temporary fix
        let email =
          authState.msalAccount.idTokenClaims?.preferred_username ??
          authState.msalAccount.username;
        const isEmailWithOnlyIkeaDomain =
          email.split("@")[1]?.toLowerCase() === "ikea.com";
        // Exclude inter users
        if (isEmailWithOnlyIkeaDomain) {
          email = email.replace("ikea", "ingka.ikea");
        }

        if (
          !authState.isAuthenticated &&
          authState.firebaseSignInStatus !== "Error"
        ) {
          fetchUserProfile(email)
            .then((response) => {
              if (response === "User not found") {
                return Promise.reject(new Error("User not found"));
              } else {
                return handleAuthUserUpdate(response);
              }
            })
            .then(() => {
              trackerHelper.trackLogin();
              authReducerDispatch({
                type: "handleAuthStateChange",
                payload: {
                  isAuthenticated: true,
                },
              });
            })
            .catch((error: any) => {
              console.error("fetch user profile", error?.message);
              //if user is not found in graphAPI 404 NotFoundError
              // if user is not store user, core -service will through 403 forbidden error
              let errorMessage = error?.message;
              if (error.message.includes("User not found")) {
                errorMessage =
                  "You do not have your profile created. Please contact your Store Super User.";
              }
              authReducerDispatch({
                type: "handleFirebaseSignInStateChange",
                payload: {
                  status: "Error",
                  error: errorMessage,
                },
              });
              navigate("/");
            });
        } else {
          return userDataFromService as any;
        }
      }
    }
  }, [
    authState,
    resetLoggedInUser,
    msalInstance,
    handleAuthUserUpdate,
    navigate,
    fetchUserProfile,
    userDataFromService,
    msalInteractionStatus,
  ]);

  React.useEffect(() => {
    if (msalInteractionStatus === InteractionStatus.None) {
      authReducerDispatch({
        type: "handleMsalSignInStateChange",
        payload: { msalAccount },
      });
    }
  }, [msalInteractionStatus, msalAccount]);

  React.useEffect(() => {
    if (!authState.msalAccount || IS_CHINA) {
      return;
    }
    authReducerDispatch({
      type: "handleFirebaseSignInStateChange",
      payload: {
        status: "Initializing",
      },
    });
    return firebase.auth().onAuthStateChanged(
      (firebaseUser) => {
        if (
          firebaseUser?.uid &&
          authState.msalAccount?.idTokenClaims?.oid === firebaseUser.uid
        ) {
          authReducerDispatch({
            type: "handleFirebaseSignInStateChange",
            payload: {
              status: "Success",
              firebaseAuthUid: firebaseUser.uid,
            },
          });
        } else {
          authReducerDispatch({
            type: "handleFirebaseSignInStateChange",
            payload: {
              status: "Initialized",
            },
          });
        }
      },
      (error) => {
        authReducerDispatch({
          type: "handleFirebaseSignInStateChange",
          payload: {
            status: "Error",
            error: error.message,
          },
        });
        navigate("/");
      }
    );
    //
  }, [authState.msalAccount, navigate]);

  const loginRedirect = React.useCallback(async () => {
    await msalInstance.loginRedirect({
      scopes: tokenScopes,
    });
  }, [msalInstance]);

  const msalLogout = React.useCallback(async () => {
    await msalInstance.logout({
      postLogoutRedirectUri: `${window.location.origin}${LOGIN_PATH}`,
    });
  }, [msalInstance]);

  const cleanUpSignInStatus = React.useCallback(async () => {
    return await msalInstance.handleRedirectPromise();
  }, [msalInstance]);

  return {
    isAuthenticated: authState.isAuthenticated,
    isAuthInProgress: isAuthInProgress(authState),
    authError: authState.error,
    loginRedirect,
    msalLogout,
    cleanUpSignInStatus,
  };
}

function saveAfterLoginRedirectUrl() {
  sessionStorage.removeItem(AFTER_LOGIN_REDIRECT_URL_KEY);
  const afterLoginRedirectUrl = window.location.href.slice(
    window.location.origin.length
  );
  if (
    afterLoginRedirectUrl.length > 1 &&
    !afterLoginRedirectUrl.startsWith(LOGOUT_PATH)
  ) {
    sessionStorage.setItem(AFTER_LOGIN_REDIRECT_URL_KEY, afterLoginRedirectUrl);
  }
}

/*
async function handleUnauthenticatedUser(
  authReducerDispatch: AuthReducerDispatch,
  resetLoggedInUser: () => void
) {
  console.log("handleUnauthenticatedUser");
  authReducerDispatch({
    type: "handleUserPurgeStateChange",
    payload: {status: "InProgress"},
  });
  resetLoggedInUser();
  await logoutUser(false);
  authReducerDispatch({
    type: "handleUserPurgeStateChange",
    payload: {status: "Success"},
  });
}
*/

async function handleUnauthenticatedUserCoreService(
  authReducerDispatch: AuthReducerDispatch,
  resetLoggedInUser: () => void,
  msalInstance?: IPublicClientApplication
) {
  authReducerDispatch({
    type: "handleUserPurgeStateChange",
    payload: { status: "InProgress" },
  });
  resetLoggedInUser();
  if (msalInstance) {
    await msalInstance.logout({
      postLogoutRedirectUri: `${window.location.origin}${LOGIN_PATH}`,
    });
  }
  if (!IS_CHINA) {
    await logoutUser(false);
  }
  authReducerDispatch({
    type: "handleUserPurgeStateChange",
    payload: { status: "Success" },
  });
}

async function acquireMsalTokenSilent(
  authReducerDispatch: AuthReducerDispatch,
  msalInstance: IPublicClientApplication,
  msalAccount: AccountInfo,
  msalInteractionStatus: InteractionStatus
) {
  console.log("acquireMsalTokenSilently");
  try {
    authReducerDispatch({
      type: "handleAzureAccessTokenStateChange",
      payload: { status: "InProgress" },
    });
    const msalResponse = await msalInstance.acquireTokenSilent({
      account: msalAccount,
      scopes: tokenScopes,
    });
    authReducerDispatch({
      type: "handleAzureAccessTokenStateChange",
      payload: { status: "Done", azureAccessToken: msalResponse.accessToken },
    });
  } catch (error: any) {
    saveAfterLoginRedirectUrl();
    if (error instanceof InteractionRequiredAuthError) {
      if (msalInteractionStatus === InteractionStatus.None) {
        try {
          await msalInstance.acquireTokenRedirect({
            scopes: tokenScopes,
            redirectUri: window.location.origin + LOGIN_PATH,
          });
        } catch (error) {
          throw new Error(`Error msal token redirect ${error}`);
        }
      } else {
        throw new Error(`Msal- Interaction already in progress ${error}`);
      }
    } else if (error instanceof BrowserAuthError) {
      throw new Error(`Msal browser auth error ${error}`);
    } else {
      throw error;
    }
  }
}

async function signInToFirebase(
  authReducerDispatch: AuthReducerDispatch,
  azureAccessToken: string
) {
  authReducerDispatch({
    type: "handleFirebaseSignInStateChange",
    payload: { status: "InProgress" },
  });
  try {
    const exchangeTokenResponse = await functions.httpsCallable(
      EXCHANGE_ACCESS_TOKEN
    )({
      azureAccessToken,
      _clientVersion: versionJson.version,
    });
    const firebaseUserCredential = await firebase
      .auth()
      .signInWithCustomToken(exchangeTokenResponse.data.firebaseCustomToken);

    if (!firebaseUserCredential.user?.uid) {
      throw new Error("Failed to get Firebase auth user");
    }
  } catch (error: any) {
    authReducerDispatch({
      type: "handleFirebaseSignInStateChange",
      payload: {
        status: "Error",
        error: error?.message,
      },
    });
  }
}

function isAuthInProgress(authState: AuthState) {
  return (
    authState.msalSignInStatus === "Initializing" ||
    authState.firebaseSignInStatus === "Initializing" ||
    authState.firebaseSignInStatus === "InProgress" ||
    authState.userPurgeStatus === "InProgress"
  );
}
