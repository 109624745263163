import React, { useState } from "react";
import Search from "@ingka/search";
import { Articles } from "../types/article";
import { SearchBarContainer } from "../styles/styles";
import { useTranslation } from "@coworker/locales";

interface SearchBarProps {
  articles: Articles;
  onSearchResults: (results: Articles) => void;
}

export const SearchBar = ({ articles, onSearchResults }: SearchBarProps) => {
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();

  const handleSearch = (e: InputEvent) => {
    if (!articles) return;

    const searchInput = (e.target as HTMLInputElement).value;

    setSearchInput((e.target as HTMLInputElement).value);
    onSearchResults(
      articles.filter(
        (article) =>
          article.itemName.toLowerCase().includes(searchInput.toLowerCase()) ||
          article.itemType.toLowerCase().includes(searchInput.toLowerCase()) ||
          article.itemColor.toLowerCase().includes(searchInput.toLowerCase()) ||
          article.productArticleId
            .toLowerCase()
            .includes(searchInput.toLowerCase())
      )
    );
  };

  const handleClear = () => {
    setSearchInput("");
    onSearchResults(articles);
  };

  return (
    <SearchBarContainer>
      <Search
        id="article-search"
        value={searchInput}
        onChange={(e: InputEvent) => {
          handleSearch(e);
        }}
        onClear={handleClear}
        placeholder={t("searchBasicString")}
      />
    </SearchBarContainer>
  );
};
