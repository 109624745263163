const StyleGroupOptions = [
  "Scandinavian modern",
  "Scandinavian traditional",
  "International modern",
  "International traditional",
];

export function getStyleGroupOptions() {
  return StyleGroupOptions.map((value) => ({
    value,
    name: value,
  }));
}
