import React from "react";
import { ProfileSectionItem } from "../../../types/profileSectionType";
import { ProfileSectionListView } from "./ProfileSectionListView";
import { ProfileSectionHeader } from "./ProfileSectionHeader";
import {
  useEmail,
  usePhone,
} from "@coworker/app/src/core/auth/useLoggedInUser";
import { useMyStore } from "@coworker/app/src/hooks/useMyStore";
import { useStoreContactsEnabled } from "@coworker/app/src/hooks/useStoreContacts";
import { useTranslation } from "@coworker/locales";
import { useMyTeam } from "@coworker/app/src/core/hooks/useStoreTeams";
// @ts-ignore
import { versionFixa } from "@coworker/app/src/components/Profile/versionFixa";
import { useUserPreference } from "@coworker/app/src/hooks/useProfilePreferencesQuery";
import { languageMapper } from "../../../helpers/profileLanguageHelper";

export const ProfileSections: React.FC = () => {
  const { t } = useTranslation();
  const isStoreContactsEnabled = useStoreContactsEnabled();
  const email = useEmail();
  const phone = usePhone();
  const store = useMyStore();
  const storeName = store?.name ?? "";
  const storeConfiguration = store?.configuration;
  const [userLanguage] = useUserPreference(
    "language",
    storeConfiguration?.locale?.primary_locale
  );
  const myTeam = useMyTeam();
  const teamName = myTeam?.name ?? "";
  const languageName = languageMapper(userLanguage).name;

  const teamSection: ProfileSectionItem[] = [
    {
      id: "team",
      title: "teamString",
      description: teamName,
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/team",
    },
  ];

  const profileSections: ProfileSectionItem[] = [
    {
      id: "email",
      title: "emailString",
      description: email,
      isNavigational: false,
      isTitleBold: true,
    },
    {
      id: "storeName",
      title: "storeNameString",
      description: storeName,
      isNavigational: false,
      isTitleBold: true,
    },
  ];

  const storeContactsSection: ProfileSectionItem[] = [
    {
      id: "updateStoreContact",
      title: phone ? "updateContactString" : "addContactString",
      description: phone ?? t("noneString"),
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/phone",
    },
    {
      id: "storeContacts",
      title: "storeString",
      description: "Find all store contacts",
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/contacts",
    },
  ];

  const allProfileSections: ProfileSectionItem[] = isStoreContactsEnabled
    ? [...teamSection, ...storeContactsSection, ...profileSections]
    : [...teamSection, ...profileSections];

  const settingsSection: ProfileSectionItem[] = [
    {
      id: "updateLanguage",
      title: "updateLanguageString",
      description: languageName,
      isNavigational: true,
      isTitleBold: true,
      navigationUrl: "/language",
    },
    {
      id: "notifications",
      title: "notificationsSettingsString",
      isNavigational: true,
      isTitleBold: false,
      navigationUrl: "/notifications",
    },
  ];

  const helpSection: ProfileSectionItem[] = [
    {
      id: "appSupport",
      title: "supportString",
      isNavigational: true,
      navigationUrl: "/support",
      isTitleBold: false,
    },
    {
      id: "privacyPolicy",
      title: "privacyString",
      isNavigational: true,
      navigationUrl: "/privacy",
      isTitleBold: false,
    },
    {
      id: "version",
      title: "versionString",
      description: versionFixa(),
      isNavigational: false,
      isTitleBold: true,
    },
  ];

  return (
    <div>
      <ProfileSectionHeader title={t("profileString")}></ProfileSectionHeader>
      <ProfileSectionListView
        id="profile"
        sectionsList={allProfileSections}
      ></ProfileSectionListView>
      <ProfileSectionHeader title={t("settingsString")}></ProfileSectionHeader>
      <ProfileSectionListView
        id="settings"
        sectionsList={settingsSection}
      ></ProfileSectionListView>
      <ProfileSectionHeader title={t("supportString")}></ProfileSectionHeader>
      <ProfileSectionListView
        id="help"
        sectionsList={helpSection}
      ></ProfileSectionListView>
    </div>
  );
};
