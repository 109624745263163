import React from "react";
import dayjs from "dayjs";
import { useToggle } from "../../../hooks/useToggle";
import { Trans, useTranslation } from "@coworker/locales";
import {
  useStoreId,
  useUserId,
  useCountryId,
} from "../../../core/auth/useLoggedInUser";
import { useMyStore } from "../../../hooks/useMyStore";
import { useUserPreference } from "../../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences.json";
import "@ingka/inline-message/dist/style.css";
import InlineMessage from "@ingka/inline-message";
import informationCircle from "@ingka/ssr-icon/paths/information-circle";
import {
  areaIsWholeStore,
  defaultFilters,
  describeAreaFilter,
  describeDivision,
  getAreaFilter,
  getDivisionFilter,
  getPeriodFilter,
} from "../../InputPopup/UserFiltersRow/filterChoices";
import useCallInternal, {
  callInternal,
  TESTBUY_NEW,
  GET_DIVISIONS,
  ACES_AND_KING_COUNTRIES,
} from "../../../hooks/API/useCallInternal";
import { CreateNewTestBuyApiResponse } from "@coworker/types/lib/testbuy_api";
import { useWorkspacesAction } from "../../../hooks/useWorkspaces";
import { AMOUNTS, DEFAULT_AMOUNT } from "../constants";
import { ContentWrapper } from "../CommonComponents/layout";
import { useToastNotification } from "@coworker/components";
import { WARNING_TOAST } from "@coworker/components/src/components/ToastNotification";
//@ts-ignore
import { FLAGS } from "@coworker/functions/src/enums/featureFlags";
import { ToggleField } from "../../../core/components/widgets/ToggleField";
import styled from "styled-components";
import { PillSelection } from "../../../core/components/widgets/PillSelection";
import { ArticleSelection } from "../../../core/components/widgets/ArticleSelection";
import {
  AreaSelection,
  useAreaSelectionPopup,
} from "../../../core/components/widgets/AreaSelection";
import { useNumberOfArticles as useNumberOfArticlesFS } from "../CommonComponents/Repositories/article.repository.service";
import { useNumberOfArticles as useNumberOfArticlesPG } from "../CommonComponents/Repositories/useArticleService";
import { ARTICLE_HASH } from "../../TestBuy/constants";
import useFlag from "../../../hooks/useFlag";
import { countNumbersOfArticles } from "./NewTestBuyForm.helper";
import {
  TypeOfArticle,
  TypesOfArticles,
  Division,
  TestBuyId,
} from "@coworker/types";
import { saveChosenDivision } from "../../InputPopup/UserFiltersRow/Divisions/divisions.helpers";
import { postToTestbuyService } from "../../../hooks/API/testbuy.service";
import { isChineseEnvironment } from "@coworker/reusable";
import FullScreenPopup from "@coworker/apprestructured/src/layout/components/FullScreenPopup/FullScreenPopup";
import Button from "@ingka/button";

const { TESTBUY_PREFIX, USER_FILTERS } = profilePreferences;

const WidgetRowWrapper = styled.div`
  width: 100%;
  box-shadow: 0 1px var(--grey200);
`;

const ButtonContainer = styled.div`
  padding: 24px;
`;

const InlineMessageWrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
`;

export function NewTestBuyForm() {
  const storeId: string = useStoreId();
  const uid = useUserId();
  const countryId = useCountryId();
  const store = useMyStore();
  const storeConfiguration = store?.configuration || {};
  const isChina = isChineseEnvironment();
  const { t } = useTranslation();
  const [userLanguage] = useUserPreference(
    profilePreferences.LANGUAGE,
    storeConfiguration.locale?.primary_locale
  );
  const enableDivisionsArea = useFlag(FLAGS.DIVISIONS_IN_FILTER);

  const [filters, setFilters] = useUserPreference(
    `${TESTBUY_PREFIX}${USER_FILTERS}`,
    defaultFilters
  );

  const [area, setArea] = React.useState(getAreaFilter(filters));
  const [division, setDivision] = React.useState(
    getDivisionFilter(filters) ?? ""
  );
  const [randomHFBs, setRandomHFBs] = React.useState<string[]>([]);
  // @ts-ignore
  const avoidFirebase = window.callTestbuyService;
  const useNumberOfArticles = avoidFirebase
    ? useNumberOfArticlesPG
    : useNumberOfArticlesFS;
  const { numberOfArticlesForArea, loading } = useNumberOfArticles(
    storeId,
    area ?? ""
  );
  // Local State

  const DEFAULT_TYPE_OF_ARTICLES = ARTICLE_HASH.ACESANDKING;

  const [typeOfArticles, setTypeOfArticles] = React.useState(
    DEFAULT_TYPE_OF_ARTICLES
  );

  const [numberOfArticles, setNumberOfArticles] =
    React.useState(DEFAULT_AMOUNT);
  const [newAmountOfArticles, setNewAmountOfArticles] = React.useState(0);
  const [includeOutgoing, toggleIncludeOutgoing] = useToggle(true);
  const [includeOutOfStock, toggleIncludeOutOfStock] = useToggle(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // Input popups
  const { openAreaSelectionPopup } = useAreaSelectionPopup();

  const { data } = useCallInternal(
    GET_DIVISIONS,
    {
      storeId: storeId,
    },
    {
      blockRequest: isChina,
    }
  );
  const divisions = data as Division[];

  const { data: acesAndKingCountries } = useCallInternal(
    ACES_AND_KING_COUNTRIES,
    {},
    {
      blockRequest: isChina,
    }
  );

  const onAreaSelected = async (selectedArea: any) => {
    if (typeof selectedArea === "object") {
      const { item, type } = selectedArea;
      const { hfbs } = saveChosenDivision(divisions, item, type);
      setRandomHFBs(hfbs);

      const selectedAreaInDivision = areaIsWholeStore(selectedArea.item)
        ? "whole=whole"
        : selectedArea.item;

      setArea(selectedAreaInDivision);

      setFilters([
        getPeriodFilter(filters)!,
        selectedArea.item,
        selectedArea.type,
      ]);
      setDivision(selectedArea.type);
    } else {
      setArea(selectedArea);
      setFilters([getPeriodFilter(filters)!, selectedArea, ""]);
    }
  };
  const onTypeOfArticlesSelected = async (selectedTypeOfArticles: {
    type: TypesOfArticles;
    transKey: string;
  }) => {
    setTypeOfArticles(selectedTypeOfArticles);
    setArea(
      isChina && selectedTypeOfArticles.type === "ACESANDKING"
        ? "whole=whole"
        : getAreaFilter(filters)
    );
  };

  React.useEffect(() => {
    if (isChina) setArea("whole=whole");
  }, [setArea, isChina]);

  React.useEffect(() => {
    countNumbersOfArticles({
      loading,
      newAmountOfArticles,
      numberOfArticlesForArea,
      typeOfArticles: typeOfArticles as TypeOfArticle,
      setNumberOfArticles,
    });
  }, [loading, newAmountOfArticles, numberOfArticlesForArea, typeOfArticles]);

  const wholeArea = area === "whole=whole";

  const onNumberOfArticlesSelected = async (selectedValue: number) => {
    if (selectedValue) {
      setNumberOfArticles(selectedValue);
      setNewAmountOfArticles(selectedValue);
      // After selecting number of articles, immediately open the area input if whole store is selected.
      if (wholeArea && typeOfArticles?.type === ARTICLE_HASH.RANDOM?.type) {
        await openAreaSelectionPopup({
          selected: selectedValue,
          onConfirm: onAreaSelected,
        });
      }
    }
  };
  // Submit
  const { push } = useWorkspacesAction();
  const { showToast } = useToastNotification();
  const onSubmitToPG = React.useCallback(async () => {
    if (typeOfArticles?.type !== "CUSTOM" && area) {
      const createBody = {
        numberOfArticles,
        area,
        storeId,
        includeOutgoing,
        includeOutOfStock,
        createdAt: dayjs().format("YYYY-MM-DD"),
        createdBy: uid,
        typeOfArticles: typeOfArticles?.type,
        area_new:
          enableDivisionsArea && areaIsWholeStore(area) ? randomHFBs : [area],
        divisionId: division,
        locale: userLanguage,
        countryId,
      };
      try {
        setIsLoading(true);
        await postToTestbuyService(
          "testbuy/create_testbuy",
          createBody,
          (createTestbuyRes: TestBuyId) => {
            setIsLoading(false);
            if (createTestbuyRes.testBuyId) {
              push(`/testbuy/${createTestbuyRes.testBuyId}`);
            } else {
              const message =
                data?.message === "INSUFFICIENT_ARTICLES" ? (
                  <Trans>insufficientArticles</Trans>
                ) : (
                  <Trans>searchErrorString</Trans>
                );
              showToast(message, undefined, undefined, 5, WARNING_TOAST);
            }
          }
        );
      } catch (error) {
        setIsLoading(false);
        const message =
          error instanceof Error &&
          error.message === "INSUFFICIENT_ARTICLES" ? (
            <Trans>insufficientArticles</Trans>
          ) : (
            <Trans>searchErrorString</Trans>
          );
        showToast(message, undefined, undefined, 5, WARNING_TOAST);
      }
    }
    if (typeOfArticles?.type === "CUSTOM") {
      push(`/testbuy/custom`);
    }
  }, [
    area,
    includeOutgoing,
    numberOfArticles,
    push,
    showToast,
    storeId,
    uid,
    includeOutOfStock,
    typeOfArticles?.type,
    enableDivisionsArea,
    division,
    randomHFBs,
    userLanguage,
    countryId,
    data?.message,
    setIsLoading,
  ]);

  const onSubmitToFS = React.useCallback(async () => {
    if (typeOfArticles?.type !== "CUSTOM" && area) {
      try {
        setIsLoading(true);
        const res: CreateNewTestBuyApiResponse = await callInternal(
          TESTBUY_NEW,
          {
            numberOfArticles,
            area,
            storeId,
            includeOutgoing,
            includeOutOfStock,
            createdAt: dayjs().format("YYYY-MM-DD"),
            createdBy: uid,
            typeOfArticles: typeOfArticles?.type,
            area_new:
              enableDivisionsArea && areaIsWholeStore(area)
                ? randomHFBs
                : [area],
            divisionId: division,
            countryId,
            locale: userLanguage,
          }
        );

        if (res.data.testBuyId) {
          setIsLoading(false);
          push(`/testbuy/${res.data.testBuyId}`);
        }
      } catch (error) {
        setIsLoading(false);
        const message =
          error instanceof Error &&
          error.message === "INSUFFICIENT_ARTICLES" ? (
            <Trans>insufficientArticles</Trans>
          ) : (
            <Trans>searchErrorString</Trans>
          );
        showToast(message, undefined, undefined, 5, WARNING_TOAST);
      }
    } else if (typeOfArticles?.type === "CUSTOM") {
      push(`/testbuy/custom`);
    }
  }, [
    area,
    includeOutgoing,
    numberOfArticles,
    push,
    showToast,
    storeId,
    uid,
    includeOutOfStock,
    typeOfArticles?.type,
    enableDivisionsArea,
    division,
    randomHFBs,
    setIsLoading,
    countryId,
    userLanguage,
  ]);

  function getAreaDescription(
    selectedArea: string | undefined,
    selectedDivision: string | undefined
  ) {
    if (selectedArea && selectedDivision && areaIsWholeStore(area)) {
      return <Trans>{describeDivision(selectedDivision)}</Trans>;
    } else if (area) {
      return describeAreaFilter([area], true);
    } else {
      return <Trans>selectAreaString</Trans>;
    }
  }

  const noAcesAndKingData =
    isChina ||
    (Array.isArray(acesAndKingCountries) &&
      acesAndKingCountries?.includes(countryId)) ||
    false;

  return (
    <FullScreenPopup
      appBarConfig={{
        title: t("testBuyString"),
        actions: [],
      }}
      noPadding
    >
      <ContentWrapper>
        {typeOfArticles && (
          <WidgetRowWrapper>
            <ArticleSelection
              typeOfArticles={typeOfArticles}
              onConfirm={onTypeOfArticlesSelected}
            />
          </WidgetRowWrapper>
        )}
        {!isChina && (
          <WidgetRowWrapper>
            <AreaSelection
              title={<Trans>areaString</Trans>}
              selected={getAreaDescription(area, division)}
              onConfirm={onAreaSelected}
            />
          </WidgetRowWrapper>
        )}
        {typeOfArticles?.type !== ARTICLE_HASH.CUSTOM?.type && (
          <>
            <WidgetRowWrapper>
              <PillSelection
                title={<Trans>numberOfArticlesString</Trans>}
                choices={AMOUNTS}
                selected={numberOfArticles}
                onConfirm={onNumberOfArticlesSelected}
                closePopupWhenSelected={
                  typeOfArticles?.type === ARTICLE_HASH.ACESANDKING?.type ||
                  (!wholeArea &&
                    typeOfArticles?.type === ARTICLE_HASH.RANDOM?.type)
                }
              />
            </WidgetRowWrapper>
            <WidgetRowWrapper>
              <ToggleField
                title={
                  <b>
                    <Trans>includeEDSArticlesString</Trans>
                  </b>
                }
                onChange={toggleIncludeOutgoing}
                value={includeOutgoing}
              />
            </WidgetRowWrapper>
            <WidgetRowWrapper>
              <ToggleField
                title={
                  <b>
                    <Trans>includeOutOfStockString</Trans>
                  </b>
                }
                onChange={toggleIncludeOutOfStock}
                value={includeOutOfStock}
              />
            </WidgetRowWrapper>
          </>
        )}
      </ContentWrapper>
      {typeOfArticles?.type === ARTICLE_HASH.ACESANDKING?.type &&
      !noAcesAndKingData ? (
        <InlineMessageWrapper>
          <InlineMessage
            title={<Trans>noAcesAndKingDataTitleString</Trans>}
            body={<Trans>noAcesAndKingDataBodyString</Trans>}
            ssrIcon={informationCircle}
            variant={"negative"}
          />
        </InlineMessageWrapper>
      ) : (
        <ButtonContainer>
          <Button
            type={"primary"}
            text={
              typeOfArticles?.type !== "CUSTOM" ? (
                <Trans>createTestbuyString</Trans>
              ) : (
                <Trans>continue2String</Trans>
              )
            }
            disabled={
              !area ||
              (areaIsWholeStore(area) &&
                !division &&
                typeOfArticles?.type === ARTICLE_HASH.RANDOM?.type) ||
              (areaIsWholeStore(area) &&
                !division &&
                typeOfArticles?.type === ARTICLE_HASH.CUSTOM?.type)
            }
            onClick={avoidFirebase ? onSubmitToPG : onSubmitToFS}
            data-testid="start-test-buy"
            fluid={true}
            loading={isLoading}
          />
        </ButtonContainer>
      )}
    </FullScreenPopup>
  );
}
