import React from "react";
import styled from "styled-components";
import { SkapaSwitch } from "../../SkapaSwitch";
import Instructions from "../../Instructions";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { Trans } from "@coworker/locales";
import taskStateOptions from "@coworker/functions/src/enums/taskState.json";
import { useFixaUID, useUID } from "../../../core/auth/useLoggedInUser";
import trackerHelper from "../../../helpers/tracker";

const Border = styled.div`
  background: var(--grey150);
  height: 10px;
`;
const DetailSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;
const DetailSectionTop = styled.div`
  margin: 0 29px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const DetailSectionBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Picking({ task, picking, setPicking }) {
  const handleToggle = () => {
    trackerHelper.task.trackCompletePickingOnlyToggle(!picking);
    setPicking(!picking);
  };
  const uid = useUID();
  const fixaUid = useFixaUID();

  return (
    !task.picked &&
    task.state === taskStateOptions.ASSIGNED &&
    (task.assigned_user_id === uid || task.assigned_user_id === fixaUid) && (
      <>
        <Border />
        <DetailSection data-testid="pickingSection">
          <DetailSectionTop>
            <Trans>completePickingString</Trans>
            <SkapaSwitch
              testId="pickingToggle"
              checked={picking}
              onChange={handleToggle}
            />
          </DetailSectionTop>
          <DetailSectionBottom>
            {
              <Instructions
                title={<Trans>pickingOnlyString</Trans>}
                text={<Trans>pickingOnlyTextString</Trans>}
                type={profilePreferences.DISABLE_POPUPS_PICKING}
              />
            }
          </DetailSectionBottom>
        </DetailSection>
        <Border />
      </>
    )
  );
}

export default Picking;
