import React from "react";
import useLastAssignedTeam from "../../../hooks/useLastAssignedTeam";
import { useUserId } from "../../../core/auth/useLoggedInUser";
import { AssigneeSelection } from "../../../core/components/widgets/AssigneeSelection";
import trackerHelper from "../../../helpers/tracker";

type Assignee = {
  gid?: string | undefined;
  uid?: string | undefined;
};

type ValueProp = {
  gid?: string | undefined;
  uid?: string | undefined;
  productId: string;
  refilled_by_me: boolean;
};

interface TaskFormAssignProps {
  value: ValueProp;
  onChange: ({ gid, uid }: Assignee) => null;
  conf: {
    icon: object;
    deactivated: boolean;
    testId: string;
  };
  additionalSubTitle?: JSX.Element | undefined;
}

/**
 * @deprecated Perhaps prefer AssignToWidget
 */
export default function TaskFormAssign({
  value,
  onChange,
  conf,
  additionalSubTitle,
}: TaskFormAssignProps) {
  const lastAssignResult = useLastAssignedTeam(value.productId);
  const gidBestGuess = lastAssignResult?.data?.id;
  const profileUid = useUserId();

  const { icon, deactivated, testId } = conf;
  const { gid, uid, refilled_by_me } = value;

  React.useEffect(() => {
    // Auto-assign only if assignment was not already made.
    // If gid is already set then we do not overwrite, and instead ignore the fetched best guess.
    if (!gid && gidBestGuess) {
      trackerHelper.taskEfficiency.trackTeamChange();
      onChange({ gid: gidBestGuess });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gidBestGuess]);

  return (
    <AssigneeSelection
      tabIndex={0}
      selectedTeamId={gid}
      selectedUserId={refilled_by_me ? profileUid : uid}
      onConfirm={onChange}
      image={icon}
      testId={testId ? testId : "assignWidget"}
      disabled={deactivated}
      shortId={value.productId}
      additionalSubTitle={
        !value.gid || !value.uid || !value.refilled_by_me ? (
          additionalSubTitle
        ) : (
          <></>
        )
      }
    />
  );
}
