import { TaskListingTypes } from "../constants";
import { OngoingTaskLinksResponse } from "../types/taskLink";
import { useTaskLinksByRoom } from "./useTaskLinksByRoom";
import { useTaskLinks } from "./useTasksByTaskLinks";

export function useTasksByRoom(
  roomId: string | undefined
): OngoingTaskLinksResponse {
  const { data: taskLinks } = useTaskLinksByRoom(roomId || "");
  const tasksResponse = useTaskLinks(taskLinks || [], TaskListingTypes.Ongoing);
  return tasksResponse;
}
