import { TestBuyProductWithCriteria } from "@coworker/types";
import React from "react";
import { partitionArticlesByStatus } from "../../CommonComponents/article.helpers";
import { LostPassedSalesWidget } from "../../CommonComponents/LostPassedSalesWidget";
import { ArticleList } from "../ArticleList";
import ArticlesHeader from "./ArticlesHeader";
import { WidgetDivider } from "@coworker/reusable/components/Widgets";

interface HistoricDetailsProps {
  testbuyId: string;
  articles: TestBuyProductWithCriteria[];
}

export function HistoricDetails({ testbuyId, articles }: HistoricDetailsProps) {
  const { SUCCESS, FAIL } = partitionArticlesByStatus(articles);
  const currency = [...SUCCESS, ...FAIL][0]?.currency || "";

  return (
    <>
      <LostPassedSalesWidget
        isSingleTestbuy
        currency={currency}
        lostSales={FAIL.reduce((acc, curr) => acc + Math.trunc(curr.price), 0)}
        passedSales={SUCCESS.reduce(
          (acc, curr) => acc + Math.trunc(curr.price),
          0
        )}
      />
      <WidgetDivider />
      <ArticlesHeader count={FAIL.length} type="FAIL" />
      <ArticleList
        showAsCompleted
        articles={FAIL}
        testbuyId={testbuyId}
        testId="failedArticles"
      />
      <WidgetDivider />
      <ArticlesHeader count={SUCCESS.length} type="SUCCESS" />
      <ArticleList
        showAsCompleted
        articles={SUCCESS}
        testbuyId={testbuyId}
        testId="successfulArticles"
      />
    </>
  );
}
