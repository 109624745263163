import React from "react";
import styled from "styled-components";
import { ReactComponent as OpenIcon } from "../assets/svg/arrow-small-forward.svg";
import { ElidedText } from "@coworker/reusable/components/ElidedText";

const Image = styled.img`
  align-self: flex-start;
  width: 73px;
  height: 73px;
  min-width: 73px;
  min-height: 73px;
  margin-right: 20px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
  background: var(--white);
  min-height: 110px;
  padding: 15px 36px 0px 28px;
  ${({ border }) => border && `border-top: 1px solid var(--grey150);`}
`;

const TitleContent = styled.div`
  min-height: 43px;
`;

const ItemHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
`;
const Title = styled.p`
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
`;
const Description = styled.div`
  color: #484848;
  font-size: 14px;
`;

const MainContent = styled.div`
  display: flex;
  align-items: center;
`;

const WrappedOpenIcon = styled(OpenIcon)`
  margin-right: -8px;
`;

const ProductRow = ({
  image,
  title,
  description,
  formattedNumber,
  hideArrow,
  onClick,
  children,
  testId,
  border = true,
}) => {
  return (
    <Content
      onClick={onClick}
      border={border}
      data-testid={testId || `productRow-${formattedNumber}`}
    >
      <MainContent>
        <Image src={image} />
        <TitleContent>
          <ItemHead>
            <Title>{title}</Title>
          </ItemHead>
          <Description>
            <ElidedText>{description}</ElidedText>
          </Description>
          <Description>{formattedNumber}</Description>
          {children}
        </TitleContent>
      </MainContent>
      {!hideArrow && <WrappedOpenIcon />}
    </Content>
  );
};

export default ProductRow;
