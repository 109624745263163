import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteGroupById } from "../services/groups.service";
import React from "react";

export const useDeleteGroupById = () => {
  const queryClient = useQueryClient();
  const [storedRoomId, setStoredRoomId] = React.useState<string>("");

  return useMutation({
    mutationFn: ({ groupId, roomId }: { groupId: string; roomId: string }) => {
      setStoredRoomId(roomId);
      return deleteGroupById(groupId);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["groupsByRoom", storedRoomId],
      });
    },
  });
};
