import { useQuery } from "@tanstack/react-query";
import { getImagesByRoomId } from "../services/images.service";

export const useImagesByRoomId = (roomId: string) => {
  return useQuery({
    queryKey: ["imagesByRoom", roomId],
    queryFn: () => getImagesByRoomId(roomId),
    refetchOnWindowFocus: false, // To stop excessive calls to backend. Especially in the transition between RoomsView and RoomView
    staleTime: 1000 * 60 * 15, // 15 minutes, doesn't refetch images on every render (no new network request)
    enabled: !!roomId,
  });
};
