import { useQuery } from "@tanstack/react-query";
import { fetchArticlesInfo } from "../../../hooks/API/tasks.service.helper";

export const useArticlesInfo = (articleIds: string | undefined) => {
  return useQuery({
    queryKey: ["articlesInfo", articleIds],
    queryFn: () => fetchArticlesInfo(articleIds || ""),
    enabled: !!articleIds,
  });
};
