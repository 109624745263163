import React from "react";
import { shallowEqual } from "react-redux";
import styled, { css } from "styled-components";
import { ReactComponent as UpvoteIcon } from "../../assets/svg/add-big.svg";
import { ReactComponent as ArrowRight } from "../../assets/svg/icon-arrow-small-back.svg";
import { useTranslation } from "@coworker/locales";
import useFormatter from "../../hooks/useFormatter";
import { useUserPreference } from "../../hooks/useProfilePreferencesQuery";
import profilePreferences from "@coworker/functions/src/enums/profilePreferences";
import { defaultFilters } from "../InputPopup/UserFiltersRow/filterChoices";
import { ConditionalWrapper } from "@coworker/components";
import { Link } from "../Link";
import { useFeatureFlagHooks } from "./useMFAQs.featureToggle";
import { useStoreId } from "../../core/auth/useLoggedInUser";

export const QuestionWrapper = styled.div`
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 88px;
  min-height: 88px;
  width: calc(100% - 5px);
  overflow: hidden;
  transition: background-color 100ms linear;
  ${({ canUpvote }) =>
    canUpvote &&
    css`
      &:active {
        background-color: rgba(0, 88, 163, 0.25);
      }
    `}
  ${({ noBottomBorder }) =>
    !noBottomBorder &&
    css`
      border-bottom: 1px solid var(--grey200);
    `}
  ${({ showBanner }) =>
    showBanner
      ? css`
          border-left: 5px solid var(--warning);
          padding: 12px 0px;
        `
      : css`
          border-left: 5px solid transparent;
        `}
`;

const VotingWrapper = styled.div`
  width: 71px;
  padding: 20px 0px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
`;

const LinkWrapper = styled((props) => <Link {...props} />)`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
`;

export const TextWrapper = styled.div`
  flex-grow: 1;
  font-size: 14px;
  color: var(--grey900);
  width: 0px; // TODO: This works to limit text overflow but why?
`;

export const QuestionText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Subtitle = styled.span`
  color: var(--grey718);
  mix-blend-mode: normal;
`;

const ArrowRightIcon = styled(ArrowRight)`
  padding: 20px;
  transform: scaleX(-1);
`;

/**
 *
 * @param {string} question_id
 * @param {string} questionText
 * @param {boolean?} useCustomTextStyle
 * @param {boolean?} canUpvote
 * @param {boolean?} showUpvote
 * @param {boolean?} noLink
 * @param {number} upvotesCount
 * @param {boolean} noBottomBorder
 * @param {number} lastAskedAt
 * @param {string} customSubtitle
 * @param {function} onClick
 * @param {string} testId
 * @param {string} selectedArea
 * @param {number?} ongoingTaskCreatedAt
 * @returns {JSX.Element}
 * @constructor
 */
const Question = ({
  question_id,
  questionText,
  useCustomTextStyle = false,
  canUpvote = true,
  showUpvote = true,
  noLink = false,
  upvotesCount,
  noBottomBorder,
  lastAskedAt,
  customSubtitle,
  onClick,
  testId,
  selectedArea,
  ongoingTaskCreatedAt = null,
}) => {
  const { createVote } = useFeatureFlagHooks();
  const [frontEndUpvoteCount, setFrontendUpvoteCount] =
    React.useState(upvotesCount);
  const [[, areaFilter]] = useUserPreference(
    profilePreferences.MFAQ_PREFIX + profilePreferences.USER_FILTERS,
    defaultFilters
  );
  const store_id = useStoreId();
  const currentSelectedArea = selectedArea ?? areaFilter;
  React.useEffect(() => {
    setFrontendUpvoteCount(upvotesCount);
  }, [upvotesCount]);

  const vote = React.useCallback(
    (value) => {
      setFrontendUpvoteCount(frontEndUpvoteCount + value);
      createVote(question_id, value, currentSelectedArea, store_id).catch(() =>
        setFrontendUpvoteCount(frontEndUpvoteCount)
      );
    },
    [
      currentSelectedArea,
      frontEndUpvoteCount,
      question_id,
      store_id,
      createVote,
    ]
  );

  const onUpvoteClick = React.useCallback(async () => {
    if (canUpvote && !onClick) {
      window.navigator.vibrate &&
        window.navigator.vibrate(0) &&
        window.navigator.vibrate(50);

      vote(1);
    }
  }, [canUpvote, onClick, vote]);

  const { formatNumberShort, formatNumber } = useFormatter();
  const formattedUpvoteCount =
    frontEndUpvoteCount > 999
      ? formatNumberShort(frontEndUpvoteCount)
      : formatNumber(frontEndUpvoteCount);

  return (
    <QuestionWrapper
      data-testid={testId}
      onClick={() => {
        if (onClick && noLink && !canUpvote) onClick();
      }}
      noBottomBorder={noBottomBorder}
      showBanner={ongoingTaskCreatedAt}
      canUpvote={canUpvote}
    >
      <VotingWrapper data-testid="upvoteCount" onClick={onUpvoteClick}>
        {showUpvote && canUpvote && <UpvoteIcon data-testid="upvoteIcon" />}
        <strong>{formattedUpvoteCount}</strong>
      </VotingWrapper>
      <ConditionalWrapper
        condition={!noLink}
        wrapper={(children) => (
          <LinkWrapper to={`/customerquestions/${question_id}`}>
            {children}
          </LinkWrapper>
        )}
      >
        <TextWrapper>
          <QuestionText>
            {useCustomTextStyle ? (
              questionText
            ) : (
              <strong>{questionText}</strong>
            )}
          </QuestionText>
          <Subtitle>
            {useSubtitle(lastAskedAt, customSubtitle, ongoingTaskCreatedAt)}
          </Subtitle>
        </TextWrapper>
        {noLink ? null : ( // Does not add chevron to question preview when adding new question
          <div>
            <ArrowRightIcon />
          </div>
        )}
      </ConditionalWrapper>
    </QuestionWrapper>
  );
};

export default React.memo(Question, shallowEqual);

function useSubtitle(lastAskedAt, customSubtitle, ongoingTaskCreatedAt) {
  const { t } = useTranslation();
  const { toTimeAgo } = useFormatter();
  if (!!customSubtitle) {
    return customSubtitle;
  }
  const date = !!lastAskedAt ? toTimeAgo(lastAskedAt) : t("unknownString");

  const taskCreatedDate =
    !!ongoingTaskCreatedAt && toTimeAgo(ongoingTaskCreatedAt);

  const subTitle = `${t("lastAskedAtString", { date })}${
    !!ongoingTaskCreatedAt
      ? `, ${t("mfaqTaskCreatedString", { taskCreatedDate })}`
      : ""
  }`;

  return subTitle;
}
