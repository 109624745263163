import React from "react";
import styled from "styled-components";
import { Trans, useTranslation } from "@coworker/locales";
import { ReactComponent as CheckIcon } from "../../assets/svg/check.svg";
import { Icon } from "@coworker/components";

const IconStyle = styled.div`
  padding: 10px 0px;
`;

const ICONS = {
  QUESTION_TASK: (
    <IconStyle>
      <CheckIcon />
    </IconStyle>
  ),
  QUESTION_EDIT: (
    <Icon
      size="16"
      family="icons"
      name="pencil"
      color="grey900"
      className="removeHeight"
    />
  ),
  QUESTION_MERGE: (
    <Icon
      size="16"
      family="icons"
      name="merge"
      color="grey900"
      className="removeHeight"
    />
  ),
};

export function useTaskHistory(tasks, dateFormat) {
  const { t } = useTranslation();
  const getTaskDetails = (task) => {
    let taskSummaryText = null;
    let votesCountText = null;

    // Merge and Edit both have type and task creation doesn't so we are adding here.
    const historyType = task.type ?? "QUESTION_TASK";
    switch (historyType) {
      case "QUESTION_TASK":
        taskSummaryText = (
          <>
            <Trans>mfaqTaskResolvedString</Trans>
            {`: ${task.finished_summary}`}
          </>
        );
        votesCountText = (
          <>
            {dateFormat(task.finished_at)}
            {", "}
            <Trans>mfaqCountWasString</Trans> {task.finished_votes_count}
          </>
        );
        break;
      case "QUESTION_EDIT":
        taskSummaryText = (
          <>
            <Trans>mfaqRenamedFromString</Trans>
            {` :"${task.previous_question_text}"`}
          </>
        );
        votesCountText = `${dateFormat(task.taken_at)}`;
        break;
      case "QUESTION_MERGE":
        taskSummaryText = (
          <>
            <Trans>mfaqMergeWithString</Trans>
            {` :"${task.previous_question_text}"`}
          </>
        );
        votesCountText = (
          <>
            <>{dateFormat(task.taken_at)}, </>
            <>{t("mfaqMergeUpvotesCountString", { count: task.merge_count })}</>
          </>
        );
        break;
      default:
        break;
    }

    return {
      key: task.taken_at || task.finished_at,
      taskIcon: ICONS[historyType],
      taskSummaryText,
      votesCountText,
    };
  };

  return tasks.map(getTaskDetails);
}
