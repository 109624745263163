import React from "react";
import styled, { css } from "styled-components";
import { TextArea, Button, Selector } from "@coworker/components";
import { Trans, useTranslation } from "@coworker/locales";
import { useTextAreaPopup } from "./_common";
import useNullToggler from "../../hooks/useNullToggler";

const Container = styled.div`
  padding-top: 70px;
  overflow: scroll;
  background: white;
`;

const ButtonWrapper = styled.div`
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
`;
const StyledButton = styled(Button)`
  width: 90%;
`;
const StyledArea = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  ${({ fixed }) =>
    fixed &&
    css`
      position: fixed;
      bottom: 0;
      left: 0;
    `}
`;
const Optional = styled.div`
  font-size: 14px;
  color: var(--grey500);
  position: absolute;
  top: 36px;
  right: 29px;
`;
const Required = styled.div`
  font-size: 14px;
  color: var(--grey900);
  position: absolute;
  top: 36px;
  right: 29px;
  font-weight: bold;
`;
const Title = styled.h2`
  font-size: 28px;
  font-weight: bold;
  line-height: 45px;
  letter-spacing: -0.2px;
  color: var(--grey900);
  display: block;
  max-width: 320px;
  margin: 0 auto;
  padding: 0 19px;
  min-height: 45px;
`;

export const TaskAction = ({ content, customTitle, onSubmit }) => {
  const { t } = useTranslation();
  const selector = useNullToggler();
  const [description, setDescription] = React.useState("");
  const containerRef = React.useRef(null);
  const { inFocus, onFocus, onBlur, textArea } = useTextAreaPopup(
    containerRef,
    description,
    { focusOnMount: false }
  );

  const checked = content?.find((c) => c.type === selector.checked) || {};

  const placeholder = () =>
    checked.descriptionRequired && inFocus
      ? t("addDescriptionRequiredString")
      : t("addDescriptionPlainString");

  React.useEffect(() => {
    if (checked?.descriptionRequired) {
      textArea.current.focus();
    }
  }, [checked.descriptionRequired, textArea]);

  return (
    <Container ref={containerRef}>
      <Title>{customTitle}</Title>
      <Selector
        {...selector}
        issue={selector.issue}
        content={content}
        noborder
        numberOfColumns={3}
      />
      <StyledArea>
        <TextArea
          data-testid="descriptionInput"
          maxLength={250}
          wrap="hard"
          value={description}
          placeholder={placeholder()}
          onChange={(e) => setDescription(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={textArea}
        />
        {!inFocus && !checked.descriptionRequired && !description && (
          <Optional>
            <Trans>optionalString</Trans>
          </Optional>
        )}
        {!inFocus && checked.descriptionRequired && !description && (
          <Required>
            <Trans>requiredString</Trans>
          </Required>
        )}
      </StyledArea>
      <ButtonWrapper>
        <StyledButton
          data-testid="continueButton"
          type="submit"
          text={<Trans>continue2String</Trans>}
          primary
          dark
          onClick={() => onSubmit({ issue: selector.checked, description })}
          disabled={
            !selector.checked ||
            (checked.descriptionRequired && description === "")
          }
        />
      </ButtonWrapper>
    </Container>
  );
};
