const testUserAgent = (userAgent) =>
  /iP(ad|od|hone)/i.test(userAgent) &&
  /WebKit/i.test(userAgent) &&
  !/(CriOS|FxiOS|OPiOS|mercury)/i.test(userAgent);

export function iOSSafari() {
  return testUserAgent(window.navigator.userAgent);
}
// Testing:
// var iPhoneSafari = [
//   "Mozilla/5.0 (iPhone; CPU iPhone OS 14_4_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.3 Mobile/15E148 Safari/604.1",
//   "Mozilla/5.0 (Apple-iPhone7C2/1202.466; U; CPU like Mac OS X; en) AppleWebKit/420+ (KHTML, like Gecko) Version/3.0 Mobile/1A543 Safari/419.3",
//   "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.34 (KHTML, like Gecko) Version/11.0 Mobile/15A5341f Safari/604.1",
//   "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1",
//   "Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A5370a Safari/604.1",
//   "Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.0 Mobile/15E148 Safari/604.1",
//   "Mozilla/5.0 (iPhone9,3; U; CPU iPhone OS 10_0_1 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) Version/10.0 Mobile/14A403 Safari/602.1",
//   "Mozilla/5.0 (iPhone9,4; U; CPU iPhone OS 10_0_1 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) Version/10.0 Mobile/14A403 Safari/602.1",
// ];
// console.log("All true:", iPhoneSafari.map(testUserAgent));

// var iPhoneNotSafari = [
//   // Chrome
//   "Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/69.0.3497.105 Mobile/15E148 Safari/605.1",
//   // Firefox
//   "Mozilla/5.0 (iPhone; CPU iPhone OS 12_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/13.2b11866 Mobile/16A366 Safari/605.1.15",
// ];
// console.log("All false:", iPhoneNotSafari.map(testUserAgent));

export const blockBrowserScannerKey = "fixa_no_browserscanner";

export function getLocalDeviceConfig(key, initialValue) {
  const stored = localStorage.getItem(key);
  if (stored === null || stored === undefined) return initialValue;
  return stored === "1";
}

export function setLocalDeviceConfig(key, yes) {
  localStorage.setItem(key, yes ? "1" : "0");
}
