import React from "react";
import styled from "styled-components";
import { ReactComponent as LocationIcon } from "../assets/svg/location.svg";
import { ReactComponent as PickupIcon } from "../assets/svg/pickup_location.svg";
import { ReactComponent as RefillIcon } from "@coworker/reusable/svg/refilling.svg";
import { ReactComponent as ForwardIcon } from "../assets/svg/arrow-small-forward.svg";
import { useTranslation } from "@coworker/locales";
import { ShortLine } from "@coworker/components";

const Container = styled.div`
  background: var(--white);
  padding: ${(props) => props.overridePadding || "0 18px 0 29px"};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid var(--grey150);
  ${({ deactivated, other }) =>
    deactivated || other ? "height: 90px;" : "height: 110px;"}
  font-weight: ${(props) => (props.deactivated ? "normal" : "bold")};
  cursor: pointer;
  color: ${(props) =>
    props.deactivated ? "var(--grey600)" : "var(--grey900)"};
  ${({ selected }) => (selected ? "min-height: 110px;" : null)}
`;

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;
const Title = styled.div`
  padding-bottom: 2px;
  padding-left: 0;
  white-space: normal;
  font-weight: 700;
  ${({ deactivated, overridePadding }) =>
    !deactivated || overridePadding
      ? "color: var(--grey900);"
      : "color: var(--grey600);"}
`;

const Department = styled.div`
  font-weight: normal;
  padding-left: 0;
  color: var(--grey700);
`;

const IconWrapper = styled.div`
  margin-right: 20px;
  width: 20px;
  ${({ iconMargin }) =>
    iconMargin &&
    `margin-top:4px;
         margin-left: 7px;`}
  & > svg {
    transform: scale(1.2);
    color: ${(props) =>
      props.deactivated ? "var(--disabled)" : "var(--grey900)"};
  }
`;

const GrowText = styled.div`
  text-align: left;
  margin: auto;
  margin-right: 15px;
`;
const Optional = styled.div`
  opacity: 0.58;
  font-size: 14px;
  line-height: 24px;
  flex-grow: 0;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
`;
const PHtag = styled.div`
  background: var(--grey200);
  width: 22px;
  height: 22px;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
`;

const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  height: 100%;
`;

const RightIconWrapper = styled.div`
  display: flex;
  margin: auto;
  ${({ iconMargin }) =>
    iconMargin &&
    `margin-top:4px;
       margin-left: 7px;`}
  & > svg {
    transform: scale(1.2);
  }
`;

function AddLocation({
  loading,
  deactivated,
  other,
  locationString,
  quantity,
  displayUnit, // TODO: remove displayUnit and add unitFormatter = (unit, quantity) -=> {quantity, unit}
  optional,
  addon,
  ptag,
  htag,
  list,
  selected,
  code,
  department,
  section,
  onClick,
  noArrow,
  testId,
  overridePadding,
  newAddonLocation,
  additionalSubTitle = <></>,
}) {
  const { t } = useTranslation();

  if (loading && addon === "pickup") {
    return (
      <Container>
        <ShortLine />
      </Container>
    );
  }

  const showDepartment =
    department || section || (!deactivated && quantity) || newAddonLocation;

  return (
    <Container
      overridePadding={overridePadding}
      deactivated={deactivated}
      other={other}
      onMouseDown={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      selected={selected}
      data-testid={testId}
    >
      {!other && (
        <IconWrapper iconMargin={!!addon} deactivated={deactivated}>
          {!addon && <LocationIcon />}
          {addon === "pickup" && <PickupIcon />}
          {addon === "refill" && <RefillIcon />}
        </IconWrapper>
      )}
      <InnerContainer>
        <TitleContainer>
          <GrowText>
            <Stack>
              <Title
                deactivated={deactivated}
                overridePadding={overridePadding}
              >
                <span>{(!newAddonLocation && code) || locationString}</span>
                <span> </span>
                <span>{selected}</span>
              </Title>
              {showDepartment ? (
                <Department>
                  <span>
                    {newAddonLocation &&
                      code &&
                      code + ((department || section || quantity) && ", ")}
                  </span>
                  <span key="department">{department || ""}</span>
                  <span> </span>
                  <span key="section">{section || ""}</span>
                  <span key="quantity">
                    {(department || section || code) && quantity ? (
                      <span>
                        {(department || section) && " • "}
                        <span>
                          {t("maxNUnitsString", {
                            quantity,
                            unit: displayUnit,
                          })}
                        </span>
                      </span>
                    ) : null}
                  </span>
                </Department>
              ) : null}
              {!optional && additionalSubTitle}
            </Stack>
          </GrowText>
          <RightIconWrapper>
            {(addon === "pickup" || addon === "refill" || list) &&
              !deactivated &&
              ptag && <PHtag>P</PHtag>}
            {(addon === "pickup" || addon === "refill" || list) &&
              !deactivated &&
              !ptag &&
              htag && <PHtag>H</PHtag>}
            {!list && !deactivated && !noArrow && <ForwardIcon />}
          </RightIconWrapper>
        </TitleContainer>
        <Optional>{optional}</Optional>
      </InnerContainer>
    </Container>
  );
}

export default AddLocation;
