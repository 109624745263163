import React from "react";
import { SkapaRadioButton } from "../../../TestBuy/CommonComponents/Skapa/SkapaRadioButton";
import { SkapaTab, SkapaTabPanel } from "../../SkapaTabs2";
import { Division, TypesOfDivisions } from "@coworker/types";
import { Trans } from "@coworker/locales";
import { describeDivision } from "../filterChoices";

interface DivisionTabPanelProps {
  divisions: Division[];
  tabKey: string | undefined;
  setCurrent: Function;
  currentCheckedButton: string;
  isWholeStore?: boolean;
  setChecked: Function;
}

export const DivisionTabPanel: React.FC<DivisionTabPanelProps> = ({
  divisions,
  tabKey,
  setCurrent,
  currentCheckedButton,
  isWholeStore,
  setChecked,
}) => {
  const title = isWholeStore ? (
    <Trans>wholeStoreString</Trans>
  ) : (
    <>
      <Trans>WholeString</Trans> <Trans>{describeDivision(tabKey ?? "")}</Trans>
    </>
  );

  const callUpdateFilters = (value: string, tabKey: string) => {
    setChecked({ type: tabKey, item: value });
  };

  return (
    <SkapaTabPanel tabKey={tabKey}>
      <SkapaRadioButton
        onClick={() => {
          setCurrent({
            area: "whole=whole",
            div: tabKey,
          });
          callUpdateFilters("whole=whole", tabKey ?? "");
        }}
        isChecked={currentCheckedButton === "whole=whole"}
        key={`whole-${tabKey}`}
        id={`whole-${tabKey}`}
        name={`whole-${tabKey}`}
        value={`whole=whole`}
        title={title}
      />
      {!isWholeStore &&
        divisions?.map((division: Division, index: number) => {
          return (
            <SkapaRadioButton
              name={`HFB ${division.hfb}-${division.division_id}-${index}`}
              onClick={() => {
                setCurrent({
                  area: `depts=${division.hfb}`,
                  div: tabKey,
                });
                callUpdateFilters(`depts=${division.hfb}`, tabKey ?? "");
              }}
              isChecked={currentCheckedButton === `depts=${division.hfb}`}
              key={`HFB ${division.hfb}-${index}`}
              id={`HFB ${division.hfb}-${division.division_id}-${index}`}
              value={`depts=${division.hfb}`}
              title={
                <>
                  {`HFB ${division.hfb}`} {" - "}
                  <Trans>{`hfb${division.hfb}String`}</Trans>
                </>
              }
            />
          );
        })}
    </SkapaTabPanel>
  );
};

interface DivisionTabProps {
  title: string;
  tabKey: TypesOfDivisions | undefined;
  onTabChanged: Function;
  hasDivisionId?: boolean;
  setChecked: Function;
}
export const DivisionTab: React.FC<DivisionTabProps> = ({
  title,
  tabKey,
  onTabChanged,
  hasDivisionId,
  setChecked,
}) => {
  if (!hasDivisionId) return <></>;
  return (
    <SkapaTab
      title={title}
      tabKey={tabKey}
      onTabChanged={onTabChanged}
      setChecked={setChecked}
    />
  );
};
